import { Button } from '@mui/material';
import styled from 'styled-components';

import { LanguageSelectorProps } from '.';

export const ChveronContainer = styled(Button)`
    min-width: 0 !important;
    padding: 0 !important;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LanguageSelectorContainer = styled.div<LanguageSelectorProps>`
    align-items: center;
    color: ${props => props.color};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0px;
    position: relative;

    p {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin: 0;
    }
`;
