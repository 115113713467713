import { SVGProps } from 'react';

export default function BellBadgeFill(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.80309 12.7933L1.80307 12.7933C0.979628 13.5914 0.108032 14.4362 0.108032 15.4191C0.108032 15.436 0.108302 15.4529 0.10884 15.4695C0.132979 16.2421 0.708162 17.1188 1.63596 17.1188H6.10369C6.36872 18.332 7.49356 19.3337 8.99938 19.3337C10.4967 19.3337 11.6209 18.332 11.8859 17.1188H16.3536C17.3016 17.1188 17.8815 16.2034 17.8815 15.4191C17.8815 14.9983 17.7218 14.6028 17.4744 14.2255C17.2163 13.8316 16.8627 13.4574 16.4957 13.0949C16.3931 12.9935 16.2894 12.893 16.1865 12.7933L16.1865 12.7932L16.1695 12.7768C15.5804 12.1793 15.3964 10.9748 15.3135 9.92909C15.2859 9.3222 15.2399 8.74332 15.1662 8.19245C15.1585 8.1957 15.1508 8.19893 15.143 8.20214C14.8133 5.49893 13.7922 3.60137 11.7422 2.8518C11.3557 1.61934 10.3524 0.666992 8.99936 0.666992C8.99783 0.666992 8.9963 0.666993 8.99477 0.666996C8.99324 0.666993 8.99171 0.666992 8.99018 0.666992C7.63715 0.666992 6.63388 1.61934 6.2473 2.8518C4.19738 3.60137 3.17627 5.49893 2.8465 8.20214C2.83875 8.19893 2.83102 8.1957 2.82329 8.19245C2.74966 8.74332 2.70364 9.3222 2.67603 9.92909C2.59319 10.9748 2.4091 12.1793 1.82003 12.7768L1.80309 12.7933Z"
                fill="white"
            />
        </svg>
    );
}
