import ReactEcharts from 'echarts-for-react';

export interface StackedLineChartProps {
    legends: string[];
    data: StackedLineChart[];
}

export interface StackedLineChart {
    label: string;
    color: string;
    data: number[];
}

export function StackedLineChart({ legends, data }: StackedLineChartProps) {
    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        legend: {
            type: 'scroll',
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                data: legends,
            },
        ],
        yAxis: [
            {
                type: 'value',
            },
        ],
        series: data.map(d => {
            return {
                type: 'line',
                stack: 'total',
                itemStyle: {
                    color: d.color,
                },
                data: d.data,
                name: d.label,
            };
        }),
    };

    return <ReactEcharts notMerge={true} option={option} style={{ width: '100%', height: '100%' }} />;
}
