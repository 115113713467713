import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Logout as LogoutIcon } from 'src/common/components/icons';

import { StyledLogout } from './styles';

export default function LogoutButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
    const { t } = useTranslation();

    return (
        <StyledLogout type="button" {...props}>
            <LogoutIcon />
            <p>{t('menu.logout')}</p>
        </StyledLogout>
    );
}
