import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, ListItemText, MenuItem, Popover, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ClipboardEvent, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, useSearch } from 'src/common';
import { Button, Column, Divider, Input } from 'src/common/components';
import { HourHelper } from 'src/common/helpers/hour.helper';
import { EventRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { useEvents } from 'src/modules/alarm-processing/hooks/use-events';
import { filterEventsSchema } from 'src/modules/alarm-processing/infra';
import { DepartmentResume, departmentService } from 'src/modules/configurations';
import { vehicleService } from 'src/modules/configurations/vehicles/services/vehicle.service';
import { Vehicle } from 'src/modules/vehicle-monitoring/domain/entities/vehicle';

import { TypeSearch } from '../../../../common/constants/type-search';
import { useAuth } from '../../../auth';
import { userProfile } from '../../../auth/constants/user-profile';
import { useSortReviewEvent } from '../../hooks';
import { FilterNamePropsEvents } from '../alarms-container';
import RangeSlider from '../silder-range';
import { Container, Form, SpanStyle } from './styles';

interface AlarmFilterProps {
    names?: FilterNamePropsEvents[];
    setNames?: (value: FilterNamePropsEvents[] | ((prevVar: FilterNamePropsEvents[]) => FilterNamePropsEvents[])) => void;
}

export default function AlarmsFilter({ names, setNames }: AlarmFilterProps) {
    const { eventScreenType, handleRequestList, requestData, setOperations, setVehicles, vehicles, operations } = useEvents();
    const { clear } = useSearch();
    const { handleSelectAll: selectAll } = useSortReviewEvent();
    const { t } = useTranslation();
    const { session } = useAuth();

    const [alarmOrEvent, setAlarmOrEvent] = useState(t('alarms.alarm'));
    const [alarmsInPtbr, setAlarmsInPtbr] = useState(['']);
    const [rangeSliderValue, setRangeSliderValue] = useState<number[]>([0, 200]);

    const filterEventsSchemaMemo = useMemo(() => filterEventsSchema(t), [t]);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [componentWidth, setComponentWidth] = useState<number>(0);

    const componentRef = useRef<HTMLDivElement>(null);

    const handleResize = () => {
        if (componentRef.current) {
            const width: number = componentRef.current.clientWidth;
            setComponentWidth(width);
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [componentRef.current?.clientWidth]);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (names!.filter(name => name.checked).length > 0) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleInputHour = useCallback((e: FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;

        const newValue = HourHelper.mask(value?.substring(0, 5));

        e.currentTarget.value = newValue;
    }, []);

    const handlePreventPaste = useCallback((event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        if (eventScreenType === 'events') {
            setAlarmOrEvent(t('alarms.event'));
        } else {
            setAlarmOrEvent(t('alarms.alarm'));
        }
    }, [eventScreenType, t]);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const selectedValues = event.target.value as string[];
        const updatedOptions: FilterNamePropsEvents[] | undefined = names?.map(name => ({
            ...name,
            checked: selectedValues.includes(name.language),
        }));
        setNames!(updatedOptions!);

        showSelectedEvents(updatedOptions!);
    };

    const showSelectedEvents = useCallback((updatedOptions: FilterNamePropsEvents[]) => {
        const resultArray: string[] = [];

        updatedOptions.forEach(inputItem => {
            if (inputItem.checked) {
                resultArray.push(inputItem.language);
            }
        });

        setAlarmsInPtbr(resultArray);
    }, []);

    useEffect(() => {
        showSelectedEvents(names!);
    }, [names, showSelectedEvents]);

    const {
        setValue,
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm<EventRequestDTO>({
        defaultValues: requestData,
        resolver: yupResolver(filterEventsSchemaMemo),
        mode: 'onChange',
    });

    const onSubmit = useCallback(
        (data: Partial<EventRequestDTO>) => {
            delete data?.pageSize;
            delete data?.finalDate;
            delete data?.initialDate;
            clear();
            selectAll([]);

            handleRequestList({
                ...data,
                text: '',
                typeSearch: TypeSearch.PARAMS,
                currentPage: 1,
                speedRange: rangeSliderValue,
                alarmsName: names?.filter(name => name.checked).map(obj => obj.real_name),
            });
        },
        [clear, handleRequestList, names, rangeSliderValue, selectAll],
    );

    const fleet = watch('board');
    const operation = watch('operation');

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        setValue('board', '');

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 15,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations, setValue]);

    useEffect(() => {
        const controller = new AbortController();

        setVehicles({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            vehicleService
                .list({
                    currentPage: 1,
                    pageSize: 15,
                    fleet,
                    typeSearch: TypeSearch.PARAMS,
                    operation,
                    signal: controller.signal,
                })
                .then(res =>
                    setVehicles({
                        type: 'data',
                        payload: res.elements as Array<Vehicle>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [fleet, operation, setValue, setVehicles]);

    return (
        <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {session?.user.profile === userProfile.analyst && (
                    <Input
                        autoComplete="off"
                        type="text"
                        label={String(t('configurations.analyst'))}
                        placeholder={String(t('configurations.analyst'))}
                        {...register('analyst')}
                    />
                )}

                <Column
                    className="select-container"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    onClick={handlePopoverClose}
                    style={{ width: componentWidth }}
                >
                    <SpanStyle> {eventScreenType === 'events' ? t('alarms.event') : t('alarms.alarm')} </SpanStyle>
                    <Select
                        id="select-alarm"
                        multiple
                        displayEmpty={true}
                        style={{ height: '2.25rem', background: 'white', borderRadius: '0.5rem' }}
                        sx={{
                            '&:hover': {
                                '&& fieldset': {
                                    borderColor: '#3e66fb',
                                },
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#3e66fb',
                            },
                            fontFamily: 'Open Sans',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '20px',
                            color: '#000000',
                            '& .MuiSelect-select .notranslate::after': alarmOrEvent
                                ? {
                                      content: `"${alarmOrEvent}"`,
                                      opacity: 0.42,
                                  }
                                : {},
                        }}
                        value={names?.filter(name => name.checked).map(name => name.language)}
                        onChange={handleChange}
                        renderValue={() => {
                            return alarmsInPtbr.join(', ');
                        }}
                    >
                        {names?.map(name => {
                            if (!name) return null;
                            return (
                                <MenuItem key={name.language} value={name.language} property={name.language} placeholder={name.language}>
                                    <Checkbox checked={name.checked} />
                                    <ListItemText primary={name.language} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: 'none',
                            width: '70%',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography sx={{ p: 1 }}>
                            {names
                                ?.filter(name => name.checked)
                                .map(name => name.language)
                                .join(', ')}
                        </Typography>
                    </Popover>
                </Column>
                <Autocomplete
                    isLoading={operations.isLoading}
                    options={operations.data.map(operation => ({ ...operation, value: operation.name }))}
                    setValue={setValue}
                    label={String(t('alarms.operation'))}
                    placeholder={String(t('alarms.operation'))}
                    {...register('operation')}
                    feedback={{
                        message: errors['operation']?.message?.toString(),
                    }}
                    nextElement={{
                        id: 'board',
                    }}
                />
                <Autocomplete
                    setValue={setValue}
                    label={String(t('alarms.fleet'))}
                    placeholder={String(t('alarms.fleet'))}
                    isLoading={vehicles.isLoading}
                    options={vehicles.data.map(vehicle => ({
                        id: vehicle.id,
                        value: vehicle.fleet,
                    }))}
                    {...register('board')}
                    feedback={{
                        message: errors['board']?.message?.toString(),
                    }}
                />
                <RangeSlider min={0} max={200} value={rangeSliderValue} setValue={setRangeSliderValue} />
                <Input
                    autoComplete="off"
                    maxLength={5}
                    type="text"
                    label={String(t('general.initial_hour'))}
                    placeholder={String(t('general.initial_hour'))}
                    onInput={handleInputHour}
                    onPaste={handlePreventPaste}
                    {...register('startTime')}
                    feedback={{
                        message: errors['startTime']?.message?.toString(),
                    }}
                />
                <Input
                    autoComplete="off"
                    maxLength={5}
                    type="text"
                    label={String(t('general.final_hour'))}
                    placeholder={String(t('general.final_hour'))}
                    onInput={handleInputHour}
                    onPaste={handlePreventPaste}
                    {...register('endTime')}
                    feedback={{
                        message: errors['endTime']?.message?.toString(),
                    }}
                />
                <Column justify="flex-end" height="60px" ref={componentRef}>
                    <Column justify="flex-end" height="36px">
                        <Button type="submit" size="small" disabled={!isValid}>
                            {t('general.apply')}
                        </Button>
                    </Column>
                </Column>
            </Form>
            <Divider />
        </Container>
    );
}
