import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { ApiResponse } from 'src/common/core/api-response';
import { refreshTokenService } from 'src/common/services/refresh-token.service';
import { API_URL, ENV } from 'src/config/consts';
import { authService } from 'src/modules/auth';
import Cookies from 'universal-cookie';

let isRefreshing = false;
let failedRequestsQueue: any[] = [];

export const interceptorFunction = async (request: InternalAxiosRequestConfig) => {
    try {
        const tokens = await authService.getTokens();
        const idToken = tokens.idToken.jwtToken;
        const token = tokens.accessToken.jwtToken;
        request.headers.idToken = idToken;
        request.headers.jose = tokens.jose;
        request.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return request;
};

const api = axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use(interceptorFunction);

api.interceptors.response.use(
    response => response,
    async (error: AxiosError<ApiResponse>) => {
        if (error.response?.status === 401) {
            if (error.response?.data?.message === 'TokenExpiredError') {
                const originalConfig = error.config;
                if (!isRefreshing) {
                    isRefreshing = true;

                    const { jose } = await authService.getTokens();

                    if (!jose) return authService.signOut();
                    const cookies = new Cookies();
                    refreshTokenService
                        .execute(jose)
                        .then(response => {
                            const { content } = response.data;
                            localStorage.setItem(`@session-${ENV}`, JSON.stringify(content));

                            cookies.set('jose', content.token, { path: '/', secure: true, sameSite: 'none' });

                            api.defaults.headers.jose = content.token;

                            failedRequestsQueue?.forEach(request => request.resolve(content.token));

                            failedRequestsQueue = [];
                        })
                        .catch(error => {
                            failedRequestsQueue.forEach(request => request.reject(error));

                            failedRequestsQueue = [];
                            cookies.remove('jose');
                            authService.signOut();
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                }

                return new Promise((resolve, reject) => {
                    failedRequestsQueue.push({
                        resolve: (token: string) => {
                            if (originalConfig) {
                                originalConfig.headers['Authorization'] = `Bearer ${token}`;

                                resolve(api(originalConfig));
                            }
                        },
                        reject: (error: AxiosError) => {
                            reject(error);
                        },
                    });
                });
            } else {
                authService.signOut();
            }
        }

        return Promise.reject(error);
    },
);

export { api };
