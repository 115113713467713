import { GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, useMap } from '../../../../common';

export interface ProvidersProps extends GoogleMapProps {
    children?: React.ReactNode;
    height?: string;
    width?: string;
    zoom?: number;
    center?: {
        lat: number;
        lng: number;
    };
}

export function GoogleMapContainer({
    children,
    height = '100%',
    width = '100%',
    zoom = 2,
    center = { lat: -22.7253017, lng: -47.6532601 },
    ...rest
}: ProvidersProps) {
    const { isLoaded, loadError } = useMap();
    const { t } = useTranslation();

    if (loadError) return <ErrorMessage>{t('general.error')}</ErrorMessage>;

    return (
        <>
            {isLoaded && (
                <GoogleMap
                    zoom={zoom}
                    center={center}
                    mapContainerStyle={{
                        width: width,
                        height: height,
                    }}
                    options={{
                        gestureHandling: 'greedy',
                    }}
                    {...rest}
                >
                    {children}
                </GoogleMap>
            )}
        </>
    );
}
