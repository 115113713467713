import styled from 'styled-components';

export const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const Dialog = styled.dialog`
    border: 0;
    padding: 0;
    background-color: transparent;
    overflow: hidden;
`;

export const ErrorMessage = styled.p`
    position: absolute;
    cursor: default;

    font-weight: 500;
    line-height: 18px;

    text-align: center;

    color: #7a7f85;

    bottom: 15%;
`;

export const ExpandButton = styled.button`
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    display: none;
    justify-content: center;

    position: absolute;
    bottom: 8px;
    right: 8px;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    opacity: 0.5;

    padding: 12px;

    color: #fff;

    &:hover {
        background-color: #000;
    }
`;

export const ExpandedBox = styled(Box)`
    height: 100vh;
    width: 100vw;
    overflow: hidden;
`;

export const ExpandedImage = styled.img`
    height: 80%;
    width: 80%;

    border-radius: 8px !important;
`;

export const MinimizeButton = styled.button`
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 12.5vh;
    right: 11vw;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    opacity: 0.5;

    padding: 12px;

    color: #fff;

    &:hover {
        background-color: #000;
    }
`;

export const ResizableBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.2s ease-in-out;

    &:hover button[type='button'].expand-button {
        display: flex;
    }
`;
