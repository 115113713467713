import { AxiosError } from 'axios';
import { DialogHTMLAttributes, forwardRef, ForwardRefRenderFunction, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollbar } from 'src/common';
import { Button, Dialog, Divider } from 'src/common/components';
import Column from 'src/common/components/column';
import Row from 'src/common/components/row';
import { toast } from 'src/common/components/toast';

import { useUsers } from '../../hooks/use-users';
import { userService } from '../../services/user.service';
import { CancelButton, DialogContent } from './styles';

const Base: ForwardRefRenderFunction<HTMLDialogElement, DialogHTMLAttributes<HTMLDialogElement>> = (props, ref) => {
    const { showScrollBar } = useScrollbar();
    const { t } = useTranslation();
    const { handleRequestList, selectedUsers, handleSelectAll } = useUsers();

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = useCallback(() => {
        document.querySelector<HTMLDialogElement>(`dialog#delete-users-bulk-dialog`)?.close();
    }, []);

    const handleConfirm = useCallback(async () => {
        const uuids = selectedUsers?.map(user => user.uuid);

        setIsLoading(true);
        try {
            await userService.deleteUsersInBulk(uuids);
            handleSelectAll([]);
            handleRequestList({
                currentPage: 1,
            });
            showScrollBar();
            toast.success(t('general.success'), '');
            handleClose();
        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.response?.data.message === 'general.exclude_yourself') {
                    toast.error(t('general.error'), t('general.exclude_yourself'));
                    return;
                }
            }
            toast.error(t('general.error'), '');
        } finally {
            setIsLoading(false);
        }
    }, [handleClose, handleRequestList, handleSelectAll, selectedUsers, showScrollBar, t]);

    const data = useMemo(() => {
        if (selectedUsers?.length === 0) return <Fragment />;

        return (
            <Fragment>
                <Row justify="center" width="100%" align="start">
                    <h4>{t('configurations.user_delete')}</h4>
                </Row>
                <Column align="center" gap={16} flex width="100%">
                    <Column align="center">
                        <p>{t('configurations.confirm_delete_users')}</p>
                        <p>{t('configurations.this_action')}</p>
                    </Column>
                    <Divider />
                </Column>
                <Row align="flex-end" gap={16} flex width="100%">
                    <CancelButton onClick={handleClose}>{t('general.cancel')}</CancelButton>
                    <Button isLoading={isLoading} type="reset" color="alert" onClick={handleConfirm}>
                        {t('configurations.delete')}
                    </Button>
                </Row>
            </Fragment>
        );
    }, [handleClose, handleConfirm, isLoading, selectedUsers?.length, t]);

    return (
        <Dialog ref={ref} id="delete-users-bulk-dialog" {...props}>
            <DialogContent>{data}</DialogContent>
        </Dialog>
    );
};

const DeleteUsersBulkDialog = forwardRef(Base);

export default DeleteUsersBulkDialog;
