import { createContext, useCallback, useMemo, useState } from 'react';
import { ResponseStatus } from 'src/common/core/api-response';
import { Pagination } from 'src/common/core/pagination';
import { FunctionComponent } from 'src/common/types';
import { EventDTO, PastEventsRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { listPastEvents } from 'src/modules/alarm-processing/services/list-past-events-by-board.service';

import { PastEventsContextProps } from './types';

const INITIAL_REQUEST_DATA: PastEventsRequestDTO = {
    board: '',
    operation: '',
    currentPage: 1,
    pageSize: 5,
};

export const PastEventsContext = createContext<PastEventsContextProps>({} as PastEventsContextProps);

export function PastEventsProvider({ children }: FunctionComponent): JSX.Element {
    const [events, setEvents] = useState<Pagination<EventDTO> | null>(null);
    const [requestData, setRequestData] = useState<PastEventsRequestDTO>(INITIAL_REQUEST_DATA);
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: true,
    });

    const handleGetList = useCallback(async () => {
        if (!requestData.board) return;
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));
        listPastEvents
            .execute(requestData)
            .then(data => {
                setEvents(data);
                setResponseStatus({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: !data?.elements || data?.elements?.length === 0,
                    success: true,
                });
            })
            .catch(reason =>
                setResponseStatus({
                    loading: false,
                    error: reason,
                    hasError: true,
                    void: false,
                    success: false,
                }),
            );
    }, [requestData]);

    const handleRequestList = useCallback((data: Partial<PastEventsRequestDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const data: PastEventsContextProps = useMemo(
        () => ({ events, handleGetList, handleRequestList, requestData, responseStatus }),
        [events, handleGetList, handleRequestList, requestData, responseStatus],
    );

    return <PastEventsContext.Provider value={data}>{children}</PastEventsContext.Provider>;
}
