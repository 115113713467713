import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Image } from 'src/common/components';
import { useSortReviewEvent } from 'src/modules/alarm-processing/hooks';
import { useEvents } from 'src/modules/alarm-processing/hooks/use-events';

import { Container, StyledImage, StyledVideoPlayer } from './styles';

export default function Carousel() {
    const { selectedEvents } = useEvents();
    const { handleShowEvent, showEvent } = useSortReviewEvent();

    const handleSelectEvent = useCallback(
        (operation: 'add' | 'sub') => {
            if (!selectedEvents?.length) return;

            const index = selectedEvents?.findIndex(event => event.id === showEvent.data?.id);
            const lastIndex = selectedEvents.length - 1;

            if (index === undefined || index === -1) return;

            switch (operation) {
                case 'add':
                    showEvent.index === 1
                        ? handleShowEvent({
                              data: selectedEvents[index >= lastIndex ? 0 : index + 1],
                              index: 0,
                          })
                        : handleShowEvent({
                              ...showEvent,
                              index: 1,
                          });

                    break;
                case 'sub':
                    showEvent.index === 0
                        ? handleShowEvent({
                              data: selectedEvents[index <= 0 ? lastIndex : index - 1],
                              index: 1,
                          })
                        : handleShowEvent({
                              ...showEvent,
                              index: 0,
                          });
                    break;
                default:
                    break;
            }
        },
        [handleShowEvent, selectedEvents, showEvent],
    );

    useEffect(() => {
        handleShowEvent({
            data: selectedEvents?.[0] || null,
            index: 0,
        });
    }, [handleShowEvent, selectedEvents]);

    return useMemo(() => {
        if (!selectedEvents?.length) {
            return <div />;
        }

        return (
            <Container aria-labelledby="simple-tab-0">
                {selectedEvents?.map(event => {
                    return (
                        <Fragment key={event.id?.toString()}>
                            <StyledImage className="fade" selected={showEvent.data?.id === event?.id && showEvent.index === 0}>
                                <Image src={event.photoUrl || undefined} alt={event?.alarm || 'Generic image'} height={190} width={328} />
                            </StyledImage>

                            {event?.videoUrl ? (
                                <StyledVideoPlayer
                                    source={event?.videoUrl}
                                    type="video/mp4"
                                    selected={showEvent.data?.id === event?.id && showEvent.index === 1}
                                    autoPlay
                                    muted
                                />
                            ) : (
                                <StyledImage className="fade" selected={showEvent.data?.id === event?.id && showEvent.index === 1}>
                                    <Image alt="" height={190} width={328} />
                                </StyledImage>
                            )}
                        </Fragment>
                    );
                })}

                <a className="prev" onClick={() => handleSelectEvent('sub')}>
                    <MdChevronLeft size={24} color="#46526C" />
                </a>
                <a className="next" onClick={() => handleSelectEvent('add')}>
                    <MdChevronRight size={24} color="#46526C" />
                </a>
            </Container>
        );
    }, [handleSelectEvent, selectedEvents, showEvent.data?.id, showEvent.index]);
}
