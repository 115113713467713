import { styled as mui, Modal as MuiModal } from '@mui/material';
import { Button, Column } from 'src/common/components';
import styled from 'styled-components';

export const CancelButton = styled(Button).attrs(() => ({
    type: 'button',
    color: 'outline',
    id: 'cancel-btn',
}))`
    &#cancel-btn {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.2px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #7b7b7b;
        background: #d7d7d7;
        border: 1px solid #a9a9a9;

        &:hover {
            background-color: #f5f5f5;
        }
    }
`;

export const Card = styled(Column)`
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
    width: 394px;

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        color: #7b7b7b;
        text-align: center;
        word-break: break-all;
    }
`;

export const Modal = mui(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h4`
    margin: 0;

    font-weight: 500;
    font-size: 26px;
    line-height: 24px;

    color: #262d3c;
`;
