import { forwardRef, useCallback } from 'react';

import Row from '../../row';
import Autocomplete, { AutocompleteProps } from '../autocomplete';
import { Container, StyledChip } from './styles';

type AutocompleteMultipleProps = AutocompleteProps & {
    autocompleteName: string;
    setValue(field: string, value: any): void;
    values: string[];
};

export default forwardRef<HTMLInputElement, AutocompleteMultipleProps>(function AutocompleteMultiple(
    { autocompleteName, values, setValue, ...props },
    ref,
) {
    const handleRemove = useCallback(
        (value: string) => {
            setValue(
                autocompleteName,
                values.filter(item => item !== value),
            );
        },
        [autocompleteName, setValue, values],
    );

    const handleSetValue = useCallback(
        (field: string, value: string) => {
            if (values.findIndex(item => item === value) === -1) setValue(autocompleteName, [...values, value]);

            setValue(field, '');
        },
        [autocompleteName, setValue, values],
    );

    return (
        <Container>
            <Autocomplete {...props} ref={ref} setValue={handleSetValue} />
            <Row gap={8}>
                {values?.map(value => (
                    <StyledChip key={value} label={value} onClick={() => handleRemove(value)} />
                ))}
            </Row>
        </Container>
    );
});
