import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollbar } from 'src/common';
import { Button, Dialog, Divider } from 'src/common/components';
import Column from 'src/common/components/column';
import Row from 'src/common/components/row';
import { toast } from 'src/common/components/toast';

import { useOperations } from '../../hooks/use-operations';
import { departmentService } from '../../services';
import { CancelButton, DialogContent } from './styles';

function DeleteDptosInBulkDialog() {
    const { showScrollBar } = useScrollbar();
    const { deleteInBulkDialogRef, setRequest, handleSelectAll, selectedOperations } = useOperations();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = useCallback(() => {
        deleteInBulkDialogRef.current?.close();
    }, [deleteInBulkDialogRef]);

    const handleConfirm = useCallback(async () => {
        if (selectedOperations?.length === 0) return;
        setIsLoading(true);
        try {
            await departmentService.deleteInBulk(selectedOperations.map(o => o.id));
            setRequest(state => ({
                ...state,
                currentPage: 1,
            }));
            showScrollBar();
            toast.success(t('general.success'), '');
            handleSelectAll([]);
            handleClose();
        } catch (e) {
            toast.error(t('general.error'), '');
        } finally {
            setIsLoading(false);
        }
    }, [handleClose, handleSelectAll, selectedOperations, setRequest, showScrollBar, t]);

    const data = useMemo(() => {
        if (selectedOperations?.length === 0) return <Fragment />;

        return (
            <Fragment>
                <Row justify="center" width="100%" align="start">
                    <h4>{t('configurations.delete_department')}</h4>
                </Row>
                <Column align="center" gap={16} flex width="100%">
                    <Column align="center">
                        <p>
                            {t('configurations.do_you_want_to_delete_the_department')}?
                        </p>
                        <p>{t('configurations.this_action')}</p>
                    </Column>
                    <Divider />
                </Column>
                <Row align="flex-end" gap={16} flex width="100%">
                    <CancelButton onClick={handleClose}>{t('general.cancel')}</CancelButton>
                    <Button isLoading={isLoading} type="reset" color="alert" onClick={handleConfirm}>
                        {t('configurations.delete')}
                    </Button>
                </Row>
            </Fragment>
        );
    }, [handleClose, handleConfirm, isLoading, selectedOperations?.length, t]);

    return (
        <Dialog ref={deleteInBulkDialogRef} id="delete-dptos-in-bulk-dialog">
            <DialogContent>{data}</DialogContent>
        </Dialog>
    );
};



export default DeleteDptosInBulkDialog;
