import styled from 'styled-components';

export const Container = styled.div`
    padding: 0.5rem;
    border-radius: 0.3rem;
    height: 1rem;
    display: flex;
    align-items: center;
    white-space: nowrap;

    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &.default {
        background-color: green;
        color: #fff;
        border: 1px solid green;
    }

    &.primary {
        background-color: #A2CDF0;
        border: 1px solid #A2CDF0;
        color: #fff;
    }

    &.secondary {
        background: red;
        border: 1px solid red;
        color: #fff;
    }
`;
