import styled from 'styled-components';

export const Form = styled.form`
    background: #f1f1f1;
    padding: 16px;

    border-bottom: 1px solid #d9d9d9;

    display: grid;
    grid-template-columns: repeat(5, 1fr) 144px;
    gap: 16px;
    margin-top: -16px;

    @media screen and (max-width: 1525px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 1024px) {
        grid-template-columns: auto;
        grid-auto-flow: row;
    }
`;
