import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

type Storage<T> = [T, Dispatch<SetStateAction<T>>];

export function getStorageValue<T>(key: string, initialState: T) {
    const saved = localStorage.getItem(key);
    return saved ? (JSON.parse(saved) as T) : initialState;
}

export function useLocalStorage<T>(key: string, initialState: T): Storage<T> {
    const [state, setState] = useState(() => getStorageValue(key, initialState));

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return useMemo(() => [state, setState], [state]);
}
