import { Fragment } from 'react';

import { Dialog } from '../../../../../common';
import Column from '../../../../../common/components/column';
import { useRegistrationShipments } from '../../hooks';
import { RegistrationShipmentsDeleteDialogForm } from '../registration-shipments-delete-dialog-form';

export default function RegistrationShipmentsDeleteDialog() {
    const { registrationShipmentsDeleteDialogRef, deleteDialogIsOpen, handleOpenDeleteDialog } = useRegistrationShipments();

    return (
        <Dialog onCloseModal={handleOpenDeleteDialog} ref={registrationShipmentsDeleteDialogRef} id="registration-shipments-delete-dialog">
            <Column width="400px">{deleteDialogIsOpen ? <RegistrationShipmentsDeleteDialogForm /> : <Fragment />}</Column>
        </Dialog>
    );
}
