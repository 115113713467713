import styled from 'styled-components';

type CircularProgressProps = {
    color?: string;
    trackColor?: string;
};

const CircularProgress = styled.div<CircularProgressProps>`
    border: 1px solid ${props => props?.trackColor || 'transparent'};
    border-top: 1px solid ${props => props?.color || '#fff'};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    box-sizing: border-box;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default CircularProgress;
