import { FunctionComponent } from 'src/common/types/react';

import { BackgroundColor, BackgroundContainer, BackgroundImage } from './styles';

export default function AuthBackground({ children }: FunctionComponent) {
    return (
        <BackgroundContainer>
            <BackgroundImage />
            <BackgroundColor />
            {children}
        </BackgroundContainer>
    );
}
