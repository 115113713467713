import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'src/common/components/toast';

import { authService } from '../factories';
import { useAuth } from './use-auth';

export function useLogin() {
    const { setChallenge, initialize } = useAuth();
    const { t } = useTranslation();

    return useMutation({
        mutationFn: authService.signIn,
        mutationKey: ['login'],
        onError: () => {
            toast.error(t('auth.login'), t('auth.error_login'));
        },
        onSuccess: async (data, variables) => {
            const { user, issue, challengeName, challengeParameters } = data;
            const { password, username } = variables;
            if (challengeName) {
                return setChallenge({ password, challengeParameters, email: username, challengeName, name: user?.name || '', issue });
            }
            await initialize();
        },
    });
}
