import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoEyeOutline } from 'react-icons/io5';
import { Button, ChangeLayout, Column, Refresh, Row, SortUp, Title } from 'src/common/components';
import { useEventListener, useSearch } from 'src/common/hooks';
import { UpdateData } from 'src/modules/alarm-processing/components';
import AlarmDetailsDialog from 'src/modules/alarm-processing/components/alarm-details-dialog';
import AlarmsContainer from 'src/modules/alarm-processing/components/alarms-container';
import { useSortReviewEvent } from 'src/modules/alarm-processing/hooks';
import { useEvents } from 'src/modules/alarm-processing/hooks/use-events';

import { ActivityCard } from '../../modules/alarm-processing/components/activity-card';
import { useAuth } from '../../modules/auth';
import { userProfile } from '../../modules/auth/constants/user-profile';

export default function ProcessingAlarms() {
    const { eventScreenType, handleGetList, handleSetInitialData, handleShowEventDialog, handleClearEvents } = useEvents();
    const { formRef } = useSearch();
    const { selectedEvents, handleSelectAll } = useSortReviewEvent();
    const { t } = useTranslation();
    const { session } = useAuth();

    const eventButtonRef = useRef<HTMLButtonElement>(null);

    const handleEventDialog = useCallback(() => {
        handleShowEventDialog(selectedEvents);
    }, [handleShowEventDialog, selectedEvents]);

    const handleUpdate = useCallback(() => {
        handleSelectAll([]);
        handleGetList();
    }, [handleGetList, handleSelectAll]);

    const handleClickOnEventButton = useCallback(
        (e: KeyboardEvent) => {
            if (formRef.current?.contains(e.target as Element)) {
                return;
            }

            if ([`,`].includes(e.key)) {
                handleUpdate();
            }

            if (['Enter', `'`].includes(e.key)) {
                eventButtonRef?.current?.click();
            }
        },
        [formRef, handleUpdate],
    );

    const eventButtonProps = useMemo(() => {
        switch (eventScreenType) {
            case 'events':
                return {
                    icon:
                        session?.user.profile === userProfile.client ? (
                            <IoEyeOutline size={20} color={selectedEvents.length === 0 ? '#A9A9A9' : '#FFF'} />
                        ) : (
                            <Refresh color={selectedEvents.length === 0 ? '#A9A9A9' : '#FFF'} />
                        ),
                    label: session?.user.profile === userProfile.client ? t('alarms.see_details') : t('general.review'),
                };
            case 'pending':
                return {
                    icon: <SortUp color={selectedEvents.length === 0 ? '#A9A9A9' : '#FFF'} />,
                    label: t('general.classification'),
                };
            default:
                return null;
        }
    }, [eventScreenType, selectedEvents.length, session?.user.profile, t]);

    ['keyup', 'keydown'].forEach(event => {
        window.addEventListener(event, (e: any) => {
            document.onselectstart = function () {
                return !(e.key == 'Shift' && e.shiftKey);
            };
        });
    });

    useEffect(() => {
        handleSetInitialData(eventScreenType === 'events');

        //return () => handleSetInitialData(false);
    }, [eventScreenType, handleSetInitialData]);

    useEffect(() => {
        handleSelectAll([]);
        handleClearEvents();

        if (session?.user.profile !== userProfile.analyst) {
            handleUpdate();
        }
    }, [handleClearEvents, handleSelectAll, handleUpdate, session?.user.profile]);

    useEventListener(document.body, 'keydown', handleClickOnEventButton);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('menu.processing_alarms')}</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>

                <Row align="center" gap={16}>
                    <ChangeLayout />
                    <Button
                        ref={eventButtonRef}
                        type="button"
                        leftIcon={eventButtonProps?.icon}
                        disabled={selectedEvents.length === 0}
                        onClick={handleEventDialog}
                        padding="0 47px"
                    >
                        {eventButtonProps?.label}
                    </Button>
                </Row>
            </Row>

            {eventScreenType === 'pending' && session?.user.profile === userProfile.analyst && <ActivityCard />}

            <AlarmsContainer />

            <AlarmDetailsDialog id="alarm-details-dialog" tabInfoLabel={eventButtonProps?.label} />
        </Column>
    );
}
