import { styled as mui, Modal as MuiModal } from '@mui/material';
import styled from 'styled-components';

export const Modal = mui(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Card = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;

    width: 656px;

    background: #ffffff;
    border-radius: 8px;

    .pr {
        padding-right: 8px;
    }
`;

export const Title = styled.h4`
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;

    color: #262d3c;
    margin: 0;
`;
