import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, FilterButton, RenderIf, TypeSearch } from 'src/common';
import {
    Button,
    CardHeader,
    Column,
    Content,
    Divider,
    Export,
    MainCard,
    NoContent,
    Pagination,
    Row,
    SelectNumber,
    Skeleton,
    Title,
    UsedFilters,
} from 'src/common/components';
import { ObjectHelper } from 'src/common/helpers';
import { useEventListener, usePermission, useSearch } from 'src/common/hooks';
import { CreateOrUpdateVehicleDialog, TableList, TimeProgress, VehicleFilters } from 'src/modules/configurations/vehicles/components';
import { defaultValues, getDefaultCreateOrUpdateValues } from 'src/modules/configurations/vehicles/contexts/default-form-values';
import { ListVehicleFilterDTO } from 'src/modules/configurations/vehicles/dtos';
import { useListVehicles, useReport } from 'src/modules/configurations/vehicles/hooks';
import { useVehicles } from 'src/modules/configurations/vehicles/hooks/use-vehicles';
import { vehicleTypeService } from 'src/modules/configurations/vehicles/services/vehicle-type.service';

interface IsOpenProps {
    createOrUpdateDialog: boolean;
    filter: boolean;
}

const IS_OPEN_INITIAL_STATE: IsOpenProps = {
    createOrUpdateDialog: false,
    filter: false,
};

export default function Vehicles() {
    const { data, isError, isLoading, mutate, isSuccess } = useListVehicles();
    const { mutate: getReport, isLoading: reportIsLoading } = useReport();
    const {
        filter: request,
        setVehicles,
        setFilter,
        setInitialFormData,
        handleSelectAll,
        selectedVehicles,
        getVehicleTypes,
        setVehicleTypes,
    } = useVehicles();
    const { formRef, clear, inputRef } = useSearch();
    const { isAdmin } = usePermission();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<IsOpenProps>(IS_OPEN_INITIAL_STATE);
    const [vehicleDialogIsOnlyView, setVehicleDialogIsOnlyView] = useState(false);

    const controller = useMemo(() => new AbortController(), []);

    const handleClearFilterValue = useCallback(
        (key: keyof ListVehicleFilterDTO) => {
            setFilter(state => ({
                ...state,
                currentPage: 1,
                text: '',
                typeSearch: TypeSearch.PARAMS,
                [key]: '',
            }));
            setIsOpen(IS_OPEN_INITIAL_STATE);
            clear();
        },
        [clear, setFilter],
    );

    const handleClearFilters = useCallback(() => {
        setFilter(state => ({
            currentPage: 1,
            pageSize: state.pageSize,
            deviceState: '',
            fleet: '',
            operation: '',
            type: '',
            text: '',
            typeSearch: TypeSearch.PARAMS,
        }));
        setIsOpen(IS_OPEN_INITIAL_STATE);
        clear();
    }, [clear, setFilter]);

    const handleGenerateReport = useCallback(() => {
        getReport({
            signal: controller.signal,
            ids: selectedVehicles?.map(item => item.id) || undefined,
            ...request,
        });

        return () => controller.abort();
    }, [controller, getReport, request, selectedVehicles]);

    const handleList = useCallback(() => {
        mutate(request);
    }, [mutate, request]);

    const handleOpenVehicleDialog = useCallback((onlyView: boolean) => {
        setVehicleDialogIsOnlyView(onlyView);
        setIsOpen(state => ({ ...state, filter: false, createOrUpdateDialog: !state.createOrUpdateDialog }));
    }, []);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();
            setIsOpen(IS_OPEN_INITIAL_STATE);
            handleSelectAll([]);
            setFilter({ ...defaultValues, text: inputRef?.current?.value, typeSearch: TypeSearch.TEXT });
        },
        [handleSelectAll, inputRef, setFilter],
    );

    const content: JSX.Element = useMemo(() => {
        if (isLoading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        }

        if (isError) {
            return <NoContent message={t('general.error')} messageClassName="error" onClick={handleList} />;
        }

        if (data?.elements?.length === 0) {
            return <NoContent message={t('general.no_content')} onClick={handleList} />;
        }

        return <TableList onEdit={() => handleOpenVehicleDialog(false)} onView={() => handleOpenVehicleDialog(true)} />;
    }, [data?.elements?.length, handleList, handleOpenVehicleDialog, isError, isLoading, t]);

    const usedFilters = useMemo(
        () => ({
            type: request?.type ? getVehicleTypes(t).find(type => type.value === request?.type)?.label : request?.type,
            fleet: request?.fleet,
            operation: request?.operation,
        }),
        [request?.type, request?.fleet, request?.operation, getVehicleTypes, t],
    );

    useEffect(() => {
        setVehicleTypes({
            type: 'loading',
        });
        vehicleTypeService
            .listAll({
                signal: controller.signal,
            })
            .then(res =>
                setVehicleTypes({
                    type: 'data',
                    payload: res,
                }),
            )
            .catch(() =>
                setVehicleTypes({
                    type: 'error',
                }),
            );
    }, [controller.signal, setVehicleTypes]);

    useEffect(handleList, [handleList]);

    useEffect(() => {
        return () => {
            controller.abort();
            handleSelectAll([]);
            setFilter(defaultValues);
            setVehicles(null);
            clear();
        };
    }, [clear, controller, handleSelectAll, setFilter, setVehicles]);

    useEventListener(formRef.current, 'submit', handleSearch);

    return (
        <Column gap={16}>
            <Row gap={16} align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('general.configurations')}</h2>
                </Title>
                <RenderIf condition={isAdmin}>
                    <Row gap={32}>
                        <Button
                            type="button"
                            size="medium"
                            padding="0 12px"
                            leftIcon={<TimeProgress />}
                            onClick={() => handleOpenVehicleDialog(false)}
                        >
                            {t('configurations.create_vehicle')}
                        </Button>
                    </Row>
                </RenderIf>
            </Row>
            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>
                                {t('configurations.vehicles')}
                                <RenderIf condition={isSuccess && !isLoading}>
                                    <span className="total">({data?.totalElements || 0})</span>
                                </RenderIf>
                            </h3>
                        </Row>
                        <Row align="center" gap={16} style={{ minWidth: '265px' }}>
                            {reportIsLoading ? <CircularProgress color="#262D3C" /> : <Export onClick={handleGenerateReport} />}
                            <Row align="center" gap={8}>
                                <span className="per-page">{t('general.items_per_page')}:</span>
                                <SelectNumber
                                    fontMode="small"
                                    value={request?.pageSize}
                                    options={[15, 25, 50]}
                                    onChange={e => setFilter(state => ({ ...state, pageSize: Number(e.target.value), currentPage: 1 }))}
                                />
                            </Row>
                            <FilterButton
                                isOpen={isOpen.filter}
                                onClick={() => setIsOpen(state => ({ ...state, filter: !state.filter, createOrUpdateDialog: false }))}
                            />
                        </Row>
                    </CardHeader>
                    <Divider />
                    <RenderIf condition={isOpen.filter}>
                        <VehicleFilters onPostSubmit={() => setIsOpen(IS_OPEN_INITIAL_STATE)} />
                    </RenderIf>
                    <RenderIf condition={!isLoading && !ObjectHelper.isEmpty(usedFilters as any)}>
                        <UsedFilters data={usedFilters} onClear={handleClearFilterValue} onClearAll={handleClearFilters} />
                    </RenderIf>
                    {content}
                    <Divider />
                    <RenderIf condition={!!data?.totalElements}>
                        <Pagination
                            currentPage={request.currentPage}
                            onPageChange={currentPage => setFilter(state => ({ ...state, currentPage }))}
                            perPage={request.pageSize}
                            total={data?.totalElements}
                        />
                    </RenderIf>
                </Column>
            </MainCard>
            <RenderIf condition={isOpen.createOrUpdateDialog}>
                <CreateOrUpdateVehicleDialog
                    open={isOpen.createOrUpdateDialog}
                    onlyView={vehicleDialogIsOnlyView}
                    onClose={() => {
                        setIsOpen(IS_OPEN_INITIAL_STATE);
                        setInitialFormData(getDefaultCreateOrUpdateValues(null));
                    }}
                />
            </RenderIf>
        </Column>
    );
}
