import { createContext, useCallback, useMemo, useReducer, useState } from 'react';
import { DateHelper, FunctionComponent, ResponseStatus } from 'src/common';
import { Reducer, reducer } from 'src/common/types/reducer';
import { DepartmentResume } from 'src/modules/configurations';

import { GenericAnalyticsFilterDTO } from '../../common/dtos/generic-filter.dto';
import { INITIAL_OPERATION_STATE } from '../../fleets/context/const';
import { overviewService } from '../../services/overview.service';
import { EventByOverviewDTO, OverviewDataDTO, RankingOverviewDTO } from '../dtos/overview.dto';
import { OverviewContextProps } from './types';

export const OverviewContext = createContext({} as OverviewContextProps);

export const INIT_FILTER_STATE: GenericAnalyticsFilterDTO = {
    finalDate: new Date(),
    initialDate: DateHelper.yesterday(),
    operationInput: '',
    operations: [],
};

export function OverviewsProvider({ children }: FunctionComponent) {
    const [filter, setFilter] = useState<GenericAnalyticsFilterDTO>(INIT_FILTER_STATE);
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_OPERATION_STATE);
    const [listOverview, setListOverview] = useState<Array<OverviewDataDTO>>([]);
    const [listEventsByFleet, setListEventsByFleet] = useState<Array<EventByOverviewDTO> | null>(null);

    const [requestData, setRequestData] = useState<RankingOverviewDTO>({
        dateFrom: DateHelper.yesterday(),
        dateTo: new Date(),
        equipament: 'DS',
    });

    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: true,
        success: false,
        void: false,
    });

    const handleRequestList = useCallback((data: Partial<RankingOverviewDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleFilterIsOpen = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const handleGetListHeatMap = useCallback(async () => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const data = await overviewService.execute({
                ...requestData,
                equipament: requestData.equipament === 'Telemetria' ? 'Geotab' : requestData.equipament,
            });

            setListOverview(data);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: false,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const reset = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(true);
        setOperations({
            type: 'data',
            payload: [],
        });
    }, []);

    const data: OverviewContextProps = useMemo(
        () => ({
            filter,
            setFilter,
            filterIsOpen,
            handleFilterIsOpen,
            operations,
            setOperations,
            reset,
            listEventsByFleet,
            setListEventsByFleet,
            handleGetListHeatMap,
            listOverview,
            responseStatus,
            handleRequestList,
        }),
        [
            filter,
            filterIsOpen,
            handleFilterIsOpen,
            handleGetListHeatMap,
            handleRequestList,
            listEventsByFleet,
            listOverview,
            operations,
            reset,
            responseStatus,
        ],
    );

    return <OverviewContext.Provider value={data}>{children}</OverviewContext.Provider>;
}
