import { Fragment } from 'react';
import { Column, Dialog } from 'src/common/components';

import { useFunctions } from '../../hooks';
import FunctionDialogForm from '../function-dialog-form';

export default function FunctionDialog() {
    const { functionDialogRef, dialogIsOpen, handleOpenDialog } = useFunctions();

    return (
        <Dialog onCloseModal={handleOpenDialog} ref={functionDialogRef} id="function-dialog">
            <Column width="656px">{dialogIsOpen ? <FunctionDialogForm /> : <Fragment />}</Column>
        </Dialog>
    );
}
