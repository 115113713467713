import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EditFill, Eye, RenderIf, Row, Table, TableOptionButton, Trash } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { DateHelper } from 'src/common/helpers';
import { usePermission } from 'src/common/hooks';

import { EventExpiresIn } from '../../domain/constants/event-expires-in';
import { ElectronicFence } from '../../domain/entities/electronic-fence';
import { useElectronicFence } from '../../hooks';

const getFinalDate = (expiresIn: EventExpiresIn, lang: string, date?: Date | null): string =>
    date ? DateHelper.formatSimple(date, lang, 'UTC') : `monitoring.event_expires_in.${expiresIn.toLocaleLowerCase()}`;

export default function TableList() {
    const { electronicFences, setIsOpen, setFormData, map } = useElectronicFence();
    const { has } = usePermission();
    const { t, i18n } = useTranslation();

    const handleDelete = useCallback(
        (data: ElectronicFence) => {
            setFormData(data);
            setIsOpen(state => ({ ...state, filter: false, delete: true }));
        },
        [setFormData, setIsOpen],
    );

    const handleEdit = useCallback(
        (data: ElectronicFence) => {
            map?.current?.setZoom(data?.mapZoom || 12);
            map?.current?.setCenter(data?.coordinates[0]);
            setFormData(data);
            setIsOpen(state => ({ ...state, filter: false, createOrUpdate: true, map: false }));
        },
        [map, setFormData, setIsOpen],
    );

    const tableHeaders = useMemo(
        () => [
            t('ID'),
            t('general.name'),
            t('alarms.operation'),
            t('monitoring.generate_event_by'),
            t('configurations.creation_date'),
            t('monitoring.expires_in'),
            t('configurations.status'),
            <Fragment key="options" />,
        ],
        [t],
    );

    return (
        <Table tableHeaders={tableHeaders}>
            {electronicFences?.elements?.map(item => {
                return (
                    <TableRow className="body" key={item?.id}>
                        <TableData>{item?.id}</TableData>
                        <TableData className="break">{item?.name}</TableData>
                        <TableData>{item?.operation || '---'}</TableData>
                        <TableData>{t(`general.${item?.generateEventBy.toLowerCase()}`)}</TableData>
                        <TableData>{DateHelper.formatSimple(item?.createdAt, i18n.language, 'UTC')}</TableData>
                        <TableData>{t(getFinalDate(item.expiresIn, i18n.language, item.expirationDate))}</TableData>
                        <TableData>{t(`general.status.${item?.status}`)}</TableData>
                        <TableData style={{ paddingRight: '16px', width: '80px' }}>
                            <Row gap={16}>
                                <RenderIf condition={!has('electronic_fence') && has('vehicle_monitoring')}>
                                    <TableOptionButton type="button" onClick={() => handleEdit(item)}>
                                        <Eye color="#46526C" />
                                    </TableOptionButton>
                                </RenderIf>
                                <RenderIf condition={has('electronic_fence')}>
                                    <Fragment>
                                        <TableOptionButton type="button" onClick={() => handleEdit(item)}>
                                            <EditFill color="#46526C" />
                                        </TableOptionButton>
                                        <TableOptionButton type="button" onClick={() => handleDelete(item)}>
                                            <Trash />
                                        </TableOptionButton>
                                    </Fragment>
                                </RenderIf>
                            </Row>
                        </TableData>
                    </TableRow>
                );
            })}
        </Table>
    );
}
