import { State } from 'src/common/types/reducer';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { DepartmentResume } from 'src/modules/configurations';

import { Vehicle } from '../domain/entities/vehicle';
import { LastOnlineData } from '../dtos/last-online-data';

export const INITIAL_DEPARTMENT_STATE: State<Array<DepartmentResume>> = {
    data: [],
    hasError: false,
    isLoading: true,
};

export const INITIAL_VEHICLES_STATE: State<Array<Vehicle>> = {
    data: [],
    hasError: false,
    isLoading: true,
};

export const INITITAL_VEHICLE_LAST_EVENTS_STATE: State<Array<EventDTO>> = {
    data: [],
    hasError: false,
    isLoading: true,
};

export const INITIAL_LAST_ONLINE_DATA_STATE: State<LastOnlineData | null> = {
    data: null,
    hasError: false,
    isLoading: true,
};
