export class TransformRequestHelper {
    public static currentPageToStart(page?: number, pageSize?: number) {
        if (!page || !pageSize) return 0;

        return (page - 1) * pageSize;
    }

    public static startToCurrentPage(start: number, pageSize: number) {
        return start / pageSize + 1;
    }
}
