import { Locale } from 'date-fns';
import * as dateFNSLocales from 'date-fns/locale';
import { forwardRef, useMemo } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import langs from 'src/common/infra/translations/locales';
import { LanguageTranslations } from 'src/common/infra/translations/types/translations';
import { FeedbackProps } from 'src/common/types';

import Feedback from '../feedback';
import { Calendar } from '../icons';
import { Container, Label } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

interface Locales {
    [key: string]: Locale;
}

interface DatePickerProps extends ReactDatePickerProps<never, true> {
    feedback?: FeedbackProps;
    label?: string;
    multiple?: boolean;
    name: string;
    placeholder: string;
}

export default forwardRef<ReactDatePicker, DatePickerProps>(function DatePicker({ feedback, multiple, placeholder, label, ...props }, ref) {
    const { i18n } = useTranslation();

    const locales: Locales = useMemo(() => dateFNSLocales as Locales, []);
    const selectedLocale: LanguageTranslations = useMemo(() => langs[i18n.language] as LanguageTranslations, [i18n.language]);

    const feedbackComponent = useMemo(() => {
        if (feedback?.message) {
            return <Feedback {...feedback} />;
        }

        return null;
    }, [feedback]);

    const locale: Locale = useMemo(() => {
        if (i18n.language) {
            if (Object.keys(locales).includes(i18n.language)) {
                return locales[i18n.language];
            }

            if (Object.keys(locales).includes(i18n.language?.replace('-', ''))) {
                return locales[i18n.language?.replace('-', '')];
            }

            if (Object.keys(locales).includes(i18n.language?.split('-')[0])) {
                return locales[i18n.language?.split('-')[0]];
            }

            return locales.ptBR;
        }

        return locales.ptBR;
    }, [i18n.language, locales]);

    return (
        <Label htmlFor={props.name}>
            {label && <span className="date-picker-label">{label}</span>}
            <Container className="date-picker-container">
                <Calendar className="icon" color={label ? '#A9A9A9' : '#46526C'} />
                <ReactDatePicker
                    autoComplete="off"
                    className="date-picker"
                    dateFormat={selectedLocale?.dateFormat}
                    id={props.name}
                    isClearable
                    clearButtonClassName="clear-date-pciker"
                    locale={locale}
                    placeholderText={placeholder}
                    selectsRange={multiple}
                    showMonthDropdown
                    showYearDropdown
                    ref={ref}
                    {...props}
                />
            </Container>
            {feedbackComponent}
        </Label>
    );
});
