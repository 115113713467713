import { Divider, Modal } from '@mui/material';
import { addDays, subDays } from 'date-fns';
import moment from 'moment';
import { FocusEvent, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import { BiSolidConversation } from 'react-icons/bi';
import { IoEyeOutline } from 'react-icons/io5';
import { io } from 'socket.io-client';
import {
    ASCII,
    ChevronDown,
    Column,
    Content,
    DateHelper,
    DatePicker,
    FilterAlt,
    Pagination,
    Row,
    Select,
    SelectNumber,
    Skeleton,
    Table,
    Title,
    api,
    listPagination,
    useEventListener,
    useSearch,
} from 'src/common';
import { Button } from 'src/common/components';
import { env } from 'src/config/env';
import { SortProps } from 'src/modules/alarm-processing/dtos/event.dto';
import { authService, useAuth } from 'src/modules/auth';
import { InterventionBadge } from 'src/modules/interventions/components/intervention-badge';
import InterventionFilter from 'src/modules/interventions/components/intervention-filter';
import Cookies from 'universal-cookie';

import UpdateData from '../../common/components/update-data';
import { Pagination as PaginationRequest } from '../../common/core/pagination';
import { refreshTokenService } from '../../common/services/refresh-token.service';
import { ENV } from '../../config/consts';
import { FilterNamePropsEvents } from '../../modules/alarm-processing/components/alarms-container';
import { ChatListStatus, InterventionRequestDTO } from '../../modules/interventions/dtos/intervention.dto';
import { interventionByIdService } from '../../modules/interventions/services/interventions-by-id.service';
import { interventionService } from '../../modules/interventions/services/interventions.service';
import {
    ButtonContainer,
    CheckIconButton,
    CommentsContainer,
    IconButton,
    InterventionsInformationsContainer,
    ListMessagesContainer,
    ListMessagesContent,
    ListMessagesForm,
    MainMenu,
    ModalContentInfo,
    ModalContentInfoContainer,
    PopupContainer,
    SituationContainer,
    SpacingBlankContainer,
    TableData,
    TableRow,
} from './styles';

interface DataTable {
    id: number;
    operation: string;
    fleet: string;
    totalEvent: number;
    startAlarm: Date;
    endAlarm: Date;
    status: string;
    events: any[];
    event: string;
}

interface Comments {
    idConversation: number | undefined;
    idUser: number | undefined;
    sender: string | undefined | null;
    message: string;
    hourMessage?: string | undefined;
    status: string;
    isPerson: boolean;
}

export function Interventions() {
    const { t, i18n } = useTranslation();
    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const [open, setOpen] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [isLoading, setIsLoading] = useState(false);

    const [startDate, setStartDate] = useState<Date | null>(DateHelper.yesterday);
    const [endDate, setEndDate] = useState<Date | null>(new Date());

    const [interventions, setInterventions] = useState<PaginationRequest<InterventionRequestDTO> | null>(null);
    const [sortElements, setSortElements] = useState<SortProps[]>([]);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
    const [comments, setComments] = useState<Comments[]>([]);
    const [comment, setComment] = useState('');

    const [selectedRow, setSelectedRow] = useState<DataTable>();
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);
    const myRef = useRef<HTMLDivElement>(null);
    const [fleet, setFleet] = useState('');
    const [operation, setOperation] = useState('');
    const [searchTextBar, setSearchTextBar] = useState('');

    const [names, setNames] = useState<FilterNamePropsEvents[]>([]);

    const [chatListStatus, setChatListStatus] = useState<ChatListStatus[]>([]);

    const [hours, setHours] = useState({
        startHour: '00:00:00',
        endHour: '23:59:59',
    });

    const [selectedValue, setSelectedValue] = useState('');
    const [actualStatus, setActualStatus] = useState('');

    moment.updateLocale(i18n.language, {
        relativeTime: {
            future: t('hours.in') + ' %s',
            past: '%s ' + t('hours.ago'),
            s: t('hours.secondsAgo').toString(),
            ss: '%d ' + t('hours.seconds'),
            m: t('hours.aMinute').toString(),
            mm: '%d ' + t('hours.minute'),
            h: '1 ' + t('hours.hour'),
            hh: '%d ' + t('hours.hours'),
            d: t('hours.oneDay').toString(),
            dd: '%d ' + t('hours.days'),
            w: t('hours.oneWeek').toString(),
            ww: '%d ' + t('hours.weeks'),
            M: '1 ' + t('hours.monthAgo'), //change this for month
            MM: '%d ' + t('hours.months'),
            y: t('hours.aYear').toString(),
            yy: '%d ' + t('hours.years'),
        },
    });

    const handleSelectChange = (selectedOption: any) => {
        setSelectedValue(selectedOption.target.value as string);
    };

    const cookies = useMemo(() => new Cookies(), []);

    const socket = useMemo(() => {
        const token = cookies.get('jose');

        if (!token) document.location.reload();

        return io(env.WEB_SOCKET_URL, {
            query: {
                token,
            },
        });
    }, [cookies]);

    const refreshToken = useCallback(
        async (err: Error) => {
            if (err.message === 'TOKEN_EXPIRED_ERROR') {
                authService.getTokens().then(({ jose }) => {
                    if (!jose) return authService.signOut();
                    const cookies = new Cookies();
                    refreshTokenService
                        .execute(jose)
                        .then(response => {
                            const { content } = response.data;
                            localStorage.setItem(`@session-${ENV}`, JSON.stringify(content));

                            cookies.set('jose', content.token, { path: '/', secure: true, sameSite: 'none' });

                            if (socket.io.opts.query) socket.io.opts.query.token = content.token;

                            api.defaults.headers.jose = content.token;
                        })
                        .catch(() => {
                            cookies.remove('jose');
                            authService.signOut();
                        });
                });
            }
        },
        [socket.io.opts.query],
    );

    useEffect(() => {
        socket.on('connect_error', err => {
            refreshToken(err);
        });

        return () => {
            socket.removeAllListeners();
            socket.disconnect();
        };
    }, [refreshToken, socket]);

    const { formRef, inputRef } = useSearch();

    const { session } = useAuth();

    const situationOptions = useMemo(() => {
        return [
            {
                value: 'Pendente',
                label: t('interventions.pending'),
            },
            {
                value: 'Em tratativa',
                label: t('interventions.in_negotiation'),
            },
            {
                value: 'Fechado',
                label: t('interventions.closed'),
            },
        ];
    }, [t]);

    const maxDate = useMemo(() => (startDate ? addDays(startDate, 30) : new Date()), [startDate]);
    const minDate = useMemo(() => (endDate ? subDays(endDate, 30) : undefined), [endDate]);

    const handleChangeDates = useCallback((dates: [Date, Date]) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
    }, []);

    const handleChangeDatesRaw = useCallback((event: FocusEvent<HTMLInputElement, Element>) => {
        event.preventDefault();
    }, []);

    const handleOpen = async (data: any) => {
        const response = await interventionByIdService.execute({
            interventionId: data.id,
        });

        openSelectedRow(data);
        setSelectedRow(response);
        setComment('');

        setOpen(true);
    };

    const handleClose = () => {
        socket.emit('leave_room', selectedRow?.id);
        setComment('');
        setComments([]);
        setOpen(false);
        retrieveAllConversations();
    };

    const retrieveAllConversations = useCallback(async () => {
        socket.emit('join_interventions_page');
        socket.on('retrive_all_conversations', (data: any) => {
            const allConversations: ChatListStatus[] = [];
            if (data) {
                data.map((conversation: any) => {
                    allConversations.push({
                        idIntervention: conversation._id,
                        status: conversation.messages[conversation.messages.length - 1].status,
                    });
                });
                setChatListStatus(allConversations);
            }
        });
    }, [socket]);

    useEffect(() => {
        retrieveAllConversations();
    }, [retrieveAllConversations]);

    const handleOpenFilters = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const getInterventions = useCallback(
        async (e?: SortProps[], textSearch?: string, page?: number) => {
            let sortProps: SortProps[] = sortElements;
            let searchText = searchTextBar;

            if (e !== undefined) {
                sortProps = e;
            }

            if (textSearch !== undefined) {
                searchText = textSearch;
            }

            if (startDate && endDate) {
                try {
                    setIsLoading(true);
                    const response = await interventionService.execute({
                        dateFrom: startDate,
                        dateTo: endDate,
                        currentPage: page ?? currentPage,
                        pageSize,
                        sortProps,
                        operation,
                        fleet,
                        events: names?.filter(name => name.checked).map(obj => obj.languagePtbr),
                        textSearch: searchText,
                        startHour: hours.startHour,
                        endHour: hours.endHour,
                    });

                    setInterventions(response);
                } catch (error: any) {
                    setInterventions(null);
                } finally {
                    setIsLoading(false);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [startDate, endDate, currentPage, pageSize, hours],
    );

    const handleNormalizeInput = useCallback((e: Event) => {
        const event = e as unknown as FormEvent<HTMLInputElement>;

        event.currentTarget.value = ASCII.format(event.currentTarget.value);
    }, []);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();

            const { target } = e;

            const currentTarget = target as unknown as HTMLFormElement;

            const text: HTMLInputElement = currentTarget?.elements?.namedItem('text') as HTMLInputElement;
            setSearchTextBar(text.value);
            getInterventions(sortElements, text.value);
        },
        [getInterventions, sortElements],
    );

    useEventListener(formRef.current, 'submit', handleSearch);
    useEventListener(inputRef.current, 'input', handleNormalizeInput);

    document?.getElementById('text')?.addEventListener('search', function (event: any) {
        if (event.type === 'search') {
            if (event.currentTarget.value === '') {
                setSearchTextBar('');
            }
        }
    });

    useEffect(() => {
        setCurrentPage(1);
    }, [startDate, endDate]);

    const handleUpdate = useCallback(async () => {
        setHours({
            startHour: '00:00:00',
            endHour: '23:59:59',
        });

        getInterventions();
    }, [getInterventions]);

    const tableHeaders = useMemo(
        () => [
            'ID',
            t('general.operation'),
            t('general.fleet'),
            t('alarms.alarm_time'),
            t('alarms.event'),
            t('monitoring.lat'),
            t('monitoring.long'),
            t('interventions.status'),
        ],
        [t],
    );

    const tableHeadersPopup = useMemo(
        () => [
            t('interventions.media'),
            t('alarms.event'),
            t('interventions.speed'),
            t('interventions.hour'),
            t('interventions.lat'),
            t('interventions.long'),
        ],
        [t],
    );

    const handleIconClick = useCallback(() => {
        if (actualStatus != selectedValue) {
            const newMessageBot: Comments = {
                idConversation: selectedRow?.id,
                sender: session?.user.name,
                message: 'mudou para status',
                idUser: session?.user.id,
                status: selectedValue,
                isPerson: false,
            };
            socket.emit('chat_message', newMessageBot);
            setComments(state => [...state, newMessageBot]);
            setActualStatus(selectedValue);
        }
    }, [actualStatus, selectedRow?.id, selectedValue, session?.user.id, session?.user.name, socket]);

    const sendMessage = useCallback(
        (comment: Comments) => {
            const interventionId = selectedRow?.id;

            socket.emit('chat_message', {
                idConversation: interventionId,
                sender: comment.sender,
                message: comment.message,
                idUser: comment.idUser,
                status: comment.status,
                isPerson: true,
            });
        },
        [selectedRow?.id, socket],
    );

    useEffect(() => {
        socket.on('chatting', (data: Comments) => {
            if (data.idUser !== session?.user.id) {
                setComments(state => [
                    ...state,
                    {
                        idConversation: data.idConversation,
                        sender: data.sender,
                        message: data.message,
                        idUser: data.idUser,
                        isPerson: data.isPerson,
                        hourMessage: data.hourMessage,
                        status: data.status,
                    },
                ]);
            }
        });
    }, [session?.user.id, socket]);

    const handleNewComment = useCallback(
        (event: FormEvent) => {
            event.preventDefault();

            if (comment === '') {
                return;
            }

            const addNewComment: Comments = {
                idConversation: selectedRow?.id,
                message: comment,
                sender: session?.user.name,
                idUser: session?.user.id,
                status: selectedValue,
                isPerson: true,
            };
            sendMessage(addNewComment);

            setComments([...comments, addNewComment]);
            setComment('');
        },
        [comment, comments, selectedRow?.id, selectedValue, sendMessage, session?.user.id, session?.user.name],
    );

    useEffect(() => {
        if (myRef.current) {
            myRef.current.scrollIntoView();
            myRef.current.focus();
        }
    }, [comments, open]);

    const handleChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    const handleSelectEvent = (eventIndex: any) => {
        setSelectedRowIndex(eventIndex);
    };

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleNewComment(event);
            }
        },
        [handleNewComment],
    );

    const modalPopupBase: JSX.Element = useMemo(() => {
        return (
            <>
                <InterventionsInformationsContainer>
                    <div>
                        <p style={{ fontSize: '1.625rem' }}>{t('interventions.interventions_informations')}</p>
                    </div>
                </InterventionsInformationsContainer>

                <ModalContentInfoContainer>
                    <div className="image">
                        {selectedRow && selectedRow?.events[selectedRowIndex]?.videoUrl ? (
                            <video
                                src={selectedRow.events[selectedRowIndex].videoUrl}
                                height={'100%'}
                                width={'100%'}
                                style={{ borderRadius: '1rem' }}
                                autoPlay
                                muted
                                controls
                                loop
                            ></video>
                        ) : (
                            <img
                                src={selectedRow ? selectedRow.events[selectedRowIndex].photoUrl : ''}
                                height={'100%'}
                                width={'100%'}
                                style={{ borderRadius: '1rem' }}
                            ></img>
                        )}
                    </div>
                    <ModalContentInfo>
                        <p>{t('interventions.informations')}</p>

                        <div>
                            <label className="spanTable">ID:</label>
                            <span>{selectedRow?.id}</span>
                        </div>

                        <div>
                            <label className="spanTable">{t('general.operation')}:</label>
                            <span>{selectedRow?.operation}</span>
                        </div>
                        <div>
                            <label className="spanTable">{t('general.fleet')}:</label>
                            <span>{selectedRow?.fleet}</span>
                        </div>
                        <div>
                            <label className="spanTable">{t('interventions.event')}:</label>
                            <span>{selectedRow?.event}</span>
                        </div>
                        <div>
                            <label className="spanTable">{t('interventions.qtd_events')}</label>
                            <span>{selectedRow?.totalEvent}</span>
                        </div>
                        <div>
                            <label className="spanTable">{t('interventions.hour_initial')}</label>
                            <span>{DateHelper.format(selectedRow?.startAlarm, i18n.language)}</span>
                        </div>
                        <div>
                            <label className="spanTable">{t('interventions.hour_final')}</label>
                            <span>{DateHelper.format(selectedRow?.endAlarm, i18n.language)}</span>
                        </div>
                        <div>
                            <label className="spanTable">{t('interventions.interventions_situation')}</label>
                            <span>{t(`interventions.${actualStatus}`)}</span>
                        </div>
                    </ModalContentInfo>
                </ModalContentInfoContainer>
            </>
        );
    }, [actualStatus, i18n.language, selectedRow, selectedRowIndex, t]);

    const openSelectedRow = async (data: any) => {
        const interventionId = data?.id;

        // Join the conversation room with the given ID
        socket.emit('join', {
            conversationId: interventionId,
            userName: session?.user.name,
            idUser: session?.user.id,
        });

        socket.on('retrieve_pasts_messages', (data: any) => {
            const pastMessages: Comments[] = [];
            if (data) {
                data.messages.map((message: Comments) => {
                    pastMessages.push({
                        message: message.message,
                        hourMessage: message.hourMessage,
                        idConversation: message.idConversation,
                        idUser: message.idUser,
                        sender: message.sender,
                        status: message.status,
                        isPerson: message.isPerson,
                    });
                });
            }
            setComments(pastMessages);
            if (pastMessages.length > 0) {
                setSelectedValue(pastMessages[pastMessages.length - 1].status as string);
                setActualStatus(pastMessages[pastMessages.length - 1].status as string);
            } else {
                setSelectedValue('Pendente');
                setActualStatus('Pendente');
            }
        });
    };

    const chatBody: JSX.Element = useMemo(() => {
        return (
            <div style={{ margin: '1rem 0 0 0', width: '90%' }}>
                <ButtonContainer>
                    <IconButton onClick={handleClose} className="closeButton">
                        <AiOutlineClose size={40} style={{ outline: 'active' }} />
                    </IconButton>
                </ButtonContainer>
                <SituationContainer>
                    <Select
                        name="Situation"
                        options={situationOptions}
                        label={t('interventions.status').toString()}
                        placeholder={t('interventions.status').toString()}
                        onChange={handleSelectChange}
                        value={selectedValue}
                    />
                    <CheckIconButton className="checkButton" onClick={handleIconClick}>
                        <AiOutlineCheck size={24} style={{ outline: 'active' }} />
                    </CheckIconButton>
                </SituationContainer>
                <SpacingBlankContainer />
                <CommentsContainer>
                    <ListMessagesContainer>
                        <ListMessagesContent>
                            {comments.map((comment, i) => {
                                if (comment.idConversation !== selectedRow?.id) {
                                    return null;
                                }

                                if (!comment.isPerson) {
                                    return (
                                        <div key={comment.message + i} className="commentContainerCenter" ref={myRef}>
                                            <div className={'commentSectionCenter'}>
                                                <span>
                                                    <span style={{ color: '#6DA4D3' }}>{comment.sender} </span>
                                                    <span className="spanComment">
                                                        {comment.isPerson ? comment.message : t(`interventions.${comment.message}`)}
                                                    </span>
                                                    <span className="spanComment"> {t(`interventions.${comment.status}`)}</span>
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontSize: '11px' }}>{moment.utc(comment.hourMessage).locale('pt-br').fromNow()}</span>
                                            </div>
                                        </div>
                                    );
                                }
                                return (
                                    <div key={comment.message + i} className="commentContainerLeft" ref={myRef}>
                                        <div className="messageInformations">
                                            <span className={'senderName'}>{comment.sender}</span>
                                            <span className={'senderMessage'}>
                                                {moment.utc(comment.hourMessage).locale('pt-br').startOf('seconds').fromNow()}
                                            </span>
                                        </div>
                                        <div className={'commentSectionLeft'}>
                                            <span>{comment.message}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </ListMessagesContent>

                        <Divider />

                        <ListMessagesForm onSubmit={handleNewComment}>
                            <textarea
                                id="comment"
                                name="comment"
                                className="textArea"
                                placeholder={String(t('interventions.type_comment'))}
                                value={comment}
                                onKeyDown={handleKeyDown}
                                onChange={event => handleChangeTextarea(event)}
                                maxLength={100}
                                rows={4}
                            />

                            <button className={'commentButton'} type="submit">
                                <BiSolidConversation size={30} color={'#6DA4D3'} />
                            </button>
                        </ListMessagesForm>
                    </ListMessagesContainer>
                </CommentsContainer>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleNewComment, comments, selectedRow?.id, selectedRow?.status, session?.user.name, situationOptions, t]);

    const alarmBody: JSX.Element = useMemo(() => {
        return (
            <div style={{ margin: '1rem', height: '70%', width: '93%' }}>
                {modalPopupBase}
                <Divider style={{ margin: '1rem 0', width: '100%' }} />

                <Table tableHeaders={tableHeadersPopup} styleContainer={{ height: '27rem', overflow: 'auto', width: '100%' }} allowSort={false}>
                    {selectedRow?.events.map((event, index) => {
                        return (
                            <TableRow className="body pointer" key={event.id} onClick={() => handleSelectEvent(index)}>
                                <TableData width={'13%'}>
                                    {event.videoUrl ? (
                                        <video src={event.videoUrl} height={120} width={150} autoPlay muted controls loop></video>
                                    ) : (
                                        <img src={event.photoUrl} height={120} width={150}></img>
                                    )}
                                </TableData>
                                <TableData width={'10%'} style={{ fontSize: 12 }}>
                                    {t(`tableEventName.${event.event}`)}
                                </TableData>
                                <TableData width={'10%'} style={{ fontSize: 12 }}>
                                    {event.speed} km/h
                                </TableData>
                                <TableData width={'13%'} style={{ fontSize: 12 }}>
                                    {DateHelper.format(event.alarmTime, i18n.language)}
                                </TableData>
                                <TableData width={'10%'} style={{ fontSize: 12 }}>
                                    {event.latitude}
                                </TableData>
                                <TableData width={'10%'} style={{ fontSize: 12 }}>
                                    {event.longitude}
                                </TableData>
                            </TableRow>
                        );
                    })}
                </Table>
            </div>
        );
    }, [i18n.language, modalPopupBase, selectedRow?.events, t, tableHeadersPopup]);

    const content: JSX.Element = useMemo(() => {
        return (
            <Content className="mb-16" style={{ marginTop: '1rem' }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Content>
        );
    }, []);

    const handleChangePage = (page: number) => {
        setCurrentPage(page);
        getInterventions(sortElements, '', page);
    };

    const handleHeaderClick = useCallback(
        (event: MouseEvent, thData: string) => {
            const clickedHeader = thData;
            const isShiftKeyPressed = event.shiftKey;

            if (isShiftKeyPressed) {
                if (selectedHeaders.length < 2) {
                    // Add the clicked header to the selected headers array
                    setSelectedHeaders(prevSelectedHeaders => [...prevSelectedHeaders, clickedHeader]);
                    // Add a class to indicate selection
                }

                if (selectedHeaders.length === 1) {
                    setSelectedHeaders([]);
                }
            } else {
                setSelectedHeaders([]);
            }
        },
        [selectedHeaders],
    );

    useEffect(() => {
        getInterventions();
    }, []);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between">
                <Title>
                    <h2>Analytics</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
            </Row>
            <MainMenu>
                <div className="upMenu">
                    <div>
                        <Row gap={16} align="center">
                            <h3>
                                {t('general.interventions')}
                                {interventions?.totalElements !== undefined && <span className="total">({interventions.totalElements})</span>}
                            </h3>
                        </Row>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <span className="per-page">{t('general.items_per_page')}:</span>
                        <SelectNumber
                            fontMode="small"
                            value={pageSize || 10}
                            options={listPagination}
                            onChange={e => setPageSize(Number(e.target.value))}
                        />

                        <DatePicker
                            endDate={endDate}
                            maxDate={maxDate}
                            minDate={minDate}
                            multiple
                            name="period"
                            onChange={handleChangeDates}
                            onChangeRaw={handleChangeDatesRaw}
                            placeholder={String(t('general.select_period'))}
                            startDate={startDate}
                        />

                        <Button
                            color={filterIsOpen ? 'purple' : 'outline-purple'}
                            leftIcon={<FilterAlt color={filterIsOpen ? '#ffffff' : '#46526C'} />}
                            size="small"
                            rightIcon={<ChevronDown spin={filterIsOpen} color={filterIsOpen ? '#fff' : '#46526C'} />}
                            type="button"
                            onClick={handleOpenFilters}
                        >
                            {t('general.filter')}
                        </Button>
                    </div>
                </div>
                <div>
                    {filterIsOpen && (
                        <InterventionFilter
                            setInterventions={setInterventions}
                            startDate={startDate}
                            endDate={endDate}
                            pageSize={pageSize}
                            sortProps={sortElements}
                            names={names}
                            operation={operation}
                            setOperation={setOperation}
                            fleet={fleet}
                            setFleet={setFleet}
                            setNames={setNames}
                            setLoading={setIsLoading}
                            setHours={setHours}
                            chatListStatus={chatListStatus}
                        />
                    )}
                </div>

                <Divider />
                {isLoading ? (
                    <>{content}</>
                ) : (
                    <Table
                        tableHeaders={tableHeaders}
                        style={{ marginTop: '3rem', width: '100%' }}
                        sortData={e => getInterventions(e)}
                        allowSort={true}
                        sortElements={sortElements}
                        newSortElements={setSortElements}
                        handleHeaderClick={handleHeaderClick}
                    >
                        {interventions?.elements.map((data: any) => {
                            const findIdIntervention = chatListStatus.filter(chatStatus => chatStatus.idIntervention === data.id);
                            let statusActual = 'Pendente';
                            let styleActual: 'default' | 'primary' | 'secondary' = 'default';
                            if (findIdIntervention.length > 0) {
                                statusActual = findIdIntervention[0].status;
                                switch (findIdIntervention[0].status) {
                                    case 'Pendente':
                                        styleActual = 'default';
                                        break;
                                    case 'Em tratativa':
                                        styleActual = 'primary';
                                        break;
                                    case 'Fechado':
                                        styleActual = 'secondary';
                                        break;
                                }
                            }

                            return (
                                <TableRow className="body" key={Math.random() * data.id}>
                                    <TableData width={'8%'}>{data.id}</TableData>
                                    <TableData width={'30%'}>{data.operation}</TableData>
                                    <TableData width={'8%'}>{data.fleet}</TableData>
                                    <TableData width={'8%'}>{DateHelper.format(data.alarmTime, i18n.language)}</TableData>
                                    <TableData width={'25%'}>{t(`tableEventName.${data.event}`)}</TableData>
                                    <TableData width={'8%'}>{data.latitude}</TableData>
                                    <TableData width={'8%'}>{data.longitude}</TableData>
                                    <TableData width={'8%'}>
                                        <InterventionBadge badgeStyles={styleActual} text={statusActual} />
                                    </TableData>
                                    <TableData width={'5%'}>
                                        <Button
                                            style={{ border: 'none', color: 'none', background: 'none' }}
                                            leftIcon={<IoEyeOutline size={16} color="#46526C" />}
                                            onClick={() => handleOpen(data)}
                                        />
                                    </TableData>
                                </TableRow>
                            );
                        })}
                    </Table>
                )}

                <Divider />
                <Pagination
                    style={{ marginTop: '1rem' }}
                    onPageChange={handleChangePage}
                    currentPage={interventions?.currentPage}
                    perPage={interventions?.pageSize}
                    total={interventions?.totalElements}
                />
            </MainMenu>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <PopupContainer>
                    {alarmBody}
                    {chatBody}
                </PopupContainer>
            </Modal>
        </Column>
    );
}
