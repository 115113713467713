import { Polyline } from '@react-google-maps/api';
import { Fragment, useMemo } from 'react';
import endRoute from 'src/assets/icons/end_route.svg';
import startRoute from 'src/assets/icons/start_route.svg';
import { HistoricalRoute } from 'src/modules/alarm-processing/domain/models/historical-route';

import Mark from './mark';

interface RouteLineProps {
    historicalRoute: HistoricalRoute[];
}

export default function RouteLine({ historicalRoute }: RouteLineProps) {
    const path: google.maps.LatLngLiteral[] = useMemo<google.maps.LatLngLiteral[]>(
        () =>
            historicalRoute.map(route => ({
                lat: route.latitude,
                lng: route.longitude,
            })),
        [historicalRoute],
    );

    const options: google.maps.PolylineOptions = useMemo(
        () => ({
            clickable: false,
            draggable: false,
            editable: false,
            path,
            strokeColor: '#186DEB',
            strokeOpacity: 0.8,
            strokeWeight: 8,
            visible: true,
            zIndex: 1,
        }),
        [path],
    );

    const firstRoute = useMemo(() => historicalRoute[historicalRoute.length - 1], [historicalRoute]);
    const lastRoute = useMemo(() => (historicalRoute.length > 1 ? historicalRoute[0] : null), [historicalRoute]);

    return (
        <Fragment>
            <Polyline path={path} options={options} />
            <Mark icon={startRoute} route={firstRoute} />
            {lastRoute && <Mark icon={endRoute} route={lastRoute} />}
        </Fragment>
    );
}
