import styled from 'styled-components';

export interface GraphDivProps {
    rightPosition: number;
    topPosition: number;
}

export const GraphDiv = styled.div<GraphDivProps>`
    position: absolute;
    width: 19rem;
    background-color: white;
    padding: 1rem 0.8rem;
    z-index: 9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    right: ${({ rightPosition }) => rightPosition}px;
    top: ${({ topPosition }) => topPosition}px;

    .radioInput {
        font-style: normal;
        font-size: 1rem;
        line-height: 1.5rem;

        color: #333333;
        margin-top: -1rem;

        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
`;

export const TitleDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CloseButton = styled.button`
    border: none;
    background-color: white;
    cursor: pointer;
    color: #5f5f5f;
`;
export const DefaultText = styled.p`
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: #262d3c;
`;

export const OptionsText = styled(DefaultText)`
    font-size: 1.625rem;
`;

export const RadioDiv = styled.div``;

export const ButtonDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    gap: 1rem;

    .applyButton {
        width: 47%;
        height: 2.5rem;
        background: #262d3c;
        border-radius: 0.5rem;
        color: white;
        cursor: pointer;
        border: none;

        line-height: 0.75rem;
        transition: 0.5s;

        &:hover {
            background: #46526c;
            color: white;
        }
    }

    .cancelButton {
        width: 47%;
        height: 2.5rem;
        cursor: pointer;
        border-radius: 0.5rem;
        transition: 0.5s;
        background: #f7f7f7;
        border: 1px solid #e6e6e6;
        color: #262d3c;

        &:hover {
            background: #a9a9a9;
            color: white;
        }
    }
`;
