import { memo } from 'react';

import { SidebarItemProps } from '../sidebar-item';
import SubmenuItem from '../submenu-item';
import { Container } from './styles';

interface SubmenuProps {
    items: Array<SidebarItemProps>;
}

function Submenu(props: SubmenuProps) {
    return (
        <Container>
            {props.items.map(item => (
                <SubmenuItem key={item.id} {...item} />
            ))}
        </Container>
    );
}

export default memo(Submenu);
