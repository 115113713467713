import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../button';
import { ChevronDown, FilterAlt } from '../icons';

type OnOpen = (state: boolean) => boolean;

interface FilterButtonProps {
    isOpen: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onOpen?: (fun: OnOpen) => void;
}

export default function FilterButton({ isOpen, onOpen, onClick }: FilterButtonProps) {
    const { t } = useTranslation();

    return (
        <Button
            color={isOpen ? 'purple' : 'outline-purple'}
            leftIcon={<FilterAlt color={isOpen ? '#fff' : '#46526C'} />}
            size="small"
            rightIcon={<ChevronDown spin={isOpen} color={isOpen ? '#fff' : '#46526C'} />}
            type="button"
            onClick={e => onClick?.(e) || onOpen?.((state: boolean) => !state)}
        >
            {t('general.filter')}
        </Button>
    );
}
