import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, CircularProgress, Column, ErrorMessage, Feedback, Fieldset, Textarea } from 'src/common/components';
import { FeedbackType } from 'src/common/types';
import { ExtraEvent, ListEventOptionsByEventNamesDTO, SortAlarmRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { useEvents, useSortReviewEvent } from 'src/modules/alarm-processing/hooks';

import { FakeLoader, Grid, Subtitle } from './styles';

type SortAndReviewEventFormProps = UseFormReturn<Omit<SortAlarmRequestDTO, 'id'>, any> & {
    fakeLoading: boolean;
    setFakeLoading: Dispatch<SetStateAction<boolean>>;
};

const operationsDoNotUseBelt: string[] = [
    'GRINDROD_TCM',
    'NUKE_TRANSPORTES_MOCAMBIQUE',
    'MOTA ENGIL - VULCAN',
    'ALCOA_JURUTI_HIDROVIA',
    'ALCOA_JURUTI_FERROVIA',
    'KLABIN',
    'VALE_SLS_GER MANUT DE COMP E IND_EMPILHADEIRAS',
    'CCR',
    'CCR - VIAMOB_8E9',
    'CCR_METRO BAHIA',
    'CCR - VIAMOB_5',
    'AMBEV PILOTO - EMPILHADEIRA',
    'INHAUS GPSSA',
];

export default function SortAndReviewFormFields({
    clearErrors,
    fakeLoading,
    formState: { errors },
    getValues,
    register,
    setFakeLoading,
    setValue,
}: SortAndReviewEventFormProps) {
    const { selectedEvents, eventScreenType } = useEvents();

    const {
        options,
        responseStatus,
        handleGetListOptions,
        eventData,
        alarmEvent,
        handleEventChecked,
        extraAlarmEvent,
        handleExtraEventChecked,
        handleDescriptionDetails,
        descriptionDetails,
    } = useSortReviewEvent();

    const { t } = useTranslation();

    const noBeltFilter = useMemo(() => {
        if (selectedEvents?.length === 1) {
            const filterOperations = operationsDoNotUseBelt.includes(selectedEvents[0].operation as string);

            if (filterOperations) {
                const updatedExtraEvents = options?.extraEvents.filter(event => event.key !== 'Cinto');

                const updatedData: ListEventOptionsByEventNamesDTO | null = {
                    eventOptions: options?.eventOptions as string[],
                    extraEvents: updatedExtraEvents as ExtraEvent[],
                };

                return updatedData;
            } else {
                return options;
            }
        }

        return options;
    }, [options, selectedEvents]);

    const event = useMemo(
        () => (selectedEvents?.length === 1 && eventScreenType === 'events' ? selectedEvents[0] : null),
        [eventScreenType, selectedEvents],
    );

    const handleChangeEvent = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            clearErrors('event');
            handleEventChecked(e.target.value);
            setValue('event', e.target.value);
        },
        [clearErrors, handleEventChecked, setValue],
    );

    const handleChangeExtraEvents = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            const values = getValues('extraEvents');

            const index = values?.findIndex(el => el === value);
            const selectedExtraEvents = index === -1 ? [...(values || []), value] : values?.filter(el => el !== value) || [];
            handleExtraEventChecked(selectedExtraEvents);
            setValue('extraEvents', selectedExtraEvents);
        },
        [getValues, handleExtraEventChecked, setValue],
    );

    const extraEvents = useMemo(() => {
        return (
            <Column gap={8} align="flex-start" width="100%">
                {noBeltFilter?.extraEvents?.map(event => (
                    <Column gap={8} align="flex-start" key={event.key} width="100%">
                        <Subtitle>{event.key}</Subtitle>
                        <Grid>
                            {event.options.map(opt => (
                                <Checkbox
                                    key={opt}
                                    mode={'blue-fill'}
                                    label={t(`tableEventName.${opt}`).toString()}
                                    value={opt}
                                    name="extraEvents"
                                    ref={register('extraEvents').ref}
                                    onChange={handleChangeExtraEvents}
                                />
                            ))}
                        </Grid>
                    </Column>
                ))}
            </Column>
        );
    }, [handleChangeExtraEvents, noBeltFilter?.extraEvents, register, t]);

    const checkboxes = useMemo(() => {
        return (
            <>
                {noBeltFilter?.eventOptions?.map(opt => {
                    return (
                        <Checkbox
                            key={opt}
                            mode={'blue-fill'}
                            label={t(`tableEventName.${opt}`).toString()}
                            value={opt}
                            name="event"
                            ref={register('event').ref}
                            onChange={handleChangeEvent}
                            checked={alarmEvent === opt}
                        />
                    );
                })}
            </>
        );
    }, [alarmEvent, handleChangeEvent, noBeltFilter?.eventOptions, register, t]);

    const loader = useMemo(() => {
        if (responseStatus?.hasError) {
            return (
                <FakeLoader className="show">
                    <ErrorMessage>{t('general.error')}</ErrorMessage>
                    <Button
                        type="button"
                        disabled={!selectedEvents}
                        onClick={() => handleGetListOptions(selectedEvents?.map(e => e.alarm as string) || [])}
                        style={{ maxHeight: '40px' }}
                    >
                        {t('general.apply')}
                    </Button>
                </FakeLoader>
            );
        }

        return (
            <FakeLoader className={responseStatus.loading || fakeLoading ? 'show' : 'hidde'}>
                <CircularProgress color="#186DEB" />
            </FakeLoader>
        );
    }, [fakeLoading, handleGetListOptions, responseStatus?.hasError, responseStatus.loading, selectedEvents, t]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (responseStatus?.success) {
            setValue('details', descriptionDetails || event?.backDetail || event?.details || '');
            setValue('event', alarmEvent || eventData?.event?.event || event?.event || '');
            setValue('extraEvents', eventData?.extraEvents || extraAlarmEvent || []);

            handleEventChecked(alarmEvent || eventData?.event?.event || event?.event || '');

            timer = setTimeout(() => setFakeLoading(false), 1000);
        }

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, eventData, responseStatus?.success]);

    const handleChangeTextArea = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleDescriptionDetails(e.currentTarget.value);
        },
        [handleDescriptionDetails],
    );

    return (
        <Column
            flex
            width="100%"
            align="flex-start"
            justify="space-between"
            gap={16}
            style={{
                position: 'relative',
            }}
        >
            {loader}
            <Column flex align="flex-start" width="400px" gap={16}>
                <Fieldset legend={String(t('general.events'))}>
                    <Grid>{checkboxes}</Grid>
                    {errors['event']?.message && <Feedback type={FeedbackType.ERROR} message={errors['event']?.message} />}
                </Fieldset>
                <Fieldset legend={String(t('alarms.extra_events'))}>{extraEvents}</Fieldset>
            </Column>
            <Column flex align="flex-start" gap={4}>
                <Fieldset legend={String(t('general.description'))}>
                    <Textarea
                        autoComplete="off"
                        placeholder={String(t('general.write_here'))}
                        width="400px"
                        feedback={{
                            message: errors['extraEvents']?.message,
                        }}
                        {...register('details')}
                        onChange={handleChangeTextArea}
                    />
                </Fieldset>
            </Column>
        </Column>
    );
}
