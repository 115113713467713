import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SidebarItemsProps } from './sidebar-items-list';

export const Children = styled.div<SidebarItemsProps>`
    height: 100%;
    padding: 24px 24px 32px 0;
    margin-left: ${({ displaySidebar }) => (displaySidebar ? '20rem' : '5rem')};
    position: relative;
`;

export const SidebarOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 64px;
`;

export const SidebarWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
    height: 100%;
    font-weight: 500;

    a {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
`;

export const SidebarLogoWrapper = styled.div<SidebarItemsProps>`
    gap: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${props => (props.displaySidebar ? '16px' : '0')};

    button {
        border: none;
        outline: none;
        background: transparent;
    }
`;

export const SidebarLogo = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 468px) {
        display: none;
    }
`;

export const SidebarBrand = styled.span<SidebarItemsProps>`
    display: ${({ displaySidebar }) => (displaySidebar ? 'block' : 'none')};
`;

export const SidebarToggler = styled.button<SidebarItemsProps>`
    cursor: pointer;
`;

export const Bar = styled.div`
    width: 5px;
    height: 40px;
    background: #6da4d3;
    border-radius: 4px 0px 0px 4px;
`;

// SidebarItem styles
export const ItemsList = styled.ul`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    list-style: none;
    margin: 0;
    padding: 0;

    a {
        display: flex;
        width: 100%;
        text-decoration: none;
    }
`;

export const ItemContainer = styled.li`
    border-radius: 4px 0px 0px 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    padding: 8px;
    position: relative;
    width: 100%;

    a {
        span {
            color: #d7d7d7;
        }
        text-decoration: none;
    }

    &.active,
    &:hover {
        background: #ffffff;
        color: #6da4d3;
        box-shadow: 0px 0px 2px #66a3d4;
        span {
            color: #6da4d3;
        }

        & > div::after {
            content: '';
            position: absolute;
            height: 40px;
            width: 5px;
            background: #6da4d3;
            border-radius: 4px 0px 0px 4px;
            right: 0;
        }
    }
`;

export const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #7c7788;
`;

export const ItemSubmenu = styled.div`
    color: #b2d5f2;
    font-size: 14px;

    &.active {
        color: #6da4d3;
        font-size: 14px;
        font-weight: 500;
    }
`;

export const SubMenu = styled.div`
    display: flex;
    flex-flow: column;

    a {
        text-decoration: none;
        padding-left: 45px;
    }
`;

export const ItemName = styled.span<SidebarItemsProps>`
    display: ${({ displaySidebar }) => (displaySidebar ? 'block' : 'none')};
    text-transform: capitalize;
    color: #d7d7d7;
`;

// Sidebar Container
export const SidebarContainer = styled.div<SidebarItemsProps>`
    background: #262d3c;
    box-shadow: 0px 0px 2px #66a3d4;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    padding: 24px 0 56px 24px;
    position: fixed;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    z-index: 1;
    white-space: nowrap;

    .menu-icon {
        display: none;
        color: #fff;
    }

    &.sidebar-closed {
        padding: 24px 0 24px 12px;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
        width: 48px;

        #arrow,
        .argus-logo,
        .no-display {
            display: none;
        }

        .menu-icon {
            display: flex;
        }
    }

    ${ItemWrapper} {
        justify-content: ${({ displaySidebar }) => !displaySidebar && 'center'};
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 3px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #eaeced;

        &:hover {
            background: #d5e0f3;
        }
    }
`;

export const SidebarFooter = styled.div<SidebarItemsProps>`
    align-items: flex-start;
    color: #ffffff;
    display: ${props => (props.displaySidebar ? 'flex' : 'none')};
    flex-direction: column;
    gap: 16px;
    padding: 0px 0px 0px 8px;
`;
