import { SVGProps } from 'react';

function DriverEmptyPhoto(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={104} height={89} viewBox="0 0 104 89" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width={104} height={89} rx={8} fill="#E6E6E6" />
            <path d="M57.151 53.286a5.333 5.333 0 00-10.303 0" stroke="#A9A9A9" strokeWidth={2} />
            <ellipse cx={52.0002} cy={42.6667} rx={2.66667} ry={2.66667} stroke="#A9A9A9" strokeWidth={2} strokeLinecap="round" />
            <rect x={42.3335} y={34.3333} width={19.3333} height={19.3333} rx={3} stroke="#A9A9A9" strokeWidth={2} />
        </svg>
    );
}

export { DriverEmptyPhoto };
