import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Autocomplete,
    Button,
    Column,
    Divider,
    Feedback,
    FeedbackProps,
    FeedbackType,
    Input,
    MainCard,
    Modal,
    Range,
    RenderIf,
    Row,
    Select,
    Textarea,
    usePermission,
} from 'src/common';
import { toast } from 'src/common/components/toast';
import { DepartmentResume, departmentService } from 'src/modules/configurations';
import { MandatoryFields } from 'src/modules/configurations/users/components/create-or-update-user-form/styles';
import AmbigousContactDialog from 'src/modules/configurations/vehicles/components/ambigous-contact-dialog';

import { EventExpiresIn } from '../../domain/constants/event-expires-in';
import { GenerateEventBy } from '../../domain/constants/generate-event-by';
import { GeometricShape } from '../../domain/constants/geometric-shape';
import { CreateElectronicFenceFormDTO } from '../../dtos/create-electronic-fence.dto';
import { useCreateElectronicFence, useElectronicFence, useUpdateElectronicFence } from '../../hooks';
import { createElectronicFenceSchema } from '../../infra/validations/yup/create-electronic-fence.schema';
import { OverlayProps } from '../electronic-fence-map-modal';
import { Map } from '../electronic-fence-map-modal/map';
import { Header } from './header';
import { Form, InputColor, Paragraph } from './styles';
import SuggestedColors from './suggested-colors';

export default function CreateOrUpdateElectronicFenceModal() {
    const { mutateAsync: create, isLoading: isCreating } = useCreateElectronicFence();
    const { mutateAsync: update, isLoading: isUpdating } = useUpdateElectronicFence();
    const { setOperations, operations, isOpen, setIsOpen, setFormData, initialFormData, map, overlayRef } = useElectronicFence();
    const { t } = useTranslation();
    const { has } = usePermission();

    const {
        formState: { errors, isValid },
        handleSubmit: onSubmit,
        register,
        setValue,
        watch,
    } = useForm<CreateElectronicFenceFormDTO>({
        defaultValues: initialFormData,
        resolver: yupResolver(createElectronicFenceSchema),
        mode: 'onChange',
    });

    const [ambiguousParents, setAmbiguousParents] = useState<DepartmentResume[]>([]);
    const [parentId, setParentId] = useState<number | null>(null);
    const [showAmbiguousModal, setShowAmbiguousModal] = useState(false);
    const [isEditShape, setIsEditShape] = useState(false);

    const controller = useMemo(() => new AbortController(), []);

    const opacity = isNaN(Number(watch('colorTransparencyPercentage')))
        ? undefined
        : Math.abs((watch('colorTransparencyPercentage') || 0) - 100) / 100;

    const overlay: OverlayProps | null = watch('geometricShape')
        ? {
              coordinates: watch('coordinates'),
              radius: watch('radius'),
              type: watch('geometricShape') as GeometricShape,
          }
        : null;

    const setOverlay = (overlay: OverlayProps | null) => {
        if (overlay) {
            setValue('coordinates', overlay.coordinates);
            setValue('radius', overlay.radius);
            setValue('geometricShape', overlay.type);
            return;
        }
        setValue('coordinates', []);
        setValue('radius', 0);
        setValue('geometricShape', null);
    };

    const [tab, setTab] = useState<number>(0);

    const handleClose = () => {
        setFormData(null);
        setIsOpen(state => ({ ...state, createOrUpdate: false }));
    };

    const feedback = (key: keyof CreateElectronicFenceFormDTO): FeedbackProps => {
        return {
            type: FeedbackType.ERROR,
            message: t(errors[key]?.message || '')?.toString(),
        };
    };

    const isReadOnly = useMemo(
        () => ({
            disabled: !has('electronic_fence'),
            readOnly: !has('electronic_fence'),
        }),
        [has],
    );

    const eventExpiresInOptions = useMemo(() => {
        return [
            {
                value: EventExpiresIn.NEVER,
                label: t('monitoring.event_expires_in.never'),
            },
            {
                value: EventExpiresIn.TODAY,
                label: t('monitoring.event_expires_in.today'),
            },
            {
                value: EventExpiresIn.TOMORROW,
                label: t('monitoring.event_expires_in.tomorrow'),
            },
            {
                value: EventExpiresIn.SEVEN_DAYS,
                label: t('monitoring.event_expires_in.seven_days'),
            },
            {
                value: EventExpiresIn.CUSTOM,
                label: t('monitoring.event_expires_in.custom'),
            },
        ];
    }, [t]);

    const statusOptions = useMemo(() => {
        return [
            {
                value: 'true',
                label: t('general.status.active'),
            },
            {
                value: 'false',
                label: t('general.status.inactive'),
            },
        ];
    }, [t]);

    const generateEventByOptions = useMemo(() => {
        return [
            {
                value: GenerateEventBy.INPUT,
                label: t('general.input'),
            },
            {
                value: GenerateEventBy.OUTPUT,
                label: t('general.output'),
            },
        ];
    }, [t]);

    const title = useMemo(() => {
        if (!has('electronic_fence')) {
            return t('monitoring.view_electronic_fence');
        }

        return initialFormData?.id ? t('monitoring.edit_electronic_fence') : t('monitoring.add_electronic_fence');
    }, [has, initialFormData?.id, t]);

    const handleSubmit = useCallback(
        async (data: CreateElectronicFenceFormDTO) => {
            const operationsFiltered = operations.data.filter(item => item.name === data.operation.trim());

            if (!operationsFiltered?.length) {
                toast.error(t('general.error'), `${t('field_validations.invalid_record')}: ${t('monitoring.organization')}`);
                return;
            }
            if (initialFormData?.operation !== data.operation) {
                if (!parentId) {
                    if (operationsFiltered.length > 1) {
                        setAmbiguousParents(operationsFiltered);
                        setShowAmbiguousModal(true);
                        return;
                    }
                }
            }

            if (overlayRef?.current) {
                const circle = overlayRef?.current as google.maps.Circle;
                const polygon = overlayRef?.current as google.maps.Polygon;
                const circleRadius = circle?.getRadius?.();
                const circleCenter = circle?.getCenter?.();

                if (circleRadius && circleCenter) {
                    data.coordinates = [{ ...circleCenter.toJSON() }];
                    data.radius = circleRadius;
                } else {
                    const coordinates = polygon
                        ?.getPath?.()
                        .getArray()
                        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

                    if (coordinates.length) {
                        data.coordinates = coordinates;
                        data.radius = 0;
                    }
                }
            }

            if (data.id) {
                return update({
                    ...data,
                    signal: controller.signal,
                    operationId: parentId || operationsFiltered[0].id,
                    id: data.id,
                    zoom: map?.current?.getZoom(),
                });
            }

            await create({ ...data, signal: controller.signal, operationId: parentId || operationsFiltered[0].id, zoom: map?.current?.getZoom() });
        },
        [controller.signal, create, initialFormData?.operation, map, operations.data, overlayRef, parentId, t, update],
    );

    const operation = watch('operation');

    useEffect(() => {
        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 15,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [controller, operation, setOperations]);

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, [controller]);

    return (
        <Fragment>
            <RenderIf condition={showAmbiguousModal}>
                <AmbigousContactDialog
                    open={showAmbiguousModal}
                    onClose={() => setShowAmbiguousModal(false)}
                    parentId={parentId}
                    ambiguousParents={ambiguousParents}
                    onSelect={setParentId}
                    title={title}
                />
            </RenderIf>

            <Modal open={isOpen.createOrUpdate} onClose={handleClose}>
                <MainCard>
                    <Form onSubmit={onSubmit(handleSubmit)}>
                        <Column padding="24px" gap={32} align="flex-start">
                            <Column gap={24} width="100%" align="flex-start">
                                <Header tab={tab} onChangeTab={setTab} title={title} onClose={handleClose} />
                                <RenderIf condition={tab === 0}>
                                    <Fragment>
                                        <Row align="center" justify="flex-end" gap={16} width="100%" style={{ margin: '-24px 0' }}>
                                            <MandatoryFields>* {t('field_validations.mandatory_fields')}</MandatoryFields>
                                        </Row>
                                        <Row align="flex-start" gap={16} width="100%">
                                            <Input
                                                autoFocus
                                                label={String(t('monitoring.electronic_fence_name'))}
                                                placeholder={String(t('general.write'))}
                                                autoComplete="off"
                                                hint="*"
                                                feedback={feedback('name')}
                                                maxLength={191}
                                                {...register('name')}
                                                {...isReadOnly}
                                            />
                                            <Select
                                                options={statusOptions}
                                                label={String(t('configurations.status'))}
                                                placeholder={String(t('general.select'))}
                                                autoComplete="off"
                                                feedback={feedback('active')}
                                                {...register('active')}
                                                {...isReadOnly}
                                            />
                                        </Row>
                                        <Row align="flex-start" gap={16} width="100%">
                                            <Autocomplete
                                                hint="*"
                                                label={String(t('alarms.operation'))}
                                                isLoading={operations.isLoading}
                                                options={operations.data.map(operation => ({ ...operation, value: operation.name }))}
                                                nextElement={{
                                                    id: 'generateEventBy',
                                                }}
                                                feedback={feedback('operation')}
                                                placeholder={String(t('alarms.operation'))}
                                                setValue={setValue}
                                                type="text"
                                                {...register('operation')}
                                                {...isReadOnly}
                                            />
                                            <Select
                                                hint="*"
                                                options={generateEventByOptions}
                                                label={String(t('monitoring.generate_event_by'))}
                                                placeholder={String(t('general.select'))}
                                                autoComplete="off"
                                                feedback={feedback('generateEventBy')}
                                                {...register('generateEventBy')}
                                                {...isReadOnly}
                                            />
                                        </Row>
                                        <Row align="flex-start" gap={8} width="100%">
                                            <Row align="flex-start" gap={16} width="50%" padding="0 8px 0 0">
                                                <Select
                                                    options={eventExpiresInOptions}
                                                    label={String(t('general.expires_in'))}
                                                    placeholder={String(t('general.select'))}
                                                    autoComplete="off"
                                                    feedback={feedback('expiresIn')}
                                                    {...register('expiresIn')}
                                                    {...isReadOnly}
                                                />
                                            </Row>
                                            {watch('expiresIn') === EventExpiresIn.CUSTOM ? (
                                                <Input
                                                    label={String(t('monitoring.expires_in'))}
                                                    placeholder={String(t('general.select'))}
                                                    type="date"
                                                    feedback={feedback('expirationDate')}
                                                    min={initialFormData?.id ? undefined : format(new Date(), 'yyyy-MM-dd')}
                                                    {...isReadOnly}
                                                    {...register('expirationDate')}
                                                />
                                            ) : (
                                                <Fragment />
                                            )}
                                        </Row>
                                        <Row align="flex-start" gap={16} width="100%">
                                            <Textarea
                                                label={String(t('configurations.observation'))}
                                                placeholder={String(t('general.write'))}
                                                autoComplete="off"
                                                feedback={feedback('observation')}
                                                maxLength={191}
                                                {...register('observation')}
                                                {...isReadOnly}
                                            />
                                        </Row>
                                    </Fragment>
                                </RenderIf>
                                <RenderIf condition={tab === 1}>
                                    <Column gap={8} align="center" justify="center" width="100%">
                                        <Row gap={8} align="center" justify="flex-start" width="100%">
                                            <Button
                                                type="button"
                                                color="outline"
                                                size="small"
                                                disabled={isReadOnly.disabled}
                                                onClick={() => setIsEditShape(state => !state)}
                                            >
                                                {t('monitoring.change_zone_shape')}
                                            </Button>
                                            <Row gap={4} align="center" justify="flex-start">
                                                <Paragraph> {t('monitoring.zone_color')}:</Paragraph>
                                                <SuggestedColors onClick={e => setValue('color', e.currentTarget.name)} {...isReadOnly} />
                                            </Row>
                                            <InputColor {...register('color')} {...isReadOnly} />
                                            <Range
                                                label={`${t('monitoring.transparency')}:`}
                                                onFormat={value => `${value}%`}
                                                {...register('colorTransparencyPercentage')}
                                                {...isReadOnly}
                                            />
                                        </Row>
                                        <Map
                                            readonly={!isEditShape || isReadOnly.readOnly}
                                            className="small-map"
                                            overlay={overlay}
                                            setOverlay={setOverlay}
                                            color={watch('color')}
                                            opacity={opacity}
                                            zoom={map?.current?.getZoom()}
                                            center={map?.current?.getCenter()}
                                        />
                                        <RenderIf condition={!!feedback('geometricShape').message}>
                                            <Feedback {...feedback('geometricShape')} />
                                        </RenderIf>
                                    </Column>
                                </RenderIf>
                                <Divider />
                            </Column>
                            <RenderIf condition={has('electronic_fence')}>
                                <Row align="flex-start" gap={16} width="100%">
                                    <Button color="alert" type="reset" onClick={handleClose}>
                                        {t('general.cancel')}
                                    </Button>
                                    <Button type="submit" isLoading={isCreating || isUpdating} disabled={!isValid}>
                                        {t('general.confirm')}
                                    </Button>
                                </Row>
                            </RenderIf>
                        </Column>
                    </Form>
                </MainCard>
            </Modal>
        </Fragment>
    );
}
