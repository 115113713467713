import { Fragment, LabelHTMLAttributes, useMemo } from 'react';
import { InputProps } from 'src/common';
import Row from 'src/common/components/row';
import { FunctionComponent } from 'src/common/types/react';

import CircularProgress from '../../circular-progress';
import { StyledLabel } from '../styles';

type LabelProps = InputProps & FunctionComponent & LabelHTMLAttributes<HTMLLabelElement>;

export default function Label({ children, label, hint, isLoading, ...props }: LabelProps) {
    const atomLabel = useMemo(() => {
        if (label || hint || isLoading) {
            return (
                <Row width="100%" justify="space-between" gap={8}>
                    <strong>{label}</strong>
                    <small>{isLoading ? <CircularProgress color="#7f7f7f" /> : hint}</small>
                </Row>
            );
        }
        return null;
    }, [hint, isLoading, label]);

    return (
        <StyledLabel {...props}>
            <Fragment>
                {atomLabel}
                {children}
            </Fragment>
        </StyledLabel>
    );
}
