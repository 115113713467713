import { Column, Divider, Row } from 'src/common/components';

import Carousel from '../carousel';
import PastEventInfo from '../past-event-info';
import PastEventsList from '../past-events-list';

export default function TabPastEvents() {
    return (
        <Column align="flex-start" gap={24} flex width="742px" height='450px'>
            <Row align="flex-start" gap={16}>
                <Carousel />
                <Column gap={16} align="flex-start">
                    <PastEventInfo />
                </Column>
            </Row>
            <Divider color="#E6E6E6" />
            <PastEventsList />
        </Column>
    );
}
