import { TypeSearch } from 'src/common';
import { Vehicle } from 'src/modules/vehicle-monitoring/domain/entities/vehicle';

import { CreateVehicleFormDTO } from '../dtos';
import { ListVehiclesDTO } from '../dtos/list-vehicles.dto';

export const defaultValues: ListVehiclesDTO = {
    currentPage: 1,
    pageSize: 15,
    fleet: '',
    operation: '',
    type: '',
    text: '',
    typeSearch: TypeSearch.PARAMS,
};

export const getDefaultCreateOrUpdateValues = (data: Vehicle | null): Partial<CreateVehicleFormDTO> => ({
    fleet: data?.fleet || '',
    id: data?.id || undefined,
    observation: data?.observation || '',
    organization: data?.operation?.name || '',
    vehicleType: data?.vehicleType || undefined,
});
