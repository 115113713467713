import { useMutation } from '@tanstack/react-query';

import { getHistoricalRouteService } from '../services/get-historical-route.service';

export function useGetHistoricalRoute() {
    return useMutation({
        mutationKey: ['get-historical-route'],
        mutationFn: getHistoricalRouteService.execute,
    });
}
