import { styled, Tab, Tabs } from '@mui/material';

export const MuiTab = styled(Tab)`
    &.MuiTab-root {
        max-width: auto;
        min-width: auto;
        width: 32px;
        height: 32px;
        min-height: auto;

        color: #a9a9a9;
        background: #e6e6e6;
        border-radius: 4px;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            position: relative;
            z-index: 1;
        }

        svg.bell-badge-fill path {
            fill: #a9a9a9;
        }

        .time > * {
            stroke: #a9a9a9;
        }

        &:hover {
            background-color: buttonface;
        }
    }

    &.Mui-selected {
        color: #fff;
        background: #262d3c;

        svg.bell-badge-fill path {
            fill: #fff;
        }

        .time > * {
            stroke: #fff;
        }

        &:hover {
            background-color: #30384b;
        }
    }
`;

export const MuiTabs = styled(Tabs)`
    &.MuiTabs-root {
        min-height: auto;
    }
    .MuiTabs-flexContainer {
        align-items: flex-start;
        gap: 16px;
        min-height: auto;
    }

    .MuiTabs-indicator {
        display: none;
    }
`;
