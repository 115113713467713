import { ApiResponse, api } from '../../../../common';
import { PeriodRequestDTO } from '../dtos/period.dtos';

export class PeriodService {
    public async getData({ dateFrom, dateTo, equipament, operations }: PeriodRequestDTO): Promise<any> {
        const {
            data: { content },
        } = await api.get<ApiResponse>('/analytics/period', {
            params: {
                dateFrom,
                dateTo,
                equipament,
                operations,
            },
        });

        return content;
    }
}

export const periodService = new PeriodService();
