import styled, { css } from 'styled-components';

interface ContainerProps {
    isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
    align-items: center;
    color: #b2d5f2;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    font-weight: 500;

    &:hover {
        font-weight: 500;
        font-size: 15px;
        color: #6da4d3;
    }

    ${props =>
        props.isActive &&
        css`
            font-weight: 500;
            font-size: 14px;
            color: #6da4d3;
        `}
`;
