import moment from 'moment';
import { ApiResponse } from 'src/common';
import { api } from 'src/common/infra';

import { DriversEventsDTO, DriversRequest } from '../dtos/drivers.dtos';

export class DriversService {
    public async execute(data: DriversRequest) {
        const { dateFrom, dateTo, events, regions, countries, operationsFiltered, operations} = data;
        const {
            data: content,
        } = await api.get<ApiResponse<Record<string, DriversEventsDTO[]>>>('/analytics/drivers', {
            params: {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                events,
                regions,
                countries,
                operationsFiltered,
                operations,
            },
        });

        return content;
    }

    public async getDriverByImage(data: DriversRequest & { file: File }) {
        const { dateFrom, dateTo, events, regions, countries, operationsFiltered, file } = data;
        const formData = new FormData();
        
        formData.append('image', file);

        const {
            data: content,
        } = await api.post<ApiResponse<DriversEventsDTO[]>>('/analytics/drivers/image', 
            formData, 
            {
                headers: {
                    "Content-Type": file.type
                },
                params: {
                    dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                    dateTo: moment(dateTo).format('YYYY-MM-DD'),
                    events,
                    regions,
                    countries,
                    operationsFiltered
                },
            }
        );

        return content;
    }

}

export const driversService = new DriversService();
