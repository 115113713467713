import { Fragment, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, EditFill, Eye, Row, Table, Trash } from 'src/common/components';
import { TableOptionButton } from 'src/common/components/table-option-button';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { DateHelper } from 'src/common/helpers/date.helper';
import { usePermission } from 'src/common/hooks';

import { Functionality } from '../../dtos';
import { useFunctions } from '../../hooks';
import DeleteDialog from '../delete-dialog';

function TableList() {
    const {
        allOnThisPageAreSelected,
        functions,
        deleteDialogRef,
        setDeletableFunction,
        selectedFunctions,
        handleSelect,
        handleSelectAll: selectAll,
        handleOpenDialog,
    } = useFunctions();
    const { isAdmin } = usePermission();
    const { t, i18n } = useTranslation();

    const handleChecked = useCallback(
        (data: Functionality) => selectedFunctions.findIndex(event => event.id === data.id) !== -1,
        [selectedFunctions],
    );

    const handleSelectAll = useCallback(() => {
        if (functions?.elements) {
            selectAll(functions.elements.map(e => e as Functionality));
        }
    }, [functions?.elements, selectAll]);

    const tableHeaders = useMemo(
        () => [
            <Checkbox
                mode={'blue-check'}
                name={'checkboxAll'}
                key="checkboxAll"
                hidden={!isAdmin}
                checked={allOnThisPageAreSelected}
                onChange={() => handleSelectAll()}
            />,
            t('configurations.function_number'),
            t('configurations.function'),
            t('configurations.creation_date'),
            t('configurations.observation'),
            <Fragment key="options" />,
        ],
        [allOnThisPageAreSelected, handleSelectAll, isAdmin, t],
    );

    const handleOpenDeleteDialog = useCallback(
        (fun: Functionality | null) => {
            if (!fun) return;

            setDeletableFunction(fun);
            deleteDialogRef?.current?.showModal();
        },
        [deleteDialogRef, setDeletableFunction],
    );

    return (
        <Fragment>
            <Table tableHeaders={tableHeaders}>
                {functions?.elements?.map(fun => {
                    return (
                        <TableRow className="body pointer" key={fun?.number} onClick={() => handleSelect(fun as Functionality)}>
                            <TableData style={{ width: '16px' }}>
                                <Checkbox
                                    hidden={!isAdmin}
                                    checked={handleChecked(fun as Functionality)}
                                    mode={'blue-check'}
                                    readOnly
                                    name={fun?.name || ''}
                                />
                            </TableData>
                            <TableData style={{ width: '126px' }}>{fun?.number}</TableData>
                            <TableData>{fun?.name || '---'}</TableData>
                            <TableData>{DateHelper.formatSimple(fun?.createdAt, i18n.language, 'UTC')}</TableData>
                            <TableData>{fun?.observation || '---'}</TableData>
                            <TableData style={{ width: '68px', paddingRight: '16px' }}>
                                {isAdmin ? (
                                    <Row gap={16}>
                                        <TableOptionButton
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOpenDialog(fun);
                                            }}
                                        >
                                            <EditFill color="#7B7B7B" />
                                        </TableOptionButton>
                                        <TableOptionButton
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOpenDeleteDialog(fun);
                                            }}
                                        >
                                            <Trash />
                                        </TableOptionButton>
                                    </Row>
                                ) : (
                                    <TableOptionButton
                                        type="button"
                                        onClick={e => {
                                            e.stopPropagation();
                                            handleOpenDialog(fun);
                                        }}
                                    >
                                        <Eye color="#7B7B7B" />
                                    </TableOptionButton>
                                )}
                            </TableData>
                        </TableRow>
                    );
                })}
            </Table>
            <DeleteDialog />
        </Fragment>
    );
}

export default memo(TableList);
