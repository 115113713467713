import { State } from 'src/common/types/reducer';

import { DepartmentResume } from '../../departments/dtos';
import { VehicleType } from '../dtos';

export const INITIAL_DEPARTMENT_STATE: State<Array<DepartmentResume>> = {
    data: [],
    hasError: false,
    isLoading: true,
};

export const INITIAL_VEHICLE_TYPES_STATE: State<Array<VehicleType>> = {
    data: [],
    hasError: false,
    isLoading: true,
};
