import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseButton, usePermission } from 'src/common';
import { Autocomplete, Button, Column, Divider, Input, Row, Select, Textarea } from 'src/common/components';
import RenderIf from 'src/common/components/render-if';
import { toast } from 'src/common/components/toast';
import { DepartmentResume, departmentService } from 'src/modules/configurations/departments';

import { CreateVehicleFormDTO } from '../../dtos';
import { useVehicles } from '../../hooks/use-vehicles';
import { createVehicleSchema } from '../../infra/validations';
import { vehicleService } from '../../services/vehicle.service';
import AmbigousContactDialog from '../ambigous-contact-dialog';
import { Card, Modal, Title } from './styles';

interface CreateOrUpdateVehicleDialogProps {
    onClose(): void;
    onlyView: boolean;
    open: boolean;
}

export function CreateOrUpdateVehicleDialog({ onClose, open, onlyView }: CreateOrUpdateVehicleDialogProps): JSX.Element {
    const { isAdmin } = usePermission();
    const [ambiguousParents, setAmbiguousParents] = useState<DepartmentResume[]>([]);
    const [parentId, setParentId] = useState<number | null>(null);
    const [showAmbiguousModal, setShowAmbiguousModal] = useState(false);

    const isOnlyView = useMemo(() => onlyView || !isAdmin, [isAdmin, onlyView]);

    const { operations, setOperations, setFilter, initialFormData, handleSelectAll, getVehicleTypes } = useVehicles();

    const {
        setValue,
        register,
        watch,
        handleSubmit: onSubmit,
        formState: { errors, isValid },
    } = useForm<CreateVehicleFormDTO>({
        defaultValues: initialFormData,
        mode: 'onChange',
        resolver: yupResolver(createVehicleSchema),
    });

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: initialFormData?.id ? vehicleService.update : vehicleService.create,
        mutationKey: ['create-update-vehicles'],
        onSuccess: () => {
            toast.success(t('general.success'), '');
            handleSelectAll([]);
            setFilter(state => ({ ...state, currentPage: 1 }));
            onClose();
        },
        onError: error => {
            if (error instanceof AxiosError) {
                if (error.response?.data.message === 'VEHICLE_ALREADY_EXISTS') {
                    return toast.error(t('general.error'), t('field_validations.record_already_exists'));
                }
            }

            toast.error(t('general.error'), '');
        },
    });

    const { t } = useTranslation();

    const handleSubmit = useCallback(
        async (data: CreateVehicleFormDTO) => {
            if (isOnlyView) return;

            const { fleet, observation, organization, vehicleType, id } = data;

            const operationsFiltered = operations.data.filter(o => o.name?.toUpperCase()?.trim() === organization?.toUpperCase()?.trim());

            if (!operationsFiltered?.length) {
                toast.error(t('general.error'), `${t('field_validations.invalid_record')}: ${t('monitoring.organization')}`);
                return;
            }

            if (initialFormData?.organization !== organization) {
                if (!parentId) {
                    if (operationsFiltered.length > 1) {
                        setAmbiguousParents(operationsFiltered);
                        setShowAmbiguousModal(true);
                        return;
                    }
                }
            }

            await mutateAsync({
                fleet,
                observation,
                vehicleType,
                operationId: parentId || operationsFiltered[0].id,
                id,
            });
        },
        [initialFormData?.organization, isOnlyView, mutateAsync, operations.data, parentId, t],
    );

    const disabled = useMemo(() => !isValid || operations.isLoading, [isValid, operations.isLoading]);

    const isInputDisabled = useMemo(
        () => ({
            readOnly: isOnlyView,
            disabled: isOnlyView,
        }),
        [isOnlyView],
    );

    const title = useMemo(() => {
        if (isOnlyView) return t('configurations.view_vehicle');

        return initialFormData?.id ? t('configurations.edit_vehicle') : t('configurations.add_vehicle');
    }, [initialFormData?.id, isOnlyView, t]);

    const operation = watch('organization');

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    active: true,
                    currentPage: 1,
                    pageSize: 15,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations]);

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Card onSubmit={onSubmit(handleSubmit)}>
                    <Column gap={24} width="100%">
                        <Column gap={16} width="100%">
                            <Row gap={48} justify="space-between" width="100%">
                                <Title>{title}</Title>
                                <CloseButton type="button" onClick={onClose} />
                            </Row>
                            <Row gap={16} width="100%">
                                <Autocomplete
                                    hint="*"
                                    isLoading={operations.isLoading}
                                    label={String(t('monitoring.organization'))}
                                    options={operations.data.map(o => ({ id: o.id, value: o.name }))}
                                    placeholder={String(t('general.write'))}
                                    setValue={setValue}
                                    feedback={{
                                        message: t(errors.organization?.message || '')?.toString(),
                                    }}
                                    {...isInputDisabled}
                                    {...register('organization')}
                                    nextElement={{
                                        id: 'vehicleType',
                                    }}
                                />
                                <Select
                                    hint="*"
                                    label={String(t('configurations.vehicle_type'))}
                                    placeholder={String(t('general.select'))}
                                    options={getVehicleTypes(t)}
                                    feedback={{
                                        message: t(errors.vehicleType?.message || '')?.toString(),
                                    }}
                                    {...isInputDisabled}
                                    {...register('vehicleType')}
                                />
                            </Row>
                            <Row gap={16} width="50%" className="pr">
                                <Input
                                    label={String(t('monitoring.board_number'))}
                                    placeholder={String(t('general.write'))}
                                    autoComplete="off"
                                    hint="*"
                                    feedback={{
                                        message: String(t(errors.fleet?.message || '')),
                                    }}
                                    maxLength={20}
                                    {...isInputDisabled}
                                    {...register('fleet')}
                                />
                            </Row>
                            <Textarea
                                label={String(t('configurations.observation'))}
                                placeholder={String(t('general.write'))}
                                feedback={{
                                    message: t(errors.observation?.message || '')?.toString(),
                                }}
                                {...isInputDisabled}
                                {...register('observation')}
                            />
                        </Column>
                        <Divider />
                    </Column>
                    <RenderIf condition={!isOnlyView}>
                        <Row align="flex-start" gap={16} width="100%">
                            <Button color="alert" type="reset" onClick={onClose}>
                                {t('general.cancel')}
                            </Button>

                            <Button type="submit" disabled={disabled} isLoading={isLoading}>
                                {t('general.confirm')}
                            </Button>
                        </Row>
                    </RenderIf>
                </Card>
            </Modal>
            <RenderIf condition={showAmbiguousModal}>
                <AmbigousContactDialog
                    open={showAmbiguousModal}
                    onClose={() => setShowAmbiguousModal(false)}
                    parentId={parentId}
                    ambiguousParents={ambiguousParents}
                    onSelect={setParentId}
                    title={title}
                />
            </RenderIf>
        </>
    );
}
