import { SelectChangeEvent, Slider } from '@mui/material';
import { HeatmapLayer } from '@react-google-maps/api';
import * as turf from '@turf/turf';
import { addDays, format, isValid, subDays } from 'date-fns';
import { FocusEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'src/common/components/date-picker';
import { GraphsModal } from 'src/common/components/graphics/graphs-modal';
import { StackedLineChart } from 'src/common/components/graphics/stacked-line-chart';
import Radio from 'src/common/components/inputs/radio';
import { EventHelper } from 'src/common/helpers/events.helper';
import { filterByAlarmsName } from 'src/modules/alarm-processing/services/filter-by-alarms-name';
import { EventsFilter, EventsProps } from 'src/modules/analytics/components/events-filter';
import { DriversFilter } from 'src/modules/analytics/drivers/components/driver-filter';
import UploadImage from 'src/modules/analytics/drivers/components/upload-image-input';
import { DriverHistoricFilter, DriversEventsDTO, ITimeRangeFilter } from 'src/modules/analytics/drivers/dtos/drivers.dtos';

import {
    ASCII,
    Button,
    CardHeader,
    ChevronDown,
    Content,
    DateHelper,
    Divider,
    FilterAlt,
    Input,
    MainCard,
    NoContent,
    Pagination,
    Row,
    SearchBar,
    Skeleton,
    Title,
    useEventListener,
    useSearch,
} from '../../../common';
import Column from '../../../common/components/column';
import { BarChart, BarChartDataProps } from '../../../common/components/graphics/bar-chart';
import { BarChartDataStackProps, BarChartStack } from '../../../common/components/graphics/bar-chart-stack';
import { BarPolar, BarPolarDataProps } from '../../../common/components/graphics/bar-polar';
import RadioButton from '../../../common/components/radio-button';
import UpdateData from '../../../common/components/update-data';
import { GoogleMapContainer } from '../../../modules/analytics/components/google-map';
import { ImagesList } from '../../../modules/analytics/drivers/components/images-list';
import TableFilter from '../../../modules/analytics/drivers/components/table-filter';
import TableImage from '../../../modules/analytics/drivers/components/table-image';
import TableList from '../../../modules/analytics/drivers/components/table-list';
import { useDrivers } from '../../../modules/analytics/drivers/hook';
import {
    ButtonChangeContainer,
    ButtonTab,
    ChartContent,
    ChartSubTitle,
    FilterContainer,
    FilterOptionContainer,
    FilterTimeRangeRadioContainer,
    GraphContainer,
    GraphSection,
    HistoryContainer,
    ImagesContainer,
    ModalFilter,
    RadioButtonContainer,
    RankingFilter,
    TableEventHistoryEvents,
    TitleContainer,
    WideCardSection,
} from './styles';

export interface LegendsTimeRange {
    group: string;
    min: number;
    max: number;
}

export function Driver() {
    const { t } = useTranslation();
    const [uniqueEventsHeatMap, setUniqueEventsHeatMap] = useState<EventsProps[]>([]);
    const [uniqueEventsFilterHeatMap, setUniqueEventsFilterHeatMap] = useState<EventsProps[]>([]);
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const [filterHistoryOfDriversIsOpen, setFilterHistoryOfDriversIsOpen] = useState(false);
    const [filterTimeRangeIsOpen, setFilterTimeRangeIsOpen] = useState(false);
    const [sliderValue, setSliderValue] = useState<number>(1);

    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('polar');
    const [changeGraph, setChangeGraph] = useState<string>('polar');

    const [radius, setRadius] = useState(20);
    const [tab, setTab] = useState(0);
    const [eventsFilterMap, setEventsFilterMap] = useState<EventsProps[]>([]);

    const [startDate, setStartDate] = useState<Date | null>(DateHelper.yesterday);
    const [endDate, setEndDate] = useState<Date | null>(new Date());

    const [selectedType, setSelectedType] = useState<string>('Barra');
    const [selectedTypeBar, setSelectedTypeBar] = useState<string>('Dia');
    
    const [selectedTotalGraph, setSelectedTotalGraph] = useState<string>('Barra');
    const [selectedTotalPeriod, setSelectedTotalPeriod] = useState<string>('Dia');
    const [operations, setOperations] = useState<string[]>([]);

    const {
        register: registerRadioButton,
        handleSubmit: handleRadioButton,
        watch,
    } = useForm<ITimeRangeFilter>({
        defaultValues: {
            slider: sliderValue,
            typeCoordinates: 'polar',
        },
    });

    function calculateValue(value: number) {
        switch (value) {
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
                return 3;
            case 4:
                return 4;
            case 5:
                return 6;
            case 6:
                return 12;
            default:
                return 0;
        }
    }

    const marks = [1, 2, 3, 4, 5, 6].map(value => ({
        value,
        label: calculateValue(value),
    }));

    const handleChangeTab = useCallback((newValue: number) => {
        setTab(newValue);
    }, []);

    const { formRef, inputRef, clear } = useSearch();

    const handleOpenHistoryOfDriverFilter = useCallback(() => {
        setFilterHistoryOfDriversIsOpen(state => !state);
    }, []);

    const handleOpenTimeRangeFilter = useCallback(() => {
        setFilterTimeRangeIsOpen(state => !state);
    }, []);

    const handleApplyTimeRangeFilter = useCallback(
        (data: ITimeRangeFilter) => {
            const { slider } = data;

            setChangeGraph(selectedTimeRange);
            setSliderValue(slider);

            handleOpenTimeRangeFilter();
        },
        [handleOpenTimeRangeFilter, selectedTimeRange],
    );

    //Used for date to not return with a one day less or a month less, more informations in:
    //https://stackoverflow.com/a/34821566
    const blanckSpaceForFixDate = ' ';

    const maxDate = useMemo(() => (startDate ? addDays(startDate, 30) : new Date()), [startDate]);
    const minDate = useMemo(() => (endDate ? subDays(endDate, 30) : undefined), [endDate]);

    const handleChangeDates = useCallback((dates: [Date, Date]) => {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        },
        [],
    );

    const handleChangeDatesRaw = useCallback((event: FocusEvent<HTMLInputElement, Element>) => {
        event.preventDefault();
    }, []);

    const {
        handleGetDriversList,
        responseStatus,
        responseStatusGraphs,
        responseStatusHistoricEvents,
        handleGetDrivers,
        drivers,
        driverIdCluster,
        driversPaged,
        driverPagedIdCluster,
        handleGetDriverListIdCluster,
        handleGetDriverByImage,
        driverByImage,
        file,
        responseStatusByImage,
        filter,
        driverHistoric,
        handleGetDriverHistoric,
        handleGetDriverIdCluster,
    } = useDrivers();

    useEffect(() => {
        setOperations(filter.operations);
    }, [filter]);

    const [filtersMapGraphIsOpen, setFiltersMapGraphIsOpen] = useState(false);

    const handleOpenGraphMapFilters = useCallback(() => {
        setFiltersMapGraphIsOpen(state => !state);
    }, []);

    useEffect(() => {
        const allEvents: EventsProps[] = [];
        const uniqueEvents = [...new Set(driverIdCluster?.map(item => item.event))];

        uniqueEvents.map(event => {
            allEvents.push({
                name: event,
                translate: t(`tableEventName.${event}`),
                checked: false,
            });
        });

        setUniqueEventsHeatMap(allEvents);
    }, [driverIdCluster, t]);

    const handleOpenFilters = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const handleUpdateEvents = useCallback(async () => {
        const eventsFiltered: EventsProps[] = [];
        const response = await filterByAlarmsName.execute();

        response.map((event: any) => {
            if (event.equipament_type === 'DS') {
                const eventFilter: EventsProps = {
                    checked: false,
                    name: event.pt_br,
                    translate: t(`tableEventName.${event.pt_br}`),
                };
                eventsFiltered.push(eventFilter);
            }
        });

        const uniqueNamesSet = new Set();
        const result: EventsProps[] = [];

        eventsFiltered.forEach(event => {
            if (!uniqueNamesSet.has(event.name)) {
                uniqueNamesSet.add(event.name);
                result.push(event);
            }
        });

        setEventsFilterMap(result);
    }, [t]);

    const handleUpdate = useCallback(async () => {
        if (isValid(startDate) && isValid(endDate)) {
            handleUpdateEvents();
            handleGetDrivers({
                dateFrom: startDate,
                dateTo: endDate,
                operations: operations,
            })
            handleGetDriversList({
                dateFrom: startDate,
                dateTo: endDate,
                pageSize: 15,
                start: 1,
                operations: operations,
            });
            if (driverPagedIdCluster && driverPagedIdCluster.elements && driverPagedIdCluster.elements.length !== 0) {
                handleGetDriverHistoric({
                    dateFrom: startDate,
                    dateTo: endDate,
                    operations: operations,
                    id: driverPagedIdCluster.elements[0].idCluster,
                });
            }
        }
    }, [
        endDate, 
        startDate, 
        operations, 
        driverPagedIdCluster,
        handleGetDriversList, 
        handleUpdateEvents, 
        handleGetDriverHistoric, 
        handleGetDrivers
    ]);

    const onChartClick = useCallback(
        (params: any) => {
            const { value, name } = params;

            const auxName = name ? name : value;

            if (drivers) {
                const aux = Object.values(drivers).map((values) => {
                    return { 
                        data: values,
                        value: values.reduce((a, c) => a + c.total, 0) 
                    };
                })
                
                const driversSorted = aux
                    .sort((a, b) => b.value - a.value)
                    .map(result => result.data)
                    .flat(1);

                const selectedDriver = driversSorted.find(driver => driver.driver === auxName || driver.idCluster === auxName) as DriversEventsDTO;

                const id = selectedDriver.idCluster;
    
                handleGetDriverListIdCluster({
                    dateFrom: startDate,
                    dateTo: endDate,
                    pageSize: 15,
                    start: 1,
                    id,
                });
    
                handleGetDriverIdCluster({
                    dateFrom: startDate,
                    dateTo: endDate,
                    id,
                });
            }
        },
        [endDate, startDate, drivers, handleGetDriverIdCluster, handleGetDriverListIdCluster],
    )       

    const setPaginationAndHandleRequestList = useCallback(
        (currentPage: number) => {
            handleGetDriversList({
                dateFrom: startDate,
                dateTo: endDate,
                pageSize: 15,
                start: currentPage,
                operations: operations,
            });
        },
        [endDate, handleGetDriversList, startDate, operations],
    );

    const setPaginationAndHandleRequestListEventHistory = useCallback(
        (currentPage: number) => {
            if (driverPagedIdCluster) {
                handleGetDriverListIdCluster({
                    dateFrom: startDate,
                    dateTo: endDate,
                    pageSize: 15,
                    start: currentPage,
                    id: driverPagedIdCluster.elements[0].idCluster,
                    operations: operations,
                });
            }
        },
        [driverPagedIdCluster, endDate, handleGetDriverListIdCluster, startDate, operations],
    );

    const setPaginationAndHandleRequestByImage = useCallback(
        (currentPage: number) => {
            if (driverByImage && file) {
                handleGetDriverByImage({
                    dateFrom: startDate,
                    dateTo: endDate,
                    pageSize: 5,
                    start: currentPage,
                    file,
                });
            }
        },
        [driverByImage, endDate, handleGetDriverByImage, startDate, file],
    );

    const paginationContent = useMemo(() => {
        if (!responseStatus?.void && responseStatus?.success) {
            return (
                <>
                    <Divider />
                    <Pagination
                        onPageChange={currentPage => setPaginationAndHandleRequestList(currentPage)}
                        currentPage={driversPaged?.currentPage}
                        perPage={driversPaged?.pageSize}
                        total={driversPaged?.totalElements}
                    />
                </>
            );
        }

        return null;
    }, [
        driversPaged?.currentPage,
        driversPaged?.pageSize,
        driversPaged?.totalElements,
        responseStatus?.success,
        responseStatus?.void,
        setPaginationAndHandleRequestList,
    ]);

    const photoContent = useMemo(() => {
        if (!driverIdCluster) {
            return;
        }

        const numberOfPhotos = driverIdCluster.length >= 3 ? 3 : driverIdCluster.length;

        return driverIdCluster.slice(0, numberOfPhotos).map(data => data.photo);
    }, [driverIdCluster]);

    const paginationContentEventHistory = useMemo(() => {
        if (!driverPagedIdCluster || responseStatus.loading) {
            return;
        }

        if (!responseStatus?.void && responseStatus?.success) {
            return (
                <>
                    <Divider style={{ marginBottom: '0.4rem' }} />
                    <Pagination
                        onPageChange={currentPage => setPaginationAndHandleRequestListEventHistory(currentPage)}
                        currentPage={driverPagedIdCluster?.currentPage}
                        perPage={driverPagedIdCluster?.pageSize}
                        total={driverPagedIdCluster?.totalElements}
                    />
                </>
            );
        }

        return null;
    }, [driverPagedIdCluster, responseStatus.loading, responseStatus?.success, responseStatus?.void, setPaginationAndHandleRequestListEventHistory]);

    const paginationContentByImage = useMemo(() => {
        if (!driverByImage || responseStatus.loading) {
            return;
        }

        if (!responseStatus?.void && responseStatus?.success) {
            return (
                <>
                    <Divider />
                    <Pagination
                        onPageChange={currentPage => setPaginationAndHandleRequestByImage(currentPage)}
                        currentPage={driverByImage?.currentPage !== 0 ? driverByImage?.currentPage : 1}
                        perPage={driverByImage?.pageSize}
                        total={driverByImage?.totalElements}
                    />
                </>
            );
        }

        return null;
    }, [driverByImage, responseStatus.loading, responseStatus?.success, responseStatus?.void, setPaginationAndHandleRequestByImage]);

    const filters = useMemo(() => {
        if (filterIsOpen) {
            return <DriversFilter />;
        }

        return null;
    }, [filterIsOpen]);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    const getDrivers = useCallback(async () => {
        if (startDate && endDate) {
            handleGetDrivers({
                dateFrom: startDate,
                dateTo: endDate,
                events: [],
                regions: [],
                countries: [],
                operationsFiltered: [],
            });
        }
    }, [endDate, handleGetDrivers, startDate]);

    useEffect(() => {
        getDrivers();
    }, [getDrivers]);

    const content = useMemo(() => {
        if (responseStatusHistoricEvents.loading) {
            BarChart;
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        } else if (responseStatusHistoricEvents?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleUpdate} messageClassName="error" />;
        } else if (responseStatusHistoricEvents?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleUpdate} />;
        }

        if (!driverPagedIdCluster) {
            return <NoContent message={t('drivers.need_to_select_driver')} />;
        }

        return <TableList />;
    }, [
        driverPagedIdCluster,
        handleUpdate,
        responseStatusHistoricEvents?.hasError,
        responseStatusHistoricEvents.loading,
        responseStatusHistoricEvents?.void,
        t,
    ]);

    const handleNormalizeInput = useCallback((e: Event) => {
        const event = e as unknown as FormEvent<HTMLInputElement>;

        event.currentTarget.value = ASCII.format(event.currentTarget.value);
    }, []);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();
            const { target } = e;

            const currentTarget = target as unknown as HTMLFormElement;
            const text: HTMLInputElement = currentTarget?.elements?.namedItem('text') as HTMLInputElement;

            if (text.value === '') {
                return;
            }

            handleGetDriverListIdCluster({
                dateFrom: startDate,
                dateTo: endDate,
                pageSize: 15,
                start: 1,
                id: text.value,
                isText: true,
            });

            handleGetDriverHistoric({
                dateFrom: startDate,
                dateTo: endDate,
                id: text.value,
            })

            handleGetDriverIdCluster({
                dateFrom: startDate,
                dateTo: endDate,
                id: text.value,
            });
        },
        [endDate, handleGetDriverListIdCluster, handleGetDriverHistoric, handleGetDriverIdCluster, startDate],
    );

    document.getElementById('searchbox')?.addEventListener('search', function (event: any) {
        if (event.type === 'search') {
            if (event.currentTarget.value === '') {
                handleUpdate();
                clear();
            }
        }
    });

    useEventListener(formRef.current, 'submit', handleSearch);
    useEventListener(inputRef.current, 'input', handleNormalizeInput);

    const contentFilterIdCluster = useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleUpdate} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleUpdate} />;
        }

        return (
            <>
                <div style={{ marginLeft: '1rem' }}>
                    <SearchBar name="text" id="searchbox" type="search" />
                </div>
                <TableFilter dateFrom={startDate} dateTo={endDate} />
            </>
        );
    }, [endDate, handleUpdate, responseStatus?.hasError, responseStatus.loading, responseStatus?.void, startDate, t]);

    const contentByImage = useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleUpdate} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleUpdate} />;
        }

        return <TableImage dateFrom={startDate} dateTo={endDate} />;
    }, [endDate, handleUpdate, responseStatus?.hasError, responseStatus.loading, responseStatus?.void, startDate, t]);

    const contentImages = useMemo(() => {
        if (responseStatus.loading) {
            BarChart;
            return (
                <Content>
                    <Skeleton />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleUpdate} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleUpdate} />;
        }

        if (!driverPagedIdCluster) {
            return <NoContent message={t('drivers.need_to_select_driver')} />;
        } else if (driverPagedIdCluster.elements.length === 0) {
            return <NoContent message={t('general.no_content')} onClick={handleUpdate} />;
        }

        return <TitleContainer>
                <div>
                    <Title>
                        <h2>{t('drivers.images')}</h2>
                    </Title>
                    <ChartSubTitle>{t('drivers.photos_legends')}</ChartSubTitle>
                </div>
            </TitleContainer>;
    }, [
        driverPagedIdCluster,
        handleUpdate,
        responseStatus?.hasError,
        responseStatus.loading,
        responseStatus?.void,
        t,
    ]);

    const renderChartTotalEvents = useCallback(() => {
        const listData: BarChartDataProps[] = [];

        if (!drivers) {
            return listData;
        }

        const aux = Object.values(drivers).map((values) => {
            return { 
                data: values,
                value: values.reduce((a, c) => a + c.total, 0) 
            };
        })
        
        const driversSorted = aux.sort((a, b) => b.value - a.value).map(result => result.data);

        const uniqueEvents: string[] = [ ...new Set(driversSorted.flat(1).map(driver => driver.event))];

        uniqueEvents.forEach(event => {
            listData.push({
                name: event,
                data: [],
                color: EventHelper.getColorByEventName(event),
            });
        });
        
        driversSorted.forEach(data => {
            const eventsAux: number[] = [];

            listData.forEach((list, listIndex) => { 
                data.forEach((driver, index) => {
                    eventsAux[index] = driver.total;
                });

                if (!eventsAux[listIndex]) {
                    eventsAux[listIndex] = 0;
                }
            })

            eventsAux.forEach((value, index) => {
                listData[index].data.push(value);
            })
        });
        
        return listData;
    }, [drivers]);

    const renderBarAxisY = useCallback(() => {
        const listData: string[] = [];
        if (!drivers) {
            return listData;
        }

        const aux = Object.values(drivers).map((values) => {
            return { 
                data: values,
                value: values.reduce((a, c) => a + c.total, 0) 
            };
        })
        
        const driversSorted = aux.sort((a, b) => b.value - a.value).map(result => result.data);

        driversSorted.forEach(result => {
            result.forEach(driver => {
                if (driver.driver) {
                    listData.push(driver.driver);
                } else {
                    listData.push(driver.idCluster);
                }
            })
        })

        return [...new Set(listData)];
    }, [drivers]);

    const renderChartTimeRangeLegend = useMemo(() => {
        const hours: LegendsTimeRange[] = [];

        const interval = Number(sliderValue > 4 ? (sliderValue == 5 ? 6 : 12) : sliderValue);

        const hoursArray = Array.from({ length: 25 }, (_, index) => index);

        for (let i = 0; i < hoursArray.length; i += interval) {
            const groupStart = hoursArray[i];
            const groupEnd = Math.min(groupStart + interval - 1, 24);

            if (groupStart < 24) {
                const endLabel = Math.min(groupEnd, 24);
                hours.push(
                    interval === 1
                        ? {
                              group: `${endLabel}H`,
                              min: Number(groupStart),
                              max: Number(endLabel),
                          }
                        : {
                              group: `${groupStart}-${endLabel}H`,
                              min: Number(groupStart),
                              max: Number(endLabel),
                          },
                );
            }
        }

        return hours;
    }, [sliderValue]);

    const renderChartTimeRangeLegendForGraph = useMemo(() => {
        const legendTimeFilterData = renderChartTimeRangeLegend;

        return legendTimeFilterData.map(obj => obj.group);
    }, [renderChartTimeRangeLegend]);

    const findIndexForNumber = useCallback((arr: LegendsTimeRange[], num: number): number => {
        for (let i = 0; i < arr.length; i++) {
            if (num >= arr[i].min && num <= arr[i].max) {
                return i;
            }
        }
        return -1;
    }, []);

    const renderChartTimeRange = useMemo(() => {
        const legendsData = renderChartTimeRangeLegend;
        const contentEvents: BarPolarDataProps[] = [];

        if (!driverIdCluster) {
            return contentEvents;
        }

        const uniqueEvents = [...new Set(driverIdCluster.map(obj => obj.event))];

        uniqueEvents.map(event => {
            contentEvents.push({
                label: event,
                data: Array.from({ length: legendsData.length }).fill(0) as number[],
                color: EventHelper.getColorByEventName(event) as string,
            });
        });

        driverIdCluster?.map(driver => {
            const indexEvent = contentEvents.findIndex(data => data.label === driver.event);
            const indexHour = findIndexForNumber(legendsData, Number(driver.hour));

            contentEvents[indexEvent].data[indexHour] += 1;
        });

        return contentEvents;
    }, [driverIdCluster, findIndexForNumber, renderChartTimeRangeLegend]);

    const renderGoogleMapHeatLayer = useMemo(() => {
        const dataHeatMapLayer: google.maps.LatLng[] = [];

        if (!driverIdCluster) {
            return dataHeatMapLayer;
        }

        driverIdCluster?.map(driver => {
            dataHeatMapLayer.push(new google.maps.LatLng(driver.latitude, driver.longitude));
        });

        return dataHeatMapLayer;
    }, [driverIdCluster]);

    const dataHeatMapLayer = useMemo(() => {
        if (!driverIdCluster || driverIdCluster.length === 0) {
            return [];
        }

        const filteredEvents =
            uniqueEventsFilterHeatMap.length > 0 ?
                driverIdCluster.filter(event => {
                    return uniqueEventsFilterHeatMap.some(eventName => eventName.name === event.event);
                }) : driverIdCluster;

        return filteredEvents.map(event => new google.maps.LatLng(event.latitude, event.longitude));
    }, [driverIdCluster, uniqueEventsFilterHeatMap]);

    const centerHeatMap = useMemo(() => {
        if (renderGoogleMapHeatLayer.length === 0) {
            return { lat: -22.7253017, lng: -47.6532601 };
        }

        const point1 = turf.point([renderGoogleMapHeatLayer[0].lat(), renderGoogleMapHeatLayer[0].lng()]);
        const point2 = turf.point([
            renderGoogleMapHeatLayer[renderGoogleMapHeatLayer.length - 1].lat(),
            renderGoogleMapHeatLayer[renderGoogleMapHeatLayer.length - 1].lng(),
        ]);

        const midpoint = turf.midpoint(point1, point2);

        return { lat: midpoint.geometry.coordinates[0], lng: midpoint.geometry.coordinates[1] };
    }, [renderGoogleMapHeatLayer]);     

    const contentFilter = useMemo(() => {
        switch (tab) {
            case 0:
                return (
                    <ChartContent>
                        {!responseStatus.loading ? (
                            <BarChart
                                data={renderChartTotalEvents()}
                                yData={renderBarAxisY()}
                                triggerEventYAxis={true}
                                onChartClick={onChartClick}
                            />
                        ) : (
                            <Content>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Content>
                        )}
                    </ChartContent>
                );
            case 1:
                return (
                    <>
                        {contentFilterIdCluster}
                        {paginationContent}
                    </>
                );
            case 2:
                return driverByImage && driverByImage.elements.length !== 0 
                    ? <>
                        <UploadImage startDate={startDate} endDate={endDate} />
                        {!responseStatusByImage.error ? contentByImage : ''}
                        {!responseStatusByImage.error ? paginationContentByImage : ''}
                    </>
                    :   <UploadImage startDate={startDate} endDate={endDate} />;
        }
    }, [
        tab, 
        responseStatus.loading, 
        renderChartTotalEvents, 
        renderBarAxisY, 
        startDate, 
        endDate, 
        contentFilterIdCluster, 
        paginationContent, 
        driverByImage,
        contentByImage,
        paginationContentByImage,
        responseStatusByImage,
        onChartClick,
    ]);

    const handleChangeHeatMap = useCallback(
        (eventChange: SelectChangeEvent<string[]>) => {
            const selectedValues = eventChange.target.value as string[];
            const oldValues = [...uniqueEventsHeatMap];

            const updatedOptions: EventsProps[] = oldValues.map(event => ({
                ...event,
                checked: selectedValues.includes(event.name),
            }));

            setUniqueEventsHeatMap(updatedOptions);
        },
        [uniqueEventsHeatMap],
    );

    const handleApplyHeatMapFilter = useCallback(
        () => {
            setUniqueEventsFilterHeatMap(uniqueEventsHeatMap.filter(eventProp => eventProp.checked === true));
        },
        [uniqueEventsHeatMap],
    );

    const contentHeatMap = useMemo(() => {
        if (!driverIdCluster) {
            return <NoContent message={t('fleets.need_to_select_fleet')} />;
        }

        if (responseStatusHistoricEvents.loading) {
            return (
                <>
                    <Skeleton height={'40rem'} width={'20rem'} />
                    <Skeleton height={'40rem'} width={'30rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'450rem'} style={{ marginTop: '-1rem' }} />
                </>
            );
        } else if (responseStatusHistoricEvents.hasError) {
            return <NoContent message={t('general.error')} onClick={handleUpdate} messageClassName="error" />;
        } else if (responseStatusHistoricEvents.void) {
            return <NoContent message={t('general.no_content')} onClick={handleUpdate} />;
        }

        return (
            <>
                <TitleContainer>
                    <div>
                        <Title>
                            <h2>{t('drivers.heat_map')}</h2>
                        </Title>
                        <ChartSubTitle>{t('drivers.heat_map_subtitle')}</ChartSubTitle>
                    </div>
                    <div className="filters">
                        <button className="filterButton" onClick={handleOpenGraphMapFilters}>
                            <FilterAlt color={'#46526C'} />
                        </button>
                    </div>
                </TitleContainer>

                <GoogleMapContainer center={centerHeatMap}>
                    <HeatmapLayer
                        options={{
                            radius: radius,
                        }}
                        data={dataHeatMapLayer}
                    />
                </GoogleMapContainer>

                <ModalFilter>
                    {filtersMapGraphIsOpen && (
                        <GraphsModal
                            topPosition={12}
                            rightPosition={60}
                            handleCloseGraphFilter={handleOpenGraphMapFilters}
                            handleCancelButton={handleOpenGraphMapFilters}
                            handleApplyButton={handleApplyHeatMapFilter}
                        >
                            <div>
                                <p>{t('fleets.events')}</p>
                                <EventsFilter
                                    events={uniqueEventsHeatMap}
                                    handleChange={handleChangeHeatMap}
                                />
                            </div>
                            <div className="heatMapInputs">
                                <div>
                                    <Input
                                        name="raio"
                                        label={String(t('Raio'))}
                                        style={{ width: '100%' }}
                                        type="number"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRadius(Number(e.target.value))}
                                        value={radius}
                                        defaultValue={20}
                                    />
                                </div>
                                <div>
                                    <Input name="blur" label={String(t('Blur'))} style={{ width: '100%' }} type="number" defaultValue={7} />
                                </div>
                            </div>
                        </GraphsModal>
                    )}
                </ModalFilter>
            </>
        );
    }, [
        centerHeatMap, 
        dataHeatMapLayer, 
        filtersMapGraphIsOpen, 
        handleApplyHeatMapFilter, 
        handleChangeHeatMap, 
        handleOpenGraphMapFilters, 
        radius, 
        responseStatusHistoricEvents.hasError, 
        responseStatusHistoricEvents.loading, 
        responseStatusHistoricEvents.void, 
        t, 
        uniqueEventsHeatMap,
        driverIdCluster,
        handleUpdate,
    ]);

    const contentTimeRange = useMemo(() => {
        if (responseStatusGraphs.loading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '1rem' }}>
                    <Skeleton height={'10%'} width={'20rem'} />
                    <Skeleton height={'10%'} width={'30rem'} />
                    <Skeleton height={'80%'} />
                </div>
            );
        }

        if (!driverIdCluster) {
            return <NoContent message={t('drivers.need_to_select_driver')} />;
        } else if (driverIdCluster.length === 0) {
            return <NoContent message={t('general.no_content')} onClick={handleUpdate} />;
        }

        return (
            <>
                <TitleContainer>
                    <div>
                        <Title>
                            <h2>{t('drivers.time_range')}</h2>
                        </Title>
                        <ChartSubTitle>{t('drivers.subtitle_time_range')}</ChartSubTitle>
                    </div>
                    <div className="filters">
                        <button className="filterButton" onClick={handleOpenTimeRangeFilter}>
                            <FilterAlt color={'#46526C'} />
                        </button>
                        {filterTimeRangeIsOpen && (
                            <GraphsModal
                                topPosition={32}
                                rightPosition={60}
                                handleCancelButton={handleOpenTimeRangeFilter}
                                handleApplyButton={handleRadioButton(handleApplyTimeRangeFilter)}
                                handleCloseGraphFilter={handleOpenTimeRangeFilter}
                            >
                                <div style={{ marginBottom: '1rem' }}>
                                    <p>{t('period.hours_per_bar')}</p>
                                    <div className="sliderInput" style={{ margin: '0.3rem 0.5rem 0 0.5rem' }}>
                                        <Slider
                                            {...registerRadioButton('slider')}
                                            marks={marks}
                                            step={null}
                                            min={1}
                                            max={6}
                                            value={watch('slider')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <p style={{ marginBottom: '0.5rem' }}>{t('period.coordinates')}</p>
                                    <FilterTimeRangeRadioContainer
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedTimeRange(event.target.value)}
                                    >
                                        <Radio
                                            {...registerRadioButton('typeCoordinates')}
                                            value="polar"
                                            label={String(t('period.polar'))}
                                        />
                                        <Radio
                                            {...registerRadioButton('typeCoordinates')}
                                            label={String(t('period.cartesian'))}
                                            value="cartesian"
                                        />
                                    </FilterTimeRangeRadioContainer>
                                </div>
                            </GraphsModal>
                        )}
                    </div>
                </TitleContainer>

                <ChartContent>
                    {changeGraph === 'polar' ? (
                        <BarPolar dataY={renderChartTimeRangeLegendForGraph} dataX={renderChartTimeRange} />
                    ) : (
                        <BarChartStack legends={renderChartTimeRangeLegendForGraph} data={renderChartTimeRange} />
                    )}
                </ChartContent>
            </>
        );
    }, [
        changeGraph,
        driverIdCluster,
        filterTimeRangeIsOpen,
        handleApplyTimeRangeFilter,
        handleOpenTimeRangeFilter,
        handleRadioButton,
        marks,
        registerRadioButton,
        renderChartTimeRange,
        renderChartTimeRangeLegendForGraph,
        responseStatusGraphs.loading,
        t,
        watch,
        handleUpdate,
    ]);

    const renderEventsDriverTotalLegendChart = useMemo(() => {
        const listData: string[] = [];

        if (!driverHistoric) {
            return listData;
        }
        if (selectedTotalPeriod === 'Dia') {
            driverHistoric?.map(eventsDriver => {
                const index = listData.findIndex(data => data === format(new Date(eventsDriver.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));

                if (index === -1) {
                    listData.push(format(new Date(eventsDriver.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));
                }
            });
        } else if (selectedTotalPeriod === 'Semana') {
            const uniqueDates = [...new Set(driverHistoric.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            const groupedDates: Record<string, string[]> = {};
            parsedDates.forEach(date => {
                const weekNumber = DateHelper.getWeek(date);
                const weekKey = `Semana ${weekNumber}`;
                if (!groupedDates[weekKey]) {
                    groupedDates[weekKey] = [];
                }

                groupedDates[weekKey].push(format(date, 'dd-MM-yyyy'));
            });

            Object.entries(groupedDates).map(date => {
                const { startWeekDate, endWeekDate } = DateHelper.getStartAndEndDateOfWeek(date[1][0]);

                listData.push(`${startWeekDate} - ${endWeekDate}`);
            });
        } else if (selectedTotalPeriod === 'Mês') {
            const uniqueDates = [...new Set(driverHistoric.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);

                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByMonth).map(date => {
                listData.push(format(new Date(date[0].split('-')[1] + '-01-' + date[0].split('-')[0]), 'MM-yyyy'));
            });
        } else if (selectedTotalPeriod === 'Ano') {
            const uniqueDates = [...new Set(driverHistoric.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByYear).map(date => {
                listData.push(format(new Date(date[0] + blanckSpaceForFixDate), 'yyyy'));
            });
        }

        return listData;
    }, [driverHistoric, selectedTotalPeriod]);

    const renderEventsByDriverTotalDataChart = useMemo(() => {
        const listData: BarChartDataStackProps[] = [];
        if (!driverHistoric) {
            return listData;
        }
        const uniqueDates = [...new Set(driverHistoric.map(obj => obj.date))];
        const uniqueEvents = [...new Set(driverHistoric.map(obj => obj.event))];

        const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

        const groupedDates: Record<string, string[]> = {};
        parsedDates.forEach(date => {
            const weekNumber = DateHelper.getWeek(date);
            const weekKey = `Week ${weekNumber}`;
            if (!groupedDates[weekKey]) {
                groupedDates[weekKey] = [];
            }

            groupedDates[weekKey].push(format(date, 'dd-MM-yyyy'));
        });

        uniqueEvents.map((event, eventIndex) => {
            listData.push({
                data: [],
                label: event,
                color: EventHelper.getColorByEventName(event) as string,
            });
            if (selectedTotalPeriod === 'Dia') {
                uniqueDates.map(() => {
                    listData[eventIndex].data.push(0);
                });
            } else {
                Object.entries(groupedDates).map(() => {
                    listData[eventIndex].data.push(0);
                });
            }
        });

        if (selectedTotalPeriod === 'Dia') {
            uniqueDates.map((date, indexDate) => {
                driverHistoric.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    if (indexFilterData !== -1 && date === overview.date) {
                        listData[indexFilterData].data[indexDate] += 1;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Semana') {
            Object.entries(groupedDates).map((date, indexDate) => {
                driverHistoric.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += 1;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Mês') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);

                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByMonth).map((date, indexDate) => {
                driverHistoric.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += 1;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Ano') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByYear).forEach((year, datesInYear) => {
                driverHistoric.forEach(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = year[1].findIndex(dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[datesInYear] +=1;
                    }
                });
            });
        }

        return listData;
    }, [driverHistoric, selectedTotalPeriod]);

    const renderChartTotalEventsLineChart = useMemo(() => {
        const listData: StackedLineChart[] = [];
        if (!driverHistoric) {
            return listData;
        }
        const uniqueDates = [...new Set(driverHistoric.map(obj => obj.date))];
        const uniqueEvents = [...new Set(driverHistoric.map(obj => obj.event))];

        const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

        const groupedDates: Record<string, string[]> = {};
        parsedDates.forEach(date => {
            const weekNumber = DateHelper.getWeek(date);
            const weekKey = `Week ${weekNumber}`;
            if (!groupedDates[weekKey]) {
                groupedDates[weekKey] = [];
            }

            groupedDates[weekKey].push(format(date, 'dd-MM-yyyy'));
        });

        uniqueEvents.map((event, eventIndex) => {
            listData.push({
                data: [],
                label: event,
                color: EventHelper.getColorByEventName(event) as string,
            });
            if (selectedTotalPeriod === 'Dia') {
                uniqueDates.map(() => {
                    listData[eventIndex].data.push(0);
                });
            } else {
                Object.entries(groupedDates).map(() => {
                    listData[eventIndex].data.push(0);
                });
            }
        });

        if (selectedTotalPeriod === 'Dia') {
            uniqueDates.map((date, indexDate) => {
                driverHistoric.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    if (indexFilterData !== -1 && date === overview.date) {
                        listData[indexFilterData].data[indexDate] += 1;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Semana') {
            Object.entries(groupedDates).map((date, indexDate) => {
                driverHistoric.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += 1;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Mês') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);

                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByMonth).map((date, indexDate) => {
                driverHistoric.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += 1;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Ano') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByYear).forEach((year, datesInYear) => {
                driverHistoric.forEach(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = year[1].findIndex(dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[datesInYear] +=1;
                    }
                });
            });
        }

        return listData;
    }, [selectedTotalPeriod, driverHistoric]);

    const graphTotalSelected: JSX.Element = useMemo(() => {
        if (selectedTotalGraph === 'Barra') {
            return <BarChartStack legends={renderEventsDriverTotalLegendChart} data={renderEventsByDriverTotalDataChart} />;
        }
        if (selectedTotalGraph === 'Linha') {
            return <StackedLineChart legends={renderEventsDriverTotalLegendChart} data={renderChartTotalEventsLineChart} />;
        }
        return <></>;
    }, [renderEventsByDriverTotalDataChart, renderEventsDriverTotalLegendChart, selectedTotalGraph, renderChartTotalEventsLineChart]);

    const { handleSubmit: handleTotalFilterSubmit } = useForm<DriverHistoricFilter>({
        defaultValues: {
            typeOfGraph: 'Barra',
            periodOfGraph: 'Dia',
        },
    });

    const handleApplyTotalFilter = useCallback(() => {
        setSelectedTotalGraph(selectedType);
        setSelectedTotalPeriod(selectedTypeBar);
    }, [selectedType, selectedTypeBar]);

    const contentHistoryDriver = useMemo(() => {
        if (!driverHistoric) {
            return <NoContent message={t('drivers.need_to_select_driver')} />;
        }

        if (responseStatusGraphs.loading) {
            return (
                <>
                    <Skeleton height={'40rem'} width={'20rem'} />
                    <Skeleton height={'40rem'} width={'30rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'450rem'} style={{ marginTop: '-1rem' }} />
                </>
            );
        } else if (responseStatusHistoricEvents.hasError) {
            return <NoContent message={t('general.error')} onClick={handleUpdate} messageClassName="error" />;
        } else if (responseStatusHistoricEvents.void) {
            return <NoContent message={t('general.no_content')} onClick={handleUpdate} />;
        }

        const nameDriver: string =
            driverPagedIdCluster?.elements.length !== undefined
                ? driverPagedIdCluster?.elements.length > 0
                    ? driverPagedIdCluster?.elements[0].driver
                        ? driverPagedIdCluster?.elements[0].driver
                        : driverPagedIdCluster?.elements[0].idCluster
                    : ''
                : '';

        return (
            <>
                <TitleContainer>
                    <div className='historic'>
                        <Title>
                            <h2>{t('drivers.driver_history') + ' - ' + nameDriver}</h2>
                        </Title>
                        <ChartSubTitle>{t('drivers.subtitle_driver_history')}</ChartSubTitle>
                    </div>
                    <div>
                        <button className="filterButton" onClick={handleOpenHistoryOfDriverFilter}>
                            <FilterAlt color={'#46526C'} />
                        </button>
                        <div style={{ display: 'flex', position: 'relative', marginLeft: '1.5625rem' }}>
                            {filterHistoryOfDriversIsOpen && (
                                <GraphsModal
                                    topPosition={-42}
                                    rightPosition={40}
                                    handleCancelButton={handleOpenHistoryOfDriverFilter}
                                    handleCloseGraphFilter={handleOpenHistoryOfDriverFilter}
                                    handleApplyButton={handleTotalFilterSubmit(handleApplyTotalFilter)}
                                >
                                    <FilterOptionContainer onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedType(event.target.value)}>
                                        <p>{t('drivers.type')}</p>
                                        <RadioButtonContainer>
                                            <RadioButton
                                                group="filterchecktype"
                                                checked={selectedType === 'Barra'}
                                                name={String(t('drivers.bar'))}
                                                value={'Barra'}
                                            />
                                            <RadioButton
                                                group="filterchecktype"
                                                checked={selectedType === 'Linha'}
                                                name={String(t('drivers.line'))}
                                                value={'Linha'}
                                            />
                                        </RadioButtonContainer>
                                    </FilterOptionContainer>

                                    <FilterOptionContainer
                                        className="teste"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedTypeBar(event.target.value)}
                                    >
                                        <p>{t('drivers.period')}</p>
                                        <RadioButtonContainer>
                                            <RadioButton
                                                group="filtercheck"
                                                checked={selectedTypeBar === 'Dia'}
                                                name={String(t('drivers.day'))}
                                                value={'Dia'}
                                            />
                                            <RadioButton
                                                group="filtercheck"
                                                checked={selectedTypeBar === 'Semana'}
                                                name={String(t('drivers.week'))}
                                                value={'Semana'}
                                            />
                                            <RadioButton
                                                group="filtercheck"
                                                checked={selectedTypeBar === 'Mês'}
                                                name={String(t('drivers.month'))}
                                                value={'Mês'}
                                            />
                                            <RadioButton
                                                group="filtercheck"
                                                checked={selectedTypeBar === 'Ano'}
                                                name={String(t('drivers.year'))}
                                                value={'Ano'}
                                            />
                                        </RadioButtonContainer>
                                    </FilterOptionContainer>
                                </GraphsModal>
                            )}
                        </div>
                    </div>
                </TitleContainer>
                
                {graphTotalSelected}

                <ModalFilter>
                    
                </ModalFilter>
            </>
        );
    }, [
        filterHistoryOfDriversIsOpen, 
        graphTotalSelected,
        handleOpenHistoryOfDriverFilter,
        handleApplyTotalFilter, 
        handleTotalFilterSubmit, 
        responseStatusHistoricEvents.hasError, 
        responseStatusHistoricEvents.void,
        responseStatusGraphs.loading,
        driverPagedIdCluster?.elements,
        selectedType, 
        selectedTypeBar,
        handleUpdate,
        driverHistoric,
        t
    ]);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between">
                <Title>
                    <h2>Analytics</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
            </Row>

            <MainCard style={{ paddingBottom: '1rem' }}>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>{t('drivers.drivers')}</h3>
                        </Row>

                        <Row align="flex-start" gap={16}>
                            <Row>
                                <DatePicker
                                    endDate={endDate}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    multiple
                                    name="period"
                                    onChange={handleChangeDates}
                                    onChangeRaw={handleChangeDatesRaw}
                                    placeholder={String(t('general.select_period'))}
                                    startDate={startDate}
                                />
                            </Row>
                            <Row align="center" gap={8}>
                                <Button
                                    color={filterIsOpen ? 'purple' : 'outline-purple'}
                                    leftIcon={<FilterAlt color={filterIsOpen ? '#fff' : '#46526C'} />}
                                    size="small"
                                    rightIcon={<ChevronDown spin={filterIsOpen} color={filterIsOpen ? '#fff' : '#46526C'} />}
                                    type="button"
                                    onClick={handleOpenFilters}
                                >
                                    {t('general.filter')}
                                </Button>
                            </Row>
                        </Row>
                    </CardHeader>
                    {filters}
                </Column>
                <Row>
                    <WideCardSection style={{ paddingTop: '1rem' }}>
                        <FilterContainer>
                            <RankingFilter>
                                <ButtonChangeContainer>
                                    <ButtonTab
                                        onClick={() => handleChangeTab(0)}
                                        style={{ backgroundColor: tab === 0 ? '#262D3C' : '#E6E6E6', color: tab === 0 ? '#ffffff' : '#000000' }}
                                    >
                                        {t('drivers.driver_ranking')}
                                    </ButtonTab>
                                    <ButtonTab
                                        onClick={() => handleChangeTab(1)}
                                        style={{ backgroundColor: tab === 1 ? '#262D3C' : '#E6E6E6', color: tab === 1 ? '#ffffff' : '#000000' }}
                                    >
                                        {t('drivers.search_by_id_cluster')}
                                    </ButtonTab>
                                    <ButtonTab
                                        onClick={() => handleChangeTab(2)}
                                        style={{ backgroundColor: tab === 2 ? '#262D3C' : '#E6E6E6', color: tab === 2 ? '#ffffff' : '#000000' }}
                                    >
                                        {t('drivers.search_by_image')}
                                    </ButtonTab>
                                </ButtonChangeContainer>
                                {contentFilter}
                            </RankingFilter>
                        </FilterContainer>
                    </WideCardSection>
                </Row>

                <Row>
                    <WideCardSection>
                        <HistoryContainer>{contentHistoryDriver}</HistoryContainer>
                    </WideCardSection>
                </Row>

                <Row padding="0.8rem" style={{ justifyContent: 'space-between' }}>
                    <GraphSection>
                        <GraphContainer>{contentTimeRange}</GraphContainer>

                        <GraphContainer>{contentHeatMap}</GraphContainer>
                    </GraphSection>
                </Row>

                <Row>
                    <WideCardSection>
                        <HistoryContainer>
                            {driverPagedIdCluster && driverPagedIdCluster.elements.length !== 0 && (
                                <TitleContainer>
                                    <div>
                                        <Title>
                                            <h2>{t('drivers.history_of_events')}</h2>
                                        </Title>
                                        <ChartSubTitle>{t('drivers.subtitle_history_events')}</ChartSubTitle>
                                    </div>
                                </TitleContainer>
                            )}

                            <TableEventHistoryEvents>
                                {content}
                                {driverPagedIdCluster && driverPagedIdCluster.elements.length !== 0 
                                    ? paginationContentEventHistory 
                                    : ''
                                }
                            </TableEventHistoryEvents>
                        </HistoryContainer>
                    </WideCardSection>
                </Row>

                <Row>
                    <WideCardSection>
                        <ImagesContainer>
                            {contentImages}
                            <ChartContent>
                                <ImagesList photoUrlList={photoContent} />
                            </ChartContent>
                        </ImagesContainer>
                    </WideCardSection>
                </Row>
            </MainCard>
        </Column>
    );
}
