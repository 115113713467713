import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'src/modules/auth';

export default function LoginRoute(): JSX.Element {
    const { challenge } = useAuth();

    return useMemo<JSX.Element>(() => {
        if (challenge) {
            if (['SOFTWARE_TOKEN_MFA', 'SMS_MFA'].includes(challenge.challengeName)) {
                return <Navigate to="/login/mfa" />;
            }

            switch (challenge.challengeName) {
                case 'NEW_PASSWORD_REQUIRED':
                    return <Navigate to="/login/first-access" />;
                case 'REDEFINE_PASSWORD_REQUIRED':
                    return <Navigate to="/login/new-password" />;
                case 'SETUP_MFA':
                    return <Navigate to="/login/setup-mfa" />;
                default:
                    return <Outlet />;
            }
        }
        return <Outlet />;
    }, [challenge]);
}
