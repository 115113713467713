export interface Action<T> {
    payload?: T;
    type: 'data' | 'loading' | 'error';
}

export interface State<T> {
    hasError: boolean;
    isLoading: boolean;
    data: T;
}

export type Reducer<T> = (state: State<T>, action: Action<T>) => State<T>;

export function reducer<S>(state: State<S>, action: Action<S>): State<S> {
    if (action?.payload) {
        return { ...state, data: action.payload, hasError: false, isLoading: false };
    }

    switch (action.type) {
        case 'error':
            return { ...state, hasError: true, isLoading: false };
        case 'loading':
            return { ...state, isLoading: true };
        default:
            return state;
    }
}
