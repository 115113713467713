import { api } from 'src/common/infra';

import { ApiResponse, TransformRequestHelper } from '../../../../common';
import { Pagination } from '../../../../common/core/pagination';
import { RegistrationDTO, ResgistrationShipmentsDTO } from '../dtos/registration-shipments.dtos';

class RegistrationShipmentsService {
    public async listRegistrationShipments(data: ResgistrationShipmentsDTO): Promise<Pagination<RegistrationDTO>> {
        const { pageSize, currentPage, name } = data;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<RegistrationDTO>>>('/analytics/sented_emails', {
            params: {
                name,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
        });

        return content;
    }
}

export const registrationShipmentsService = new RegistrationShipmentsService();
