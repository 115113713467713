import { CreatePasswordPolicyDTO, CreatePasswordPolicyFormDTO } from '../dtos/create-password-policy.dto';
export class PasswordPolicyMapper {
    public static fromFormToDTO(data: CreatePasswordPolicyFormDTO & { operationId: number }): CreatePasswordPolicyDTO {
        return {
            active: data.active,
            containsAtLeastOneNumber: data.containsAtLeastOneNumber,
            containsAtLeastOneSpecialCharacter: data.containsAtLeastOneSpecialCharacter,
            description: data.description,
            expiryTime: data.expiryTime ? Number(data.expiryTime) : undefined,
            mfa: data.mfa,
            name: data.name.trim().toUpperCase(),
            operationId: data.operationId,
            passwordMinimumLength: data.passwordMinimumLength ? Number(data.passwordMinimumLength) : undefined,
            containsAtLeastOneUppercaseLetter: data.containsAtLeastOneUppercaseLetter,
            containsAtLeastOneLowercaseLetter: data.containsAtLeastOneLowercaseLetter,
            passwordDiffFrom: data.passwordDiffFrom ? Number(data.passwordDiffFrom) : undefined,
        };
    }
}
