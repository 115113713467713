import styled from 'styled-components';

export const TableOptionButton = styled.button`
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border: 0;

    background-color: transparent;
    transition: all 0.2s ease-in-out;

    &::after {
        content: '';
        width: 36px;
        height: 36px;
        position: absolute;
        border-radius: 50%;
        opacity: 0.4;
    }

    &:hover::after {
        background-color: #f0f0f0;
    }
`;
