import { createContext, MouseEvent, useCallback, useMemo, useState } from 'react';
import { FunctionComponent, ResponseStatus } from 'src/common';

import { SyntheticDataDTO } from '../../dtos/event.dto';
import { getSyntheticData } from '../../services';
import { SyntheticDataContextProps } from './types';

export const SyntheticDataContext = createContext<SyntheticDataContextProps>({} as SyntheticDataContextProps);

export default function SyntheticDataProvider({ children }: FunctionComponent) {
    const [syntheticData, setSyntheticData] = useState<SyntheticDataDTO | null>(null);
    const [selectedChartFilter, setSelectedChartFilter] = useState<'7_DAYS' | '24_HOURS' | null>(null);
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: true,
    });

    const handleSelectChartFilter = useCallback((e: MouseEvent<HTMLInputElement>) => {
        setSelectedChartFilter(e.currentTarget.name as '7_DAYS' | '24_HOURS');
    }, []);

    const handleResetChartFilter = useCallback(() => {
        setSelectedChartFilter('24_HOURS');
    }, []);

    const handleGetSyntheticData = useCallback(() => {
        if (!selectedChartFilter) return;
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));
        getSyntheticData
            .execute({ period: selectedChartFilter })
            .then(data => {
                setSyntheticData(data);
                setResponseStatus({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: !data,
                    success: true,
                });
            })
            .catch(reason =>
                setResponseStatus({
                    loading: false,
                    error: reason,
                    hasError: true,
                    void: false,
                    success: false,
                }),
            );
    }, [selectedChartFilter]);

    const data = useMemo<SyntheticDataContextProps>(
        () => ({ handleResetChartFilter, handleSelectChartFilter, handleGetSyntheticData, responseStatus, selectedChartFilter, syntheticData }),
        [handleGetSyntheticData, handleResetChartFilter, handleSelectChartFilter, responseStatus, selectedChartFilter, syntheticData],
    );

    return <SyntheticDataContext.Provider value={data}>{children}</SyntheticDataContext.Provider>;
}
