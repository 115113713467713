import { SVGProps, useMemo } from 'react';
import { CSSProperties } from 'styled-components';

interface ChevronDownProps extends SVGProps<SVGSVGElement> {
    color?: string;
    spin?: boolean;
}

export default function ChevronDown({ color = '#fff', spin, ...props }: ChevronDownProps) {
    const style: CSSProperties = useMemo(
        () => ({
            transform: spin ? 'rotate(180deg)' : undefined,
            transition: '0.4s ease-in-out'
        }),
        [spin],
    );

    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} {...props}>
            <path
                d="M6.99995 7.84717C7.26362 7.83963 7.50469 7.7417 7.70056 7.53076L13.426 1.66971C13.5917 1.50398 13.6821 1.29304 13.6821 1.04443C13.6821 0.547224 13.2904 0.147949 12.7932 0.147949C12.5521 0.147949 12.3186 0.245884 12.1453 0.419155L7.00748 5.70013L1.85458 0.419155C1.68131 0.253418 1.4553 0.147949 1.2067 0.147949C0.70949 0.147949 0.317749 0.547224 0.317749 1.04443C0.317749 1.29304 0.408151 1.50398 0.573887 1.66971L6.30687 7.53076C6.51027 7.7417 6.73628 7.84717 6.99995 7.84717Z"
                fill={color}
            />
        </svg>
    );
}
