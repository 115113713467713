import { Tooltip } from '@mui/material';
import { addDays, isValid, subDays } from 'date-fns';
import { FocusEvent, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CardHeader,
    Checkbox,
    Column,
    Content,
    DatePicker,
    Divider,
    FilterButton,
    IOSSwitch,
    MainCard,
    NoContent,
    Pagination,
    RenderIf,
    Row,
    SelectNumber,
    Skeleton,
    UsedFilters,
} from 'src/common/components';
import { listPagination } from 'src/common/constants';
import { Layout } from 'src/common/contexts/list-layout/types';
import { ASCII } from 'src/common/helpers/ascii.helper';
import { DateHelper } from 'src/common/helpers/date.helper';
import { ObjectHelper } from 'src/common/helpers/object.helper';
import { useEventListener, useListLayout, useSearch } from 'src/common/hooks';
import i18n from 'src/common/infra/translations';
import { EventDTO, EventRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { useEvents } from 'src/modules/alarm-processing/hooks/use-events';

import { TypeSearch } from '../../../../common/constants/type-search';
import { useAuth } from '../../../auth';
import { userProfile } from '../../../auth/constants/user-profile';
import { INITIAL_REQUEST_DATA } from '../../contexts/events/provider';
import { useSortReviewEvent } from '../../hooks';
import { filterByAlarmsName } from '../../services/filter-by-alarms-name';
import AlarmsFilter from '../alarms-filter';
import CardList from '../card-list';
import TableList from '../table-list';
import { AutomaticSearchOptionsSwitch, AutomaticSearchTitle } from './styles';

export interface SortProps {
    keyOrder: string;
    sortOrder: string;
}

export interface FilterNamePropsEvents {
    language: string;
    languagePtbr: string;
    checked: boolean;
    real_name: string;
}

const alarmArray = [
    {
        pt_br: 'Condução perigosa N2',
        en: 'Dangerous driving N2',
        es: 'Conducción peligrosa N2',
        it: 'Guida pericolosa N2',
        fr: 'Conduite dangereuse N2',
    },
    {
        pt_br: 'Condução perigosa N1',
        en: 'Dangerous driving N1',
        es: 'Conducción peligrosa N1',
        it: 'Guida pericolosa N1',
        fr: 'Conduite dangereuse N1',
    },
    {
        pt_br: 'Celular',
        en: 'Cell phone',
        es: 'Teléfono móvil',
        it: 'Cellulare',
        fr: 'Téléphone portable',
    },
    {
        pt_br: 'Sem cinto',
        en: 'No seatbelt',
        es: 'Sin cinturón de seguridad',
        it: 'Senza cintura di sicurezza',
        fr: 'Pas de ceinture de sécurité',
    },
    {
        pt_br: 'Fumando',
        en: 'Smoking',
        es: 'Fumando',
        it: 'Fumare',
        fr: 'Fumer',
    },
    {
        pt_br: 'Oclusão',
        en: 'Occlusion',
        es: 'Oclusión',
        it: 'Oclusione',
        fr: 'Occlusion',
    },
    {
        pt_br: 'Bocejo',
        en: 'Yawning',
        es: 'Bostezo',
        it: 'Sbadiglio',
        fr: 'Bâillement',
    },
    {
        pt_br: 'Face missing',
        en: 'Face missing',
        es: 'Face missing',
        it: 'Face missing',
        fr: 'Face missing',
    },
    {
        pt_br: 'Óculos de sol',
        en: 'Sunglasses',
        es: 'Gafas de sol',
        it: 'Occhiali da sole',
        fr: 'Lunettes de soleil',
    },
];

const initialSortValues: SortProps[] = [
    {
        keyOrder: 'Prioridade',
        sortOrder: 'ASC',
    },
    {
        keyOrder: 'Chegada',
        sortOrder: 'ASC',
    },
];

export default function AlarmsContainer() {
    const checkAllRef = useRef<HTMLInputElement>(null);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(DateHelper.yesterday);
    const [pagination, setPagination] = useState(1);
    const [names, setNames] = useState<FilterNamePropsEvents[]>([]);
    const [sortElements, newSortElements] = useState<SortProps[]>(initialSortValues);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
    const [isAutomaticSearch, setIsAutomaticSearch] = useState(false);

    const { session } = useAuth();

    const { layout } = useListLayout();
    const { eventScreenType, requestData, handleGetList, responseStatus, handleRequestList, events } = useEvents();
    const { allOnThisPageAreSelected, handleDeselectAll, handleSelectAll: selectAll, selectedEvents } = useSortReviewEvent();
    const { formRef, inputRef, clear } = useSearch();
    const { t } = useTranslation();
    const filterNamesChecked = useMemo(() => names?.filter(name => name.checked).map(obj => obj.real_name), [names]);

    const selectedLanguage = useCallback(() => {
        switch (i18n.language) {
            case 'es':
                return 'es';
            case 'pt-BR':
            case 'pt':
                return 'pt_br';
            case 'it':
                return 'it';
            case 'fr':
                return 'fr';
            default:
                return 'en';
        }
    }, []);

    const filterUniqueValuesByKey = useCallback((objectsArray: FilterNamePropsEvents[]) => {
        const uniqueValues = [];
        const uniqueValuesSet = new Set();

        for (const obj of objectsArray) {
            const uniqueValue = obj.language;
            if (!uniqueValuesSet.has(uniqueValue)) {
                uniqueValues.push(obj);
                uniqueValuesSet.add(uniqueValue);
            }
        }

        return uniqueValues;
    }, []);

    const filterByAlarmName = useCallback(async () => {
        if (eventScreenType !== 'pending') {
            const response = await filterByAlarmsName.execute();
            const responseMap: FilterNamePropsEvents[] = response.map((obj: any) => {
                return {
                    language: obj[selectedLanguage()],
                    languagePtbr: obj.pt_br,
                    real_name: obj.real_name,
                    checked: obj.checked,
                };
            });
            const responseFiltered = filterUniqueValuesByKey(responseMap);
            setNames(responseFiltered);
        } else {
            const responseMap: FilterNamePropsEvents[] = alarmArray.map(alarm => {
                return {
                    language: alarm[selectedLanguage()],
                    languagePtbr: alarm.pt_br,
                    real_name: alarm.pt_br,
                    checked: false,
                };
            });
            setNames(responseMap);
        }
    }, [eventScreenType, filterUniqueValuesByKey, selectedLanguage]);

    useEffect(() => {
        filterByAlarmName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByAlarmName, i18n.language]);

    const handleHeaderClick = useCallback(
        (event: MouseEvent, thData: string) => {
            const clickedHeader = thData;
            const isShiftKeyPressed = event.shiftKey;

            if (isShiftKeyPressed) {
                if (selectedHeaders.length < 2) {
                    // Add the clicked header to the selected headers array
                    setSelectedHeaders(prevSelectedHeaders => [...prevSelectedHeaders, clickedHeader]);
                    // Add a class to indicate selection
                }

                if (selectedHeaders.length === 1) {
                    setSelectedHeaders([]);
                }
            } else {
                setSelectedHeaders([]);
            }
        },
        [selectedHeaders],
    );

    const content: JSX.Element = useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Content className="mb-16">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        }

        if (responseStatus?.hasError) {
            return (
                <Content>
                    <span className="error">{t('general.error')}</span>
                </Content>
            );
        }

        if (responseStatus?.void) {
            return <NoContent message={t('general.no_content_specific_alarm')} onClick={handleGetList} />;
        }

        return layout === Layout.CARD ? (
            <CardList />
        ) : (
            <TableList
                currentPage={pagination}
                initialDate={startDate!}
                finalDate={endDate!}
                alarmsName={names?.filter(name => name.checked).map(obj => obj.languagePtbr)}
                handleHeaderClick={handleHeaderClick}
                sortElements={sortElements}
                newSortElements={newSortElements}
            />
        );
    }, [
        endDate,
        handleGetList,
        handleHeaderClick,
        layout,
        names,
        pagination,
        responseStatus?.hasError,
        responseStatus.loading,
        responseStatus?.void,
        sortElements,
        startDate,
        t,
    ]);

    const setPaginationAndHandleRequestList = useCallback(
        (currentPage: number) => {
            setPagination(currentPage);
            handleRequestList({ currentPage });
        },
        [handleRequestList],
    );

    const paginationContent = useMemo(() => {
        if ((!responseStatus?.void && responseStatus?.success) || (requestData?.currentPage && requestData?.currentPage > 1)) {
            return (
                <>
                    <Divider />
                    <Pagination
                        onPageChange={currentPage => setPaginationAndHandleRequestList(currentPage)}
                        currentPage={requestData?.currentPage}
                        perPage={requestData?.pageSize}
                        total={events?.totalElements}
                    />
                </>
            );
        }

        return null;
    }, [
        events?.totalElements,
        requestData?.currentPage,
        requestData?.pageSize,
        responseStatus?.success,
        responseStatus?.void,
        setPaginationAndHandleRequestList,
    ]);

    const maxDate = useMemo(() => (startDate ? addDays(startDate, 6) : new Date()), [startDate]);
    const minDate = useMemo(() => (endDate ? subDays(endDate, 6) : undefined), [endDate]);

    const usedFilters = useMemo(
        () => ({
            alarmLevel: requestData?.alarmLevel,
            alarmType: requestData?.alarmType,
            board: requestData?.board,
            endTime: requestData?.endTime,
            eventType: requestData?.eventType,
            operation: requestData?.operation,
            startTime: requestData?.startTime,
        }),
        [
            requestData?.alarmLevel,
            requestData?.alarmType,
            requestData?.board,
            requestData?.endTime,
            requestData?.eventType,
            requestData?.operation,
            requestData?.startTime,
        ],
    );

    const handleChangeDates = useCallback(
        (dates: [Date, Date]) => {
            setStartDate(dates[0]);
            setEndDate(dates[1]);

            if (isValid(dates[0]) && isValid(dates[1])) {
                selectAll([]);
                handleRequestList({
                    initialDate: dates[0],
                    finalDate: dates[1],
                });
            }
        },
        [handleRequestList, selectAll],
    );

    const handleChangeDatesRaw = useCallback((event: FocusEvent<HTMLInputElement, Element>) => {
        event.preventDefault();
    }, []);

    const handleClearFilterValue = useCallback(
        (key: keyof EventRequestDTO) => {
            handleRequestList({
                [key]: '',
            });
            setFilterIsOpen(false);
        },
        [handleRequestList],
    );

    const handleClearFilters = useCallback(() => {
        handleRequestList({
            currentPage: 1,
            alarmLevel: '',
            alarmType: '',
            board: '',
            endTime: '',
            eventType: '',
            operation: '',
            startTime: '',
        });
        setFilterIsOpen(false);
    }, [handleRequestList]);

    const handleNormalizeInput = useCallback((e: Event) => {
        const event = e as unknown as FormEvent<HTMLInputElement>;

        event.currentTarget.value = ASCII.format(event.currentTarget.value);
    }, []);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();
            selectAll([]);

            const { target } = e;

            const currentTarget = target as unknown as HTMLFormElement;

            const text: HTMLInputElement = currentTarget?.elements?.namedItem('text') as HTMLInputElement;

            setFilterIsOpen(false);

            const req: Partial<EventRequestDTO> = INITIAL_REQUEST_DATA;

            delete req?.finalDate;
            delete req?.initialDate;
            delete req?.pageSize;
            delete req?.treatedAlarms;
            delete req?.typeAlarmDistribution;
            delete req?.analytics;

            handleRequestList({
                ...req,
                currentPage: 1,
                text: text.value,
                typeSearch: TypeSearch.TEXT,
            });
        },
        [handleRequestList, selectAll],
    );

    const handleSelectAll = useCallback(() => {
        if (events?.elements) {
            selectAll(events.elements.map(e => e as EventDTO));
        }
    }, [events, selectAll]);

    document.addEventListener('keydown', (event: KeyboardEvent) => {
        const modalDialog = document.getElementById('alarm-details-dialog') as HTMLDialogElement;
        const modalIsOpen = modalDialog?.open;

        if (event.key === 'Escape' && selectedEvents.length > 0 && !modalIsOpen) {
            handleDeselectAll();
        }
    });

    const usedFiltersComponent = useMemo(
        () =>
            !responseStatus?.loading &&
            !ObjectHelper.isEmpty(usedFilters as any) && (
                <UsedFilters data={usedFilters} onClear={handleClearFilterValue} onClearAll={handleClearFilters} />
            ),
        [handleClearFilterValue, handleClearFilters, responseStatus?.loading, usedFilters],
    );

    useEffect(() => {
        setFilterIsOpen(false);

        if (eventScreenType === 'events' && checkAllRef?.current) {
            checkAllRef.current.checked = false;
        }
    }, [eventScreenType]);

    useEffect(() => {
        return () => clear();
    }, [clear, eventScreenType]);

    useEffect(() => {
        setStartDate(new Date());
        setEndDate(new Date());

        handleRequestList({
            initialDate: new Date(),
            finalDate: new Date(),
            currentPage: 1,
            alarmsName: filterNamesChecked,
            treatedAlarms: eventScreenType === 'events',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventScreenType, handleRequestList]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isAutomaticSearch) {
            interval = setInterval(() => {
                handleGetList(isAutomaticSearch);
            }, 30000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [handleGetList, isAutomaticSearch]);

    const handleSearchClear = useCallback(() => {
        selectAll([]);
        setFilterIsOpen(false);

        const req: Partial<EventRequestDTO> = INITIAL_REQUEST_DATA;

        delete req?.finalDate;
        delete req?.initialDate;
        delete req?.pageSize;
        delete req?.treatedAlarms;
        delete req?.typeAlarmDistribution;
        delete req?.analytics;

        handleRequestList({
            ...req,
            currentPage: 1,
            text: '',
            typeSearch: TypeSearch.TEXT,
        });
    }, [handleRequestList, selectAll]);

    document?.getElementById('text')?.addEventListener('search', function (event: any) {
        if (event.type === 'search') {
            if (event.currentTarget.value === '') {
                handleSearchClear();
            }
        }
    });

    useEventListener(formRef.current, 'submit', handleSearch);
    useEventListener(inputRef.current, 'input', handleNormalizeInput);

    const handleAutomaticSearch = useCallback(
        async (checked: boolean) => {
            setIsAutomaticSearch(checked);

            if (checked) {
                handleGetList();
            }
        },
        [handleGetList],
    );

    return (
        <MainCard>
            <Column width="100%" gap={16}>
                <CardHeader>
                    <Row gap={16} align="center">
                        <h3>
                            {eventScreenType === 'events' ? t('general.events') : t('menu.pending_alarms')}
                            {responseStatus?.success && !responseStatus?.loading && <span className="total">({events?.totalElements || 0})</span>}
                        </h3>
                        <Checkbox
                            ref={checkAllRef}
                            onChange={handleSelectAll}
                            checked={allOnThisPageAreSelected}
                            mode="blue-check"
                            name="checkAll"
                            hidden={eventScreenType === 'events' || layout === Layout.LINE}
                        />
                    </Row>

                    <Row align="flex-start" gap={16}>
                        <Row align="center" gap={8}>
                            <AutomaticSearchOptionsSwitch>
                                <Tooltip title={t('alarms.automatic_search_tooltip')} placement="top">
                                    <div className="tooltip-container">
                                        <AutomaticSearchTitle isAutomaticSearch={isAutomaticSearch} className="per-page">
                                            {t('alarms.automatic_search')}
                                        </AutomaticSearchTitle>
                                        <IOSSwitch checked={isAutomaticSearch} onChange={(e: any) => handleAutomaticSearch(e.target.checked)} />
                                    </div>
                                </Tooltip>
                            </AutomaticSearchOptionsSwitch>

                            <span className="per-page">{t('general.items_per_page')}:</span>
                            <SelectNumber
                                fontMode="small"
                                value={requestData?.pageSize || 10}
                                options={listPagination}
                                onChange={e =>
                                    handleRequestList({
                                        pageSize: Number(e.target.value),
                                        currentPage: 1,
                                    })
                                }
                            />
                            {(eventScreenType === 'events' || session?.user.profile !== userProfile.analyst) && (
                                <DatePicker
                                    endDate={endDate}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    multiple
                                    name="period"
                                    onChange={handleChangeDates}
                                    onChangeRaw={handleChangeDatesRaw}
                                    placeholder={String(t('general.select_period'))}
                                    startDate={startDate}
                                />
                            )}

                            <FilterButton isOpen={filterIsOpen} onOpen={setFilterIsOpen} />
                        </Row>
                    </Row>
                </CardHeader>
                <Divider />
                <RenderIf condition={filterIsOpen}>
                    <AlarmsFilter names={names} setNames={setNames} />
                </RenderIf>
                {usedFiltersComponent}
                {content}
                {paginationContent}
            </Column>
        </MainCard>
    );
}
