import { Service, api } from '../../../common';
import { InterventionByIdDTO  } from '../dtos/intervention.dto';

class InterventionByIdService implements Service<InterventionByIdDTO, any> {
    public async execute({ interventionId }: InterventionByIdDTO) {
        const {
            data: { content },
        } = await api.get<any>(`/interventions/${interventionId}`);

        return content;
    }
}

export const interventionByIdService = new InterventionByIdService();
