import { Divider, SelectChangeEvent, Slider } from '@mui/material';
import { addDays, subDays, isValid } from 'date-fns';
import { useCallback, useMemo, useState, FocusEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ChevronDown,
    Column,
    Content,
    DateHelper,
    DatePicker,
    FilterAlt,
    NoContent,
    Skeleton,
    Title,
    Input,
    Row,
    MainCard,
    CardHeader,
    usePermission,
    RenderIf,
} from 'src/common';
import { Button } from 'src/common/components';
import { BarChartDataStackProps, BarChartStack } from 'src/common/components/graphics/bar-chart-stack';
import { BarPolar, BarPolarDataProps } from 'src/common/components/graphics/bar-polar';
import { FilterGraph } from 'src/common/components/graphics/filter-graphs';
import { ScatterChart } from 'src/common/components/graphics/scatter-chart';
import RadioButton from 'src/common/components/radio-button';
import { EventsFilter, EventsProps } from 'src/modules/analytics/components/events-filter';

import Radio from '../../../common/components/inputs/radio';
import UpdateData from '../../../common/components/update-data';
import { EventHelper } from '../../../common/helpers/events.helper';
import { typeEquipament } from '../../../modules/analytics/common/consts/typeEquipaments';
import { PeriodFilter } from '../../../modules/analytics/period/components/period-filter';
import { IPerDayAndHourFilter, ITimeRangeFilter } from '../../../modules/analytics/period/dtos/filter-period.dtos';
import { PeriodDTO } from '../../../modules/analytics/period/dtos/period.dtos';
import { useAnalyticsPeriod } from '../../../modules/analytics/period/hook';
import {
    BarPolarFilter,
    ChartContainer,
    ChartContainerLineChart,
    DivRounded,
    FilterDayAndTimeContainer,
    FilterTimeRangeRadioContainer,
    GraphSection,
    RadioText,
    RadiosCardHeader,
    ScatterChartFilter,
    SubTitle,
    TitleContainer,
} from './styles';

const hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
];

export function Period() {
    const { t } = useTranslation();
    const { hasTelemetry } = usePermission();

    const { handleRequestList, listPeriod, handleGetList, responseStatus, requestData, handleFilterIsOpen, filterIsOpen } = useAnalyticsPeriod();

    const [sliderValue, setSliderValue] = useState<number>(1);
    const [selectedBarPolarPeriod, setSelectedBarPolarPeriod] = useState<string>('polar');
    const [changeGraph, setChangeGraph] = useState<string>('polar');
    const [filterGraphBarPolarIsOpen, setFilterGraphBarPolarIsOpen] = useState(false);

    const [worksTracksValue, setWorksTracksValue] = useState<number>(7);
    const [uniqueEvents, setUniqueEvents] = useState<EventsProps[]>([]);
    const [uniqueEventsFilter, setUniqueEventsFilter] = useState<EventsProps[]>([]);
    const [filterGraphLineChartIsOpen, setFilterGraphLineChartIsOpen] = useState(false);

    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [startDate, setStartDate] = useState<Date | null>(DateHelper.yesterday);
    const maxDate = useMemo(() => (startDate ? addDays(startDate, 30) : new Date()), [startDate]);
    const minDate = useMemo(() => (endDate ? subDays(endDate, 30) : undefined), [endDate]);

    const filters = useMemo(() => {
        if (filterIsOpen) {
            return <PeriodFilter />;
        }

        return null;
    }, [filterIsOpen]);

    const closeDayAndHourFilterGraph = useCallback(() => {
        setFilterGraphLineChartIsOpen(state => !state);
    }, []);

    function calculateValue(value: number) {
        switch (value) {
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
                return 3;
            case 4:
                return 4;
            case 5:
                return 6;
            case 6:
                return 12;
            default:
                return 0;
        }
    }

    const marks = [1, 2, 3, 4, 5, 6].map(value => ({
        value,
        label: calculateValue(value),
    }));

    const handleUpdate = useCallback(async () => {
        handleGetList();
    }, [handleGetList]);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    useEffect(() => {
        const allEvents: EventsProps[] = [];

        listPeriod.forEach(period => {
            const existingEvent = allEvents.find(e => e.name === period.event);
            if (!existingEvent) {
                allEvents.push({
                    name: period.event,
                    translate: t(`tableEventName.${period.event}`),
                    checked: false,
                });
            }
        });

        setUniqueEvents(allEvents);
    }, [listPeriod, t]);

    const handleChangeDates = useCallback(
        (dates: [Date, Date]) => {
            setStartDate(dates[0]);
            setEndDate(dates[1]);

            if (isValid(dates[0]) && isValid(dates[1])) {
                handleRequestList({
                    dateFrom: dates[0],
                    dateTo: dates[1],
                });
            }
        },
        [handleRequestList],
    );

    const handleChangeDatesRaw = useCallback((event: FocusEvent<HTMLInputElement, Element>) => {
        event.preventDefault();
    }, []);

    const handleOpenGraphTimeRangeFilters = useCallback(() => {
        setFilterGraphBarPolarIsOpen(state => !state);
    }, []);

    const handleOpenGraphDayAndHourFilters = useCallback(() => {
        setFilterGraphLineChartIsOpen(state => !state);
    }, []);

    const renderEventsDaysOfWeekLegends = useCallback(() => {
        const dates = [...new Set(listPeriod?.map(data => data.date))];

        return dates;
    }, [listPeriod]);

    const renderChartDaysOfTheWeekEvents = useCallback(() => {
        const content: Array<BarChartDataStackProps> = [];

        const typeEquipamentSelected = requestData.equipament === 'Telemetria' ? 'Geotab' : requestData.equipament;

        const labels = typeEquipament.filter(equipament => equipament.type === typeEquipamentSelected);

        labels.forEach(label => {
            content.push({
                label: label.name,
                data: [],
                color: EventHelper.getColorByEventName(label.name),
            });
        });

        const groupedEvents: Array<Omit<PeriodDTO, 'hour'>> = [];

        listPeriod.forEach(period => {
            const index = groupedEvents.findIndex(data => data.date === period.date && data.event === period.event);

            if (index > 0) {
                groupedEvents[index].total += period.total;
            } else {
                groupedEvents.push({
                    date: period.date,
                    event: period.event,
                    total: period.total,
                });
            }
        });

        const legends = renderEventsDaysOfWeekLegends();

        legends.forEach(legend => {
            content.forEach(serie => {
                const groupFound = groupedEvents.find(group => group.event === serie.label && group.date === legend);

                serie.data.push(groupFound ? groupFound.total : 0);
            });
        });

        return content;
    }, [listPeriod, renderEventsDaysOfWeekLegends, requestData.equipament]);

    const renderEventsByTimeRangeLegendChart = useMemo(() => {
        const interval = Number(sliderValue > 4 ? (sliderValue == 5 ? 6 : 12) : sliderValue);

        const hoursArray = Array.from({ length: 25 }, (_, index) => index);

        const labels = [];

        for (let i = 0; i < hoursArray.length; i += interval) {
            const groupStart = hoursArray[i];
            const groupEnd = Math.min(groupStart + interval - 1, 24);

            if (groupStart < 24) {
                const endLabel = Math.min(groupEnd, 24);
                labels.push(interval === 1 ? `${endLabel}H` : `${groupStart}-${endLabel}H`);
            }
        }

        return labels;
    }, [sliderValue]);

    const renderEventsByHourRange = useMemo(() => {
        const content: BarPolarDataProps[] = [];

        const typeEquipamentSelected = requestData.equipament === 'Telemetria' ? 'Geotab' : requestData.equipament;

        const labels = typeEquipament.filter(equipament => equipament.type === typeEquipamentSelected);

        labels.forEach(label => {
            content.push({
                label: label.name,
                data: [],
                color: EventHelper.getColorByEventName(label.name),
            });
        });

        const groupedEvents: Array<{ event: string; hourGroup: string; total: number }> = [];

        const interval: number = sliderValue > 4 ? (sliderValue == 5 ? 6 : 12) : sliderValue;

        listPeriod.forEach(period => {
            const hourGroup = Math.floor(period.hour / interval) * interval;

            const hourGroupLabel = interval == 1 ? `${hourGroup}H` : `${hourGroup}-${Number(hourGroup) + Number(interval - 1)}H`;

            const itemIndex = groupedEvents.findIndex(item => item.event === period.event && item.hourGroup === hourGroupLabel);

            if (itemIndex > 0) {
                groupedEvents[itemIndex].total += period.total;
            } else {
                groupedEvents.push({
                    event: period.event,
                    hourGroup: hourGroupLabel,
                    total: period.total,
                });
            }
        });

        content.forEach(serie => {
            renderEventsByTimeRangeLegendChart.forEach(hour => {
                const eventFound = groupedEvents.find(groupedEvent => groupedEvent.event === serie.label && groupedEvent.hourGroup === hour);

                serie.data.push(eventFound ? eventFound.total : 0);
            });
        });

        return content;
    }, [listPeriod, renderEventsByTimeRangeLegendChart, requestData.equipament, sliderValue]);

    const renderEventsByDayAndHourLegends = useCallback(() => {
        const dates = [...new Set(listPeriod?.map(data => data.date))];

        return dates;
    }, [listPeriod]);

    const renderEventsByDayAndHourData = useCallback(() => {
        const legends = renderEventsByDayAndHourLegends();

        const content: Array<{ label: string; total: number[] }> = [];

        legends.forEach(legend => {
            content.push({
                label: legend,
                total: [],
            });
        });

        const groupedEvents: Array<Omit<PeriodDTO, 'event'>> = [];

        const listPeriodFilter =
            uniqueEventsFilter.length > 0
                ? listPeriod.filter(period => uniqueEventsFilter.some(event => period.event.includes(event.name)))
                : listPeriod;

        listPeriodFilter.forEach(period => {
            const index = groupedEvents.findIndex(data => data.date === period.date && data.hour === Number(period.hour));

            if (index !== -1) {
                groupedEvents[index].total += period.total;
            } else {
                groupedEvents.push({
                    date: period.date,
                    hour: period.hour,
                    total: period.total,
                });
            }
        });

        content.forEach(data => {
            hours.forEach(hour => {
                const periodFound = groupedEvents.find(period => period.date === data.label && period.hour === Number(hour));

                data.total.push(periodFound ? periodFound.total : 0);
            });
        });

        const transformedData = content.map((data, indexRow) => {
            return data.total.map((total, indexValue) => {
                return [indexValue, indexRow, total];
            });
        });

        return transformedData.flat();
    }, [listPeriod, renderEventsByDayAndHourLegends, uniqueEventsFilter]);

    const content = useMemo(() => {
        return (
            <Content className="mb-16" style={{ display: 'flex', flexDirection: 'column' }}>
                <Skeleton width={'30%'} height={'2rem'} />
                <Skeleton width={'70%'} height={'2rem'} />
                <Skeleton height={'20rem'} style={{ marginTop: '1rem' }} />
            </Content>
        );
    }, []);

    const {
        register: registerRadioButton,
        handleSubmit: handleRadioButton,
        watch,
    } = useForm<ITimeRangeFilter>({
        defaultValues: {
            slider: sliderValue,
            typeCoordinates: 'polar',
        },
    });

    const handleApplyTimeRangeFilter = useCallback(
        (data: ITimeRangeFilter) => {
            const { slider } = data;

            setChangeGraph(selectedBarPolarPeriod);
            setSliderValue(slider);

            setFilterGraphBarPolarIsOpen(false);
        },
        [selectedBarPolarPeriod],
    );

    const contentCartesian = useMemo(() => {
        if (responseStatus?.loading) {
            return content;
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return (
            <>
                <div className="filterDiv">
                    <TitleContainer>
                        <Title>
                            <h2>{t('period.time_range')}</h2>
                        </Title>
                        <SubTitle>{t('period.cartesianChart')}</SubTitle>
                    </TitleContainer>
                    <div>
                        <button className="filterButton" onClick={handleOpenGraphTimeRangeFilters}>
                            <FilterAlt color={'#46526C'} />
                        </button>
                        <BarPolarFilter>
                            {filterGraphBarPolarIsOpen && (
                                <FilterGraph
                                    handleCancelButton={handleOpenGraphTimeRangeFilters}
                                    handleApplyButton={handleRadioButton(handleApplyTimeRangeFilter)}
                                >
                                    <div>
                                        <RadioText>{t('period.hours_per_bar')}</RadioText>
                                        <div className="sliderInput">
                                            <Slider
                                                {...registerRadioButton('slider')}
                                                marks={marks}
                                                step={null}
                                                min={1}
                                                max={6}
                                                value={watch('slider')}
                                            />
                                        </div>
                                    </div>
                                    <div className="radioInput">
                                        <RadioText>{t('period.coordinates')}</RadioText>
                                        <FilterTimeRangeRadioContainer
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedBarPolarPeriod(event.target.value)}
                                        >
                                            <RadioButton
                                                {...registerRadioButton('typeCoordinates')}
                                                group="barStackType"
                                                checked={selectedBarPolarPeriod === 'polar'}
                                                name={t('period.polar')}
                                                value="polar"
                                            />
                                            <RadioButton
                                                {...registerRadioButton('typeCoordinates')}
                                                group="barStackType"
                                                checked={selectedBarPolarPeriod === 'cartesian'}
                                                name={t('period.cartesian')}
                                                value="cartesian"
                                            />
                                        </FilterTimeRangeRadioContainer>
                                    </div>
                                </FilterGraph>
                            )}
                        </BarPolarFilter>
                    </div>
                </div>
                <ChartContainer>
                    <BarChartStack data={renderEventsByHourRange} legends={renderEventsByTimeRangeLegendChart} />
                </ChartContainer>
            </>
        );
    }, [
        content,
        filterGraphBarPolarIsOpen,
        handleApplyTimeRangeFilter,
        handleGetList,
        handleOpenGraphTimeRangeFilters,
        handleRadioButton,
        marks,
        registerRadioButton,
        renderEventsByHourRange,
        renderEventsByTimeRangeLegendChart,
        responseStatus?.hasError,
        responseStatus?.loading,
        responseStatus?.void,
        selectedBarPolarPeriod,
        t,
        watch,
    ]);

    const contentPolar = useMemo(() => {
        if (responseStatus?.loading) {
            return content;
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return (
            <>
                <div className="filterDiv">
                    <TitleContainer>
                        <Title>
                            <h2>{t('period.time_range')}</h2>
                        </Title>
                        <SubTitle>{t('period.cartesianChart')}</SubTitle>
                    </TitleContainer>
                    <div>
                        <button className="filterButton" onClick={handleOpenGraphTimeRangeFilters}>
                            <FilterAlt color={'#46526C'} />
                        </button>
                        <BarPolarFilter>
                            {filterGraphBarPolarIsOpen && (
                                <FilterGraph
                                    handleCancelButton={handleOpenGraphTimeRangeFilters}
                                    handleApplyButton={handleRadioButton(handleApplyTimeRangeFilter)}
                                >
                                    <div>
                                        <RadioText>{t('period.hours_per_bar')}</RadioText>
                                        <div className="sliderInput">
                                            <Slider
                                                {...registerRadioButton('slider')}
                                                marks={marks}
                                                step={null}
                                                min={1}
                                                max={6}
                                                value={watch('slider')}
                                            />
                                        </div>
                                    </div>
                                    <div className="radioInput">
                                        <RadioText>{t('period.coordinates')}</RadioText>
                                        <FilterTimeRangeRadioContainer
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedBarPolarPeriod(event.target.value)}
                                        >
                                            <RadioButton
                                                {...registerRadioButton('typeCoordinates')}
                                                group="barStackType"
                                                checked={selectedBarPolarPeriod === 'polar'}
                                                name={t('period.polar')}
                                                value="polar"
                                            />
                                            <RadioButton
                                                {...registerRadioButton('typeCoordinates')}
                                                group="barStackType"
                                                checked={selectedBarPolarPeriod === 'cartesian'}
                                                name={t('period.cartesian')}
                                                value="cartesian"
                                            />
                                        </FilterTimeRangeRadioContainer>
                                    </div>
                                </FilterGraph>
                            )}
                        </BarPolarFilter>
                    </div>
                </div>
                <ChartContainer>
                    <BarPolar dataX={renderEventsByHourRange} dataY={renderEventsByTimeRangeLegendChart} />
                </ChartContainer>
            </>
        );
    }, [
        responseStatus?.loading,
        responseStatus?.hasError,
        responseStatus?.void,
        t,
        handleOpenGraphTimeRangeFilters,
        filterGraphBarPolarIsOpen,
        handleRadioButton,
        handleApplyTimeRangeFilter,
        registerRadioButton,
        marks,
        watch,
        selectedBarPolarPeriod,
        renderEventsByHourRange,
        renderEventsByTimeRangeLegendChart,
        content,
        handleGetList,
    ]);

    const contentBar = useMemo(() => {
        if (responseStatus?.loading) {
            return content;
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return (
            <>
                <div className="filterDiv">
                    <TitleContainer>
                        <Title>
                            <h2>{t('period.day_week')}</h2>
                        </Title>
                        <SubTitle>{t('period.barChartGraph')}</SubTitle>
                    </TitleContainer>
                </div>
                <ChartContainer>
                    <BarChartStack legends={renderEventsDaysOfWeekLegends()} data={renderChartDaysOfTheWeekEvents()} />
                </ChartContainer>
            </>
        );
    }, [
        content,
        handleGetList,
        renderChartDaysOfTheWeekEvents,
        renderEventsDaysOfWeekLegends,
        responseStatus?.hasError,
        responseStatus?.loading,
        responseStatus?.void,
        t,
    ]);

    const { register, handleSubmit } = useForm<IPerDayAndHourFilter>({
        defaultValues: {
            range: 7,
            events: [],
        },
    });

    const handleApplyFilter = useCallback(
        (data: any) => {
            const { range } = data;
            setWorksTracksValue(range);
            setUniqueEventsFilter(uniqueEvents.filter((x: any) => x.checked === true));
        },
        [uniqueEvents],
    );

    const handleChange = useCallback(
        (eventChange: SelectChangeEvent<string[]>) => {
            const selectedValues = eventChange.target.value as string[];
            const oldValues = [...uniqueEvents];

            const updatedOptions: EventsProps[] = oldValues.map(event => ({
                ...event,
                checked: selectedValues.includes(event.name),
            }));

            setUniqueEvents(updatedOptions);
        },
        [uniqueEvents],
    );

    const contentByDayAndHour = useMemo(() => {
        if (responseStatus?.loading) {
            return (
                <Content className="mb-16" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Skeleton width={'30%'} height={'2rem'} />
                    <Skeleton width={'70%'} height={'2rem'} />
                    <Skeleton height={'20rem'} style={{ marginTop: '1rem' }} />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return (
            <>
                <div className="filterDiv">
                    <TitleContainer>
                        <Title>
                            <h2>{t('period.evento_per_day_hour')}</h2>
                        </Title>
                        <SubTitle>{t('period.scatterGraphSubTitle')}</SubTitle>
                    </TitleContainer>

                    <div>
                        <button className="filterButton" onClick={handleOpenGraphDayAndHourFilters}>
                            <FilterAlt color={'#46526C'} />
                        </button>
                        <ScatterChartFilter>
                            {filterGraphLineChartIsOpen && (
                                <FilterGraph handleCancelButton={closeDayAndHourFilterGraph} handleApplyButton={handleSubmit(handleApplyFilter)}>
                                    <FilterDayAndTimeContainer>
                                        <div>
                                            <RadioText>{t('general.events')}</RadioText>
                                            <EventsFilter {...register('events')} events={uniqueEvents} handleChange={handleChange} />
                                        </div>
                                        <div>
                                            <RadioText>{t('period.worst_tracks_highlights')}</RadioText>

                                            <Input {...register('range')} type="number" max={20} min={1} />
                                        </div>
                                    </FilterDayAndTimeContainer>
                                </FilterGraph>
                            )}
                        </ScatterChartFilter>
                    </div>
                </div>
                <ChartContainerLineChart>
                    <ScatterChart
                        dataX={hours}
                        dataY={renderEventsByDayAndHourLegends()}
                        data={renderEventsByDayAndHourData()}
                        worstTracksValue={worksTracksValue}
                    />
                </ChartContainerLineChart>
            </>
        );
    }, [
        responseStatus?.loading,
        responseStatus?.hasError,
        responseStatus?.void,
        t,
        handleOpenGraphDayAndHourFilters,
        filterGraphLineChartIsOpen,
        closeDayAndHourFilterGraph,
        handleSubmit,
        handleApplyFilter,
        register,
        uniqueEvents,
        handleChange,
        renderEventsByDayAndHourLegends,
        renderEventsByDayAndHourData,
        worksTracksValue,
        handleGetList,
    ]);

    const graphSelected: JSX.Element = useMemo(() => {
        return changeGraph === 'polar' ? contentPolar : contentCartesian;
    }, [changeGraph, contentCartesian, contentPolar]);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    return (
        <Column gap={1}>
            <Row align="center" width="100%" justify="space-between" padding="10px">
                <Title>
                    <h2>Analytics</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
            </Row>

            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3 className="period">{t('general.period')}</h3>
                        </Row>

                        <Row align="center" gap={16}>
                            <Row>
                                <RadiosCardHeader
                                    className="divRadios"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleRequestList({ equipament: event.target.value as 'DS' | 'FW' | 'Telemetria' })
                                    }
                                >
                                    <label>{t('general.view')}</label>
                                    <Radio label="DS" checked={requestData.equipament === 'DS'} name="DS" value="DS" />
                                    <Radio label="FW" checked={requestData.equipament === 'FW'} name="FW" value="FW" />

                                    <RenderIf condition={hasTelemetry('Geotab')}>
                                        <Radio
                                            label="Telemetria"
                                            checked={requestData.equipament === 'Telemetria'}
                                            name="Telemetria"
                                            value="Telemetria"
                                        />
                                    </RenderIf>
                                </RadiosCardHeader>
                            </Row>

                            <DatePicker
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={maxDate}
                                minDate={minDate}
                                multiple
                                name="period"
                                onChange={handleChangeDates}
                                onChangeRaw={handleChangeDatesRaw}
                                placeholder={String(t('general.select_period'))}
                            />
                            <Button
                                color={filterIsOpen ? 'purple' : 'outline-purple'}
                                leftIcon={<FilterAlt color={filterIsOpen ? '#ffffff' : '#46526C'} />}
                                size="small"
                                rightIcon={<ChevronDown spin={filterIsOpen} color={filterIsOpen ? '#fff' : '#46526C'} />}
                                type="button"
                                onClick={handleFilterIsOpen}
                            >
                                {t('general.filter')}
                            </Button>
                        </Row>
                    </CardHeader>
                    <div>{filters}</div>
                </Column>

                <Divider />

                <Row padding="0.8rem" style={{ gap: '1.5rem' }}>
                    <GraphSection>
                        <DivRounded>{graphSelected}</DivRounded>
                        <DivRounded>{contentBar}</DivRounded>
                    </GraphSection>
                    <GraphSection>
                        <DivRounded>{contentByDayAndHour}</DivRounded>
                    </GraphSection>
                </Row>
            </MainCard>
        </Column>
    );
}
