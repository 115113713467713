import { SVGProps } from 'react';

function Broom(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path fill="url(#pattern0)" d="M0 0H20V20H0z" />
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
                    <use xlinkHref="#image0_568_13859" transform="scale(.01042)" />
                </pattern>
                <image
                    id="image0_568_13859"
                    width={96}
                    height={96}
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACW0lEQVR4nO3bvU5UYRDG8bGwsF0/7sAAV0Ai3gCFYo8V9nSg5bZaUgs2WxqjhZJYC8FaC8l6ByIVhoTmb05yqje7arLvujPzPr8LYGdnMsnw7DlmIiIiQfAXi64vPTQADaBpaAM0gKahDdAAmoY2QANoGtoADaBpaAM0gKahDdAAmoY2QANoGtoADaBpaAM0gKahDdAAmoY2QANoGtoADaBpaAM0gKahDdAAmoY2YKbm3QcOgO/AJX5c9jXtA/csG2AAvCGO113NlgFwB/hKPF+A2xYZcA34QFwfu+9gUQEbxPfAogIOie+9RQRcBy6I76L7LhYNsEIeyxYN8Ig8Niwa4Bl5PLVogFfkcWDRAMfkcbTofoqIiIiIiEzVBVfkteR+9El+hJnmoXnXBVfktWve9Y+dZLVv3nXBFXl9Mu+AM/I6M8+AW+R307wC1shvzbwCnpDflnkFvCC/5+YV8I783ppXwCn5fTPHT8Jdkd+Vyyflkodw/kO55CGc/1CuC6pox655079f5d1PYJwylAsSwp0AeylDuSAh3AhYTxfKBQrhhsAN4FeqUC5QCLdZ8dUpP+8TBwrhVvt6t1OFcoFCuEFf791UoVyQEO68qHmcJpTrAipnd/okn4ua91KEcpVDuJNKd/oko6Lu9RShXOUQblTpTp9kWNRd4xxdyhbCDSve6aXHE2o/DB/KVQ7hNis1ZuoJWtQ+6zm6s5CmzzGEW63UmH/6z7XCOfpyIU2fYwg3qNSYP56gRf3j0KFcxRDuvPi743mdoMXnzHJ1/fgvTRYREREREcvkN1BJP9zG5y0tAAAAAElFTkSuQmCC"
                />
            </defs>
        </svg>
    );
}

export default Broom;
