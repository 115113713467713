import styled from 'styled-components';

export const ImageSection = styled.section`
    ul {
        padding: 0;
        display: grid;
        gap: 1.2rem;
        list-style: none;
        grid-template-columns: 33% 33% 33%;
        width: auto;
        min-width: 1400px;

        li {
            margin: 0 auto;

            img {
                margin: 0 auto;
                max-width: 450px;
                height: auto;
                border-radius: 10px;
            }
        }
    }

    @media (max-width: 1525px) {
        ul {
            grid-template-columns: 50% 50%;
            min-width: 900px;
        }
    }

    @media (max-width: 1050px) {
        ul {
            grid-template-columns: 100%;
            min-width: 400px;
        }
    }
`;
