import styled from 'styled-components';

export const Text = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    cursor: default;

    p {
        color: #7b7b7b;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin: 0 !important;
        max-width: 302px;
    }
`;

export const Title = styled.span`
    color: #262d3c;

    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 !important;
    text-align: left;

    @media (max-width: 480px) {
        max-width: auto;
    }
`;
