import { Children, ReactNode, TableHTMLAttributes, useCallback, useState } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { TiArrowUnsorted } from 'react-icons/ti';
import { FunctionComponent } from 'src/common/types';
import { SortProps } from 'src/modules/alarm-processing/components/alarms-container';
import { CSSProperties } from 'styled-components';

import { Container, TableContainer, TableHead, TableHeader, TableRow } from './styles';

interface TablePropsContainer {
    styleContainer?: CSSProperties;
}

type TableProps = FunctionComponent &
    TablePropsContainer &
    TableHTMLAttributes<HTMLTableElement> & {
        tableHeaders: ReactNode;
        sortData?: (e: any) => void;
        allowSort?: boolean;
        handleHeaderClick?: (event: MouseEvent, child: string) => void;
        sortElements?: SortProps[] | undefined;
        newSortElements?: (newSortElements: SortProps[]) => void;
    };

export default function Table({
    children,
    tableHeaders,
    sortData,
    allowSort,
    styleContainer,
    handleHeaderClick,
    sortElements,
    newSortElements,
    ...props
}: TableProps) {
    const [headerClicked, setHeaderClicked] = useState<string[]>([]);

    const iconSortType = useCallback(
        (child: string) => {
            const selectedKey = sortElements?.find(x => x.keyOrder === child);
            const ascType = selectedKey?.sortOrder === 'ASC';

            if (ascType && selectedKey) {
                return <AiFillCaretDown color="#7b7f83" />;
            } else if (!ascType && selectedKey) {
                return <AiFillCaretUp color="#7b7f83" />;
            } else {
                return <TiArrowUnsorted size={18} color="#7b7f83" />;
            }
        },
        [sortElements],
    );

    const isCaptureHeader = useCallback((headerName: string) => {
        switch (headerName) {
            case 'Captures':
            case 'Capturas':
            case 'Cattura':
                return false;
            default:
                return true;
        }
    }, []);

    const setDataAndKeyData = useCallback(
        (event: any, child: string) => {
            if (!allowSort || !isCaptureHeader(child as string) || typeof child !== 'string') {
                return;
            }

            const isShiftKeyPressed = event.shiftKey;
            const newArrayElement: SortProps[] = [];
            const newElement: SortProps = {
                keyOrder: child,
                sortOrder: 'ASC',
            };

            if (!isShiftKeyPressed) {
                const keyExist = sortElements?.some(x => x.keyOrder === child);
                if (keyExist) {
                    const sortElementsEdit = sortElements;
                    const keyOrderIndex = sortElementsEdit?.findIndex(x => x.keyOrder === child);
                    if (keyOrderIndex !== -1) {
                        sortElementsEdit![keyOrderIndex as number].sortOrder =
                            sortElementsEdit![keyOrderIndex as number].sortOrder === 'ASC' ? 'DESC' : 'ASC';
                        newSortElements?.(sortElementsEdit as SortProps[]);
                        sortData?.(sortElementsEdit);
                    }
                } else {
                    setHeaderClicked([]);
                    newArrayElement.push(newElement);
                    newSortElements?.(newArrayElement);
                    sortData?.(newArrayElement);
                }
            } else {
                const newHeaderClicked: string[] = [...headerClicked, child];

                if (newHeaderClicked.length < 2) {
                    setHeaderClicked(newHeaderClicked);
                }

                if (newHeaderClicked.length == 2) {
                    newHeaderClicked.map(x => {
                        newArrayElement.push({
                            keyOrder: x,
                            sortOrder: 'ASC',
                        });
                    });
                    newSortElements?.(newArrayElement);
                    setHeaderClicked([]);
                    handleHeaderClick?.(event, child);
                    sortData?.(newArrayElement);
                }
            }
        },
        [allowSort, handleHeaderClick, headerClicked, isCaptureHeader, newSortElements, sortData, sortElements],
    );

    return (
        <Container style={styleContainer}>
            <TableContainer {...props}>
                <TableHead>
                    <TableRow>
                        {Children.map(tableHeaders, child => (
                            <TableHeader
                                onClick={e => setDataAndKeyData(e, child as string)}
                                allowSort={allowSort}
                                sortHeaders={headerClicked}
                                headerSelected={child as string}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
                                    <span>{child}</span>
                                    {allowSort && isCaptureHeader(child as string) && typeof child === 'string' && iconSortType(child as string)}
                                </div>
                            </TableHeader>
                        ))}
                    </TableRow>
                </TableHead>
                <tbody>{children}</tbody>
            </TableContainer>
        </Container>
    );
}
