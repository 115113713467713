import { LinearGradientObject, EChartsOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { DateHelper } from 'src/common/helpers';
import { InterventionRequestDTO } from 'src/modules/interventions/dtos/intervention.dto';

export interface BarChartProps {
    width?: string | number;
    height?: string | number;
    color?: string | LinearGradientObject;
    data: InterventionRequestDTO[] | undefined;
}

export function LineChart({ width = '100%', height = '100%', color = '#', data }: BarChartProps) {
    const { t } = useTranslation();

    const orderedData = data 
        ? data.sort((a, b) => {
            const Adate = new Date(a.alarmTime);
            const Bdate = new Date(b.alarmTime);

            const hours = [Adate.getTime(), Bdate.getTime()];

            return hours[0] - hours[1];
        })
        : undefined

    const formatedInterventions: {
        time: string;
        total: number
    }[] = [];

    const aux: string[] = [];

    if (orderedData) {
        orderedData.forEach((element) => {
            const dayTimeFormated = new Date(element.alarmTime).toString().split(":")[0];

            if (aux.includes(dayTimeFormated)) {
                const index = aux.indexOf(new Date(element.alarmTime).toString().split(":")[0])

                formatedInterventions[index].total += 1;
            } else {
                aux.push(dayTimeFormated);

                const date = new Date(element.alarmTime);

                formatedInterventions.push({
                    time: DateHelper.formatSimple(date) + " " + date.getHours(),
                    total: 1,
                })
            }
        });
    }

    const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: formatedInterventions.map(value => value.time.replace('-', '/').replace('-', '/').replace('T', ' ') + 'h'),
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: t('echarts.total') || 'Total',
                type: 'line',
                data: formatedInterventions.map(value => value.total),
                markPoint: {
                    data: [{ type: 'max', name: 'Máximo' }],
                },
                lineStyle: {
                    color: '#679ECD',
                    width: 3,
                    opacity: 0.7,
                },
                areaStyle: {
                    opacity: 0.8,
                    color: color,
                },
                symbol: 'roundRect',
            },
        ],
    };

    return <ReactEcharts notMerge={true} option={JSON.parse(JSON.stringify(option))} style={{ height, width }} />;
}
