import { useMutation } from '@tanstack/react-query';

import { authService } from '../factories';

interface UseRedefinePasswordProps {
    onError: (error: unknown) => void;
    onSuccess: () => void;
}

export function useRedefinePassword({ onError, onSuccess }: UseRedefinePasswordProps) {
    return useMutation({
        mutationKey: ['redefine-password'],
        mutationFn: authService.redefinePassword,
        onError,
        onSuccess,
    });
}
