import { createContext, useCallback, useMemo, useRef, useState } from 'react';

import { ResponseStatus } from '../../../../common';
import { Pagination } from '../../../../common/core/pagination';
import { DetailsProtocols, InterventionProtocol, ListInterventionProtocolDTO, Protocol, ProtocolNames } from '../dtos';
import { interventionProtocolByNameService } from '../services/intervention-protocol-by-name.service';
import { interventionProtocolService } from '../services/intervention-protocol.service';
import { InterventionProtocolContextProps } from './types';

export const InterventionProtocolContext = createContext({} as InterventionProtocolContextProps);

export const INITIAL_REQUEST_DATA: ListInterventionProtocolDTO = {
    currentPage: 1,
    pageSize: 15,
    active: false
};

export function InterventionProtocolProvider({ children }: any) {
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const interventionProtocolDialogRef = useRef<HTMLDialogElement>(null);
    const deleteInterventionProtocolDialogRef = useRef<HTMLDialogElement>(null);
    const [interventionProtocol, setInterventionProtocolService] = useState<Pagination<InterventionProtocol> | null>(null);
    const [interventionProtocolByName, setInterventionProtocolServiceByName] = useState<Protocol>();

    const [requestData, setRequestData] = useState<ListInterventionProtocolDTO>();
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
    });

    const handleRequestList = useCallback((data: Partial<ListInterventionProtocolDTO>) => {
        setRequestData(state => {
            return state ? { ...state, ...data } : undefined;
        });
    }, []);

    const handleOpenDialog = useCallback(() => {
        setDialogIsOpen(state => {
            if (state) {
                interventionProtocolDialogRef?.current?.close();
            } else {
                interventionProtocolDialogRef?.current?.showModal();
            }

            return !state;
        });
    }, []);

    const handleOpenDeleteDialog = useCallback(() => {
        setDeleteDialogIsOpen(state => {
            if (state) {
                deleteInterventionProtocolDialogRef?.current?.close();
            } else {
                deleteInterventionProtocolDialogRef?.current?.showModal();
            }

            return !state;
        });
    }, []);

    const handleGetList = useCallback(async () => {
        if (!requestData) {
            setRequestData(INITIAL_REQUEST_DATA);
        };

        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        interventionProtocolService
            .list(requestData!)
            .then(data => {
                setInterventionProtocolService(data);
                setResponseStatus({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: !data?.elements || data?.elements?.length === 0,
                    success: true,
                });
            })
            .catch(reason =>
                setResponseStatus({
                    loading: false,
                    error: reason,
                    hasError: true,
                    void: false,
                    success: false,
                }),
            );
    }, [requestData]);

    const handleGetListByOperationName = useCallback(async (protocolNames: ProtocolNames) => {
        const { operation, intervention_protocol_name } = protocolNames;

        interventionProtocolByNameService
            .listByName(operation)
            .then((data: DetailsProtocols[]) => {
                setInterventionProtocolServiceByName({
                    operationName: operation,
                    protocolName: intervention_protocol_name,
                    detailsProtocols: data
                });
            })
    }, []);

    const data: InterventionProtocolContextProps = useMemo(
        () => ({
            dialogIsOpen,
            handleOpenDialog,
            interventionProtocolDialogRef,
            deleteInterventionProtocolDialogRef,
            deleteDialogIsOpen,
            handleOpenDeleteDialog,
            responseStatus,
            requestData,
            handleGetList,
            handleGetListByOperationName,
            handleRequestList,
            interventionProtocol,
            interventionProtocolByName,
        }),
        [
            dialogIsOpen,
            handleOpenDialog,
            interventionProtocolDialogRef,
            deleteInterventionProtocolDialogRef,
            deleteDialogIsOpen,
            handleOpenDeleteDialog,
            responseStatus,
            requestData,
            handleGetList,
            handleGetListByOperationName,
            handleRequestList,
            interventionProtocol,
            interventionProtocolByName,
        ],
    );

    return <InterventionProtocolContext.Provider value={data}>{children}</InterventionProtocolContext.Provider>;
}
