import { CreatePasswordPolicyFormDTO, ListPasswordPoliciesDTO, PasswordPolicy } from '../dtos';

export const defaultValues: ListPasswordPoliciesDTO = {
    active: true,
    currentPage: 1,
    pageSize: 15,
    text: '',
};

export const getDefaultCreateOrUpdateValues = (data: PasswordPolicy | null): CreatePasswordPolicyFormDTO => ({
    active: data ? Boolean(data?.active) : true,
    containsAtLeastOneLowercaseLetter: data ? Boolean(data?.containsAtLeastOneLowercaseLetter) : false,
    containsAtLeastOneNumber: data ? Boolean(data?.containsAtLeastOneNumber) : false,
    containsAtLeastOneSpecialCharacter: data ? Boolean(data?.containsAtLeastOneSpecialCharacter) : false,
    containsAtLeastOneUppercaseLetter: data ? Boolean(data?.containsAtLeastOneUppercaseLetter) : false,
    description: data?.description || '',
    expiryTime: String(data?.expiryTime || ''),
    id: data?.id || undefined,
    mfa: data ? Boolean(data?.mfa) : false,
    name: data?.name || '',
    organization: data?.operation?.name || '',
    passwordDiffFrom: String(data?.passwordDiffFrom || ''),
    passwordMinimumLength: Number(data?.passwordMinimumLength || 8),
});
