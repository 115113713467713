import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArgusLogo, Button, CircularProgress, Column, Input, Row } from 'src/common/components';
import { toast } from 'src/common/components/toast';
import { FeedbackType } from 'src/common/types';
import { AuthBackground, AuthCard, AuthForm, BackButton, ForgotPasswordButton } from 'src/modules/auth/components';
import { loginSchema } from 'src/modules/auth/infra/validations/yup/schemas/login-schema';

import { LoginFormDTO } from '../../dtos/auth.dto';
import { useAuth, useLogin, useSendForgotPasswordRequest } from '../../hooks';

interface InternalLoginProps {
    email: string;
    onGoBack(): void;
}

export default function InternalLogin({ email, onGoBack }: InternalLoginProps): JSX.Element {
    const controller = useMemo(() => new AbortController(), []);

    const { isAuthenticating } = useAuth();
    const push = useNavigate();
    const { t } = useTranslation();

    const { mutateAsync: login, isLoading } = useLogin();
    const { mutateAsync: forgotPassword, isLoading: isReqForgotPasswd } = useSendForgotPasswordRequest({
        onError: () => {
            toast.error(t('general.error'), '');
        },
        onSuccess: () => {
            toast.success(t('auth.on_request_code.success.title'), t('auth.on_request_code.success.message'));
            push(`/login/redefine-password?email=${email}`);
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<LoginFormDTO>({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            user: email,
            password: '',
        },
    });

    const onSubmit = useCallback(
        async (data: LoginFormDTO) => {
            const { password, user, code } = data;

            await login({
                username: user,
                password,
                code,
                signal: controller.signal,
            });
        },
        [controller.signal, login],
    );

    const handleForgotPassword = useCallback(() => {
        forgotPassword({ email, signal: controller.signal });
    }, [controller.signal, email, forgotPassword]);

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, [controller]);

    return (
        <AuthBackground>
            <Column align="center" justify="center" size="100%">
                <AuthCard>
                    <Row width="100%" align="center" justify="space-between" gap={8}>
                        <BackButton onClick={onGoBack} />
                        <ArgusLogo color="#262D3C" height={30} width={131} />
                        <div style={{ width: '30px' }} />
                    </Row>

                    <AuthForm onSubmit={handleSubmit(onSubmit)}>
                        <Column width="302px" align="center" gap={16}>
                            <Column width="100%" id="fieldset" align="flex-start" gap={8}>
                                <Input
                                    type="text"
                                    placeholder={t('auth.user')?.toString()}
                                    style={{ width: '100%' }}
                                    feedback={{
                                        message: t(errors?.user?.message || '')?.toString(),
                                    }}
                                    disabled={true}
                                    {...register('user')}
                                />
                                <Input
                                    autoFocus
                                    type="password"
                                    placeholder={t('auth.access_password')?.toString()}
                                    style={{ width: '100%' }}
                                    feedback={{
                                        message: t(errors?.password?.message || '')?.toString(),
                                    }}
                                    disabled={isLoading || isAuthenticating}
                                    data-test="password-input"
                                    {...register('password')}
                                />
                            </Column>
                        </Column>
                        <Column gap={16} width="100%" align="center">
                            <Button type="submit" disabled={!isValid} isLoading={isLoading || isAuthenticating} data-test="internal-button-submit">
                                {t('auth.login')}
                            </Button>
                        </Column>
                        <ForgotPasswordButton type="button" onClick={handleForgotPassword}>
                            {isReqForgotPasswd ? <CircularProgress color="#0a0a0a" /> : t('auth.forgot_password')}
                        </ForgotPasswordButton>
                    </AuthForm>
                </AuthCard>
            </Column>
        </AuthBackground>
    );
}
