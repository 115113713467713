import { GoogleMap } from '@react-google-maps/api';
import { CSSProperties, PropsWithChildren, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, RenderIf, Skeleton } from 'src/common/components';
import { useMap, usePosition } from 'src/common/hooks';

import { Coordinates } from '../../domain/types/coordinates';
import { mapContainerStyle } from './styles';

interface MapProps {
    center?: Coordinates;
    styles?: CSSProperties;
}

export default memo(function Map({ center, children, styles }: PropsWithChildren<MapProps>) {
    const { isLoaded, loadError } = useMap();
    const { coordinates } = usePosition();
    const { t } = useTranslation();

    const mapRef = useRef<google.maps.Map | null>(null);

    const handleLoad = (map: google.maps.Map) => {
        mapRef.current = map;
    };

    return (
        <Column gap={16} padding="0 16px 24px 16px">
            <RenderIf condition={!!loadError}>
                <p>{t('general.error')}</p>
            </RenderIf>
            <RenderIf condition={isLoaded && !!coordinates}>
                <GoogleMap
                    onLoad={handleLoad}
                    mapContainerStyle={styles || mapContainerStyle}
                    zoom={16}
                    clickableIcons
                    center={{
                        lat: center?.latitude || mapRef?.current?.getCenter()?.lat() || (coordinates?.latitude as number),
                        lng: center?.longitude || mapRef?.current?.getCenter()?.lng() || (coordinates?.longitude as number),
                    }}
                    options={{
                        gestureHandling: 'greedy',
                    }}
                >
                    {children}
                </GoogleMap>
            </RenderIf>
            <RenderIf condition={!isLoaded}>
                <Column align="center" justify="center" gap={16}>
                    {[1, 2, 3, 4, 5].map(v => (
                        <Skeleton key={v} />
                    ))}
                </Column>
            </RenderIf>
        </Column>
    );
});
