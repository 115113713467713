import { useCallback, useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useEvents, useSortReviewEvent } from 'src/modules/alarm-processing/hooks';

import { BlankContainer, CloseButton, Container, NavigationButton, Preview, PreviewButton, Wrapper } from './styles';

export default function ScrollRow() {
    const { selectedEvents, handleRemoveSelectedEvent } = useEvents();
    const { showEvent, handleShowEvent, selectedEvents: selectedEventsProcessingAlarms, handleSelectEvent: handleSelectEventProcessingAlarms } = useSortReviewEvent();
    const [isHovering, setIsHovering] = useState('');

    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleMouseOver = (id: string) => {
        setIsHovering(id);
    }

    const handleMouseOut = () => setIsHovering('');

    const handleSelectEvent = useCallback(
        (operation: 'add' | 'sub') => {
            if (!selectedEvents?.length) return;

            const index = selectedEvents?.findIndex(event => event.id === showEvent.data?.id);
            const lastIndex = selectedEvents.length - 1;

            if (index === undefined || index === -1) return;

            switch (operation) {
                case 'add':
                    handleShowEvent({
                        data: selectedEvents[index >= lastIndex ? 0 : index + 1],
                        index: 0,
                    });
                    if (index >= lastIndex && wrapperRef?.current) {
                        wrapperRef.current.scrollLeft = 0;
                    }
                    break;
                case 'sub':
                    handleShowEvent({
                        data: selectedEvents[index <= 0 ? lastIndex : index - 1],
                        index: 0,
                    });
                    if (index <= 0 && wrapperRef?.current) {
                        wrapperRef.current.lastElementChild?.scrollIntoView();
                    }

                    break;
                default:
                    break;
            }
        },
        [handleShowEvent, selectedEvents, showEvent],
    );

    const handleNavigate = useCallback(
        (value: number, operation: 'add' | 'sub') => {
            if (wrapperRef?.current) {
                switch (operation) {
                    case 'add':
                        wrapperRef.current.scrollLeft += value;
                        handleSelectEvent('add');
                        break;
                    case 'sub':
                        wrapperRef.current.scrollLeft -= value;
                        handleSelectEvent('sub');
                        break;
                    default:
                        break;
                }
            }
        },
        [handleSelectEvent],
    );

    const handleRemoveEvent = useCallback((id: string) => {
        handleRemoveSelectedEvent(id);
        const event = selectedEventsProcessingAlarms.find(event => event.id.toString() == id);

        if (event !== undefined) {
            handleSelectEventProcessingAlarms(event);
        }
    },[handleRemoveSelectedEvent, handleSelectEventProcessingAlarms, selectedEventsProcessingAlarms]);

    return (
        <Container>
            <NavigationButton type="button" className="prev" onClick={() => handleNavigate(100, 'sub')}>
                <MdChevronLeft size={24} color="#46526C" />
            </NavigationButton>
            <Wrapper ref={wrapperRef}>
                {selectedEvents?.map(event => (
                    <div key={event.id?.toString()} onMouseOver={() => handleMouseOver(event.id?.toString())} onMouseOut={handleMouseOut}>
                        {isHovering === event.id?.toString() ?
                            <CloseButton key={event.id?.toString()} onClick={() => handleRemoveEvent(event.id?.toString())}>
                                <CgClose size={14} />
                            </CloseButton>
                            :
                            <BlankContainer />
                        }
                        <PreviewButton
                            type="button"
                            selected={showEvent.data?.id === event?.id}
                            onClick={() =>
                                handleShowEvent({
                                    data: event,
                                    index: 0,
                                })
                            }
                        >
                            <Preview src={event.photoUrl || ''} alt={event.alarm || ''} resizable={false} />
                        </PreviewButton>
                    </div>
                ))}
            </Wrapper>
            <NavigationButton type="button" className="next" onClick={() => handleNavigate(100, 'add')}>
                <MdChevronRight size={24} color="#46526C" />
            </NavigationButton>
        </Container>
    );
}
