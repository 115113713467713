import { yup } from 'src/common/infra/validations';

export const createOrUpdateFunctionSchema = yup.object().shape({
    name: yup.string().required('field_validations.required'),
    roles: yup
        .array()
        .of(yup.string().uuid('field_validations.required').required('field_validations.required'))
        .min(1, 'field_validations.required')
        .required('field_validations.required'),
    observation: yup.string().nullable(),
});
