import { HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Button, Row, SelectNumber } from 'src/common/components';

import { ActualPage, NumberActualTotal, Wrapper } from './styles';

interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
    currentPage?: number;
    mode?: 'normal' | 'small';
    onPageChange: (page: number) => void;
    perPage?: number;
    total?: number;
}

const generatePagesArray = (from: number, to: number) => {
    return [...new Array(to - from)]
        .map((_, index) => {
            return from + index + 1;
        })
        .filter(page => page > 0);
};

export default function Pagination({ currentPage = 1, onPageChange, perPage = 10, total, mode = 'normal', ...props }: PaginationProps) {
    const { t } = useTranslation();

    const from = useMemo(() => perPage * currentPage - perPage + 1, [currentPage, perPage]);
    const lastPage = useMemo(() => (total ? Math.ceil(total / perPage) : 0), [perPage, total]);
    const to = useMemo(() => (total ? (total < perPage * currentPage ? total : perPage * currentPage) : 0), [currentPage, perPage, total]);

    const actualPage = useMemo(() => {
        if (total) {
            return (
                <Row align="center" gap={8}>
                    <ActualPage mode={mode}>{t('general.on_the_page')}:</ActualPage>
                    <SelectNumber
                        fontMode="small"
                        style={{
                            minHeight: '32px',
                            backgroundSize: '16px',
                        }}
                        options={generatePagesArray(0, lastPage)}
                        value={currentPage}
                        onChange={e => onPageChange(Number(e.target.value))}
                    />
                </Row>
            );
        }

        return null;
    }, [currentPage, lastPage, mode, onPageChange, t, total]);

    const fromTo = useMemo(() => {
        if (total) {
            return `${from} - ${to} ${t('general.of')} ${total}`;
        }

        return null;
    }, [from, t, to, total]);

    const previousButtonIsDisabled = useMemo(() => currentPage - 1 < 1, [currentPage]);
    const nextButtonIsDisabled = useMemo(() => (total ? currentPage + 1 > lastPage : false), [currentPage, lastPage, total]);

    return (
        <Wrapper align="center" justify="space-between" gap={24} {...props}>
            <NumberActualTotal mode={mode}>{fromTo}</NumberActualTotal>
            <Row align="center" gap={mode === 'small' ? 8 : 24}>
                {actualPage}
                <Row align="center" gap={mode === 'small' ? 8 : 16}>
                    <Button
                        type="button"
                        size="small"
                        style={
                            mode === 'small'
                                ? {
                                      height: '32px',
                                      width: '32px',
                                      padding: '0',
                                  }
                                : {
                                      width: '36px',
                                      padding: '0',
                                  }
                        }
                        disabled={previousButtonIsDisabled}
                        onClick={() => onPageChange(currentPage - 1)}
                    >
                        <BsChevronLeft size={18} />
                    </Button>
                    <Button
                        type="button"
                        size="small"
                        style={
                            mode === 'small'
                                ? {
                                      height: '32px',
                                      width: '32px',
                                      padding: '0',
                                  }
                                : {
                                      width: '36px',
                                      padding: '0',
                                  }
                        }
                        disabled={nextButtonIsDisabled}
                        onClick={() => onPageChange(currentPage + 1)}
                    >
                        <BsChevronRight size={18} />
                    </Button>
                </Row>
            </Row>
        </Wrapper>
    );
}

Pagination.defaultProps = {
    currentPage: 1,
    perPage: 10,
};
