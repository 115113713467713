import { Fragment } from 'react';
import { Column, Modal } from 'src/common';

import { useOperations } from '../../hooks/use-operations';
import Form from './form';
import { Card } from './styles';

export default function CreateOrUpdateDepartmentDialogForm() {
    const { dialogIsOpen, handleOpenDialog } = useOperations();

    return (
        <Modal open={dialogIsOpen} onClose={() => handleOpenDialog()}>
            <Card>
                <Column width="656px">{dialogIsOpen ? <Form /> : <Fragment />}</Column>
            </Card>
        </Modal>
    );
}
