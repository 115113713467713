import { InfoWindow, Marker } from '@react-google-maps/api';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pinAlt from 'src/assets/icons/pin-alt.svg';
import { Column, Field } from 'src/common/components';
import { DateHelper } from 'src/common/helpers';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import styled from 'styled-components';

import EventModal from '../event-modal';

interface MarkProps {
    event: EventDTO;
}

const EventName = styled.h5`
    color: #262d3c;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
`;

export default function Mark({ event }: MarkProps) {
    const { t, i18n } = useTranslation();
    const [showInfo, setShowInfo] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const eventName = useMemo(() => {
        if (event?.event) {
            return event?.event === '?' ? t('alarms.pending_analysis') : event?.event;
        }

        return t('general.information_unavailable');
    }, [event?.event, t]);

    const position = useMemo<google.maps.LatLngLiteral>(
        () => ({
            lat: event.latitude,
            lng: event.longitude,
        }),
        [event.latitude, event.longitude],
    );

    const handleOpen = () => setShowModal(state => !state);

    return (
        <Marker icon={pinAlt} position={position} onClick={handleOpen} onMouseOver={() => setShowInfo(true)} onMouseOut={() => setShowInfo(false)}>
            {showInfo && (
                <InfoWindow position={position} onCloseClick={() => setShowInfo(false)}>
                    <Column gap={4} align="flex-start">
                        <EventName>{event?.alarm}</EventName>
                        <Column gap={2} align="flex-start">
                            <Field name={t('alarms.priority')} value={event?.priority?.toString() || t('general.information_unavailable')} />
                            <Field name={t('alarms.event')} value={eventName} warn={event?.event === '?'} />
                            <Field
                                name={t('alarms.alarm_time')}
                                value={event?.alarmTime ? DateHelper.intl(event.alarmTime, i18n.language) : t('general.information_unavailable')}
                            />
                            <Field
                                name={t('alarms.arrival')}
                                value={
                                    event?.alarmArrivalTime
                                        ? DateHelper.intl(event.alarmArrivalTime, i18n.language)
                                        : t('general.information_unavailable')
                                }
                            />
                            <Field name={t('alarms.fleet')} value={event?.fleet || t('general.information_unavailable')} />
                            <Field name={t('alarms.velocity')} value={event?.speedInKmH?.toString() || t('general.information_unavailable')} />
                            <Field name={t('monitoring.lat')} value={event.latitude?.toString()} />
                            <Field name={t('monitoring.long')} value={event.longitude?.toString()} />
                        </Column>
                    </Column>
                </InfoWindow>
            )}
            <EventModal open={showModal} onClose={handleOpen} event={event} />
        </Marker>
    );
}
