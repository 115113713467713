import { ApiResponse } from 'src/common/core';
import { Pagination } from 'src/common/core/pagination';
import { TransformRequestHelper } from 'src/common/helpers';
import { api } from 'src/common/infra';

import { CreateInterventionProtocol, InterventionProtocol, ListInterventionProtocolDTO } from '../dtos/intervention-protocol.dto';

export class InterventionProtocolService {
    public async create(data: CreateInterventionProtocol): Promise<void> {
        await api.post('/intervention-protocol', data);
    }

    public async list(data: ListInterventionProtocolDTO): Promise<Pagination<InterventionProtocol>> {
        const { pageSize, currentPage, name } = data;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<InterventionProtocol>>>('/intervention-protocols', {
            params: {
                name,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
        });

        return content;
    }
}

export const interventionProtocolService = new InterventionProtocolService();
