import italyFlag from 'src/assets/flags/italy-flag.svg';

import { LanguageTranslations } from '../types/translations';

const IT: LanguageTranslations = {
    dateFormat: 'dd/MM/yyyy',
    flag: italyFlag,
    translations: {
        auth: {
            unable_to_scan: 'Impossibile eseguire la scansione? Puoi utilizzare il file X per configurare manualmente la tua app di autenticazione',
            setup_key: 'chiave di configurazione',
            use_an_authenticator_app_or_browser_extension_to_scan:
                "Utilizza un'app di autenticazione o un'estensione del browser per eseguire la scansione.",
            verify_code: 'Verifica codice',
            scan_the_qr_code: 'Scansiona il codice QR',
            preferred_mfa_method: 'Metodo MFA preferito',
            authenticator_app: 'App di autenticazione',
            sms: 'SMS/messaggio di testo',
            configure_mfa_text: 'Per continuare, configura MFA per questo utente.',
            configure_mfa: 'Configura MFA',
            enter_the_code_sent_to: 'Inserisci il codice inviato a:',
            redefine_password: 'Ridefinisci password',
            access_password: 'Password di accesso',
            confirm_new_password: 'Conferma la nuova password',
            email: 'E-mail',
            email_or_phone: 'Email o telefono',
            forgot_password: 'Ho dimenticato la mia password',
            login: 'Login',
            logout_confirmation: 'Stai per uscire, vuoi continuare?',
            logout_system: 'Disconnettersi',
            name: 'Nome',
            new_password: 'Nuova password',
            password: 'Password',
            password_confirmation: 'Conferma password',
            register_new_password: 'Per continuare controlla i tuoi dati e registra una password per accedere alla nostra piattaforma.',
            remember_password: 'Ricordare la password',
            send: 'Invia',
            user: 'Utente',
            welcome: 'Benarrivato',
            continue: 'Continua',
            error_login: 'Nome utente o password errati.',
            forgot_password_page_message: `Inserisci l'e-mail registrato per ricevere il link di ripristino:`,
            forgot_password_code_message: 'Inserisci il codice di verifica ricevuto.',
            request_new_code: 'Richiedi nuovo codice',
            verification_code: 'Codice di verifica',
            on_confirm_change_pass: {
                success: {
                    title: 'Successo',
                    message: 'Password cambiata con successo.',
                },
                error: {
                    expiredCodeException: {
                        title: 'Codice scaduto',
                        message: 'Codice di verifica scaduto.',
                    },
                },
            },
            on_request_code: {
                success: {
                    title: 'Richiesta ricevuta',
                    message: `L'e -mail di recupero è stata inviata con successo!`,
                },
                error: {
                    limitExceeded: {
                        title: 'Limite superato',
                        message: 'I limiti dei tentativi sono già superati, riprovare più tardi.',
                    },
                },
            },
        },
        configurations: {
            description: 'Descrizione',
            intervention_protocol: 'Protocollo di intervento',
            paper: 'Carta',
            edit_password_policy: 'Modifica criterio password',
            view_password_policy: 'Visualizza criterio password',
            view_operation: 'Visualizza operazione',
            view_function: 'Visualizza funzione',
            edit_function: 'Modifica la funzione',
            view_user: 'Visualizza utente',
            save_contact: 'Salva contatto',
            do_you_want_to_delete_the_password_policy: 'Vuoi eliminare i criteri di password?',
            delete_policy: 'Elimina politica',
            mfa_validation: 'Convalida MFA obbligatoria',
            password_policy_status: 'Stato politica password',
            current_password_must_be_different_from_the_last_X_passwords: 'La password corrente deve essere diversa dalle ultime X password.',
            contains_at_least_one_uppercase_letter: 'Contiene almeno 1 lettera maiuscola',
            contains_at_least_one_lowercase_letter: 'Contiene almeno 1 lettera minuscola',
            contains_at_least_one_special_character: 'Contiene almeno 1 carattere speciale',
            contains_at_least_one_number: 'Contiene almeno 1 numero',
            minimum_time: 'Tempo minimo (in giorni)',
            expiry_time: 'Tempo di scadenza (in giorni)',
            password_minimum_length: 'Lunghezza minima password (caratteri)',
            assigned_to: 'Assegnato a',
            password_policy_name: 'Nome criterio password',
            create_password_policy: 'Crea criterio password',
            password_policies: 'Politiche password',
            redefine_password: 'Ridefinisci password',
            view_vehicle: 'Visualizza Veicolo',
            edit_vehicle: 'Modifica veicolo',
            delete_vehicle: 'Escludi veicolo',
            delete_the_vehicle_white_board: 'Vuoi eliminare il veicolo targato',
            acquisition_date: 'Data di acquisizione',
            add_vehicle: 'Aggiungi veicolo',
            current_vehicle_statuses: {
                new: 'Nuovo',
                semi_new: 'Semi nuovo',
                used: 'Usato',
            },
            vehicle_types: {
                agricultural_machine: 'Macchina agricola',
                bus: 'Autobus',
                light_vehicle: 'Veicolo leggero',
                locomotive: 'Locomotiva',
                motorcycle: 'Motociclo',
                ship: 'Nave',
                stationary_equipment: 'Attrezzatura fissa',
                truck: 'Camion',
            },
            current_vehicle_status: 'Stato attuale del veicolo',
            vehicle_type: 'Tipo di veicolo',
            create_vehicle: 'Crea veicolo',
            edit_department: 'Modifica Operazione',
            equals_resources: 'Le risorse padre e figlio sono uguali',
            ambiguous_record: 'Questo record è ambiguo, seleziona il record desiderato.',
            create_department: 'Crea Operazione',
            contact: 'Contatto',
            add_department: 'Aggiungi Operazione',
            add_function: 'Aggiungi funzione',
            add_user: 'Aggiungi utente',
            cancel: 'Annulla',
            confirm: 'Confermare',
            confirm_delete_functions: 'Vuoi eliminare i ruoli?',
            confirm_delete_users: 'Vuoi eliminare gli utenti?',
            confirm_password: 'Conferma password',
            contacts: 'Contatti',
            creation_date: 'Data di creazione',
            creator: 'Creatore',
            delete: 'Elimina',
            delete_department: 'Elimina Operazione',
            delete_function: 'Elimina ruolo',
            delete_the_user: 'Vuoi eliminare l utente',
            department: 'Operazione',
            departments: 'Operazioni',
            do_you_want_to_delete_the_department: 'Vuoi eliminare il/i Operazione/i',
            do_you_want_to_delete_the_role: 'Vuoi eliminare il ruolo',
            edit_user: 'Modifica utente',
            email: 'E-mail',
            equipments: 'Attrezzatura',
            function: 'Funzione',
            function_name: 'Nome ruolo',
            function_number: 'Numero di funzione',
            functionalities: 'Funzionalità',
            functions: 'Funzioni',
            institution: 'Istituzione',
            institution_name: "Nome dell'istituto",
            login: 'Login',
            name_login: 'Nome login',
            name_user: 'Nome utente',
            number: 'Numero',
            observation: 'Osservazione',
            organization: 'Organizzazione',
            organization_name: "Nome dell'organizzazione",
            password: 'Parola d ordine',
            password_policy: 'Politica password',
            phone_number: 'Numero di telefono',
            settings: 'Impostazioni',
            show_active_users: 'Mostra utenti attivi',
            status: 'Stato',
            system: 'Sistema',
            this_action: 'Questa azione non può essere annullata',
            user: 'Utente',
            user_delete: 'Elimina utente',
            users: 'Utenti',
            vehicles: 'Veicoli',
            client: 'Cliente',
            analyst: 'Analista',
            client_analyst: 'Cliente Analista',
            profile: 'Profilo',
            view_protocol: 'visualizzare il protocollo',
        },
        field_validations: {
            min: 'Il numero minimo di caratteri è: $$min',
            name: 'Nome non valido',
            date_is_before_creation_date: 'La data selezionata non può essere inferiore alla data di creazione',
            date_is_before_today: 'La data selezionata non può essere precedente alla data corrente',
            date: 'Data non valida',
            phone_number_tooltip: 'Inserisci un numero di telefono, inclusi + e il prefisso internazionale, ad esempio +12065551212.',
            password_length_recommended:
                'Deve essere un numero compreso tra 6 e 99. Ti consigliamo vivamente di richiedere che le password siano lunghe almeno 8 caratteri.',
            equals_resources: 'Le risorse padre e figlio sono uguali',
            mandatory_fields: 'Campi obbligatori',
            max: 'Dimensione massima: %%',
            phone: 'Telefono non valido',
            email: 'E-mail non valido',
            hour: "Formato dell'ora non valido",
            invalid_record: 'Registrazione non valida',
            passwords_match: 'Le password non corrispondono',
            record_already_exists: 'Questo record esiste già.',
            required: 'Campo obbligatorio',
            password: {
                lower_case: 'La password dovrebbe contenere lettere minuscole.',
                upper_case: 'La password deve contenere lettere maiuscole.',
                special_character: 'La password deve contenere almeno un carattere speciale.',
                length_8: 'La password deve contenere almeno 8 caratteri.',
                number: 'La password deve contenere almeno un numero.',
            },
        },
        home: {
            events: 'Eventi',
            updated_at: 'Aggiornato alle',
            interventions: 'Interventi',
            drowsiness: 'Sonnolenza',
            vehicles: 'Veicoli',
            interventions_last_hour: "Interventi nell'ultima ora",
            interventions_last_hour_subtitle: "Ogni punto rappresenta l'evoluzione del numero di eventi nell'ultima ora.",
            event_distribution: 'Distribuzione degli eventi',
            event_distribution_subtitle: 'Ogni sezione indica la percentuale di eventi per ciascuna categoria nel periodo selezionato.',
            violation: 'Infrazione',
            fatigue: 'Affaticamento',
            distraction: 'Distrazione',
            speeding: 'Eccesso di velocità',
            dangerous_driving: 'Guida pericolosa',
            aggressive_driving: 'Guida aggressiva',
            recent_interventions: 'Interventi recenti',
            recent_interventions_subtitle:
                "I marcatori rossi sulla mappa indicano la posizione degli interventi avvenuti nel periodo selezionato. Cliccando su di essi vengono visualizzati il video e le informazioni relative all'intervento.",
        },
        menu: {
            events: 'Eventi',
            language: 'Italiano',
            logout: 'Disconnettersi',
            panel: 'Pannello',
            pending_alarms: 'Allarmi in sospeso',
            processing_alarms: 'Elaborazione allarmi',
            vehicles_monitoring: 'Monitoraggio veicoli',
            interventions: 'Interventi',
            analytics: 'Analytics',
            period: 'Periodo',
            overview: 'Panoramica',
            registration_shipments: 'Registrazione delle spedizioni',
            help: 'Aiuto',
            review_request: `Revisione dell'evento`,
            analysis_requests: 'Richieste di analisi',
            home: 'Inizio',
            smart_report: 'Smart Report',
        },
        general: {
            hours: 'Ore',
            last: 'Scorsa',
            loading: 'Caricamento',
            see_all: 'Vedi tutto',
            status: {
                active: 'Attivo',
                inactive: 'Inattivo',
            },
            recommended: 'Consigliato',
            name: 'Nome',
            input: 'Input',
            output: 'Uscita',
            expires_in: 'Scade tra',
            default: 'Predefinito(a)',
            record_has_relationships: 'Le norme hanno utenti collegati, scollegali e riprova.',
            select: 'Selezionare',
            export: 'Esportare',
            select_filters_to_view_the_map: 'Seleziona i filtri per visualizzare la mappa',
            no_vehicle_selected: 'Nessun veicolo selezionato',
            error_loading_data: 'Errore durante il caricamento dei dati',
            unhandled_alarms: 'Allarmi non gestiti',
            admin: 'Amministratore',
            interventions: 'Interventi',
            analytics: 'Analytics',
            overview: 'Panoramica',
            unit: 'Unità',
            fleet: 'Flotta',
            driver: 'Autista',
            communication: 'Comunicazione',
            support: 'Supporto',
            vehicle_monitoring: 'Monitoraggio del veicolo',
            sort_alarm: 'Ordina allarme',
            settings: 'Impostazioni',
            review_alarm: "Rivedere l'allarme",
            reports: 'Rapporti',
            real_time_localization: 'Localizzazione in tempo reale',
            playback_of_tracks: 'Riproduzione traccia',
            live_camera_stream: 'Streaming della videocamera',
            electronic_fence: 'Recinto elettronico',
            dashboard: 'Dashboard',
            authentication: 'Autenticazione',
            alarms_processing: 'Elaborazione degli allarmi',
            selection_of_settings: 'Selezione delle impostazioni',
            main_menu: 'Menu principale',
            exclude_yourself: 'Non puoi cancellarti.',
            configurations: 'Impostazioni',
            alarm_type: 'Tipo di allarme',
            alarms_high_risk: 'Allarmi ad alto rischio',
            alarms_solved: 'Allarmi gestiti',
            alarms_total: 'Totale allarmi',
            all: 'Tutto',
            apply: 'Applicare',
            cancel: 'Annulla',
            classification: 'Classifica',
            clear_filters: 'Cancella filtri',
            conclude: 'Concludere',
            confirm: 'Confermare',
            date: 'Data',
            description: 'Descrizione',
            destination: 'Destinazione',
            error: 'Errore, riprova.',
            events: 'Eventi',
            filter: 'Filtro',
            filters: 'Filtri',
            final_hour: 'Ultima ora',
            high_risk: 'Alto rischio',
            historic: 'Storico',
            information_unavailable: 'Informazioni non disponibili',
            initial_hour: 'Ora iniziale',
            items_per_page: 'Oggetti per pagina',
            low_risk: 'A basso rischio',
            medium_risk: 'Rischio medio',
            no_content: 'Nessuna registrazione',
            no_content_specific_alarm: 'Non hai ancora alcuna sveglia',
            no_content_specific_event: 'Non hai ancora nessun evento',
            no_filter_vehicle: 'Non abbiamo trovato alcun risultato per i filtri applicati',
            no_vehicle: 'Nessun veicolo trovato',
            of: 'di',
            offline: 'Disconnesso',
            on_the_page: 'Sei nella pagina',
            online: 'In linea',
            online_vehicles: 'Veicoli online',
            origin: 'Origine',
            period: 'Periodo',
            review: 'Revisione',
            search: 'Ricerca...',
            select_period: 'Seleziona Periodo',
            selected_alarms: 'Allarmi selezionati',
            seven_days: '7 giorni',
            show_events: 'Mostra Eventi',
            success: 'Successo',
            test: 'Test',
            total_vehicles: 'Totale veicoli',
            twenty_four_hours: '24 ore',
            update: 'Aggiornare',
            update_data: 'Aggiorna dati',
            vehicle: 'Veicolo',
            vehicles: 'Veicoli',
            vehicles_events: 'Eventi di veicoli',
            view: 'Visualizzazione',
            write: 'Scrivere',
            write_here: 'Scrivere qui',
            activity: 'Attività',
            region: 'Regione',
            country: 'Paese',
            operation: 'Operazione',
            notification_sound: 'Suono di notifica',
            functionalities: 'Funzionalità',
            operations: 'Operazioni',
            password_policy: 'Politica delle password',
            users: 'Utenti',
            speed_above: 'Velocità sopra',
            message: {
                success: {
                    title: 'Successo',
                    message: 'Richiesta completata correttamente.',
                },
                error: {
                    title: 'Fallimento',
                    message: `C'è stato un mancato elaborazione della tua richiesta.`,
                },
            },
            face_missing: 'Face Missing',
            yes: 'Sì',
            no: 'No',
            back_to_home: 'tornare a casa',
            intervention_protocol: 'Protocollo di intervento',
            registration_shipments: 'Registrazione della spedizione',
            event_redistribution: 'Ridistribuzione degli eventi',
            select_the_event: `Seleziona l'evento`,
            review_requests: 'Richieste di Analisi',
            unit_comparison: 'Confronto delle unità',
            last_alarm: 'Ultimo allarme',
            fleets: 'Flotte',
            select_options: 'Selezionare le opzioni',
            see_driver_name: 'Visualizza il nome del conducentea',
        },
        alarms: {
            pending_analysis: 'Analisi in sospeso',
            last_location: 'Ultima posizione',
            minimize: 'Minimizzare',
            see_all: 'Guarda tutto',
            alarm_time: 'Ora della sveglia',
            event_type: 'Tipo di evento',
            GPS: 'GPS',
            alarm: 'Allarme',
            alarm_type: 'Tipo di allarme',
            arrival: 'Arrivo',
            board: 'Asse',
            break: 'Rompere',
            cam: 'Camera',
            capture: 'Catturare',
            captures: 'Cattura',
            device: 'Dispositivo',
            event: 'Evento',
            extra_events: 'Eventi extra',
            false_alarm: 'Falso allarme',
            fleet: 'Flotta',
            historic_route: 'Itinerario Storico',
            hour: 'Ora',
            information: "Informazioni sull'allarme",
            level: 'Livello di allarme',
            looking_down: 'Guardando in basso',
            name: 'Nome allarme',
            number_of: 'Numero di acquisizioni',
            operation: 'Operazione',
            previous_events: 'Eventi precedenti',
            priority: 'Priorità',
            priority_1: 'Alto',
            priority_2: 'Medio',
            priority_3: 'Basso',
            real_time: 'Monitoraggio in tempo reale',
            select_alarm: 'Seleziona Sveglia',
            select_board: 'Seleziona Bacheca',
            select_destination: 'Indirizzo di destinazione',
            select_events: 'Seleziona Eventi',
            select_operation: 'Seleziona Operazione',
            select_origin: 'Indirizzo di origine',
            select_vehicle: 'Seleziona Veicolo',
            somnolence: 'Sonnolenza',
            sort_multiple_alarms: 'Ordina più allarmi',
            velocity: 'Velocità',
            select_redistribution: 'Ridistribuzione degli allarmi',
            label_select_redistribution: 'Ridistribuisci i miei allarmi (percentuale)',
            placeholder_select_redistribution: 'Inserisci la percentuale da ridistribuire',
            alarms_search_parameters: 'Ricerca allarmi',
            label_alarms_search_parameters: 'Distribuzione degli allarmi',
            placeholder_select_alarms_search_parameters: "Seleziona l'opzione di distribuzione",
            input_alarms_search_parameters: 'Periodo di ricerca (in ore)',
            placeholder_alarms_search_parameters: 'Inserisci il numero di ore per la ricerca',
            dynamic: 'Dinamica',
            open_alarms: 'Allarmi aperti',
            processed_alarms: 'Allarmi elaborati',
            activity_analyst: "Attività dell'analista",
            automatic_search: 'Ricerca Automatica',
            automatic_search_tooltip: 'Esegue la ricerca automatica di nuovi allarmi ogni 30s',
            analyst: 'Analista',
            media_visualization: 'Visualizzazione multimediale',
            media_size: 'Dimensioni del supporto (pixel)',
            placeholder_midias_size: 'Inserisci la quantità di pixel per aumentare il media',
            options: 'Opzione',
            request_review: 'Richiedere la Revisione',
            review_event: 'Evento di Revisione',
            see_details: 'Guarda i Detagli',
        },
        review_request: {
            review_request: `Revisione dell'evento`,
            analysis_requests: 'Richiesta di Analisi',
            analysis_request: 'Analysis Request',
            request_number: 'N.º richiesta',
            request_time: 'Data della richiesta',
            fleet: 'Flotta',
            operation: 'Operazione',
            event: 'Evento',
            event_time: `Data dell'evento`,
            post_analysis: 'Post-analisi',
            justification: 'Giustificazione',
            answer: 'Risposta',
            status: 'Situazione',
            see: 'Da vedere',
            success: 'Successo',
            pending: 'In attesa',
        },
        monitoring: {
            view_electronic_fence: 'Visualizza recinto elettronico',
            do_you_want_to_delete_the_electronic_fence: 'Vuoi eliminare il recinto elettronico',
            delete_electronic_fence: 'Elimina recinto elettronico',
            change_zone_shape: 'Cambia forma zona',
            zone_color: 'Colore zona',
            transparency: 'Trasparenza',
            expires_in: 'Scade tra',
            generate_event_by: 'Genera evento da',
            electronic_fence_name: 'Nome recinto elettronico',
            edit_electronic_fence: 'Modifica recinto elettronico',
            add_electronic_fence: 'Aggiungi recinto elettronico',
            add_fence: 'Aggiungi recinto',
            view: 'Visualizzazione',
            SIM: 'Numero della carta SIM',
            add_perimeter: 'Aggiungi perimetro',
            altitude: 'Altitudine',
            board_color: 'Colore bordo',
            board_number: 'Numero di bordo',
            cam_stream: 'Flusso di cam',
            cellphone: 'Contatto Cellulare',
            cities: 'Città e Province',
            conduction: 'Modello di conduzione',
            define_route: 'Definisci percorso',
            defined_perimeters: 'Perimetri definiti',
            direction: 'Direzione',
            driver_info: 'Informazioni sul conducente',
            driver_name: 'Nome del conducente',
            eletronic_fence: 'Recinto elettrico',
            filiation: 'Filiazione',
            identity: "Numero d'identità",
            last_events: 'Ultimi Eventi',
            last_register: 'Ultimo registro',
            lat: 'Latitudine',
            localization: 'Localizzazione',
            long: 'Longitudine',
            mark_perimeter: 'Segna i perimetri',
            organization: 'Organizzazione di controllo',
            perimeter: 'Perimetro',
            position: 'Posizionamento',
            radius: 'Raggio',
            real_time: 'Localizzazione in tempo reale',
            select_perimeter: 'Seleziona Perimetro',
            status: 'Stato',
            track_playback: 'Riproduzione traccia',
            tracking: 'Monitoraggio',
            vehicle_info: 'Informazioni sul veicolo',
            write_radius: 'Scrivere Raggio (km)',
            compass_points: {
                east: 'Est',
                north: 'Nord',
                northeast: 'Nord-est',
                northwest: 'Nord-ovest',
                south: 'Sud',
                southeast: 'Sud-est',
                southwest: 'Sud-ovest',
                west: 'Ovest',
            },
            event_expires_in: {
                never: 'Main',
                today: 'Oggi',
                tomorrow: 'Domani',
                seven_days: '7 giorni',
                custom: 'Personalizzato',
            },
        },
        password: {
            new_password_is_equal_to_old: 'La tua nuova password deve essere diversa da quelle vecchie',
            contains_at_least_one_lowercase_letter: 'La password deve contenere almeno 1 lettera minuscola',
            contains_at_least_one_number: 'La password deve contenere almeno 1 numero',
            contains_at_least_one_special_character: 'La password deve contenere almeno un carattere speciale',
            contains_at_least_one_uppercase_letter: 'La password deve contenere almeno una lettera maiuscola',
            is_expired: 'La password è scaduta',
        },
        overview: {
            proportion_events: 'Proporzione',
            proportion_events_chart_subtitle: 'Questo grafico rappresenta la proporzione degli eventi nel periodo selezionato.',
            heat_map_events: 'Mappa di calore',
            heat_map_events_chart_subtile: 'I punti su questa mappa indicano le località con il maggior numero di eventi.',
            total_events: 'Totale',
            total_events_chart_subtitle: 'Questo grafico rappresenta il numero totale di eventi nel periodo selezionato.',
            average_events: 'Average',
            average_events_chart_subtile: 'Questo grafico rappresenta il numero medio di veicoli nel periodo selezionato.',
            telemetry: 'Telemetria',
        },
        period: {
            time_range: 'Fascia oraria',
            day_week: 'Giorni della settimana',
            evento_per_day_hour: 'Eventi per giorno e ora',
            hours_per_bar: 'Ore per bar',
            coordinates: 'Coordinate',
            polar: 'Polare',
            cartesian: 'Cartesiano',
            options: 'Opzioni',
            day: 'Giorno',
            week: 'Settimana',
            month: 'Mese',
            year: 'Anno',
            bar: 'Bar',
            line: 'Linea',
            graph_type: 'Tipo di grafica',
            cancel: 'Annullamento',
            apply: 'Applicare',
            worst_tracks_highlights: 'Punti salienti tracce peggiori',
            total_events: 'Totale eventi',
            at: 'a',
            scatterGraphSubTitle:
                'I punti del grafico rappresentano ' +
                ' gli eventi selezionati durante le ore ei giorni della' +
                ' settimana. Più grande è il punto, più eventi e i punti' +
                ' lampeggianti rappresentano la quantità selezionata di tracce di basso.',
            cartesianChart: 'Le bande su questo grafico indicano i tempi con il maggior numero di eventi.',
            barChartGraph: 'Le barre in questo grafico indicano i giorni della settimana con il maggior numero di eventi.',
            telemetry: 'Telemetria',
        },
        interventions: {
            interventions_informations: "Informazioni sull'intervento",
            qtd_events: 'Numero di eventi: ',
            hour_initial: 'Ora di inizio degli eventi: ',
            hour_final: 'Ora di fine degli eventi: ',
            interventions_situation: 'Situazione di intervento',
            media: 'Media',
            speed: 'Velocità',
            hour: 'Ora',
            lat: 'Latitudine',
            long: 'Longitudine',
            close: 'Chiudere',
            finalize: 'Finalizzare',
            informations: 'Informazione',
            comments: 'Commenti',
            type_comment: 'Scrivi il tuo commento',
            comment: 'Commento',
            event: 'Evento',
            pending: 'In attesa di',
            in_negotiation: 'In negoziazione',
            closed: 'Chiuso',
            status: 'Stato',
            all: 'Tutti',
            'Em aberto': 'Ha aperto',
            Pendente: 'In attesa di',
            'Em tratativa': 'In negoziazione',
            Fechado: 'Chiuso',
            'mudou para status': 'cambiato in status',
        },
        face_missing: {
            proportion: 'Proporzione',
            fleet: 'Flotta',
            details: 'Dettagli',
            details_table_subtitle: 'Selezionare il formato desiderato per scaricare le informazioni sulla tabella',
            face_missing: 'Face Missing',
            total_alarms: 'Allarmi totali',
            percentage: 'Percentuale',
            status: 'Stato',
            proportion_graph_subtitle: 'Questo grafico rappresenta la proporzione degli eventi nel periodo selezionato.',
            fleet_graph_subtitle: 'Il grafico sottostante mostra lo stato del dispositivo DS in base a ciascuna flotta.',

            informative: 'Informativo',
            about_face_missing: 'Informazioni sul volto mancante:',
            a_fleet_is_classified: 'Una flotta viene classificata come sfollata se almeno il 50% degli eventi in questa flotta Face Missing.',
        },
        registration_shipments: {
            registration_of_report_submissions: 'Registrazione degli invii di report',
            operation: 'Operazione',
            email: 'Email',
            shipping_type: 'Tipo di spedizione',
            channel: 'Canale',
            priority: 'Priorità',
            telephone: 'Telefono',
            add_chance: 'Aggiungi modifica',
            send_request: 'Invia richiesta',
            contact: 'Contatto',
            save: 'Salva',
            cancel: 'Annulla',
            daily_report: 'Rapporto Giornaliero',
            delete_contact: 'Elimina contatto',
            title_confirm_delete: 'Vuoi eliminare il contatto “NOME CONTATTO”?',
            confirm: 'Confermare',
            registration_shipments: 'Spedizioni di registrazione',
            intervention: 'Intervento',
            contacts: 'Contatti',
            select_type: 'Seleziona il tipo',
        },
        fleets: {
            fleets: 'Flotte',
            ds_ranking: 'Classifica DS',
            fleet_ranking: 'Classifica de flotta',
            total: 'Totale',
            time_range: 'Intervallo di tempo',
            heat_map: 'Mappa di calore',
            events: 'Eventi',
            type: 'Tipo',
            bar: 'Sbarra',
            line: 'Linea',
            day: 'Giorno',
            week: 'Settimana',
            month: 'Mese',
            year: 'Anno',
            select_the_event: `seleziona l'evento`,
            hours_per_bar: 'Ore per barra',
            period: 'Periodo',
            polar: 'Polare',
            cartesian: 'Cartesiano',
            radio: 'Radio',
            blur: 'Sfocatura',
            period_view: 'Vista del periodo',
            need_to_select_fleet: 'Seleziona una flotta dalla classifica sopra per visualizzare i dettagli della flotta',
            fleet_ranking_subtile: 'Questa classifica rappresenta il totale degli eventi di tutte le flotte',
            fleet_total_subtile: 'Questo grafico rappresenta il totale degli eventi della flotta selezionata',
            time_range_subtile: 'Le bande di questo grafico indicano gli orari con maggiore incidenza di eventi',
            heat_map_subtitle: 'Le onde di calore in questo grafico indicano i luoghi con una maggiore incidenza di eventi',
        },
        events: {
            events: 'Eventi',
        },
        intervention_protocol: {
            intervention_protocol: 'Protocollo di intervento',
            protocol: 'Protocollo',
            group_whatsapp: 'Gruppo whatsapp',
            observation: 'Osservazione',
            enter_the_name_of_the_operation: 'inserire il nome dell operazione',
            enter_protocol_name: 'inserire il nome del protocollo',
            enter_group_name: 'inserire il nome del gruppo',
            type_the_note: 'digita la tua osservazione',
            enter_event_name: 'Inserisci nome evento',
            to_accept: 'Accettare',
            cancel: 'Annulla',
            to_add: 'Aggiungere',
            add_protocol: 'Aggiungi protocollo',
            add_details: 'Aggiungi dettagli',
            delete_protocol: 'Elimina protocollo',
            do_you_want_to_delete_the_protocol: 'Vuoi eliminare il protocollo “PROTOCOL NAME”?',
            operation: 'Operazione',
            protocol_details: 'Dettagli del protocollo',
            edit_protocol: 'Modifica protocollo',
            save: 'Salvare',
            event: 'Evento',
            amount: 'Quantità',
            enter_the_amount: `Inserisci l'importo`,
            time: 'Tempo',
            enter_the_time: `Inserire l'ora`,
            wait: 'Aspettare (h)',
            enter_the_wait: `entrare nell'attesa`,
            send_image: 'Invia immagine',
            select_the_option: `Seleziona_l'opzione`,
            send_video: 'Inviare video',
            edit_details: 'Modifica i dettagli',
            back: 'Indietro',
            advance: 'Avanzare',
            to_close: 'Chiudere',
            want_to_delete_the_protocol_event_protocol_name: 'voglio eliminare il protocollo evento nome protocollo',
            new_details: 'Nuovi Dettagli',
            delete: 'Eliminare',
            this_action_cannot_be_undone: 'questa azione non può essere annullata',
            select_the_event: `seleziona_l'evento`,
            confirm: 'Confermare',
            view_protocol: 'Visualizza protocollo',
        },
        drivers: {
            drivers: 'Autisti',
            driver_history: 'Historique du pilote',
            images: 'Immagini',
            id: 'Id',
            operation: 'Operazione',
            fleet: 'Flotta',
            event: 'Evento',
            day: 'Giorno',
            alarm_hour: 'Ora della sveglia',
            latitude: 'Latitudine',
            longitude: 'Longitudine',
            id_cluster: 'Id Cluster',
            driver: 'Autista',
            document: 'Documento',
            time_range: 'Fascia oraria',
            heat_map: 'Mappa di calore',
            history_of_events: 'Storia degli eventi',
            subtitle_history_of_events: 'I punti su questa mappa indicano i luoghi in cui gli eventi si verificano più frequentemente.',
            subtitle_time_range: 'Le fasce di questo grafico indicano gli orari con il maggior verificarsi di eventi',
            subtitle_history_events: 'I dati nella tabella mostrano la cronologia degli eventi.',
            driver_ranking: 'Classifica dei conducenti',
            search_by_id_cluster: 'Ricerca per cluster ID',
            search_by_image: 'Ricerca per immagine',
            upload_image: 'Carica Immagine',
            click_on_a_line: 'Fare clic su una riga per visualizzare la cronologia del driver selezionato.',
            period: 'Periodo',
            week: 'Settimana',
            month: 'Mese',
            year: 'Anno',
            hours_per_bar: 'Ore per bar',
            coordinates: 'Coordinate',
            polar: 'Polare',
            cartesian: 'Cartesiano',
            events: 'Eventi',
            select_the_event: 'Seleziona eventi',
            ray: 'Ray',
            blur: 'Blur',
            subtitle_driver_history: 'I dati in questo grafico indicano il numero totale di eventi del conducente in base al periodo.',
            need_to_select_driver: 'Seleziona un pilota dalle classifiche sopra per visualizzare i dettagli del pilota',
            photos_legends: 'Foto degli eventi accaduti',
            file_text: 'Nessun file selezionato.',
            search: 'Cercare',
            no_face_founded: "Nessun volto rilevato nell'immagine.",
            try_again: 'Riprova',
            driver_founded_no_events: 'Volto rilevato ma nessun driver trovato.',
            similarity: 'Somiglianza',
            type: 'Tipo',
            bar: 'Sbarra',
            line: 'Linea',
            heat_map_subtitle: 'Le onde di calore in questo grafico indicano i luoghi con una maggiore incidenza di eventi',
        },
        last_alarm: {
            last_alarm: 'Ultimo Allarme',
            operation: 'Operazione',
            fleet: 'Flotta',
            last_communication: 'Ultima Comunicazione',
            days_since_the_last_communication: `Giorni dall'ultima comunicazione (giorni)`,
            days_since_the_last_alarm: `Ultimo allarme (giorni)`,
            status: 'Stato',
            proportion: 'Proporzione',
            table: 'Tavolo',
            subtitle_pie_chart: 'Questo grafico rappresenta la proporzione degli eventi nel periodo selezionato.',
            subtitle_bar_chart: 'Questo grafico mostra lo stato della comunicazione per operazione e tutte le flotte al suo interno.',

            informative: 'Informativo',
            a_fleet_is_classified_as: 'Una flotta è classificata come:',
            no_communication: 'Nessuna comunicazione se non vi è stata comunicazione per oltre 6 giorni;',
            communicating_without_event:
                'Non segnalare alcun evento se ha segnalato negli ultimi 6 giorni ma non ha presentato alcun allarme in quel periodo;',
            if_you_are_communicating: 'OK! se hai comunicato e ricevuto allarmi negli ultimi 6 giorni.',
        },
        comparison_of_units: {
            comparison_of_units: 'Confronto di unità',
            country: 'Paese',
            week: 'Settimana',
            yawn: 'Sbadiglio',
            drowsinessN1: 'Sonnolenza N1',
            drowsinessN2: 'Sonnolenza N2',
            cellPhone: 'Cellulare',
            smoking: 'Fumare',
            occlusion: 'Occlusione',
            lookingDownN1: 'Guardando in basso N1',
            lookingDownN2: 'Guardando in basso N2',
            drowsinessIndex: 'Indice di sonnolenza',
            distractionIndex: 'Indice di distrazione',
            telemetryIndex: 'Indice di telemetria',
            generalIndex: 'indice generale',
            averages_per_period: 'Medie per periodo',
            comparison: 'Comparaison',
            the_vehicles_column_is_the_maximum:
                'La colonna <strong>Veicoli</strong> indica il numero massimo di veicoli che hanno circolato ogni giorno.',
            each_event_column_contains_the_total_number:
                'Ciascuna colonna evento contiene il numero totale di eventi del periodo diviso per il numero di Veicoli ottenuti sopra, a sua volta diviso per il numero di giorni attivi.',
            we_have_the_following_formulas: 'Abbiamo le seguenti formule:',
            fatigue_index: 'Indice di fatica = 10 * Sonnolenza N1 + 20 * Sonnolenza N2',
            distraction_index:
                'Indice di distrazione = 15 * Cellulare + 5 * Fumo + 15 * Occlusione + 1 * Guardare in basso N1 + 2 * Guardare in basso N2 + 15 * Cuffia',
            indice_argus: 'Indice Argus = Indice di fatica + Indice di distrazione',
            period_vision: 'Periodo Visione',
            unity_vision: 'Unità Visione',
            day: 'Diorno',
            month: 'mese',
            year: 'Anno',
            period: 'Periodo',
            operation: 'Operazione',
            region: 'Regione',
            column: 'Colonna',
            select_the_event: `Seleziona l'evento`,
            informative: 'Informativo',
        },
        tableEventName: {
            Sonolência: 'Sonnolenza',
            'Sonolência N1': 'Sonnolenza N1',
            'Sonolência N2': 'Sonnolenza N2',
            'Sonolência leve': 'Lieve sonnolenza',
            'Olhando para baixo N1': 'Guardando giù N1',
            'Olhando para baixo N2': 'Guardando giù N2',
            Celular: 'Cellulare',
            'Sem cinto': 'Nessuna cintura',
            Fumando: 'Fumare',
            Oclusão: 'Occlusione',
            Bocejo: 'Sbadiglio',
            'Face missing': 'Face missing',
            'Óculos de sol': 'Occhiali da sole',
            'Consumo alimento': 'Consumo alimentare',
            'Fone de ouvido': 'Auricolare',
            'Colisão frontal': 'Collisione frontale',
            'Alarme antecipado de colisão': 'Collisione frontale',
            'Colisão de pedestres': 'Collisione pedone',
            'Alarme de colisão de passageiros': 'Collisione pedone',
            'Excesso de velocidade da via': 'Velocità su strada eccessiva',
            'Alarme de excesso de limite de sinal rodoviário': 'Velocità su strada eccessiva',
            'Colisão para frente de baixa velocidade': 'Collisione frontale a bassa velocità',
            'Curva rápida': 'Giro veloce',
            'Curva rápida à direita': 'Giro veloce',
            'Curva rápida à esquerda': 'Giro veloce',
            'Veículo muito próximo': 'Veicolo molto vicino',
            'Distância de veículos muito próxima': 'Veicolo molto vicino',
            'Excesso de velocidade na faixa de pedestre': 'Velocità eccessiva sulle strisce pedonali',
            'Excesso de velocidade na listra de zebra': 'Velocità eccessiva sulle strisce pedonali',
            'Mudança irregular de faixa': 'Cambio di corsia irregolare',
            'Linha de pressão direita da pista': 'Cambio di corsia irregolare',
            'Linha de pressão esquerda da pista': 'Cambio di corsia irregolare',
            'Ponto cego': 'Punto cieco',
            Velocidade: 'Eccesso di velocità',
            'Condução perigosa N1': 'Guida pericolosa N1',
            'Condução perigosa N2': 'Guida pericolosa N2',
            'DS deslocado': 'DS dislocato',
            'Dano ao DS': 'Danni DS',
            'Cinto irregular': 'Cintura irregolare',
            Rádio: 'Radio',
            'Falso alarme': 'Falso allarme',
            'Tentativa de Oclusão': 'Tentata occlusione',
            'Oclusão parcial': 'Occlusione parziale',
            'Passageiro irregular': 'Passeggero irregolare',
            Teste: 'Test',
            '?': '?',
            'Óculos de sol à noite': 'Occhiali da sole di notte',
            Infração: 'Infrazione',
            Distração: 'Distrazione',
            Fadiga: 'Fatica',
            'Excesso de Velocidade': 'Eccesso di velocità',
            'Direção perigosa': 'Guida pericolosa',
            'Direção agressiva': 'Guida aggressiva',
        },
        hours: {
            in: 'in',
            ago: 'indietro',
            secondsAgo: 'qualche secondo fa',
            seconds: 'secondi',
            minute: 'minuti',
            aMinute: 'un minuto',
            hour: 'ora',
            hours: 'ore',
            oneDay: 'un giorno',
            days: 'giorni',
            oneWeek: 'una settimana',
            weeks: 'settimane',
            monthAgo: 'un mese fa',
            months: 'mesi',
            aYear: 'un anno',
            years: 'anni',
        },
        echarts: {
            group: 'Gruppo',
            total: 'Totale',
        },
    },
};

export default IT;
