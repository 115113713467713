import { NodeProps } from 'src/common/components/checkbox-tree';

import { HierarchyOperation } from '../../departments/dtos/hierarchy.dto';

export class OperationHelper {
    private higherParents: Array<HierarchyOperation> = [];
    private copy: NodeProps[] = [];
    public constructor(private _ids: string[]) {}

    public set ids(ids: string[]) {
        this._ids = ids;
    }

    public get ids() {
        return this._ids;
    }

    public set setCopy(copy: NodeProps[]) {
        this.copy = copy;
    }

    public findHigherParents(hierarchyOperations: Array<HierarchyOperation>): Array<HierarchyOperation> {
        const parsedIds = this._ids?.map(id => Number(id));

        if (!parsedIds?.length) return [];

        hierarchyOperations?.forEach(object => {
            const isHigherParent = !object?.children
                ? parsedIds.includes(object.id)
                : object?.children?.map(c => c.id)?.every(v => parsedIds.includes(v));
            if (isHigherParent) {
                this.higherParents.push(object);
            } else {
                this.findHigherParents(object?.children);
            }
        });

        return this.higherParents || [];
    }

    public static toTree(hierarchyOperations: Array<HierarchyOperation>) {
        hierarchyOperations.forEach((object: any) => {
            Object.keys(object).forEach(key => {
                const prop = object[key as keyof HierarchyOperation];

                object.label = object.name;
                object.value = object.id.toString();

                if (Array.isArray(prop)) {
                    if (prop.length === 0) {
                        object.children = undefined;
                        return;
                    }

                    this.toTree(prop as HierarchyOperation[]);
                }
            });
        });

        return hierarchyOperations as unknown as Array<NodeProps>;
    }

    public getIds(hierarchyOperations: Array<HierarchyOperation>) {
        hierarchyOperations.forEach(object => {
            this._ids.push(object.id.toString());

            if (object.children.length) {
                this.getIds(object.children);
            }
        });

        return this._ids;
    }

    public filter(value: string, nodes: any[]): NodeProps[] {
        if (!value) return nodes;

        nodes.forEach(node => {
            if (node.label.toUpperCase().startsWith(value.toUpperCase())) {
                if (this.copy.map(c => c.value).includes(node.value)) return;

                if (this.copy.map(c => c.value).includes(node?.parentId?.toString())) {
                    this.copy = this.copy.filter(c => c.value !== node?.parentId?.toString());
                }

                this.copy.push(node);
            } else if (node.children) {
                this.filter(value, node.children);
            }
        });

        return this.copy;
    }
}
