import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    CardHeader,
    Column,
    Content,
    DeleteButton,
    Divider,
    FilterButton,
    GroupShare,
    MainCard,
    NoContent,
    Pagination,
    RenderIf,
    Row,
    SelectNumber,
    Skeleton,
    Title,
    UsedFilters,
} from 'src/common/components';
import { ObjectHelper } from 'src/common/helpers';
import { useEventListener, usePermission, useSearch } from 'src/common/hooks';
import { CreateOrUpdateDepartmentDialogForm, DptoTableList, Filters, ListDepartmentsDTO } from 'src/modules/configurations';
import { useOperations } from 'src/modules/configurations/departments/hooks/use-operations';

export default function Departments() {
    const {
        data,
        deleteInBulkDialogRef,
        handleOpenDialog,
        handleSelectAll,
        isError,
        isLoading,
        isSuccess,
        mutate,
        operations,
        request,
        selectedOperations,
        setRequest,
    } = useOperations();
    const { isAdmin } = usePermission();
    const { formRef, parse, clear } = useSearch();
    const { t } = useTranslation();

    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const handleClearFilterValue = useCallback(
        (key: keyof ListDepartmentsDTO) => {
            handleSelectAll([]);
            setRequest(state => ({
                ...state,
                [key]: '',
            }));
            setFilterIsOpen(false);
        },
        [handleSelectAll, setRequest],
    );

    const handleClearFilters = useCallback(() => {
        setRequest({
            active: true,
            currentPage: 1,
            name: '',
            pageSize: 15,
            parentName: '',
        });
        setFilterIsOpen(false);
    }, [setRequest]);

    const handleRequest = useCallback(() => {
        mutate(request);
    }, [mutate, request]);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            const text = parse(e, 'text');
            handleSelectAll([]);
            setFilterIsOpen(false);

            setRequest(state => ({
                ...state,
                active: true,
                currentPage: 1,
                name: '',
                parentName: '',
                text,
            }));
        },
        [handleSelectAll, parse, setRequest],
    );

    const content: JSX.Element = useMemo(() => {
        if (isLoading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        }

        if (isError) {
            return <NoContent message={t('general.error')} onClick={handleRequest} messageClassName="error" />;
        }

        if (data?.totalElements === 0) {
            return <NoContent message={t('general.no_content')} onClick={handleRequest} />;
        }

        return <DptoTableList />;
    }, [data?.totalElements, handleRequest, isError, isLoading, t]);

    const filters = useMemo(() => (filterIsOpen ? <Filters /> : null), [filterIsOpen]);

    const usedFilters = useMemo(
        () => ({
            name: request?.name,
            parentName: request?.parentName,
        }),
        [request?.name, request?.parentName],
    );

    const usedFiltersComponent = useMemo(
        () =>
            !isLoading &&
            !ObjectHelper.isEmpty(usedFilters as any) && (
                <UsedFilters data={usedFilters} onClear={handleClearFilterValue} onClearAll={handleClearFilters} />
            ),
        [handleClearFilterValue, handleClearFilters, isLoading, usedFilters],
    );

    useEffect(() => {
        handleRequest();
    }, [handleRequest, mutate, request]);

    useEffect(() => {
        return () => {
            setRequest({
                active: true,
                currentPage: 1,
                name: '',
                pageSize: 15,
                parentName: '',
                text: '',
            });
            clear();
        };
    }, [clear, setRequest]);

    useEventListener(formRef.current, 'submit', handleSearch);

    return (
        <Column gap={16}>
            <Row gap={16} align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('general.configurations')}</h2>
                </Title>
                <RenderIf condition={isAdmin}>
                    <Row
                        className="w-full"
                        gap={16}
                        style={{
                            minWidth: selectedOperations?.length ? '486px' : '228px',
                        }}
                    >
                        <RenderIf condition={!!selectedOperations?.length}>
                            <DeleteButton onClick={() => deleteInBulkDialogRef?.current?.showModal()} />
                        </RenderIf>
                        <Button leftIcon={<GroupShare />} type="button" size="medium" padding="0 12px" onClick={() => handleOpenDialog()}>
                            {t('configurations.add_department')}
                        </Button>
                    </Row>
                </RenderIf>
            </Row>
            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>
                                {t('configurations.departments')}
                                {isSuccess && !isLoading && <span className="total">({operations?.totalElements || 0})</span>}
                            </h3>
                        </Row>
                        <Row align="center" gap={16}>
                            <Row align="center" gap={8}>
                                <span className="per-page">{t('general.items_per_page')}:</span>
                                <SelectNumber
                                    fontMode="small"
                                    value={request.pageSize || 15}
                                    options={[15, 25, 50]}
                                    onChange={e => setRequest(state => ({ ...state, pageSize: Number(e.target.value), currentPage: 1 }))}
                                />
                            </Row>
                            <FilterButton isOpen={filterIsOpen} onOpen={setFilterIsOpen} />
                        </Row>
                    </CardHeader>
                    <Divider />
                    {filters}
                    {usedFiltersComponent}
                    {content}
                    <Divider />
                    <RenderIf condition={!!operations?.totalElements}>
                        <Pagination
                            currentPage={request.currentPage}
                            perPage={request.pageSize}
                            onPageChange={(currentPage: number) => setRequest(state => ({ ...state, currentPage }))}
                            total={operations?.totalElements}
                        />
                    </RenderIf>
                </Column>
            </MainCard>
            <CreateOrUpdateDepartmentDialogForm />
        </Column>
    );
}
