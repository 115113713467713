import { Service } from 'src/common/core/service';
import { api } from 'src/common/infra';

import { SortAlarmRequestDTO } from '../dtos/event.dto';

class ReviewAlarmService implements Service<SortAlarmRequestDTO, void> {
    public async execute(request: SortAlarmRequestDTO): Promise<void> {
        const { id, ...data } = request;

        await api.put(`/alarms-processing/review-alarm/${id}`, data);
    }
}

export const reviewAlarm = new ReviewAlarmService();
