import { useMemo } from 'react';
import { useEvents } from 'src/modules/alarm-processing/hooks';
import { useAuth } from 'src/modules/auth';
import { userProfile } from 'src/modules/auth/constants/user-profile';

import HistoricRoute from '../historic-route';
import TabPastEvents from '../tab-past-events';
import TabSortAndReviewAlarm from '../tab-sort-review-alarm';
import TabViewAlarm from '../tab-view-alarm';

interface TabsContentProps {
    value: number | false;
    onClose(): void;
}

export default function TabsContent({ value, onClose }: TabsContentProps): JSX.Element | null {
    const { session } = useAuth();
    const { selectedEvents } = useEvents();

    return useMemo(() => {
        switch (value) {
            case 0:
                if (selectedEvents![0].alarmSource === 'FW' || session?.user.profile === userProfile.client) {
                    return <TabViewAlarm onClose={onClose} />;
                }

                return <TabSortAndReviewAlarm onClose={onClose} />;
            case 2:
                return <HistoricRoute />;
            case 3:
                return <TabPastEvents />;
            default:
                return null;
        }
    }, [onClose, selectedEvents, session?.user.profile, value]);
}
