import styled from 'styled-components';

import { TableData } from '../../../../../common/components/table/styles';

export interface TableColumnDataProps {
    backgroundColor?: string;
}

export const TableColumnData = styled(TableData)<TableColumnDataProps>`
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
