import { ChangeEventHandler, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    CardHeader,
    Column,
    Content,
    DeleteModal,
    Divider,
    FilterButton,
    MainCard,
    NoContent,
    Pagination,
    Radio,
    RenderIf,
    Row,
    SelectNumber,
    Skeleton,
    Title,
    UsedFilters,
} from 'src/common/components';
import { useEventListener, usePermission, useSearch } from 'src/common/hooks';
import { UpdateData } from 'src/modules/alarm-processing';
import { CreateOrUpdateElectronicFenceModal, ElectronicFenceList, ElectronicFenceMapModal } from 'src/modules/vehicle-monitoring/components';
import ElectronicFenceFilter from 'src/modules/vehicle-monitoring/components/electronic-fence-filter';
import { useDeleteElectronicFence, useElectronicFence, useListElectronicFence } from 'src/modules/vehicle-monitoring/hooks';

export default function ElectronicFence() {
    const { isOpen, setIsOpen, filter, setFilter, initialFormData } = useElectronicFence();
    const { mutateAsync: delety, isLoading: isDeleting } = useDeleteElectronicFence();
    const { data, isError, isLoading, mutate } = useListElectronicFence();
    const { has } = usePermission();
    const { formRef, clear, inputRef } = useSearch();
    const { t } = useTranslation();

    const controller = useMemo(() => new AbortController(), []);

    const handleClearFilters = useCallback(() => {
        setIsOpen(state => ({ ...state, filter: false }));
        setFilter(state => ({
            ...state,
            currentPage: 1,
            operation: '',
        }));
    }, [setFilter, setIsOpen]);

    const handleChangeStatus: ChangeEventHandler<HTMLInputElement> = e => {
        setFilter(state => ({ ...state, status: e.target.value as 'all' | 'active' | 'inactive' }));
    };

    const handleDelete = useCallback(async () => {
        if (initialFormData?.id) await delety({ signal: controller.signal, id: initialFormData.id });
    }, [controller.signal, delety, initialFormData?.id]);

    const handleOpenDeleteModal = () => {
        setIsOpen(state => ({ ...state, delete: !state.delete }));
    };

    const handleOpenMapModal = () => {
        setIsOpen(state => ({ ...state, map: !state.map }));
    };

    const handleList = useCallback(() => {
        mutate({
            ...filter,
            signal: controller.signal,
        });
    }, [controller, filter, mutate]);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();
            setIsOpen(state => ({ ...state, filter: false }));
            setFilter(state => ({ ...state, operation: '', currentPage: 1, text: inputRef?.current?.value as string }));
        },

        [inputRef, setFilter, setIsOpen],
    );

    useEffect(handleList, [handleList]);

    useEffect(() => {
        return () => {
            controller.abort();
            clear();
        };
    }, [clear, controller]);

    useEventListener(formRef.current, 'submit', handleSearch);

    const content: JSX.Element = useMemo(() => {
        if (isLoading) {
            return (
                <Content className="mb-16">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        }

        if (isError) {
            return <NoContent message={t('general.error')} messageClassName="error" onClick={handleList} />;
        }

        if (!data?.elements?.length) {
            return <NoContent message={t('general.no_content')} onClick={handleList} />;
        }

        return <ElectronicFenceList />;
    }, [data, handleList, isError, isLoading, t]);

    return (
        <>
            <Column gap={16}>
                <Row gap={16} align="center" width="100%" justify="space-between">
                    <Title>
                        <h2>{t('menu.vehicles_monitoring')}</h2>
                        <UpdateData onClick={handleList} />
                    </Title>
                    <RenderIf condition={has('electronic_fence')}>
                        <Row gap={16} className="w-full">
                            <Button type="button" size="medium" padding="0 12px" onClick={handleOpenMapModal}>
                                {t('monitoring.add_fence')}
                            </Button>
                        </Row>
                    </RenderIf>
                </Row>
                <MainCard>
                    <Column width="100%" gap={16}>
                        <CardHeader>
                            <Row gap={16} align="center">
                                <h3>{t('monitoring.eletronic_fence')}</h3>
                            </Row>

                            <Row align="center" gap={32}>
                                <Row align="center" gap={16}>
                                    <Radio
                                        name="status"
                                        value="all"
                                        label={t('general.all')?.toString()}
                                        checked={filter.status === 'all'}
                                        onChange={handleChangeStatus}
                                    />
                                    <Radio
                                        name="status"
                                        value="active"
                                        label={t('general.status.active')?.toString()}
                                        checked={filter.status === 'active'}
                                        onChange={handleChangeStatus}
                                    />
                                    <Radio
                                        name="status"
                                        value="inactive"
                                        label={t('general.status.inactive')?.toString()}
                                        checked={filter.status === 'inactive'}
                                        onChange={handleChangeStatus}
                                    />
                                </Row>

                                <Row align="center" gap={8}>
                                    <Row align="center" gap={8}>
                                        <span className="per-page">{t('general.items_per_page')}:</span>
                                        <SelectNumber
                                            fontMode="small"
                                            onChange={e => setFilter(state => ({ ...state, pageSize: Number(e.target.value), currentPage: 1 }))}
                                            options={[15, 25, 50]}
                                            value={filter.pageSize}
                                        />
                                    </Row>
                                    <FilterButton isOpen={isOpen.filter} onClick={() => setIsOpen(state => ({ ...state, filter: !state.filter }))} />
                                </Row>
                            </Row>
                        </CardHeader>
                        <Divider />
                        <RenderIf condition={isOpen.filter}>
                            <ElectronicFenceFilter />
                        </RenderIf>
                        <RenderIf condition={!!filter?.operation?.trim() && !isLoading}>
                            <UsedFilters
                                data={{
                                    operation: filter?.operation,
                                }}
                                onClear={handleClearFilters}
                                onClearAll={handleClearFilters}
                            />
                        </RenderIf>
                        {content}
                        <RenderIf condition={!!data?.totalElements}>
                            <Fragment>
                                <Divider />
                                <Pagination
                                    currentPage={filter.currentPage}
                                    onPageChange={currentPage => setFilter(state => ({ ...state, currentPage }))}
                                    perPage={filter.pageSize}
                                    total={data?.totalElements}
                                />
                            </Fragment>
                        </RenderIf>
                    </Column>
                </MainCard>
            </Column>
            <RenderIf condition={isOpen.map}>
                <ElectronicFenceMapModal />
            </RenderIf>
            <RenderIf condition={isOpen.createOrUpdate}>
                <CreateOrUpdateElectronicFenceModal />
            </RenderIf>
            <RenderIf condition={isOpen.delete}>
                <DeleteModal
                    open={isOpen.delete}
                    onClose={handleOpenDeleteModal}
                    onConfirm={handleDelete}
                    title={t('monitoring.delete_electronic_fence')}
                    message={
                        <>
                            {t('monitoring.do_you_want_to_delete_the_electronic_fence')} <b>{JSON.stringify(initialFormData?.name)}</b>?
                            <br />
                            {t('configurations.this_action')}
                        </>
                    }
                    isLoading={isDeleting}
                />
            </RenderIf>
        </>
    );
}
