import styled from 'styled-components';

import { MainCard } from '../../../../../common';

export const Card = styled(MainCard)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    gap: 1.5rem;

    width: 35rem;

    > div {
        display: flex;
        flex-direction: column;
        gap: 0.935rem;
        width: 100%;
    }

    p {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: block;
        flex: 1;

        margin: 0;

        color: #7b7b7b;
        word-break: break-all;
    }

    h2 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        text-align: center;

        color: #262d3c;
        word-break: break-all;
    }

    h3 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        display: block;
        flex: 1;

        margin: 0;

        color: #7b7b7b;
        word-break: break-all;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;

        li {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            color: #7b7b7b;
        }
    }
`;
