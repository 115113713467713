import blueCheckRounded from 'src/assets/icons/checkbox-blue-check-rounded.svg';
import blueCheck from 'src/assets/icons/checkbox-blue-check.svg';
import blueFill from 'src/assets/icons/checkbox-blue-fill.svg';
import grayCheck from 'src/assets/icons/checkbox-gray-check.svg';
import styled, { css } from 'styled-components';
interface CheckboxLabelProps {
    mode: 'blue-check' | 'blue-check-rounded' | 'blue-fill' | 'gray-check';
}

const checkMode = {
    'blue-check': blueCheck,
    'blue-check-rounded': blueCheckRounded,
    'blue-fill': blueFill,
    'gray-check': grayCheck,
};

export const CheckboxLabel = styled.label<CheckboxLabelProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    position: relative;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #a9a9a9;
    cursor: pointer;
    flex-wrap: wrap;

    span {
        margin-left: 32px;
    }

    input[type='checkbox'] {
        cursor: pointer;
        appearance: none;
        position: absolute;

        box-sizing: border-box;

        &:checked {
            background: ${props => `url('${checkMode[props.mode]}') no-repeat center`};
            & ~ span {
                color: #262d3c;
            }
        }
    }

    ${props =>
        props.mode === 'blue-check' &&
        css`
            input[type='checkbox'] {
                width: 16px;
                height: 16px;
                background-color: #f5f5f5;
                border: 1px solid #a9a9a9;
                border-radius: 4px;

                &:checked {
                    background-size: 16px;
                    border: 0;
                }

                &:not(:checked):hover {
                    border: 2px solid #7a7f85;
                }
            }
        `}

    ${props =>
        (props.mode === 'blue-fill' || props.mode === 'blue-check-rounded') &&
        css`
            input[type='checkbox'] {
                width: 20px;
                height: 20px;
                border: 2px solid #a9a9a9;
                border-radius: 8px;

                &:checked {
                    background-size: 20px;
                    border: 0;
                    font-weight: 500;
                }
            }
        `}

    ${props =>
        props.mode === 'gray-check' &&
        css`
            input[type='checkbox'] {
                width: 20px;
                height: 20px;
                border: 2px solid #a9a9a9;
                border-radius: 8px;

                &:checked {
                    background-size: 20px;
                    border: 0;
                }
            }
        `}
`;
