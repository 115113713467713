import styled from 'styled-components';

export const Container = styled.form`
    display: grid;
    grid-template-columns: 27.5% 27.5% 35% 10%;
    padding: 16px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    position: relative;

    .boolean {
        margin-bottom: 10px;
    }

    @media (max-width: 950px) {
        grid-template-columns: 50% 50%;

        .boolean {
            padding: 10px;
            display: flex;
        }
    }
`;

export const Itens = styled.div`
    min-width: 25%;
    padding: 0 10px;
`;

export const Filter = styled.form`
    display: grid;
    grid-template-columns: 38rem 15rem 12rem;
    grid-auto-flow: column;
    gap: 1rem;
    width: 100%;
    place-content: center right;
`;
