import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableData, TableRow } from 'src/common/components/table/styles';

import { EditFill, Eye, Row, Table, TableOptionButton, Trash } from '../../../../../common';
import { useRegistrationShipments } from '../../hooks';

function TableList() {
    const { t } = useTranslation();
    const { registrations, handleOpenDialog, handleOpenDeleteDialog } = useRegistrationShipments();

    const tableHeaders = useMemo(
        () => [
            'ID',
            t('registration_shipments.operation'),
            t('registration_shipments.email'),
            t('registration_shipments.shipping_type'),
            t('registration_shipments.channel'),
            t('registration_shipments.priority'),
            t('registration_shipments.telephone'),
        ],
        [t],
    );
    return (
        <Fragment>
            <Table tableHeaders={tableHeaders}>
                {registrations?.elements?.map((registration, index) => {
                    return (
                        <TableRow className="body" key={index}>
                            <TableData>{index + 1}</TableData>
                            <TableData>{registration.operation}</TableData>
                            <TableData>{registration.email}</TableData>
                            <TableData>{registration.sentType}</TableData>
                            <TableData>{registration.channel}</TableData>
                            <TableData>{registration.priority}</TableData>
                            <TableData>{registration.telephone}</TableData>
                            <TableData style={{ width: '7rem', paddingRight: '16px' }}>
                                <Row gap={16}>
                                    <TableOptionButton
                                        type="button"
                                        onClick={e => {
                                            e.stopPropagation();
                                            handleOpenDialog(registration);
                                        }}
                                    >
                                        <Eye color="#7B7B7B" />
                                    </TableOptionButton>
                                    <TableOptionButton
                                        type="button"
                                        onClick={e => {
                                            e.stopPropagation();
                                            handleOpenDialog(registration);
                                        }}
                                    >
                                        <EditFill color="#7B7B7B" />
                                    </TableOptionButton>
                                    <TableOptionButton
                                        type="button"
                                        onClick={e => {
                                            e.stopPropagation();
                                            handleOpenDeleteDialog(registration);
                                        }}
                                    >
                                        <Trash />
                                    </TableOptionButton>
                                </Row>
                            </TableData>
                        </TableRow>
                    );
                })}
            </Table>
        </Fragment>
    );
}

export default memo(TableList);
