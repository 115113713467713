import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { FunctionComponent, ResponseStatus } from 'src/common';
import { Pagination } from 'src/common/core/pagination';

import { ReviewDTO, ReviewRequestDTO } from '../dtos/review-request.dto';
import { getReviewRequestReview } from '../services';
import { ReviewRequestContextProps } from './types';

export const ReviewRequestContext = createContext({} as ReviewRequestContextProps);

export function ReviewRequestProvider({ children }: FunctionComponent) {
    const reviewRequestDialogRef = useRef<HTMLDialogElement>(null);

    const [reviews, setReviews] = useState<Pagination<ReviewDTO> | null>(null);

    const [selectedReview, setSelectedReview] = useState<ReviewDTO | null>(null);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const [screenType, setScreenType] = useState(0);

    const [requestData, setRequestData] = useState<ReviewRequestDTO>({
        currentPage: 1,
        pageSize: 15,
    });

    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
    });

    const handleRequestList = useCallback((data: Partial<ReviewRequestDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleGetList = useCallback(async () => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const data = await getReviewRequestReview.execute(requestData);

            setReviews(data);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: !data?.elements || data?.elements?.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const handleOpenDialog = useCallback((data: ReviewDTO | null, screenTypeIndex: number) => {
        setScreenType(screenTypeIndex);
        setDialogIsOpen(state => {
            if (state) {
                setSelectedReview(null);
                reviewRequestDialogRef?.current?.close();
            } else {
                setSelectedReview(data);
                reviewRequestDialogRef?.current?.showModal();
            }

            return !state;
        });
    }, []);

    const data: ReviewRequestContextProps = useMemo(
        () => ({
            reviews,
            handleRequestList,
            handleGetList,
            requestData,
            responseStatus,
            dialogIsOpen,
            reviewRequestDialogRef,
            handleOpenDialog,
            selectedReview,
            screenType,
        }),
        [
            reviews,
            handleRequestList,
            handleGetList,
            requestData,
            responseStatus,
            dialogIsOpen,
            reviewRequestDialogRef,
            handleOpenDialog,
            selectedReview,
            screenType,
        ],
    );

    return <ReviewRequestContext.Provider value={data}>{children}</ReviewRequestContext.Provider>;
}
