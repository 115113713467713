import styled from 'styled-components';

interface TabProps {
    selected: boolean;
}

export const Tab = styled.button<TabProps>`
    background: ${props => (props.selected ? '#262D3C' : '#e6e6e6')};
    border-radius: 4px;

    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    width: 32px;
    height: 32px;

    svg > * {
        stroke: ${props => (props.selected ? '#fff' : '#a9a9a9')};
    }

    &:hover {
        background-color: ${props => (props.selected ? '#30384b' : 'buttonface')};
    }
`;
