import { TFunction } from 'i18next';
import { hourRegex } from 'src/common/helpers/hour.helper';
import { yup } from 'src/common/infra/validations/yup';

export const filterEventsSchema = (t: TFunction) =>
    yup.object().shape({
        endTime: yup
            .string()
            .optional()
            .nullable()
            .test('is-valid-hour', t('field_validations.hour')?.toString(), field => {
                if (!field) return true;
                const match = field.match(hourRegex);

                if (!match) return false;

                return match?.length > 0;
            }),
        startTime: yup
            .string()
            .optional()
            .nullable()
            .test('is-valid-hour', t('field_validations.hour')?.toString(), field => {
                if (!field) return true;
                const match = field.match(hourRegex);

                if (!match) return false;

                return match?.length > 0;
            }),
    });
