import { Fragment } from 'react';

import { Dialog } from '../../../../../common';
import Column from '../../../../../common/components/column';
import { useInterventionProtocol } from '../../hooks';
import { DeleteInterventionProtocolForm } from '../delete-intervention-protocol-form';

export function DeleteInterventionProtocolDialog() {
    const { deleteDialogIsOpen, handleOpenDeleteDialog, deleteInterventionProtocolDialogRef } = useInterventionProtocol();

    return (
        <Dialog id="intervention-protocol-delete-dialog" onCloseModal={handleOpenDeleteDialog} ref={deleteInterventionProtocolDialogRef}>
            <Column width="450px">{deleteDialogIsOpen ? <DeleteInterventionProtocolForm /> : <Fragment />}</Column>
        </Dialog>
    );
}
