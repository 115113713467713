import { SelectHTMLAttributes } from 'react';

import { StyledSelect } from './styles';

export interface SelectNumberProps extends SelectHTMLAttributes<HTMLSelectElement> {
    fontMode: 'large' | 'small';
    options: Array<number>;
    value: number;
}

export default function SelectNumber({ fontMode, options, ...props }: SelectNumberProps): JSX.Element {
    return (
        <StyledSelect className={`font-${fontMode}`} {...props}>
            {options.map(option => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </StyledSelect>
    );
}
