export class DateHelper {
    public static format(date?: Date | null, locale?: string, timeZone?: string): string {
        if (!date) return '';

        return new Date(date).toLocaleDateString(locale, {
            month: '2-digit',
            year: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
            timeZone,
        });
    }
}
