import { Checkbox, ListItemText, MenuItem, Popover, Select, SelectChangeEvent, Typography } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'src/common';

export interface EventsProps {
    checked: boolean;
    name: string;
    translate: string;
}

interface EventFilterProps {
    events: EventsProps[];
    handleChange: (eventChange: SelectChangeEvent<string[]>) => void;
    placeHolder?: string;
}

export function EventsFilter({ events, handleChange, placeHolder }: EventFilterProps) {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (eventSelected: React.MouseEvent<HTMLElement>) => {
        if (events.filter(event => event.checked).length > 0) {
            setAnchorEl(eventSelected.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Column className="select-container" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} onClick={handlePopoverClose}>
            <Select
                id="select-alarm"
                multiple
                displayEmpty={true}
                style={{ height: '2.25rem', background: 'white', borderRadius: '0.5rem' }}
                sx={{
                    '&:hover': {
                        '&& fieldset': {
                            borderColor: '#3e66fb',
                        },
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#3e66fb',
                    },
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: '#000000',
                }}
                value={events.filter(event => event.checked).map(event => event.name)}
                onChange={handleChange}
                renderValue={selected => {
                    if (selected.length === 0) {
                        return <p>{placeHolder ?? String(t('general.select_the_event'))}</p>;
                    }
                    return events
                        .filter(event => event.checked)
                        .map(event => event.name)
                        .join(', ');
                }}
            >
                {events.map(event => {
                    if (!event) return null;
                    return (
                        <MenuItem key={event.name} value={event.name} property={event.name} placeholder={event.name}>
                            <Checkbox checked={event.checked} />
                            <ListItemText primary={event.translate} />
                        </MenuItem>
                    );
                })}
            </Select>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                    width: '70%',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>
                    {events
                        .filter(event => event.checked)
                        .map(event => event.name)
                        .join(', ')}
                </Typography>
            </Popover>
        </Column>
    );
}
