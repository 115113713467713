import { api } from 'src/common';

import { ListVehiclesDTO } from '../dtos';

interface GenerateReportRequest extends Omit<ListVehiclesDTO, 'pageSize' | 'currentPage'> {
    ids?: Array<number>;
    signal?: AbortSignal;
}

export class VehicleReportService {
    public async generateReport(request: GenerateReportRequest): Promise<void> {
        const { signal, typeSearch, fleet, operation, text, type, ids } = request;

        const { data } = await api.get<BlobPart>('/vehicles/reports', {
            responseType: 'blob',
            signal,
            params: {
                typeSearch,
                fleet,
                operation,
                text,
                type,
                ids,
            },
        });

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `vehicles${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }
}

export const vehicleReportService = new VehicleReportService();
