import styled from 'styled-components';

export const CardContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px 32px;
    gap: 32px;

    background-color: #ffffff;
    border-radius: 8px;

    position: relative;
    z-index: 1;

    transition: height 0.3s ease-in, width 0.2s ease-out;
    overflow: auto;

    @media (max-width: 400px) and (max-width: 680px) {
        margin: 24px;
    }

    @media screen and (max-height: 769px) {
        max-height: 95vh;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
    }
`;
