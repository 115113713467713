import { useMemo } from 'react';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';

import { useHistoricalRoute } from '../../hooks';
import Mark from './mark';

interface EventsMarkerProps {
    events: EventDTO[];
}

export default function EventsMarker({ events }: EventsMarkerProps) {
    const { showEvents } = useHistoricalRoute();

    return useMemo(() => {
        if (showEvents) {
            return (
                <>
                    {events.map(event => (
                        <Mark key={Number(event.id)} event={event} />
                    ))}
                </>
            );
        }

        return null;
    }, [events, showEvents]);
}
