import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    padding: 24px;
    width: 656px;
    flex: 1;
`;

export const CloseButton = styled.button`
    border: 0;
    box-sizing: border-box;
    height: 30px;
    padding: 0;
    width: 30px;

    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &:hover {
        background-color: buttonface;
    }
`;

export const MandatoryFields = styled.p`
    cursor: default;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: rgba(0, 0, 0, 0.5);
`;
