import { ApiResponse, TransformRequestHelper, api } from '../../../../common';
import { FaceMissingRequestDTO } from '../dtos/face-missing.dtos';

export class FaceMissingService {
    public async getData({ dateFrom, dateTo, operations }: FaceMissingRequestDTO): Promise<any> {
        const {
            data: { content },
        } = await api.get<ApiResponse>('/analytics/face_missing', {
            params: { dateFrom, dateTo, operations },
        });

        return content;
    }

    public async getDetails({ dateFrom, dateTo, currentPage, pageSize, operations }: FaceMissingRequestDTO): Promise<any> {
        const {
            data: { content },
        } = await api.get<ApiResponse>('/analytics/face_missing/paginated', {
            params: {
                dateFrom,
                dateTo,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
                size: pageSize,
                operations,
            },
        });

        return content;
    }
}

export const faceMissingService = new FaceMissingService();
