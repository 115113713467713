import { api } from 'src/common';
import { ApiResponse, Service } from 'src/common/core';
import Cookies from 'universal-cookie';

export interface PArgusResponse {
    code: number;
    message: string;
    data: Data;
}

interface Data {
    token: string;
}

export class AuthenticateInPArgusService implements Service<AbortController, string> {
    public async execute(controller: AbortController): Promise<string> {
        const cookies = new Cookies();
        const {
            data: { content },
        } = await api.get<ApiResponse<string>>('/vehicle-monitoring/auth-in-p-argus', {
            signal: controller.signal,
        });

        cookies.set('p-argus-token', content, { path: '/', secure: true, sameSite: 'none' });

        return content;
    }
}

export const authInPArgus = new AuthenticateInPArgusService();
