import styled from 'styled-components';

export const Container = styled.div`
    background: #f5f5f5;
    border: 1px solid #9fafd2;
    border-radius: 8px;
    height: 36px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    flex: 1 auto;

    font-family: 'Space Grotesk', sans-serif;

    position: relative;

    &:focus,
    &:focus-within,
    &:hover {
        border-color: #3e66fb;
    }

    input[type='text' i] {
        padding: 0;
    }

    .clear-date-pciker::after {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #46526c;
    }

    .react-datepicker__close-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        position: relative;
    }

    .date-picker {
        box-sizing: border-box;
        background-color: transparent;
        border: 0;
        display: flex;
        font-family: 'Space Grotesk', sans-serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;

        letter-spacing: 0.2px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #46526c;

        &:focus {
            border: 0;
            outline: 0;
        }

        &::placeholder {
            color: #46526c;
        }
        &:focus::placeholder {
            color: rgba(0, 0, 0, 0.25);
        }
    }

    .icon {
        cursor: pointer;
    }

    .react-datepicker {
        font-family: 'Space Grotesk', sans-serif;
    }

    .react-datepicker__close-icon::after {
    }

    .react-datepicker-popper {
        position: absolute;
        z-index: 2;
    }

    .react-datepicker-popper[data-placement^='bottom'] {
        padding-top: 16px;
    }

    .react-datepicker-wrapper {
        width: auto;
    }

    .react-datepicker__current-month {
        display: none;
    }
    .react-datepicker__header__dropdown {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }

    .react-datepicker__input-container {
        display: flex;
        width: auto;
        gap: 8px;
    }

    .react-datepicker__navigation-icon::before {
        height: 4px;
        width: 4px;
        top: 8px;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        height: 4px;
        width: 4px;
        top: 4px;
    }
`;

export const Label = styled.label`
    &:has(span.date-picker-label) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        position: relative;
        flex-wrap: wrap;

        div.date-picker-container {
            background: #ffffff;
            padding: 0 8px;
            border-color: #c2c9d1;
            width: 100%;
            justify-content: flex-start;
            max-height: 36px;

            &:focus,
            &:focus-within,
            &:hover {
                border-color: #3e66fb;
            }

            .react-datepicker-wrapper {
                width: 100%;
                flex: 1;
            }

            .react-datepicker__input-container {
                justify-content: space-between;

                input {
                    flex: 1;
                }
            }

            .date-picker {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #000000;
                outline: 0;

                &::placeholder {
                    color: rgba(0, 0, 0, 0.5);
                }
                &:focus::placeholder {
                    color: rgba(0, 0, 0, 0.25);
                }
            }
        }

        span.date-picker-label {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #000000;
        }
    }
`;
