import { ApiResponse, Service, api } from 'src/common';

import { HistoricalRoute } from '../domain/models/historical-route';
import { EventDTO } from '../dtos/event.dto';

interface GetHistoricalRouteOfEventsRequest {
    finalDate: Date;
    fleet: string;
    initialDate: Date;
    size: number;
    start: number;
    controller: AbortController;
}

export interface GetHistoricalRouteOfEventsResponse {
    selectedEvent: EventDTO | null;
    lastAppearance: HistoricalRoute | null;
    events: Array<EventDTO>;
    historicalRoutes: Array<HistoricalRoute>;
}

export class GetHistoricalRouteService implements Service<GetHistoricalRouteOfEventsRequest, GetHistoricalRouteOfEventsResponse> {
    public async execute(request: GetHistoricalRouteOfEventsRequest): Promise<GetHistoricalRouteOfEventsResponse> {
        const { fleet, finalDate, initialDate, size, start, controller } = request;

        const {
            data: { content },
        } = await api.get<ApiResponse<GetHistoricalRouteOfEventsResponse>>(`/alarms-processing/historical-route/${fleet}`, {
            signal: controller.signal,
            params: {
                finalDate,
                initialDate,
                size,
                start,
            },
        });

        return content;
    }
}

export const getHistoricalRouteService = new GetHistoricalRouteService();
