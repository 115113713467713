import { Resource } from 'i18next';

import { LanguageTranslations } from '../types/translations';
import enUsTranslations from './en-us';
import esTranslations from './es';
import frTranslations from './fr';
import itTranslations from './it';
import ptBrTranslations from './pt-br';

interface Locale extends Resource {
    'en-US': LanguageTranslations;
    es: LanguageTranslations;
    fr: LanguageTranslations;
    it: LanguageTranslations;
    'pt-BR': LanguageTranslations;
}

const locales: Locale = {
    'en-US': enUsTranslations,
    es: esTranslations,
    fr: frTranslations,
    it: itTranslations,
    'pt-BR': ptBrTranslations,
};

export default locales;
