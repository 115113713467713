import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, Column, Divider, Row } from 'src/common/components';
import { toast } from 'src/common/components/toast';

import { useVehicles } from '../../hooks/use-vehicles';
import { vehicleService } from '../../services/vehicle.service';
import { CancelButton, Card, Modal, Title } from './styles';

interface DeleteVehicleProps {
    fleet: string;
    id: number;
    onClose(): void;
    open: boolean;
}

export default function DeleteVehicle({ fleet, id, onClose, open }: DeleteVehicleProps) {
    const { t } = useTranslation();
    const { setFilter, handleSelectAll } = useVehicles();

    const { mutate, isLoading } = useMutation({
        mutationKey: ['delete-vehicle'],
        mutationFn: () => vehicleService.delete(id),
        onError() {
            toast.error(t('general.error'), '');
        },
        onSuccess() {
            toast.success(t('general.success'), '');
            setFilter(state => ({ ...state, currentPage: 1 }));
            handleSelectAll([]);
            onClose();
        },
    });

    return (
        <Modal open={open} onClose={onClose}>
            <Card gap={24} align="center">
                <Title>{t('configurations.delete_vehicle')}</Title>
                <Column gap={16} width="100%" align="center">
                    <Column align="center">
                        <p>
                            {t('configurations.delete_the_vehicle_white_board')} <b>{JSON.stringify(fleet)}</b>?
                        </p>
                        <p>{t('configurations.this_action')}</p>
                    </Column>
                    <Divider />
                </Column>
                <Row align="flex-end" gap={16} flex width="100%">
                    <CancelButton onClick={onClose}>{t('general.cancel')}</CancelButton>
                    <Button type="reset" color="alert" isLoading={isLoading} onClick={() => mutate()}>
                        {t('general.confirm')}
                    </Button>
                </Row>
            </Card>
        </Modal>
    );
}
