import styled, { css } from 'styled-components';

import Row from '../row';

interface ContainerProps {
    mode: 'normal' | 'small';
}

export const ActualPage = styled.span<ContainerProps>`
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #a9a9a9;
    cursor: default;

    ${props =>
        props.mode === 'small' &&
        css`
            font-size: 12px;
            line-height: 16px;
        `}
`;

export const NumberActualTotal = styled.span<ContainerProps>`
    font-family: 'Space Grotesk';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #a9a9a9;
    cursor: default;

    ${props =>
        props.mode === 'small' &&
        css`
            font-size: 12px;
            line-height: 16px;
        `}
`;

export const Wrapper = styled(Row)`
    padding: 0 16px 16px;
`;
