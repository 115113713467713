import { useCallback, useMemo, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { FiCheck } from 'react-icons/fi';
import { Broom, Column, Modal, RenderIf, Row, SquareButton } from 'src/common/components';
import { usePosition } from 'src/common/hooks';

import { GeometricShape } from '../../domain/constants/geometric-shape';
import { useElectronicFence } from '../../hooks';
import { Map } from './map';

export interface OverlayProps {
    coordinates: Array<google.maps.LatLngLiteral>;
    radius: number;
    type: GeometricShape;
}

export default function ElectronicFenceMapModal() {
    const { coordinates } = usePosition();
    const { isOpen, setIsOpen, setFormData } = useElectronicFence();

    const center = useMemo<google.maps.LatLng | google.maps.LatLngLiteral | undefined>(
        () => (coordinates ? { lat: coordinates?.latitude, lng: coordinates?.longitude } : undefined),
        [coordinates],
    );
    const [overlay, setOverlay] = useState<OverlayProps | null>(null);

    const handleOpenMapModal = () => {
        setIsOpen(state => ({ ...state, map: !state.map }));
    };

    const handleConfirm = useCallback(() => {
        if (overlay) {
            setFormData({
                geometricShape: overlay?.type,
                coordinates: overlay?.coordinates,
                radius: overlay.radius,
                active: true,
            });
            setIsOpen(state => ({ ...state, createOrUpdate: true, map: false }));
        }
    }, [overlay, setFormData, setIsOpen]);

    return (
        <Modal open={isOpen.map} id={'electronic-fence-map-modal'} onClose={handleOpenMapModal}>
            <Column gap={16} align="center" justify="center" padding="48px">
                <Row gap={8} width="100%" align="center" justify="flex-end">
                    <RenderIf condition={!!overlay}>
                        <SquareButton bgColor="#1EC01A" type="button" onClick={handleConfirm}>
                            <FiCheck size={20} color="#fff" />
                        </SquareButton>
                    </RenderIf>
                    <RenderIf condition={!!overlay}>
                        <SquareButton bgColor="#262D3C" type="button" onClick={() => setOverlay(null)}>
                            <Broom />
                        </SquareButton>
                    </RenderIf>
                    <SquareButton bgColor="#FF543D" type="reset" onClick={handleOpenMapModal}>
                        <CgClose size={20} color="#fff" />
                    </SquareButton>
                </Row>
                <Map className="big-map" overlay={overlay} setOverlay={setOverlay} center={center} zoom={10} />
            </Column>
        </Modal>
    );
}
