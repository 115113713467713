import { FieldsetHTMLAttributes, ReactNode } from 'react';

import { FieldsetContainer } from './styles';

interface FieldsetProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
    children: ReactNode;
    legend: string;
}

export default function Fieldset({ legend, ...props }: FieldsetProps) {
    return (
        <FieldsetContainer {...props}>
            <legend>{legend}</legend>
            {props.children}
        </FieldsetContainer>
    );
}
