import { createContext, useCallback, useMemo, useReducer, useState } from 'react';
import { Reducer, reducer } from 'src/common/types/reducer';
import { DepartmentResume } from 'src/modules/configurations';

import { DateHelper, FunctionComponent, ResponseStatus } from '../../../../common';
import { Pagination } from '../../../../common/core/pagination';
import { GenericAnalyticsFilterDTO } from '../../common/dtos/generic-filter.dto';
import { DriverByImageResultDTO, DriversDTO, DriversEventsDTO, DriversHistoricDTO, DriversIdClusterDTO, DriversRequest } from '../dtos/drivers.dtos';
import { driversPagedIdClusterService } from '../services/driver.IdCluster.paginated.service';
import { driversIdClusterService } from '../services/driver.IdCluster.service';
import { driversPagedService } from '../services/drivers.paginated.service';
import { driversService } from '../services/drivers.service';
import { INITIAL_OPERATION_STATE } from './const';
import { DriversContextProps } from './types';

export const INIT_FILTER_STATE: GenericAnalyticsFilterDTO = {
    finalDate: new Date(),
    initialDate: DateHelper.yesterday(),
    operationInput: '',
    operations: [],
};

export const DriversContext = createContext({} as DriversContextProps);

export function DriversProvider({ children }: FunctionComponent) {
    const [driversPaged, setDriversPaged] = useState<Pagination<DriversDTO> | null>(null);
    const [driverPagedIdCluster, setDriverPagedIdCluster] = useState<Pagination<DriversDTO> | null>(null);
    const [drivers, setDrivers] = useState<Record<string, DriversEventsDTO[]> | null>(null);
    const [driverIdCluster, setDriverIdCluster] = useState<DriversIdClusterDTO[] | null>(null);
    const [driverByImage, setDriverByImage] = useState<Pagination<DriverByImageResultDTO> | null>(null);
    const [file, setFile] = useState<File>();
    const [returnMessage, setReturnMessage] = useState<string>('');
    const [filter, setFilter] = useState<GenericAnalyticsFilterDTO>(INIT_FILTER_STATE);
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_OPERATION_STATE);
    const [driverHistoric, setDriverHistoric] = useState<DriversHistoricDTO[] | null>(null);

    const [requestData, setRequestData] = useState<DriversRequest>();

    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: false,
        success: false,
        void: false,
    });

    const [responseStatusHistoricEvents, setResponseStatusHistoricEvents] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: false,
        success: false,
        void: false,
    });

    const [responseStatusGraphs, setResponseStatusGraphs] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: false,
        success: false,
        void: false,
    });

    const [responseStatusByImage, setResponseStatusByImage] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: false,
        success: false,
        void: false,
    });

    const handleGetDrivers = useCallback((data: Partial<DriversRequest>) => {
            driversService
                .execute(data)
                .then(data => {
                    setDrivers(data.content);
                })
    }, []);

    const handleGetDriverByImage = useCallback((data: Partial<DriversRequest> & { file: File } ) => {
        setResponseStatusByImage(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        driversService
            .getDriverByImage(data)
            .then(data => {
                const response = data.content as unknown as Pagination<DriverByImageResultDTO>;
                setDriverByImage(response);
                
                setResponseStatusByImage({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: !response?.elements || response?.elements?.length === 0,
                    success: true,
                });
            })
            .catch(error => {
                if (error.response.data.message === 'No face found on image or no drivers similars to this image founded') {
                    setReturnMessage('drivers.no_face_founded');
                }

                setResponseStatusByImage({
                    loading: false,
                    error,
                    hasError: true,
                    void: false,
                    success: false,
                });
            })
    }, []);

    const handleGetDriverIdCluster = useCallback((data: Partial<DriversRequest>) => {
        driversIdClusterService
            .execute(data)
            .then(data => {
                setDriverIdCluster(data);
            })
    }, []);

    const handleGetDriversList = useCallback(async (data: Partial<DriversRequest>) => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const response: Pagination<DriversDTO> = await driversPagedService.execute(data);

            setDriversPaged(response);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: !response?.elements || response?.elements?.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, []);

    const handleGetDriverHistoric = useCallback(async (data: Partial<DriversRequest>) => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const response: DriversHistoricDTO[] = await driversIdClusterService.getHistoric(data);

            setDriverHistoric(response);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: !response || response.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, []);

    const handleRequestList = useCallback((data: Partial<DriversRequest>) => {
        setRequestData(state => {
            return state ? { ...state, ...data } : undefined;
        });
    }, []);

    const handleGetDriverListIdCluster = useCallback(async (data: Partial<DriversRequest>) => {
        setResponseStatusHistoricEvents(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        setResponseStatusGraphs(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const response: Pagination<DriversDTO> = await driversPagedIdClusterService.execute(data);

            if (data.isText){
                setDriversPaged(response);
            }
            setDriverPagedIdCluster(response);
            setResponseStatusHistoricEvents({
                loading: false,
                error: undefined,
                hasError: false,
                void: !response?.elements || response?.elements?.length === 0,
                success: true,
            });

            setResponseStatusGraphs({
                loading: false,
                error: undefined,
                hasError: false,
                void: !response?.elements || response?.elements?.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatusHistoricEvents({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });

            setResponseStatusGraphs({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });

            return null;
        }
    }, []);

    const data: DriversContextProps = useMemo(
        () => ({
            drivers,
            driverIdCluster,
            driversPaged,
            driverPagedIdCluster,
            handleRequestList,
            handleGetDrivers,
            handleGetDriverIdCluster,
            handleGetDriversList,
            handleGetDriverListIdCluster,
            requestData,
            responseStatus,
            responseStatusHistoricEvents,
            responseStatusGraphs,
            handleGetDriverByImage,
            driverByImage,
            file,
            setFile,
            responseStatusByImage,
            returnMessage, 
            setReturnMessage,
            filter, 
            setFilter,
            operations,
            setOperations,
            driverHistoric,
            handleGetDriverHistoric,
        }),
        [
            driverIdCluster, 
            driverPagedIdCluster, 
            drivers, 
            driversPaged, 
            handleGetDriverIdCluster, 
            handleGetDriverListIdCluster, 
            handleGetDrivers, 
            handleGetDriversList, 
            handleRequestList, 
            requestData, 
            responseStatus, 
            responseStatusGraphs, 
            responseStatusHistoricEvents,
            handleGetDriverByImage,
            driverByImage,
            file,
            setFile,
            responseStatusByImage,
            returnMessage, 
            setReturnMessage,
            filter, 
            setFilter,
            operations,
            setOperations,
            driverHistoric,
            handleGetDriverHistoric,
        ],
    );

    return <DriversContext.Provider value={data}>{children}</DriversContext.Provider>;
}
