export enum MFA {
    'SMS' = 'SMS',
    'TOTP' = 'TOTP',
}

export interface SetupMFAFormDTO {
    code: string;
    mfaType: MFA;
    phone: string;
}
