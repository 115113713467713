import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Divider, Row } from 'src/common/components';
import { RealTimeLocation } from 'src/modules/vehicle-monitoring/domain/entities/real-time-location';
import { useRealTimeLocation } from 'src/modules/vehicle-monitoring/hooks';
import { authInPArgus } from 'src/modules/vehicle-monitoring/services/authenticate-in-p-argus.service';
import { getLastOnlineData } from 'src/modules/vehicle-monitoring/services/get-last-online-data.service';
import { getLatestEvents } from 'src/modules/vehicle-monitoring/services/get-latest-events.service';
import Cookies from 'universal-cookie';

import CloseRound from '../../close-round';
import TabContent from '../content';
import Tabs from '../tabs';
import { Card, CardHeader, StyledModal, Subtitle, Title } from './styles';

interface ModalProps {
    onClose(): void;
    open: boolean;
    realTimeLocation: RealTimeLocation;
}

export default function RealTimeModal({ onClose, open, realTimeLocation }: ModalProps) {
    const cookies = new Cookies();
    const [token, setToken] = useState<string>(() => cookies.get('p-argus-token'));
    const { setLatestEvents, setLastOnlineData } = useRealTimeLocation();
    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState<number>(0);

    const subtitle = useMemo<string | null>(() => {
        switch (selectedTab) {
            case 0:
                return t('monitoring.vehicle_info');
            // case 1:
            //     return t('monitoring.driver_info');
            case 2:
                return t('monitoring.last_events');
            default:
                return null;
        }
    }, [selectedTab, t]);

    useEffect(() => {
        const controller = new AbortController();

        setLatestEvents({
            type: 'loading',
        });

        getLatestEvents
            .execute({ fleet: realTimeLocation.licensePlate, controller, limit: 10 })
            .then(data => {
                setLatestEvents({
                    type: 'data',
                    payload: data.events,
                });
            })
            .catch(() => {
                setLatestEvents({
                    type: 'error',
                });
            });

        return () => {
            controller.abort();
            setLatestEvents({
                type: 'data',
                payload: [],
            });
        };
    }, [realTimeLocation.licensePlate, setLatestEvents]);

    useEffect(() => {
        const controller = new AbortController();

        setLastOnlineData({
            type: 'loading',
        });

        if (!token) {
            authInPArgus
                .execute(controller)
                .then(tk => setToken(tk))
                .catch(() => {
                    setLastOnlineData({
                        type: 'error',
                    });
                });
        }

        if (token) {
            getLastOnlineData
                .execute({
                    controller,
                    fleet: realTimeLocation.licensePlate,
                })
                .then(res => {
                    setLastOnlineData({
                        type: 'data',
                        payload: res,
                    });
                })
                .catch(() => {
                    setLastOnlineData({
                        type: 'error',
                    });
                });
        }

        return () => {
            controller.abort();
            setLastOnlineData({
                type: 'data',
                payload: null,
            });
        };
    }, [realTimeLocation.licensePlate, setLastOnlineData, token]);

    return (
        <StyledModal open={open} onClose={onClose}>
            <Card style={{ maxHeight: 650 }}>
                <CardHeader>
                    <Row gap={48} align="flex-start" justify="space-between" flex width="100%">
                        <Title>{realTimeLocation.licensePlate}</Title>
                        <CloseRound onClose={onClose} />
                    </Row>
                    <Divider />
                </CardHeader>
                <Column align="flex-start" gap={16} flex width="100%" style={{ overflow: 'auto' }}>
                    <Column align="flex-start" gap={16}>
                        <Subtitle>{subtitle}</Subtitle>
                        <Tabs value={selectedTab} onChange={setSelectedTab} />
                        <TabContent value={selectedTab} realTimeLocation={realTimeLocation} />
                    </Column>
                    <Divider />
                </Column>
            </Card>
        </StyledModal>
    );
}
