import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Divider, EditFill, Pagination, Row, Table, TableOptionButton, Trash } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { usePermission } from 'src/common/hooks';

import { DepartmentsContext } from '../../../contexts';
import { CreateDptoContactDTO } from '../../../dtos/create-dpto-contact.dto';

interface ContactsTableProps {
    onEdit: (data: CreateDptoContactDTO) => void;
}

export default function ContactsTable({ onEdit }: ContactsTableProps) {
    const { contacts, setContacts } = useContext(DepartmentsContext);
    const [currentPage, setCurrentPage] = useState(1);

    const { isAdmin } = usePermission();
    const { t } = useTranslation();

    return (
        <Column gap={8}>
            <Divider />
            <Table tableHeaders={[t('configurations.contact'), t('configurations.phone_number'), <Fragment key="options" />]}>
                {contacts.slice(5 * (currentPage - 1), 5 * currentPage).map(contact => {
                    return (
                        <TableRow className="body" key={contact.id}>
                            <TableData>{contact.contactName}</TableData>
                            <TableData>{contact.phone}</TableData>
                            <TableData style={{ width: '68px', paddingRight: '16px' }}>
                                {isAdmin && (
                                    <Row gap={16}>
                                        <TableOptionButton type="button" onClick={() => onEdit(contact)}>
                                            <EditFill color="#7B7B7B"/>
                                        </TableOptionButton>
                                        <TableOptionButton
                                            type="button"
                                            onClick={() => {
                                                setContacts(state => state.filter(c => c.id !== contact.id));
                                                setCurrentPage(1);
                                            }}
                                        >
                                            <Trash />
                                        </TableOptionButton>
                                    </Row>
                                )}
                            </TableData>
                        </TableRow>
                    );
                })}
            </Table>
            {contacts.length > 0 ? (
                <Pagination currentPage={currentPage} total={contacts.length} perPage={5} onPageChange={setCurrentPage} mode="small" />
            ) : null}
        </Column>
    );
}
