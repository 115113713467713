import { Service } from 'src/common/core/service';
import { api } from 'src/common/infra/http';
import { SortMultipleAlarmsRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';

class SortAlarmsService implements Service<SortMultipleAlarmsRequestDTO, void> {
    public async execute(data: SortMultipleAlarmsRequestDTO): Promise<void> {
        await api.put(`/alarms-processing/sort-multiple-alarms/`, data);
    }
}

export const sortAlarm = new SortAlarmsService();
