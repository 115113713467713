import styled from 'styled-components';

export const Message = styled.p`
    font-family: 'Open Sans', sans-serif;
    color: #4d4d4d;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    cursor: default;
    text-align: center;
    max-width: 302px;
`;

export const Title = styled.strong`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
`;
