import { SVGProps } from 'react';

function UserAddAlt(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={9.5} cy={8.5} r={3.5} stroke="#fff" strokeWidth={2} strokeLinecap="round" />
            <path d="M14.813 20.076a5.5 5.5 0 00-10.626 0M18 10v6M21 13h-6" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
        </svg>
    );
}

export default UserAddAlt;
