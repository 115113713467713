import { yup } from 'src/common/infra';
import { InferType } from 'yup';

import { SOCKET_URL } from './consts';

const envSchema = yup.object().shape({
    ARGUS_API_URL: yup.string().required(),
    GOOGLE_MAPS_API_KEY: yup.string().required(),
    NODE_ENV: yup.string().oneOf(['local', 'develop', 'argus', 'alfa', 'beta', 'production']).default('local'),
    WEB_SOCKET_URL: yup.string().required(),
});

const _env = envSchema.validateSync({
    ARGUS_API_URL: 'https://p.arguss.ai',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDLqEyOA4RU0eM_vpVQhOUAtgoiqJcL1a0',
    NODE_ENV: process.env.REACT_APP_ENV,
    WEB_SOCKET_URL: SOCKET_URL,
});

type Env = InferType<typeof envSchema>;

export const env: Env = _env;
