import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row, Skeleton, Title, UsedFilters } from 'src/common/components';
import { useSearch } from 'src/common/hooks';
import { UpdateData } from 'src/modules/alarm-processing/components';
import { Content, Map } from 'src/modules/vehicle-monitoring/components';
import EventsMarker from 'src/modules/vehicle-monitoring/components/events-marks';
import HistoricalRouteContainer from 'src/modules/vehicle-monitoring/components/historical-route-container';
import RouteLine from 'src/modules/vehicle-monitoring/components/route-line';
import { INIT_FILTER_STATE } from 'src/modules/vehicle-monitoring/contexts/historical-route';
import { GetHistoricalRouteDTO } from 'src/modules/vehicle-monitoring/dtos/get-historical-route.dto';
import { useGetHistoricalRoute, useHistoricalRoute } from 'src/modules/vehicle-monitoring/hooks';

export default function HistoricRoute() {
    const { t } = useTranslation();

    const { mutate, isLoading, isError, data } = useGetHistoricalRoute();
    const { filter, setFilter, setFilterIsOpen } = useHistoricalRoute();
    const { setIsVisible } = useSearch();

    const handleClearFilterValue = useCallback(
        (key: keyof GetHistoricalRouteDTO) => {
            setFilter(state => ({
                ...state,
                [key]: INIT_FILTER_STATE[key],
            }));
            setFilterIsOpen(false);
        },
        [setFilter, setFilterIsOpen],
    );

    const handleClearFilters = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(false);
    }, [setFilter, setFilterIsOpen]);

    const handleFilter = useCallback(() => {
        if (filter.finalDate && filter.fleet && filter.initialDate) {
            mutate(filter);
        }
    }, [filter, mutate]);

    const content = useMemo(() => {
        if (!filter.fleet) {
            return <Content title={t('general.no_vehicle_selected')} message={t('general.select_filters_to_view_the_map')} />;
        }

        if (isLoading) {
            return (
                <Column padding="0 16px 16px" gap={8}>
                    {[1, 2, 3, 4, 5].map(value => (
                        <Skeleton key={value} />
                    ))}
                </Column>
            );
        }

        if (isError) {
            return <Content title={t('general.error')} message={t('general.error_loading_data')} />;
        }

        if (!data?.events.length && !data?.historicalRoute.length) {
            return (
                <Content
                    title={t('general.no_vehicle')}
                    message={t('general.no_filter_vehicle')}
                    action={{
                        onClick: handleClearFilters,
                        text: t('general.clear_filters'),
                    }}
                />
            );
        }

        return (
            <Map
                center={{
                    latitude: data?.historicalRoute?.[0]?.latitude || data?.events?.[0]?.latitude,
                    longitude: data?.historicalRoute?.[0]?.longitude || data?.events?.[0]?.longitude,
                }}
            >
                {data?.events.length && <EventsMarker events={data?.events} />}
                {data?.historicalRoute.length && <RouteLine historicalRoute={data?.historicalRoute} />}
            </Map>
        );
    }, [data?.events, data?.historicalRoute, filter.fleet, handleClearFilters, isError, isLoading, t]);

    const usedFiltersComponent = useMemo(
        () =>
            !isLoading && (
                <UsedFilters
                    data={{
                        operation: filter?.operation,
                        fleet: filter.fleet,
                        initialDate: filter.initialDate,
                        finalDate: filter.finalDate,
                        startTime: filter?.startTime,
                        endTime: filter?.endTime,
                    }}
                    onClear={handleClearFilterValue}
                    onClearAll={handleClearFilters}
                />
            ),
        [filter, handleClearFilterValue, handleClearFilters, isLoading],
    );

    useEffect(handleFilter, [handleFilter]);

    useEffect(() => {
        setIsVisible(false);

        return () => setIsVisible(true);
    }, [setIsVisible]);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('menu.vehicles_monitoring')}</h2>
                    <UpdateData onClick={handleFilter} />
                </Title>
            </Row>
            <HistoricalRouteContainer>
                {usedFiltersComponent}
                {content}
            </HistoricalRouteContainer>
        </Column>
    );
}
