import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    CardHeader,
    ChieldCheckDuotoneLine,
    Column,
    Content,
    DeleteButton,
    DeleteModal,
    Divider,
    MainCard,
    NoContent,
    Pagination,
    RenderIf,
    Row,
    SelectNumber,
    Skeleton,
    Title,
} from 'src/common/components';
import { toast } from 'src/common/components/toast';
import { useEventListener, usePermission, useSearch } from 'src/common/hooks';
import {
    CreateOrUpdatePasswordPolicyDialog,
    PasswordPoliciesTableList,
    PasswordPolicy,
    passwordPolicyService,
    usePasswordPolicies,
} from 'src/modules/configurations/password-policies';
import { getDefaultCreateOrUpdateValues } from 'src/modules/configurations/password-policies/contexts/default-form-values';

interface IsOpenProps {
    createOrUpdateDialog: boolean;
    deleteDialog: boolean;
    deleteInBulkDiloag: boolean;
}

const IS_OPEN_INITIAL_STATE: IsOpenProps = {
    createOrUpdateDialog: false,
    deleteDialog: false,
    deleteInBulkDiloag: false,
};

export default function PasswordPolicies() {
    const [isOpen, setIsOpen] = useState<IsOpenProps>(IS_OPEN_INITIAL_STATE);
    const [passwordPolicyToDelete, setPasswordPolicyToDelete] = useState<PasswordPolicy | null>(null);

    const { filters, setFilters, setPasswordPolicies, selectedValues, handleSelectAll, setInitialFormData } = usePasswordPolicies();
    const { isAdmin } = usePermission();
    const { formRef, clear, inputRef } = useSearch();
    const { t, i18n } = useTranslation();

    const onError = (error: unknown) => {
        if (error instanceof AxiosError) {
            return toast.error(
                t('general.message.error.title'),
                i18n.exists(error.response?.data?.message) ? t(error.response?.data?.message) : t('general.message.error.message'),
            );
        }
        toast.error(t('general.message.error.title'), t('general.message.error.message'));
    };

    const { mutate, isLoading, isError, data } = useMutation({
        mutationFn: passwordPolicyService.listPolicies,
        mutationKey: ['list-password-policies'],
        onError,
        onSuccess(data) {
            setPasswordPolicies(data);
        },
    });

    const onDelete = useCallback(() => {
        setIsOpen(IS_OPEN_INITIAL_STATE);
        setPasswordPolicyToDelete(null);
        handleSelectAll([]);
        toast.success(t('general.success'), '');
        setFilters(state => ({ ...state, currentPage: 1 }));
    }, [handleSelectAll, setFilters, t]);

    const { mutate: mutateDelete, isLoading: isDeleting } = useMutation({
        mutationFn: passwordPolicyService.delete,
        mutationKey: ['delete-password-policies'],
        onSuccess: onDelete,
        onError,
    });
    const { mutate: mutateDeleteInBulk, isLoading: isDeletingInBulk } = useMutation({
        mutationFn: passwordPolicyService.deleteInBulk,
        mutationKey: ['delete-password-policies-in-bulk'],
        onSuccess: onDelete,
        onError,
    });

    const controller = useMemo(() => new AbortController(), []);

    const handleDelete = useCallback(() => {
        if (passwordPolicyToDelete) {
            mutateDelete({
                id: passwordPolicyToDelete.id,
                signal: controller.signal,
            });
        }
    }, [controller.signal, mutateDelete, passwordPolicyToDelete]);

    const handleDeleteInBulk = useCallback(() => {
        if (selectedValues.length) {
            mutateDeleteInBulk({
                signal: controller.signal,
                ids: selectedValues.map(item => item.id),
            });
        }
    }, [controller.signal, mutateDeleteInBulk, selectedValues]);

    const handleList = useCallback(() => {
        mutate({
            ...filters,
            signal: controller.signal,
        });
    }, [controller, filters, mutate]);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();
            setIsOpen(IS_OPEN_INITIAL_STATE);
            handleSelectAll([]);
            setFilters(state => ({ ...state, currentPage: 1, text: inputRef?.current?.value }));
        },
        [handleSelectAll, inputRef, setFilters],
    );

    const content: JSX.Element = useMemo(() => {
        if (isLoading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        }

        if (isError) {
            return <NoContent message={t('general.error')} messageClassName="error" onClick={handleList} />;
        }

        if (!data?.elements?.length) {
            return <NoContent message={t('general.no_content')} onClick={handleList} />;
        }

        return (
            <PasswordPoliciesTableList
                data={data}
                onEdit={policy => {
                    setInitialFormData(getDefaultCreateOrUpdateValues(policy));
                    setIsOpen(state => ({ ...state, createOrUpdateDialog: !state.createOrUpdateDialog }));
                }}
                onDelete={policy => {
                    setPasswordPolicyToDelete(policy);
                    setIsOpen(state => ({ ...state, deleteDialog: !state.deleteDialog }));
                }}
            />
        );
    }, [data, handleList, isError, isLoading, setInitialFormData, t]);

    useEffect(handleList, [handleList]);

    useEffect(() => {
        return () => {
            controller.abort();
            clear();
        };
    }, [clear, controller]);

    useEventListener(formRef.current, 'submit', handleSearch);

    return (
        <Column gap={16}>
            <Row gap={16} align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('general.configurations')}</h2>
                </Title>
                <RenderIf condition={isAdmin}>
                    <Row gap={16}>
                        <Button
                            type="button"
                            leftIcon={<ChieldCheckDuotoneLine color="#fff" />}
                            size="medium"
                            padding="0 12px"
                            onClick={() => setIsOpen(state => ({ ...state, createOrUpdateDialog: !state.createOrUpdateDialog }))}
                        >
                            {t('configurations.create_password_policy')}
                        </Button>
                    </Row>
                </RenderIf>
            </Row>
            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>{t('configurations.password_policies')}</h3>
                        </Row>
                        <Row align="center" gap={16}>
                            <Row align="center" gap={8}>
                                <span className="per-page">{t('general.items_per_page')}:</span>
                                <SelectNumber
                                    fontMode="small"
                                    onChange={e => setFilters(state => ({ ...state, pageSize: Number(e.target.value), currentPage: 1 }))}
                                    options={[15, 25, 50]}
                                    value={filters.pageSize}
                                />
                            </Row>
                            <RenderIf condition={isAdmin && !!selectedValues?.length}>
                                <DeleteButton onClick={() => setIsOpen(state => ({ ...state, deleteInBulkDiloag: !state.deleteInBulkDiloag }))} />
                            </RenderIf>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {content}
                    <Divider />
                    <RenderIf condition={!!data?.totalElements}>
                        <Pagination
                            currentPage={filters.currentPage}
                            onPageChange={currentPage => setFilters(state => ({ ...state, currentPage }))}
                            perPage={filters.pageSize}
                            total={data?.totalElements}
                        />
                    </RenderIf>
                </Column>
            </MainCard>
            <RenderIf condition={isOpen.createOrUpdateDialog}>
                <CreateOrUpdatePasswordPolicyDialog
                    open={isOpen.createOrUpdateDialog}
                    onClose={() => {
                        setIsOpen(IS_OPEN_INITIAL_STATE);
                        setInitialFormData(getDefaultCreateOrUpdateValues(null));
                    }}
                />
            </RenderIf>

            <RenderIf condition={isOpen.deleteDialog}>
                <DeleteModal
                    open={isOpen.deleteDialog}
                    onClose={() => {
                        setIsOpen(IS_OPEN_INITIAL_STATE);
                    }}
                    onConfirm={handleDelete}
                    title={t('configurations.delete_policy')}
                    message={
                        <>
                            {t('configurations.do_you_want_to_delete_the_password_policy')} <b>{JSON.stringify(passwordPolicyToDelete?.name)}</b>?
                            <br />
                            {t('configurations.this_action')}
                        </>
                    }
                    isLoading={isDeleting}
                />
            </RenderIf>

            <RenderIf condition={isOpen.deleteInBulkDiloag}>
                <DeleteModal
                    open={isOpen.deleteInBulkDiloag}
                    onClose={() => {
                        setIsOpen(IS_OPEN_INITIAL_STATE);
                    }}
                    onConfirm={handleDeleteInBulk}
                    title={t('configurations.delete_policy')}
                    message={
                        <>
                            {t('configurations.do_you_want_to_delete_the_password_policy')}? <br />
                            {t('configurations.this_action')}
                        </>
                    }
                    isLoading={isDeletingInBulk}
                />
            </RenderIf>
        </Column>
    );
}
