import moment from 'moment';
import { api } from 'src/common';

import { Service } from '../../../common';
import { SummaryRequest } from './home.dto';

export class GetSummary implements Service<any, void> {
    public async execute({ dateFrom, dateTo }: SummaryRequest): Promise<any> {
        return await api.get('/home/summary', {
            params: {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
            },
        });
    }
}

export const getSummary = new GetSummary();
