import { createContext, useCallback, useMemo, useRef, useState } from 'react';

import { FunctionComponent, ResponseStatus } from '../../../../common';
import { Pagination } from '../../../../common/core/pagination';
import { RegistrationDTO, ResgistrationShipmentsDTO } from '../dtos/registration-shipments.dtos';
import { registrationShipmentsService } from '../services/registration-shipments.service';
import { RegistrationShipmentsContextProps } from './types';

export const RegistrationShipmentsContext = createContext({} as RegistrationShipmentsContextProps);

export function RegistrationShipmentsProvider({ children }: FunctionComponent) {
    const registrationShipmentsDialogRef = useRef<HTMLDialogElement>(null);

    const registrationShipmentsDeleteDialogRef = useRef<HTMLDialogElement>(null);

    const [requestData, setRequestData] = useState<ResgistrationShipmentsDTO>({
        currentPage: 1,
        pageSize: 15,
    });

    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: true,
        success: false,
        void: false,
    });

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [registrations, setRegistrations] = useState<Pagination<RegistrationDTO> | null>(null);

    const handleRequestList = useCallback((data: Partial<ResgistrationShipmentsDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleGetList = useCallback(async () => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        // const elements: Array<RegistrationDTO> = [
        //     {
        //         operation: 'ABI COLOMBIA_BARRANQUILLA_TGN',
        //         email: 'adriana.salasm@ab-inbev.com',
        //         shippingType: 'PARA',
        //         channel: 'Intervenção',
        //         priority: null,
        //         telephone: null,
        //     },
        // ];

        try {
            const data = await registrationShipmentsService.listRegistrationShipments(requestData);

            setRegistrations(data);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: !data?.elements || data?.elements?.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const handleOpenDialog = useCallback((data?: RegistrationDTO | null) => {
        console.log(data);

        setDialogIsOpen(state => {
            if (state) {
                registrationShipmentsDialogRef?.current?.close();
            } else {
                registrationShipmentsDialogRef?.current?.showModal();
            }

            return !state;
        });
    }, []);

    const handleOpenDeleteDialog = useCallback((data?: RegistrationDTO) => {
        console.log(data);

        setDeleteDialogIsOpen(state => {
            if (state) {
                registrationShipmentsDeleteDialogRef?.current?.close();
            } else {
                registrationShipmentsDeleteDialogRef?.current?.showModal();
            }

            return !state;
        });
    }, []);

    const data: RegistrationShipmentsContextProps = useMemo(
        () => ({
            registrations,
            handleRequestList,
            handleGetList,
            requestData,
            responseStatus,
            dialogIsOpen,
            handleOpenDialog,
            registrationShipmentsDialogRef,
            registrationShipmentsDeleteDialogRef,
            handleOpenDeleteDialog,
            deleteDialogIsOpen,
        }),
        [
            registrations,
            handleRequestList,
            handleGetList,
            requestData,
            responseStatus,
            dialogIsOpen,
            handleOpenDialog,
            registrationShipmentsDeleteDialogRef,
            handleOpenDeleteDialog,
            deleteDialogIsOpen,
        ],
    );

    return <RegistrationShipmentsContext.Provider value={data}>{children}</RegistrationShipmentsContext.Provider>;
}
