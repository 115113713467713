import styled from 'styled-components';

export const UpdateButton = styled.button`
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-decoration-line: underline;

    color: #6da4d3;
    padding: 0;
    margin: 0;

    background-color: transparent;
    border: 0;
    cursor: pointer;
`;

export const Message = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #5f5f5f;
    margin: 0;

    &.error {
        color: #ef1d00;
    }
`;

export const Title = styled.h4`
    font-weight: 400;
    font-size: 26px;
    line-height: 24px;

    color: #7a7f85;
    margin: 0;
`;
