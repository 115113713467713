/* eslint-disable @typescript-eslint/ban-ts-comment */
export class ObjectHelper {
    public static deleteNullProps<T extends Record<string, any>>(object: T): Partial<T> {
        const newObject = object;

        Object.keys(newObject)?.forEach(key => {
            if (Array.isArray(newObject[key])) {
                if (Array.from(newObject[key] as any)?.length === 0) delete newObject[key];
                return;
            }

            if (newObject[key] instanceof Date) {
                // @ts-ignore
                newObject[key] = newObject[key].toISOString();
                return;
            }

            if (!newObject[key]) {
                delete newObject[key];
                return;
            }

            try {
                // @ts-ignore
                newObject[key] = JSON.parse(newObject[key]);
            } catch {
                // @ts-ignore
                newObject[key] = JSON.parse(JSON.stringify(newObject[key]));
            }
        });

        return newObject;
    }

    public static isEmpty<T extends Record<string, any>>(object: T): boolean {
        const newObject = this.deleteNullProps(object);
        return Object.keys(newObject).length === 0;
    }
}
