import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { FunctionComponent, ResponseStatus } from 'src/common';
import { Pagination } from 'src/common/core/pagination';

import { CreateFunctionDTO, Functionality, ListFunctionsDTO } from '../dtos';
import { functionService } from '../services';
import { defaultValues } from './default-form-values';
import { FunctionalitiesContextProps } from './types';

export const FunctionalitiesContext = createContext({} as FunctionalitiesContextProps);

export default function FunctionalitiesProvider({ children }: FunctionComponent) {
    const deleteDialogRef = useRef<HTMLDialogElement>(null);
    const deleteInBulkDialogRef = useRef<HTMLDialogElement>(null);
    const functionDialogRef = useRef<HTMLDialogElement>(null);

    const [requestData, setRequestData] = useState<ListFunctionsDTO>({
        currentPage: 1,
        pageSize: 15,
        active: true,
    });
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
    });

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [deletableFunction, setDeletableFunction] = useState<Functionality | null>(null);
    const [editableFunctionId, setEditableFunctionId] = useState<string | null>(null);
    const [initialFormData, setInitialFormData] = useState<CreateFunctionDTO>(defaultValues);
    const [functions, setFunctions] = useState<Pagination<Functionality> | null>(null);
    const [selectedFunctions, setSelectedFunctions] = useState<Functionality[]>([]);

    const allOnThisPageAreSelected: boolean = useMemo(
        () =>
            functions?.elements && functions?.elements?.length > 0
                ? functions.elements?.every(value => selectedFunctions.findIndex(dt => value && dt.id === value.id) !== -1)
                : false,
        [selectedFunctions, functions?.elements],
    );

    const handleGetList = useCallback(async () => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        functionService
            .listFunctions(requestData)
            .then(data => {
                setFunctions(data);
                setResponseStatus({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: !data?.elements || data?.elements?.length === 0,
                    success: true,
                });
            })
            .catch(reason =>
                setResponseStatus({
                    loading: false,
                    error: reason,
                    hasError: true,
                    void: false,
                    success: false,
                }),
            );
    }, [requestData]);

    const handleOpenDialog = useCallback((data?: Functionality | null) => {
        const initialData: CreateFunctionDTO = data
            ? {
                  name: data.name,
                  roles: [],
                  observation: data.observation || '',
              }
            : {
                  name: '',
                  roles: [],
                  observation: '',
              };

        setDialogIsOpen(state => {
            if (state) {
                functionDialogRef?.current?.close();
                setEditableFunctionId(null);
                setInitialFormData(defaultValues);
            } else {
                setEditableFunctionId(data?.id || null);
                setInitialFormData(initialData);
                functionDialogRef?.current?.showModal();
            }

            return !state;
        });
    }, []);

    const handleRequestList = useCallback((data: Partial<ListFunctionsDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleSelectAll = useCallback((data: Array<Functionality>) => {
        setSelectedFunctions(state => {
            const all = state.length > 0 && data?.every(value => state.findIndex(dt => dt.id === value.id) !== -1);

            if (all) {
                return [];
            }

            return [...state, ...data].filter((elem, index, self) => index === self.indexOf(elem));
        });
    }, []);

    const handleSelect = useCallback((data: Functionality) => {
        setSelectedFunctions(state => {
            const index = state.findIndex(event => event.id === data.id);

            if (index === -1) {
                return [...state, data];
            }

            return state.filter(event => event.id !== data.id);
        });
    }, []);

    const data: FunctionalitiesContextProps = useMemo(
        () => ({
            allOnThisPageAreSelected,
            deletableFunction,
            deleteDialogRef,
            deleteInBulkDialogRef,
            dialogIsOpen,
            editableFunctionId,
            functionDialogRef,
            functions,
            handleGetList,
            handleOpenDialog,
            handleRequestList,
            handleSelect,
            handleSelectAll,
            initialFormData,
            requestData,
            responseStatus,
            selectedFunctions,
            setDeletableFunction,
        }),
        [
            allOnThisPageAreSelected,
            deletableFunction,
            dialogIsOpen,
            editableFunctionId,
            functions,
            handleGetList,
            handleOpenDialog,
            handleRequestList,
            handleSelect,
            handleSelectAll,
            initialFormData,
            requestData,
            responseStatus,
            selectedFunctions,
        ],
    );

    return <FunctionalitiesContext.Provider value={data}>{children}</FunctionalitiesContext.Provider>;
}
