import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, AutocompleteMultiple, Button, Column } from 'src/common/components';
import { TypeSearch } from 'src/common/constants';
import { FeedbackProps } from 'src/common/types';
import { DepartmentResume, departmentService } from 'src/modules/configurations';
import { vehicleService } from 'src/modules/configurations/vehicles/services/vehicle.service';

import { Vehicle } from '../../domain/entities/vehicle';
import { GetRealTimeLocationDTO } from '../../dtos/get-real-time-location.dto';
import { useRealTimeLocation } from '../../hooks';
import { realTimeLocationSchema } from '../../infra/validations/yup/real-time-location.schema';
import { Container, Filter } from './styles';

export default function RealTimeLocationFilter() {
    const { filter, setFilter, operations, vehicles, setOperations, setVehicles, setCenter } = useRealTimeLocation();
    const { t } = useTranslation();

    const {
        setValue,
        register,
        handleSubmit: onSubmit,
        formState: { errors },
        watch,
    } = useForm<GetRealTimeLocationDTO>({
        defaultValues: filter,
        mode: 'onChange',
        resolver: yupResolver(realTimeLocationSchema),
    });

    const handleSubmit = useCallback(
        (data: Omit<GetRealTimeLocationDTO, 'status'>) => {
            setCenter(undefined);
            setFilter(state => ({ status: state.status, fleetInput: '', fleets: data.fleets, operation: data.operation }));
        },
        [setCenter, setFilter],
    );

    const feedback = (key: keyof GetRealTimeLocationDTO): FeedbackProps => {
        return {
            message: t(errors[key]?.message || '')?.toString(),
        };
    };
    const fleet = watch('fleetInput');
    const operation = watch('operation');

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 15,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations, setValue]);

    useEffect(() => {
        const controller = new AbortController();
        setVehicles({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            vehicleService
                .list({
                    currentPage: 1,
                    pageSize: 15,
                    fleet,
                    typeSearch: TypeSearch.PARAMS,
                    operation,
                    signal: controller.signal,
                })
                .then(res =>
                    setVehicles({
                        type: 'data',
                        payload: res.elements as Array<Vehicle>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [fleet, operation, setValue, setVehicles]);

    return (
        <Container align="flex-start">
            <Filter onSubmit={onSubmit(handleSubmit)}>
                <Autocomplete
                    showErrorOnMount
                    label={String(t('alarms.operation'))}
                    isLoading={operations.isLoading}
                    options={operations.data.map(operation => ({ ...operation, value: operation.name }))}
                    nextElement={{
                        id: 'fleet',
                    }}
                    feedback={feedback('operation')}
                    placeholder={String(t('alarms.operation'))}
                    setValue={setValue}
                    type="text"
                    {...register('operation')}
                />
                <AutocompleteMultiple
                    showErrorOnMount
                    autocompleteName="fleets"
                    id="fleet"
                    isLoading={vehicles.isLoading}
                    options={vehicles.data.map(vehicle => ({
                        id: vehicle.id,
                        value: vehicle.fleet,
                    }))}
                    nextElement={{
                        id: 'real-time-submit-button',
                    }}
                    feedback={{
                        message: t(errors.fleets?.message || '')
                            ?.toString()
                            ?.replace('%%', '10'),
                    }}
                    label={String(t('alarms.fleet'))}
                    placeholder={String(t('alarms.fleet'))}
                    setValue={setValue}
                    type="text"
                    values={watch('fleets', filter.fleets)}
                    tooltip={t('field_validations.max')?.toString()?.replace('%%', '10')}
                    {...register('fleetInput')}
                    disabled={watch('fleets')?.length >= 10}
                />
                <Column justify="flex-end" height="60px">
                    <Column justify="flex-end" height="36px">
                        <Button type="submit" size="small" id="real-time-submit-button">
                            {t('general.apply')}
                        </Button>
                    </Column>
                </Column>
            </Filter>
        </Container>
    );
}
