import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../button';
import { CancelButton } from '../cancel-button';
import Column from '../column';
import Divider from '../divider';
import { Modal } from '../modal';
import Row from '../row';
import { Card, Title } from './styles';

interface DeleteModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    message: ReactElement;
    isLoading: boolean;
}

export default function DeleteModal({ onClose, onConfirm, open, title, message, isLoading }: DeleteModalProps) {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose}>
            <Card>
                <Row justify="center" width="100%" align="start">
                    <Title>{title}</Title>
                </Row>
                <Column align="center" gap={16} flex width="100%">
                    <Row align="center" flex width="100%">
                        <p>{message}</p>
                    </Row>
                    <Divider />
                </Column>
                <Row align="flex-end" gap={16} flex width="100%">
                    <CancelButton onClick={onClose}>{t('general.cancel')}</CancelButton>
                    <Button type="reset" color="alert" onClick={onConfirm} isLoading={isLoading}>
                        {t('general.confirm')}
                    </Button>
                </Row>
            </Card>
        </Modal>
    );
}
