import { useJsApiLoader } from '@react-google-maps/api';
import { useMemo } from 'react';
import { FunctionComponent } from 'src/common/types';
import { env } from 'src/config/env';

import { MapContext } from './context';
import { MapContextProps } from './types';

export default function MapProvider({ children }: FunctionComponent) {
    const libraries = useMemo<string[]>(() => ['drawing', 'visualization'], []);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: env.GOOGLE_MAPS_API_KEY,
        libraries: libraries as any,
    });

    const data = useMemo<MapContextProps>(() => ({ isLoaded, loadError }), [isLoaded, loadError]);

    return <MapContext.Provider value={data}>{children}</MapContext.Provider>;
}
