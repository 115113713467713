import { SVGProps } from 'react';

function TimeProgress(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.343 6.343A8 8 0 1012 4M12 12L6.667 6.667M12 4v2M20 12h-2M12 18v2M6 12H4"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>
    );
}

export { TimeProgress };
