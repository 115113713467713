import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { io } from 'socket.io-client';
import { CircularProgress, Column, RenderIf, Row, Title } from 'src/common/components';
import { useSearch } from 'src/common/hooks';
import { api } from 'src/common/infra';
import { refreshTokenService } from 'src/common/services/refresh-token.service';
import { ENV } from 'src/config/consts';
import { env } from 'src/config/env';
import { authService } from 'src/modules/auth/factories';
import {
    Content,
    Map,
    RealTimeLocationContainer,
    RealTimeLocationMapContainer,
    RealTimeLocationTable,
} from 'src/modules/vehicle-monitoring/components';
import RealTimeMarker from 'src/modules/vehicle-monitoring/components/real-time-marker';
import RealTimeModal from 'src/modules/vehicle-monitoring/components/real-time-marker/modal';
import { RealTimeLocation as RealTimeLocationModel } from 'src/modules/vehicle-monitoring/domain/entities/real-time-location';
import { useRealTimeLocation } from 'src/modules/vehicle-monitoring/hooks';
import Cookies from 'universal-cookie';

export default function RealTimeLocation() {
    const { t } = useTranslation();

    const { reset, filter, modalData, setModalData, center, setCenter } = useRealTimeLocation();
    const { setIsVisible } = useSearch();

    const [realTimeLocation, setRealTimeLocation] = useState<Array<RealTimeLocationModel>>([]);

    const cookies = useMemo(() => new Cookies(), []);

    const socket = useMemo(() => {
        const token = cookies.get('jose');

        if (!token) document.location.reload();

        return io(env.WEB_SOCKET_URL, {
            query: {
                token,
            },
        });
    }, [cookies]);

    const refreshToken = useCallback(
        async (err: Error) => {
            if (err.message === 'TOKEN_EXPIRED_ERROR') {
                authService.getTokens().then(({ jose }) => {
                    if (!jose) return authService.signOut();
                    const cookies = new Cookies();
                    refreshTokenService
                        .execute(jose)
                        .then(response => {
                            const { content } = response.data;
                            localStorage.setItem(`@session-${ENV}`, JSON.stringify(content));

                            cookies.set('jose', content.token, { path: '/', secure: true, sameSite: 'none' });

                            if (socket.io.opts.query) socket.io.opts.query.token = content.token;

                            api.defaults.headers.jose = content.token;
                        })
                        .catch(() => {
                            cookies.remove('jose');
                            authService.signOut();
                        });
                });
            }
        },
        [socket.io.opts.query],
    );

    useEffect(() => {
        setIsVisible(false);
        socket.on('connect_error', err => {
            refreshToken(err);
        });
        return () => {
            socket.removeAllListeners();
            reset();
            setIsVisible(true);
        };
    }, [refreshToken, reset, setIsVisible, socket]);

    useEffect(() => {
        if (filter.fleets.length) {
            socket.emit('real-time-location:read', filter);
            socket.on('real-time-location:update', (data: RealTimeLocationModel) => {
                if (filter.fleets.includes(data.licensePlate)) {
                    setRealTimeLocation(state => [data, ...(state?.filter(vehicle => vehicle.licensePlate !== data.licensePlate) || [])]);
                    if (filter.fleets.length === 1) {
                        setCenter(state => (data ? { ...data.coordinates, fleet: data.licensePlate } : state));
                    }
                }
            });
            socket.on('real-time-location:list', (data: Array<RealTimeLocationModel>) => {
                setCenter(state => (state ? state : data?.length ? { ...data[0].coordinates, fleet: data[0].licensePlate } : state));
                setRealTimeLocation(data);
            });
        }
    }, [filter, setCenter, socket]);

    const filteredRealTimeLocation = useMemo<RealTimeLocationModel[]>(
        () =>
            realTimeLocation
                ?.filter(location => (filter?.status === 'all' ? location : filter?.status === 'online' ? location?.online : !location?.online))
                ?.filter(location => filter?.fleets?.includes(location?.licensePlate)),
        [filter?.fleets, filter?.status, realTimeLocation],
    );

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between">
                <Row align="center" gap={16} justify="center">
                    <Title>
                        <h2>{t('menu.vehicles_monitoring')}</h2>
                    </Title>
                    <RenderIf condition={!!filter.fleets.length}>
                        <CircularProgress color="#6DA4D3" trackColor="#fff" />
                    </RenderIf>
                </Row>
            </Row>
            <RealTimeLocationMapContainer>
                <RenderIf condition={!!filter.fleets.length}>
                    <Map center={center}>
                        {filteredRealTimeLocation?.map(location => (
                            <RealTimeMarker key={location.id} {...location} />
                        ))}
                    </Map>
                </RenderIf>
                <RenderIf condition={!filter.fleets.length}>
                    <Content title={t('general.no_vehicle_selected')} message={t('general.select_filters_to_view_the_map')} />
                </RenderIf>
            </RealTimeLocationMapContainer>
            <RenderIf condition={!!filter.fleets.length && !!realTimeLocation.length}>
                <RealTimeLocationContainer>
                    <RealTimeLocationTable data={filteredRealTimeLocation} />
                </RealTimeLocationContainer>
            </RenderIf>
            <RenderIf condition={modalData.isOpen}>
                <RealTimeModal
                    onClose={() => setModalData({ isOpen: false, realTimeLocation: null })}
                    open={modalData.isOpen}
                    realTimeLocation={modalData.realTimeLocation as RealTimeLocationModel}
                />
            </RenderIf>
        </Column>
    );
}
