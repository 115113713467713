import { api } from "src/common";

class FilterByEventsName {
    public async execute() {
        const {
            data: { content },
        } = await api.get<any>('/interventions/events_name');

        return content;
    }
}

export const filterByEventName = new FilterByEventsName();
