import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const MuiTheme = createTheme({
    palette: {
        primary: {
            main: grey[900],
        },
        secondary: {
            main: '#E5E5E5',
        },
    },
    typography: {
        fontFamily: ['Space Grotesk', 'sans serif'].join(','),
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#262D3C',
                    fontFamily: 'Space Grotesk',
                },
                arrow: {
                    '::before': {
                        backgroundColor: '#262D3C',
                    },
                },
            },
        },
    },
});
