import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Column, Input } from 'src/common/components';
import { useSearch } from 'src/common/hooks';

import { ListDepartmentsDTO } from '../../dtos';
import { useOperations } from '../../hooks/use-operations';
import { Form } from './styles';

export default function Filters() {
    const { setRequest, handleSelectAll } = useOperations();
    const { clear } = useSearch();
    const { t } = useTranslation();
    const { register, handleSubmit: onSubmit } = useForm<Pick<ListDepartmentsDTO, 'name' | 'parentName'>>({});

    const handleSubmit = useCallback(
        (data: Pick<ListDepartmentsDTO, 'name' | 'parentName'>) => {
            clear();
            handleSelectAll([]);
            setRequest(state => ({
                ...state,
                name: data.name,
                parentName: data.parentName,
                currentPage: 1,
                text: '',
            }));
        },
        [clear, handleSelectAll, setRequest],
    );

    return (
        <Form onSubmit={onSubmit(handleSubmit)}>
            <Input
                autoComplete="off"
                label={String(t('configurations.organization_name'))}
                id="filter-name"
                placeholder={String(t('general.write'))}
                type="text"
                {...register('name')}
            />
            <Input
                autoComplete="off"
                label={String(t('configurations.institution_name'))}
                id="filter-parentName"
                placeholder={String(t('general.write'))}
                {...register('parentName')}
            />
            <Column justify="flex-end" height="60px">
                <Column justify="flex-end" height="36px">
                    <Button type="submit" size="small" width="144px">
                        {t('general.apply')}
                    </Button>
                </Column>
            </Column>
        </Form>
    );
}
