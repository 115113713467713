import { Column } from 'src/common/components';
import styled from 'styled-components';

export const Container = styled(Column)`
    padding: 16px;

    background: #f1f1f1;
    border-bottom: 1px solid #d9d9d9;
    margin-top: -16px;
`;

export const Filter = styled.form`
    display: grid;
    grid-template-columns: repeat(2, 1fr) 144px;
    gap: 1rem;
    width: 100%;
`;
