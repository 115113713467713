import { useLoader } from 'src/common/hooks';

import CircularProgress from '../circular-progress';

export default function DialogLoader(): JSX.Element {
    const { loaderRef } = useLoader();

    return (
        <dialog ref={loaderRef} style={{ background: 'transparent', border: '0' }}>
            <CircularProgress color="#262D3C" />
        </dialog>
    );
}
