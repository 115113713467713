import { ApiResponse, Service } from 'src/common/core';
import { api } from 'src/common/infra';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';

interface GetLatestEventsRequest {
    limit: number;
    fleet: string;
    controller: AbortController;
}

interface GetLatestEventsResponse {
    events: Array<EventDTO>;
}

export class GetLatestEventsService implements Service<GetLatestEventsRequest, GetLatestEventsResponse> {
    public async execute(request: GetLatestEventsRequest): Promise<GetLatestEventsResponse> {
        const { controller, fleet, limit } = request;

        const {
            data: { content },
        } = await api.get<ApiResponse<Array<EventDTO>>>(`vehicle-monitoring/real-time-location/latest-events/${fleet}/${limit}`, {
            signal: controller.signal,
        });

        return { events: content };
    }
}

export const getLatestEvents = new GetLatestEventsService();
