import { createContext, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'src/common/hooks';
import { FunctionComponent } from 'src/common/types';

import { Layout, ListLayoutContextProps, ListMediaSize } from './types';

export const ListLayoutContext = createContext<ListLayoutContextProps>({} as ListLayoutContextProps);

export default function ListLayoutProvider({ children }: FunctionComponent) {
    const [layout, setLayout] = useLocalStorage<Layout>('layoutType', Layout.LINE);
    const [media, setMedia] = useLocalStorage<ListMediaSize>('layoutMediaType', { heigth: 150, width: 205 });

    const handleMediaVisualization = useCallback(
        (media: ListMediaSize) => {
            setMedia(media);
        },
        [setMedia],
    );

    const handleChangeLayout = useCallback(
        (layout: Layout) => {
            setLayout(layout);
        },
        [setLayout],
    );

    const data = useMemo(() => {
        return { handleChangeLayout, layout, media, handleMediaVisualization };
    }, [handleChangeLayout, layout, media, handleMediaVisualization]);

    return <ListLayoutContext.Provider value={data}>{children}</ListLayoutContext.Provider>;
}
