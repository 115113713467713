import styled from 'styled-components';

export const GraphSection = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
    justify-content: center;
`;

export const DivRounded = styled.div`
    display: flex;
    width: 100%;
    height: 50vh;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    border-radius: 0.625rem;
    padding: 1.25rem;
    padding-bottom: 0rem;
    gap: 1rem;

    .filterDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filterButton {
        border: none;
        background-color: white;
        cursor: pointer;
    }

    .sliderInput {
        padding: 0.1rem 0.4rem 0.8rem;
    }

    .radioInput {
        display: flex;
        flex-direction: column;
        align-items: initial;
        padding: 0.4rem 0 0.6rem;
    }
`;

export const ChartContainer = styled.div`
    height: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
`;

export const ChartContainerLineChart = styled.div`
    height: 85%;
    padding: 1.25rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BaseText = styled.p`
    letter-spacing: 0em;
    text-align: left;
`;

export const SubTitle = styled(BaseText)`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-top: -20px;
    color: #b9b6b6;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const DefaultText = styled.span`
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: #262d3c;
`;

export const OptionsText = styled(DefaultText)`
    font-size: 1.625rem;
`;

export const RadioText = styled(DefaultText)`
    color: #000000;
    font-size: 0.75rem;
    font-weight: 600;
`;

export const FilterDayAndTimeContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0px 0px 9px 0px;
`;

export const FilterTimeRangeRadioContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const RadiosCardHeader = styled.div`
    display: flex;
    font-weight: 500;
    margin-right: 0.5rem;

    > label {
        margin-right: 0.5rem;
    }
`;

export const BarPolarFilter = styled.div`
    position: relative;
    margin-left: 1.5625rem;
`;

export const ScatterChartFilter = styled.div`
    display: flex;
    position: relative;
    margin-left: 1.5625rem;
`;
