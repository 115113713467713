import { SVGProps } from 'react';

interface EyeProps extends SVGProps<SVGSVGElement> {
    color: string;
}

function Eye({ color, ...props }: EyeProps) {
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={8} cy={8} r={1.25} stroke={color} strokeWidth={1.5} />
            <path
                d="M12.396 6.919c.442.447.663.67.663 1.081 0 .41-.221.634-.663 1.081-.961.974-2.572 2.252-4.396 2.252-1.824 0-3.435-1.278-4.396-2.252-.442-.447-.663-.67-.663-1.081 0-.41.221-.634.663-1.081C4.565 5.945 6.176 4.667 8 4.667c1.824 0 3.435 1.278 4.396 2.252z"
                stroke={color}
                strokeWidth={1.5}
            />
        </svg>
    );
}

export default Eye;
