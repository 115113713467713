import { object, string } from 'yup';

export const createNewPasswordSchema = object().shape({
    email: string().email('field_validations.email').required('field_validations.required'),
    oldPassword: string().required('field_validations.required'),
    newPassword: string()
        .required('field_validations.required')
        .test('must-not-be-equal-old-password', 'password.new_password_is_equal_to_old', (field, body) => {
            if (field === body.parent.oldPassword) return false;
            return true;
        }),
    newPasswordConfirmation: string()
        .required('field_validations.required')
        .test('is-equal', 'field_validations.passwords_match', (field, body) => field === body.parent.newPassword),
});
