import { Dispatch, SetStateAction } from 'react';

import { SessionDTO } from '../../dtos/auth.dto';
import { LoginChallengeProps } from '../../dtos/login-challenge.dto';

export const ConfirmationStatus = {
    ARCHIVED: 'ARCHIVED',
    COMPROMISED: 'COMPROMISED',
    CONFIRMED: 'CONFIRMED',
    FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
    RESET_REQUIRED: 'RESET_REQUIRED',
    UNCONFIRMED: 'UNCONFIRMED',
    UNKNOWN: 'UNKNOWN',
};

export type ConfirmationStatus = (typeof ConfirmationStatus)[keyof typeof ConfirmationStatus];

export enum UserStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export type User = {
    confirmationStatus: ConfirmationStatus;
    countryCallingCode: string | null;
    createdAt: Date;
    creator: string | null;
    creatorUuid: string | null;
    department: string | null;
    departmentId: string | null;
    email: string;
    emailVerified: boolean;
    enabled: boolean;
    function: string | null;
    functionId: string | null;
    id: number;
    isAdmin: boolean;
    name: string | null;
    organization: string | null;
    organizationId: string | null;
    passwordPolicy: string | null;
    passwordPolicyId: string | null;
    phoneNumber: string | null;
    status: string;
    updatedAt: Date;
    uuid: string;
    notificationSound?: boolean;
    profile?: string;
};

export type Costumer = {
    name: string;
    telemetry: string;
};

export interface AuthContextProps {
    challenge: LoginChallengeProps | null;
    initialize(): Promise<void>;
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    session: SessionDTO | null;
    setChallenge: Dispatch<SetStateAction<LoginChallengeProps | null>>;
    signOut(): void;
}

export interface UserProps {
    email: string;
    email_verified: boolean;
    name: string;
    sub: string;
}
