import { forwardRef, Fragment, ImgHTMLAttributes, KeyboardEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineExpand } from 'react-icons/ai';
import { CgMinimize } from 'react-icons/cg';
import brokenImg from 'src/assets/images/broken-image.svg';

import { Box, Dialog, ErrorMessage, ExpandButton, ExpandedBox, ExpandedImage, MinimizeButton, ResizableBox } from './styles';

interface ImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'onError'> {
    showErrorMessage?: boolean;
    resizable?: boolean;
}

const Image = forwardRef<HTMLImageElement, ImageProps>(function Base(props, ref) {
    const { t } = useTranslation();
    const [hasError, setHasError] = useState(false);
    const modalRef = useRef<HTMLDialogElement>(null);

    const { resizable, showErrorMessage, ...rest } = props;

    const handleMinimizeByKeyboard = useCallback((e: KeyboardEvent<HTMLDialogElement>) => {
        if (['Escape'].includes(e.key)) {
            modalRef?.current?.close();
        }
    }, []);

    const onError = useCallback((event: SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = brokenImg;
        event.currentTarget.srcset = brokenImg;
        event.currentTarget.alt = '';
        setHasError(true);
    }, []);

    useEffect(() => {
        if (!props?.src) {
            setHasError(true);
        }
    }, [props?.src]);

    const image = useMemo(
        () =>
            hasError ? (
                <Box>
                    <img {...rest} src={brokenImg} alt="" />
                    {showErrorMessage && <ErrorMessage>{t('general.information_unavailable')}</ErrorMessage>}
                </Box>
            ) : (
                <ResizableBox style={rest?.style}>
                    <img ref={ref} onError={onError} {...rest} />
                    {resizable && (
                        <ExpandButton
                            type="button"
                            className="expand-button"
                            onClick={e => {
                                e.stopPropagation();

                                modalRef?.current?.showModal();
                            }}
                        >
                            <AiOutlineExpand />
                        </ExpandButton>
                    )}
                </ResizableBox>
            ),
        [hasError, onError, ref, resizable, rest, showErrorMessage, t],
    );

    return (
        <Fragment>
            {image}

            <Dialog ref={modalRef} onKeyDown={handleMinimizeByKeyboard} aria-describedby="image-expanded">
                <ExpandedBox
                    onClick={e => {
                        e.stopPropagation();

                        modalRef?.current?.close();
                    }}
                >
                    <ExpandedImage {...rest} onClick={e => e.stopPropagation()} />
                    <MinimizeButton type="button" className="min-button">
                        <CgMinimize size={24} />
                    </MinimizeButton>
                </ExpandedBox>
            </Dialog>
        </Fragment>
    );
});

export default Image;

Image.defaultProps = {
    showErrorMessage: true,
    resizable: true,
};
