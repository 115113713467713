import { Modal, styled as mui } from '@mui/material';
import styled from 'styled-components';

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    place-content: end space-between;
    width: 100%;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    .underline {
        text-decoration: underline;
    }
`;

export const Form = styled.form`
    position: relative;
    overflow: auto;
    height: 100%;

    @media screen and (max-height: 659px) {
        .bottom {
            gap: 16px;

            &::after {
                content: '';
                height: 1px;
                width: 100%;
                position: absolute;
                bottom: 0;
            }
        }
    }
`;

export const StyledModal = mui(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .modal-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;

        color: #262d3c;
        margin: 0;
    }

    .card {
        transition: height 0.3s ease-in, width 0.2s ease-out;

        @media screen and (max-height: 659px) {
            height: 85vh;
        }
    }

`;
