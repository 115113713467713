import ReactEcharts from 'echarts-for-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface ScatterChartDataProps {
    dataY: string[];
    dataX: string[];
    data: any;
    worstTracksValue: number;
}

export function ScatterChart({ dataX, dataY, data, worstTracksValue }: ScatterChartDataProps) {
    const { t } = useTranslation();
    const arrayOrderly = data.sort((a: number[], b: number[]) => b[2] - a[2]);
    const maxWorstTrackValue = arrayOrderly.slice(0, worstTracksValue);

    const calculateSize = useMemo(() => {
        const maxValue = maxWorstTrackValue[0][2];
        if (maxValue < 10) {
            return 3;
        }
        else if (maxValue < 50) {
            return 1;
        } else if (maxValue < 100) {
            return 0.4;
        } else if (maxValue < 500) {
            return 0.2;
        } else if (maxValue < 1000) {
            return 0.04;
        } else if (maxValue < 2000) {
            return 0.01;
        }

        return 0.01;
    },[maxWorstTrackValue]);

    const option = {
        grid: {
            top: 0,
            left: '1%',
            bottom: 10,
            right: '1%',
            containLabel: true,
        },
        tooltip: {
            position: 'top',
            formatter: function (data: any) {
                return (
                    t('period.total_events') +
                    ': ' +
                    '<strong>' +
                    data.value[2] +
                    ' ' +
                    '</strong>' +
                    t('period.at') +
                    ' ' +
                    dataX[data.value[0]] +
                    'h'
                );
            },
        },
        xAxis: {
            type: 'category',
            data: dataX,
            boundaryGap: false,
            splitLine: {
                show: true,
            },
            axisLine: {
                show: false,
            },
        },
        yAxis: {
            type: 'category',
            data: dataY,
            axisLine: {
                show: false,
            },
        },
        series: [
            {
                type: 'scatter',
                symbol: 'circle',
                symbolSize: function (data: any) {
                    return Math.round(data[2] * calculateSize);
                },
                data: data,
            },
            {
                type: 'effectScatter',
                symbol: 'circle',
                itemStyle: {
                    color: 'yellow',
                },
                rippleEffect: {
                    brushType: 'stroke',
                    color: 'black',
                },
                symbolSize: function (maxValues: any) {
                    return Math.round(maxValues[2] * calculateSize);
                },
                data: maxWorstTrackValue,
            },
        ],
    };

    return <ReactEcharts notMerge={true} option={option} style={{ width: '100%', height: '100%' }} />;
}
