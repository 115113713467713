import styled from 'styled-components';

export const ChartContainer = styled.div`
    padding: 1rem 1rem 2.5rem;
    height: 50vh;
    border: 1px solid #d9d9d9;
    border-radius: 0.6rem;
    width: 49%;

    .filterDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filterButton {
        border: none;
        background-color: white;
        cursor: pointer;
    }

    .filters {
        position: relative;
        margin-left: 1.5625rem;
    }

    .radioInput {
        display: flex;
        flex-direction: column;
        align-items: initial;
        padding: 0.4rem 0 0.6rem;
    }

    .radioInputGraphs {
        display: flex;
        align-items: initial;
        margin: 0.5rem 0;
    }

    .heatMapInputs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        gap: 2rem;

        div {
            width: 100%;
        }
    }

    .lineChart {
        height: 400px;
        margin-top: -1.875rem;
    }
`;
