import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { usePermission } from 'src/common/hooks';

import { SidebarItemProps } from '../sidebar-item';
import { Container } from './styles';

export default function SubmenuItem({ id, shouldMatchExactHref, value, userHasAny, ...props }: SidebarItemProps) {
    const { pathname, search } = useLocation();
    const { hasAny } = usePermission();
    const { t } = useTranslation();
    const asPath = useMemo(() => pathname + search, [pathname, search]);

    const isActive: boolean = useMemo(() => {
        if ((shouldMatchExactHref && asPath === props.to) || (!shouldMatchExactHref && asPath.startsWith(String(props.to)))) {
            return true;
        }
        return false;
    }, [asPath, props.to, shouldMatchExactHref]);

    return useMemo(() => {
        if (userHasAny) {
            if (!hasAny(...userHasAny)) return null;
        }

        return (
            <Link id={id} {...props}>
                <Container isActive={isActive}>
                    <span>{t(value)}</span>
                </Container>
            </Link>
        );
    }, [hasAny, id, isActive, props, t, userHasAny, value]);
}
