import styled from 'styled-components';

export const DialogContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    h4 {
        color: #262d3c;
        margin: 0;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        color: #7b7b7b;
    }
`;
