export enum FeedbackType {
    WARN = 'WARN',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export interface FeedbackProps {
    message?: string;
    type?: FeedbackType;
}
