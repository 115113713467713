import { TFunction } from 'i18next';
import { PropsWithChildren, createContext, useCallback, useMemo, useReducer, useState } from 'react';
import { Pagination } from 'src/common/core/pagination';
import { LabelValue } from 'src/common/domain/entities';
import { CheckboxHelper } from 'src/common/helpers';
import { Reducer, reducer } from 'src/common/types/reducer';
import { Vehicle } from 'src/modules/vehicle-monitoring/domain/entities/vehicle';

import { DepartmentResume } from '../../departments/dtos';
import { CreateVehicleFormDTO, VehicleType } from '../dtos';
import { ListVehiclesDTO } from '../dtos/list-vehicles.dto';
import { INITIAL_DEPARTMENT_STATE, INITIAL_VEHICLE_TYPES_STATE } from './const';
import { defaultValues, getDefaultCreateOrUpdateValues } from './default-form-values';
import { VehicleContextProps } from './types';

export const VehiclesContext = createContext({} as VehicleContextProps);

export function VehiclesProvider({ children }: PropsWithChildren): JSX.Element {
    const [filter, setFilter] = useState<ListVehiclesDTO>(defaultValues);
    const [initialFormData, setInitialFormData] = useState<Partial<CreateVehicleFormDTO>>(() => getDefaultCreateOrUpdateValues(null));
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_DEPARTMENT_STATE);
    const [vehicles, setVehicles] = useState<Pagination<Vehicle> | null>(null);
    const [vehicleTypes, setVehicleTypes] = useReducer<Reducer<Array<VehicleType>>>(reducer, INITIAL_VEHICLE_TYPES_STATE);
    const [selectedVehicles, setSelectedVehicles] = useState<Vehicle[]>([]);

    const allOnThisPageAreSelected: boolean = useMemo(
        () => CheckboxHelper.allOnThisPageAreSelected(vehicles?.elements, selectedVehicles),
        [selectedVehicles, vehicles?.elements],
    );

    const getVehicleTypes = useCallback(
        (t: TFunction) =>
            vehicleTypes.data.map<LabelValue>(item => ({
                label: item.translationKey ? t(`configurations.vehicle_types.${item.translationKey}`) : item.type,
                value: item.type,
            })),
        [vehicleTypes.data],
    );

    const handleSelectAll = useCallback((data: Array<Vehicle>) => {
        setSelectedVehicles(state => {
            const all = state.length > 0 && data?.every(value => state.findIndex(dt => dt.id === value.id) !== -1);

            if (all) {
                return [];
            }

            return [...state, ...data].filter((elem, index, self) => index === self.indexOf(elem));
        });
    }, []);

    const handleSelect = useCallback((data: Vehicle) => {
        setSelectedVehicles(state => {
            const index = state.findIndex(event => event.id === data.id);

            if (index === -1) {
                return [...state, data];
            }

            return state.filter(event => event.id !== data.id);
        });
    }, []);

    const data: VehicleContextProps = useMemo<VehicleContextProps>(
        () => ({
            allOnThisPageAreSelected,
            filter,
            getVehicleTypes,
            handleSelect,
            handleSelectAll,
            initialFormData,
            operations,
            selectedVehicles,
            setFilter,
            setInitialFormData,
            setOperations,
            setVehicleTypes,
            setVehicles,
            vehicleTypes,
            vehicles,
        }),
        [allOnThisPageAreSelected, filter, getVehicleTypes, handleSelect, handleSelectAll, initialFormData, operations, selectedVehicles, vehicleTypes, vehicles],
    );

    return <VehiclesContext.Provider value={data}>{children}</VehiclesContext.Provider>;
}
