import { yup } from 'src/common/infra/validations';

export const realTimeLocationSchema = yup.object().shape({
    fleets: yup
        .array()
        .typeError('field_validations.required')
        .of(yup.string().required('field_validations.required'))
        .required('field_validations.required')
        .min(1, 'field_validations.required')
        .max(10, 'field_validations.max'),
    operation: yup.string(),
});
