import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutocompleteMultiple, Button, Column } from '../../../../../common';
import { DepartmentResume, departmentService } from '../../../../configurations';
import { GenericAnalyticsFilterDTO } from '../../../common/dtos/generic-filter.dto';
import { genericAnalyticsFilterSchema } from '../../../common/infra/validations/yup/generic-analytics-filter.schema';
import { useDrivers } from '../../hook';
import { Container, Itens } from './styles';

export function DriversFilter() {
    const { filter, setFilter, operations, setOperations, handleGetDriversList, handleGetDrivers } = useDrivers();
    const { t } = useTranslation();

    const [operationsFilter, setOperationsFilter] = useState<string[]>([]);

    useEffect(() => {
        handleGetDriversList({
            dateFrom: new Date(filter.initialDate.toISOString().split('T')[0]),
            dateTo: new Date(filter.finalDate.toISOString().split('T')[0]),
            pageSize: 15,
            start: 1,
            operations: operationsFilter.length === 0 ? undefined : operationsFilter,
        });

        handleGetDrivers({
            dateFrom: new Date(filter.initialDate.toISOString().split('T')[0]),
            dateTo: new Date(filter.finalDate.toISOString().split('T')[0]),
            operations: operationsFilter.length === 0 ? undefined : operationsFilter,
        });
    }, [operationsFilter, filter, handleGetDriversList, handleGetDrivers]);

    const {
        setValue,
        register,
        handleSubmit: onSubmit,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: filter,
        mode: 'onChange',
        resolver: yupResolver(genericAnalyticsFilterSchema),
    });

    const operation = watch('operationInput');

    const handleSubmit = useCallback(
        (data: GenericAnalyticsFilterDTO) => {
            setFilter({ ...data });
            setOperationsFilter(data.operations);
        },
        [setFilter, setOperationsFilter],
    );

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 30,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations, setValue]);

    return (
        <Container onSubmit={onSubmit(handleSubmit)}>
            <Itens />
            <Itens />
            <Itens>
                <AutocompleteMultiple
                    showErrorOnMount
                    autocompleteName="operations"
                    id="operation"
                    isLoading={operations.isLoading}
                    options={operations.data
                        .filter(operation => operation.type === 'Operação')
                        .map(operation => ({
                            id: operation.id,
                            value: operation.name,
                        }))}
                    nextElement={{
                        id: 'real-time-submit-button',
                    }}
                    feedback={{
                        message: t(errors.operations?.message || '')
                            ?.toString()
                            ?.replace('%%', '10'),
                    }}
                    label={String(t('general.operation'))}
                    placeholder={String(t('general.operation'))}
                    setValue={setValue}
                    type="text"
                    values={watch('operations', filter.operations)}
                    tooltip={t('field_validations.max')?.toString()?.replace('%%', '10')}
                    {...register('operationInput')}
                    disabled={watch('operations')?.length >= 10}
                />
            </Itens>

            <Itens>
                <Column>
                    <Column justify="flex-end" height="36px" style={{
                            marginTop: '23px'
                        }}>
                        <Button type="submit" size="small" id="real-time-submit-button">
                            {t('general.apply')}
                        </Button>
                    </Column>
                </Column>
            </Itens>
        </Container>
    );
}
