import styled, { css } from 'styled-components';

interface ContainerProps {
    isActive: boolean;
}

export const Container = styled.li<ContainerProps>`
    border-radius: 4px 0px 0px 4px;
    box-sizing: border-box;
    color: #d7d7d7;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    height: 40px;
    padding: 8px;
    position: relative;
    width: 100%;

    > span {
        flex: 1;
    }

    svg.circle-grid-cross-left-fill path {
        fill: #d7d7d7;
    }

    &:hover {
        background: #ffffff;
        color: #6da4d3;
        box-shadow: 0px 0px 2px #66a3d4;

        svg.circle-grid-cross-left-fill path,
        svg.icon-menu path {
            fill: #6da4d3;
        }

        &::after {
            content: '';
            position: absolute;
            height: 40px;
            width: 5px;
            background: #6da4d3;
            border-radius: 4px 0px 0px 4px;
            right: 0;
            top: 0;
        }
    }

    ${props =>
        props.isActive &&
        css`
            background: #ffffff;
            color: #6da4d3;
            box-shadow: 0px 0px 2px #66a3d4;

            svg.circle-grid-cross-left-fill path,
            svg.icon-menu path {
                fill: #6da4d3;
            }

            &::after {
                content: '';
                position: absolute;
                height: 40px;
                width: 5px;
                background: #6da4d3;
                border-radius: 4px 0px 0px 4px;
                right: 0;
                top: 0;
            }
        `}
`;
