import ReactECharts from 'echarts-for-react';

export interface PieChartDataProps {
    name: string;
    value: number;
    color?: string;
}

interface PieChartProps {
    showPercentage?: boolean;
    showLabel?: boolean;
    data: PieChartDataProps[];
}

export function PieChart({ data, showPercentage = false, showLabel = true }: PieChartProps) {
    const pie = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            type: 'scroll',
            top: 'top',
        },
        series: [
            {
                type: 'pie',
                radius: '70%',
                label: showPercentage
                    ? {
                          show: true,
                          formatter(param: any) {
                              return param.name + ' (' + param.percent + '%)';
                          },
                      }
                    : {
                          show: showLabel,
                      },
                data: data.map(d => {
                    return {
                        value: d.value,
                        name: d.name,
                        itemStyle: {
                            color: d.color,
                        },
                    };
                }),
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    };

    return <ReactECharts notMerge={true} style={{ width: '100%', height: '100%' }} option={pie} />;
}
