import { MenuItem, MenuProps } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePermission } from 'src/common/hooks';

import { SidebarItemProps } from '../sidebar-item';
import { StyledMenu } from './styles';

interface DropdownMenuProps extends MenuProps {
    items: Array<SidebarItemProps>;
    onGo: () => void;
}

function DropdownMenu({ items, onGo, ...props }: DropdownMenuProps) {
    const { hasAny } = usePermission();
    const { t } = useTranslation();

    return (
        <StyledMenu {...props}>
            {items.map(item => {
                const { userHasAny, ...rest } = item;
                if (userHasAny) {
                    if (!hasAny(...userHasAny)) return null;
                }

                return (
                    <Link key={item.id} {...rest} onClick={onGo}>
                        <MenuItem>{t(item.value)}</MenuItem>
                    </Link>
                );
            })}
        </StyledMenu>
    );
}

export default memo(DropdownMenu);
