import { useCallback, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePermission } from 'src/common/hooks';
import { useAuth } from 'src/modules/auth';

import { Settings } from '../icons';
import RenderIf from '../render-if';
import Row from '../row';
import SearchBar from '../search-bar';
import User from '../user-component';
import SettingOptions from './settings-options';
import { Button, HeaderBox } from './styles';

export default function Header() {
    const { session } = useAuth();
    const { has, hasAny } = usePermission();
    const { pathname } = useLocation();

    const settingsRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    const settingsPermissions = ['users', 'operations', 'funcionalities', 'vehicles', 'password_policy', 'intervention_protocol'];

    const handleOpenSettings = useCallback(() => {
        setOpen(state => !state);
    }, []);

    const settingsIsActive = useMemo(() => pathname.includes('configurations') || open, [pathname, open]);

    return (
        <HeaderBox>
            <SearchBar name="text" />
            <Row align="center" gap={16}>
                <RenderIf condition={hasAny(...settingsPermissions) && has('settings')}>
                    <Button isActive={settingsIsActive} onClick={handleOpenSettings} className="settings" type="button" ref={settingsRef}>
                        <Settings />
                    </Button>
                </RenderIf>

                <User name={session?.user?.name || session?.user?.email} />
            </Row>

            <RenderIf condition={has('settings') && open}>
                <SettingOptions anchorEl={settingsRef.current} open={open} onClose={handleOpenSettings} />
            </RenderIf>
        </HeaderBox>
    );
}
