import { DialogHTMLAttributes, forwardRef, ForwardRefRenderFunction, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Dialog, Row } from 'src/common/components';
import { usePastEvents, useSortReviewEvent } from 'src/modules/alarm-processing/hooks';
import { useEvents } from 'src/modules/alarm-processing/hooks/use-events';

import { DialogContent, Title } from './styles';
import Tabs from './tabs';
import TabsContent from './tabs-content';

type AlarmDetailsDialogProps = DialogHTMLAttributes<HTMLDialogElement> & {
    id: string;
    tabInfoLabel?: string;
};

const Base: ForwardRefRenderFunction<HTMLDialogElement, AlarmDetailsDialogProps> = (props, ref) => {
    const { handleResetSelectedEvent, selectedEvents, eventScreenType } = useEvents();
    const { handleGetList, handleRequestList } = usePastEvents();
    const {
        handleGetExtraEvents,
        handleGetListOptions,
        handleResetEventData,
        handleEventChecked,
        handleExtraEventChecked,
        handleDescriptionDetails,
    } = useSortReviewEvent();
    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState<number | false>(0);

    const fleetsMap = useMemo(
        () =>
            (selectedEvents?.map(event => {
                if (!event?.fleet) return;
                return event.fleet;
            }) as unknown as string[]) || [],
        [selectedEvents],
    );

    const fleets = useMemo(() => Array.from(new Set(fleetsMap)), [fleetsMap]);

    const eventNames = useMemo(
        () =>
            (selectedEvents?.map(event => {
                if (!event?.alarm) return;
                return event.alarm;
            }) as unknown as string[]) || [],
        [selectedEvents],
    );

    const title = useMemo(() => {
        switch (selectedTab) {
            case 0:
                return t(selectedEvents?.length && selectedEvents?.length > 1 ? 'alarms.sort_multiple_alarms' : 'alarms.information');
            case 1:
                return t('alarms.real_time');
            case 2:
                return t('alarms.historic_route');
            case 3:
                return t('alarms.previous_events');
            default:
                return null;
        }
    }, [selectedEvents?.length, selectedTab, t]);

    const handleChangeTab = useCallback((_: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    }, []);

    const handleReset = useCallback(() => {
        setSelectedTab(0);
        handleResetSelectedEvent();
        handleEventChecked('');
        handleDescriptionDetails('');
        handleExtraEventChecked([]);
    }, [handleDescriptionDetails, handleEventChecked, handleExtraEventChecked, handleResetSelectedEvent]);

    const handleClose = useCallback(() => {
        handleReset();

        document.querySelector<HTMLDialogElement>(`dialog#${props.id}`)?.close();
    }, [handleReset, props.id]);

    useEffect(() => {
        if (fleets?.length === 1 && selectedEvents?.length === 1) {
            handleRequestList({ board: fleets[0], operation: selectedEvents[0].operation || '', currentPage: 1 });
        }
    }, [fleets, handleRequestList, selectedEvents]);

    useEffect(() => {
        if (eventNames?.length > 0) handleGetListOptions(eventNames);
    }, [handleGetListOptions, eventNames]);

    useEffect(() => {
        handleGetList();
    }, [handleGetList]);

    useEffect(() => {
        if (eventScreenType === 'events') {
            selectedEvents?.[0] && handleGetExtraEvents(selectedEvents[0].id);
        } else {
            handleResetEventData();
        }
    }, [eventScreenType, handleGetExtraEvents, handleResetEventData, selectedEvents]);

    const dialogContent = useMemo(() => {
        if (selectedEvents?.length && selectedEvents?.length > 0) {
            return (
                <DialogContent>
                    <Column align="flex-start" gap={24} flex width="100%">
                        <Column gap={16} align="flex-start" flex width="100%">
                            <Row gap={48} align="flex-start" justify="space-between" width="100%" flex>
                                <Title>{title}</Title>
                                <Tabs value={selectedTab} onChange={handleChangeTab} onClose={handleClose} />
                            </Row>
                            <TabsContent value={selectedTab} onClose={handleClose} />
                        </Column>
                    </Column>
                </DialogContent>
            );
        }

        return <div />;
    }, [handleChangeTab, handleClose, selectedEvents?.length, selectedTab, title]);

    return (
        <Dialog ref={ref} onCloseModal={handleReset} {...props}>
            {dialogContent}
        </Dialog>
    );
};

const AlarmDetailsDialog = forwardRef(Base);

export default AlarmDetailsDialog;
