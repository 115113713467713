import { useTranslation } from 'react-i18next';

import Column from '../column';
import { Message, Title, UpdateButton } from './styles';

interface NoContentProps {
    message: string;
    messageClassName?: string;
    onClick?: () => void;
}

export default function NoContent({ message, messageClassName, onClick }: NoContentProps) {
    const { t } = useTranslation();

    return (
        <Column
            size="100%"
            align="center"
            justify="center"
            gap={8}
            style={{
                padding: '96px 0',
            }}
        >
            <Title>{t('general.no_content')}</Title>
            <Message className={messageClassName}>{message}</Message>

            {onClick && (
                <UpdateButton type="button" onClick={onClick}>
                    {t('general.update')}
                </UpdateButton>
            )}
        </Column>
    );
}
