import styled from 'styled-components';

export const Button = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    border-radius: 8px;

    background-color: transparent;
    border: none;

    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: #262d3c;

    padding: 6px;

    transition: 0.3s ease-in all;

    &:hover {
        background-color: #b2d5f2;
    }
`;
