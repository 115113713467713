import { SVGProps } from 'react';

export default function LayoutCard(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="1" y="1" width="7" height="7" rx="1" stroke="#A9A9A9" strokeWidth="2" strokeLinejoin="round" />
            <rect x="1" y="12" width="7" height="7" rx="1" stroke="#A9A9A9" strokeWidth="2" strokeLinejoin="round" />
            <rect x="12" y="12" width="7" height="7" rx="1" stroke="#A9A9A9" strokeWidth="2" strokeLinejoin="round" />
            <rect x="12" y="1" width="7" height="7" rx="1" stroke="#A9A9A9" strokeWidth="2" strokeLinejoin="round" />
        </svg>
    );
}
