import styled from 'styled-components';

export const AddNewProtocol = styled.section``;

export const MainCard = styled.div`
    background: #ffffff;
    border-radius: 8px;
    overflow: auto;
`;

export const CardHeader = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    padding: 16px 16px 0;

    h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #4d4d4d;
        display: flex;
        align-items: center;
        gap: 8px;

        margin: 0;
    }

    span.total {
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        margin: 0;

        color: #a9a9a9;
    }
`;

export const Intro = styled.div`
    display: flex;
    justify-content: space-between;

    div {
        display: flex;
        gap: 1rem;

        h2 {
            color: #262d3c;
            font-size: 500;
        }
    }
`;

export const ButtonsDeleteContainer = styled.section`
    padding-top: 1.5rem;
    display: flex;
    gap: 2rem;
`;

export const AddNewProtocolSection = styled.section`
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -20rem;
    margin-left: -30rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 45%;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    padding: 20px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 8px;

    div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
`;

export const ButtonNewProtocol = styled.div`
    padding-top: 3rem;
    display: flex;
    gap: 2rem;
`;

export const SecondScreenAddNewProtocol = styled.div``;

export const TableRow = styled.tr`
    cursor: none;

    &.body {
        border-top: 1px solid #d9d9d9;
    }

    &.pointer {
        cursor: pointer;
    }
`;

export const TableData = styled.td`
    cursor: default;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    text-align: left;
    color: #7b7b7b;
    padding: 0.5rem 0 0;

    &:first-child {
        padding-left: 16px;
    }
`;

export const ViewProtocolSection = styled.section`
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -20rem;
    margin-left: -30rem;

    width: 50%;
    padding: 20px;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
`;

export const TableSection = styled.section`
    display: flex;
    flex-direction: column;
    > div {
        display: flex;
    }
`;

export const ViewDetailsSection = styled.section`
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -20rem;
    margin-left: -30rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
`;

export const EditProtocol = styled.section`
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -20rem;
    margin-left: -30rem;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 50%;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
`;

export const AddNewProtocolDetails = styled.section`
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -20rem;
    margin-left: -30rem;

    width: 45%;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    padding: 20px;
`;

export const EditProtocolTable = styled.section`
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -20rem;
    margin-left: -30rem;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 50%;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    padding: 20px;
`;

export const DeleteOptionTable = styled.div`
    position: absolute;
    top: 60%;
    left: 55%;
    margin-top: -17.5rem;
    margin-left: -20rem;

    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    width: 30rem;
    padding: 40px;

    > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h2 {
            color: #262d3c;
            font-weight: 500;
            text-align: center;
        }

        p {
            color: #7b7b7b;
            text-align: center;
            font-size: 12px;
        }
    }
`;

export const AddNewProtocolView = styled.div`
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -20rem;
    margin-left: -30rem;

    width: 50%;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
`;

export const ButtonAddProtocolContainer = styled.div`
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
`;

export const LabelInterventionProtocol = styled.label`
    color: #000000;
    font-weight: 600;
    line-height: 16px;
    font-size: 12px;
`;

export const AddNewProtocolDetailsTable = styled.section`
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -20rem;
    margin-left: -30rem;

    width: 45%;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    padding: 20px;
`;
