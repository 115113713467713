import styled from 'styled-components';

import Column from '../../column';

interface OptionsProps {
    optionsHeight?: string;
}

export const Container = styled(Column)`
    position: relative;
    gap: 4px;

    &:focus-within:has(input:enabled) .opt {
        display: block;
    }
`;

export const Button = styled.button`
    background: transparent;
    border: 0;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    width: 100%;
    text-align: left;

    cursor: pointer;

    color: #5f5f5f;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #262d3c;
        background-color: #f0f0f0;
    }

    margin-bottom: 8px;
`;

export const Options = styled.div<OptionsProps>`
    display: none;
    position: absolute;
    z-index: 2;
    top: 59px;
    left: 1px;
    float: bottom;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    max-height: ${props => props?.optionsHeight || '229px'};
    overflow-x: auto;

    padding: 8px 16px;

    background: #f5f5f5;
    box-shadow: 0px 2px 4px 1px rgba(3, 3, 3, 0.15);
    border-radius: 8px;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        border: 0;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e6e6e6;
        border: 1px solid #f5f5f5;
        background-clip: content-box;
    }
`;
