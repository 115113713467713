import { createContext, useCallback, useMemo, useReducer, useState } from 'react';

import { DateHelper, FunctionComponent, ResponseStatus } from '../../../../common';
import { Pagination } from '../../../../common/core/pagination';
import { Reducer, reducer } from '../../../../common/types/reducer';
import { DepartmentResume } from '../../../configurations';
import { INITIAL_OPERATION_STATE } from '../../fleets/context/const';
import { FaceMissingFilterDTO } from '../dtos/face-missing-filter.dtos';
import { FaceMissingDTO, FaceMissingDetailsDTO, FaceMissingRequestDTO } from '../dtos/face-missing.dtos';
import { faceMissingService } from '../services/face-missing.service';
import { FaceMissingContextProps } from './types';

export const FaceMissingContext = createContext({} as FaceMissingContextProps);

export const INIT_FILTER_STATE: FaceMissingFilterDTO = {
    finalDate: new Date(),
    initialDate: DateHelper.yesterday(),
    operationInput: '',
    operations: [],
};

export function FaceMissingProvider({ children }: FunctionComponent) {
    const [filter, setFilter] = useState<FaceMissingFilterDTO>(INIT_FILTER_STATE);
    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_OPERATION_STATE);

    const [listFaceMissing, setListFaceMissing] = useState<Array<FaceMissingDTO> | null>(null);
    const [listFaceMissingDetails, setListFaceMissingDetails] = useState<Pagination<FaceMissingDetailsDTO> | null>(null);

    const [requestData, setRequestData] = useState<FaceMissingRequestDTO>({
        currentPage: 1,
        pageSize: 10,
        dateFrom: DateHelper.yesterday().toISOString().split('T')[0],
        dateTo: new Date().toISOString().split('T')[0],
    });
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: true,
        success: false,
        void: false,
    });

    const [responseStatusDetails, setResponseStatusDetails] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: true,
        success: false,
        void: false,
    });

    const handleRequestList = useCallback((data: Partial<FaceMissingRequestDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleGetList = useCallback(async () => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const data = await faceMissingService.getData(requestData);

            setListFaceMissing(data);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: data.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const handleGetListDetails = useCallback(async () => {
        setResponseStatusDetails(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const data = await faceMissingService.getDetails(requestData);

            setListFaceMissingDetails(data);

            setResponseStatusDetails({
                loading: false,
                error: undefined,
                hasError: false,
                void: !data?.elements || data?.elements?.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatusDetails({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const reset = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(true);
        setOperations({
            type: 'data',
            payload: [],
        });
    }, []);

    const handleFilterIsOpen = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const data: FaceMissingContextProps = useMemo(
        () => ({
            listFaceMissing,
            handleRequestList,
            handleGetList,
            requestData,
            responseStatus,
            listFaceMissingDetails,
            handleGetListDetails,
            responseStatusDetails,
            filter,
            setFilter,
            filterIsOpen,
            reset,
            handleFilterIsOpen,
            operations,
            setOperations,
        }),
        [
            listFaceMissing,
            handleRequestList,
            handleGetList,
            requestData,
            responseStatus,
            listFaceMissingDetails,
            handleGetListDetails,
            responseStatusDetails,
            filter,
            setFilter,
            filterIsOpen,
            reset,
            handleFilterIsOpen,
            operations,
            setOperations,
        ],
    );

    return <FaceMissingContext.Provider value={data}>{children}</FaceMissingContext.Provider>;
}
