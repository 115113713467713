export function Uncheck() {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0232 2.94327V19.6786H3.28787V2.94327H20.0232ZM20.0232 0.55249H3.28786C1.97294 0.55249 0.897095 1.62833 0.897095 2.94325V19.6786C0.897095 20.9935 1.97294 22.0693 3.28786 22.0693H20.0232C21.3381 22.0693 22.4139 20.9935 22.4139 19.6786V2.94325C22.4139 1.62833 21.3381 0.55249 20.0232 0.55249Z"
                fill="#7A7F85"
            />
        </svg>
    );
}
