import franceFlag from 'src/assets/flags/france-flag.svg';

import { LanguageTranslations } from '../types/translations';

const FR: LanguageTranslations = {
    dateFormat: 'dd/MM/yyyy',
    flag: franceFlag,
    translations: {
        auth: {
            unable_to_scan: "Impossible de numériser ? Vous pouvez utiliser le X pour configurer manuellement votre application d'authentification.",
            setup_key: 'clé de configuration',
            use_an_authenticator_app_or_browser_extension_to_scan:
                "Utilise une application d'authentification ou une extension de navigateur pour numériser.",
            verify_code: 'Vérifier le code',
            scan_the_qr_code: 'Scannez le code QR',
            preferred_mfa_method: 'Méthode MFA préférée',
            authenticator_app: "Application d'authentification",
            sms: 'SMS',
            configure_mfa_text: 'Pour continuer, veuillez configurer MFA pour cet utilisateur.',
            configure_mfa: 'Configurer MFA',
            enter_the_code_sent_to: 'Saisissez le code envoyé à :',
            redefine_password: 'Redéfinir le mot de passe',
            access_password: "Mot de passe d'accès",
            confirm_new_password: 'Confirmer le nouveau mot de passe',
            email: 'E-mail',
            email_or_phone: 'Email ou téléphone',
            forgot_password: "J'ai oublié mon mot de passe",
            login: 'Connexion',
            logout_confirmation: 'Vous allez vous déconnecter, voulez-vous continuer ?',
            logout_system: 'Se déconnecter',
            name: 'Nom',
            new_password: 'Nouveau mot de passe',
            password: 'Mot de passe',
            password_confirmation: 'Password confirmation',
            register_new_password: 'Pour continuer, vérifiez vos données et enregistrez un mot de passe pour accéder à notre plateforme.',
            remember_password: 'Confirmation mot de passe',
            send: 'Soumettre',
            user: 'Utilisateur',
            welcome: 'Bienvenu',
            continue: 'Continuez',
            error_login: 'Nombre de usuario o contraseña incorrecta.',
            forgot_password_page_message: `Entrez l'e-mail enregistré pour recevoir le lien de réinitialisation :`,
            forgot_password_code_message: 'Entrez le code de vérification reçu.',
            request_new_code: 'Demander un nouveau code',
            verification_code: 'Code de vérification',
            on_confirm_change_pass: {
                success: {
                    title: 'Succès',
                    message: 'Le mot de passe a été changé avec succès.',
                },
                error: {
                    expiredCodeException: {
                        title: 'Code expiré',
                        message: 'Code de vérification expiré.',
                    },
                },
            },

            on_request_code: {
                success: {
                    title: 'Demande reçue',
                    message: 'Le courrier électronique de récupération a été envoyé avec succès!',
                },
                error: {
                    limitExceeded: {
                        title: 'Limite dépassée',
                        message: 'Les limites des tentatives ont déjà dépassé, réessayez plus tard.',
                    },
                },
            },
        },
        configurations: {
            paper: 'Papier',
            intervention_protocol: `Protocole d'intervention`,
            edit_password_policy: 'Modifier la politique de mot de passe',
            view_password_policy: 'Afficher la politique de mot de passe',
            view_operation: "Afficher l'opération",
            view_function: 'Afficher la fonction',
            edit_function: 'Modifier la fonction',
            view_user: "Afficher l'utilisateur",
            save_contact: 'Enregistrer le contact',
            do_you_want_to_delete_the_password_policy: 'Voulez-vous supprimer la ou les politiques de mot de passe ?',
            delete_policy: 'Supprimer la politique',
            mfa_validation: 'Validation MFA obligatoire',
            password_policy_status: 'État de la stratégie de mot de passe',
            current_password_must_be_different_from_the_last_X_passwords: 'Le mot de passe actuel doit être différent des X derniers mots de passe.',
            contains_at_least_one_uppercase_letter: 'Contient au moins 1 lettre majuscule',
            contains_at_least_one_lowercase_letter: 'Contient au moins 1 lettre minuscule',
            contains_at_least_one_special_character: 'Contient au moins 1 caractère spécial',
            contains_at_least_one_number: 'Contient au moins 1 numéro',
            minimum_time: 'Temps minimum (en jours)',
            expiry_time: "Date d'expiration (en jours)",
            password_minimum_length: 'Longueur minimale du mot de passe (caractères)',
            assigned_to: 'Attribué à',
            password_policy_name: 'Nom de la stratégie de mot de passe',
            create_password_policy: 'Créer une politique de mot de passe',
            password_policies: 'Politiques de mot de passe',
            redefine_password: 'Redéfinir le mot de passe',
            view_vehicle: 'Voir le véhicule',
            edit_vehicle: 'Modifier le véhicule',
            delete_vehicle: 'Exclure le véhicule',
            delete_the_vehicle_white_board: "Voulez-vous supprimer la plaque d'immatriculation du véhicule",
            add_vehicle: 'Ajouter un véhicule',
            acquisition_date: "Date d'acquisition",
            current_vehicle_statuses: {
                new: 'Nouveau',
                semi_new: 'Semi-nouveau',
                used: 'Occasion',
            },
            vehicle_types: {
                agricultural_machine: 'Machine agricole',
                bus: 'Autobus',
                light_vehicle: 'Véhicule léger',
                locomotive: 'Locomotive',
                motorcycle: 'Moto',
                ship: 'Navire',
                stationary_equipment: 'Équipement fixe',
                truck: 'Camion',
            },
            current_vehicle_status: 'État actuel du véhicule',
            vehicle_type: 'Type de véhicule',
            create_vehicle: 'Créer un véhicule',
            edit_department: 'Modifier le Opération',
            equals_resources: 'Les ressources des parents et des enfants sont égales',
            ambiguous_record: "Cet enregistrement est ambigu, sélectionnez l'enregistrement souhaité.",
            create_department: 'Créer un Opération',
            contact: 'Contact',
            add_department: 'Ajouter un Opération',
            add_function: 'Ajouter une fonction',
            add_user: 'Ajouter un utilisateur',
            cancel: 'Annuler',
            confirm: 'Confirmer',
            confirm_delete_functions: 'Voulez-vous supprimer le(s) rôle(s) ?',
            confirm_delete_users: 'Voulez-vous supprimer le ou les utilisateurs ?',
            confirm_password: 'Confirmez le mot de passe',
            contacts: 'Contacts',
            creation_date: 'Date de création',
            creator: 'créateur',
            delete: 'Effacer',
            delete_department: 'Supprimer le Opération',
            delete_function: 'Supprimer le rôle',
            delete_the_user: 'Voulez-vous supprimer l utilisateur',
            department: 'Opération',
            departments: 'Opérations',
            do_you_want_to_delete_the_department: 'Voulez-vous supprimer le(s) Opération(s)',
            do_you_want_to_delete_the_role: 'Voulez-vous supprimer le rôle',
            edit_user: 'Modifier l utilisateur',
            email: 'E-mail',
            equipments: 'Équipement',
            function: 'Une fonction',
            function_name: 'Nom de rôle',
            function_number: 'Numéro de fonction',
            functionalities: 'Fonctionnalités',
            functions: 'Les fonctions',
            institution: 'Institution',
            institution_name: "Nom de l'institution",
            login: 'Connexion',
            name_login: 'Connexion par nom',
            name_user: 'Nom d utilisateur',
            number: 'Numéro',
            observation: 'Observation',
            organization: 'Organisation',
            organization_name: 'nom de l organisation',
            password: 'Mot de passe',
            password_policy: 'Politique de mot de passe',
            phone_number: 'Numéro de téléphone',
            settings: 'Réglages',
            show_active_users: 'Afficher les utilisateurs actifs',
            status: 'Statut',
            system: 'Système',
            this_action: 'Cette action ne peut pas être annulée',
            user: 'utilisateur',
            user_delete: 'Supprimer l utilisateur',
            users: 'Utilisateurs',
            vehicles: 'Véhicules',
            client: 'Client',
            analyst: 'Analyste',
            client_analyst: 'Analyste Clients',
            profile: 'Profil',
            description: 'Description',
        },
        field_validations: {
            min: 'Le nombre minimum de caractères est : $$min',
            name: 'Nom invalide',
            date_is_before_creation_date: 'La date sélectionnée ne peut pas être inférieure à la date de création',
            date_is_before_today: 'La date sélectionnée ne peut pas être inférieure à la date actuelle',
            date: 'Date invalide',
            phone_number_tooltip: "Entrez un numéro de téléphone, y compris + et l'indicatif du pays, par exemple +12065551212.",
            password_length_recommended:
                "Doit être un nombre compris entre 6 et 99. Nous vous recommandons fortement d'exiger que les mots de passe comportent au moins 8 caractères.",
            equals_resources: 'Les ressources des parents et des enfants sont égales',
            mandatory_fields: 'Champs obligatoires',
            max: 'Taille maximale : %%',
            phone: 'Téléphone invalide',
            email: 'Email invalide',
            hour: "Format d'heure non valide.",
            invalid_record: 'Inscription invalide',
            passwords_match: 'Les mots de passe ne correspondent pas',
            record_already_exists: 'Cet enregistrement existe déjà.',
            required: 'Champs requis',
            password: {
                lower_case: 'Le mot de passe doit contenir des lettres minuscules.',
                upper_case: 'Le mot de passe doit contenir des majuscules.',
                special_character: 'Le mot de passe doit contenir au moins un caractère spécial.',
                length_8: 'Le mot de passe doit contenir au moins 8 caractères.',
                number: 'Le mot de passe doit contenir au moins un numéro.',
            },
        },
        home: {
            events: 'Événements',
            updated_at: 'Mis à jour à',
            interventions: 'Interventions',
            drowsiness: 'Somnolence',
            interventions_last_hour: 'Interventions dans la dernière heure',
            interventions_last_hour_subtitle: "Chaque point représente l'évolution du nombre d'événements dans la dernière heure.",
            event_distribution: 'Répartition des événements',
            event_distribution_subtitle: "Chaque section indique le pourcentage d'événements pour chaque catégorie pendant la période sélectionnée.",
            violation: 'Infraction',
            fatigue: 'Fatigue',
            distraction: 'Distraction',
            speeding: 'Excès de vitesse',
            dangerous_driving: 'Conduite dangereuse',
            aggressive_driving: 'Conduite agressive',
            recent_interventions: 'Interventions récentes',
            recent_interventions_subtitle:
                "Les marqueurs rouges sur la carte indiquent l'emplacement des interventions survenues pendant la période sélectionnée. En cliquant dessus, la vidéo et les informations liées à l'intervention sont affichées.",
            vehicles: 'Véhicles',
        },
        menu: {
            events: 'Événements',
            language: 'Français',
            logout: 'Se déconnecter',
            panel: 'Panneau',
            pending_alarms: 'Alarmes en attente',
            processing_alarms: 'Traitement des alarmes',
            vehicles_monitoring: 'Surveillance des véhicules',
            interventions: 'Interventions',
            analytics: 'Analytics',
            overview: 'Aperçu',
            period: 'Période',
            registration_shipments: 'Enregistrement des expéditions',
            help: 'Aider',
            review_request: `Examen de l'événement`,
            analysis_requests: `Demandes d'analyse`,
            home: 'Accueil',
            smart_report: 'Smart Report',
        },
        general: {
            hours: 'Heures',
            last: 'Dernière',
            loading: 'Chargement',
            see_all: 'voir tout',
            status: {
                active: 'Actif',
                inactive: 'Inactif',
            },
            recommended: 'Recommandé',
            name: 'Nom',
            input: 'Entrée',
            output: 'Sortie',
            expires_in: 'Expire dans',
            default: 'Défaut',
            record_has_relationships: 'Les politiques ont des utilisateurs liés, veuillez les dissocier et réessayer.',
            select: 'Sélectionner',
            export: 'Exporter',
            select_filters_to_view_the_map: 'Sélectionnez les filtres pour afficher la carte',
            no_vehicle_selected: 'Aucun véhicule sélectionné',
            error_loading_data: 'Erreur lors du chargement des données',
            admin: 'Admin',
            interventions: 'Interventions',
            analytics: 'Analytics',
            overview: 'Aperçu',
            unit: 'Unité',
            fleet: 'Flotte',
            driver: 'Conducteur',
            communication: 'Communication',
            support: 'Soutien',
            vehicle_monitoring: 'Surveillance des véhicules',
            sort_alarm: "Trier l'alarme",
            settings: 'Paramètres',
            review_alarm: "Examiner l'alarme",
            reports: 'Rapports',
            real_time_localization: 'Localisation en temps réel',
            playback_of_tracks: 'Lecture de piste',
            live_camera_stream: 'Flux de caméra',
            electronic_fence: 'Clôture électronique',
            dashboard: 'Dashboard',
            authentication: 'Authentification',
            alarms_processing: 'Traitement des alarmes',
            selection_of_settings: 'Sélection des paramètres',
            main_menu: 'Menu principal',
            exclude_yourself: 'Vous ne pouvez pas vous supprimer.',
            configurations: 'Paramètres',
            alarm_type: "Type d'alarme",
            alarms_high_risk: 'Alarmes à haut risque',
            alarms_solved: 'Alarmes gérées',
            unhandled_alarms: 'Alarmes non gérées',
            alarms_total: 'Total des alarmes',
            all: 'Tout',
            apply: 'Appliquer',
            cancel: 'Annuler',
            classification: 'Classer',
            clear_filters: 'Effacer les filtres',
            conclude: 'Conclure',
            confirm: 'Confirmer',
            date: 'Date',
            description: 'La description',
            destination: 'Destination',
            error: 'Erreur, réessayez.',
            events: 'Événements',
            filter: 'Filtre',
            filters: 'Filtres',
            final_hour: 'Dernière heure',
            high_risk: 'Risque élevé',
            historic: 'Historique',
            information_unavailable: 'Informations non disponibles',
            initial_hour: 'Heure initiale',
            items_per_page: 'Objets par page',
            low_risk: 'Faible risque',
            medium_risk: 'Risque moyen',
            no_content: 'Aucun enregistrement',
            no_content_specific_alarm: "Vous n'avez pas encore d'alarme",
            no_content_specific_event: "Vous n'avez pas encore d'événements",
            no_filter_vehicle: "Nous n'avons trouvé aucun résultat pour les filtres appliqués",
            no_vehicle: 'Aucun véhicule trouvé',
            of: 'sur',
            offline: 'Hors ligne',
            on_the_page: 'Vous êtes sur la page',
            online: 'En ligne',
            online_vehicles: 'Véhicules en ligne',
            origin: 'Origine',
            period: 'Période',
            review: 'Examen',
            search: 'Chercher...',
            select_period: 'Sélectionnez la période',
            selected_alarms: 'Alarmes sélectionnées',
            seven_days: '7 jours',
            show_events: 'Afficher les événements',
            success: 'Succès',
            test: 'Test',
            total_vehicles: 'Véhicules Total',
            twenty_four_hours: '24 heures',
            update: 'Mise à jour',
            update_data: 'Mettre à jour les données',
            vehicle: 'Véhicule',
            vehicles: 'Véhicules',
            vehicles_events: 'Véhicules Événements',
            view: 'Voir',
            write: 'Écrire',
            write_here: 'Écrivez ici',
            activity: 'Activité',
            region: 'Région',
            country: 'Pays',
            operation: 'Opération',
            notification_sound: 'Son de notification',
            functionalities: 'Fonctionnalités',
            operations: 'Opérations',
            password_policy: 'Politique de mot de passe',
            users: 'Utilisateurs',
            speed_above: 'Vitesse au-dessus',
            message: {
                success: {
                    title: 'Succès',
                    message: 'Demande terminée avec succès.',
                },
                error: {
                    title: 'Échec',
                    message: 'Il y a eu un non-traitement de votre demande.',
                },
            },
            face_missing: 'Visage manquant',
            yes: 'Oui',
            no: 'Non',
            back_to_home: 'de retour à la maison',
            intervention_protocol: `Protocole d'intervention`,
            registration_shipments: `Enregistrement d'expédition`,
            event_redistribution: `Redistribution d'événements`,
            select_the_event: `Sélectionner l'événement`,
            review_requests: "Demandes d'Analyse",
            unit_comparison: `Comparaison d'unités`,
            last_alarm: 'Dernière alarme',
            fleets: 'Flottes',
            select_options: 'Sélectionnez les options',
            see_driver_name: 'Voir le nom du conducteur',
        },
        alarms: {
            activity_analyst: 'Activité Analyste',
            alarms_search_parameters: `Paramètres de recherche d'alarmes`,
            dynamic: 'dynamique',
            input_alarms_search_parameters: `Paramètres de recherche d'alarmes d'entrée`,
            label_alarms_search_parameters: `Paramètres de recherche d'alarmes d'étiquette`,
            label_select_redistribution: `Redistribution de sélection d'étiquette`,
            open_alarms: 'alarmes ouvertes',
            placeholder_alarms_search_parameters: `Paramètres de recherche d'alarmes d'espace réservé`,
            placeholder_select_alarms_search_parameters: `Espace réservé sélectionner les paramètres de recherche des alarmes`,
            placeholder_select_redistribution: `Redistribution de sélection d'espace réservé`,
            processed_alarms: 'alarmes traitées',
            select_redistribution: 'Sélectionner la redistribution',
            pending_analysis: 'Analyse en attente',
            last_location: 'Dernier lieu',
            minimize: 'Minimiser',
            see_all: 'Tout voir',
            alarm_time: "Heure de l'alarme",
            event_type: "Type d'événement",
            GPS: 'GPS',
            alarm: 'Alarme',
            alarm_type: "Type d'alarme",
            arrival: 'Arrivée',
            board: 'Planche',
            break: 'Casser',
            cam: 'Caméra',
            capture: 'Capture',
            captures: 'Captures',
            device: 'Dispositif',
            event: 'Événement',
            extra_events: 'Événements supplémentaires',
            false_alarm: 'Fausse alarme',
            fleet: 'Flotte',
            historic_route: 'Itinéraire historique',
            hour: 'Heure',
            information: "Informations sur l'alarme",
            level: "Niveau d'alarme",
            looking_down: 'Regarder en bas',
            name: "Nom de l'alarme",
            number_of: 'Nombre de captures',
            operation: 'Opération',
            previous_events: 'Événements précédents',
            priority: 'Priorité',
            priority_1: 'Haut',
            priority_2: 'Moyen',
            priority_3: 'Bas',
            real_time: 'Surveillance en temps réel',
            select_alarm: "Sélectionnez l'alarme",
            select_board: 'Sélectionnez le conseil',
            select_destination: 'Adresse de destination',
            select_events: 'Sélectionnez des événements',
            select_operation: "Sélectionnez l'opération",
            select_origin: "Adresse d'origine",
            select_vehicle: 'Sélectionnez un véhicule',
            somnolence: 'Somnolence',
            sort_multiple_alarms: 'Trier plusieurs alarmes',
            velocity: 'Rapidité',
            automatic_search: 'Recherche Automatique',
            automatic_search_tooltip: 'Effectue une recherche automatique de nouvelles alarmes toutes les 30s',
            analyst: 'Analyste',
            media_visualization: 'Visualisation des médias',
            media_size: 'Taille du support (pixels)',
            placeholder_midias_size: 'Entrez la quantité de pixels pour augmenter le média',
            options: 'Choix',
            request_review: 'Demander un Examen',
            review_event: 'Événement de Révision',
            see_details: 'Voir les Détails',
        },
        review_request: {
            review_request: `Examen de l'événement`,
            analysis_requests: `Demandes d'analyse`,
            analysis_request: `demande d'analyse`,
            request_number: 'N° de demande',
            request_time: 'Date de la demande',
            fleet: 'Flotte',
            operation: 'Opération',
            event: 'Événement',
            event_time: `Date de l'événement`,
            post_analysis: 'Post-analyse',
            justification: 'Justification',
            answer: 'Réponse',
            status: 'Situation',
            see: 'Voir',
            success: 'Succès',
            pending: 'En attente',
        },
        monitoring: {
            view_electronic_fence: 'Afficher la clôture électronique',
            do_you_want_to_delete_the_electronic_fence: 'Voulez-vous supprimer la clôture électronique',
            delete_electronic_fence: 'Supprimer la clôture électronique',
            change_zone_shape: 'Modifier la forme de la zone',
            zone_color: 'Couleur de la zone',
            transparency: 'Transparence',
            expires_in: 'Expire dans',
            generate_event_by: "Générer l'événement par",
            electronic_fence_name: 'Nom de la clôture électronique',
            edit_electronic_fence: 'Modifier la clôture électronique',
            add_electronic_fence: 'Ajouter une clôture électronique',
            add_fence: 'Ajouter une clôture',
            view: 'Voir',
            SIM: 'Numéro de carte SIM',
            add_perimeter: 'Ajouter un périmètre',
            altitude: 'Altitude',
            board_color: 'Couleur du tableau',
            board_number: 'Numéro de carte',
            cam_stream: 'Flux de came',
            cellphone: 'Contacter le téléphone portable',
            cities: 'Villes et provinces',
            conduction: 'Modèle de conduction',
            define_route: "Définir l'itinéraire",
            defined_perimeters: 'Périmètres définis',
            direction: 'Direction',
            driver_info: 'Informations sur le conducteur',
            driver_name: 'Nom du conducteur',
            eletronic_fence: 'Clôture électrique',
            filiation: 'Filiation',
            identity: "Numéro d'identité",
            last_events: 'Derniers événements',
            last_register: 'Dernier enregistrement',
            lat: 'Latitude',
            localization: 'Localisation',
            long: 'Longitude',
            mark_perimeter: 'Marquer les périmètres',
            organization: 'Organisation de contrôle',
            perimeter: 'Périmètre',
            position: 'Positionnement',
            radius: 'Rayon',
            real_time: 'Surveillance en temps réel',
            select_perimeter: 'Sélectionnez le périmètre',
            status: 'Statut',
            track_playback: 'Lecture de piste',
            tracking: 'Suivi',
            vehicle_info: 'Informations sur le véhicule',
            write_radius: "Rayon d'écriture (km)",
            compass_points: {
                east: 'Est',
                north: 'Nord',
                northeast: 'Nord-est',
                northwest: 'Nord-ouest',
                south: 'Sud',
                southeast: 'Sud-est',
                southwest: 'Sud-ouest',
                west: 'Ouest',
            },
            event_expires_in: {
                never: 'Jamais',
                today: "Aujourd'hui",
                tomorrow: 'Demain',
                seven_days: '7 jours',
                custom: 'Personnalisé',
            },
        },
        password: {
            new_password_is_equal_to_old: 'Votre nouveau mot de passe doit être différent de vos anciens.',
            contains_at_least_one_lowercase_letter: 'Le mot de passe doit contenir au moins 1 lettre minuscule',
            contains_at_least_one_number: 'Le mot de passe doit contenir au moins 1 chiffre',
            contains_at_least_one_special_character: 'Le mot de passe doit contenir au moins un caractère spécial',
            contains_at_least_one_uppercase_letter: 'Le mot de passe doit contenir au moins une lettre majuscule',
            is_expired: 'Le mot de passe a expiré',
        },
        overview: {
            proportion_events: 'Proportion',
            proportion_events_chart_subtitle: `Ce graphique représente la proportion d'événements dans la période sélectionnée.`,
            heat_map_events: 'Carte de chaleur',
            heat_map_events_chart_subtile: 'Les points sur cette carte indiquent les endroits où les événements se produisent le plus souvent.',
            total_events: 'Total',
            total_events_chart_subtitle: `Ce graphique représente le nombre total d'événements dans la période sélectionnée.`,
            average_events: 'Average',
            average_events_chart_subtile: 'Ce graphique représente le nombre moyen de véhicules sur la période sélectionnée.',
            telemetry: 'Télémétrie',
        },
        period: {
            time_range: 'Intervalle de temps',
            day_week: 'Jours de la semaine',
            evento_per_day_hour: 'Événements par jour et heure',
            hours_per_bar: 'Heures par barre',
            coordinates: 'Coordonnées',
            polar: 'Polaire',
            cartesian: 'Cartésien',
            options: 'Choix',
            day: 'Jour',
            week: 'Semaine',
            month: 'Mois',
            year: 'Ano',
            bar: 'Année',
            line: 'Doubler',
            graph_type: 'Type de graphique',
            cancel: 'Annuler',
            apply: 'Postuler',
            worst_tracks_highlights: 'Met en évidence les pires pistes',
            total_events: "nombre total d'événements",
            at: 'à',
            scatterGraphSubTitle:
                'Les points du graphique représentent' +
                ' des événements sélectionnés pendant les heures et les jours' +
                " de la semaine. Plus le point est grand, plus il y a d'événements" +
                ' et les points clignotants représentent la quantité sélectionnée de pistes de basse.',
            cartesianChart: "Les bandes sur ce graphique indiquent les heures avec la plus grande occurrence d'événements.",
            barChartGraph: "Les barres de ce graphique indiquent les jours de la semaine avec le plus grand nombre d'événements.",
            telemetry: 'Télémétrie',
        },
        interventions: {
            interventions_informations: 'Informations sur les interventions',
            qtd_events: "Nombre d'événements: ",
            hour_initial: 'Heure de début des événements: ',
            hour_final: 'Heure de fin des événements: ',
            interventions_situation: "Cas d'intervention",
            media: 'Médias',
            speed: 'Vitesse',
            hour: 'Heure',
            lat: 'Latitude',
            long: 'Longitude',
            close: 'Fermer',
            finalize: 'Finaliser',
            informations: 'Information',
            comments: 'Commentaires',
            type_comment: 'Écrivez votre commentaire',
            comment: 'Commentaire',
            event: 'Événement',
            pending: 'En attente',
            in_negotiation: 'En négociation',
            closed: 'Fermé',
            status: 'Status',
            all: 'Tous',
            'Em aberto': 'Ouvert',
            Pendente: 'En attente',
            'Em tratativa': 'En négociation',
            Fechado: 'Fermé',
            'mudou para status': 'changé en status',
        },
        face_missing: {
            proportion: 'Proportion',
            fleet: 'Flotte',
            details: 'Détails',
            details_table_subtitle: 'Sélectionnez le format souhaité pour télécharger les informations du tableau',
            face_missing: 'Face Missing',
            total_alarms: 'Alarmes totales',
            percentage: 'Pourcentage',
            status: 'Statut',
            proportion_graph_subtitle: `Ce graphique représente la proportion d'événements dans la période sélectionnée.`,
            fleet_graph_subtitle: `Le graphique ci-dessous présente l'état de l'appareil DS en fonction de chaque flotte.`,
            informative: 'Informatif',
            about_face_missing: 'À propos du visage manquant :',
            a_fleet_is_classified: 'Une flotte est classée comme déplacée si au moins 50 % des événements de cette flotte sont Face Missing.',
        },
        registration_shipments: {
            registration_of_report_submissions: 'Enregistrement des soumissions de rapports',
            operation: 'Opération',
            email: 'Email',
            shipping_type: `Type d'expédition`,
            channel: 'Canaliser',
            priority: 'Priorité',
            telephone: 'Téléphone',
            add_chance: 'Ajouter un changement',
            send_request: 'Envoyer une demande',
            contact: 'Contact',
            save: 'Sauvegarder',
            cancel: 'Annuler',
            daily_report: 'Rapport journalier',
            delete_contact: 'Effacer le contact',
            title_confirm_delete: 'Voulez-vous supprimer le contact «NOM DU CONTACT»?',
            confirm: 'Confirmer',
            registration_shipments: `Envois d'enregistrement`,
            intervention: 'Intervention',
            contacts: 'Contacts',
            select_type: 'Sélectionner le genre',
        },
        fleets: {
            fleets: 'Flottes',
            ds_ranking: 'Classement DS',
            fleet_ranking: 'Classement de la flotte',
            total: 'Total',
            time_range: 'Intervalle de temps',
            heat_map: 'Carte de chaleur',
            events: 'Événements',
            type: 'Taper',
            bar: 'Bar',
            line: 'Doubler',
            day: 'Jour',
            week: 'Semaine',
            month: 'Mois',
            year: 'Année',
            select_the_event: `sélectionner l'événement`,
            hours_per_bar: 'Heures par barre',
            period: 'Période',
            polar: 'Polaire',
            cartesian: 'Cartésien',
            radio: 'Radio',
            blur: 'Se brouiller',
            period_view: 'Vue de la période',
            need_to_select_fleet: 'Sélectionnez une flotte dans le classement ci-dessus pour visualiser les détails de la flotte',
            fleet_ranking_subtile: 'Ce classement représente le total des événements de toutes les flottes',
            fleet_total_subtile: "Ce graphique représente le total d'événements de la flotte sélectionnée",
            time_range_subtile: "Les bandes sur ce graphique indiquent les moments de la journée où l'occurrence d'événements est la plus élevée",
            heat_map_subtitle: "Les vagues de chaleur sur ce graphique indiquent les endroits avec la plus grande occurrence d'événements",
        },
        events: {
            events: 'Événements',
        },
        intervention_protocol: {
            select_the_option: `Sélectionnez l'option`,
            intervention_protocol: `Protocole d'intervention`,
            protocol: 'Protocole',
            group_whatsapp: 'Groupe WhatsApp',
            observation: 'Observation',
            enter_the_name_of_the_operation: `entrez le nom de l'opération`,
            enter_protocol_name: 'entrez le nom du protocole',
            enter_group_name: 'entrez le nom du groupe',
            type_the_note: 'tape la note',
            enter_event_name: `entrez le nom de l'événement`,
            to_accept: 'Accepter',
            cancel: 'Annuler',
            to_add: 'Ajouter',
            add_protocol: 'Ajouter un protocole',
            add_details: 'Ajouter des détails',
            delete_protocol: 'Supprimer le protocole',
            do_you_want_to_delete_the_protocol: 'voulez-vous supprimer "le protocole"',
            operation: 'Opération',
            protocol_details: 'Détails du protocole',
            edit_protocol: 'Modifier le protocole',
            save: 'Sauvegarder',
            event: 'Événement',
            amount: 'Montant',
            enter_the_amount: 'Entrez le montant',
            time: 'Temps',
            enter_the_time: `Entrez l'heure`,
            wait: 'Attendez (h)',
            enter_the_wait: `Entrez dans l'attente`,
            send_image: `Envoyer l'image`,
            send_video: 'Envoyer une vidéo',
            edit_details: 'Modifier les détails',
            back: 'Dos',
            advance: 'Avance',
            to_close: 'Fermer',
            want_to_delete_the_protocol_event_protocol_name: `souhaitez-vous exclure l'événement de protocole «NOM DU PROTOCOLE»?`,
            new_details: `Détails de l'actualité`,
            delete: 'Supprimer',
            this_action_cannot_be_undone: `Cette action ne peut pas être annulée`,
            select_the_event: `Sélectionnez l'événement`,
            confirm: 'Confirmer',
            view_protocol: 'Afficher le protocole',
        },
        drivers: {
            drivers: 'Conducteurs',
            driver_history: 'Historique du pilote',
            images: 'Images',
            id: 'Id',
            operation: 'Opération',
            fleet: 'Flotte',
            event: 'Événement',
            day: 'Jour',
            alarm_hour: `Heure de l'alarme`,
            latitude: 'Latitude',
            longitude: 'Longitude',
            id_cluster: 'Id Cluster',
            driver: 'Conducteur',
            document: 'Document',
            time_range: 'Intervalle de temps',
            heat_map: 'Carte de chaleur',
            history_of_events: 'Historique des événements',
            subtitle_history_of_events: 'Les points sur cette carte indiquent les endroits où les événements se produisent le plus fréquemment.',
            subtitle_time_range: 'Les bandes sur ce graphique indiquent les moments où les événements se produisent le plus souvent.',
            subtitle_history_events: `Les données du tableau montrent l'historique des événements.`,
            driver_ranking: 'Classement des pilotes',
            search_by_id_cluster: `Recherche par cluster d'identifiant`,
            search_by_image: 'Recherche par image',
            upload_image: 'Télécharger une image',
            click_on_a_line: `Cliquez sur une ligne pour afficher l'historique du pilote sélectionné.`,
            period: 'Période',
            week: 'Semaine',
            month: 'Mois',
            year: 'Année',
            hours_per_bar: 'Heures par bar',
            coordinates: 'Coordonnées',
            polar: 'Polaire',
            cartesian: 'Cartésien',
            events: 'Événements',
            select_the_event: 'Sélectionner ou un événement',
            ray: 'Raio',
            blur: 'Blur',
            subtitle_driver_history: `Les données de ce graphique indiquent le nombre total d'événements de conducteur en fonction de la période.`,
            need_to_select_driver: 'Sélectionnez un pilote dans le classement ci-dessus pour afficher les détails du pilote',
            photos_legends: 'Photos des événements',
            file_text: 'Aucun fichier sélectionné.',
            search: 'Rechercher',
            no_face_founded: "Aucun visage n'a été détecté dans l'image.",
            try_again: 'Essayer à nouveau',
            driver_founded_no_events: 'Visage détecté mais aucun pilote trouvé.',
            similarity: 'Similarité',
            type: 'Taper',
            bar: 'Bar',
            line: 'Doubler',
            heat_map_subtitle: "Les vagues de chaleur sur ce graphique indiquent les endroits avec la plus grande occurrence d'événements",
        },
        last_alarm: {
            last_alarm: 'Dernière Alarme',
            operation: 'Opération',
            fleet: 'Flotte',
            last_communication: 'Dernière Communication',
            days_since_the_last_communication: 'Dernière communication  (jours)',
            days_since_the_last_alarm: 'Dernière alarme (jours)',
            status: 'Status',
            proportion: 'Proportion',
            table: 'Table',
            subtitle_pie_chart: `Ce graphique représente la proportion d'événements dans la période sélectionnée.`,
            subtitle_bar_chart: `Ce graphique montre l'état de la communication par opération et toutes les flottes qui s'y trouvent.`,

            informative: 'Informatif',
            a_fleet_is_classified_as: 'Une flotte est classée comme suit:',
            no_communication: `Aucune communication s'il n'y a pas eu de communication depuis plus de 6 jours;`,
            communicating_without_event: `Ne signaler aucun événement s'il l'a signalé au cours des 6 derniers jours mais n'a présenté aucune alarme au cours de cette période;`,
            if_you_are_communicating: `D'ACCORD! si vous avez communiqué et reçu des alarmes au cours des 6 derniers jours.`,
        },
        comparison_of_units: {
            comparison_of_units: 'Comparaison des unités',
            country: 'Pays',
            week: 'Semaine',
            yawn: 'Bâillement',
            drowsinessN1: 'Somnolence N1',
            drowsinessN2: 'Somnolence N2',
            cellPhone: 'Téléphone portable',
            smoking: 'Fumeur',
            occlusion: 'Occlusion',
            lookingDownN1: 'Regarder vers le bas N1',
            lookingDownN2: 'Regarder vers le bas N2',
            drowsinessIndex: 'Indice de somnolence',
            distractionIndex: 'Indice de distraction',
            telemetryIndex: 'Indice de télémétrie',
            generalIndex: 'Indice général',
            averages_per_period: 'Moyennes par période',
            comparison: 'Confronto',
            the_vehicles_column_is_the_maximum: 'La colonne <strong>Véhicules</strong> indique le nombre maximum de véhicules circulant chaque jour.',
            each_event_column_contains_the_total_number:
                "Chaque colonne d'événements contient le total des événements de la période divisé par le nombre de véhicules obtenu ci-dessus, lui-même divisé par le nombre de jours actifs.",
            we_have_the_following_formulas: 'Nous avons les formules suivantes:',
            fatigue_index: 'Indice de fatigue = 10 * Somnolence N1 + 20 * Somnolence N2',
            distraction_index:
                'Indice de distraction = 15 * Téléphone portable + 5 * Tabagisme + 15 * Occlusion + 1 * Regarder vers le bas N1 + 2 * Regarder vers le bas N2 + 15 * Casque',
            indice_argus: 'Indice Argus = Indice de fatigue + Indice de distraction',
            period_vision: 'Période Vision',
            unity_vision: 'Unité Vision',
            day: 'Jour',
            month: 'Mois',
            year: 'Année',
            period: 'Période',
            operation: 'Fonctionnement',
            region: 'Région',
            column: 'Colonne',
            select_the_event: `Sélectionnez l'événement`,
            informative: 'Informatif',
        },
        tableEventName: {
            Sonolência: 'Somnolence',
            'Sonolência N1': 'Somnolence N1',
            'Sonolência N2': 'Somnolence N2',
            'Sonolência leve': 'Somnolence légère',
            'Olhando para baixo N1': 'Regarder en bas N1',
            'Olhando para baixo N2': 'Regarder en bas N2',
            Celular: 'Téléphone portable',
            'Sem cinto': 'Pas de ceinture',
            Fumando: 'Fumeur',
            Oclusão: 'Occlusion',
            Bocejo: 'Bâillement',
            'Face missing': 'Face missing',
            'Óculos de sol': 'Des lunettes de soleil',
            'Consumo alimento': 'Consommation de nourriture',
            'Fone de ouvido': 'Casque',
            'Colisão frontal': 'Collision frontale',
            'Alarme antecipado de colisão': 'Collision frontale',
            'Colisão de pedestres': 'Collision avec un piéton',
            'Alarme de colisão de passageiros': 'Collision avec un piéton',
            'Excesso de velocidade da via': 'Vitesse routière excessive',
            'Alarme de excesso de limite de sinal rodoviário': 'Vitesse routière excessive',
            'Colisão para frente de baixa velocidade': 'Collision frontale à basse vitesse',
            'Curva rápida': 'Tour rapide',
            'Curva rápida à direita': 'Tour rapide',
            'Curva rápida à esquerda': 'Tour rapide',
            'Veículo muito próximo': 'Véhicule trop proche',
            'Distância de veículos muito próxima': 'Véhicule trop proche',
            'Excesso de velocidade na faixa de pedestre': 'Vitesse excessive sur le passage pour piétons',
            'Excesso de velocidade na listra de zebra': 'Vitesse excessive sur le passage pour piétons',
            'Mudança irregular de faixa': 'Changement de voie irrégulier',
            'Linha de pressão direita da pista': 'Changement de voie irrégulier',
            'Linha de pressão esquerda da pista': 'Changement de voie irrégulier',
            'Ponto cego': 'Angle mort',
            Velocidade: 'Excès de vitesse',
            'Condução perigosa N1': 'Conduite dangereuse N1',
            'Condução perigosa N2': 'Conduite dangereuse N2',
            'DS deslocado': 'DS disloqué',
            'Dano ao DS': 'Dégâts DS',
            'Cinto irregular': 'Ceinture inégale',
            Rádio: 'Radio',
            'Falso alarme': 'Fausse alarme',
            'Tentativa de Oclusão': "Tentative d'occlusion",
            'Oclusão parcial': 'Occlusion partielle',
            'Passageiro irregular': 'Passager irrégulier',
            Teste: 'Test',
            '?': '?',
            'Óculos de sol à noite': 'Lunettes de soleil la nuit',
            Infração: 'Infraction',
            Distração: 'Distraction',
            Fadiga: 'Fatigue',
            'Excesso de Velocidade': 'Excès de vitesse',
            'Direção perigosa': 'Conduite dangereuse',
            'Direção agressiva': 'Conduite agressive',
        },
        hours: {
            in: 'dans',
            ago: 'dos',
            secondsAgo: 'il ya quelques secondes',
            seconds: 'secondes',
            minute: 'minutes',
            aMinute: 'une minute',
            hour: 'heure',
            hours: 'heures',
            oneDay: 'un jour',
            days: 'jours',
            oneWeek: 'une semaine',
            weeks: 'semaines',
            monthAgo: 'il y a un mois',
            months: 'mois',
            aYear: 'un ans',
            years: 'années',
        },
        echarts: {
            group: 'Groupe',
            total: 'Total',
        },
    },
};

export default FR;
