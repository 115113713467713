import moment from 'moment';

import { Service, api } from '../../../common';
import { OverviewDTO, OverviewRequestDTO } from '../dtos/overview';

class OverviewService implements Service<OverviewDTO, OverviewRequestDTO[]> {
    public async execute({ dateFrom, dateTo, equipament, operations }: OverviewDTO): Promise<OverviewRequestDTO[]> {
        const {
            data: { content },
        } = await api.get('/analytics/overview', {
            params: {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                equipament,
                operations,
            },
        });

        return content;
    }
}

export const overviewService = new OverviewService();
