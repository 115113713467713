import { Switch, SwitchProps } from '@mui/material';
import styled from 'styled-components';

const IOSSwitchComponent = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)({
    width: 43,
    height: 27,
    padding: '5px !important',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        marginTop: 5,
        display: 'flex',
        alignSelf: 'center',
        height: 27,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(30px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#6DA4D3',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#A9A9A9',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 27,
        height: 27,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: 'background-color 500',
    },
});

export type IOSSwitchBaseProps = SwitchProps

export default function IOSSwitch(props: IOSSwitchBaseProps) {
    return <IOSSwitchComponent  {...props}/>;
}