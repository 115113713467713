export const lastAlarms = [
    {
        name: 'Sem comunicação',
        color: '#5C3D2E',
    },
    {
        name: 'Comunicando sem evento',
        color: '#C67C5F',
    },
    {
        name: 'Ok!',
        color: '#f0b7d6',
    },
];
