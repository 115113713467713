import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Field } from 'src/common/components';
import { DateHelper } from 'src/modules/alarm-processing/helpers/date.helper';
import { useSortReviewEvent } from 'src/modules/alarm-processing/hooks';

import { EventName } from '../event-info/styles';

export default function PastEventInfo() {
    const {
        showEvent: { data: selectedEvent },
    } = useSortReviewEvent();
    const { t, i18n } = useTranslation();

    const eventName = useMemo(() => {
        if (selectedEvent?.event) {
            return selectedEvent?.event === '?' ? t('alarms.pending_analysis') : selectedEvent?.event;
        }

        return t('general.information_unavailable');
    }, [selectedEvent?.event, t]);

    return (
        <Column gap={5} align="flex-start">
            <EventName>{t(`tableEventName.${selectedEvent?.alarm}`)}</EventName>
            <Column gap={8} align="flex-start">
                <Field name={t('alarms.priority')} value={selectedEvent?.priority?.toString() || t('general.information_unavailable')} />
                <Field name={t('alarms.event')} value={eventName} warn={selectedEvent?.event === '?'} />
                <Field name={t('alarms.operation')} value={selectedEvent?.operation?.toString() || t('general.information_unavailable')} />
                <Field name={t('alarms.fleet')} value={selectedEvent?.fleet?.toString() || t('general.information_unavailable')} />
                <Field
                    name={t('alarms.alarm_time')}
                    value={
                        selectedEvent?.alarmTime ? DateHelper.format(selectedEvent.alarmTime, i18n.language, 'UTC') : t('general.information_unavailable')
                    }
                />
                <Field
                    name={t('alarms.arrival')}
                    value={
                        selectedEvent?.alarmArrivalTime
                            ? DateHelper.format(selectedEvent.alarmArrivalTime, i18n.language, 'UTC')
                            : t('general.information_unavailable')
                    }
                />
                <Field name={t('alarms.velocity')} value={selectedEvent?.speedInKmH?.toString() || t('general.information_unavailable')} />
            </Column>
        </Column>
    );
}
