import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollbar } from 'src/common';
import { Button, Dialog, Divider } from 'src/common/components';
import Column from 'src/common/components/column';
import Row from 'src/common/components/row';
import { toast } from 'src/common/components/toast';

import { useFunctions } from '../../hooks';
import { functionService } from '../../services';
import { CancelButton, DialogContent } from './styles';

export default function DeleteInBulkDialog() {
    const { showScrollBar } = useScrollbar();
    const { t } = useTranslation();
    const { handleRequestList, selectedFunctions, handleSelectAll, deleteInBulkDialogRef } = useFunctions();

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = useCallback(() => {
        document.querySelector<HTMLDialogElement>(`dialog#delete-users-bulk-dialog`)?.close();
    }, []);

    const handleConfirm = useCallback(async () => {
        const uuids = selectedFunctions?.map(user => user.id);

        setIsLoading(true);
        try {
            await functionService.deleteInBulk(uuids);
            handleSelectAll([]);
            handleRequestList({
                currentPage: 1,
            });
            showScrollBar();
            toast.success(t('general.success'), '');
            handleClose();
        } catch (e) {
            toast.error(t('general.error'), '');
        } finally {
            setIsLoading(false);
        }
    }, [handleClose, handleRequestList, handleSelectAll, selectedFunctions, showScrollBar, t]);

    const data = useMemo(() => {
        if (selectedFunctions?.length === 0) return <Fragment />;

        return (
            <Fragment>
                <Row justify="center" width="100%" align="start">
                    <h4>{t('configurations.delete_function')}</h4>
                </Row>
                <Column align="center" gap={16} flex width="100%">
                    <Column align="center">
                        <p>{t('configurations.confirm_delete_functions')}</p>
                        <p>{t('configurations.this_action')}</p>
                    </Column>
                    <Divider />
                </Column>
                <Row align="flex-end" gap={16} flex width="100%">
                    <CancelButton onClick={handleClose}>{t('general.cancel')}</CancelButton>
                    <Button isLoading={isLoading} type="reset" color="alert" onClick={handleConfirm}>
                        {t('configurations.delete')}
                    </Button>
                </Row>
            </Fragment>
        );
    }, [handleClose, handleConfirm, isLoading, selectedFunctions?.length, t]);

    return (
        <Dialog ref={deleteInBulkDialogRef} id="delete-users-bulk-dialog">
            <DialogContent>{data}</DialogContent>
        </Dialog>
    );
}
