import styled from 'styled-components';

export const GraphSection = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
`;

export const GraphContainer = styled.section`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    border-radius: 0.625rem;
    padding: 1.25rem;
    gap: 1rem;
`;

export const GraphTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    > div {
        display: flex;
        flex-direction: column;
        gap: 0.1rem;

        button {
            border: 0;
            background-color: white;
        }
    }
    p {
        font-size: 1rem;
        color: #b9b6b6;
    }
`;

export const FilterContainer = styled.section`
    align-items: center;
    font-family: 'Space Grotesk', sans-serif;

    color: #9a9a9a;
    gap: 9rem;
    align-items: center;
    display: flex;
    justify-content: space-between;

    div {
        gap: 1rem;
        display: flex;
        align-items: center;
    }

    label {
        color: #262d3c;
        font-weight: 500;
        font-size: 0.875rem;
    }
`;

export const DetailsSection = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const DetailsContainer = styled.section`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.25rem;
    border-radius: 0.5rem;
    border: 1px solid #d9d9d9;
`;

export const PageChange = styled.section`
    div {
        padding: 5px;
    }
`;
