import { api } from 'src/common';
import { Service } from 'src/common/core/service';

class AnalystOnlineService implements Service<boolean, void> {
    public async execute(isOnline: boolean): Promise<void> {
        return isOnline ? await api.post(`/analyst/online`) : await api.delete('/analyst/online');
    }
}

export const analystOnlineService = new AnalystOnlineService();
