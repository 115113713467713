import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export interface FlexBoxProps extends HTMLAttributes<HTMLDivElement> {
    direction: 'row' | 'column';
    align?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end';
    flex?: boolean;
    gap?: string | number;
    height?: string;
    justify?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between';
    size?: string;
    width?: string;
    padding?: string;
}

export const FlexBox = styled.div<FlexBoxProps>`
    align-items: ${props => props?.align};
    display: flex;
    flex: ${props => props?.flex && '1'};
    flex-direction: ${props => props.direction};

    height: ${props => props?.size || props?.height || 'auto'};
    justify-content: ${props => props?.justify};
    padding: ${props => props?.padding};
    width: ${props => props?.size || props?.width || 'auto'};

    ${props =>
        props?.direction === 'row' &&
        css`
            flex-wrap: wrap;
        `}

    ${props =>
        props?.gap &&
        css`
            gap: ${typeof props.gap === 'string' ? props.gap : `${props.gap}px`};
        `};
`;
