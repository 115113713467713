import { PropsWithChildren, createContext, useCallback, useMemo, useReducer, useState } from 'react';
import { Pagination } from 'src/common/core/pagination';
import { CheckboxHelper } from 'src/common/helpers';
import { Reducer, reducer } from 'src/common/types/reducer';

import { DepartmentResume } from '../../departments';
import { CreatePasswordPolicyFormDTO, ListPasswordPoliciesDTO, PasswordPolicy } from '../dtos';
import { INITIAL_DEPARTMENT_STATE } from './const';
import { defaultValues, getDefaultCreateOrUpdateValues } from './default-form-values';
import { PasswordPoliciesContextProps } from './types';

export const PasswordPoliciesContext = createContext({} as PasswordPoliciesContextProps);

export default function PasswordPoliciesProvider({ children }: PropsWithChildren) {
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_DEPARTMENT_STATE);
    const [initialFormData, setInitialFormData] = useState<CreatePasswordPolicyFormDTO>(() => getDefaultCreateOrUpdateValues(null));
    const [filters, setFilters] = useState<ListPasswordPoliciesDTO>(defaultValues);
    const [passwordPolicies, setPasswordPolicies] = useState<Pagination<PasswordPolicy> | null>(null);
    const [selectedValues, setSelectedValues] = useState<Array<PasswordPolicy>>([]);

    const allOnThisPageAreSelected: boolean = useMemo(
        () => CheckboxHelper.allOnThisPageAreSelected(passwordPolicies?.elements, selectedValues),
        [selectedValues, passwordPolicies?.elements],
    );

    const handleSelect = useCallback((data: PasswordPolicy) => {
        setSelectedValues(state => CheckboxHelper.select(data, state));
    }, []);

    const handleSelectAll = useCallback((data: Array<PasswordPolicy>) => {
        setSelectedValues(state => CheckboxHelper.selectAll(data, state));
    }, []);

    const data: PasswordPoliciesContextProps = useMemo<PasswordPoliciesContextProps>(
        () => ({
            allOnThisPageAreSelected,
            filters,
            handleSelect,
            handleSelectAll,
            initialFormData,
            operations,
            passwordPolicies,
            selectedValues,
            setFilters,
            setInitialFormData,
            setOperations,
            setPasswordPolicies,
            setSelectedValues,
        }),
        [allOnThisPageAreSelected, filters, handleSelect, handleSelectAll, initialFormData, operations, passwordPolicies, selectedValues],
    );

    return <PasswordPoliciesContext.Provider value={data}>{children}</PasswordPoliciesContext.Provider>;
}
