import { DateHelper } from 'src/common';
import { ApiResponse } from 'src/common/core/api-response';
import { Pagination } from 'src/common/core/pagination';
import { Service } from 'src/common/core/service';
import { TransformRequestHelper } from 'src/common/helpers/transform-request.helper';
import { api } from 'src/common/infra/http';
import { EventDTO, EventRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';

class ListEventsService implements Service<EventRequestDTO, Pagination<EventDTO>> {
    public async execute({ pageSize, currentPage, ...request }: EventRequestDTO) {
        if (!request.initialDate) {
            request.initialDate = DateHelper.yesterday();
        }

        if (!request.finalDate) {
            request.finalDate = new Date();
        }

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<EventDTO>>>('/alarms-processing', {
            params: {
                ...request,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
            timeout: 300000,
        });

        return content;
    }
}

export const listEvents = new ListEventsService();
