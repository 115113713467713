import { t } from 'i18next';

import ErrorServer from '../../../assets/error-server.svg';
import { Container } from './styles';

export function InternalServerError() {
    return (
        <>
            <Container>
                <img src={ErrorServer} />
                <a href="/">{t('general.back_to_home')}</a>
            </Container>
        </>
    );
}
