import { COGNITO_DATA } from './consts';
export const amplifyConfig = {
    region: COGNITO_DATA.region,
    userPoolId: COGNITO_DATA.userPoolId,
    userPoolWebClientId: COGNITO_DATA.clientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    Storage: 'LocalStorage',
    oauth: {
        domain: COGNITO_DATA.domain.substring(8),
        redirectSignIn: COGNITO_DATA.redirect,
        redirectSignOut: COGNITO_DATA.redirect + '/login',
        scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        responseType: 'token',
    },
};
