import styled from 'styled-components';

export const ChartContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem 1rem 2.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 0.6rem;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    div {
        display: flex;
        gap: 0.5rem;
        > button {
            border: 0;
            background-color: white;
        }
    }
`;

export const DefaultText = styled.span`
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: #262d3c;
`;

export const RadioText = styled(DefaultText)`
    font-size: 1rem;
`;

export const GraphTable = styled.div`
    div {
        padding: 3px;
    }
    .tableFilterDiv {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;

        .radioButton {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
    }
`;
