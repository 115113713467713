import { Fragment } from 'react';
import { Column, Dialog } from 'src/common/components';

import { useReviewRequest } from '../../hooks';
import { ReviewRequestModal } from '../review-request-modal';

export function ReviewRequestDialog() {
    const { reviewRequestDialogRef, dialogIsOpen, handleOpenDialog } = useReviewRequest();

    return (
        <Dialog onCloseModal={() => handleOpenDialog(null, 0)} ref={reviewRequestDialogRef} id="review-request-dialog">
            <Column width="656px">{dialogIsOpen ? <ReviewRequestModal /> : <Fragment />}</Column>
        </Dialog>
    );
}
