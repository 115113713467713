import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Column } from 'src/common/components';
import { FeedbackProps } from 'src/common/types';
import { DepartmentResume, departmentService } from 'src/modules/configurations/departments';

import { GetElectronicFenceDTO } from '../../dtos/get-electronic-fence.dto';
import { useElectronicFence } from '../../hooks';
import { Container, Filter } from './styles';

export default function ElectronicFenceFilter() {
    const { setOperations, operations, filter, setFilter } = useElectronicFence();
    const { t } = useTranslation();

    const {
        setValue,
        register,
        watch,
        formState: { errors },
        handleSubmit: onSubmit,
    } = useForm<GetElectronicFenceDTO>({
        mode: 'onChange',
        defaultValues: filter,
    });

    const handleSubmit = useCallback(
        (data: GetElectronicFenceDTO) => {
            const { operation } = data;
            setFilter(state => ({ ...state, operation, text: '', currentPage: 1 }));
        },
        [setFilter],
    );

    const feedback = (key: keyof GetElectronicFenceDTO): FeedbackProps => {
        return {
            message: t(errors[key]?.message || '')?.toString(),
        };
    };

    const operation = watch('operation');

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 15,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations]);

    return (
        <Container align="flex-start">
            <Filter onSubmit={onSubmit(handleSubmit)}>
                <Autocomplete
                    label={String(t('alarms.operation'))}
                    isLoading={operations.isLoading}
                    options={operations.data.map(operation => ({ ...operation, value: operation.name }))}
                    nextElement={{
                        id: 'electronic-fence-submit-button',
                    }}
                    feedback={feedback('operation')}
                    placeholder={String(t('alarms.operation'))}
                    setValue={setValue}
                    type="text"
                    {...register('operation')}
                />
                <Column justify="flex-end" height="60px">
                    <Column justify="flex-end" height="36px">
                        <Button type="submit" size="small" id="electronic-fence-submit-button">
                            {t('general.apply')}
                        </Button>
                    </Column>
                </Column>
            </Filter>
        </Container>
    );
}
