import { ButtonHTMLAttributes } from 'react';
import { CgClose } from 'react-icons/cg';
import styled from 'styled-components';

const Button = styled.button`
    border: 0;
    box-sizing: border-box;
    height: 30px;
    padding: 0;
    width: 30px;

    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &:hover {
        background-color: buttonface;
    }
`;

export default function CloseButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <Button {...props}>
            <CgClose color="#4D4D4D" size={20} />
        </Button>
    );
}
