import ReactEcharts, { EChartsReactProps } from 'echarts-for-react';
export interface BarPolarDataProps {
    color?: string;
    data: number[];
    label: string;
}

export interface BarPolarProps extends Omit<EChartsReactProps, 'option'> {
    dataY: string[];
    dataX: BarPolarDataProps[];
}

export function BarPolar({ dataY: hours, dataX: data, ...rest }: BarPolarProps) {
    const option = {
        legend: {
            type: 'scroll',
            data: data.map(a => a.label),
            top: -5,
        },
        grid: {
            containLabel: true,
            top: 66,
        },
        angleAxis: {
            type: 'category',
            data: hours,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        radiusAxis: {},
        polar: {},
        series: data.map(d => {
            return {
                type: 'bar',
                stack: 'total',
                coordinateSystem: 'polar',
                itemStyle: {
                    color: d.color,
                },
                data: d.data,
                name: d.label,
                z: 0,
            };
        }),
    };

    return <ReactEcharts notMerge={true} option={option} style={{ width: '100%', height: '100%' }} {...rest} />;
}
