import styled from 'styled-components';

const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;

    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    position: relative;

    &::after {
        content: '';

        border-radius: 0.25rem;
        background-color: #f1f1f1;
        position: absolute;
        opacity: 0.4;
    }

    &:hover::after {
        width: 2rem;
        height: 2rem;
    }
`;

interface CloseRoundProps {
    onClose: () => void;
}

export default function CloseRound({ onClose }: CloseRoundProps) {
    return (
        <CloseButton type="button" onClick={onClose}>
            <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1L1 13M1 1l12 12" stroke="#5F5F5F" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </CloseButton>
    );
}
