import { useId, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Column, VideoPlayer } from 'src/common/components';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { DateHelper } from 'src/modules/alarm-processing/helpers/date.helper';
import { useEvents, useSortReviewEvent } from 'src/modules/alarm-processing/hooks';
import { useAuth } from 'src/modules/auth';
import { userProfile } from 'src/modules/auth/constants/user-profile';
import { CSSProperties } from 'styled-components';

import { criticalCustomers } from '../../constants/critical-customers';
import { criticalOperations } from '../../constants/critical-operations';
import { ButtonsWrapper, CardBody, CardBox, CardImage, Field, FieldValue, Grid, Tag } from './styles';

interface AlarmCardProps {
    data: EventDTO;
}

export default function AlarmCard({ data }: AlarmCardProps) {
    const checkboxRef = useRef<HTMLInputElement>(null);
    const id = useId();

    const { eventScreenType } = useEvents();
    const { handleSelectEvent, selectedEvents } = useSortReviewEvent();
    const { t, i18n } = useTranslation();
    const { session } = useAuth();

    const checked = useMemo(() => selectedEvents.findIndex(event => event.id === data.id) !== -1, [data.id, selectedEvents]);

    const isAnalyst = useMemo(() => {
        return session?.user.profile === userProfile.analyst;
    }, [session?.user.profile]);

    const styleCriticalCustomer: CSSProperties = {
        border: '4px solid #d50000',
        fontWeight: '600',
        color: '#1D1D1D',
    };

    const criticalEvent = useMemo<boolean>(() => {
        if (!isAnalyst) {
            return false;
        }

        if (data.customer && criticalCustomers.includes(data.customer)) {
            return true;
        }

        if (data.operation && criticalOperations.includes(data.operation)) {
            return true;
        }

        return false;
    }, [data.customer, data.operation, isAnalyst]);

    return (
        <CardBox style={criticalEvent ? styleCriticalCustomer : undefined}>
            <ButtonsWrapper>
                <Checkbox
                    ref={checkboxRef}
                    className="checkbox-absolute"
                    mode={'blue-check'}
                    name={id}
                    checked={checked}
                    onChange={() => handleSelectEvent(data)}
                />
            </ButtonsWrapper>

            {data.videoUrl ? (
                <VideoPlayer
                    source={data.videoUrl}
                    type="video/mp4"
                    autoPlay
                    muted
                    loop
                    style={{
                        width: '100%',
                        height: '12rem',
                        objectFit: 'fill',
                        borderRadius: '4px 4px 0px 0px',
                    }}
                    onClick={() => checkboxRef?.current?.click()}
                />
            ) : (
                <CardImage
                    src={data.photoUrl || undefined}
                    alt={data.alarm || 'Genereic image'}
                    style={{
                        cursor: 'fill',
                    }}
                    onClick={() => checkboxRef?.current?.click()}
                />
            )}

            <CardBody onClick={() => handleSelectEvent(data)}>
                {data.priority ? <Tag style={{ marginBottom: '0.5rem' }}>{data.priority}</Tag> : null}
                <Grid>
                    <Column style={{ gridRow: 1, gridColumn: '1 / span 2' }}>
                        <Field>{eventScreenType === 'events' ? t('alarms.event') : t('alarms.alarm')}</Field>
                        <FieldValue>
                            {eventScreenType === 'events' ? t(`tableEventName.${data.event}`) : t(`tableEventName.${data.alarm}`)}
                        </FieldValue>
                    </Column>
                    <Column>
                        <Field>{t('alarms.velocity')}</Field>
                        <FieldValue>{data.speedInKmH}</FieldValue>
                    </Column>
                    <Column>
                        <Field>{t('alarms.hour')}</Field>
                        <FieldValue>{DateHelper.format(data?.alarmTime, i18n.language, 'UTC')}</FieldValue>
                    </Column>
                    <Column>
                        <Field>{t('alarms.arrival')}</Field>
                        <FieldValue>{DateHelper.format(data?.alarmArrivalTime, i18n.language, 'UTC')}</FieldValue>
                    </Column>
                    <Column>
                        <Field>{t('alarms.fleet')}</Field>
                        <FieldValue>{data.fleet}</FieldValue>
                    </Column>
                    <Column style={{ gridRow: 4, gridColumn: '1 / span 2' }}>
                        <Field>{t('alarms.operation')}</Field>
                        <FieldValue>{data.operation}</FieldValue>
                    </Column>

                    {session?.user.profile === userProfile.analyst && (
                        <Column style={{ gridRow: 5, gridColumn: '1 / span 2' }}>
                            <Field>{t('alarms.analyst')}</Field>
                            <FieldValue>{eventScreenType === 'events' ? data.analyst : data.analyst_per_event}</FieldValue>
                        </Column>
                    )}
                </Grid>
            </CardBody>
        </CardBox>
    );
}
