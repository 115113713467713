import styled from 'styled-components';

const BaseText = styled.p`
    letter-spacing: 0em;
    text-align: left;
`;

export const ChartContainer = styled.div`
    padding: 1rem 1rem 2.5rem;
    height: 50vh;
    border: 1px solid #d9d9d9;
    border-radius: 0.6rem;
    width: 49%;
    position: relative;
    .filterDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filterButton {
        border: none;
        background-color: white;
        cursor: pointer;
    }

    .filters {
        position: relative;
        margin-left: 1.5625rem;
    }

    .radioInput {
        display: flex;
        flex-direction: column;
        align-items: initial;
        padding: 0.4rem 0 0.6rem;
    }

    .radioInputGraphs {
        display: flex;
        align-items: initial;
        margin: 0.5rem 0;
    }

    .heatMapInputs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        gap: 2rem;

        div {
            width: 100%;
        }
    }

    .lineChart {
        height: 400px;
        margin-top: -1.875rem;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    > div {
        display: flex;
        flex-direction: column;
        gap: 1.3rem;

        > button {
            border: 0;
            background-color: white;
        }
    }

    .historic {
        *:nth-child(1) {
            height: auto;
        }
    }
`;

export const TableEventHistoryEvents = styled.div`
    margin-top: 1rem;
    height: 72%;
`

export const ButtonTab = styled.button`
    transition: 0.3s ease-in all;

    &:hover {
        background-color: #262d3c;
        color: #fff;
    }
`
export const FilterTimeRangeRadioContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const ChartSubTitle = styled(BaseText)`
    font-size: 0.813rem;
    font-weight: 500;
    line-height: -1.25rem;
    margin-top: -20px;
    color: #b9b6b6;
`;

export const WideCardSection = styled.section`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0rem 1rem 2.5rem;
`;

export const HistoryContainer = styled.div`
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.25rem;
    border-radius: 0.5rem;
    border: 1px solid #d9d9d9;

    .tableFilterDiv {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;

        .radioButton {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
    }
`;

export const FilterContainer = styled.div`
    width: 100%;
    height: 50vh;
    border-radius: 0.5rem;
    border: 1px solid #d9d9d9;
`;

export const ChartContent = styled.div`
    height: 85%;
    margin-top: 1rem;
    margin-right: 1rem;
`;

export const ImagesContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 1.25rem;
    border-radius: 0.5rem;
    border: 1px solid #d9d9d9;
    overflow-y: hidden;
`;

export const RankingFilter = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
`;

export const ButtonChangeContainer = styled.div`
    display: flex;
    padding: 5px;

    > button {
        padding: 0.625rem;
        background-color: #e6e6e6;
        font-size: 20px;
        font-weight: 500;
        border: 0;
        margin-right: 5px;
    }

    button:nth-child(1) {
        border-radius: 10px 0 0 0;
    }

    button:nth-child(3) {
        border-radius: 0 10px 0 0;
    }
`;

export const UploadImageSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
    align-items: center;
    justify-content: center;
    height: 100%;
    span {
        color: #b9b6b6;
        text-align: center;
    }
`;

export const PageChange = styled.section`
    div {
        padding: 5px;
    }
`;

export const DefaultText = styled.span`
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: #262d3c;
`;

export const RadioText = styled(DefaultText)`
    font-size: 1rem;
`;

export const GraphSection = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
`;

export const GraphContainer = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: 1px solid #d9d9d9;
    border-radius: 0.625rem;
    padding: 1.25rem;
    position: relative;
`;

export const GraphTitle = styled.div`
    display: flex;
    flex-direction: column;

    > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    p {
        font-size: 1rem;
        color: #b9b6b6;
    }
`;

export const ButtonFilter = styled.button`
    background: transparent;
    border: none;
`;

export const ModalFilter = styled.div`
    position: absolute;
    right: 0;

    .heatMapInputs {
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        margin-bottom: 1rem;
        gap: 2rem;

        div {
            width: 100%;
        }
    }
`;

export const FilterOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-bottom: 0.3rem ;

    p {
        font-weight: 500;
    }

    label {
        font-weight: 400;
    }
`;

export const RadioButtonContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;
