import { GoogleMap, MarkerClusterer } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, Skeleton, useMap } from '../../../../common';
import Column from '../../../../common/components/column';
import { InterventionRequestDTO } from '../../../interventions/dtos/intervention.dto';
import { CustomMarker } from './CustomMarker';

interface InterventionsMapProps {
    data: Array<InterventionRequestDTO>;
}
export function InterventionsMap({ data }: InterventionsMapProps) {
    const { isLoaded, loadError } = useMap();
    const { t } = useTranslation();

    if (loadError) return <ErrorMessage>{t('general.error')}</ErrorMessage>;

    if (!isLoaded) {
        return (
            <Column align="center" justify="center" gap={4} width="742px" height="450px">
                <Skeleton />
            </Column>
        );
    }

    return (
        <GoogleMap
            zoom={13}
            center={{
                lat: data[data.length - 1]?.latitude || -22.7253017,
                lng: data[data.length - 1]?.longitude || -47.6532601,
            }}
            mapContainerStyle={{
                width: '100%',
                height: '100%',
                borderRadius: 8,
            }}
            options={{
                gestureHandling: 'greedy',
            }}
        >
            <MarkerClusterer>
                {clusterer => (
                    <div>
                        {data.map((intervention, index) => {
                            return (
                                <CustomMarker key={intervention.id} clusterer={clusterer} intervention={intervention} isShowStatus={index === 0} />
                            );
                        })}
                    </div>
                )}
            </MarkerClusterer>
        </GoogleMap>
    );
}
