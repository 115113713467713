import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Column,
    Divider,
    Row,
    Title,
    SelectNumber,
    listPagination,
    Pagination,
    CardHeader,
    Content,
    Skeleton,
    NoContent,
    MainCard,
    useSearch,
    useEventListener,
    RenderIf,
    Plus,
    Select,
} from '../../../common';
import UpdateData from '../../../common/components/update-data';
import RegistrationShipmentsDeleteDialog from '../../../modules/configurations/registration-shipments/components/registration-shipments-delete-dialog';
import RegistrationShipmentsDialog from '../../../modules/configurations/registration-shipments/components/registration-shipments-dialog';
import TableList from '../../../modules/configurations/registration-shipments/components/table-list';
import { useRegistrationShipments } from '../../../modules/configurations/registration-shipments/hooks/use-registration-shipments';

const optionsFilterShippingType = [
    {
        label: String(t('registration_shipments.intervention')),
        value: 'intervention',
    },
    {
        label: String(t('registration_shipments.contacts')),
        value: 'contacts',
    },
];

export function RegistrationShipments() {
    const { t } = useTranslation();

    const { formRef, clear } = useSearch();
    const { handleGetList, responseStatus, requestData, registrations, handleRequestList, handleOpenDialog } = useRegistrationShipments();

    const handleSelectedShippingTypeChange = () => {
        console.log('oi');
    };

    const handleUpdate = useCallback(async () => {
        handleGetList();
    }, [handleGetList]);

    const content = useMemo(() => {
        if (responseStatus?.loading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return <TableList />;
    }, [handleGetList, responseStatus?.hasError, responseStatus?.loading, responseStatus?.void, t]);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();

            const { target } = e;

            const currentTarget = target as unknown as HTMLFormElement;

            const text: HTMLInputElement = currentTarget?.elements?.namedItem('text') as HTMLInputElement;

            handleRequestList({
                currentPage: 1,
                name: text.value,
            });
        },
        [handleRequestList],
    );

    useEffect(() => {
        handleGetList();
    }, [handleGetList]);

    useEffect(() => {
        return () => {
            handleRequestList({
                currentPage: 1,
                pageSize: 15,
                name: '',
            });
            clear();
        };
    }, [clear, handleRequestList]);

    useEventListener(formRef.current, 'submit', handleSearch);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between" padding="10px">
                <Title>
                    <h2>{t('general.configurations')}</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
                <Row gap={16} style={{ width: 'max-content' }}>
                    <Button type="button" size="medium" leftIcon={<Plus width={12.63} height={12.63} />} onClick={() => handleOpenDialog()}>
                        {t('registration_shipments.add_chance')}
                    </Button>
                </Row>
            </Row>

            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>
                                {t('registration_shipments.registration_of_report_submissions')}
                                {responseStatus?.success && !responseStatus?.loading && (
                                    <span className="total">({registrations?.totalElements || 0})</span>
                                )}
                            </h3>
                        </Row>
                        <Row align="center" gap={16}>
                            <Row align="center" gap={8}>
                                <span className="titleSelect">{t('registration_shipments.shipping_type')}:</span>
                                <Select
                                    name="events"
                                    options={optionsFilterShippingType}
                                    placeholder={String(t('registration_shipments.select_type'))}
                                    onChange={handleSelectedShippingTypeChange}
                                />
                            </Row>

                            <Row align="center" gap={8}>
                                <span className="per-page">{t('general.items_per_page')}:</span>
                                <SelectNumber
                                    fontMode="small"
                                    value={requestData?.pageSize || 15}
                                    options={listPagination}
                                    onChange={e => handleRequestList({ pageSize: Number(e.target.value), currentPage: 1 })}
                                />
                            </Row>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {content}
                    <Divider />
                    <RenderIf condition={!!registrations?.totalElements}>
                        <Pagination
                            perPage={requestData?.pageSize}
                            total={registrations?.totalElements}
                            onPageChange={currentPage => handleRequestList({ currentPage })}
                            currentPage={requestData?.currentPage}
                        />
                    </RenderIf>
                </Column>
            </MainCard>
            <RegistrationShipmentsDialog />
            <RegistrationShipmentsDeleteDialog />
        </Column>
    );
}
