import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    color: #b2d5f2;
    margin-left: 40px;
`;
