import { Coordinate } from '../../domain/constants/coordinates';
import { EventExpiresIn } from '../../domain/constants/event-expires-in';
import { GeometricShape } from '../../domain/constants/geometric-shape';
import { CreateElectronicFenceFormDTO } from '../../dtos/create-electronic-fence.dto';
import { ElectronicFenceFormData } from './types';

export const getDefaultCreateOrUpdateValues = (data: ElectronicFenceFormData | null): CreateElectronicFenceFormDTO => ({
    active: !data?.active ? 'false' : 'true',
    color: data?.color || undefined,
    colorTransparencyPercentage: data?.colorTransparencyPercentage || undefined,
    coordinates: data?.coordinates as Array<Coordinate>,
    createdAt: data?.createdAt ? new Date(data?.createdAt)?.toDateString() : undefined,
    expirationDate: data?.expirationDate ? data?.expirationDate?.toString().split('T')[0] : undefined,
    expiresIn: data?.expiresIn || EventExpiresIn.NEVER,
    generateEventBy: data?.generateEventBy || undefined,
    geometricShape: data?.geometricShape as GeometricShape,
    id: data?.id,
    name: data?.name || '',
    observation: data?.observation || '',
    operation: data?.operation || '',
    operationId: data?.operationId,
    radius: data?.radius || 0,
    zoom: data?.mapZoom || undefined,
});
