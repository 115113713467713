import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Column, Divider, Image, Row } from 'src/common/components';
import { toast } from 'src/common/components/toast';
import { usePermission, useScrollbar } from 'src/common/hooks';
import { SortAlarmRequestDTO, SortMultipleAlarmsRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { useEvents, useSortReviewEvent } from 'src/modules/alarm-processing/hooks';
import { sortAlarmSchema } from 'src/modules/alarm-processing/infra';

import ScrollRow from '../../scroll-row';
import EventInfo from '../event-info';
import SortAndReviewFormFields from '../sort-review-form-fields';
import { MediumText, Number } from '../styles';
import { Form, ImageWrapper, StyledVideoPlayer } from './styles';

interface TabSortAndReviewAlarmProps {
    onClose(): void;
}

function AlarmLeftInfo() {
    const { selectedEvents } = useEvents();
    const { handleShowEvent, showEvent } = useSortReviewEvent();

    useEffect(() => {
        handleShowEvent({
            data: selectedEvents?.[0] || null,
            index: 0,
        });
    }, [handleShowEvent, selectedEvents]);

    return showEvent?.data ? (
        <Column gap={32} align="flex-start" key={String(showEvent?.data.id)}>
            <ImageWrapper>
                <Image src={showEvent?.data?.photoUrl || undefined} alt={showEvent?.data?.alarm || 'Generic image'} height={190} width={328} />
            </ImageWrapper>
            {showEvent?.data?.videoUrl ? (
                <StyledVideoPlayer type="video/mp4" source={showEvent?.data?.videoUrl} autoPlay loop muted style={{ objectFit: 'fill' }} />
            ) : (
                <ImageWrapper>
                    <Image alt="" height={190} width={328} style={{ objectFit: 'cover' }} />
                </ImageWrapper>
            )}
            <EventInfo />
        </Column>
    ) : (
        <></>
    );
}

export default function TabSortAndReviewAlarm({ onClose }: TabSortAndReviewAlarmProps) {
    const { eventScreenType, handleRequestList, selectedEvents, handleRemoveSelectedsEvents } = useEvents();
    const { has } = usePermission();
    const { handleSortAlarms, handleSelectAll, alarmEvent, handleEventChecked, handleDescriptionDetails, handleExtraEventChecked } = useSortReviewEvent();
    const { showScrollBar } = useScrollbar();
    const { t } = useTranslation();

    const [fakeLoading, setFakeLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const sortAlarmSchemaMemo = useMemo(() => sortAlarmSchema(t), [t]);

    const eventsHasAnyTreated = useMemo(() => {
        if (selectedEvents?.length === 1) return false;

        const filteredArr = selectedEvents?.filter(el => el.analyst !== null && !!el.event) || [];

        return filteredArr?.length > 0;
    }, [selectedEvents]);

    const formProps = useForm<Omit<SortAlarmRequestDTO, 'id'>>({
        resolver: yupResolver(sortAlarmSchemaMemo),
        defaultValues: {
            details: '',
            event: '',
            extraEvents: [],
        },
    });

    const eventList = useMemo(() => {
        if (selectedEvents?.length && selectedEvents?.length > 0) {
            return (
                selectedEvents?.length > 1 && (
                    <Column gap={8} align="flex-start" width="100%">
                        <Row gap={8} align="flex-start">
                            <MediumText>{t('general.selected_alarms')}</MediumText>
                            <Number>({selectedEvents?.length})</Number>
                        </Row>
                        <ScrollRow />
                    </Column>
                )
            );
        }

        return <Fragment />;
    }, [selectedEvents?.length, t]);

    const handleResetForm = useCallback(() => {
        handleEventChecked('');

        formProps.reset();
        onClose();
    }, [handleEventChecked, formProps, onClose]);

    const onSubmit = useCallback(
        async (data: Omit<SortMultipleAlarmsRequestDTO, 'ids'>) => {
            if (!selectedEvents?.length) return;

            if (isLoading) return;

            setIsLoading(true);

            const ids = selectedEvents?.map(event => event.id);

            try {
                await handleSortAlarms({
                    ids,
                    ...data,
                });

                toast.success(t('general.success'), '');
                formProps.reset();
                handleEventChecked('');
                handleSelectAll([]);
                handleDescriptionDetails('');
                handleExtraEventChecked([]);

                if (eventScreenType === 'events') {
                    handleRequestList({
                        currentPage: 1,
                    });
                } else {
                    handleRemoveSelectedsEvents(ids);
                }

                showScrollBar();
                document.querySelector<HTMLDialogElement>('dialog#alarm-details-dialog')?.close();
            } catch (e) {
                toast.error(t('general.error'), '');
            } finally {
                setIsLoading(false);
            }
        },
        [eventScreenType, formProps, handleDescriptionDetails, handleEventChecked, handleExtraEventChecked, handleRemoveSelectedsEvents, handleRequestList, handleSelectAll, handleSortAlarms, isLoading, selectedEvents, showScrollBar, t],
    );

    const hasAccess = useMemo(() => {
        if (eventScreenType === 'events' || eventScreenType === 'pending') {
            if (eventScreenType === 'events') {
                if (!has('review_alarm')) {
                    return false;
                }
                return true;
            }

            if (eventScreenType === 'pending') {
                if (!has('sort_alarm')) {
                    return false;
                }

                return true;
            }

            return true;
        }

        return false;
    }, [eventScreenType, has]);

    const formButtons = useMemo(() => {
        return (
            hasAccess && (
                <Row align="flex-start" gap={16} flex width="100%">
                    <Button color="alert" type="reset" onClick={handleResetForm}>
                        {t('general.cancel')}
                    </Button>
                    <Button disabled={eventsHasAnyTreated || !alarmEvent || fakeLoading} type="submit" isLoading={isLoading}>
                        {eventScreenType === 'events' ? t('general.review') : t('general.confirm')}
                    </Button>
                </Row>
            )
        );
    }, [alarmEvent, eventScreenType, eventsHasAnyTreated, fakeLoading, handleResetForm, hasAccess, isLoading, t]);

    const formFields = useMemo(() => {
        return (
            hasAccess && (
                <SortAndReviewFormFields
                    fakeLoading={fakeLoading}
                    setFakeLoading={setFakeLoading}
                    {...formProps}
                />
            )
        );
    }, [fakeLoading, formProps, hasAccess]);

    return (
        <Fragment>
            {eventList}
            <Form onSubmit={formProps.handleSubmit(onSubmit)}>
                <Row align="flex-start" justify="center" gap={16} flex width="100%">
                    <AlarmLeftInfo />
                    {formFields}
                </Row>
                <Divider color="#E6E6E6" />
                {formButtons}
            </Form>
        </Fragment>
    );
}
