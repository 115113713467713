import { FunctionComponent } from 'src/common/types';

import EventsProvider from './events/provider';
import { PastEventsProvider } from './past-events/provider';
import SortAndReviewEventProvider from './sort-and-review/provider';
import SyntheticDataProvider from './synthetic-data/provider';

export default function AlarmProcessingProviders({ children }: FunctionComponent) {
    return (
        <EventsProvider>
            <SyntheticDataProvider>
                <SortAndReviewEventProvider>
                    <PastEventsProvider>{children}</PastEventsProvider>
                </SortAndReviewEventProvider>
            </SyntheticDataProvider>
        </EventsProvider>
    );
}
