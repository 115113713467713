import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Field } from 'src/common/components';
import { DateHelper } from 'src/common/helpers';

import { EventDTO } from '../../../dtos/event.dto';
import { Title } from './styles';

export default function MapEventLocation(event: EventDTO) {
    const { t, i18n } = useTranslation();
    const [showInfo, setShowInfo] = useState(false);

    const position: google.maps.LatLngLiteral = {
        lat: event.latitude,
        lng: event.longitude,
    };

    return (
        <Marker position={position} onClick={() => setShowInfo(state => !state)}>
            {showInfo ? (
                <InfoWindow position={position} onCloseClick={() => setShowInfo(false)}>
                    <Column gap={4} align="flex-start">
                        <Title>{t(`tableEventName.${event.event === '?' ? event.alarm : event.event}`)}</Title>
                        <Column gap={2} align="flex-start">
                            <Field
                                name={t('alarms.alarm_time')}
                                value={
                                    event?.alarmTime
                                        ? DateHelper.format(event?.alarmTime, i18n.language, 'UTC')
                                        : t('general.information_unavailable')
                                }
                            />
                            <Field name={t('alarms.velocity')} value={event?.speed ? `${event?.speed}km/h` : t('general.information_unavailable')} />
                            <Field name={t('monitoring.lat')} value={event.latitude.toString()} />
                            <Field name={t('monitoring.long')} value={event.longitude.toString()} />
                        </Column>
                    </Column>
                </InfoWindow>
            ) : null}
        </Marker>
    );
}
