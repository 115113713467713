import { memo } from 'react';

import SidebarItem from '../sidebar-item';
import { SIDEBAR_ITEMS } from '../sidebar-items';
import { ItemsList } from '../styles';

export interface SidebarItemsProps {
    displaySidebar?: boolean;
}

interface SidebarItemsListProps extends SidebarItemsProps {
    onClick(id: string): void;
    selectedItem: string;
}

function SidebarItemsList({ displaySidebar, onClick, selectedItem }: SidebarItemsListProps) {
    return (
        <ItemsList>
            {SIDEBAR_ITEMS.map(item => {
                return (
                    <SidebarItem
                        displaySidebar={displaySidebar}
                        key={item.id}
                        onClick={() => onClick(item.id)}
                        subMenuAreOpen={selectedItem === item.id}
                        {...item}
                    />
                );
            })}
        </ItemsList>
    );
}

export default memo(SidebarItemsList);
