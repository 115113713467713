import { CreateUserDTO } from '../dtos';

export const defaultValues: CreateUserDTO = {
    email: '',
    emailVerified: true,
    enabled: 'true',
    function: '',
    name: '',
    phone: '',
    isAdmin: false,
    notificationSound: false,
    passwordPolicy: '',
    profile: 'client',
};
