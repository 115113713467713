import { Column } from 'src/common/components';
import styled from 'styled-components';

export const Container = styled(Column)`
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 1rem;
`;

export const Form = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    size: 100%;
    width: 70%;
`;
