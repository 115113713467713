import styled, { css } from 'styled-components';

interface ButtonProps {
    selected?: boolean;
}

export const Button = styled.button<ButtonProps>`
    cursor: pointer;
    width: 40px;
    height: 40px;

    background-color: transparent;
    border-radius: 4px;
    border: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    ${props =>
        props?.selected &&
        css`
            .layout-line rect,
            .layout-card rect {
                stroke: #262d3c !important;
            }
        `}

    &:hover {
        background-color: #f1f1f1;

        .layout-line rect,
        .layout-card rect {
            stroke: #262d3c;
        }
    }

    .layout-line rect,
    .layout-card rect {
        stroke: #a9a9a9;
    }
`;
