import { createContext, useCallback, useMemo, useReducer, useState } from 'react';

import { DateHelper, FunctionComponent, ResponseStatus } from '../../../../common';
import { Reducer, reducer } from '../../../../common/types/reducer';
import { DepartmentResume } from '../../../configurations';
import { GenericAnalyticsFilterDTO } from '../../common/dtos/generic-filter.dto';
import { EventByFleetDTO, EventsByFleetDTO, FleetDTO, HistoricEventByFleetDTO, RankingFleetsDTO } from '../dtos/fleet.dto';
import { analyticsFleetsService } from '../services/analytics-fleets.service';
import { INITIAL_OPERATION_STATE } from './const';
import { FleetsContextProps } from './types';

export const FleetsContext = createContext({} as FleetsContextProps);

export const INIT_FILTER_STATE: GenericAnalyticsFilterDTO = {
    finalDate: new Date(),
    initialDate: DateHelper.yesterday(),
    operationInput: '',
    operations: [],
};

export function FleetsProvider({ children }: FunctionComponent) {
    const [filter, setFilter] = useState<GenericAnalyticsFilterDTO>(INIT_FILTER_STATE);
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_OPERATION_STATE);

    const [requestRankingData, setRequestRankingData] = useState<RankingFleetsDTO>({
        typeEquipament: 'DS',
        dateFrom: DateHelper.yesterday().toISOString().split('T')[0],
        dateTo: new Date().toISOString().split('T')[0],
        operations: [],
    } as RankingFleetsDTO);

    const [responseRankingStatus, setResponseRankingStatus] = useState<ResponseStatus>({
        loading: false,
    });

    const [requestEventsByFleetData, setRequestEventsByFleetData] = useState<EventsByFleetDTO>({
        dateFrom: DateHelper.yesterday().toISOString().split('T')[0],
        dateTo: new Date().toISOString().split('T')[0],
    } as EventsByFleetDTO);

    const [responseEventsByFleetStatus, setResponseEventsByFleetStatus] = useState<ResponseStatus>({
        loading: false,
    });

    const [listRankingFleets, setListRankingFleets] = useState<Array<FleetDTO> | null>(null);
    const [selectedFleet, setSelectedFleet] = useState<FleetDTO | null>(null);
    const [listEventsByFleet, setListEventsByFleet] = useState<Array<EventByFleetDTO> | null>(null);
    const [listHistoricEventsByFleet, setListHistoricEventsByFleet] = useState<Array<HistoricEventByFleetDTO> | null>(null);

    const handleGetListRankingFleet = useCallback(async () => {
        setResponseRankingStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const data = await analyticsFleetsService.getRanking({
                ...requestRankingData,
                typeEquipament: requestRankingData.typeEquipament === 'Telemetria' ? 'Geotab' : requestRankingData.typeEquipament,
                dateFrom: requestRankingData.dateFrom,
                dateTo: requestRankingData.dateTo,
            });

            setListRankingFleets(data);

            setResponseRankingStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: false,
                success: true,
            });
        } catch (error: any) {
            setResponseRankingStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestRankingData]);

    const handleGetEventsByFleet = useCallback(async () => {
        setResponseEventsByFleetStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            if (!selectedFleet?.fleet) {
                setResponseEventsByFleetStatus({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: true,
                    success: true,
                });
            }

            const analyticsFleetsRequest = analyticsFleetsService.getDataFleetByFleet({
                ...requestEventsByFleetData,
                dateFrom: requestRankingData.dateFrom,
                dateTo: requestRankingData.dateTo,
                fleet: selectedFleet!.fleet,
                typeEquipament: requestRankingData.typeEquipament === 'Telemetria' ? 'Geotab' : requestRankingData.typeEquipament,
            });

            const historicRequest = analyticsFleetsService.getDataHistoricByFleet({
                fleet: selectedFleet!.fleet,
                typeEquipament: requestRankingData.typeEquipament === 'Telemetria' ? 'Geotab' : requestRankingData.typeEquipament,
                events: requestRankingData.events,
            });

            const [data, historic] = await Promise.all([analyticsFleetsRequest, historicRequest]);

            setListEventsByFleet(data);
            setListHistoricEventsByFleet(historic);

            setResponseEventsByFleetStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: false,
                success: true,
            });
        } catch (error: any) {
            setResponseEventsByFleetStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [
        requestEventsByFleetData,
        requestRankingData.dateFrom,
        requestRankingData.dateTo,
        requestRankingData.events,
        requestRankingData.typeEquipament,
        selectedFleet,
    ]);

    const handleRequestListRankingFleet = useCallback((data: Partial<RankingFleetsDTO>) => {
        setRequestRankingData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleRequestEventsByFleet = useCallback((data: Partial<EventsByFleetDTO>) => {
        setRequestEventsByFleetData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleSelect = useCallback((data: FleetDTO) => {
        setSelectedFleet(data);
    }, []);

    const reset = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(true);
        setOperations({
            type: 'data',
            payload: [],
        });
    }, []);

    const handleFilterIsOpen = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const data: FleetsContextProps = useMemo(
        () => ({
            filter,
            setFilter,
            filterIsOpen,
            handleFilterIsOpen,
            operations,
            setOperations,
            reset,
            listRankingFleets,
            listEventsByFleet,
            listHistoricEventsByFleet,
            selectedFleet,
            requestRankingData,
            responseRankingStatus,
            handleRequestListRankingFleet,
            handleGetListRankingFleet,
            handleSelect,
            requestEventsByFleetData,
            responseEventsByFleetStatus,
            handleRequestEventsByFleet,
            handleGetEventsByFleet,
        }),
        [
            filter,
            filterIsOpen,
            handleFilterIsOpen,
            operations,
            reset,
            listRankingFleets,
            listEventsByFleet,
            listHistoricEventsByFleet,
            selectedFleet,
            requestRankingData,
            responseRankingStatus,
            handleRequestListRankingFleet,
            handleGetListRankingFleet,
            handleSelect,
            requestEventsByFleetData,
            responseEventsByFleetStatus,
            handleRequestEventsByFleet,
            handleGetEventsByFleet,
        ],
    );

    return <FleetsContext.Provider value={data}>{children}</FleetsContext.Provider>;
}
