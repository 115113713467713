import styled, { css } from 'styled-components';

interface ButtonProps {
    isActive?: boolean;
}

export const HeaderBox = styled.header`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
`;

export const Button = styled.button<ButtonProps>`
    box-sizing: border-box;

    cursor: pointer;

    width: 28px;
    height: 28px;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    border: 0;

    ${props =>
        props?.isActive &&
        css`
            background-color: #262d3c;
            border-radius: 4px;

            &.settings svg path {
                fill: #fff;
            }
        `}

    &:hover {
        background-color: #262d3c;
        border-radius: 4px;

        &.settings svg path {
            fill: #fff;
        }
    }
`;
