export function Check() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.94519" y="0.357056" width="23.9076" height="23.9076" rx="4.78152" fill="#186DEB" />
            <path
                d="M7.51978 13.1477L10.9351 16.4947L18.2782 8.12708"
                stroke="white"
                strokeWidth="2.39076"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
