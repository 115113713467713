import styled from 'styled-components';

export const DefaultText = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #333333;
`;

export const RadioText = styled(DefaultText)`
    font-size: 1rem;
`;
