import { Fragment } from 'react';

import { Dialog } from '../../../../../common';
import Column from '../../../../../common/components/column';
import { useInterventionProtocol } from '../../hooks';
import { InterventionProtocolForm } from '../intervention-protocol-form';

export function CreateUpdateInterventionProtocolDialog() {
    const { dialogIsOpen, handleOpenDialog, interventionProtocolDialogRef } = useInterventionProtocol();

    return (
        <Dialog id="intervention-protocol-dialog" onCloseModal={handleOpenDialog} ref={interventionProtocolDialogRef}>
            <Column>{dialogIsOpen ? <InterventionProtocolForm /> : <Fragment />}</Column>
        </Dialog>
    );
}
