import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { Pagination } from 'src/common/core/pagination';

import { DepartmentsContext } from '../contexts';
import { Department, ListDepartmentsDTO } from '../dtos';
import { CreateDepartmetRequestDTO } from '../dtos/create-department.dto';
import { departmentService } from '../services';

export function useCreateOperation() {
    return useMutation<void, unknown, CreateDepartmetRequestDTO>({
        mutationKey: ['createOperation'],
        mutationFn: departmentService.create,
    });
}

export function useUpdateOperation() {
    return useMutation<void, unknown, CreateDepartmetRequestDTO & { id: number }>({
        mutationKey: ['updateOperation'],
        mutationFn: departmentService.update,
    });
}

export function useListOperations() {
    return useMutation<Pagination<Department>, unknown, ListDepartmentsDTO>({
        mutationKey: ['listOperations'],
        mutationFn: departmentService.listDepartments,
    });
}

export function useOperations() {
    const context = useContext(DepartmentsContext);

    return {
        ...useMutation<Pagination<Department>, unknown, ListDepartmentsDTO>({
            mutationKey: ['operations'],
            mutationFn: departmentService.listDepartments,
            onSuccess(data) {
                context.setOperations(data);
            },
        }),
        ...context,
    };
}
