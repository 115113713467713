import { differenceInDays, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CancelButton, Column, Dialog, Divider, Image, Row, Textarea } from 'src/common/components';
import { toast } from 'src/common/components/toast';
import { useEvents, useSortReviewEvent } from 'src/modules/alarm-processing/hooks';

import { events } from '../../../../../common/constants/events';
import { reviewAlarmClientService } from '../../../services/review-alarm-client.service';
import ScrollRow from '../../scroll-row';
import PastEventInfo from '../past-event-info';
import { MediumText, Number } from '../styles';
import { ButtonOption, DialogContent, ImageWrapper, StyledVideoPlayer, Title } from './styles';

interface TabViewAlarmProps {
    onClose(): void;
}

function AlarmLeftInfo() {
    const { selectedEvents } = useEvents();
    const { handleShowEvent, showEvent } = useSortReviewEvent();

    useEffect(() => {
        handleShowEvent({
            data: selectedEvents?.[0] || null,
            index: 0,
        });
    }, [handleShowEvent, selectedEvents]);

    return showEvent?.data ? (
        <Column gap={32} align="flex-start" key={String(showEvent?.data.id)}>
            <ImageWrapper>
                <Image src={showEvent?.data?.photoUrl || undefined} alt={showEvent?.data?.alarm || 'Generic image'} height={190} width={328} />
            </ImageWrapper>
            {showEvent?.data?.videoUrl ? (
                <StyledVideoPlayer type="video/mp4" source={showEvent?.data?.videoUrl} autoPlay loop muted style={{ objectFit: 'fill' }} />
            ) : (
                <ImageWrapper>
                    <Image alt="" height={190} width={328} style={{ objectFit: 'cover' }} />
                </ImageWrapper>
            )}
        </Column>
    ) : (
        <></>
    );
}

export default function TabViewAlarm({ onClose }: TabViewAlarmProps) {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [justification, setJustification] = useState('');

    const { selectedEvents } = useEvents();
    const { t } = useTranslation();

    const handleOpenDialog = useCallback(() => {
        setDialogIsOpen(state => {
            if (state) {
                setJustification('');
                dialogRef?.current?.close();
            } else {
                dialogRef?.current?.showModal();
            }

            return !state;
        });
    }, []);

    const handleConfirm = useCallback(async () => {
        setIsLoading(true);

        try {
            if (selectedEvents && selectedEvents?.length > 0) {
                await reviewAlarmClientService.execute({
                    id: selectedEvents[0].id,
                    justification,
                });

                handleOpenDialog();
                document.querySelector<HTMLDialogElement>('dialog#alarm-details-dialog')?.close();

                toast.success(t('general.message.success.title'), t('general.message.success.message'));
            }
        } catch (e) {
            toast.error(t('general.message.error.title'), t('general.message.error.message'));
        } finally {
            setIsLoading(false);
        }
    }, [handleOpenDialog, justification, selectedEvents, t]);

    const eventList = useMemo(() => {
        if (selectedEvents?.length && selectedEvents?.length > 0) {
            return (
                selectedEvents?.length > 1 && (
                    <Column gap={8} align="flex-start" width="100%">
                        <Row gap={8} align="flex-start">
                            <MediumText>{t('general.selected_alarms')}</MediumText>
                            <Number>({selectedEvents?.length})</Number>
                        </Row>
                        <ScrollRow />
                    </Column>
                )
            );
        }

        return <Fragment />;
    }, [selectedEvents?.length, t]);

    const isDisabled = useMemo(() => {
        if (selectedEvents === undefined || selectedEvents.length === 0) {
            return true;
        }

        const event = selectedEvents[0];

        const isEventDs = events.find(eventType => eventType.type === 'DS' && eventType.name === event.event);

        if (!isEventDs) {
            return true;
        }

        if (event.alarmArrivalTime === null) {
            return true;
        }

        const date = zonedTimeToUtc(new Date(), 'UTC');
        const dateGMT = utcToZonedTime(date.toISOString(), 'America/Sao_Paulo');

        const diff = differenceInDays(parseISO(dateGMT.toISOString()), parseISO(event.alarmArrivalTime.toString()));

        if (diff > 7) {
            return true;
        }

        return false;
    }, [selectedEvents]);

    return (
        <Fragment>
            {eventList}

            <Row align="flex-start" justify="center" gap={16} flex width="100%">
                <AlarmLeftInfo />
                <Column align="flex-start" justify="center" gap={32} flex width="100%">
                    <PastEventInfo />
                    <Column gap={8}>
                        <Title>{t('alarms.options')}</Title>
                        <ButtonOption onClick={handleOpenDialog} disabled={isDisabled}>
                            {t('alarms.request_review')}
                        </ButtonOption>
                    </Column>
                </Column>
            </Row>

            <Dialog onCloseModal={handleOpenDialog} ref={dialogRef} id="confirm-request-review-dialog">
                <Column width="25rem">
                    {dialogIsOpen ? (
                        <DialogContent>
                            <Row justify="center" width="100%" align="start">
                                <h4>{t('alarms.request_revision')}</h4>
                            </Row>
                            <Column align="center" gap={16} flex width="100%">
                                <Column align="center">
                                    <p>{t('alarms.this_action_revision')}?</p>
                                </Column>

                                <Textarea
                                    autoFocus
                                    name="justification"
                                    width="22rem"
                                    minHeight="8rem"
                                    label="Justificativa"
                                    placeholder={String(t('alarms.placeholder_action_revision'))}
                                    value={justification}
                                    onChange={event => setJustification(event.target.value)}
                                />
                                <Divider />
                            </Column>
                            <Row align="flex-end" gap={16} flex width="100%">
                                <CancelButton onClick={handleOpenDialog}>{t('general.cancel')}</CancelButton>
                                <Button isLoading={isLoading} type="reset" color="alert" onClick={handleConfirm}>
                                    {t('general.confirm')}
                                </Button>
                            </Row>
                        </DialogContent>
                    ) : (
                        <Fragment />
                    )}
                </Column>
            </Dialog>
        </Fragment>
    );
}
