import { PropsWithChildren, createContext, useCallback, useMemo, useReducer, useRef, useState } from 'react';
import { Pagination } from 'src/common/core/pagination';
import { Reducer, reducer } from 'src/common/types/reducer';
import { DepartmentResume } from 'src/modules/configurations';

import { ElectronicFence } from '../../domain/entities/electronic-fence';
import { CreateElectronicFenceFormDTO } from '../../dtos/create-electronic-fence.dto';
import { GetElectronicFenceDTO } from '../../dtos/get-electronic-fence.dto';
import { INITIAL_DEPARTMENT_STATE } from '../const';
import { getDefaultCreateOrUpdateValues } from './default-form-values';
import { ElectronicFenceContextProps, ElectronicFenceFormData, IsOpenProps } from './types';

export const ElectronicFenceContext = createContext({} as ElectronicFenceContextProps);

export default function ElectronicFenceProvider({ children }: PropsWithChildren) {
    const [electronicFences, setElectronicFences] = useState<Pagination<ElectronicFence> | null>(null);
    const [initialFormData, setInitialFormData] = useState<CreateElectronicFenceFormDTO>(() => getDefaultCreateOrUpdateValues(null));
    const [filter, setFilter] = useState<GetElectronicFenceDTO>({ operation: '', status: 'all', pageSize: 15, currentPage: 1, text: '' });
    const [isOpen, setIsOpen] = useState<IsOpenProps>({ createOrUpdate: false, delete: false, map: false, filter: false });
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_DEPARTMENT_STATE);
    const map = useRef<google.maps.Map | null>(null);
    const overlayRef = useRef<google.maps.Polygon | google.maps.Circle | null>(null);

    const setFormData = useCallback((data: ElectronicFenceFormData | null) => {
        setInitialFormData(() => getDefaultCreateOrUpdateValues(data));
    }, []);

    const data: ElectronicFenceContextProps = useMemo<ElectronicFenceContextProps>(
        () => ({
            electronicFences,
            filter,
            initialFormData,
            isOpen,
            map,
            operations,
            overlayRef,
            setElectronicFences,
            setFilter,
            setFormData,
            setIsOpen,
            setOperations,
        }),
        [electronicFences, filter, initialFormData, isOpen, operations, setFormData],
    );

    return <ElectronicFenceContext.Provider value={data}>{children}</ElectronicFenceContext.Provider>;
}
