import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'src/common/hooks';
import { FunctionComponent } from 'src/common/types';
import { ENV } from 'src/config/consts';
import Cookies from 'universal-cookie';

import { SessionDTO } from '../../dtos/auth.dto';
import { LoginChallengeProps } from '../../dtos/login-challenge.dto';
import { authService } from '../../factories';
import { AuthContextProps } from './types';

export const AuthenticationContext = createContext<AuthContextProps>({} as AuthContextProps);

export function AuthProvider({ children }: FunctionComponent): JSX.Element {
    const cookies = useMemo(() => new Cookies(), []);
    const [session, setSession] = useLocalStorage<SessionDTO | null>(`@session-${ENV}`, null);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [challenge, setChallenge] = useState<LoginChallengeProps | null>(null);

    const signOut = useCallback(() => {
        localStorage.removeItem(`@userEmail-${ENV}`);
        authService.signOut();
        setSession(null);
        cookies.remove('jose');
    }, [cookies, setSession]);

    const initialize = useCallback(async () => {
        try {
            setIsAuthenticating(true);
            const res = await authService.getSession();
            setSession(res);
            cookies.set('jose', res.token, { path: '/', secure: true, sameSite: 'none' });
            setChallenge(null);
        } catch {
            authService.signOut();
            setSession(null);
            cookies.remove('jose');
        } finally {
            setIsAuthenticating(false);
        }
    }, [cookies, setSession]);

    const isAuthenticated = useMemo(() => !!session && !!cookies.get('jose'), [cookies, session]);

    const data: AuthContextProps = useMemo(
        () => ({ initialize, isAuthenticating, isAuthenticated, session, signOut, challenge, setChallenge }),
        [challenge, initialize, isAuthenticated, isAuthenticating, session, signOut],
    );

    useEffect(() => {
        initialize();
    }, [initialize]);

    return <AuthenticationContext.Provider value={data}>{children}</AuthenticationContext.Provider>;
}
