import styled from 'styled-components';

import { MainCard } from '../main-card';

export const Card = styled(MainCard)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;

    width: 394px;

    p {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        display: block;
        text-align: center;
        flex: 1;

        margin: 0;

        color: #7b7b7b;
        word-break: break-all;
    }
`;

export const Title = styled.h4`
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    color: #262d3c;
    margin: 0;
`;
