import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { usePermission } from 'src/common/hooks';

import { Menu } from '../../menu';

interface SettingOptionsProps {
    anchorEl: HTMLButtonElement | null;
    open: boolean;
    onClose(): void;
}

interface MenuItemProps {
    userHasPermission: boolean;
    menuItemTitle: string;
}

export default function SettingOptions(props: SettingOptionsProps) {
    const { t } = useTranslation();
    const { has } = usePermission();

    return (
        <Menu id="menu-settings" {...props}>
            <NavLink to="/configurations/users" onClick={props.onClose}>
                <MenuItemPermission userHasPermission={has('users')} menuItemTitle={t('configurations.users')} />
            </NavLink>
            <NavLink to="/configurations/operations" onClick={props.onClose}>
                <MenuItemPermission userHasPermission={has('operations')} menuItemTitle={t('configurations.departments')} />
            </NavLink>
            <NavLink to="/configurations/functionalities" onClick={props.onClose}>
                <MenuItemPermission userHasPermission={has('functionalities')} menuItemTitle={t('configurations.functionalities')} />
            </NavLink>
            <NavLink to="/configurations/vehicles" onClick={props.onClose}>
                <MenuItemPermission userHasPermission={has('vehicles')} menuItemTitle={t('configurations.vehicles')} />
            </NavLink>
            <NavLink to="/configurations/password-policies" onClick={props.onClose}>
                <MenuItemPermission userHasPermission={has('password_policy')} menuItemTitle={t('configurations.password_policy')} />
            </NavLink>
            <NavLink to="/configurations/intervention-protocol" onClick={props.onClose}>
                <MenuItemPermission userHasPermission={has('intervention_protocol')} menuItemTitle={t('configurations.intervention_protocol')} />
            </NavLink>
            <NavLink to="/configurations/registration-shipments" onClick={props.onClose}>
                <MenuItemPermission userHasPermission={has('registration_shipments')} menuItemTitle={t('menu.registration_shipments')} />
            </NavLink>
            {/* <Link to="/" onClick={props.onClose}>
                <MenuItem>{t('configurations.equipments')}</MenuItem>
            </Link>
            <Link to="/" onClick={props.onClose}>
                <MenuItem>{t('configurations.system')}</MenuItem>
            </Link> */}
        </Menu>
    );
}

function MenuItemPermission({ userHasPermission, menuItemTitle }: MenuItemProps) {
    return <>{userHasPermission && <MenuItem>{menuItemTitle}</MenuItem>}</>;
}
