import { Skeleton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import SelectIndexButton from 'src/modules/analytics/comparison-of-units/components/select-index-button';

import {
    Button,
    CardHeader,
    ChevronDown,
    Content,
    Divider,
    Export,
    FilterAlt,
    MainCard,
    NoContent,
    ObjectHelper,
    Row,
    Select,
    Title,
    UsedFilters,
} from '../../../common';
import Column from '../../../common/components/column';
import { FilterGraph } from '../../../common/components/graphics/filter-graphs';
import { StackedLineChart } from '../../../common/components/graphics/stacked-line-chart';
import RadioButton from '../../../common/components/radio-button';
import UpdateData from '../../../common/components/update-data';
import DialogModal from '../../../modules/analytics/comparison-of-units/components/dialog-modal';
import TableList from '../../../modules/analytics/comparison-of-units/components/table-list';
import { useComparisonOfUnits } from '../../../modules/analytics/comparison-of-units/hook';
import { ChartContainer, GraphTable, RadioText, TitleContainer } from './styles';

export function ComparisonOfUnits() {
    const { t } = useTranslation();

    const days = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'];

    const [selectedTablePeriod, setSelectedTablePeriod] = useState<string>('Table');
    const [selectedTableUnity, setSelectedTableUnity] = useState<string>('Teste');
    const [filterTableIsOpen, setFilterTableIsOpen] = useState(false);
    const [filterComparisonIsOpen, setFilterComparisonIsOpen] = useState(false);
    const [tab, setTab] = useState(3);

    const events = [
        { value: 'sonolenciaN2', label: t(`tableEventName.${'Sonolência N2'}`) },
        { value: 'sonolenciaN1', label: t(`tableEventName.${'Sonolência N1'}`) },
        { value: 'Bocejo', label: t(`tableEventName.${'Bocejo'}`) },
        { value: 'Celular', label: t(`tableEventName.${'Celular'}`) },
        { value: 'Sem Cinto', label: t(`tableEventName.${'Sem cinto'}`) },
        { value: 'Fumando', label: t(`tableEventName.${'Fumando'}`) },
        { value: 'Fones de ouvido', label: t(`tableEventName.${'Fone de ouvido'}`) },
        { value: 'Consumo de alimento', label: t(`tableEventName.${'Consumo alimento'}`) },
        { value: 'Olhando para baixo N2', label: t(`tableEventName.${'Olhando para baixo N2'}`) },
        { value: 'Olhando para baixo N1', label: t(`tableEventName.${'Olhando para baixo N1'}`) },
    ];

    const dataBarChart = [
        {
            label: 'Uruguai',
            data: [181, 120, 192, 145, 158, 166, 140],
            color: '#DC3545',
        },
        {
            label: 'Equador',
            data: [134, 145, 175, 195, 158, 167, 123],
            color: '#FD7E14',
        },
        {
            label: 'Panamá',
            data: [154, 145, 122, 188, 162, 139, 0],
            color: '#6F42C1',
        },
        {
            label: 'Guatemala',
            data: [123, 168, 163, 184, 191, 129, 194],
            color: '#8BE08F',
        },
    ];

    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const { handleGetList, responseStatus } = useComparisonOfUnits();

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const handleOpenDialog = useCallback(() => {
        setDialogIsOpen(state => !state);
    }, []);

    const filters = useMemo(() => {
        if (filterIsOpen) {
            //return <AnalyticsFilter />;
        }

        return null;
    }, [filterIsOpen]);

    const handleUpdate = useCallback(async () => {
        console.log('testeeee');
    }, []);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    useEffect(() => {
        setFilterIsOpen(false);
    }, []);

    const handleOpenTableFilter = useCallback(() => {
        setFilterTableIsOpen(state => !state);
    }, []);

    const handleOpenComparisonFilter = useCallback(() => {
        setFilterComparisonIsOpen(state => !state);
    }, []);

    const handleClearFilterValue = useCallback(() => {
        setFilterIsOpen(false);
    }, []);

    const handleClearFilters = useCallback(() => {
        setFilterIsOpen(false);
    }, []);

    const handleOpenFilters = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const usedFilters = useMemo(
        () => ({
            country: '',
            region: '',
            operation: '',
        }),
        [],
    );

    const usedFiltersComponent = useMemo(
        () =>
            !ObjectHelper.isEmpty(usedFilters as any) && (
                <UsedFilters data={usedFilters} onClear={handleClearFilterValue} onClearAll={handleClearFilters} />
            ),
        [handleClearFilterValue, handleClearFilters, usedFilters],
    );

    useEffect(() => {
        handleGetList({
            currentPage: 1,
            pageSize: 10,
        });
    }, [handleGetList]);

    const handleChangeTab = useCallback((newValue: number) => {
        setTab(newValue);
    }, []);

    const content = useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={() => handleGetList({
                currentPage: 1,
                pageSize: 10,
            })} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={() => handleGetList({
                currentPage: 1,
                pageSize: 10,
            })} />;
        }

        return <TableList tab={tab}/>;
    }, [handleGetList, responseStatus?.hasError, responseStatus.loading, responseStatus?.void, t]);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between">
                <Title>
                    <h2>Analytics</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
            </Row>

            <MainCard style={{ paddingBottom: '1rem' }}>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>{t('comparison_of_units.comparison_of_units')}</h3>
                        </Row>
                        <Row gap={16}>
                            <Export />
                            <Button
                                color={filterIsOpen ? 'purple' : 'outline-purple'}
                                leftIcon={<FilterAlt color={filterIsOpen ? '#fff' : '#46526C'} />}
                                size="small"
                                rightIcon={<ChevronDown spin={filterIsOpen} color={filterIsOpen ? '#fff' : '#46526C'} />}
                                type="button"
                                onClick={handleOpenFilters}
                            >
                                {t('general.filter')}
                            </Button>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {filters}
                </Column>
                <Row padding="0.8rem">
                    <ChartContainer>
                        <TitleContainer>
                            <SelectIndexButton tab={tab} changeTab={handleChangeTab}/>
                            <div>
                                <button onClick={handleOpenDialog}>
                                    <AiOutlineInfoCircle color="#46526C" size={20} />
                                </button>
                                <button className="filterButton">
                                    <FilterAlt color={'#46526C'} onClick={handleOpenTableFilter} />
                                </button>
                            </div>
                        </TitleContainer>
                        <GraphTable>
                            {filterTableIsOpen && (
                                <FilterGraph handleCancelButton={() => console.log('teste')}>
                                    <div className="tableFilterDiv">
                                        <RadioText>{t('comparison_of_units.period_vision')}</RadioText>
                                        <div
                                            className="radioButton"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedTablePeriod(event.target.value)}
                                        >
                                            <RadioButton
                                                value="Day"
                                                checked={selectedTablePeriod === 'Day'}
                                                group="tablePeriod"
                                                name={t('comparison_of_units.day')}
                                            />
                                            <RadioButton
                                                value="Week"
                                                checked={selectedTablePeriod === 'Week'}
                                                group="tablePeriod"
                                                name={t('comparison_of_units.week')}
                                            />
                                            <RadioButton
                                                value="Month"
                                                checked={selectedTablePeriod === 'Month'}
                                                group="tablePeriod"
                                                name={t('comparison_of_units.month')}
                                            />
                                            <RadioButton
                                                value="Year"
                                                checked={selectedTablePeriod === 'Year'}
                                                group="tablePeriod"
                                                name={t('comparison_of_units.year')}
                                            />
                                        </div>
                                    </div>
                                    <div className="tableFilterDiv">
                                        <RadioText>{t('comparison_of_units.unity_vision')}</RadioText>
                                        <div
                                            className="radioButton"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedTableUnity(event.target.value)}
                                        >
                                            <RadioButton
                                                value="Period"
                                                checked={selectedTableUnity === 'Period'}
                                                group="tableUnity"
                                                name={t('comparison_of_units.period')}
                                            />
                                            <RadioButton
                                                value="Operation"
                                                checked={selectedTableUnity === 'Operation'}
                                                group="tableUnity"
                                                name={t('comparison_of_units.operation')}
                                            />
                                            <RadioButton
                                                value="Region"
                                                checked={selectedTableUnity === 'Region'}
                                                group="tableUnity"
                                                name={t('comparison_of_units.region')}
                                            />
                                            <RadioButton
                                                value="Country"
                                                checked={selectedTableUnity === 'Country'}
                                                group="tableUnity"
                                                name={t('comparison_of_units.country')}
                                            />
                                        </div>
                                    </div>
                                </FilterGraph>
                            )}
                        </GraphTable>
                        {content}
                    </ChartContainer>
                </Row>
                <Row padding="0.8rem">
                    <ChartContainer style={{ height: '50vh' }}>
                        <TitleContainer>
                            <Title>
                                <h2>{t('comparison_of_units.comparison')}</h2>
                            </Title>
                            <div>
                                <button className="filterButton">
                                    <FilterAlt color={'#46526C'} onClick={handleOpenComparisonFilter} />
                                </button>
                            </div>
                        </TitleContainer>
                        <StackedLineChart legends={days} data={dataBarChart} />
                        {filterComparisonIsOpen && (
                            <FilterGraph handleCancelButton={() => console.log('teste')}>
                                <div>
                                    <RadioText>{t('comparison_of_units.column')}</RadioText>
                                    <div>
                                        <Select
                                            name="selectGrapsModal"
                                            options={events}
                                            placeholder={String(t('comparison_of_units.select_the_event'))}
                                        />
                                    </div>
                                </div>
                            </FilterGraph>
                        )}
                    </ChartContainer>
                </Row>
                <DialogModal onClose={handleOpenDialog} open={dialogIsOpen}>
                    <div>
                        <h2>{t('comparison_of_units.informative')}</h2>
                        <p>
                            <Trans i18nKey="comparison_of_units.the_vehicles_column_is_the_maximum" />
                        </p>
                        <p>{t('comparison_of_units.each_event_column_contains_the_total_number')}</p>
                    </div>
                    <div>
                        <p>{t('comparison_of_units.we_have_the_following_formulas')}</p>
                        <ul>
                            <li>{t('comparison_of_units.fatigue_index')}</li>
                            <li>{t('comparison_of_units.distraction_index')}</li>
                            <li>{t('comparison_of_units.indice_argus')}</li>
                        </ul>
                    </div>
                </DialogModal>
            </MainCard>
        </Column>
    );
}
