import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, DateHelper, Eye, Table } from 'src/common';
import { TableData, TableRow } from 'src/common/components/table/styles';

import { useReviewRequest } from '../../hooks';

function TableList() {
    const { t, i18n } = useTranslation();
    const { reviews, handleOpenDialog } = useReviewRequest();

    const tableHeaders = useMemo(
        () => [
            t('review_request.request_number'),
            t('review_request.request_time'),
            t('review_request.operation'),
            t('review_request.fleet'),
            t('review_request.event'),
            t('review_request.event_time'),
            t('review_request.post_analysis'),
            t('review_request.status'),
            t('review_request.justification'),
            t('review_request.answer'),
        ],
        [t],
    );

    return (
        <Fragment>
            <Table tableHeaders={tableHeaders}>
                {reviews?.elements?.map(review => (
                    <TableRow className="body" key={String(review.eventId)}>
                        <TableData style={{ width: '7.8rem' }}>{String(review.orderId)}</TableData>
                        <TableData>{DateHelper.format(review.orderDate, i18n.language, 'UTC')}</TableData>
                        <TableData>{review.operation}</TableData>
                        <TableData>{review.fleet}</TableData>
                        <TableData>{t(`tableEventName.${review.answerTime ? review.preAnalysisEvent : review.event}`)}</TableData>
                        <TableData>{DateHelper.format(review.alarmTime, i18n.language, 'UTC')}</TableData>
                        <TableData>{review.postAnalysisEvent ? t(`tableEventName.${review.postAnalysisEvent}`) : '---'}</TableData>
                        <TableData style={{ paddingBottom: '0.35rem' }}>
                            <Badge
                                className={review.status === 'Analisado' ? 'success' : 'pending'}
                                label={review.status === 'Analisado' ? t('review_request.success') : t('review_request.pending')}
                            />
                        </TableData>
                        <TableData style={{ paddingLeft: '1.9rem' }}>
                            <Eye cursor="pointer" color="#262d3c" width={20} height={20} onClick={() => handleOpenDialog(review, 0)} />
                        </TableData>
                        <TableData style={{ paddingLeft: '1.2rem' }}>
                            <Eye cursor="pointer" color="#262d3c" width={20} height={20} onClick={() => handleOpenDialog(review, 1)} />
                        </TableData>
                    </TableRow>
                ))}
            </Table>
        </Fragment>
    );
}

export default memo(TableList);
