import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArgusLogo, Button, Column, Input, LanguageSelector, Row } from 'src/common/components';
import { AuthBackground, AuthCard, AuthForm } from 'src/modules/auth/components';
import { preLoginSchema } from 'src/modules/auth/infra/validations/yup/schemas/pre-login-schema';

import { useAuth } from '../../hooks';

export type PreLoginProps = {
    onSubmit: (email: string) => void;
    isLoading: boolean;
};

interface PreLoginForm {
    user: string;
}

export default function PreLogin({ onSubmit, isLoading }: PreLoginProps): JSX.Element {
    const { isAuthenticating } = useAuth();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<PreLoginForm>({
        resolver: yupResolver(preLoginSchema),
    });

    return (
        <AuthBackground>
            <Column align="center" justify="center" size="100%">
                <AuthCard>
                    <Row width="100%" align="center" justify="space-between" gap={8}>
                        <ArgusLogo color="#262D3C" height={30} width={131} data-test="logo" />
                        <LanguageSelector color="#5A5A5A" />
                    </Row>
                    <AuthForm onSubmit={handleSubmit(({ user }) => onSubmit(user))}>
                        <Column width="302px" align="center" gap={16}>
                            <Column width="100%" id="fieldset" align="flex-start" gap={8}>
                                <Input
                                    type="text"
                                    placeholder={t('auth.user')?.toString()}
                                    style={{ width: '100%' }}
                                    feedback={{
                                        message: String(t(errors?.user?.message || '')),
                                    }}
                                    disabled={isLoading || isAuthenticating}
                                    data-test="email-input"
                                    {...register('user')}
                                />
                            </Column>
                        </Column>
                        <Column gap={16} width="100%" align="center">
                            <Button type="submit" disabled={!isValid} isLoading={isLoading || isAuthenticating} data-test="button-submit">
                                {t('auth.continue')}
                            </Button>
                        </Column>
                    </AuthForm>
                </AuthCard>
            </Column>
        </AuthBackground>
    );
}
