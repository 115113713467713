import styled from 'styled-components';

export const CloseButton = styled.button`
    border: 0;
    width: 32px;
    height: 32px;

    padding: 0;

    color: #a9a9a9;
    background: #e6e6e6;
    border-radius: 4px;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #ef1d00;

        color: #fff;
    }
`;

export const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 32px;
`;

export const Title = styled.h3`
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;

    color: #262d3c;
    margin: 0;
`;

export const MediumText = styled.strong`
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #262d3c;
`;

export const Number = styled.span`
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #a9a9a9;
`;
