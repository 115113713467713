import styled from 'styled-components';

export const Title = styled.h5`
    margin: 0;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #262d3c;

    font-family: 'Roboto', sans-serif;
`;
