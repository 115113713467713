import { Service } from 'src/common/core/service';
import { api } from 'src/common/infra';

import { SortAlarmClientRequestDTO } from '../dtos/event.dto';

class ReviewAlarmClientService implements Service<SortAlarmClientRequestDTO, void> {
    public async execute(request: SortAlarmClientRequestDTO): Promise<void> {
        const { id, justification } = request;

        await api.put(`/alarms-processing/review-alarm-client/${id}`, {
            justification,
        });
    }
}

export const reviewAlarmClientService = new ReviewAlarmClientService();
