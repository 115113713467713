import { t } from 'i18next';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { CgClose } from 'react-icons/cg';
import { BellBadgeFill, PinAlt, Time } from 'src/common/components';
import { useEvents } from 'src/modules/alarm-processing/hooks';

import { CloseButton } from '../styles';
import { MuiTab, MuiTabs } from './styles';

interface TabsProps {
    value: number | false;
    onChange: (_: SyntheticEvent, newValue: number) => void;
    onClose(): void;
}

export default function Tabs({ value, onChange, onClose }: TabsProps) {
    const { selectedEvents } = useEvents();
    const color = useCallback((index: number) => (value === index ? '#fff' : '#A9A9A9'), [value]);

    const eventsAreTheSame = useMemo(() => {
        const filteredArr = selectedEvents?.filter((el, index, arr) => arr.findIndex(item => item.fleet === el.fleet) === index);

        return filteredArr?.length === 1 || false;
    }, [selectedEvents]);

    const tabProps = useCallback((index: number) => {
        return {
            'aria-controls': `simple-tabpanel-${index}`,
            id: `simple-tab-${index}`,
            value: index,
        };
    }, []);

    return (
        <MuiTabs aria-label="tabs" onChange={onChange} value={value}>
            <MuiTab label={<BellBadgeFill className="bell-badge-fill" />} {...tabProps(0)} title={t('alarms.information')!}/>
            {/* <MuiTab label={<ImageLoadBoxFill color={color(1)} />} {...tabProps(1)} />*/}
            {eventsAreTheSame && <MuiTab label={<PinAlt color={color(2)} />} {...tabProps(2)} title={t('alarms.historic_route')!}/>}
            {eventsAreTheSame && <MuiTab label={<Time className="time" />} {...tabProps(3)} title={t('alarms.previous_events')!}/>}
            <CloseButton type="button" onClick={onClose}>
                <CgClose size={20} />
            </CloseButton>
        </MuiTabs>
    );
}
