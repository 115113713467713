import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableData, TableRow } from 'src/common/components/table/styles';

import { Table } from '../../../../../common';
import { useDrivers } from '../../hook';

export interface TableFilterProps {
    dateFrom?: Date | null;
    dateTo?: Date | null;
}

function TableFilter({dateFrom, dateTo}: TableFilterProps) {
    const { t } = useTranslation();

    const { driversPaged, handleGetDriverListIdCluster, handleGetDriverIdCluster } = useDrivers();

    const hasDriver: boolean = driversPaged?.elements.filter(driver => driver.driver && driver.driver !== null).length !== 0;

    const tableHeaders = useMemo(() =>  {
        return hasDriver 
        ? [t('drivers.driver'), t('drivers.id_cluster'), t('drivers.operation'),  t('drivers.document')]
        : [t('drivers.id_cluster'), t('drivers.operation')]
    }, [t, hasDriver]);

    const handleClickedTable = useCallback((idCluster: string) => {
        handleGetDriverListIdCluster({
            dateFrom,
            dateTo,
            pageSize: 15,
            start: 1,
            id: idCluster
        });
        handleGetDriverIdCluster({
            dateFrom,
            dateTo,
            id: idCluster
        })
    },[dateFrom, dateTo, handleGetDriverIdCluster, handleGetDriverListIdCluster])

    return (
        <Table tableHeaders={tableHeaders}>
            {driversPaged?.elements.map(driver => (
                <TableRow onClick={() => handleClickedTable(driver.idCluster)} key={driver.idCluster.toString()} className="body pointer">
                    {hasDriver
                        ? driver.driver
                            ? <TableData>{driver.driver}</TableData>
                            :  <TableData />
                        : <></>
                    }
                    <TableData>{driver.idCluster}</TableData>
                    <TableData>{driver.operation}</TableData>
                    {hasDriver
                        ? driver.driver
                        ? <TableData>{driver.document}</TableData>
                        :  <TableData />
                        : <></>
                    }
                </TableRow>
            ))}
        </Table>
    );
}

export default memo(TableFilter);
