import { Amplify, Auth } from 'aws-amplify';
import { ApiResponse } from 'src/common/core/api-response';
import { LocalStorage } from 'src/common/helpers/local-storage.helper';
import { api } from 'src/common/infra/http';
import { amplifyConfig } from 'src/config/login';
import Cookies from 'universal-cookie';

import {
    FirstAccessDTO,
    ForgotPasswordDTO,
    LoginResponse,
    PreLoginResponseDTO,
    RedefinePasswordDTO,
    SessionDTO,
    SignInDTO,
} from '../../dtos/auth.dto';
import { AuthenticationService, Tokens } from '../../services';
import { CognitoSessionProps } from './cognito-auth.dto';

export class CognitoAuthService implements AuthenticationService {
    public constructor() {
        Amplify.configure(amplifyConfig);
    }

    public async createNewPassword(request: FirstAccessDTO): Promise<unknown> {
        const { email, newPassword, newPasswordConfirmation, oldPassword, signal } = request;

        const {
            data: { content },
        } = await api.post<ApiResponse<unknown>>(
            `auth/new-password`,
            { email, newPassword, newPasswordConfirmation, oldPassword },
            {
                signal,
            },
        );

        return content;
    }

    public async firstAccess(data: FirstAccessDTO): Promise<void> {
        const { email, newPassword, newPasswordConfirmation, oldPassword, signal } = data;

        await api.post<ApiResponse<LoginResponse>>(
            `auth/first-access`,
            { email, newPassword, newPasswordConfirmation, oldPassword },
            {
                signal,
            },
        );
    }

    public async federatedSignIn(provider: string) {
        return Auth.federatedSignIn({
            customProvider: provider,
        });
    }

    public async forgotPassword({ email, signal }: ForgotPasswordDTO): Promise<void> {
        await api.post(
            `auth/forgot-password`,
            { email },
            {
                signal,
            },
        );
    }

    public async preLogin({ email, signal }: ForgotPasswordDTO): Promise<PreLoginResponseDTO> {
        const {
            data: { content },
        } = await api.get<ApiResponse<PreLoginResponseDTO>>(`auth/pre-login/${email}`, {
            signal,
        });
        return content;
    }

    public async getSession(): Promise<SessionDTO> {
        const {
            data: { content },
        } = await api.get<ApiResponse<SessionDTO>>('/auth/me');
        return content;
    }

    public async getTokens(): Promise<Tokens> {
        const cookies = new Cookies();
        try {
            const data: CognitoSessionProps = await Auth.currentAuthenticatedUser();

            const jose = cookies.get('jose');

            return {
                ...data.signInUserSession,
                jose: jose || null,
            };
        } catch {
            const jose = cookies.get('jose');
            return {
                accessToken: {
                    jwtToken: cookies.get('token'),
                },
                jose,
                idToken: {
                    jwtToken: cookies.get('idToken'),
                },
                refreshToken: {
                    token: cookies.get('refreshToken'),
                },
            };
        }
    }

    public async redefinePassword(data: RedefinePasswordDTO): Promise<void> {
        const { signal, email, code, newPassword, newPasswordConfirmation } = data;

        await api.post(
            `auth/redefine-password`,
            { email, code, newPassword, newPasswordConfirmation },
            {
                signal,
            },
        );
    }

    public async signIn(data: SignInDTO): Promise<LoginResponse> {
        const { password, username, code, signal, mfaType, phone, setupMFA } = data;
        const cookies = new Cookies();

        const {
            data: { content },
        } = await api.post<ApiResponse<LoginResponse>>(
            `auth/login`,
            { password, username, code: code || undefined, mfaType, phone, setupMFA },
            {
                signal,
            },
        );

        if (content.data) {
            cookies.set('idToken', content.data.idToken, { path: '/', secure: true, sameSite: 'none' });
            cookies.set('refreshToken', content.data.refreshToken, { path: '/', secure: true, sameSite: 'none' });
            cookies.set('token', content.data.token, { path: '/', secure: true, sameSite: 'none' });
        }

        return content;
    }

    public signOut(): void {
        const cookies = new Cookies();

        LocalStorage.deletePreffix('CognitoIdentityServiceProvider');
        LocalStorage.deletePreffix(`@userEmail`);
        cookies.remove('idToken');
        cookies.remove('refreshToken');
        cookies.remove('token');
        cookies.remove('jose');

        Auth.signOut();
    }
}
