import { createContext, PropsWithChildren, useCallback, useMemo, useReducer, useState } from 'react';
import { reducer, Reducer } from 'src/common/types/reducer';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { DepartmentResume } from 'src/modules/configurations';

import { Vehicle } from '../../domain/entities/vehicle';
import { GetRealTimeLocationDTO } from '../../dtos/get-real-time-location.dto';
import { LastOnlineData } from '../../dtos/last-online-data';
import { INITIAL_DEPARTMENT_STATE, INITIAL_LAST_ONLINE_DATA_STATE, INITIAL_VEHICLES_STATE, INITITAL_VEHICLE_LAST_EVENTS_STATE } from '../const';
import { CenterProps, ModalDataProps, RealTimeLocationContextProps } from './types';

export const RealTimeLocationContext = createContext({} as RealTimeLocationContextProps);

export const INIT_FILTER_STATE: GetRealTimeLocationDTO = {
    fleetInput: '',
    fleets: [],
    operation: '',
    status: 'all',
};

export default function RealTimeLocationProvider({ children }: PropsWithChildren) {
    const [center, setCenter] = useState<CenterProps>();
    const [filter, setFilter] = useState<GetRealTimeLocationDTO>(INIT_FILTER_STATE);
    const [filterIsOpen, setFilterIsOpen] = useState(true);
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_DEPARTMENT_STATE);
    const [vehicles, setVehicles] = useReducer<Reducer<Array<Vehicle>>>(reducer, INITIAL_VEHICLES_STATE);
    const [latestEvents, setLatestEvents] = useReducer<Reducer<Array<EventDTO>>>(reducer, INITITAL_VEHICLE_LAST_EVENTS_STATE);
    const [lastOnlineData, setLastOnlineData] = useReducer<Reducer<LastOnlineData | null>>(reducer, INITIAL_LAST_ONLINE_DATA_STATE);
    const [modalData, setModalData] = useState<ModalDataProps>({
        isOpen: false,
        realTimeLocation: null,
    });

    const reset = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(true);
        setOperations({
            type: 'data',
            payload: [],
        });
        setVehicles({
            type: 'data',
            payload: [],
        });
    }, []);

    const data = useMemo<RealTimeLocationContextProps>(
        () => ({
            center,
            setCenter,
            filter,
            filterIsOpen,
            lastOnlineData,
            latestEvents,
            modalData,
            operations,
            reset,
            setFilter,
            setFilterIsOpen,
            setLastOnlineData,
            setLatestEvents,
            setModalData,
            setOperations,
            setVehicles,
            vehicles,
        }),
        [center, filter, filterIsOpen, lastOnlineData, latestEvents, modalData, operations, reset, vehicles],
    );

    return <RealTimeLocationContext.Provider value={data}>{children}</RealTimeLocationContext.Provider>;
}
