import styled from 'styled-components';

export const StyledSelect = styled.select`
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #5f5f5f;
    border: 1px solid #9fafd2;

    gap: 4px;
    min-height: 36px;
    width: fit-content;

    background-color: #f5f5f5;
    border-radius: 8px;
    outline: 0;
    padding: 0px 8px;

    font-family: 'Space Grotesk', 'Open Sans', sans-serif;
    font-weight: 500;
    cursor: pointer;

    background: transparent;

    option {
        background-color: #fff;
        color: #5f5f5f;
    }

    &.font-large {
        font-size: 20px;
    }

    &.font-small {
        font-size: 12px;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        border: 0;
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e6e6e6;
        border: 1px solid #f5f5f5;
        background-clip: content-box;
    }
`;
