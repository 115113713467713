import { Phone } from 'src/common/domain';
import { object, string } from 'yup';

export const setupMFA = object().shape({
    mfaType: string().required('field_validations.required').oneOf(['SMS', 'TOTP']),
    code: string().when('mfaType', {
        is: 'TOTP',
        then: string().required('field_validations.required'),
    }),
    phone: string().when('mfaType', {
        is: 'SMS',
        then: string()
            .required('field_validations.phone')
            .test({
                test: value => (!value ? false : Phone.isValid(value)),
                message: 'field_validations.phone',
            }),
    }),
});
