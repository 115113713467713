import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableData, TableRow } from 'src/common/components/table/styles';

import { Table } from '../../../../../common';
import { useDrivers } from '../../hook';

function TableList() {
    const { t } = useTranslation();

    const { driverPagedIdCluster } = useDrivers();

    const hasDriver: boolean = driverPagedIdCluster?.elements.filter(driver => driver.driver && driver.driver !== null).length !== 0;

    const tableHeaders = useMemo(() => {
        return hasDriver 
            ? [ 
                t('drivers.id_cluster'),
                t('drivers.driver'),
                t('drivers.document'),
                t('drivers.day'),
                t('drivers.event'),
                t('drivers.fleet'),
                t('drivers.alarm_hour'),
                t('drivers.latitude'),
                t('drivers.longitude'),
                t('drivers.operation'),
            ]
            : [ 
                t('drivers.id_cluster'),
                t('drivers.day'),
                t('drivers.event'),
                t('drivers.fleet'),
                t('drivers.alarm_hour'),
                t('drivers.latitude'),
                t('drivers.longitude'),
                t('drivers.operation'),
            ]
    }, [t, hasDriver]);

    return (
        <Fragment>
            <Table tableHeaders={tableHeaders}>
                {driverPagedIdCluster?.elements.map((driver, index) => (
                    <TableRow className="body" key={driver.hour + index}>
                        <TableData>{driver.idCluster}</TableData>
                        {hasDriver
                            ? <TableData>{driver.driver}</TableData>
                            : <></>
                        }
                        {hasDriver
                            ? <TableData>{driver.document}</TableData>
                            : <></>
                        }
                        <TableData>{driver.date}</TableData>
                        <TableData>{driver.event}</TableData>
                        <TableData>{driver.fleet}</TableData>
                        <TableData>{driver.hour}</TableData>
                        <TableData>{driver.latitude}</TableData>
                        <TableData>{driver.longitude}</TableData>
                        <TableData>{driver.operation}</TableData>
                    </TableRow>
                ))}
            </Table>
        </Fragment>
    );
}

export default memo(TableList);
