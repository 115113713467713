import { MainCard, Modal, RenderIf } from 'src/common/components';

import { useUsers } from '../../hooks/use-users';
import CreateOrUpdateUserForm from '../create-or-update-user-form';
import { DialogContent } from './styles';

export default function CreateOrUpdateUserDialog() {
    const { handleOpenDialog, dialogIsOpen } = useUsers();

    return (
        <Modal open={dialogIsOpen} onClose={() => handleOpenDialog(null)}>
            <DialogContent>
                <RenderIf condition={dialogIsOpen}>
                    <MainCard>
                        <CreateOrUpdateUserForm />
                    </MainCard>
                </RenderIf>
            </DialogContent>
        </Modal>
    );
}
