import { api } from 'src/common/infra';

export class InterventionProtocolByNameService {
    public async listByName(operationName: string) {
        const {
            data: { content },
        } = await api.get<any>('/intervention-protocols/protocol', {
            params: {
                operationName,
            },
        });

        return content;
    }

}

export const interventionProtocolByNameService = new InterventionProtocolByNameService();
