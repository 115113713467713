import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    CardHeader,
    Column,
    Content,
    DeleteButton,
    Divider,
    MainCard,
    NoContent,
    Pagination,
    Plus,
    RenderIf,
    Row,
    SelectNumber,
    Skeleton,
    Title,
} from 'src/common/components';
import { useEventListener, usePermission, useSearch } from 'src/common/hooks';
import { useFunctions } from 'src/modules/configurations';
import { DeleteInBulkDialog, FunctionDialog, TableList } from 'src/modules/configurations/functions/components';

export default function Functionalities() {
    const { isAdmin } = usePermission();
    const { formRef, clear } = useSearch();
    const { t } = useTranslation();
    const { deleteInBulkDialogRef, functions, handleGetList, handleOpenDialog, handleRequestList, requestData, responseStatus, selectedFunctions } =
        useFunctions();

    const content: JSX.Element = useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        }

        if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        }

        if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return <TableList />;
    }, [handleGetList, responseStatus?.hasError, responseStatus.loading, responseStatus?.void, t]);

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();

            const { target } = e;

            const currentTarget = target as unknown as HTMLFormElement;

            const text: HTMLInputElement = currentTarget?.elements?.namedItem('text') as HTMLInputElement;

            handleRequestList({
                currentPage: 1,
                active: true,
                name: text.value,
            });
        },
        [handleRequestList],
    );

    useEffect(() => {
        handleGetList();
    }, [handleGetList]);

    useEffect(() => {
        return () => {
            handleRequestList({
                currentPage: 1,
                pageSize: 15,
                name: '',
            });
            clear();
        };
    }, [clear, handleRequestList]);

    useEventListener(formRef.current, 'submit', handleSearch);

    return (
        <Column gap={16}>
            <Row gap={16} align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('general.configurations')}</h2>
                </Title>
                <RenderIf condition={isAdmin}>
                    <Row gap={16} style={{ width: selectedFunctions?.length ? '396px' : 'max-content' }}>
                        <RenderIf condition={!!selectedFunctions?.length}>
                            <DeleteButton onClick={() => deleteInBulkDialogRef?.current?.showModal()} />
                        </RenderIf>
                        <Button type="button" size="medium" leftIcon={<Plus width={12.63} height={12.63} />} onClick={() => handleOpenDialog()}>
                            {t('configurations.add_function')}
                        </Button>
                    </Row>
                </RenderIf>
            </Row>
            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>
                                {t('configurations.functions')}
                                {responseStatus?.success && !responseStatus?.loading && (
                                    <span className="total">({functions?.totalElements || 0})</span>
                                )}
                            </h3>
                        </Row>
                        <Row align="center" gap={16}>
                            <Row align="center" gap={8}>
                                <span className="per-page">{t('general.items_per_page')}:</span>
                                <SelectNumber
                                    fontMode="small"
                                    value={requestData?.pageSize || 15}
                                    options={[15, 25, 50]}
                                    onChange={e => handleRequestList({ pageSize: Number(e.target.value), currentPage: 1 })}
                                />
                            </Row>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {content}
                    <Divider />
                    <RenderIf condition={!!functions?.totalElements}>
                        <Pagination
                            perPage={requestData?.pageSize}
                            total={functions?.totalElements}
                            onPageChange={currentPage => handleRequestList({ currentPage })}
                            currentPage={requestData?.currentPage}
                        />
                    </RenderIf>
                </Column>
            </MainCard>
            <FunctionDialog />
            <DeleteInBulkDialog />
        </Column>
    );
}
