import styled from 'styled-components';

export const Form = styled.form`
    background: #f1f1f1;
    margin-top: -16px;
    padding: 16px;

    border-bottom: 1px solid #d9d9d9;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;
`;
