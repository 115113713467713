import { ApiResponse, api } from '../../../../common';
import { EventsByFleetDTO, EventsHistoricByFleetDTO, HistoricEventByFleetDTO, RankingFleetsDTO } from '../dtos/fleet.dto';

export class AnalyticsFleetsService {
    public async getRanking({ dateFrom, dateTo, typeEquipament, operations }: RankingFleetsDTO): Promise<any> {
        const {
            data: { content },
        } = await api.get<ApiResponse>('/analytics/fleets', {
            params: {
                dateFrom,
                dateTo,
                equipament: typeEquipament,
                operations,
            },
        });

        return content;
    }

    public async getDataFleetByFleet({ fleet, dateFrom, dateTo, typeEquipament }: EventsByFleetDTO): Promise<any> {
        const {
            data: { content },
        } = await api.get<ApiResponse>(`/analytics/fleets/${fleet}`, {
            params: {
                dateFrom,
                dateTo,
                equipament: typeEquipament,
            },
        });

        return content;
    }

    public async getDataHistoricByFleet({ fleet, typeEquipament }: EventsHistoricByFleetDTO): Promise<Array<HistoricEventByFleetDTO>> {
        const {
            data: { content },
        } = await api.get<ApiResponse>(`/analytics/fleets/historic/${fleet}`, {
            params: {
                equipament: typeEquipament,
            },
        });

        return content;
    }
}

export const analyticsFleetsService = new AnalyticsFleetsService();
