import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

export interface BarChartDataProps {
    name: string;
    color?: string | undefined;
    data: number[];
    total?: number[];
}

export interface BarChartProps {
    data: BarChartDataProps[];
    yData: string[];
    width?: string | number;
    height?: string | number;
    triggerEventYAxis?: boolean;
    dataZoom?: {
        start: number;
        end: number;
    };
    showLabel?: boolean;
    onChartClick?: (params: any) => void;
}

export function BarChart({
    data: dataBarChart,
    yData,
    width = '100%',
    height = '100%',
    dataZoom,
    triggerEventYAxis = false,
    showLabel = false,
    onChartClick,
}: BarChartProps) {
    const { t } = useTranslation();

    const onClick: (params: any) => void = onChartClick ? onChartClick : function () { return null };

    const onEvents = {
        click: onClick,
    };

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        dataZoom: [
            {
                type: 'slider',
                yAxisIndex: 0,
                right: 0,
                start: dataZoom?.start || 0,
                end: dataZoom?.end || 100,
            },
        ],
        legend: {},
        grid: {
            left: '3%',
            right: '6%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
            triggerEvent: triggerEventYAxis,
            data: yData,
            axisLabel: {
                margin: 20,
                width: 150,
                overflow: 'truncate',
            },
        },
        series: [
            ...dataBarChart.map(data => ({
                data: data.data,
                name: t(`tableEventName.${data.name}`),
                itemStyle: {
                    color: data.color,
                },
                type: 'bar',
                stack: 'total',
                emphasis: {
                    focus: 'series',
                },
            })),
            showLabel && {
                name: '',
                type: 'line',
                color: 'transparent',
                symbolSize: 0,
                data: dataBarChart[0]?.total,
                itemStyle: {
                    color: 'transparent',
                },
                label: {
                    show: true,
                    position: 'right',
                },
            },
        ],
    };

    return (
        <ReactEcharts
            notMerge={true}
            onEvents={onEvents}
            className="barPolarGraph"
            option={JSON.parse(JSON.stringify(option))}
            style={{ height, width }}
        />
    );
}
