import styled from 'styled-components';

import { Input } from '../inputs';

export const SearchBox = styled.form`
    display: flex;
    align-items: center;
    gap: 8px;

    max-width: 359px;
    width: 100%;
    height: 40px;

    position: relative;

    .search-icon {
        position: absolute;
        z-index: 1;
        left: 10px;
    }
`;

export const SearchInput = styled(Input)`
    padding-left: 38px;
    height: 40px;

    font-size: 14px;
    line-height: 20px;
`;
