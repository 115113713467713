import { SVGProps } from 'react';

interface ChieldCheckDuotoneLineProps extends SVGProps<SVGSVGElement> {
    color: string;
}

function ChieldCheckDuotoneLine({ color, ...props }: ChieldCheckDuotoneLineProps) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18.702 5.784L12.788 3.25a2 2 0 00-1.576 0L5.298 5.784A2 2 0 004.1 7.871l.613 4.904a7 7 0 002.465 4.509l3.54 2.95a2 2 0 002.561 0l3.541-2.95a7 7 0 002.465-4.51l.613-4.903a2 2 0 00-1.197-2.087z"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
            />
            <path d="M9 12l2.569 2.569a.5.5 0 00.77-.077L16 9" stroke={color} strokeWidth={2} strokeLinecap="round" />
        </svg>
    );
}

export default ChieldCheckDuotoneLine;
