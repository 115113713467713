import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import MainWrapper from 'src/common/components/main-wrapper';
import { PageNotFound } from 'src/common/components/page-not-found';
import AuthRoutes from 'src/common/components/routes/auth-route';
import ChallengeRoute from 'src/common/components/routes/challenge-route';
import CheckPermissionRoute from 'src/common/components/routes/check-permission-route';
import LoginRoute from 'src/common/components/routes/login-route';
import MFARoute from 'src/common/components/routes/mfa-route';
import PublicRoutes from 'src/common/components/routes/public-route';
import AlarmProcessingProviders from 'src/modules/alarm-processing/contexts';
import { ReviewRequestProvider } from 'src/modules/alarm-processing/review-request/contexts';
import { OverviewsProvider } from 'src/modules/analytics/overview/context';
import DepartmentsProvider from 'src/modules/configurations/departments/contexts';
import FunctionalitiesProvider from 'src/modules/configurations/functions/contexts';
import PasswordPoliciesProvider from 'src/modules/configurations/password-policies/contexts';
import UsersProvider from 'src/modules/configurations/users/contexts';
import { VehiclesProvider } from 'src/modules/configurations/vehicles/contexts';
import ElectronicFenceProvider from 'src/modules/vehicle-monitoring/contexts/electronic-fence';
import HistoricalRouteProvider from 'src/modules/vehicle-monitoring/contexts/historical-route';
import RealTimeLocationProvider from 'src/modules/vehicle-monitoring/contexts/real-time-location';
import { ReviewRequest } from 'src/pages/alarm-processing/review-request';
import Departments from 'src/pages/configurations/departments';
import PasswordPolicies from 'src/pages/configurations/password-policies';
import Vehicles from 'src/pages/configurations/vehicles';
import { Functionalities, Home, Login, ProcessingAlarms, RedefinePassword, Users } from 'src/pages/index';
import CreateNewPassword from 'src/pages/login/create-new-password';
import FirstAccess from 'src/pages/login/first-access';
import MFALogin from 'src/pages/login/mfa-login';
import SetupMFA from 'src/pages/login/setup-mfa';
import ElectronicFence from 'src/pages/vehicle-monitoring/electronic-fence';
import HistoricRoute from 'src/pages/vehicle-monitoring/historic-route';
import RealTimeLocation from 'src/pages/vehicle-monitoring/real-time-location';
import Providers from 'src/providers';
import { MuiTheme } from 'src/themes';

import { InternalServerError } from '../common/components/interntal-server-error';
import { ComparisonOfUnitsProvider } from '../modules/analytics/comparison-of-units/context';
import { DriversProvider } from '../modules/analytics/drivers/context';
import { FaceMissingProvider } from '../modules/analytics/face-missing/context';
import { FleetsProvider } from '../modules/analytics/fleets/context';
import { LastAlarmProvider } from '../modules/analytics/last-alarm/context';
import { PeriodProvider } from '../modules/analytics/period/context';
import { InterventionProtocolProvider } from '../modules/configurations/intervention-protocol/context';
import { RegistrationShipmentsProvider } from '../modules/configurations/registration-shipments/context';
import { EventsProvider } from '../modules/smart-report/events/context';
import { Overview, Period, FaceMissing, Fleets, RegistrationShipments } from '../pages/analytics';
import { ComparisonOfUnits } from '../pages/analytics/comparison-of-units';
import { Driver } from '../pages/analytics/drivers';
import { LastAlarm } from '../pages/analytics/last-alarm';
import { InterventionProtocol } from '../pages/configurations/intervention-protocol';
import { Interventions } from '../pages/interventions';
import { Events } from '../pages/smart-report';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Providers muiTheme={MuiTheme} />} errorElement={<InternalServerError />}>
            <Route element={<AuthRoutes />}>
                <Route element={<MainWrapper />}>
                    <Route path="/" element={<Home />} />
                    <Route element={<CheckPermissionRoute userHasAny={['alarms_processing', 'review_alarm', 'sort_alarm']} />}>
                        <Route
                            path="/alarm-processing"
                            element={
                                <AlarmProcessingProviders>
                                    <ProcessingAlarms />
                                </AlarmProcessingProviders>
                            }
                        />
                    </Route>
                    <Route element={<CheckPermissionRoute userHas="review_requests" />}>
                        <Route
                            path="/review-request"
                            element={
                                <ReviewRequestProvider>
                                    <ReviewRequest />
                                </ReviewRequestProvider>
                            }
                        />
                    </Route>
                    <Route element={<CheckPermissionRoute userHas={'settings'} />}>
                        <Route
                            path="/configurations/functionalities"
                            element={
                                <FunctionalitiesProvider>
                                    <Functionalities />
                                </FunctionalitiesProvider>
                            }
                        />
                        <Route
                            path="/configurations/users"
                            element={
                                <UsersProvider>
                                    <Users />
                                </UsersProvider>
                            }
                        />
                        <Route
                            path="/configurations/operations"
                            element={
                                <DepartmentsProvider>
                                    <Departments />
                                </DepartmentsProvider>
                            }
                        />
                        <Route
                            path="/configurations/vehicles"
                            element={
                                <VehiclesProvider>
                                    <Vehicles />
                                </VehiclesProvider>
                            }
                        />
                        <Route
                            path="/configurations/password-policies"
                            element={
                                <PasswordPoliciesProvider>
                                    <PasswordPolicies />
                                </PasswordPoliciesProvider>
                            }
                        />
                        <Route
                            path="/configurations/intervention-protocol"
                            element={
                                <InterventionProtocolProvider>
                                    <InterventionProtocol />
                                </InterventionProtocolProvider>
                            }
                        />

                        <Route
                            path="/configurations/registration-shipments"
                            element={
                                <RegistrationShipmentsProvider>
                                    <RegistrationShipments />
                                </RegistrationShipmentsProvider>
                            }
                        />
                    </Route>

                    <Route element={<CheckPermissionRoute userHasAny={['vehicle_monitoring', 'real_time_localization']} />}>
                        <Route
                            path="/vehicle-monitoring/real-time-location"
                            element={
                                <RealTimeLocationProvider>
                                    <RealTimeLocation />
                                </RealTimeLocationProvider>
                            }
                        />
                    </Route>
                    <Route element={<CheckPermissionRoute userHasAny={['vehicle_monitoring', 'playback_of_tracks']} />}>
                        <Route
                            path="/vehicle-monitoring/historic-route"
                            element={
                                <HistoricalRouteProvider>
                                    <HistoricRoute />
                                </HistoricalRouteProvider>
                            }
                        />
                    </Route>
                    <Route element={<CheckPermissionRoute userHasAny={['vehicle_monitoring', 'electronic_fence']} />}>
                        <Route
                            path="/vehicle-monitoring/electronic-fence"
                            element={
                                <ElectronicFenceProvider>
                                    <ElectronicFence />
                                </ElectronicFenceProvider>
                            }
                        />
                    </Route>
                    <Route element={<CheckPermissionRoute userHas={'analytics'} />}>
                        <Route
                            path="/analytics/overview"
                            element={
                                <OverviewsProvider>
                                    <Overview />
                                </OverviewsProvider>
                            }
                        />
                        <Route
                            path="/analytics/period"
                            element={
                                <PeriodProvider>
                                    <Period />
                                </PeriodProvider>
                            }
                        />
                        <Route
                            path="/analytics/fleets"
                            element={
                                <FleetsProvider>
                                    <Fleets />
                                </FleetsProvider>
                            }
                        />
                        <Route
                            path="/analytics/driver"
                            element={
                                <DriversProvider>
                                    <Driver />
                                </DriversProvider>
                            }
                        />
                        <Route
                            path="/analytics/face-missing"
                            element={
                                <FaceMissingProvider>
                                    <FaceMissing />
                                </FaceMissingProvider>
                            }
                        />

                        <Route
                            path="/analytics/last-alarm"
                            element={
                                <LastAlarmProvider>
                                    <LastAlarm />
                                </LastAlarmProvider>
                            }
                        />
                        <Route
                            path="/analytics/comparison-of-units"
                            element={
                                <ComparisonOfUnitsProvider>
                                    <ComparisonOfUnits />
                                </ComparisonOfUnitsProvider>
                            }
                        />
                    </Route>
                    <Route element={<CheckPermissionRoute userHas={'smart_report'} />}>
                        <Route
                            path="/smart-report/events"
                            element={
                                <EventsProvider>
                                    <Events />
                                </EventsProvider>
                            }
                        />
                    </Route>
                    <Route element={<CheckPermissionRoute userHas={'interventions'} />}>
                        <Route path="/interventions" element={<Interventions />} />
                    </Route>
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route element={<PublicRoutes />}>
                <Route element={<LoginRoute />}>
                    <Route path="/login" element={<Login />} />
                </Route>
                <Route path="/login/redefine-password" element={<RedefinePassword />} />
                <Route element={<ChallengeRoute />}>
                    <Route path="/login/first-access" element={<FirstAccess />} />
                    <Route path="/login/new-password" element={<CreateNewPassword />} />

                    <Route element={<MFARoute />}>
                        <Route path="/login/mfa" element={<MFALogin />} />
                        <Route path="/login/setup-mfa" element={<SetupMFA />} />
                    </Route>
                </Route>
            </Route>
        </Route>,
    ),
);

export default function AppRoutes() {
    return <RouterProvider router={router} />;
}
