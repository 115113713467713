import styled from 'styled-components';

export const Filter = styled.form`
    background: #f1f1f1;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 16px;
    padding: 16px;
    margin-top: -16px;

    border-bottom: 1px solid #d9d9d9;
`;
