import { createContext, useCallback, useMemo, useRef, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ResponseStatus } from 'src/common/core/api-response';
import { Pagination } from 'src/common/core/pagination';
import { useScrollbar } from 'src/common/hooks';
import { FunctionComponent } from 'src/common/types';
import { reducer, Reducer } from 'src/common/types/reducer';
import { EventDTO, EventRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { listEvents } from 'src/modules/alarm-processing/services/list-events.service';
import { DepartmentResume } from 'src/modules/configurations';
import { INITIAL_DEPARTMENT_STATE, INITIAL_VEHICLES_STATE } from 'src/modules/vehicle-monitoring/contexts/const';
import { Vehicle } from 'src/modules/vehicle-monitoring/domain/entities/vehicle';

import NotificationSound from '../../../../assets/audios/notification-sound-ting.mp3';
import { TypeAlarmDistribution } from '../../../../common/constants/type-alarms-distribution';
import { TypeSearch } from '../../../../common/constants/type-search';
import { useAuth } from '../../../auth';
import { EventsContextProps, EventScreenType } from './types';

export const InitialDataSearchEventsDS: string[] = [
    'Sonolência N2',
    'Sonolência N1',
    'Olhando para baixo N1',
    'Olhando para baixo N2',
    'Celular',
    'Sem cinto',
    'Fumando',
    'Oclusão',
    'Bocejo',
    'Face missing',
    'Óculos de sol',
    'Consumo alimento',
    'Fone de ouvido',
];

export const INITIAL_REQUEST_DATA: EventRequestDTO = {
    currentPage: 1,
    pageSize: 10,
    alarmLevel: '',
    alarmType: '',
    board: '',
    endTime: '',
    eventType: '',
    finalDate: new Date(),
    initialDate: new Date(),
    operation: '',
    startTime: '',
    treatedAlarms: false,
    typeSearch: TypeSearch.PARAMS,
    typeAlarmDistribution: TypeAlarmDistribution.ALL,
    text: '',
    analytics: '',
    sortProps: [
        {
            keyOrder: 'Prioridade',
            sortOrder: 'ASC',
        },
        {
            keyOrder: 'Chegada',
            sortOrder: 'ASC',
        },
    ],
};

export const EventsContext = createContext<EventsContextProps>({} as EventsContextProps);

export default function EventsProvider({ children }: FunctionComponent) {
    const [query] = useSearchParams();
    const { session } = useAuth();
    const { hideScrollBar, showScrollBar } = useScrollbar();

    const [events, setEvents] = useState<Pagination<EventDTO> | null>(null);
    const [requestData, setRequestData] = useState<EventRequestDTO>();
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
    });
    const [selectedEvents, setSelectedEvents] = useState<EventDTO[]>([]);
    const audioPlayer = useRef<HTMLAudioElement>(new Audio(NotificationSound));
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_DEPARTMENT_STATE);
    const [vehicles, setVehicles] = useReducer<Reducer<Array<Vehicle>>>(reducer, INITIAL_VEHICLES_STATE);

    const eventScreenType: EventScreenType = useMemo(() => (query?.get('search')?.includes('events') ? 'events' : 'pending'), [query]);
    INITIAL_REQUEST_DATA.alarmsName = eventScreenType === 'events' ? InitialDataSearchEventsDS : [];

    const handleGetList = useCallback(
        async (isAutomaticSearch?: boolean) => {
            if (!requestData) return;

            setResponseStatus(state => ({
                ...state,
                loading: true,
                hasError: false,
            }));

            listEvents
                .execute(requestData)
                .then(data => {
                    setEvents(data);
                    setResponseStatus({
                        loading: false,
                        error: undefined,
                        hasError: false,
                        void: !data?.elements || data?.elements?.length === 0,
                        success: true,
                    });
                    if (session?.user.notificationSound && data?.elements?.length > 0 && isAutomaticSearch) {
                        audioPlayer.current.play();
                    }
                })
                .catch(reason =>
                    setResponseStatus({
                        loading: false,
                        error: reason,
                        hasError: true,
                        void: false,
                        success: false,
                    }),
                );
        },
        [requestData, session?.user.notificationSound],
    );

    const handleRequestList = useCallback((data: Partial<EventRequestDTO>) => {
        setRequestData(state => {
            return state ? { ...state, ...data } : undefined;
        });
    }, []);

    const handleResetSelectedEvent = useCallback(() => {
        setSelectedEvents([]);
        showScrollBar();
    }, [showScrollBar]);

    const handleRemoveSelectedEvent = useCallback(
        (id: string) => {
            setSelectedEvents(selectedEvents.filter(event => event.id.toString() !== id));
        },
        [selectedEvents],
    );

    const handleSetInitialData = useCallback(
        (treatedAlarms: boolean) => {
            setRequestData({
                ...INITIAL_REQUEST_DATA,
                treatedAlarms: eventScreenType === 'events',
                typeAlarmDistribution: session?.user?.profile === 'Analista' ? TypeAlarmDistribution.DYNAMIC : TypeAlarmDistribution.ALL,
                analytics: session?.user?.profile === 'Analista' ? session?.user?.email || '' : '',
            });
        },
        [eventScreenType, session?.user?.email, session?.user?.profile],
    );

    const handleShowEventDialog = useCallback(
        (data?: EventDTO[], ignoreModal?: boolean) => {
            setSelectedEvents(data || []);

            if (ignoreModal) return;

            hideScrollBar();
            document.querySelector<HTMLDialogElement>('dialog#alarm-details-dialog')?.showModal();
        },
        [hideScrollBar],
    );

    const handleClearEvents = useCallback(() => {
        setEvents(null);
    }, []);

    const handleRemoveSelectedsEvents = useCallback(
        (ids: bigint[]) => {
            const newEventsElements = events;
            const idsToRemoveCount = ids.length;

            newEventsElements!.elements = events!.elements.filter(event => !ids.includes(event.id));
            newEventsElements!.totalElements = events!.totalElements! - idsToRemoveCount;

            setEvents(newEventsElements);
        },
        [events],
    );

    const data: EventsContextProps = useMemo(
        () => ({
            eventScreenType,
            events,
            handleGetList,
            handleRequestList,
            handleResetSelectedEvent,
            handleRemoveSelectedEvent,
            handleSetInitialData,
            handleShowEventDialog,
            operations,
            requestData,
            responseStatus,
            selectedEvents,
            handleClearEvents,
            handleRemoveSelectedsEvents,
            setOperations,
            setVehicles,
            vehicles,
        }),
        [
            eventScreenType,
            events,
            handleGetList,
            handleRequestList,
            handleResetSelectedEvent,
            handleRemoveSelectedEvent,
            handleSetInitialData,
            handleShowEventDialog,
            operations,
            requestData,
            responseStatus,
            selectedEvents,
            handleClearEvents,
            handleRemoveSelectedsEvents,
            vehicles,
        ],
    );

    return <EventsContext.Provider value={data}>{children}</EventsContext.Provider>;
}
