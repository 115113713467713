import { Column } from 'src/common/components';
import styled from 'styled-components';

export const ActionButton = styled.button.attrs(props => ({
    ...props,
    type: 'button',
}))`
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;

    color: #6da4d3;

    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
`;

export const Container = styled(Column)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 96px 0;
    gap: 8px;
`;

export const Message = styled.p`
    margin: 0;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #5f5f5f;
`;

export const Title = styled.h4`
    margin: 0;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 24px;

    color: #7a7f85;
`;
