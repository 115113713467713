import { TFunction } from 'i18next';
import { yup } from 'src/common/infra/validations/yup';

export const sortAlarmSchema = (t: TFunction) =>
    yup.object().shape({
        event: yup
            .string()
            .required(t('field_validations.required')?.toString())
            .test({
                test: value => (value === 'false' ? false : true),
                name: 'not-false',
                message: t('field_validations.required')?.toString(),
            }),
    });
