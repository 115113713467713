import { Modal as MuiModal, styled as muiStyled } from '@mui/material';
import { Column, VideoPlayer } from 'src/common/components';
import styled from 'styled-components';

interface SlideProps {
    selected?: boolean;
}

export const Modal = muiStyled(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerContainer = styled(Column)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    width: 328px;
    height: 190px;

    background: #f7f7f7;
    border-radius: 8px;
    position: relative;
    transition: 0.7s ease;

    &:hover {
        .prev,
        .next {
            display: flex;
        }
    }

    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        transition: 0.6s ease;
        user-select: none;

        align-items: center;
        justify-content: center;

        border-radius: 4px;
        width: 24px;
        height: 24px;
        z-index: 2;
        display: none;
    }

    .prev {
        left: 16px;
    }

    .next {
        right: 16px;
    }

    .prev:hover,
    .next:hover {
        background: rgba(255, 255, 255, 0.7);
    }
`;

export const Content = styled(Column)`
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    gap: 2rem;

    background: #ffffff;
    border-radius: 0.5rem;
`;

export const EventName = styled.h5`
    margin: 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #262d3c;
`;

export const StyledImage = styled.div<SlideProps>`
    @keyframes fade {
        from {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }

    display: ${props => (props?.selected ? 'flex' : 'none')};
    z-index: ${props => (props?.selected ? '1' : '0')};

    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 0;
    animation-name: fade;
    animation-duration: 1.5s;
    overflow: hidden;

    height: 190px;
    width: 328px;
`;

export const StyledVideoPlayer = styled(VideoPlayer)<SlideProps>`
    position: relative;
    width: 328px;
    height: 190px;
    left: 0;
    top: 0;

    display: ${props => (props?.selected ? 'flex' : 'none')};
    z-index: ${props => (props?.selected ? '1' : '0')};

    border-radius: 8px;
`;

export const Title = styled.h3`
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;

    color: #262d3c;
    margin: 0;
`;
