import { Skeleton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import { listPagination } from 'src/common';
import { ProtocolNames } from 'src/modules/configurations/intervention-protocol/dtos';

import {
    Column,
    Title,
    Button,
    Row,
    Content,
    NoContent,
    Divider,
    Input,
    Table,
    Pagination,
    SquareButton,
    Select,
    Eye,
    SelectNumber,
} from '../../../common/components';
import { DptoTableList } from '../../../modules/configurations';
import ButtonAddProtocol from '../../../modules/configurations/intervention-protocol/components/button-add_protocol';
import { CreateUpdateInterventionProtocolDialog } from '../../../modules/configurations/intervention-protocol/components/create-update-intervention-protocol-dialog';
import { DeleteInterventionProtocolDialog } from '../../../modules/configurations/intervention-protocol/components/delete-intervention-protocol-dialog';
import InterventionProtocolFilter from '../../../modules/configurations/intervention-protocol/components/filter-protocol-intervention';
import { useInterventionProtocol } from '../../../modules/configurations/intervention-protocol/hooks';
import {
    MainCard,
    CardHeader,
    AddNewProtocolSection,
    ButtonNewProtocol,
    InputContainer,
    SecondScreenAddNewProtocol,
    TableRow,
    TableData,
    TableSection,
    AddNewProtocolDetails,
    Intro,
    AddNewProtocolView,
    ButtonAddProtocolContainer,
    LabelInterventionProtocol,
} from './style';

export function InterventionProtocol() {
    const [addChange, setAddChange] = useState(false);

    const [editProtocolTable, setEditProtocolTable] = useState(false);
    const [addNewProtocolDetailsTable, setAddNewProtocolDetailsTable] = useState(false);

    const [deleteOptionTable, setDeleteOptionTable] = useState(false);

    const [editProtocol, setEditProtocol] = useState(false);

    const [viewProtocol, setViewProtocol] = useState(false);
    const [detailsPopUp, setDetailsPopUp] = useState(false);

    const [newProtocolDetails, setNewProtocolDetails] = useState(false);
    const [addNewProtocolView, setAddNewProtocolView] = useState(false);

    const [pageSize, setPageSize] = useState(15);

    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const {
        handleOpenDialog,
        handleOpenDeleteDialog,
        handleGetList,
        interventionProtocol,
        handleGetListByOperationName,
        handleRequestList,
        responseStatus,
        requestData,
    } = useInterventionProtocol();

    const { t } = useTranslation();

    const rowsTeste = [
        {
            id: 1,
            name: 'sua operação',
        },
    ];

    const events = [
        { label: t('tableEventName.Bocejo'), value: 'bocejo' },
        { label: t('tableEventName.Celular'), value: 'celular' },
        { label: t('tableEventName.Fumando'), value: 'fumando' },
        { label: t('tableEventName.Oclusão'), value: 'oclusão' },
        { label: t('tableEventName.Olhando para baixo N1'), value: 'olhando para baixo n1' },
        { label: t('tableEventName.Olhando para baixo N2'), value: 'olhando para baixo n2' },
        { label: t('tableEventName.Sonolência N1'), value: 'sonolência n1' },
        { label: t('tableEventName.Sonolência N2'), value: 'sonolência n2' },
        { label: t('tableEventName.Face Missing'), value: 'face missing' },
        { label: t('tableEventName.Sem Cinto'), value: 'sem cinto' },
        { label: t('tableEventName.Consumo Alimento'), value: 'consumo alimento' },
        { label: t('tableEventName.Fone de ouvido'), value: 'fone de ouvido' },
        { label: t('tableEventName.Óculos de sol'), value: 'oculos de sol' },
    ];

    const trueOrFalse = [
        { label: t('general.yes'), value: 'sim' },
        { label: t('general.no'), value: 'nao' },
    ];

    const openDialog = useCallback(
        (data: ProtocolNames) => {
            handleOpenDialog();

            handleGetListByOperationName(data);
        },
        [handleGetListByOperationName, handleOpenDialog],
    );

    const filters = useMemo(() => (filterIsOpen ? <InterventionProtocolFilter /> : null), [filterIsOpen]);

    const tableHeaders = useMemo(() => [t('general.operation'), t('intervention_protocol.protocol')], [t]);

    const handleRequest = useCallback(() => {
        handleGetList();
    }, [handleGetList]);

    const content: JSX.Element = useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Content style={{ flexDirection: 'column', width: '100%' }}>
                    <Skeleton style={{ height: '4rem', marginBottom: '-1.8rem' }} />
                    <Skeleton style={{ height: '4rem', marginBottom: '-1.8rem' }} />
                    <Skeleton style={{ height: '4rem', marginBottom: '-1.8rem' }} />
                    <Skeleton style={{ height: '4rem', marginBottom: '-1.8rem' }} />
                    <Skeleton style={{ height: '4rem', marginBottom: '-1.8rem' }} />
                    <Skeleton style={{ height: '4rem' }} />
                </Content>
            );
        }

        if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleRequest} messageClassName="error" />;
        }

        if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleRequest} />;
        }

        return <DptoTableList />;
    }, [handleRequest, responseStatus?.hasError, responseStatus.loading, responseStatus?.void, t]);

    useEffect(() => {
        handleRequest();
    }, [handleRequest]);

    const paginationContent = useMemo(() => {
        return (
            <>
                <div style={{ padding: '0px 16px 16px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Divider />
                    <Pagination
                        onPageChange={currentPage => handleRequestList({ currentPage })}
                        currentPage={interventionProtocol?.currentPage}
                        perPage={pageSize}
                        total={interventionProtocol?.totalElements}
                    />
                </div>
            </>
        );
    }, [handleRequestList, interventionProtocol?.currentPage, interventionProtocol?.totalElements, pageSize]);

    return (
        <Column gap={16}>
            <Row gap={16} align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('general.configurations')}</h2>
                </Title>
                {/* <AddNewProtocol>
                    <Button leftIcon={<IoMdAdd color="#fff" fontSize={'20px'} />} padding="0 12px">
                        {t('intervention_protocol.add_protocol')}
                    </Button>
                </AddNewProtocol> */}
            </Row>

            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>
                                {t('intervention_protocol.intervention_protocol')}
                                {responseStatus?.success && !responseStatus?.loading && (
                                    <span className="total">({interventionProtocol?.totalElements || 0})</span>
                                )}
                            </h3>
                        </Row>
                        <Row align="center" gap={16}>
                            <Row align="center" gap={8}>
                                <span className="per-page">{t('general.items_per_page')}:</span>
                                <SelectNumber
                                    fontMode="small"
                                    value={requestData?.pageSize || 15}
                                    options={listPagination}
                                    onChange={e => handleRequestList({ pageSize: Number(e.target.value), currentPage: 1 })}
                                />
                            </Row>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {filters}
                </Column>
                <TableSection>
                    <div>
                        {responseStatus.loading ? (
                            <>{content}</>
                        ) : (
                            <Table tableHeaders={tableHeaders} style={{ marginTop: '1rem' }} allowSort={false}>
                                {interventionProtocol?.elements.map((data: any, i) => {
                                    if (!data) {
                                        return null;
                                    }
                                    return (
                                        <TableRow className="body pointer" key={Math.random() * i}>
                                            <TableData>{data.operation}</TableData>
                                            <TableData>{data.intervention_protocol_name}</TableData>
                                            {/* <TableData>
                                                <EditFill
                                                    onClick={handleOpenDialog}
                                                    color="#48546E"
                                                    style={{ fontSize: '20px', fontWeight: '900', cursor: 'pointer' }}
                                                />
                                            </TableData> */}
                                            <TableData>
                                                <Eye
                                                    onClick={() => openDialog(data)}
                                                    color="#46526C"
                                                    style={{ fontSize: '16px', fontWeight: '900', cursor: 'pointer' }}
                                                />
                                            </TableData>
                                            {/* <TableData>
                                                <Trash
                                                    onClick={handleOpenDeleteDialog}
                                                    color="#EF1D00"
                                                    style={{ fontSize: '20px', cursor: 'pointer' }}
                                                />
                                            </TableData> */}
                                        </TableRow>
                                    );
                                })}
                            </Table>
                        )}
                    </div>
                </TableSection>

                {paginationContent}

                {/* INICIO ADICIONAR NOVOS PROTOCOLOS */}

                {addChange && (
                    <AddNewProtocolSection>
                        <Intro>
                            <div>
                                <h2>{t('intervention_protocol.add_protocol')}</h2>
                            </div>
                            <div>
                                <SecondScreenAddNewProtocol onClick={() => setAddChange(false)}>
                                    <SquareButton bgColor="#262D3C">
                                        <CgClose color="#ffffff" fontSize={'20px'} />
                                    </SquareButton>
                                </SecondScreenAddNewProtocol>
                            </div>
                        </Intro>

                        <InputContainer>
                            <div>
                                <LabelInterventionProtocol>{t('alarms.operation')}</LabelInterventionProtocol>
                                <Input name="operation" placeholder={String(t('intervention_protocol.enter_the_name_of_the_operation'))}></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.protocol')}</LabelInterventionProtocol>
                                <Input name="protocol" placeholder={String(t('intervention_protocol.enter_protocol_name'))}></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.group_whatsapp')}</LabelInterventionProtocol>
                                <Input name="group_whatsapp" placeholder={String(t('intervention_protocol.enter_group_name'))}></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.observation')}</LabelInterventionProtocol>
                                <Input
                                    style={{ height: '150px' }}
                                    name="observation"
                                    placeholder={String(t('intervention_protocol.type_the_note'))}
                                ></Input>
                            </div>
                        </InputContainer>

                        <ButtonNewProtocol>
                            <Button color="outline" size="medium" onClick={() => setAddChange(false)}>
                                {t('intervention_protocol.cancel')}
                            </Button>
                            <Button
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    setAddNewProtocolView(true);
                                    setAddChange(false);
                                }}
                            >
                                {t('intervention_protocol.advance')}
                            </Button>
                        </ButtonNewProtocol>
                    </AddNewProtocolSection>
                )}

                {addNewProtocolView && (
                    <AddNewProtocolView>
                        <Intro>
                            <div>
                                <h2>{t('intervention_protocol.add_details')}</h2>
                            </div>
                            <div>
                                <SecondScreenAddNewProtocol onClick={() => setAddNewProtocolView(false)}>
                                    <SquareButton bgColor="#262D3C">
                                        <CgClose color="#ffffff" fontSize={'20px'} />
                                    </SquareButton>
                                </SecondScreenAddNewProtocol>
                            </div>
                        </Intro>

                        <ButtonAddProtocolContainer
                            onClick={() => {
                                setNewProtocolDetails(true);
                                setAddNewProtocolView(false);
                            }}
                        >
                            <ButtonAddProtocol />
                        </ButtonAddProtocolContainer>

                        <TableSection>
                            <div>
                                {responseStatus.loading ? (
                                    <>{content}</>
                                ) : (
                                    <Table tableHeaders={tableHeaders} style={{ marginTop: '1.2rem' }} allowSort={true}>
                                        {rowsTeste.map((data: any) => {
                                            return (
                                                <TableRow className="body pointer" key={Math.random() * data.id}>
                                                    <TableData>{}</TableData>
                                                    <TableData>{}</TableData>
                                                    <TableData>{}</TableData>
                                                </TableRow>
                                            );
                                        })}
                                    </Table>
                                )}
                            </div>
                        </TableSection>

                        <ButtonNewProtocol>
                            <Button
                                color="outline"
                                size="medium"
                                onClick={() => {
                                    setAddChange(true);
                                    setAddNewProtocolView(false);
                                }}
                            >
                                {t('intervention_protocol.cancel')}
                            </Button>
                            <Button color="primary" size="medium" onClick={() => setAddNewProtocolView(false)}>
                                {t('intervention_protocol.to_add')}
                            </Button>
                        </ButtonNewProtocol>
                    </AddNewProtocolView>
                )}

                {newProtocolDetails && (
                    <AddNewProtocolDetails>
                        <Intro>
                            <div>
                                <h2>{t('intervention_protocol.add_details')}</h2>
                            </div>
                            <div>
                                <SecondScreenAddNewProtocol onClick={() => setNewProtocolDetails(false)}>
                                    <SquareButton bgColor="#262D3C">
                                        <CgClose color="#ffffff" fontSize={'20px'} />
                                    </SquareButton>
                                </SecondScreenAddNewProtocol>
                            </div>
                        </Intro>
                        <InputContainer>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.event')}</LabelInterventionProtocol>
                                <Select name="events" options={events} placeholder={String(t('intervention_protocol.select_the_event'))}></Select>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.amount')}</LabelInterventionProtocol>
                                <Input type="number" name="amount" placeholder={String(t('intervention_protocol.enter_the_amount'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.time')}</LabelInterventionProtocol>
                                <Input type="number" name="time" placeholder={String(t('intervention_protocol.enter_the_time'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.wait')}</LabelInterventionProtocol>
                                <Input type="number" name="wait" placeholder={String(t('intervention_protocol.enter_the_wait'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.send_image')}</LabelInterventionProtocol>
                                <Select
                                    options={trueOrFalse}
                                    name="send_image"
                                    placeholder={String(t('intervention_protocol.select_the_option'))}
                                ></Select>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.send_video')}</LabelInterventionProtocol>
                                <Select
                                    options={trueOrFalse}
                                    name="send_video"
                                    placeholder={String(t('intervention_protocol.select_the_option'))}
                                ></Select>
                            </div>
                        </InputContainer>
                        <ButtonNewProtocol>
                            <Button
                                color="outline"
                                size="medium"
                                onClick={() => {
                                    setAddNewProtocolView(true);
                                    setNewProtocolDetails(false);
                                }}
                            >
                                {t('intervention_protocol.back')}
                            </Button>
                            <Button
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    setAddNewProtocolView(true);
                                    setNewProtocolDetails(false);
                                }}
                            >
                                {t('intervention_protocol.confirm')}
                            </Button>
                        </ButtonNewProtocol>
                    </AddNewProtocolDetails>
                )}

                {/* {deleteOptionTable && (
                    <DeleteOptionTable>
                        <div>
                            <h2>{t('intervention_protocol.delete_protocol')}</h2>
                            <div>
                                <p>{t('intervention_protocol.want_to_delete_the_protocol_event_protocol_name')}</p>
                                <p>{t('intervention_protocol.this_action_cannot_be_undone')}</p>
                            </div>
                        </div>
                        <ButtonsDeleteContainer>
                            <Button
                                color="outline"
                                size="large"
                                onClick={() => {
                                    setDetailsPopUp(true);
                                    setDeleteOptionTable(false);
                                }}
                            >
                                {t('intervention_protocol.cancel')}
                            </Button>
                            <Button
                                color="alert"
                                size="large"
                                onClick={() => {
                                    setDeleteOptionTable(false);
                                    setDetailsPopUp(true);
                                }}
                            >
                                {t('intervention_protocol.delete')}
                            </Button>
                        </ButtonsDeleteContainer>
                    </DeleteOptionTable>
                )} */}

                {/* {viewProtocol && (
                    <ViewProtocolSection>
                        <Intro>
                            <div>
                                <h2>{t('intervention_protocol.protocol')}</h2>
                            </div>
                            <div>
                                <SquareButton bgColor="#D7D7D7" onClick={() => setDetailsPopUp(true)}>
                                    <CiViewList color="#262D3C" fontSize={'20px'} />
                                </SquareButton>
                                <SecondScreenAddNewProtocol onClick={() => setViewProtocol(false)}>
                                    <SquareButton bgColor="#262D3C">
                                        <CgClose color="#ffffff" fontSize={'20px'} />
                                    </SquareButton>
                                </SecondScreenAddNewProtocol>
                            </div>
                        </Intro>
                        <InputContainer>
                            <div>
                                <LabelInterventionProtocol>{t('alarms.operation')}</LabelInterventionProtocol>
                                <Input name="operation" placeholder="CBN STA CRUZ WILLY LUNA" disabled></Input>
                                <EditFill color="#48546E" onClick={() => setEditProtocol(true)} />
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.protocol')}</LabelInterventionProtocol>
                                <Input name="protocol" placeholder="ABInBev SAZ Default SEM VIDEO" disabled></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.group_whatsapp')}</LabelInterventionProtocol>
                                <Input name="group_whatsapp" placeholder="Anomalías Argus - BOLIVIA" disabled></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.observation')}</LabelInterventionProtocol>
                                <Input
                                    style={{ height: '150px' }}
                                    name="observation"
                                    placeholder={String(t('intervention_protocol.type_the_note'))}
                                    disabled
                                ></Input>
                            </div>
                        </InputContainer>
                        <ButtonNewProtocol>
                            <Button color="outline" size="medium" onClick={() => false}>
                                {t('intervention_protocol.close')}
                            </Button>
                            <Button color="primary" size="medium" onClick={() => setDetailsPopUp(true)}>
                                {t('intervention_protocol.advance')}
                            </Button>
                        </ButtonNewProtocol>
                    </ViewProtocolSection>
                )} */}

                {/* {editProtocolTable && (
                    <EditProtocolTable>
                        <Intro>
                            <div>
                                <h2>{t('intervention_protocol.edit_details')}</h2>
                            </div>
                            <div>
                                <SquareButton onClick={() => setEditProtocolTable(false)} bgColor="#262D3C">
                                    <CgClose color="#ffffff" fontSize={'20px'} />
                                </SquareButton>
                            </div>
                        </Intro>

                        <InputContainer>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.event')}</LabelInterventionProtocol>
                                <Select name="events" options={events} placeholder={String(t('intervention_protocol.select_the_event'))}></Select>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.amount')}</LabelInterventionProtocol>
                                <Input name="amount" type="number" placeholder={String(t('intervention_protocol.enter_the_amount'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.time')}</LabelInterventionProtocol>
                                <Input name="time" type="number" placeholder={String(t('intervention_protocol.enter_the_time'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.wait')}</LabelInterventionProtocol>
                                <Input name="wait" type="number" placeholder={String(t('intervention_protocol.enter_the_wait'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.send_image')}</LabelInterventionProtocol>
                                <Select
                                    options={trueOrFalse}
                                    name="send_image"
                                    placeholder={String(t('intervention_protocol.select_the_option'))}
                                ></Select>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.send_video')}</LabelInterventionProtocol>
                                <Select
                                    options={trueOrFalse}
                                    name="send_video"
                                    placeholder={String(t('intervention_protocol.select_the_option'))}
                                ></Select>
                            </div>
                        </InputContainer>

                        <ButtonNewProtocol>
                            <Button
                                color="outline"
                                size="medium"
                                onClick={() => {
                                    setDetailsPopUp(true);
                                    setEditProtocolTable(false);
                                }}
                            >
                                {t('intervention_protocol.cancel')}
                            </Button>
                            <Button
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    setEditProtocolTable(false);
                                    setDetailsPopUp(true);
                                }}
                            >
                                {t('intervention_protocol.confirm')}
                            </Button>
                        </ButtonNewProtocol>
                    </EditProtocolTable>
                )} */}
                {/*
                {addNewProtocolDetailsTable && (
                    <AddNewProtocolDetailsTable>
                        <Intro>
                            <div>
                                <h2>{t('intervention_protocol.add_details')}</h2>
                            </div>
                            <div>
                                <SecondScreenAddNewProtocol onClick={() => setNewProtocolDetails(false)}>
                                    <SquareButton bgColor="#262D3C">
                                        <CgClose color="#ffffff" fontSize={'20px'} />
                                    </SquareButton>
                                </SecondScreenAddNewProtocol>
                            </div>
                        </Intro>
                        <InputContainer>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.event')}</LabelInterventionProtocol>
                                <Select name="events" options={events} placeholder={String(t('intervention_protocol.select_the_event'))}></Select>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.amount')}</LabelInterventionProtocol>
                                <Input type="number" name="amount" placeholder={String(t('intervention_protocol.enter_the_amount'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.time')}</LabelInterventionProtocol>
                                <Input type="number" name="time" placeholder={String(t('intervention_protocol.enter_the_time'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.wait')}</LabelInterventionProtocol>
                                <Input type="number" name="wait" placeholder={String(t('intervention_protocol.enter_the_wait'))} required></Input>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.send_image')}</LabelInterventionProtocol>
                                <Select
                                    options={trueOrFalse}
                                    name="send_image"
                                    placeholder={String(t('intervention_protocol.select_the_option'))}
                                ></Select>
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.send_video')}</LabelInterventionProtocol>
                                <Select
                                    options={trueOrFalse}
                                    name="send_video"
                                    placeholder={String(t('intervention_protocol.select_the_option'))}
                                ></Select>
                            </div>
                        </InputContainer>
                        <ButtonNewProtocol>
                            <Button
                                color="outline"
                                size="medium"
                                id="cancel-btn"
                                onClick={() => {
                                    setDetailsPopUp(true);
                                    setAddNewProtocolDetailsTable(false);
                                }}
                            >
                                {t('intervention_protocol.cancel')}
                            </Button>
                            <Button
                                color="primary"
                                size="medium"
                                onClick={() => {
                                    setDetailsPopUp(true);
                                    setAddNewProtocolDetailsTable(false);
                                }}
                            >
                                {t('intervention_protocol.confirm')}
                            </Button>
                        </ButtonNewProtocol>
                    </AddNewProtocolDetailsTable>
                )} */}
            </MainCard>
            <CreateUpdateInterventionProtocolDialog />
            <DeleteInterventionProtocolDialog />
        </Column>
    );
}
