import styled from 'styled-components';

export const CloseButton = styled.button`
    border: 0;
    box-sizing: border-box;
    height: 30px;
    padding: 0;
    width: 30px;

    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &:hover {
        background-color: buttonface;
    }
`;

export const Dialog = styled.dialog`
    background: #ffffff;
    border-radius: 8px;
    border: 0;
    padding: 0;
`;

export const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px 32px;
    gap: 32px;
    width: 334px;

    h4 {
        color: #46526c;
        margin: 0;

        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 0;

        color: #4d4d4d;
    }
`;
