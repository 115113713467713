import { InputHTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

interface ColorBoxProps {
    color: string;
}

const fromLeft = keyframes`
    0% {
      left: 0;
      transform: translateX(0);
    }
    100% {
      left: 100%;
      transform: translateX(-100%);
    }
`;

export const ColorBox = styled.input.attrs<ColorBoxProps, InputHTMLAttributes<HTMLInputElement>>(props => ({ ...props, type: 'button' }))`
    width: 1rem;
    height: 1rem;
    background-color: ${props => props.color};
    border-radius: 2px;
    border: none;
    transition: transform 500ms;

    &:enabled:focus,
    &:enabled:focus-within,
    &:enabled:hover {
        cursor: pointer;
    }

    &:hover,
    &:focus {
        transform: scale(1.25);
        z-index: 1;
    }
`;

export const Form = styled.form`
    position: relative;
    overflow: auto;
    animation: ${fromLeft} 0.2s normal;
    flex: 1 656px;
    min-width: 656px;

    @media screen and (max-height: 769px) {
        height: 90vh;
    }

    .small-map {
        width: 696px;
        height: 390px;
        border-radius: 8px;
        transition: height 0.3s ease-in, width 0.2s ease-out;
    }
`;

export const InputColor = styled.input.attrs(props => ({ ...props, type: 'color' }))`
    border-radius: 4px;
    border: none;

    &:enabled:focus,
    &:enabled:focus-within,
    &:enabled:hover {
        cursor: pointer;
    }

    &::-webkit-color-swatch-wrapper {
        padding: 0;
        border-radius: 4px;
    }

    &::-webkit-color-swatch {
        border-color: #e6e6e6;
        border-width: 1px;
        border-radius: 4px;
    }
`;

export const Paragraph = styled.p`
    cursor: default;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #262d3c;
`;
