import { createContext, useCallback, useMemo, useReducer, useState } from 'react';

import { DateHelper, FunctionComponent } from '../../../../common';
import { Reducer, reducer } from '../../../../common/types/reducer';
import { DepartmentResume } from '../../../configurations';
import { GenericAnalyticsFilterDTO } from '../../common/dtos/generic-filter.dto';
import { INITIAL_OPERATION_STATE } from './const';
import { EventsContextProps } from './types';

export const EventsContext = createContext({} as EventsContextProps);

export const INIT_FILTER_STATE: GenericAnalyticsFilterDTO = {
    finalDate: new Date(),
    initialDate: DateHelper.yesterday(),
    operationInput: '',
    operations: [],
};

export function EventsProvider({ children }: FunctionComponent) {
    const [filter, setFilter] = useState<GenericAnalyticsFilterDTO>(INIT_FILTER_STATE);
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_OPERATION_STATE);

    const reset = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(true);
        setOperations({
            type: 'data',
            payload: [],
        });
    }, []);

    const handleFilterIsOpen = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const data: EventsContextProps = useMemo(
        () => ({
            filter,
            setFilter,
            filterIsOpen,
            handleFilterIsOpen,
            operations,
            setOperations,
            reset,
        }),
        [filter, filterIsOpen, handleFilterIsOpen, operations, reset],
    );

    return <EventsContext.Provider value={data}>{children}</EventsContext.Provider>;
}
