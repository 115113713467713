import { Fragment, MouseEventHandler, useId, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, Column, Dialog, Divider, Row } from 'src/common/components';

import { Container, Details, ModalTitle, Summary } from './styles';

interface SetupKeyProps {
    code: string;
}


const firstWordUpperCase = (value: string) => value[0].toUpperCase() + value.substring(1);

export default function SetupKey({ code }: SetupKeyProps) {
    const { t } = useTranslation();

    const id = useId();
    const dialogRef = useRef<HTMLDialogElement>(null);

    const [firstPart, secondPart] = t('auth.unable_to_scan').split('X');

    const handleOpen: MouseEventHandler<HTMLElement> = () => {
        dialogRef?.current?.showModal();
    };

    const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
        dialogRef?.current?.close();
    };

    return (
        <Fragment>
            <Container>
                {firstPart}
                <Details>
                    <Summary onClick={handleOpen}>{t('auth.setup_key')}</Summary>
                </Details>
                {secondPart}
            </Container>
            <Dialog ref={dialogRef} id={id}>
                <Column padding="24px" gap={32} align="flex-start">
                    <Column gap={16} align="flex-start" width="100%">
                        <Row gap={48} align="flex-start" justify="space-between" width="100%">
                            <ModalTitle>{firstWordUpperCase(t('auth.setup_key'))}</ModalTitle>
                            <CloseButton type="button" onClick={handleClose} />
                        </Row>
                        <Divider />
                        <Column gap={16} align="flex-start" justify="start">
                            <Container>{code}</Container>
                        </Column>
                    </Column>
                </Column>
            </Dialog>
        </Fragment>
    );
}
