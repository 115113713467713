import { ApiResponse } from 'src/common/core';
import { Pagination } from 'src/common/core/pagination';
import { TransformRequestHelper } from 'src/common/helpers';
import { api } from 'src/common/infra';

import { ListPasswordPoliciesDTO, PasswordPolicy } from '../dtos';
import { CreatePasswordPolicyFormDTO } from '../dtos/create-password-policy.dto';
import { DeleteInBulkDTO, DeletePasswordPolicyDTO } from '../dtos/delete-password-policy.dto';
import { PasswordPolicyMapper } from '../mappers/password-policy.mapper';

export class PasswordPolicyService {
    public async create(form: CreatePasswordPolicyFormDTO & { operationId: number }): Promise<void> {
        const data = PasswordPolicyMapper.fromFormToDTO(form);

        await api.post('/password-policies', data);
    }

    public async delete(data: DeletePasswordPolicyDTO): Promise<void> {
        const { id, signal } = data;

        await api.delete(`/password-policies/${id}`, {
            signal,
        });
    }

    public async deleteInBulk(data: DeleteInBulkDTO): Promise<void> {
        const { ids, signal } = data;

        await api.delete('/password-policies-in-bulk', {
            signal,
            data: {
                ids,
            },
        });
    }

    public async listPolicies(data: ListPasswordPoliciesDTO & { signal: AbortSignal }): Promise<Pagination<PasswordPolicy>> {
        const { pageSize, currentPage, active, text, signal } = data;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<PasswordPolicy>>>('/password-policies', {
            params: {
                active,
                text,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
            signal,
        });

        return content;
    }

    public async update(form: CreatePasswordPolicyFormDTO & { operationId: number; id: string }): Promise<void> {
        const { id } = form;
        const data = PasswordPolicyMapper.fromFormToDTO(form);

        await api.put(`/password-policies/${id}`, data);
    }
}

export const passwordPolicyService = new PasswordPolicyService();
