import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CardHeader, Column, Content, Divider, MainCard, NoContent, Pagination, RenderIf, Row, SelectNumber, Skeleton, Title } from 'src/common';
import TableList from 'src/modules/alarm-processing/review-request/components/table-list';
import { useReviewRequest } from 'src/modules/alarm-processing/review-request/hooks';

import UpdateData from '../../../common/components/update-data';
import { ReviewRequestDialog } from '../../../modules/alarm-processing/review-request/components/review-dialog';

export function ReviewRequest() {
    const { t } = useTranslation();

    const { handleGetList, responseStatus, requestData, reviews, handleRequestList } = useReviewRequest();

    useEffect(() => {
        handleGetList();
    }, [handleGetList]);

    useEffect(() => {
        return () => {
            handleRequestList({
                currentPage: 1,
                pageSize: 15,
            });
        };
    }, [handleRequestList]);

    const content = useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return <TableList />;
    }, [handleGetList, responseStatus?.hasError, responseStatus.loading, responseStatus?.void, t]);

    return (
        <Column gap={16}>
            <Row gap={16} align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('menu.processing_alarms')}</h2>
                    <UpdateData onClick={handleGetList} />
                </Title>
            </Row>
            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>
                                {t('review_request.analysis_requests')}
                                {responseStatus?.success && !responseStatus?.loading && (
                                    <span className="total">({reviews?.totalElements || 0})</span>
                                )}
                            </h3>
                        </Row>
                        <Row align="center" gap={16}>
                            <Row align="center" gap={8}>
                                <span className="per-page">{t('general.items_per_page')}:</span>
                                <SelectNumber
                                    fontMode="small"
                                    value={requestData?.pageSize || 15}
                                    options={[15, 25, 50]}
                                    onChange={e => handleRequestList({ pageSize: Number(e.target.value), currentPage: 1 })}
                                />
                            </Row>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {content}
                    <Divider />
                    <RenderIf condition={!!reviews?.totalElements}>
                        <Pagination
                            perPage={requestData?.pageSize}
                            total={reviews?.totalElements}
                            onPageChange={currentPage =>
                                handleRequestList({
                                    currentPage,
                                })
                            }
                            currentPage={requestData?.currentPage}
                        />
                    </RenderIf>
                </Column>
            </MainCard>
            <ReviewRequestDialog />
        </Column>
    );
}
