import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';

import Button from '../../button';
import { ButtonDiv, CloseButton, GraphDiv, OptionsText, TitleDiv } from './style';

export interface FilterProps {
    children: ReactNode;
    handleApplyButton?: () => void;
    handleCancelButton?: () => void;
}

export function FilterGraph({ children, handleApplyButton, handleCancelButton }: FilterProps) {
    const { t } = useTranslation();

    return (
        <GraphDiv>
            <TitleDiv>
                <OptionsText>{t('period.options')}</OptionsText>
                <CloseButton onClick={handleCancelButton}>
                    <CgClose size={20} />
                </CloseButton>
            </TitleDiv>
            {children}
            <ButtonDiv>
                <Button color="outline" style={{ width: '50%' }} onClick={handleCancelButton}>
                    {t('general.cancel')}
                </Button>
                <Button type="submit" style={{ width: '50%' }} onClick={handleApplyButton}>
                    {t('general.apply')}
                </Button>
            </ButtonDiv>
        </GraphDiv>
    );
}
