import styled from 'styled-components';

interface SkeletonCardContainerProps {
    isSidebarOpen: boolean;
}

export const SkeletonCardContainer  = styled.div<SkeletonCardContainerProps>`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 0.5rem;
    width: ${props => (props?.isSidebarOpen ? '22.5rem' : '26.5rem')};
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

export const SkeletonHeader = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
`;

interface SkeletonHeaderIconProps {
    color?: string;
}

export const SkeletonHeaderIcon = styled.div<SkeletonHeaderIconProps>`
display: flex;
align-items: center;
justify-content: center;
width: 4rem;
height: 4rem;
padding: 0.8rem 1rem;
border-radius: 0.5rem;
background-color: ${props => props.color || '#4f8dca'};
margin-top: -70px;
box-shadow: 0 6px 8px -3px ${props => props.color || '#4f8dca'}, 0 4px 6px -3px rgba(0, 0, 0, 0.8);
`
export const SkeletonTitle = styled.div``;
