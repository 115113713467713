import { UserProps } from './constants/user-props';
import { Avatar, UserBox } from './styles';

export default function User(user: UserProps) {
    return (
        <UserBox>
            <Avatar>{user?.name?.[0]?.toUpperCase()}</Avatar>
            <p>{user?.name}</p>
        </UserBox>
    );
}
