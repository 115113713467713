import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';

import { Badge, CloseButton, Row, Textarea } from '../../../../../common';
import { DateHelper } from '../../../helpers/date.helper';
import { useReviewRequest } from '../../hooks';
import { ModalInfos, Title, ModalContainer, Intro, JustificationContainer } from './styles';

export function ReviewRequestModal() {
    const { t, i18n } = useTranslation();

    const { selectedReview, screenType, handleOpenDialog } = useReviewRequest();

    if (!selectedReview) {
        return <></>;
    }

    return (
        <ModalContainer>
            <Intro>
                <div>
                    <Title>{t('review_request.analysis_request')}</Title>
                    <Badge
                        style={{ width: '7.8rem' }}
                        className={selectedReview.status === 'Analisado' ? 'success' : 'pending'}
                        label={selectedReview.status === 'Analisado' ? t('review_request.success') : t('review_request.pending')}
                    />
                </div>
                <div>
                    <CloseButton onClick={() => handleOpenDialog(null, 0)}>
                        <CgClose color="#262D3C" fontSize={'20px'} />
                    </CloseButton>
                </div>
            </Intro>
            <ModalInfos>
                <Row>
                    <div>
                        <label>{t('review_request.request_number')}:</label>
                        <span>{String(selectedReview?.orderId)}</span>
                    </div>
                    <div>
                        <label>{t('review_request.request_time')}:</label>
                        <span>{DateHelper.format(selectedReview.orderDate, i18n.language, 'UTC')}</span>
                    </div>
                    <div>
                        <label>{t('review_request.operation')}:</label>
                        <span>{selectedReview?.operation}</span>
                    </div>
                    <div>
                        <label>{t('review_request.fleet')}:</label>
                        <span>{selectedReview?.fleet}</span>
                    </div>
                    <div>
                        <label>{t('review_request.event')}:</label>
                        <span>{t(`tableEventName.${selectedReview.answerTime ? selectedReview.preAnalysisEvent : selectedReview.event}`)}</span>
                    </div>
                    <div>
                        <label>{t('review_request.event_time')}:</label>
                        <span>{DateHelper.format(selectedReview?.alarmTime)}</span>
                    </div>
                    <div>
                        <label>{t('review_request.post_analysis')}:</label>
                        <span>{selectedReview?.postAnalysisEvent ? t(`tableEventName.${selectedReview?.postAnalysisEvent}`) : '---'}</span>
                    </div>
                </Row>
            </ModalInfos>
            <JustificationContainer>
                <Row>
                    <div>
                        {screenType === 0 ? (
                            <div>
                                <h2>{t('review_request.justification')}</h2>
                                <Textarea name="justification" disabled readOnly value={selectedReview.justification} />
                            </div>
                        ) : (
                            <div>
                                <h2>{t('review_request.answer')}</h2>
                                <Textarea name="answer" disabled readOnly value={selectedReview?.answer || ''} />
                            </div>
                        )}
                    </div>
                </Row>
            </JustificationContainer>
        </ModalContainer>
    );
}
