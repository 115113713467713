import { Tooltip } from '@mui/material';
import { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, useMemo } from 'react';
import { Column, Feedback } from 'src/common/components';
import { useFeedback } from 'src/common/hooks';
import { FeedbackType } from 'src/common/types';

import { InputProps as DefaultInputProps } from '../index';
import Label from '../label';
import { StyledInput } from '../styles';

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
    DefaultInputProps & {
        name: string;
        showFeedback?: boolean;
    };

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
    {
        feedback = {
            message: '',
            type: FeedbackType.ERROR,
        },
        hint,
        isLoading,
        label,
        name,
        showFeedback,
        tooltip,
        ...props
    },
    ref,
) => {
    const { className } = useFeedback({ ...feedback, type: feedback.type || FeedbackType.ERROR });

    const feedbackComponent = useMemo(() => {
        if (feedback?.message && showFeedback) {
            return <Feedback {...feedback} maxLength={props?.maxLength || 191} />;
        }

        return null;
    }, [feedback, props?.maxLength, showFeedback]);

    return (
        <Tooltip arrow title={tooltip} placement="top-start">
            <Column align="flex-start" gap={4} style={props?.style} flex>
                <Label label={label} hint={hint} htmlFor={props?.id || name} isLoading={isLoading}>
                    <StyledInput className={className} id={props?.id || name} name={name} ref={ref} maxLength={191} {...props} />
                </Label>
                {feedbackComponent}
            </Column>
        </Tooltip>
    );
};

const Input = forwardRef(InputBase);

Input.defaultProps = {
    showFeedback: true,
};

export default Input;
