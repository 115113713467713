import { ApiResponse, Service } from 'src/common/core';
import { api } from 'src/common/infra';

import { SyntheticDataDTO, SyntheticDataRequestDTO } from '../dtos/event.dto';

export class GetSyntheticDataService implements Service<SyntheticDataRequestDTO, SyntheticDataDTO> {
    public async execute(request: SyntheticDataRequestDTO): Promise<SyntheticDataDTO> {
        const { period } = request;

        const {
            data: { content },
        } = await api.get<ApiResponse<SyntheticDataDTO>>(`/alarms-processing/synthetic-data`, {
            params: {
                period,
            },
        });

        return content;
    }
}

export const getSyntheticData = new GetSyntheticDataService();
