import moment from 'moment';

import { Service, api } from '../../../common';
import { OverviewLocationDTO, OverviewLocationsRequestDTO } from '../dtos/overview';

class OverviewServiceLocations implements Service<OverviewLocationDTO, OverviewLocationsRequestDTO[]> {
    public async execute({ dateFrom, dateTo, equipament, events, operations }: OverviewLocationDTO): Promise<OverviewLocationsRequestDTO[]> {
        const {
            data: { content },
        } = await api.get('/analytics/overview/locations', {
            params: {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                equipament,
                events,
                operations
            },
        });

        return content;
    }
}

export const overviewServiceLocations = new OverviewServiceLocations();
