import styled from 'styled-components';

export const Card = styled.div`
    background: #ffffff;

    box-shadow: -1px 1px 4px 1px rgba(3, 3, 3, 0.15);
    border-radius: 8px;
    padding: 16px 24px;
    box-sizing: border-box;
    display: flex;
`;
