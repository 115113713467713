import { FunctionComponent } from '..';

import ListLayoutProvider from './list-layout/provider';
import LoaderProvider from './loader/provider';
import MapProvider from './map/provider';
import SearchProvider from './search/provider';
import SidebarProvider from './sidebar/provider';

export default function CommonProviders({ children }: FunctionComponent) {
    return (
        <LoaderProvider>
            <SidebarProvider>
                <MapProvider>
                    <SearchProvider>
                        <ListLayoutProvider>{children}</ListLayoutProvider>
                    </SearchProvider>
                </MapProvider>
            </SidebarProvider>
        </LoaderProvider>
    );
}
