import { SVGProps } from 'react';

export default function Settings(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0001 4.14648C13.3569 4.14648 12.7941 4.55028 11.6684 5.35786L10.067 6.50676L10.067 6.50676C9.88758 6.63548 9.79787 6.69983 9.70183 6.75384C9.60579 6.80785 9.50419 6.85106 9.301 6.93749L7.57149 7.67319C6.2472 8.2365 5.58506 8.51816 5.25082 9.09256C4.91659 9.66696 4.99888 10.3818 5.16346 11.8115L5.36283 13.5433C5.38913 13.7717 5.40228 13.886 5.40228 14.0007C5.40228 14.1155 5.38913 14.2297 5.36283 14.4582L5.16346 16.19C4.99888 17.6196 4.91659 18.3345 5.25082 18.9089C5.58506 19.4833 6.2472 19.7649 7.57149 20.3283L9.301 21.064C9.50419 21.1504 9.60579 21.1936 9.70183 21.2476C9.79787 21.3016 9.88758 21.366 10.067 21.4947L11.6684 22.6436C12.7941 23.4512 13.3569 23.855 14.0001 23.855C14.6433 23.855 15.2062 23.4512 16.3318 22.6436L17.9332 21.4947L17.9332 21.4947C18.1126 21.366 18.2023 21.3016 18.2984 21.2476C18.3944 21.1936 18.496 21.1504 18.6992 21.064L18.6992 21.064L20.4287 20.3283C21.753 19.7649 22.4152 19.4833 22.7494 18.9089C23.0836 18.3345 23.0013 17.6196 22.8368 16.19L22.6374 14.4582C22.6111 14.2297 22.5979 14.1155 22.5979 14.0007C22.5979 13.886 22.6111 13.7717 22.6374 13.5433L22.8368 11.8115C23.0013 10.3818 23.0836 9.66696 22.7494 9.09256C22.4152 8.51816 21.753 8.2365 20.4287 7.67319L18.6992 6.93749L18.6992 6.93749C18.496 6.85106 18.3944 6.80784 18.2984 6.75384C18.2023 6.69983 18.1126 6.63548 17.9332 6.50676L16.3318 5.35786C15.2062 4.55028 14.6433 4.14648 14.0001 4.14648ZM14.0001 17.7519C16.1285 17.7519 17.854 16.0725 17.854 14.0007C17.854 11.929 16.1285 10.2496 14.0001 10.2496C11.8716 10.2496 10.1461 11.929 10.1461 14.0007C10.1461 16.0725 11.8716 17.7519 14.0001 17.7519Z"
                fill="#262D3C"
            />
        </svg>
    );
}
