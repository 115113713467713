import styled from 'styled-components';

export const ChartContainer = styled.div`
    padding: 1rem 1rem 2.5rem;
    height: 50vh;
    border: 1px solid #d9d9d9;
    border-radius: 0.6rem;
    width: 49%;
`;

export const LargeChartContainer = styled.div`
    padding: 1rem 1rem 0rem;
    height: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 0.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    > div {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        button {
            border: 0;
            background-color: white;
        }
    }
`;

const BaseText = styled.p`
    letter-spacing: 0em;
    text-align: left;
`;

export const ChartSubTitle = styled(BaseText)`
    font-size: 0.813rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-top: -20px;
    color: #b9b6b6;
`;

export const ChartContent = styled.div`
    height: 85%;
    margin-top: 1rem;
`;

export const PageChange = styled.section`
    div {
        padding: 5px;
    }
`;
