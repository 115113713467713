import styled from 'styled-components';

interface DividerProps {
    color?: string;
}

const Divider = styled.hr<DividerProps>`
    height: 1px;
    width: 100%;
    margin: 0;
    background-color: ${props => props?.color || '#d9d9d9'};
    border: 0;
`;

export default Divider;
