import { SVGProps } from 'react';

export default function Time(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="11" cy="11.0003" r="9.33333" stroke="#A9A9A9" strokeWidth="2" />
            <path d="M15 11H11.25C11.1119 11 11 10.8881 11 10.75V7" stroke="#A9A9A9" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}
