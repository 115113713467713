import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CancelButton, Divider } from 'src/common';
import { toast } from 'src/common/components/toast';

import { ButtonsDeleteContainer, DeleteProtocolContainer } from './styles';

export function DeleteInterventionProtocolForm() {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = useCallback(() => {
        document.querySelector<HTMLDialogElement>(`dialog#intervention-protocol-delete-dialog`)?.close();
    }, []);

    const handleConfirm = useCallback(async () => {
        setIsLoading(true);

        try {
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    return true;
                }, 1000);
            });
        } catch (e) {
            toast.error(t('general.error'), '');
        } finally {
            setIsLoading(false);
        }
    }, [t]);

    return (
        <DeleteProtocolContainer>
            <div>
                <h2>{t('intervention_protocol.delete_protocol')}</h2>
                <div>
                    <p>{t('intervention_protocol.do_you_want_to_delete_the_protocol')}</p>
                    <p>{t('intervention_protocol.this_action_cannot_be_undone')}</p>
                </div>

                <Divider />
            </div>
            <ButtonsDeleteContainer>
                <CancelButton onClick={handleClose}>{t('general.cancel')}</CancelButton>
                <Button isLoading={isLoading} type="reset" color="alert" size="large" onClick={handleConfirm}>
                    {t('intervention_protocol.delete')}
                </Button>
            </ButtonsDeleteContainer>
        </DeleteProtocolContainer>
    );
}
