import { Column } from 'src/common/components';
import styled from 'styled-components';

interface isOnlineProps {
    isOnline: boolean;
}

export const ActivityContainer = styled.div`
    background: #ffffff;
    box-shadow: 1px 1px 4px 1px rgba(100, 100, 100, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
`;

export const ActivityHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1.4rem;
`;

export const ActivityBody = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.8rem 1.4rem;
`;

export const ActivityChartContainer = styled.div`
    width: 78%;
`;

export const ActivityFormContainer = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
`;

export const ActivityFormContent = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0.8rem;

    > h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #262d3c;
        margin-bottom: 8px;
    }
`;

export const ActivityTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 78.5%;
    justify-content: space-between;

    span {
        font-weight: 500;
        font-size: 0.8rem;
        color: #262d3c;
    }
`;

export const Title = styled.h4`
    font-weight: 500;
    font-size: 1rem;
    line-height: 0.9rem;
    letter-spacing: -0.5px;
    color: #262d3c;
`;

export const ActivityOptionsChartContainer = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;

    > span {
        height: 2rem;
        display: flex;
        justify-content: end;
        align-items: center;
        cursor: pointer;
    }

    > span:last-of-type {
        margin-left: 1rem;
    }
`;

export const OnlineTitle = styled.div<isOnlineProps>`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-decoration-line: ${props => (props.isOnline ? 'underline' : 'none')};
    color: ${props => (props.isOnline ? '#6da4d3 !important' : 'rgb(169, 169, 169)')};
`;

export const ActivityOptionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ActivityOptionsButton = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
`;

export const ActivityOptionsSwitch = styled.div`
    display: flex;
    gap: 0.4rem;
`;

export const ChartFilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    background: #f1f1f1;
    padding: 1rem 1.4rem;
    gap: 1rem;
`;

export const ChartFilterForm = styled.form`
    display: flex;
    justify-content: end;
    gap: 1rem;
`;

export const ChartFilterFormColumn = styled(Column)`
    display: flex;
    width: 10rem;
    background-color: red;
`;
