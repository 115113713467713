import { Modal as MuiModal, styled as muiStyled } from '@mui/material';
import styled from 'styled-components';

export const Card = styled.section`
    background: #ffffff;

    box-shadow: -1px 1px 4px 1px rgba(3, 3, 3, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 24px;
    gap: 16px;

    position: relative;
`;

export const CardHeader = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1 auto;
    width: 100%;
`;

export const StyledModal = muiStyled(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Subtitle = styled.h5`
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #262d3c;
`;

export const Title = styled.h4`
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #262d3c;
`;
