import styled from 'styled-components';

export const ChartCardHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    flex: 1;
    width: 100%;

    h4 {
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.5px;

        color: #262d3c;
    }
`;
