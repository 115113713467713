import { SVGProps } from 'react';

interface SortUpProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export default function SortUp({ color = '#fff', ...props }: SortUpProps) {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.5 17H13.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M5.5 12H11.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M5.5 7H9.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M19.5 6L22.5 9M19.5 6L16.5 9M19.5 6L19.5 18" stroke={color} strokeWidth="2" />
        </svg>
    );
}
