import { Fragment, MouseEvent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'src/common';
import { Checkbox, EditFill, Eye, RenderIf, Row, Table, TableOptionButton, Trash } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { Vehicle } from 'src/modules/vehicle-monitoring/domain/entities/vehicle';

import { getDefaultCreateOrUpdateValues } from '../../contexts/default-form-values';
import { useVehicles } from '../../hooks/use-vehicles';
import DeleteVehicle from '../delete-vehicle';

interface DeleteModalProps {
    fleet: string;
    id: number;
    open: boolean;
}

interface TableListProps {
    onEdit(): void;
    onView(): void;
}

const DELETE_MODAL_INITIAL_STATE: DeleteModalProps = {
    open: false,
    fleet: '',
    id: 0,
};

export default memo(function TableList({ onEdit, onView }: TableListProps): JSX.Element {
    const { isAdmin } = usePermission();
    const { t } = useTranslation();
    const {
        allOnThisPageAreSelected,
        vehicles,
        setInitialFormData,
        selectedVehicles,
        handleSelectAll: selectAll,
        handleSelect,
        getVehicleTypes,
    } = useVehicles();

    const [deleteModal, setDeleteModal] = useState<DeleteModalProps>(DELETE_MODAL_INITIAL_STATE);

    const handleChecked = useCallback((data: Vehicle) => selectedVehicles.findIndex(event => event.id === data.id) !== -1, [selectedVehicles]);

    const handleDelete = (event: MouseEvent<HTMLButtonElement>, data: Vehicle | null) => {
        event.stopPropagation();
        if (!data) return;
        setDeleteModal({
            id: data?.id,
            fleet: data.fleet,
            open: true,
        });
    };

    const handleEdit = (event: MouseEvent<HTMLButtonElement>, data: Vehicle | null) => {
        event.stopPropagation();
        setInitialFormData(() => getDefaultCreateOrUpdateValues(data));
        onEdit();
    };

    const handleSelectAll = useCallback(() => {
        if (vehicles?.elements) {
            selectAll(vehicles.elements.map(e => e as Vehicle));
        }
    }, [vehicles?.elements, selectAll]);

    const handleView = (event: MouseEvent<HTMLButtonElement>, data: Vehicle | null) => {
        event.stopPropagation();
        setInitialFormData(() => getDefaultCreateOrUpdateValues(data));
        onView();
    };

    const tableHeaders = useMemo(
        () => [
            <Checkbox mode={'blue-check'} name={'checkboxAll'} key="checkboxAll" checked={allOnThisPageAreSelected} onChange={handleSelectAll} />,
            t('configurations.vehicle_type'),
            t('alarms.fleet'),
            t('monitoring.organization'),
            <Fragment key="options" />,
        ],
        [allOnThisPageAreSelected, handleSelectAll, t],
    );

    return (
        <Fragment>
            <Table tableHeaders={tableHeaders}>
                {vehicles?.elements?.map(vehicle => {
                    return (
                        <TableRow className="body pointer" key={vehicle?.id} onClick={() => handleSelect(vehicle as Vehicle)}>
                            <TableData style={{ width: '16px' }}>
                                <Checkbox
                                    checked={handleChecked(vehicle as Vehicle)}
                                    mode={'blue-check'}
                                    name={vehicle ? `${vehicle.id.toString()}` : ''}
                                    readOnly
                                />
                            </TableData>
                            <TableData>
                                {vehicle?.vehicleType
                                    ? getVehicleTypes(t).find(type => type.value === vehicle?.vehicleType)?.label
                                    : vehicle?.vehicleType}
                            </TableData>
                            <TableData>{vehicle?.fleet}</TableData>
                            <TableData>{vehicle?.operation?.name}</TableData>
                            <TableData style={{ paddingRight: '16px', width: '112px' }}>
                                <Row gap={16}>
                                    <TableOptionButton type="button" onClick={e => handleView(e, vehicle)}>
                                        <Eye color="#46526C" />
                                    </TableOptionButton>
                                    <RenderIf condition={isAdmin}>
                                        <Fragment>
                                            <TableOptionButton type="button" onClick={e => handleEdit(e, vehicle)}>
                                                <EditFill color="#46526C" />
                                            </TableOptionButton>
                                            <TableOptionButton type="button" onClick={e => handleDelete(e, vehicle)}>
                                                <Trash />
                                            </TableOptionButton>
                                        </Fragment>
                                    </RenderIf>
                                </Row>
                            </TableData>
                        </TableRow>
                    );
                })}
            </Table>
            <RenderIf condition={deleteModal.open}>
                <DeleteVehicle
                    fleet={deleteModal.fleet}
                    id={deleteModal.id}
                    onClose={() => setDeleteModal(DELETE_MODAL_INITIAL_STATE)}
                    open={deleteModal.open}
                />
            </RenderIf>
        </Fragment>
    );
});
