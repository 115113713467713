import { toast as toastify } from 'react-toastify';

import { Container, Line } from './styles';

interface toastProps {
    title: string;
    body: string;
    type: 'ERROR' | 'SUCCESS';
}

export function ToastMessage({ title, body, type }: toastProps) {
    return (
        <Container>
            <Line type={type} />
            <span className="title">{title}</span>
            <span className="body"> {body} </span>
        </Container>
    );
}

export const toast = {
    error(title: string, message: string) {
        toastify.error(<ToastMessage title={title} body={message} type="ERROR" />);
    },
    success(title: string, message: string) {
        toastify.success(<ToastMessage title={title} body={message} type="SUCCESS" />);
    },
};
