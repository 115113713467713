import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Field, Image, Row, Skeleton } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { RealTimeLocation } from 'src/modules/vehicle-monitoring/domain/entities/real-time-location';
import { useRealTimeLocation } from 'src/modules/vehicle-monitoring/hooks';

import { DateHelper } from '../../../../../common';
import { DriverEmptyPhoto } from '../driver-empty-photo';
import { StyledTable, Tag } from './styles';

interface TabContentProps {
    realTimeLocation: RealTimeLocation;
    value: number;
}

function DriverInfo(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Row gap={48} align="center" justify="space-between">
            <Column gap={8} align="flex-start">
                <Field name={t('monitoring.board_number')} value={t('general.information_unavailable')} warn />
                <Field name={t('alarms.velocity')} value={t('general.information_unavailable')} warn />
                <Field name={t('monitoring.last_register')} value={t('general.information_unavailable')} warn />
                <Field name={t('monitoring.organization')} value={t('general.information_unavailable')} warn />
            </Column>
            <DriverEmptyPhoto />
        </Row>
    );
}

function LastEvents(): JSX.Element {
    const { latestEvents } = useRealTimeLocation();
    const { t, i18n } = useTranslation();

    const tableHeaders = useMemo(
        () => [
            <Fragment key="view" />,
            t('alarms.event'),
            // t('alarms.alarm'),
            t('alarms.velocity'),
            t('alarms.hour'),
            // t('alarms.arrival'),
            // t('alarms.priority'),
        ],
        [t],
    );

    if (latestEvents.isLoading) {
        return (
            <Column gap={8} width="768px">
                {[1, 2, 3, 4, 5].map(value => (
                    <Skeleton key={value} />
                ))}
            </Column>
        );
    }

    if (latestEvents.hasError) {
        return (
            <Column width="768px" style={{ color: '#EF1D00' }}>
                {t('general.error')}
            </Column>
        );
    }

    return (
        <StyledTable tableHeaders={tableHeaders}>
            {latestEvents?.data?.map((event, index) => {
                return (
                    <TableRow key={event.id.toString()} className={index > 0 ? 'body' : ''}>
                        <TableData style={{ width: '22%', paddingLeft: 0 }}>
                            <Image
                                src={event.photoUrl || undefined}
                                alt={event.alarm || undefined}
                                width={122}
                                height={98}
                                showErrorMessage={false}
                                style={{
                                    borderRadius: '8px',
                                    objectFit: 'fill',
                                }}
                            />
                        </TableData>
                        <TableData>{event.event}</TableData>
                        {/* <TableData>{event.alarm}</TableData> */}
                        <TableData>{event.speedInKmH}</TableData>
                        <TableData>{DateHelper.format(event.alarmTime, i18n.language)}</TableData>
                        {/* <TableData>{String(event.alarmArrivalTime)}</TableData> */}
                        {/* <TableData>{event.priority ? <Tag>{event.priority?.toString()}</Tag> : <Fragment />}</TableData> */}
                    </TableRow>
                );
            })}
        </StyledTable>
    );
}

function VehicleInfo(realTimeLocation: RealTimeLocation): JSX.Element {
    const { lastOnlineData } = useRealTimeLocation();
    const { t } = useTranslation();

    return (
        <Column gap={8} align="flex-start">
            <Field name={t('monitoring.board_number')} value={realTimeLocation.licensePlate || t('general.information_unavailable')} />
            <Field name={t('alarms.velocity')} value={(realTimeLocation.currentSpeed || 0) + 'km/h'} />
            <Field
                name={t('monitoring.last_register')}
                value={lastOnlineData?.data?.sendTime || t('general.information_unavailable')}
                warn={!lastOnlineData.data}
                isLoading={lastOnlineData.isLoading}
            />
            <Field
                name={t('monitoring.organization')}
                value={realTimeLocation.operationName || t('general.information_unavailable')}
                warn={!realTimeLocation.operationName}
            />
        </Column>
    );
}

export default function TabContent({ value, realTimeLocation }: TabContentProps) {
    return useMemo<JSX.Element>(() => {
        switch (value) {
            case 0:
                return <VehicleInfo {...realTimeLocation} />;
            case 1:
                return <DriverInfo />;
            case 2:
                return <LastEvents />;
            default:
                return <Fragment />;
        }
    }, [realTimeLocation, value]);
}
