import { ApiResponse, Service } from 'src/common';
import { api } from 'src/common/infra';
import { HistoricalRoute } from 'src/modules/alarm-processing/domain/models/historical-route';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';

import { GetHistoricalRouteDTO } from '../dtos/get-historical-route.dto';

export interface GetHistoricalRouteOfEventsResponse {
    events: Array<EventDTO>;
    historicalRoute: Array<HistoricalRoute>;
}

export class GetHistoricalRouteService implements Service<GetHistoricalRouteDTO, GetHistoricalRouteOfEventsResponse> {
    public async execute(request: GetHistoricalRouteDTO): Promise<GetHistoricalRouteOfEventsResponse> {
        const { fleet, finalDate, initialDate, endTime, operation, startTime } = request;

        const {
            data: { content },
        } = await api.get<ApiResponse<GetHistoricalRouteOfEventsResponse>>('vehicle-monitoring/historical-route', {
            params: {
                endTime,
                finalDate: finalDate.toISOString(),
                fleet,
                initialDate: initialDate.toISOString(),
                operation,
                startTime,
            },
        });

        return content;
    }
}

export const getHistoricalRouteService = new GetHistoricalRouteService();
