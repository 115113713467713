import styled from 'styled-components';

export const CardHeader = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    padding: 16px 16px 0;

    h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #4d4d4d;
        display: flex;
        align-items: center;
        gap: 8px;

        margin: 0;
    }

    span.per-page {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        margin: 0;
        color: #a9a9a9;
    }

    span.total {
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        margin: 0;

        color: #a9a9a9;
    }

    .filter-open svg {
        color: #fff;
    }
`;

export const MainCard = styled.main`
    background: #ffffff;
    border-radius: 8px;
    overflow: auto;
`;
