import { Row } from 'src/common/components';
import styled from 'styled-components';

export const Container = styled(Row)`
    .big-map {
        border-radius: 8px;
        height: 697px;
        width: 1078px;
        transition: height 0.3s ease-in, width 0.2s ease-out;

        @media screen and (max-height: 769px) {
            height: 480px;
        }

        @media screen and (max-height: 624px) {
            height: 248px;
        }

        @media screen and (max-width: 1200px) {
            width: 968px;
        }

        @media screen and (max-width: 1024px) {
            width: 720px;
        }

        @media screen and (max-width: 768px) {
            width: 360px;
        }
    }
`;
