import { SVGProps } from 'react';

interface FilterAltProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export default function FilterAlt({ color = '#fff', ...props }: FilterAltProps) {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.5 5.8335L17.1667 5.8335" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M3.83331 5.8335L7.16665 5.8335" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M14.6667 14.1665L17.1667 14.1665" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M3.83331 14.1665L10.5 14.1665" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <circle
                cx="8.83333"
                cy="5.83317"
                r="1.66667"
                transform="rotate(90 8.83333 5.83317)"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <ellipse
                cx="13"
                cy="14.1667"
                rx="1.66667"
                ry="1.66667"
                transform="rotate(90 13 14.1667)"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}
