import styled from 'styled-components';

export const Message = styled.span`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    flex-grow: 1;
    cursor: default;
`;
