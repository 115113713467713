import { createContext, useCallback, useMemo, useReducer, useState } from 'react';
import { DateHelper, FunctionComponent } from 'src/common';
import { Reducer, reducer } from 'src/common/types/reducer';
import { DepartmentResume } from 'src/modules/configurations/departments/dtos';

import { Vehicle } from '../../domain/entities/vehicle';
import { GetHistoricalRouteDTO } from '../../dtos/get-historical-route.dto';
import { INITIAL_DEPARTMENT_STATE, INITIAL_VEHICLES_STATE } from '../const';
import { HistoricalRouteContextProps } from './types';

export const HistoricalRouteContext = createContext({} as HistoricalRouteContextProps);

export const INIT_FILTER_STATE: GetHistoricalRouteDTO = {
    endTime: '',
    finalDate: new Date(),
    fleet: '',
    initialDate: new Date(),
    operation: '',
    startTime: '',
};

export default function HistoricalRouteProvider({ children }: FunctionComponent) {
    const [filter, setFilter] = useState<GetHistoricalRouteDTO>(INIT_FILTER_STATE);
    const [filterIsOpen, setFilterIsOpen] = useState(true);
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_DEPARTMENT_STATE);
    const [showEvents, setShowEvents] = useState(true);
    const [vehicles, setVehicles] = useReducer<Reducer<Array<Vehicle>>>(reducer, INITIAL_VEHICLES_STATE);

    const reset = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(true);
        setOperations({
            type: 'data',
            payload: [],
        });
        setShowEvents(true);
        setVehicles({
            type: 'data',
            payload: [],
        });
    }, []);

    const data = useMemo<HistoricalRouteContextProps>(
        () => ({
            filter,
            filterIsOpen,
            operations,
            reset,
            setFilter,
            setFilterIsOpen,
            setOperations,
            setShowEvents,
            setVehicles,
            showEvents,
            vehicles,
        }),
        [filter, filterIsOpen, operations, reset, showEvents, vehicles],
    );

    return <HistoricalRouteContext.Provider value={data}>{children}</HistoricalRouteContext.Provider>;
}
