import { forwardRef, HTMLAttributes, MouseEventHandler } from 'react';
import { GrClose } from 'react-icons/gr';

import { CloseButton, Container } from './styles';

interface ChipProps extends HTMLAttributes<HTMLDivElement> {
    label: string;
    onClose?: MouseEventHandler<HTMLButtonElement>;
}

const Chip = forwardRef<HTMLDivElement, ChipProps>(function Base({ label, onClose, ...props }, ref) {
    return (
        <Container ref={ref} {...props}>
            <span>{label}</span>
            <CloseButton type="button" onClick={onClose}>
                <GrClose size={16} />
            </CloseButton>
        </Container>
    );
});

export default Chip;
