import { ApiResponse } from 'src/common/core';
import { SignalDTO, api } from 'src/common/infra';

import { VehicleType } from '../dtos';

export class VehicleTypeService {
    public async listAll(request: SignalDTO): Promise<Array<VehicleType>> {
        const { signal } = request;

        const {
            data: { content },
        } = await api.get<ApiResponse<Array<VehicleType>>>('/vehicles/types', {
            signal,
        });

        return content;
    }
}

export const vehicleTypeService = new VehicleTypeService();
