import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FiClock } from 'react-icons/fi';

import { Divider, useSidebar } from '../../../../common';
import { CardContainer, CardFooter, CardHeader, CardHeaderIcon, CardHeaderInfo, CardHeaderInfoTitle, CardHeaderInfoValue } from './styles';

interface ICardProps {
    title: string;
    value: number | string;
    icon: JSX.Element | any;
    fontColor?: string;
}

export function Card({ title, value, icon, fontColor }: ICardProps) {
    const { displaySidebar } = useSidebar();
    const { t } = useTranslation();
    return (
        <CardContainer isSidebarOpen={displaySidebar}>
            <CardHeader>
                <CardHeaderIcon color={fontColor}>{icon}</CardHeaderIcon>
                <CardHeaderInfo>
                    <CardHeaderInfoTitle>{title}</CardHeaderInfoTitle>
                    <CardHeaderInfoValue>{value}</CardHeaderInfoValue>
                </CardHeaderInfo>
            </CardHeader>

            <Divider />

            <CardFooter>
                <FiClock />
                <p>{t('home.updated_at')} {moment().format('hh:mm')}</p>
            </CardFooter>
        </CardContainer>
    );
}
