import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Title, MainCard, CardHeader, Divider } from '../../../common';
import { Column, Row } from '../../../common/components';
import UpdateData from '../../../common/components/update-data';
import { Card } from '../../../modules/smart-report/components/Card';
import { SkeletonCard } from '../../../modules/smart-report/components/CardSkeleton';
import { useAnalyticsEvents } from '../../../modules/smart-report/events/hooks';
import { ChartContainer } from './styles';

export function Events() {
    const { t } = useTranslation();

    const { filter, filterIsOpen, operations, setOperations, setFilter, handleFilterIsOpen, reset } = useAnalyticsEvents();

    //! Implements update
    // const handleUpdate = useCallback(async () => {}, []);

    //! Implements filter
    const filters = useMemo(() => {
        if (filterIsOpen) {
            return <></>;
        }
    }, [filterIsOpen]);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between">
                <Title>
                    <h2>Smart Report</h2>
                    <UpdateData />
                </Title>
            </Row>

            <MainCard style={{ paddingBottom: '1rem' }}>
                <Column width="100%" gap={16} style={{ marginTop: '2rem' }}>
                    <CardHeader>
                        <Card title={'Card Total Diário'} value={1} icon={1} description={'Total'} />
                        <Card title={'Card Fadiga'} value={2} icon={2} description={'Total'} />
                        <Card title={'Card Distrações'} value={3} icon={3} description={'Total'} />
                        <Card title={'Card Infrações'} value={4} icon={4} description={'Total'} />
                    </CardHeader>

                    <Divider />

                    <Row padding="0.8rem" style={{ justifyContent: 'space-between' }}>
                        <ChartContainer style={{ width: '100%', backgroundColor: 'grey' }}>Gráfico de evolução</ChartContainer>
                    </Row>

                    <Row padding="0.8rem" style={{ justifyContent: 'space-between' }}>
                        <ChartContainer style={{ backgroundColor: 'grey' }}>Gráfico de Propoção</ChartContainer>
                        <ChartContainer style={{ backgroundColor: 'grey' }}>Gráfico de Propoção</ChartContainer>
                    </Row>

                    <Row padding="0.8rem" style={{ justifyContent: 'space-between' }}>
                        <ChartContainer style={{ backgroundColor: 'grey' }}>Gráfico de Dia da Semana</ChartContainer>
                        <ChartContainer style={{ backgroundColor: 'grey' }}>Gráfico de Dia da Semana</ChartContainer>
                    </Row>
                </Column>
            </MainCard>
        </Column>
    );
}
