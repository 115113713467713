import { useQuery } from '@tanstack/react-query';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiResponse, MainCard, api } from 'src/common';
import { Button, Checkbox, Column, Row, Table } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { toast } from 'src/common/components/toast';
import { DepartmentResume } from 'src/modules/configurations/departments';

import { Modal, Title } from '../create-or-update-vehicle/styles';
import { Paragraph } from './styles';

interface AmbiguousContactDialogProps {
    ambiguousParents: DepartmentResume[];
    onClose: () => void;
    onSelect: (value: number | null) => void;
    open: boolean;
    parentId: number | null;
    title: string;
}

function AmbiguousContactDialog({ ambiguousParents, onSelect, parentId, onClose, open, title }: AmbiguousContactDialogProps) {
    const { t } = useTranslation();

    const tableHeaders = useMemo(() => [<Fragment key="options" />, t('configurations.organization'), t('configurations.institution')], [t]);

    const { data, isLoading, isError } = useQuery({
        queryKey: ['get-parents', { ids: ambiguousParents.map(p => p.id) }],
        queryFn: async ({ queryKey }) => {
            const [, params] = queryKey;
            if (typeof params === 'string') return;
            const {
                data: { content },
            } = await api.get<ApiResponse<Array<DepartmentResume>>>(`/operations/parents/${params.ids}`);
            return content;
        },
        onError: () => {
            toast.error(t('general.error'), '');
        },
    });

    return (
        <Modal
            open={open}
            onClose={() => {
                onSelect(null);
                onClose();
            }}
        >
            <MainCard>
                <Column
                    gap={24}
                    style={{
                        padding: '24px',
                        width: '394px',
                    }}
                >
                    <Column gap={8}>
                        <Title>{title}</Title>
                        <Paragraph>{t('configurations.ambiguous_record')}</Paragraph>
                    </Column>
                    <Table tableHeaders={tableHeaders}>
                        {data?.map(item => {
                            return (
                                <TableRow key={item.id} className="body pointer" onClick={() => onSelect(item.id)}>
                                    <TableData style={{ width: '16px' }}>
                                        <Checkbox
                                            mode={'blue-check'}
                                            name={`${item?.id.toString()} - ${item?.name}`}
                                            readOnly
                                            checked={item.id === parentId}
                                        />
                                    </TableData>
                                    <TableData>{item?.name}</TableData>
                                    <TableData>{item?.parentName}</TableData>
                                </TableRow>
                            );
                        })}
                    </Table>
                    <Row align="flex-end" gap={16} flex width="100%">
                        <Button
                            color="alert"
                            type="button"
                            onClick={() => {
                                onSelect(null);
                                onClose();
                            }}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            disabled={!parentId || isLoading || isError}
                            type="button"
                            onClick={() => {
                                if (!parentId) return;
                                onClose();
                            }}
                        >
                            {t('general.confirm')}
                        </Button>
                    </Row>
                </Column>
            </MainCard>
        </Modal>
    );
}

export default AmbiguousContactDialog;
