import { api } from 'src/common';
import { ApiResponse } from 'src/common/core/api-response';
import { Service } from 'src/common/core/service';

import { ExtraEventsDTO } from '../dtos/event.dto';

class GetExtraEventsByIdService implements Service<bigint, ExtraEventsDTO> {
    public async execute(id: bigint): Promise<ExtraEventsDTO> {
        if (!id) throw new Error('Invalid id');

        const {
            data: { content },
        } = await api.get<ApiResponse<ExtraEventsDTO>>(`/alarms-processing/extra-events/${id}`);

        return content;
    }
}
export const getExtraEventsById = new GetExtraEventsByIdService();
