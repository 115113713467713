import { KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { usePermission } from 'src/common';
import { Column, Input, Row } from 'src/common/components';
import { v4 } from 'uuid';
import { ValidationError } from 'yup';

import { DepartmentsContext } from '../../../contexts';
import { CreateDptoContactDTO } from '../../../dtos/create-dpto-contact.dto';
import { useContacts } from '../../../hooks/use-contacts';
import { createDptoContactFormSchema } from '../../../infra';
import ContactsTable from '../contacts-table';
import { AddButton, Legend } from '../form/styles';

export default function ContactForm() {
    const { mutateAsync } = useContacts();
    const { isAdmin } = usePermission();

    const { setContacts, edittingId } = useContext(DepartmentsContext);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { t } = useTranslation();
    const inputsRef = useRef<HTMLInputElement[]>([]);
    const [editId, setEditId] = useState<string | null>(null);

    const [errors, setErrors] = useState({
        contact: '',
        phone: '',
    });

    const handleReset = () => {
        inputsRef.current.forEach(item => {
            item.value = '';
        });
    };

    const handleEdit = (data: CreateDptoContactDTO) => {
        setEditId(data.id);

        inputsRef.current.forEach(item => {
            switch (item.name) {
                case 'contactName':
                    item.value = data.contactName;
                    break;
                case 'phone':
                    item.value = data.phone || '';
                    break;
                default:
                    break;
            }
        });
    };

    const handleSaveContact = useCallback(async () => {
        if (!isAdmin) return;

        const inputs = inputsRef.current
            .map(item => {
                return {
                    [item.name as keyof CreateDptoContactDTO]: item.value,
                };
            })
            .reduce((acc, item) => ({ ...acc, ...item }), {});

        try {
            const data = await createDptoContactFormSchema.validate(inputs, {
                abortEarly: false,
            });
            setErrors({
                contact: '',
                phone: '',
            });

            setContacts(state => {
                if (editId) {
                    return [{ ...data, id: editId }, ...state.filter(d => d.id !== editId)];
                }

                return [{ ...data, id: v4() }, ...state];
            });
            setEditId(null);

            handleReset();
        } catch (error) {
            if (error instanceof ValidationError) {
                setErrors({
                    contact: error.inner.find(e => e.path === 'contactName')?.message || '',
                    phone: error.inner.find(e => e.path === 'phone')?.message || '',
                });
            }
        }
    }, [editId, isAdmin, setContacts]);

    const handleSubmit = (e: KeyboardEvent) => {
        if (['Enter'].includes(e.key)) {
            buttonRef?.current?.click();
        }
    };

    useEffect(() => {
        if (edittingId) {
            mutateAsync(edittingId).then(res =>
                setContacts(
                    res.map(item => ({
                        contactName: item.name,
                        id: item.id.toString(),
                        phone: item.phone || '',
                    })),
                ),
            );
        }
    }, [edittingId, mutateAsync, setContacts]);

    return (
        <Column gap={8} width="100%">
            <Legend>{t('configurations.contacts')}</Legend>
            <Row width="100%" gap={16} align="flex-start">
                <Input
                    autoComplete="off"
                    label={String(t('configurations.contact'))}
                    maxLength={20}
                    name="contactName"
                    onKeyDown={handleSubmit}
                    placeholder={String(t('general.write'))}
                    ref={el => (inputsRef.current[0] = el as HTMLInputElement)}
                    type="text"
                    feedback={{
                        message: t(errors.contact)?.toString(),
                    }}
                />
                <Input
                    autoComplete="off"
                    label={String(t('configurations.phone_number'))}
                    maxLength={20}
                    name="phone"
                    onKeyDown={handleSubmit}
                    placeholder={String(t('general.write'))}
                    ref={el => (inputsRef.current[1] = el as HTMLInputElement)}
                    tooltip={String(t('field_validations.phone_number_tooltip'))}
                    type="text"
                    feedback={{
                        message: t(errors.phone)?.toString(),
                    }}
                />
            </Row>
            <Row justify="flex-end">
                <AddButton type="button" ref={buttonRef} onClick={handleSaveContact}>
                    <FiPlus />
                    <span>{t('configurations.save_contact')}</span>
                </AddButton>
            </Row>
            <ContactsTable onEdit={handleEdit} />
        </Column>
    );
}
