import { useCallback, useMemo, useRef, useState } from 'react';
import { FunctionComponent } from 'src/common/types';

import { SearchContext } from './context';
import { SearchContextProps } from './types';

export default function SearchProvider({ children }: FunctionComponent) {
    const formRef = useRef<HTMLFormElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [isVisible, setIsVisible] = useState(true);

    const clear = useCallback(() => {
        if (inputRef?.current) inputRef.current.value = '';
    }, []);

    const parse = useCallback((e: SubmitEvent, key: string): string => {
        e.preventDefault();
        const { target } = e;

        const currentTarget = target as unknown as HTMLFormElement;

        const keyElement: HTMLInputElement = currentTarget?.elements?.namedItem(key) as HTMLInputElement;

        return keyElement?.value;
    }, []);

    const data: SearchContextProps = useMemo(
        () => ({
            clear,
            formRef,
            inputRef,
            isVisible,
            parse,
            setIsVisible
        }),
        [clear, isVisible, parse],
    );

    return <SearchContext.Provider value={data}>{children}</SearchContext.Provider>;
}
