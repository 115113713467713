import { ImageSection } from './styles';

export interface ImagesProps {
    photoUrlList?: string[];
}

export function ImagesList({photoUrlList}: ImagesProps) {
    return (
        <ImageSection>
            <ul>
                {photoUrlList !== undefined &&
                    photoUrlList.map(photoUrl => {
                        return (
                            <li key={photoUrl}>
                                <img src={photoUrl} />
                            </li>
                        )
                    })}
            </ul>
        </ImageSection>
    );
}
