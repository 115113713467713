import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import { Autocomplete, Button, Column, Divider, Input, RenderIf, Row, ToggleSwitch } from 'src/common/components';
import { toast } from 'src/common/components/toast';
import { Pagination } from 'src/common/core/pagination';
import { usePermission } from 'src/common/hooks';

import { Department } from '../../../dtos';
import { CreateDepartmetFormDTO } from '../../../dtos/create-department.dto';
import { useCreateOperation, useOperations, useUpdateOperation } from '../../../hooks/use-operations';
import { createDepartmentFormSchema } from '../../../infra/validations';
import { departmentService } from '../../../services';
import AmbigousContactDialog from '../ambigous-contact-dialog';
import ContactsForm from '../contacts-form';
import { CloseButton, StyledForm, Title } from './styles';

export default function Form() {
    const { isAdmin } = usePermission();
    const { isLoading: isCreating, mutateAsync: createOperation } = useCreateOperation();
    const { isLoading: isUpdating, mutateAsync: updateOperation } = useUpdateOperation();
    const { handleOpenDialog, contacts, setRequest, handleSelectAll, initialFormData, edittingId, setEdittingId } = useOperations();

    const [ambiguousParents, setAmbiguousParents] = useState<Department[]>([]);
    const [parentId, setParentId] = useState<number | null>(null);
    const [operationsResponse, setOperationsResponse] = useState<Pagination<Department> | null>(null);
    const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
    const { t } = useTranslation();

    const {
        handleSubmit: onSubmit,
        setValue,
        register,
        watch,
        formState: { errors, isValid },
    } = useForm<CreateDepartmetFormDTO>({
        defaultValues: initialFormData,
        resolver: yupResolver(createDepartmentFormSchema),
        mode: 'onChange',
    });

    const handleSubmit = useCallback(
        async (data: CreateDepartmetFormDTO) => {
            if (!isAdmin) return;

            const parents = (operationsResponse?.elements as Department[]).filter(
                o => o.name?.toUpperCase()?.trim() === data.parentName.toUpperCase().trim(),
            );

            if (parents.length < 1) {
                toast.error(t('general.error'), `${t('field_validations.invalid_record')}: ${t('configurations.institution_name')}`);
                return;
            }

            if (initialFormData?.parentName !== data.parentName) {
                if (!parentId) {
                    if (parents.length > 1) {
                        setAmbiguousParents(parents);
                        document.querySelector<HTMLDialogElement>('#ambigous-contact-dialog')?.showModal();
                        return;
                    }
                }
            }

            if (edittingId) {
                updateOperation({
                    contacts,
                    imageCapture: data.imageCapture,
                    name: data.name,
                    parentId: parentId || parents[0].id,
                    id: edittingId,
                })
                    .then(() => {
                        toast.success(t('general.success'), '');
                        setEdittingId(null);
                        setParentId(null);
                        handleSelectAll([]);
                        setRequest(state => ({ ...state, currentPage: 1 }));
                        handleOpenDialog();
                    })
                    .catch(e => {
                        if (e instanceof AxiosError) {
                            if (
                                e.response?.data.message === 'field_validations.record_already_exists' ||
                                e.response?.data.message === 'field_validations.equals_resources'
                            ) {
                                toast.error(t('general.error'), t(e.response?.data.message));
                                return;
                            }
                            toast.error(t('general.error'), '');
                            return;
                        }
                        toast.error(t('general.error'), '');
                    });
                return;
            }

            createOperation({
                contacts,
                imageCapture: data.imageCapture,
                name: data.name,
                parentId: parentId || parents[0].id,
            })
                .then(() => {
                    toast.success(t('general.success'), '');
                    setParentId(null);
                    handleSelectAll([]);
                    setRequest(state => ({ ...state, currentPage: 1 }));
                    handleOpenDialog();
                })
                .catch(e => {
                    if (e instanceof AxiosError) {
                        if (e.response?.data.message === 'field_validations.record_already_exists') {
                            toast.error(t('general.error'), t('field_validations.record_already_exists'));
                            return;
                        }
                        toast.error(t('general.error'), '');
                        return;
                    }
                    toast.error(t('general.error'), '');
                });
        },
        [
            contacts,
            createOperation,
            edittingId,
            handleOpenDialog,
            handleSelectAll,
            initialFormData?.parentName,
            isAdmin,
            operationsResponse?.elements,
            parentId,
            setEdittingId,
            setRequest,
            t,
            updateOperation,
        ],
    );

    const parentName = watch('parentName');

    useEffect(() => {
        setIsLoadingList(true);
        const timer: NodeJS.Timeout = setTimeout(() => {
            departmentService
                .listDepartments({
                    currentPage: 0,
                    active: true,
                    pageSize: 10,
                    name: parentName,
                })
                .then(res => {
                    setOperationsResponse(res);
                })
                .finally(() => {
                    setIsLoadingList(false);
                });
        }, 1000);

        return () => clearTimeout(timer);
    }, [parentName]);

    const title = useMemo(() => {
        if (!isAdmin) {
            return t('configurations.view_operation');
        }

        return t(edittingId ? 'configurations.edit_department' : 'configurations.create_department');
    }, [edittingId, isAdmin, t]);

    return (
        <StyledForm method="POST" onSubmit={onSubmit(handleSubmit)}>
            <Column gap={24} align="flex-start" width="100%">
                <Column gap={16} align="flex-start" width="100%">
                    <Row flex justify="space-between" width="100%" align="flex-start" gap={48}>
                        <Title>{title}</Title>
                        <CloseButton type="button" onClick={() => handleOpenDialog(null)}>
                            <CgClose color="#4D4D4D" size={20} />
                        </CloseButton>
                    </Row>
                    <Row width="100%" gap={16} align="flex-start">
                        <Autocomplete
                            isLoading={isLoadingList}
                            hint="*"
                            label={String(t('configurations.institution_name'))}
                            placeholder={String(t('general.write'))}
                            options={
                                operationsResponse?.elements?.map(item => ({
                                    id: item?.id as number,
                                    value: item?.name as string,
                                })) || []
                            }
                            setValue={setValue}
                            feedback={{
                                message: t(errors?.parentName?.message || '')?.toString(),
                            }}
                            readOnly={!isAdmin}
                            disabled={!isAdmin}
                            nextElement={{
                                id: 'name',
                            }}
                            {...register('parentName')}
                        />
                        <Input
                            autoComplete="off"
                            hint="*"
                            label={String(t('configurations.organization_name'))}
                            placeholder={String(t('general.write'))}
                            type="text"
                            maxLength={20}
                            feedback={{
                                message: t(errors?.name?.message || '')?.toString(),
                            }}
                            readOnly={!isAdmin}
                            disabled={!isAdmin}
                            {...register('name')}
                        />
                    </Row>
                    <ContactsForm />
                </Column>
                <ToggleSwitch mode="underline" label={'Captura de imagem:'} readOnly={!isAdmin} disabled={!isAdmin} {...register('imageCapture')} />
                <Divider />
            </Column>
            <RenderIf condition={isAdmin}>
                <Row align="flex-start" gap={16} width="100%">
                    <Button color="alert" type="reset" onClick={() => handleOpenDialog(null)}>
                        {t('general.cancel')}
                    </Button>
                    <Button disabled={!isValid} isLoading={isLoadingList || isCreating || isUpdating} type="submit">
                        {t('general.confirm')}
                    </Button>
                </Row>
            </RenderIf>
            <AmbigousContactDialog parentId={parentId} ambiguousParents={ambiguousParents} onSelect={setParentId} />
        </StyledForm>
    );
}
