import { SVGProps } from 'react';

interface CalendarIconProps extends SVGProps<SVGSVGElement> {
    color: string;
}

export default function CalendarIcon({ color, ...props }: CalendarIconProps) {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="3.83331" y="5.8335" width="13.3333" height="10.8333" rx="2" stroke={color} strokeWidth="2" />
            <path d="M4.66669 9.1665H16.3334" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M8 13.3335H13" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M7.16669 3.3335L7.16669 6.66683" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M13.8333 3.3335L13.8333 6.66683" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}
