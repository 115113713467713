import { ApiResponse } from 'src/common/core/api-response';
import { Pagination } from 'src/common/core/pagination';
import { Service } from 'src/common/core/service';
import { TransformRequestHelper } from 'src/common/helpers/transform-request.helper';
import { api } from 'src/common/infra/http';
import { EventDTO, PastEventsRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';

class ListPastEventsByBoardService implements Service<PastEventsRequestDTO, Pagination<EventDTO>> {
    public async execute({ board, operation, currentPage, pageSize = 5 }: PastEventsRequestDTO): Promise<Pagination<EventDTO>> {
        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<EventDTO>>>('/alarms-processing/past-events', {
            params: {
                board: board.trim(),
                operation: operation.trim(),
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
        });

        return content;
    }
}

export const listPastEvents = new ListPastEventsByBoardService();
