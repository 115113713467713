import styled from 'styled-components';

export const MainMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52rem;
`;

export const ChoiceButton = styled.button`
    outline: none;
    border: 1px solid rgb(185, 182, 182);
    padding: 0.6rem 1.5rem;
    font-weight: 600;
    background: #fff;

    &:first-of-type {
        border-radius: 4px 0px 0px 4px;
    }

    &:last-of-type {
        border-radius: 0px 4px 4px 0px;
    }

    &.active {
        background-color: rgb(109, 164, 211);
        color: #fff;
        border-color: rgb(109, 164, 211);
    }
`;

export const CardRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 0.5rem;
    width: 49.45%;
    height: 30rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    gap: 1.25rem;
    padding: 1rem;
`;

export const ChartContainerInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    /* padding: 1rem; */
`;

export const ChartSubTitle = styled.p`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-top: -20px;
    color: #b9b6b6;
    text-align: left;
    letter-spacing: 0em;
`;

export const SpanTotal = styled.span`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 0;
    color: #a9a9a9;
    margin-left: 0.5rem;
`;

export const EventDistributionDetailsContainer = styled.div`
    flex: 1;
`;

export const EventDistributionItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
    padding: 1rem 0;

    > p {
        margin-left: 0.5rem;
        width: 100%;
        text-align: left;
        font-size: 1rem;
        flex: 1;
    }
`;
