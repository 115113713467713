import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Column, Dialog, Row, Table } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';

import { Department } from '../../../dtos';
import { Title } from '../form/styles';
import { Paragraph } from './styles';

interface AmbiguousContactDialogProps {
    ambiguousParents: Department[];
    parentId: number | null;
    onSelect: (value: number | null) => void;
}

function AmbiguousContactDialog({ ambiguousParents, onSelect, parentId }: AmbiguousContactDialogProps) {
    const { t } = useTranslation();

    const tableHeaders = useMemo(() => [<Fragment key="options" />, t('configurations.organization'), t('configurations.institution')], [t]);

    return (
        <Dialog onCloseModal={() => onSelect(null)} id={'ambigous-contact-dialog'}>
            <Column
                gap={24}
                style={{
                    padding: '24px',
                    width: '394px',
                }}
            >
                <Column gap={8}>
                    <Title>{t('configurations.create_department')}</Title>
                    <Paragraph>{t('configurations.ambiguous_record')}</Paragraph>
                </Column>
                <Table tableHeaders={tableHeaders}>
                    {ambiguousParents.map(item => {
                        return (
                            <TableRow key={item.id} className="body pointer" onClick={() => onSelect(item.id)}>
                                <TableData style={{ width: '16px' }}>
                                    <Checkbox
                                        mode={'blue-check'}
                                        name={`${item?.id.toString()} - ${item?.name}`}
                                        readOnly
                                        checked={item.id === parentId}
                                    />
                                </TableData>
                                <TableData>{item?.name}</TableData>
                                <TableData>{item?.parentName}</TableData>
                            </TableRow>
                        );
                    })}
                </Table>
                <Row align="flex-end" gap={16} flex width="100%">
                    <Button
                        color="alert"
                        type="button"
                        onClick={() => {
                            onSelect(null);
                            document.querySelector<HTMLDialogElement>('#ambigous-contact-dialog')?.close();
                        }}
                    >
                        {t('general.cancel')}
                    </Button>
                    <Button
                        disabled={!parentId}
                        type="button"
                        onClick={() => {
                            if (!parentId) return;
                            document.querySelector<HTMLDialogElement>('#ambigous-contact-dialog')?.close();
                        }}
                    >
                        {t('general.confirm')}
                    </Button>
                </Row>
            </Column>
        </Dialog>
    );
}

export default AmbiguousContactDialog;
