import { memo } from 'react';
import { Row } from 'src/common/components';
import { useEvents } from 'src/modules/alarm-processing/hooks/use-events';

import AlarmCard from '../alarm-card';
import { Content } from './styles';

function CardList() {
    const { events } = useEvents();

    return (
        <Row>
            <Content>
                {events?.elements?.map(data => {
                    if (!data) return null;
                    return <AlarmCard key={data.id.toString()} data={data} />;
                })}
            </Content>
        </Row>
    );
}

export default memo(CardList);
