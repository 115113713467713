import { createContext, useCallback, useMemo, useReducer, useState } from 'react';

import { DateHelper, FunctionComponent, ResponseStatus } from '../../../../common';
import { Reducer, reducer } from '../../../../common/types/reducer';
import { DepartmentResume } from '../../../configurations';
import { INITIAL_OPERATION_STATE } from '../../fleets/context/const';
import { PeriodAnalyticsFilterDTO } from '../dtos/period-analytics-filter.dto';
import { PeriodDTO, PeriodRequestDTO } from '../dtos/period.dtos';
import { periodService } from '../services/period.service';
import { PeriodContextProps } from './types';

export const PeriodContext = createContext({} as PeriodContextProps);

export const INIT_FILTER_STATE: PeriodAnalyticsFilterDTO = {
    operationInput: '',
    operations: [],
};

export function PeriodProvider({ children }: FunctionComponent) {
    const [filter, setFilter] = useState<PeriodAnalyticsFilterDTO>(INIT_FILTER_STATE);
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const [listPeriod, setListPeriod] = useState<Array<PeriodDTO>>([]);

    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_OPERATION_STATE);

    const [requestData, setRequestData] = useState<PeriodRequestDTO>({
        dateFrom: DateHelper.yesterday(),
        dateTo: new Date(),
        equipament: 'DS',
    });

    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: true,
        success: false,
        void: false,
    });

    const handleRequestList = useCallback((data: Partial<PeriodRequestDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleGetList = useCallback(async () => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const data = await periodService.getData({
                ...requestData,
                equipament: requestData.equipament === 'Telemetria' ? 'Geotab' : requestData.equipament,
            });

            setListPeriod(data);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: false,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const reset = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(true);
        setOperations({
            type: 'data',
            payload: [],
        });
    }, []);

    const handleFilterIsOpen = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const data: PeriodContextProps = useMemo(
        () => ({
            listPeriod,
            handleRequestList,
            handleGetList,
            responseStatus,
            requestData,
            operations,
            filter,
            setFilter,
            reset,
            filterIsOpen,
            handleFilterIsOpen,
            setOperations,
        }),
        [
            listPeriod,
            handleRequestList,
            handleGetList,
            responseStatus,
            requestData,
            operations,
            filter,
            setFilter,
            reset,
            filterIsOpen,
            handleFilterIsOpen,
            setOperations,
        ],
    );

    return <PeriodContext.Provider value={data}>{children}</PeriodContext.Provider>;
}
