import styled from 'styled-components';

export const VelocitySpan = styled.span`
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(0, 0, 0);
`;

export const SliderRangeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
