import { ApiResponse, TransformRequestHelper, api } from 'src/common';
import { Pagination } from 'src/common/core/pagination';
import { Service } from 'src/common/core/service';

import { ReviewDTO, ReviewRequestDTO } from '../dtos/review-request.dto';

class GetReviewRequestReview implements Service<ReviewRequestDTO, Pagination<ReviewDTO>> {
    public async execute({ currentPage, pageSize }: ReviewRequestDTO): Promise<Pagination<ReviewDTO>> {
        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<ReviewDTO>>>('/alarms-processing/request-review', {
            params: {
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
        });

        return content;
    }
}

export const getReviewRequestReview = new GetReviewRequestReview();
