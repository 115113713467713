import { lighten } from 'polished';
import styled from 'styled-components';

interface SquareButtonProps {
    bgColor: string;
}

export const SquareButton = styled.button<SquareButtonProps>`
    width: 32px;
    height: 32px;

    background: ${props => props.bgColor};
    border-radius: 4px;
    margin: 0;
    padding: 0;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s ease-in;

    &:hover {
        background-color: ${props => lighten(0.05, props.bgColor)};
    }
`;
