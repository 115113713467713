import { ChangeEvent, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "src/common";

import { useDrivers } from "../../hook";
import { UploadImageSection, UploadImageTop } from "./style";

function UploadImage(props: { startDate: Date | null, endDate: Date | null }) {
    const { startDate, endDate } = props;
    const { t } = useTranslation();

    const { setFile, handleGetDriverByImage, driverByImage, setReturnMessage, returnMessage, file, responseStatusByImage } = useDrivers();

    const fileInput = useRef<HTMLInputElement>(null);
   
    const handleFileInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const target = (e.target as HTMLInputElement);
        const file = target.files ? target.files[0] : {} as File;
        
        setFile(file);
        handleGetDriverByImage({
            dateFrom: startDate,
            dateTo: endDate,
            pageSize: 15,
            start: 0,
            file,
        });
    }, [handleGetDriverByImage, endDate, startDate, setFile]);

    useEffect(() => {
        if (driverByImage) {
            switch (driverByImage.elements.length) {
                case 0:
                    setReturnMessage('drivers.driver_founded_no_events')
                    break;
                default:
                    setReturnMessage('')
                    break;
            }
        }
    }, [driverByImage, handleGetDriverByImage, setReturnMessage]);

    const handleClick = useCallback(() => {
        setReturnMessage('');
    }, [setReturnMessage])

    return driverByImage !== null && driverByImage?.elements.length > 0 && !responseStatusByImage.error
        ?   <UploadImageTop>
                <input 
                    type="file" 
                    accept="image/png, image/jpeg" 
                    onChange={handleFileInput} 
                    style={{ display: 'none' }} 
                    ref={fileInput}
                />

                <span className="fileInput">
                    <button
                        onClick={() => fileInput.current?.click()}
                    >
                        {t('drivers.search')}
                    </button>

                    <h2>{
                        file?.name === undefined
                            ? t('drivers.file_text') 
                            : file?.name
                    }</h2>
                </span>
            </UploadImageTop>
        :   returnMessage.length === 0 
            ? <UploadImageSection>
                <Title>
                    <h2>{t('drivers.upload_image')}</h2>
                </Title>
                <input 
                    type="file" 
                    accept="image/png, image/jpeg" 
                    onChange={handleFileInput} 
                    style={{ display: 'none' }} 
                    ref={fileInput}
                />
    
                <span className="fileInput">
                    <button
                        onClick={() => fileInput.current?.click()}
                    >
                        {t('drivers.search')}
                    </button>
    
                    <h2>{
                        file?.name === undefined
                            ? t('drivers.file_text') 
                            : file?.name
                    }</h2>
                </span>
            </UploadImageSection>
            : <UploadImageSection>
                <Title>
                    <h2>{t(returnMessage)}</h2>
                </Title>
                
                <button onClick={handleClick}>
                    {t('drivers.try_again')}
                </button>
            </UploadImageSection>
}

export default UploadImage;
