import { Skeleton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { Button, CardHeader, ChevronDown, Content, Divider, FilterAlt, MainCard, NoContent, Pagination, Row, Title } from '../../../common';
import Column from '../../../common/components/column';
import { BarChartDataStackProps, BarChartStack } from '../../../common/components/graphics/bar-chart-stack';
import { PieChart, PieChartDataProps } from '../../../common/components/graphics/pie-chart';
import UpdateData from '../../../common/components/update-data';
import { lastAlarms } from '../../../common/constants/last-alarms';
import { LastAlarmsHelper } from '../../../common/helpers/last-alarms.helper';
import DialogModal from '../../../modules/analytics/last-alarm/components/dialog-modal';
import { LastAlarmFilter } from '../../../modules/analytics/last-alarm/components/last-alarm-filter';
import TableList from '../../../modules/analytics/last-alarm/components/table-list';
import { LastAlarmDTO } from '../../../modules/analytics/last-alarm/dtos/last-alarm-dtos';
import { useLastAlarm } from '../../../modules/analytics/last-alarm/hook/use-last-alarm';
import { ChartContainer, ChartContent, ChartSubTitle, LargeChartContainer, PageChange, TitleContainer } from './styles';

interface IOperationStatus {
    description: string;
    percentage: number;
}

interface IOperationLastAlarm {
    operation: string;
    status: Array<IOperationStatus>;
}

export function LastAlarm() {
    const { t } = useTranslation();

    const {
        handleGetList,
        handleRequestList,
        handleGetListDetails,
        responseStatusDetails,
        requestData,
        listLastAlarmDetails,
        listLastAlarm,
        handleFilterIsOpen,
        filterIsOpen,
        responseStatus,
    } = useLastAlarm();

    const filters = useMemo(() => {
        if (filterIsOpen) {
            return <LastAlarmFilter />;
        }

        return null;
    }, [filterIsOpen]);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const handleUpdate = useCallback(async () => {
        handleGetList();
        handleGetListDetails();
    }, [handleGetList, handleGetListDetails]);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    const handleOpenDialog = useCallback(() => {
        setDialogIsOpen(state => !state);
    }, []);

    useEffect(() => {
        return () => {
            handleRequestList({
                currentPage: 1,
                pageSize: 10,
            });
        };
    }, [handleRequestList]);

    const paginationContent = useMemo(() => {
        return (
            <>
                <div>
                    <Divider />
                    <Pagination
                        onPageChange={currentPage => handleRequestList({ currentPage })}
                        currentPage={requestData.currentPage}
                        perPage={requestData.pageSize}
                        total={listLastAlarmDetails?.totalElements}
                    />
                </div>
            </>
        );
    }, [handleRequestList, listLastAlarmDetails?.totalElements, requestData.currentPage, requestData.pageSize]);

    const renderDataPieChart = useMemo(() => {
        const content: Array<PieChartDataProps> = [];

        const lastAlarmGroup: Array<{ status: string; total: number }> = [];

        listLastAlarm?.forEach(lastAlarm => {
            const index = lastAlarmGroup.findIndex(data => data.status === lastAlarm.status);

            if (index !== -1) {
                lastAlarmGroup[index].total += lastAlarm.fleets;
            } else {
                lastAlarmGroup.push({
                    status: lastAlarm.status,
                    total: lastAlarm.fleets,
                });
            }
        });

        lastAlarmGroup?.forEach(data => {
            content.push({
                name: data.status,
                value: data.total,
                color: LastAlarmsHelper.getColorByAlarmName(data.status),
            });
        });

        return content;
    }, [listLastAlarm]);

    const renderStatusPerOperationLegends = useMemo(() => {
        const operations = [...new Set(listLastAlarm?.map(data => data.operation))];

        return operations;
    }, [listLastAlarm]);

    const renderStatusPerOperationData = useMemo(() => {
        const content: Array<BarChartDataStackProps> = [];

        lastAlarms.forEach(alarm => {
            content.push({
                label: alarm.name,
                data: [],
                color: alarm.color,
            });
        });

        const listLastAlarmsGrouped: Array<IOperationLastAlarm> = [];

        listLastAlarm?.map((data: LastAlarmDTO) => {
            const indexGrouped = listLastAlarmsGrouped.findIndex(grouped => grouped.operation === data.operation);

            if (indexGrouped === -1) {
                const listStatus: Array<IOperationStatus> = [];

                lastAlarms.forEach(alarm => {
                    listStatus.push({
                        description: alarm.name,
                        percentage: alarm.name === data.status ? data.percentage : 0,
                    });
                });

                listLastAlarmsGrouped.push({
                    operation: data.operation,
                    status: listStatus,
                });
            } else {
                const indexStatus = listLastAlarmsGrouped[indexGrouped].status.findIndex(status => status.description === data.status);

                if (indexStatus !== -1) {
                    listLastAlarmsGrouped[indexGrouped].status[indexStatus].percentage = data.percentage;
                }
            }
        });

        listLastAlarmsGrouped?.forEach(lastAlarm => {
            lastAlarm.status.forEach((status, index) => {
                content[index].data.push(Number(status.percentage));
            });
        });

        return content;
    }, [listLastAlarm]);

    const contentPieChart = useMemo(() => {
        if (responseStatus?.loading) {
            return (
                <Content style={{ display: 'flex', flexDirection: 'column' }}>
                    <Skeleton height={'4rem'} width={'20rem'} />
                    <Skeleton height={'3rem'} width={'40rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'32rem'} style={{ marginTop: '-7rem' }} />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return (
            <>
                <TitleContainer>
                    <div>
                        <Title>
                            <h2>{t('last_alarm.proportion')}</h2>
                        </Title>
                        <ChartSubTitle>{t('last_alarm.subtitle_pie_chart')}</ChartSubTitle>
                    </div>
                </TitleContainer>
                <ChartContent>
                    <PieChart data={renderDataPieChart} showPercentage />
                </ChartContent>
            </>
        );
    }, [handleGetList, renderDataPieChart, responseStatus?.hasError, responseStatus?.loading, responseStatus?.void, t]);

    const contentPolarChart = useMemo(() => {
        if (responseStatus?.loading) {
            return (
                <Content style={{ display: 'flex', flexDirection: 'column' }}>
                    <Skeleton height={'4rem'} width={'20rem'} />
                    <Skeleton height={'3rem'} width={'40rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'32rem'} style={{ marginTop: '-7rem' }} />
                </Content>
            );
        } else if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        } else if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return (
            <>
                <TitleContainer>
                    <div>
                        <Title>
                            <h2>{t('last_alarm.operation')}</h2>
                        </Title>
                        <ChartSubTitle>{t('last_alarm.subtitle_bar_chart')}</ChartSubTitle>
                    </div>
                    <div className="button-container">
                        <button onClick={handleOpenDialog}>
                            <AiOutlineInfoCircle color="#46526C" size={20} />
                        </button>
                    </div>
                </TitleContainer>
                <ChartContent>
                    <BarChartStack
                        legends={renderStatusPerOperationLegends}
                        data={renderStatusPerOperationData}
                        isDataZoom
                        dataZoom={{
                            start: 0,
                            end: 100,
                        }}
                    />
                </ChartContent>
            </>
        );
    }, [
        handleGetList,
        handleOpenDialog,
        renderStatusPerOperationData,
        renderStatusPerOperationLegends,
        responseStatus?.hasError,
        responseStatus?.loading,
        responseStatus?.void,
        t,
    ]);

    const contentTable = useMemo(() => {
        if (responseStatusDetails?.loading) {
            return (
                <Content className="mb-16" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Skeleton height={'3.5rem'} width={'20rem'} />
                    <Skeleton height={'4rem'} />
                    <Skeleton height={'4rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'4rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'4rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'4rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'4rem'} style={{ marginTop: '-1.5rem' }} />
                </Content>
            );
        } else if (responseStatusDetails?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetListDetails} messageClassName="error" />;
        } else if (responseStatusDetails?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetListDetails} />;
        }

        return (
            <>
                <TitleContainer>
                    <div>
                        <Title>
                            <h2>{t('last_alarm.table')}</h2>
                        </Title>
                    </div>
                </TitleContainer>

                <TableList />

                <PageChange>{paginationContent}</PageChange>
            </>
        );
    }, [handleGetListDetails, paginationContent, responseStatusDetails?.hasError, responseStatusDetails?.loading, responseStatusDetails?.void, t]);

    return (
        <Column width="100%">
            <Row align="center" width="100%" justify="space-between">
                <Title>
                    <h2>Analytics</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
            </Row>

            <MainCard style={{ paddingBottom: '2rem', overflowY: 'hidden' }}>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>{t('last_alarm.last_alarm')}</h3>
                        </Row>
                        <Row align="flex-start" gap={16}>
                            <Row align="center" gap={8}>
                                <Button
                                    color={filterIsOpen ? 'purple' : 'outline-purple'}
                                    leftIcon={<FilterAlt color={filterIsOpen ? '#fff' : '#46526C'} />}
                                    size="small"
                                    rightIcon={<ChevronDown spin={filterIsOpen} color={filterIsOpen ? '#fff' : '#46526C'} />}
                                    type="button"
                                    onClick={handleFilterIsOpen}
                                >
                                    {t('general.filter')}
                                </Button>
                            </Row>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {filters}
                </Column>

                <Row padding="0.8rem" style={{ justifyContent: 'space-between' }}>
                    <ChartContainer>{contentPieChart}</ChartContainer>
                    <ChartContainer>{contentPolarChart}</ChartContainer>
                </Row>

                <Row padding="0.8rem">
                    <LargeChartContainer>{contentTable}</LargeChartContainer>
                </Row>
                <DialogModal onClose={handleOpenDialog} open={dialogIsOpen}>
                    <h2>{t('last_alarm.informative')}</h2>
                    <div>
                        <p>{t('last_alarm.a_fleet_is_classified_as')}</p>
                        <ul>
                            <li>{t('last_alarm.no_communication')}</li>
                            <li>{t('last_alarm.communicating_without_event')}</li>
                            <li>{t('last_alarm.if_you_are_communicating')}</li>
                        </ul>
                    </div>
                </DialogModal>
            </MainCard>
        </Column>
    );
}
