import { useMutation } from '@tanstack/react-query';

import { vehicleReportService } from '../services/report.service';

export function useReport() {
    return useMutation({
        mutationKey: ['generate-vehicles-report'],
        mutationFn: vehicleReportService.generateReport,
    });
}
