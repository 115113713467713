import styled from 'styled-components';

export const Paragraph = styled.p`
    margin: 0;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #7b7b7b;
`;
