import { api } from 'src/common/infra/http';

class FilterByAlarmsName {
    public async execute() {
        const {
            data: { content },
        } = await api.get<any>('/alarms-processing/filter_by_alarms_name');

        return content;
    }
}

export const filterByAlarmsName = new FilterByAlarmsName();
