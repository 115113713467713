import styled from 'styled-components';

export const StyledSupport = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
    flex-wrap: wrap;

    gap: 0.35rem;
    height: 24px;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;

    p {
        margin: 0;
        font-family: 'Space Grotesk';
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        transition: 200ms color;
    }

    &:hover {
        p {
            color: #6da4d3;
        }

        svg path:last-child {
            stroke: #6da4d3;
            fill: #6da4d3;
        }

        svg path:first-child {
            fill: #6da4d3;
        }
    }
`;
