import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from 'src/modules/auth';

import { api, Button, RenderIf, Select, useListLayout, usePermission, yup } from '../../../../common';
import { Checkbox, ChevronDown, Button as ButtonFilter, Divider, FilterAlt, IOSSwitch, Input } from '../../../../common/components';
import { BarChart, BarChartDataProps } from '../../../../common/components/graphics/bar-chart';
import { TypeAlarmDistribution } from '../../../../common/constants/type-alarms-distribution';
import { EventHelper } from '../../../../common/helpers/events.helper';
import {
    EventByAnalistDistribuitionDTO,
    EventByAnalysisDataDTO,
    FilterChartRequestDTO,
    MediaVisualizationDTO,
    SearchAlarmsRequestDTO,
} from '../../dtos/event.dto';
import { useEvents } from '../../hooks';
import { analystOnlineService } from '../../services/analyst-online.service';
import {
    ActivityBody,
    ActivityChartContainer,
    ActivityContainer,
    ActivityFormContainer,
    ActivityFormContent,
    ActivityHeader,
    ActivityOptionsButton,
    ActivityOptionsChartContainer,
    ActivityOptionsContainer,
    ActivityOptionsSwitch,
    ActivityTitleContainer,
    ChartFilterContainer,
    ChartFilterForm,
    ChartFilterFormColumn,
    OnlineTitle,
    Title,
} from './styles';

export function ActivityCard() {
    const { has } = usePermission();
    const { session } = useAuth();
    const { handleMediaVisualization } = useListLayout();

    const [openCard, setOpenCard] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [isPending, setIsPeding] = useState(false);
    const [hoursActivity, setHoursActivity] = useState(24);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isOnline, setIsOnline] = useState(false);

    const [events, setEvents] = useState<EventByAnalysisDataDTO[]>([]);
    const { handleRequestList, handleGetList } = useEvents();

    const getEventsByAnalyst = useCallback(() => {
        if (openCard) {
            const endpoint = isPending ? '/alarms-processing/pending-events-by-analysis' : '/alarms-processing/processed-events-by-analysis';

            api.get(endpoint, {
                params: {
                    hour: hoursActivity,
                },
            }).then(response => {
                setEvents(response.data.content);
            });
        }
    }, [openCard, isPending, hoursActivity]);

    const verifyOnlineAnalyst = useCallback(() => {
        api.get('/analyst/online')
            .then(response => {
                setIsOnline(response.data.content);
            })
            .catch(() => {
                setIsOnline(false);
            });
    }, []);

    useEffect(() => {
        verifyOnlineAnalyst();

        getEventsByAnalyst();
    }, [getEventsByAnalyst, verifyOnlineAnalyst]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getEventsByAnalyst();
        }, 15000);

        return () => {
            clearInterval(intervalId);
        };
    }, [getEventsByAnalyst]);

    const handleOpenCard = (isOpen?: boolean) => {
        if (isOpen !== undefined) {
            return setOpenCard(isOpen);
        }

        const isNewOpen = !openCard;

        setOpenCard(isNewOpen);

        if (!isNewOpen) {
            handleOpenFilters(false);
        }
    };

    const handleOpenFilters = (isOpen?: boolean) => {
        if (isOpen !== undefined) {
            return setOpenFilter(isOpen);
        }

        setOpenFilter(!openFilter);
        handleOpenCard(true);
    };

    const handleChangeChart = (openPending: boolean) => {
        setIsPeding(openPending);
        handleOpenCard(true);
    };

    const handleApplyFilters = useCallback((data: FilterChartRequestDTO) => {
        setHoursActivity(data.hour);
    }, []);

    const { register, handleSubmit } = useForm<FilterChartRequestDTO>({
        defaultValues: {
            hour: hoursActivity,
        },
        resolver: yupResolver(
            yup.object().shape({
                hour: yup.number().min(1),
            }),
        ),
    });

    const handleApplySearchAlarm = useCallback(
        (data: SearchAlarmsRequestDTO) => {
            const startDate = moment().subtract(3, 'hours');
            const endDate = moment(startDate).subtract(data.search_period, 'hours');

            handleRequestList({
                initialDate: endDate.toDate(),
                finalDate: startDate.toDate(),
                sortProps: [
                    {
                        keyOrder: 'Prioridade',
                        sortOrder: 'ASC',
                    },
                    {
                        keyOrder: 'Chegada',
                        sortOrder: 'ASC',
                    },
                ],
                typeAlarmDistribution: data.alarm_distribution as TypeAlarmDistribution,
                analytics: session?.user.email,
                isAnalyst: session?.user.profile === "Analista",
            });
        },
        [handleRequestList, session?.user.email, session?.user.profile],
    );

    useEffect(() => {
        handleGetList();
    }, [handleGetList]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isOnline) {
            interval = setInterval(() => {
                analystOnlineService.execute(true);
            }, 15000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isOnline]);

    const { register: registerSearchAlarm, handleSubmit: handleSubmitSearchAlarm } = useForm<SearchAlarmsRequestDTO>({
        defaultValues: {
            alarm_distribution: 'dynamic',
            search_period: 24,
        },
        resolver: yupResolver(
            yup.object().shape({
                alarm_distribution: yup.string(),
            }),
        ),
    });

    const renderBarChart = () => {
        const listData: BarChartDataProps[] = [];
        const listEvents = events;

        const uniqueEvents = Array.from(
            new Set(
                listEvents.flatMap((analystData) =>
                    analystData.events.map((event) => event.name)
                )
            )
        );

        const totalEventsProcessedArray = listEvents.map(analyst => analyst.totalEventsProcessed) as number[];

        // Sort the array in ascending order
        const sortedArray = totalEventsProcessedArray.sort((a, b) => b - a);

        uniqueEvents.map((unEvent, index) => {
            listData.push({
                data: [],
                name: unEvent,
                color: EventHelper.getColorByAlarmName(unEvent),
                total: sortedArray,
            });
            listEvents.map(() => {
                listData[index].data.push(0)
            })
        })

        listEvents.forEach((data, indexEvent) => {
            data.events.forEach(event => {
                const index = listData.findIndex(dataList => dataList.name === event.name);
                if (index !== -1) {
                    listData[index].data[indexEvent] += event.total;
                }
            });
        });
        return listData;
    };

    const renderBarAxisX = () => {
        const listData: string[] = [];

        events.forEach(event => {
            const index = listData.findIndex(data => data === event.analyst.email);

            if (index === -1) {
                listData.push(event.analyst.name);
            }
        });

        return listData;
    };

    const { register: registerDistribuitionEvents, handleSubmit: handleSubmitDistribuitionEvents } = useForm<EventByAnalistDistribuitionDTO>({
        defaultValues: {
            percentage: 50,
        },
        resolver: yupResolver(
            yup.object().shape({
                percentage: yup.number().min(1).max(100),
            }),
        ),
    });

    const handleApplyMediaSize = useCallback(
        (data: MediaVisualizationDTO) => {
            const newHeight = Math.round((150 * data.size) / 205);

            handleMediaVisualization({
                heigth: newHeight,
                width: data.size,
            });
        },
        [handleMediaVisualization],
    );

    const { register: registerMediaSize, handleSubmit: handleSubmitMediaSize } = useForm<MediaVisualizationDTO>({
        defaultValues: {
            size: 205,
        },
        resolver: yupResolver(
            yup.object().shape({
                size: yup.number(),
            }),
        ),
    });

    const handleDistribuitionEvents = useCallback(
        async (data: EventByAnalistDistribuitionDTO) => {
            const emailSession = session?.user.email;

            setButtonLoading(true);

            try {
                await api.delete('/alarms-processing/delete-events-by-analist', {
                    data: {
                        analyst: emailSession,
                        percentage: data.percentage,
                    },
                });
            } finally {
                setButtonLoading(false);
            }
        },
        [session?.user.email],
    );

    const handleImOnline = useCallback(
        async (checked: boolean) => {
            await analystOnlineService.execute(checked);

            setIsOnline(checked);

            if (checked) {
                handleGetList();
            }
        },
        [handleGetList],
    );

    return (
        <ActivityContainer>
            <ActivityHeader>
                <ActivityTitleContainer>
                    <Title>
                        {t('alarms.activity_analyst')} (
                        {hoursActivity === 1 ? t('general.last').replace('s', '').toLowerCase() : t('general.last').toLowerCase()}{' '}
                        {hoursActivity === 1 ? '' : hoursActivity}{' '}
                        {hoursActivity === 1 ? t('general.hours').replace('s', '').toLowerCase() : t('general.hours').toLowerCase()})
                    </Title>

                    {openCard && (
                        <ActivityOptionsChartContainer>
                            <span onClick={() => handleChangeChart(false)}>{t('alarms.processed_alarms')}</span>
                            <Checkbox
                                mode="blue-fill"
                                className="not-absolute"
                                name="checkbox-events-filter"
                                checked={!isPending}
                                onChange={() => handleChangeChart(false)}
                            />

                            <span onClick={() => handleChangeChart(true)}>{t('alarms.open_alarms')}</span>
                            <Checkbox
                                mode="blue-fill"
                                className="not-absolute"
                                name="checkbox-events-filter"
                                checked={isPending}
                                onChange={() => handleChangeChart(true)}
                            />
                        </ActivityOptionsChartContainer>
                    )}
                </ActivityTitleContainer>

                <ActivityOptionsContainer>
                    <ActivityOptionsSwitch>
                        <OnlineTitle isOnline={isOnline}>{t('general.online')}</OnlineTitle>

                        <IOSSwitch checked={isOnline || false} onChange={(e: any) => handleImOnline(e.target.checked)} />
                    </ActivityOptionsSwitch>

                    <ActivityOptionsButton onClick={() => handleOpenFilters()}>
                        <FilterAlt color={'#46526C'} />
                    </ActivityOptionsButton>

                    <ActivityOptionsButton onClick={() => handleOpenCard()}>
                        <ChevronDown spin={openCard} color={'#46526C'} />
                    </ActivityOptionsButton>
                </ActivityOptionsContainer>
            </ActivityHeader>

            {openFilter && (
                <ChartFilterContainer>
                    <ChartFilterForm onSubmit={handleSubmit(handleApplyFilters)}>
                        <ChartFilterFormColumn>
                            <Input
                                {...register('hour')}
                                type="number"
                                label={String(t('general.hours'))}
                                placeholder={String(t('general.hours'))}
                                defaultValue={24}
                                min={1}
                            />
                        </ChartFilterFormColumn>

                        <ChartFilterFormColumn style={{ width: '10rem' }}>
                            <ButtonFilter type="submit" size="small" disabled={hoursActivity <= 0} style={{ marginTop: '1.5rem' }}>
                                {t('general.apply')}
                            </ButtonFilter>
                        </ChartFilterFormColumn>
                    </ChartFilterForm>
                </ChartFilterContainer>
            )}

            {openCard && (
                <ActivityBody>
                    <ActivityChartContainer>
                        <BarChart yData={renderBarAxisX()} data={renderBarChart()} showLabel={true} />
                    </ActivityChartContainer>

                    <ActivityFormContainer>
                        <RenderIf condition={has('event_redistribution')}>
                            <ActivityFormContent onSubmit={handleSubmitDistribuitionEvents(handleDistribuitionEvents)}>
                                <h3 style={{ marginTop: -3 }}>{t('alarms.select_redistribution')}</h3>

                                <Input
                                    id="filter-redistribution"
                                    autoComplete="off"
                                    label={String(t('alarms.label_select_redistribution'))}
                                    placeholder={String(t('alarms.placeholder_select_redistribution'))}
                                    {...registerDistribuitionEvents('percentage')}
                                    type="number"
                                    min={0}
                                    max={100}
                                    maxLength={3}
                                />

                                <Button disabled={buttonLoading} color="outline" style={{ alignSelf: 'center' }}>
                                    {t('general.apply')}
                                </Button>
                            </ActivityFormContent>
                        </RenderIf>

                        <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />

                        <ActivityFormContent onSubmit={handleSubmitSearchAlarm(handleApplySearchAlarm)}>
                            <h3>{t('alarms.alarms_search_parameters')}</h3>

                            <Select
                                label={String(t('alarms.label_alarms_search_parameters'))}
                                {...registerSearchAlarm('alarm_distribution')}
                                options={[
                                    { label: String(t('alarms.dynamic')), value: TypeAlarmDistribution.DYNAMIC },
                                    { label: String(t('general.see_all')), value: TypeAlarmDistribution.ALL },
                                ]}
                            />
                            <Input
                                type="number"
                                label={String(t('alarms.input_alarms_search_parameters'))}
                                placeholder={String(t('alarms.placeholder_alarms_search_parameters'))}
                                {...registerSearchAlarm('search_period')}
                            />

                            <Button style={{ alignSelf: 'center' }} color="outline">
                                {t('general.apply')}
                            </Button>
                        </ActivityFormContent>

                        <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />

                        <ActivityFormContent onSubmit={handleSubmitMediaSize(handleApplyMediaSize)}>
                            <h3>{t('alarms.media_visualization')}</h3>

                            <Input
                                type="number"
                                label={String(t('alarms.media_size'))}
                                placeholder={String(t('alarms.placeholder_midias_size'))}
                                {...registerMediaSize('size')}
                            />

                            <Button style={{ alignSelf: 'center' }} color="outline" type="submit">
                                {t('general.apply')}
                            </Button>
                        </ActivityFormContent>
                    </ActivityFormContainer>
                </ActivityBody>
            )}
        </ActivityContainer>
    );
}
