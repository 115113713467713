export const hourRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]+$/g;

export class HourHelper {
    public static matches(str: string): boolean {
        const matches = str?.match(hourRegex);

        if (!matches) return false;

        return matches?.length > 0;
    }

    public static mask(value: string): string {
        let newValue = value.replace(/\D/g, '');

        newValue = newValue.replace(/^(\d{2})(\d)/, '$1:$2');

        if (newValue.length > 2) {
            newValue = `${newValue.substring(0, 2)}:${newValue.substring(3, 5)}`;
        }

        return newValue;
    }
}
