import styled from 'styled-components';

interface Props {
    active?: boolean;
}

export const MainMenu = styled.div`
    background: #ffffff;
    border-radius: 0.5rem;

    font-size: 1rem;
    font-style: normal;
    line-height: 1.5rem;
    color: #333333;

    .upMenu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 1rem 0 1rem;
        padding: 1rem 0;
        align-items: center;

        > div > h4 {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: #4d4d4d;
        }
    }

    h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #4d4d4d;
        display: flex;
        align-items: center;
        gap: 8px;

        margin: 0;
    }

    span.total {
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        margin: 0;

        color: #a9a9a9;
    }
`;

export const TableContainer = styled.table`
    border-collapse: collapse;
    width: 100%;
    height: fit-content;
    overflow-x: auto !important;
`;

export const TableData = styled.td`
    font-style: normal;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    text-align: left;
    color: #7b7b7b;
    padding: 0.5rem 0 0;

    &:first-child {
        padding-left: 16px;
    }
`;

export const Dialog = styled.dialog`
    border: 0;
    padding: 0;
    background-color: transparent;
    overflow: hidden;
`;

export const TableHead = styled.thead`
    padding: 0.5rem;
    text-align: center;
`;

export const TableHeader = styled.th`
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;

    padding-bottom: 1rem;
    text-align: left;

    &:first-child {
        padding-left: 1rem;
    }
`;

export const TableRow = styled.tr`
    &.body {
        border-top: 1px solid #d9d9d9;
    }

    &.pointer {
        cursor: pointer;
    }
    .relative {
        position: relative;
    }
`;

export const PopupContainer = styled.div`
    display: grid;
    height: 50rem;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
    width: 75rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
    border-radius: 8px;

    .image {
        width: 50%;
        height: 15rem;
        border-radius: 1rem;
    }

    .textArea {
        width: 100%;
        height: 43%;
        border: none;
        outline: none;
        resize: none;
    }
`;

export const InterventionsInformationsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.3rem 0 1rem 0;
`;

export const TableEventsContainer = styled.div`
    background: red;
`;

export const SpacingBlankContainer = styled.div`
    margin: 0.5rem 0 0.5rem;
`;

export const ModalContentInfoContainer = styled.div`
    display: flex;
    column-gap: 1rem;
`;

export const ModalContentInfo = styled.div`
    margin-top: -0.5rem;
    > p {
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 1.2rem;
        color: rgb(38, 45, 60);
    }

    > div {
        display: flex;
        gap: 0.8rem;
        margin-bottom: 0.6rem;

        > label {
            font-weight: 500;
            font-size: 14px;
            line-height: 1rem;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            color: rgb(123, 123, 123);
            transform: rotate(-0.16deg);
        }

        > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            -webkit-box-align: center;
            align-items: center;
            color: rgb(38, 45, 60);
            word-break: break-all;
        }
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: end;

    gap: 0.5rem;
`;

export const IconButton = styled.button<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0.3rem;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    background: ${props => (props.active ? '#262D3C' : '#D7D7D7')};
    svg {
        color: ${props => (props.active ? 'white' : '#262D3C')};
    }

    &:hover {
        background: #262d3c;
        transition: 0.5s;

        svg {
            color: ${({ color }) => color ?? 'white'};
        }
    }

    &.closeButton:hover {
        background: #f03738;
    }
`;

export const CheckIconButton = styled.button<Props>`
    border: none;
    border-radius: 0.3rem;
    height: 2.15rem;
    width: 2.15rem;
    cursor: pointer;
    background: ${props => (props.active ? '#262D3C' : '#262d3c')};
    svg {
        color: ${props => (props.active ? 'white' : 'white')};
    }

    &:hover {
        transition: all 0.3s ease-out 0s;

        svg {
            color: ${({ color }) => color ?? 'white'};
        }
    }

    &.checkButton:hover {
        background: #46526c;
    }
`;

export const CommentsContainer = styled.div`
    border: 1px solid #c2c9d1;
    border-radius: 1rem;
    height: 41rem;

    .senderName {
        color: #6da4d3;
        font-weight: 600;
        font-size: 13px;
    }

    .senderMessage {
        font-size: 11px;
    }

    .messageInformations {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .commentContainerLeft {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: column;
        margin: 1rem 0.5rem 0 0.5rem;
    }

    .commentSectionLeft {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
        border: 1px solid #d6dce1;
        border-radius: 10px;
        background: #f6f6f6;
        padding: 0.4rem 0.4rem 0.4rem 0.6rem;
        width: 100%;
        white-space: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

    .commentContainerCenter {
        display: flex;
        margin: 1rem 0 0;
        width: 100%;
        gap: 0.2rem;
        justify-content: center;
    }

    .commentSectionCenter {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem 0 0.4rem 0.6rem;
        white-space: normal;
        gap: 0.3rem;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

    .spanComment {
        font-size: 0.875rem;
        white-space: normal;
    }
`;

export const SituationContainer = styled.div`
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 1rem;
`;

export const ListMessagesContainer = styled.div`
    height: 100%;
`;

export const ListMessagesContent = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 91%;
`;

export const ListMessagesForm = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    width: 100%;
    height: 9%;
    gap: 0.5rem;

    .commentButton {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;
