import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    CardHeader,
    Column,
    Content,
    DeleteButton,
    Divider,
    FilterButton,
    MainCard,
    NoContent,
    Pagination,
    RenderIf,
    Row,
    SelectNumber,
    Skeleton,
    Title,
    ToggleSwitch,
    UsedFilters,
} from 'src/common/components';
import { TypeSearch } from 'src/common/constants';
import { ObjectHelper } from 'src/common/helpers/object.helper';
import { useEventListener, usePermission, useSearch } from 'src/common/hooks';
import { ListUsersDTO, UserAddAlt } from 'src/modules/configurations/users';
import { CreateOrUpdateUserDialog, DeleteUsersBulkDialog, TableList, UsersFilters } from 'src/modules/configurations/users/components';
import { useUsers } from 'src/modules/configurations/users/hooks/use-users';

export default function Users() {
    const { isAdmin } = usePermission();
    const { formRef, clear } = useSearch();
    const { t } = useTranslation();
    const { requestData, handleRequestList, users, responseStatus, handleGetList, handleOpenDialog, selectedUsers, handleSelectAll } = useUsers();

    const deleteUsersRef = useRef<HTMLDialogElement>(null);
    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const handleClearFilterValue = useCallback(
        (key: keyof ListUsersDTO) => {
            handleRequestList({
                [key]: '',
                currentPage: 1,
            });
            setFilterIsOpen(false);
        },
        [handleRequestList],
    );
    const handleClearFilters = useCallback(() => {
        handleRequestList({
            creator: '',
            currentPage: 1,
            email: '',
            function: '',
            name: '',
        });
        setFilterIsOpen(false);
    }, [handleRequestList]);

    const content: JSX.Element = useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            );
        }

        if (responseStatus?.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetList} messageClassName="error" />;
        }

        if (responseStatus?.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetList} />;
        }

        return <TableList />;
    }, [handleGetList, responseStatus?.hasError, responseStatus.loading, responseStatus?.void, t]);

    const filters = useMemo(() => (filterIsOpen ? <UsersFilters /> : null), [filterIsOpen]);

    const usedFilters = useMemo(
        () => ({
            name: requestData?.name,
            email: requestData?.email,
            function: requestData?.function,
            creator: requestData?.creator,
        }),
        [requestData?.name, requestData?.email, requestData?.function, requestData?.creator],
    );

    const usedFiltersComponent = useMemo(
        () =>
            !responseStatus?.loading &&
            !ObjectHelper.isEmpty(usedFilters as any) && (
                <UsedFilters data={usedFilters} onClear={handleClearFilterValue} onClearAll={handleClearFilters} />
            ),
        [handleClearFilterValue, handleClearFilters, responseStatus?.loading, usedFilters],
    );

    const handleChangeStatusFilter = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const { checked: enabled } = e.currentTarget;

            handleRequestList({
                currentPage: 1,
                enabled,
            });
        },
        [handleRequestList],
    );

    const handleSearch = useCallback(
        (e: SubmitEvent) => {
            e.preventDefault();
            handleSelectAll([]);

            const { target } = e;

            const currentTarget = target as unknown as HTMLFormElement;

            const text: HTMLInputElement = currentTarget?.elements?.namedItem('text') as HTMLInputElement;

            setFilterIsOpen(false);

            handleRequestList({
                creator: '',
                currentPage: 1,
                email: '',
                function: '',
                name: '',
                text: text.value,
                typeSearch: TypeSearch.TEXT,
            });
        },
        [handleRequestList, handleSelectAll],
    );

    useEffect(() => {
        handleGetList();
    }, [handleGetList]);

    useEffect(() => {
        return () => {
            handleRequestList({
                currentPage: 1,
                pageSize: 15,
                creator: '',
                email: '',
                enabled: true,
                typeSearch: TypeSearch.PARAMS,
                function: '',
                name: '',
                text: '',
            });
            clear();
        };
    }, [clear, handleRequestList]);

    useEventListener(formRef.current, 'submit', handleSearch);

    return (
        <Column gap={16}>
            <Row gap={16} align="center" width="100%" justify="space-between">
                <Title>
                    <h2>{t('general.configurations')}</h2>
                </Title>
                <RenderIf condition={isAdmin}>
                    <Row
                        gap={16}
                        style={{
                            minWidth: selectedUsers?.length ? '396px' : '176px',
                        }}
                    >
                        <RenderIf condition={!!selectedUsers?.length}>
                            <DeleteButton onClick={() => deleteUsersRef?.current?.showModal()} />
                        </RenderIf>
                        <Button type="button" leftIcon={<UserAddAlt />} size="medium" padding="0 12px" onClick={() => handleOpenDialog()}>
                            {t('configurations.add_user')}
                        </Button>
                    </Row>
                </RenderIf>
            </Row>
            <MainCard>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>
                                {t('configurations.users')}
                                {responseStatus?.success && !responseStatus?.loading && <span className="total">({users?.totalElements || 0})</span>}
                            </h3>
                        </Row>
                        <Row align="center" gap={16}>
                            <Row align="center" gap={8}>
                                <span className="per-page">{t('general.items_per_page')}:</span>
                                <SelectNumber
                                    fontMode="small"
                                    value={requestData?.pageSize}
                                    options={[15, 25, 50]}
                                    onChange={e => handleRequestList({ pageSize: Number(e.target.value), currentPage: 1 })}
                                />
                            </Row>
                            <ToggleSwitch
                                mode="underline"
                                name="status"
                                label={t('configurations.show_active_users')}
                                checked={requestData?.enabled}
                                onChange={handleChangeStatusFilter}
                            />
                            <FilterButton isOpen={filterIsOpen} onOpen={setFilterIsOpen} />
                        </Row>
                    </CardHeader>
                    <Divider />
                    {filters}
                    {usedFiltersComponent}
                    {content}
                    <Divider />
                    <RenderIf condition={!!users?.totalElements}>
                        <Pagination
                            perPage={requestData?.pageSize}
                            total={users?.totalElements}
                            onPageChange={currentPage => handleRequestList({ currentPage })}
                            currentPage={requestData?.currentPage}
                        />
                    </RenderIf>
                </Column>
            </MainCard>
            <CreateOrUpdateUserDialog />
            <DeleteUsersBulkDialog ref={deleteUsersRef} />
        </Column>
    );
}
