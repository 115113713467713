import { t } from "i18next";

import { Container } from "./styles";

interface InterventionBadgeProps{
    text: string;
    badgeStyles: 'default'|'primary'|'secondary';
}

export function InterventionBadge({text, badgeStyles}:InterventionBadgeProps) {
    return(
        <Container className={badgeStyles}>
            <p>{t(`interventions.${text}`)}</p>
        </Container>
    )
}
