import { shade } from 'polished';
import styled from 'styled-components';

export const Button = styled.button`
    cursor: pointer;
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    border: 0;
    border-radius: 50%;
    padding: 0;

    &:hover {
        background-color: ${shade(0.1, '#FFFFFF')};
    }
`;
