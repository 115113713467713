export const typeEquipament = [
    { name: 'Sonolência N2', type: 'DS' },
    { name: 'Sonolência N1', type: 'DS' },
    { name: 'Olhando para baixo N2', type: 'DS' },
    { name: 'Olhando para baixo N1', type: 'DS' },
    { name: 'Celular', type: 'DS' },
    { name: 'Sem cinto', type: 'DS' },
    { name: 'Fumando', type: 'DS' },
    { name: 'Oclusão', type: 'DS' },
    { name: 'Bocejo', type: 'DS' },
    { name: 'Face missing', type: 'DS' },
    { name: 'Consumo alimento', type: 'DS' },
    { name: 'Fone de ouvido', type: 'DS' },
    { name: 'Óculos de sol', type: 'DS' },
    { name: 'Colisão frontal', type: 'FW' },
    { name: 'Colisão de pedestres', type: 'FW' },
    { name: 'Excesso de velocidade da via', type: 'FW' },
    { name: 'Colisão frontal de baixa velocidade', type: 'FW' },
    { name: 'Curva rápida', type: 'FW' },
    { name: 'Veículo muito próximo', type: 'FW' },
    { name: 'Excesso de velocidade na faixa de pedestre', type: 'FW' },
    { name: 'Mudança irregular de faixa', type: 'FW' },
    { name: 'Ponto cego', type: 'FW' },
    { name: 'Excesso de velocidade', type: 'FW' },
    { name: 'Aceleração intensa', type: 'Geotab' },
    { name: 'Freada brusca', type: 'Geotab' },
    { name: 'Virada agressiva', type: 'Geotab' },
    { name: 'Sem cinto de segurança', type: 'Geotab' },
    { name: 'Velocidade excessiva', type: 'Geotab' },
    { name: 'Excesso de velocidade na chuva', type: 'Geotab' },
    { name: 'Engine Fault Exception', type: 'Geotab' },
    { name: 'Luz de motor ligada', type: 'Geotab' },
    { name: 'Parado com motor ligado', type: 'Geotab' },
    { name: 'Esgotamento da bateria', type: 'Geotab' },
    { name: 'Detecção de colisão (Insights ativos)', type: 'Geotab' },
    { name: 'Abuso do motor', type: 'Geotab' },
    { name: 'Parado com motor ligado dentro da zona', type: 'Geotab' },
    { name: 'Rotação Excessiva', type: 'Geotab' },
    { name: 'Remoção não autorizada de dispositivo', type: 'Geotab' },
];
