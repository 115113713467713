import { ValueObject } from 'src/common/core/value-object';

interface IPhone {
    value: string;
}

export class Phone extends ValueObject<IPhone> {
    private constructor(props: IPhone) {
        super(props);
    }

    public get value(): string {
        return this.props.value;
    }

    public static format(value: string): string {
        return value.replace(/^(\d{2})(\d)/g, '($1) $2').replace(/(\d)(\d{4})$/, '$1-$2');
    }

    public static removeSpecialChars(value: string): string {
        return value.trim().replace(/\D/g, '');
    }

    public static isValid(value: string): boolean {
        if (!value || !value.trim()) {
            return false;
        }

        return /(^\+{1}\d{1,})$/g.test(value);
    }

    public static create(props: IPhone): Phone {
        if (!this.isValid(props.value)) {
            throw new Error(`Telefone ${props.value} inválido.`);
        }

        return new Phone(props);
    }
}
