import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TypeSearch, useSearch } from 'src/common';
import { Autocomplete, Button, Column, Input, Select } from 'src/common/components';
import { DepartmentResume, departmentService } from 'src/modules/configurations/departments';

import { ListVehicleFilterDTO } from '../../dtos';
import { useVehicles } from '../../hooks/use-vehicles';
import { Filter } from './styles';

interface VehicleFiltersProps {
    onPostSubmit(): void;
}

export function VehicleFilters({ onPostSubmit }: VehicleFiltersProps) {
    const { clear } = useSearch();
    const { setFilter, filter, operations, setOperations, handleSelectAll, getVehicleTypes } = useVehicles();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit: onSubmit,
        setValue,
        watch,
    } = useForm<ListVehicleFilterDTO>({
        defaultValues: filter,
    });

    const handleSubmit = useCallback(
        (data: ListVehicleFilterDTO) => {
            clear();
            handleSelectAll([]);
            setFilter(state => ({
                ...data,
                pageSize: state.pageSize,
                currentPage: 1,
                text: '',
                typeSearch: TypeSearch.PARAMS,
            }));
            onPostSubmit();
        },
        [clear, handleSelectAll, onPostSubmit, setFilter],
    );

    const operation = watch('operation');

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 15,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations]);

    return (
        <Filter onSubmit={onSubmit(handleSubmit)}>
            <Select
                id="filter-vehicle-type"
                label={String(t('configurations.vehicle_type'))}
                options={getVehicleTypes(t)}
                placeholder={String(t('general.select'))}
                {...register('type')}
            />
            <Input
                autoComplete="off"
                label={String(t('alarms.fleet'))}
                id="filter-board-number"
                placeholder={String(t('general.write'))}
                type="text"
                {...register('fleet')}
            />
            <Autocomplete
                isLoading={operations.isLoading}
                label={String(t('monitoring.organization'))}
                options={operations.data.map(o => ({ id: o.id, value: o.name }))}
                placeholder={String(t('general.write'))}
                setValue={setValue}
                id="filter-operation"
                {...register('operation')}
                nextElement={{
                    id: 'vehicle-filter-submit-btn',
                }}
            />

            <Column justify="flex-end" height="60px">
                <Column justify="flex-end" height="36px">
                    <Button type="submit" size="small" width="144px" id="vehicle-filter-submit-btn">
                        {t('general.apply')}
                    </Button>
                </Column>
            </Column>
        </Filter>
    );
}
