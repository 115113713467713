import { createContext, useCallback, useMemo, useState } from 'react';
import { ResponseStatus } from 'src/common/core/api-response';
import { FunctionComponent } from 'src/common/types';
import { EventDTO, ExtraEventsDTO, ListEventOptionsByEventNamesDTO, SortMultipleAlarmsRequestDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { useEvents } from 'src/modules/alarm-processing/hooks';
import { getExtraEventsById, listEventsOptions, reviewAlarm, sortAlarm } from 'src/modules/alarm-processing/services';

import { ShowEventProps, SortAndReviewEventContextProps } from './types';

export const SortAndReviewEventContext = createContext({} as SortAndReviewEventContextProps);

export default function SortAndReviewEventProvider({ children }: FunctionComponent) {
    const [eventData, setEventData] = useState<ExtraEventsDTO | null>(null);
    const [alarmEvent, setAlarmEvent] = useState('');
    const [descriptionDetails, setDescriptionDetails] = useState('');
    const [extraAlarmEvent, setExtraAlarmEvent] = useState<string[]>([]);
    const [options, setOptions] = useState<ListEventOptionsByEventNamesDTO | null>(null);
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: true,
    });
    const [selectedEvents, setSelectedEvents] = useState<EventDTO[]>([]);
    const [showEvent, setShowEvent] = useState<ShowEventProps>({
        data: null,
        index: 0,
    });

    const { events, eventScreenType } = useEvents();

    const allOnThisPageAreSelected: boolean = useMemo(
        () =>
            events?.elements && events?.elements?.length > 0
                ? events.elements?.every(value => selectedEvents.findIndex(dt => value && dt.id === value.id) !== -1)
                : false,
        [events?.elements, selectedEvents],
    );

    const handleResetEventData = useCallback(() => {
        setEventData(null);
    }, []);

    const handleEventChecked = useCallback((event: string) => {
        setAlarmEvent(event);
    }, []);

    const handleExtraEventChecked = useCallback((events: string[]) => {
        setExtraAlarmEvent(events);
    }, []);

    const handleDescriptionDetails = useCallback((description: string) => {
        setDescriptionDetails(description);
    }, []);

    const handleSelectAll = useCallback((data: Array<EventDTO>) => {
        setSelectedEvents(state => {
            const all = state.length > 0 && data?.every(value => state.findIndex(dt => dt.id === value.id) !== -1);

            if (all) {
                return [];
            }

            return [...state, ...data].filter((elem, index, self) => index === self.indexOf(elem));
        });
    }, []);

    const handleDeselectAll = useCallback(() => {
        setSelectedEvents([]);
    }, []);

    const handleSelectEvent = useCallback(
        (data: EventDTO) => {
            setSelectedEvents(state => {
                const index = state.findIndex(event => event.id === data.id);

                if (eventScreenType === 'events') return index === -1 ? [data] : [];

                if (index === -1) {
                    return [...state, data];
                }

                return state.filter(event => event.id !== data.id);
            });
        },
        [eventScreenType],
    );

    const handleGetExtraEvents = useCallback((id: bigint) => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));
        getExtraEventsById
            .execute(id)
            .then(data => {
                setEventData(data);
                setResponseStatus({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: !data,
                    success: true,
                });
            })
            .catch(reason =>
                setResponseStatus({
                    loading: false,
                    error: reason,
                    hasError: true,
                    void: false,
                    success: false,
                }),
            );
    }, []);

    const handleGetListOptions = useCallback((eventNames: string[]) => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));
        listEventsOptions
            .execute(eventNames)
            .then(data => {
                setOptions(data);
                setResponseStatus({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: !data,
                    success: true,
                });
            })
            .catch(reason =>
                setResponseStatus({
                    loading: false,
                    error: reason,
                    hasError: true,
                    void: false,
                    success: false,
                }),
            );
    }, []);

    const handleSortAlarms = useCallback(
        async (data: SortMultipleAlarmsRequestDTO) => {
            return eventScreenType === 'events' ? reviewAlarm.execute({ ...data, id: data.ids[0] as bigint }) : sortAlarm.execute(data);
        },
        [eventScreenType],
    );

    const handleShowEvent = useCallback((data: ShowEventProps) => {
        setShowEvent(data);
    }, []);

    const data: SortAndReviewEventContextProps = useMemo(
        () => ({
            allOnThisPageAreSelected,
            eventData,
            handleGetExtraEvents,
            handleDescriptionDetails,
            handleEventChecked,
            handleExtraEventChecked,
            handleGetListOptions,
            handleResetEventData,
            handleSelectAll,
            handleDeselectAll,
            handleSelectEvent,
            handleShowEvent,
            handleSortAlarms,
            options,
            responseStatus,
            selectedEvents,
            showEvent,
            descriptionDetails,
            alarmEvent,
            extraAlarmEvent,
        }),
        [
            allOnThisPageAreSelected,
            eventData,
            handleGetExtraEvents,
            handleDescriptionDetails,
            handleEventChecked,
            handleExtraEventChecked,
            handleGetListOptions,
            handleResetEventData,
            handleSelectAll,
            handleDeselectAll,
            handleSelectEvent,
            handleShowEvent,
            handleSortAlarms,
            options,
            responseStatus,
            selectedEvents,
            showEvent,
            descriptionDetails,
            alarmEvent,
            extraAlarmEvent,
        ],
    );

    return <SortAndReviewEventContext.Provider value={data}>{children}</SortAndReviewEventContext.Provider>;
}
