import { ReactNode } from 'react';

import { Modal } from '../../../../../common';
import { Card } from './styles';

interface DialogModalProps {
    open: boolean;
    onClose: () => void;
    children: ReactNode;
}

export default function DialogModal({ open, onClose, children }: DialogModalProps) {
    return (
        <Modal open={open} onClose={onClose}>
            <Card>{children}</Card>
        </Modal>
    );
}
