import { Button } from 'src/common';
import styled from 'styled-components';

export const CancelButton = styled(Button).attrs(() => ({
    type: 'button',
    color: 'outline',
    id: 'cancel-btn',
}))`
    &#cancel-btn {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.2px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #7b7b7b;
        background: #d7d7d7;
        border: 1px solid #a9a9a9;

        &:hover {
            background-color: #f5f5f5;
        }
    }
`;

export const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
    width: 394px;

    h4 {
        color: #262d3c;
        margin: 0;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        color: #7b7b7b;
        text-align: center;
        word-break: break-all;
    }
`;
