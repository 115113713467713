import { styled as mui, Modal as MuiModal } from '@mui/material';

export const Modal = mui(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .modal-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;

        color: #262d3c;
        margin: 0;
    }
`;
