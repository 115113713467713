import styled from 'styled-components';

export const FieldsetContainer = styled.fieldset`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 4px;
    border: 0;
    flex: 1;
    width: 100%;
    margin: 0;

    legend {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #262d3c;
        padding: 0;
    }
`;
