import { SVGProps } from 'react';
import { Row, Time } from 'src/common/components';

import { Tab } from './styles';

interface TabsProps {
    value: number;
    onChange: (value: number) => void;
}

function FileDock(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={18} height={24} viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.339 1.333H5.57c-2.155 0-3.232 0-3.902.695C1 2.722 1 3.839 1 6.074v11.852c0 2.235 0 3.352.67 4.046.669.695 1.746.695 3.901.695h6.858c2.155 0 3.232 0 3.902-.695.669-.694.669-1.811.669-4.046V8.24c0-.484 0-.727-.087-.944-.087-.218-.252-.39-.582-.732l-4.376-4.537c-.33-.343-.495-.514-.705-.605-.21-.09-.444-.09-.911-.09z"
                stroke="#fff"
                strokeWidth={2}
            />
            <path d="M5 13.333h8M5 17.333h5.333" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
            <path d="M10.334 1.333v4.445c0 1.047 0 1.571.325 1.896.325.326.85.326 1.897.326H17" stroke="#fff" strokeWidth={2} />
        </svg>
    );
}

function UserBox(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16.151 20.286a5.333 5.333 0 00-10.303 0" stroke="#A9A9A9" strokeWidth={2} />
            <ellipse cx={11.0002} cy={9.66667} rx={2.66667} ry={2.66667} stroke="#A9A9A9" strokeWidth={2} strokeLinecap="round" />
            <rect x={1.3335} y={1.33325} width={19.3333} height={19.3333} rx={3} stroke="#A9A9A9" strokeWidth={2} />
        </svg>
    );
}

export default function Tabs({ onChange, value }: TabsProps) {
    const options = [
        { value: 0, icon: <FileDock className="file-dock" /> },
        // { value: 1, icon: <UserBox /> },
        { value: 2, icon: <Time className="time" /> },
    ];

    return (
        <Row align="flex-start" gap={16}>
            {options.map(option => (
                <Tab onClick={() => onChange(option.value)} type="button" key={option.value} value={option.value} selected={value === option.value}>
                    {option.icon}
                </Tab>
            ))}
        </Row>
    );
}
