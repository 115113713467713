export function HalfCheck() {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.90956 0.55249H19.6449C20.9598 0.55249 22.0356 1.62833 22.0356 2.94325V19.6786C22.0356 20.9935 20.9598 22.0693 19.6449 22.0693H2.90956C1.59464 22.0693 0.518799 20.9935 0.518799 19.6786V2.94325C0.518799 1.62833 1.59464 0.55249 2.90956 0.55249ZM5.30034 12.5063H17.2541V10.1156H5.30034V12.5063Z"
                fill="#186DEB"
            />
        </svg>
    );
}
