import { SelectChangeEvent } from '@mui/material';
import { HeatmapLayer } from '@react-google-maps/api';
import * as turf from '@turf/turf';
import { addDays, format, subDays } from 'date-fns';
import { FocusEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StackedLineChart } from 'src/common/components/graphics/stacked-line-chart';
import { filterByAlarmsName } from 'src/modules/alarm-processing/services/filter-by-alarms-name';
import { AnalyticsFilter } from 'src/modules/analytics/components/analytics-filter';
import { EventsFilter, EventsProps } from 'src/modules/analytics/components/events-filter';
import { GoogleMapContainer } from 'src/modules/analytics/components/google-map';
import { overviewServiceLocations } from 'src/modules/analytics/services/overview-locations.service';

import {
    Button,
    CardHeader,
    ChevronDown,
    DateHelper,
    DatePicker,
    Divider,
    Input,
    ObjectHelper,
    RenderIf,
    usePermission,
    useSearch,
} from '../../../common';
import { Column, Content, FilterAlt, MainCard, Row, Skeleton, Title } from '../../../common/components';
import { BarChartDataStackProps, BarChartStack } from '../../../common/components/graphics/bar-chart-stack';
import { FilterGraph } from '../../../common/components/graphics/filter-graphs';
import { PieChart, PieChartDataProps } from '../../../common/components/graphics/pie-chart';
import Radio from '../../../common/components/inputs/radio';
import RadioButton from '../../../common/components/radio-button';
import UpdateData from '../../../common/components/update-data';
import UsedFilters from '../../../common/components/used-filters';
import { EventHelper } from '../../../common/helpers/events.helper';
import { OverviewLocationsRequestDTO, OverviewRequestDTO } from '../../../modules/analytics/dtos/overview';
import { IOverviewAverageFilter, IOverviewTotalFilter } from '../../../modules/analytics/overview/dtos/filters-analytics-overview.dto';
import { useAnalyticsOverview } from '../../../modules/analytics/overview/hooks';
import { overviewService } from '../../../modules/analytics/services/overview.service';
import { ChartContainer, ChartContent, ChartContentPie, ChartSubTitle, RadioText, TitleContainer } from './styles';

export function Overview() {
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const { hasTelemetry } = usePermission();
    const { listEventsByFleet, handleGetListHeatMap } = useAnalyticsOverview();

    const [startDate, setStartDate] = useState<Date | null>(DateHelper.yesterday);
    const [endDate, setEndDate] = useState<Date | null>(new Date());

    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const [selectedType, setSelectedType] = useState<'DS' | 'FW' | 'Telemetria'>('DS');

    const [overviewData, setOverviewData] = useState<OverviewRequestDTO[]>([]);
    const [overviewDataLocation, setOverviewDataLocation] = useState<OverviewLocationsRequestDTO[]>([]);

    const [events, setEvents] = useState<EventsProps[]>([]);

    const [selectedAverageGraph, setSelectedAverageGraph] = useState<string>('Barra');
    const [selectedAveragePeriod, setSelectedAveragePeriod] = useState<string>('Dia');

    const [filterAverageIsOpen, setFilterAverageIsOpen] = useState(false);

    const [selectedTotalGraphFilter, setSelectedTotalGraphFilter] = useState<string>('Barra');
    const [selectedTotalPeriodFilter, setSelectedTotalPeriodFilter] = useState<string>('Dia');

    const [selectedAvarageGraphFilter, setSelectedAvarageGraphFilter] = useState<string>('Barra');
    const [selectedAvaragePeriodFilter, setSelectedAvaragePeriodFilter] = useState<string>('Dia');

    const [selectedTotalGraph, setSelectedTotalGraph] = useState<string>('Barra');
    const [selectedTotalPeriod, setSelectedTotalPeriod] = useState<string>('Dia');

    const [filterTotalIsOpen, setFilterTotalIsOpen] = useState(false);

    const [radius, setRadius] = useState(20);
    const [filterHeatMapIsOpen, setFilterHeatMapIsOpen] = useState(false);

    const { clear } = useSearch();

    const filters = useMemo(() => {
        if (filterIsOpen) {
            return (
                <AnalyticsFilter
                    setOverviewData={setOverviewData}
                    setOverviewDataLocation={setOverviewDataLocation}
                    selectedType={selectedType}
                    setIsLoading={setIsLoading}
                />
            );
        }

        return null;
    }, [filterIsOpen, selectedType]);

    const maxDate = useMemo(() => (startDate ? addDays(startDate, 30) : new Date()), [startDate]);
    const minDate = useMemo(() => (endDate ? subDays(endDate, 30) : undefined), [endDate]);

    const usedFilters = useMemo(
        () => ({
            alarmLevel: '',
            alarmType: '',
            board: '',
            endTime: '',
            eventType: '',
            operation: '',
            startTime: '',
        }),
        [],
    );

    const handleChangeDates = useCallback((dates: [Date, Date]) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
    }, []);

    const handleChangeDatesRaw = useCallback((event: FocusEvent<HTMLInputElement, Element>) => {
        event.preventDefault();
    }, []);

    const handleClearFilterValue = useCallback(() => {
        setFilterIsOpen(false);
    }, []);

    const handleClearFilters = useCallback(() => {
        setFilterIsOpen(false);
    }, []);

    const handleOpenFilters = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const usedFiltersComponent = useMemo(
        () =>
            !ObjectHelper.isEmpty(usedFilters as any) && (
                <UsedFilters data={usedFilters} onClear={handleClearFilterValue} onClearAll={handleClearFilters} />
            ),
        [handleClearFilterValue, handleClearFilters, usedFilters],
    );

    const handleOpenHeatMapFilters = useCallback(() => {
        setFilterHeatMapIsOpen(state => !state);
    }, []);

    const handleOpenAverageFilters = useCallback(() => {
        setFilterAverageIsOpen(state => !state);
    }, []);

    const handleOpenTotalFilters = useCallback(() => {
        setFilterTotalIsOpen(state => !state);
    }, []);

    useEffect(() => {
        setFilterIsOpen(false);

        return () => clear();
    }, [clear]);

    const renderChartProportion = (): PieChartDataProps[] => {
        const content: PieChartDataProps[] = [];

        overviewData.forEach(overview => {
            const index = content.findIndex(data => data.name === overview.event);

            if (index !== -1) {
                content[index].value += overview.total;
            } else {
                content.push({
                    name: overview.event,
                    value: Number(overview.total),
                    color: EventHelper.getColorByEventName(overview.event),
                });
            }
        });

        return content;
    };

    const renderChartLegendAverageEvents = useCallback(() => {
        const listData: string[] = [];

        if (!overviewData) {
            return listData;
        }
        if (selectedAveragePeriod === 'Dia') {
            overviewData?.map(overview => {
                const index = listData.findIndex(data => data === overview.date);

                if (index === -1) {
                    listData.push(overview.date);
                }
            });
        } else if (selectedAveragePeriod === 'Semana') {
            const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            const groupedDates: Record<string, string[]> = {};
            parsedDates.forEach(date => {
                const weekNumber = DateHelper.getWeek(date);
                const weekKey = `Semana ${weekNumber}`;
                if (!groupedDates[weekKey]) {
                    groupedDates[weekKey] = [];
                }

                groupedDates[weekKey].push(format(date, 'yyyy-MM-dd'));
            });

            Object.entries(groupedDates).map(date => {
                const { startWeekDate, endWeekDate } = DateHelper.getStartAndEndDateOfWeek(date[1][0]);
                listData.push(`${startWeekDate} - ${endWeekDate}`);
            });
        } else if (selectedAveragePeriod === 'Mês') {
            const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByMonth).map(date => {
                listData.push(date[0]);
            });
        } else if (selectedAveragePeriod === 'Ano') {
            const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByYear).map(date => {
                listData.push(date[0]);
            });
        }

        return listData;
    }, [overviewData, selectedAveragePeriod]);

    const renderChartLegendTotalEvents = useCallback(() => {
        const listData: string[] = [];

        if (!overviewData) {
            return listData;
        }
        if (selectedTotalPeriod === 'Dia') {
            overviewData?.map(overview => {
                const index = listData.findIndex(data => data === overview.date);

                if (index === -1) {
                    listData.push(overview.date);
                }
            });
        } else if (selectedTotalPeriod === 'Semana') {
            const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            const groupedDates: Record<string, string[]> = {};
            parsedDates.forEach(date => {
                const weekNumber = DateHelper.getWeek(date);
                const weekKey = `Semana ${weekNumber}`;
                if (!groupedDates[weekKey]) {
                    groupedDates[weekKey] = [];
                }

                groupedDates[weekKey].push(format(date, 'yyyy-MM-dd'));
            });

            Object.entries(groupedDates).map(date => {
                const { startWeekDate, endWeekDate } = DateHelper.getStartAndEndDateOfWeek(date[1][0]);

                listData.push(`${startWeekDate} - ${endWeekDate}`);
            });
        } else if (selectedTotalPeriod === 'Mês') {
            const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByMonth).map(date => {
                listData.push(date[0]);
            });
        } else if (selectedTotalPeriod === 'Ano') {
            const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByYear).map(date => {
                listData.push(date[0]);
            });
        }

        return listData;
    }, [overviewData, selectedTotalPeriod]);

    const dataHeatMapLayer = useMemo(() => {
        if (!listEventsByFleet || listEventsByFleet.length === 0) {
            return [];
        }

        return listEventsByFleet?.map(event => new google.maps.LatLng(event.latitude, event.longitude));
    }, [listEventsByFleet]);

    const centerHeatMap = useMemo(() => {
        if (dataHeatMapLayer.length === 0) {
            return { lat: -22.7253017, lng: -47.6532601 };
        }

        const point1 = turf.point([dataHeatMapLayer[0].lat(), dataHeatMapLayer[0].lng()]);
        const point2 = turf.point([dataHeatMapLayer[dataHeatMapLayer.length - 1].lat(), dataHeatMapLayer[dataHeatMapLayer.length - 1].lng()]);

        const midpoint = turf.midpoint(point1, point2);

        return { lat: midpoint.geometry.coordinates[0], lng: midpoint.geometry.coordinates[1] };
    }, [dataHeatMapLayer]);

    const renderChartTotalEvents = useCallback(() => {
        const listData: BarChartDataStackProps[] = [];
        if (!overviewData) {
            return listData;
        }
        const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];
        const uniqueEvents = [...new Set(overviewData.map(obj => obj.event))];

        const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

        const groupedDates: Record<string, string[]> = {};
        parsedDates.forEach(date => {
            const weekNumber = DateHelper.getWeek(date);
            const weekKey = `Week ${weekNumber}`;
            if (!groupedDates[weekKey]) {
                groupedDates[weekKey] = [];
            }

            groupedDates[weekKey].push(format(date, 'yyyy-MM-dd'));
        });

        uniqueEvents.map((event, eventIndex) => {
            listData.push({
                data: [],
                label: event,
                color: EventHelper.getColorByEventName(event) as string,
            });
            if (selectedTotalPeriod === 'Dia') {
                uniqueDates.map(() => {
                    listData[eventIndex].data.push(0);
                });
            } else {
                Object.entries(groupedDates).map(() => {
                    listData[eventIndex].data.push(0);
                });
            }
        });

        if (selectedTotalPeriod === 'Dia') {
            uniqueDates.map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    if (indexFilterData !== -1 && date === overview.date) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Semana') {
            Object.entries(groupedDates).map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Mês') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByMonth).map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Ano') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByYear).forEach((year, datesInYear) => {
                overviewData.forEach(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = year[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[datesInYear] += overview.total;
                    }
                });
            });
        }

        return listData;
    }, [overviewData, selectedTotalPeriod]);

    const renderChartTotalEventsLineChart = useCallback(() => {
        const listData: StackedLineChart[] = [];
        if (!overviewData) {
            return listData;
        }
        const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];
        const uniqueEvents = [...new Set(overviewData.map(obj => obj.event))];

        const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

        const groupedDates: Record<string, string[]> = {};
        parsedDates.forEach(date => {
            const weekNumber = DateHelper.getWeek(date);
            const weekKey = `Week ${weekNumber}`;
            if (!groupedDates[weekKey]) {
                groupedDates[weekKey] = [];
            }

            groupedDates[weekKey].push(format(date, 'yyyy-MM-dd'));
        });

        uniqueEvents.map((event, eventIndex) => {
            listData.push({
                data: [],
                label: event,
                color: EventHelper.getColorByEventName(event) as string,
            });
            if (selectedTotalPeriod === 'Dia') {
                uniqueDates.map(() => {
                    listData[eventIndex].data.push(0);
                });
            } else {
                Object.entries(groupedDates).map(() => {
                    listData[eventIndex].data.push(0);
                });
            }
        });

        if (selectedTotalPeriod === 'Dia') {
            uniqueDates.map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    if (indexFilterData !== -1 && date === overview.date) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Semana') {
            Object.entries(groupedDates).map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Mês') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByMonth).map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Ano') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByYear).forEach((year, datesInYear) => {
                overviewData.forEach(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = year[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[datesInYear] += overview.total;
                    }
                });
            });
        }

        return listData;
    }, [overviewData, selectedTotalPeriod]);

    const renderChartAverageEvents = useCallback(() => {
        const listData: BarChartDataStackProps[] = [];
        if (!overviewData) {
            return listData;
        }
        const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];
        const uniqueEvents = [...new Set(overviewData.map(obj => obj.event))];

        const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

        const groupedDates: Record<string, string[]> = {};
        parsedDates.forEach(date => {
            const weekNumber = DateHelper.getWeek(date);
            const weekKey = `Week ${weekNumber}`;
            if (!groupedDates[weekKey]) {
                groupedDates[weekKey] = [];
            }

            groupedDates[weekKey].push(format(date, 'yyyy-MM-dd'));
        });

        uniqueEvents.map((event, eventIndex) => {
            listData.push({
                data: [],
                label: event,
                color: EventHelper.getColorByEventName(event) as string,
            });
            if (selectedAveragePeriod === 'Dia') {
                uniqueDates.map(() => {
                    listData[eventIndex].data.push(0);
                    handleGetListHeatMap;
                });
            } else {
                Object.entries(groupedDates).map(() => {
                    listData[eventIndex].data.push(0);
                });
            }
        });

        if (selectedAveragePeriod === 'Dia') {
            uniqueDates.map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    if (indexFilterData !== -1 && date === overview.date) {
                        const average = Number(overview.average?.toFixed(2));
                        listData[indexFilterData].data[indexDate] += average;
                    }
                });
            });
        } else if (selectedAveragePeriod === 'Semana') {
            Object.entries(groupedDates).map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        const average = Number(overview.average?.toFixed(2));
                        listData[indexFilterData].data[indexDate] += average;
                    }
                });
            });
        } else if (selectedAveragePeriod === 'Mês') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByMonth).map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        const average = Number(overview.average?.toFixed(2));
                        listData[indexFilterData].data[indexDate] += average;
                    }
                });
            });
        } else if (selectedAveragePeriod === 'Ano') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByYear).forEach((year, datesInYear) => {
                overviewData.forEach(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = year[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        const average = Number(overview.average?.toFixed(2));
                        listData[indexFilterData].data[datesInYear] += average;
                    }
                });
            });
        }

        return listData;
    }, [handleGetListHeatMap, overviewData, selectedAveragePeriod]);

    const renderChartAvarageEventsLineChart = useCallback(() => {
        const listData: StackedLineChart[] = [];
        if (!overviewData) {
            return listData;
        }
        const uniqueDates = [...new Set(overviewData.map(obj => obj.date))];
        const uniqueEvents = [...new Set(overviewData.map(obj => obj.event))];

        const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

        const groupedDates: Record<string, string[]> = {};
        parsedDates.forEach(date => {
            const weekNumber = DateHelper.getWeek(date);
            const weekKey = `Week ${weekNumber}`;
            if (!groupedDates[weekKey]) {
                groupedDates[weekKey] = [];
            }

            groupedDates[weekKey].push(format(date, 'yyyy-MM-dd'));
        });

        uniqueEvents.map((event, eventIndex) => {
            listData.push({
                data: [],
                label: event,
                color: EventHelper.getColorByEventName(event) as string,
            });
            if (selectedAveragePeriod === 'Dia') {
                uniqueDates.map(() => {
                    listData[eventIndex].data.push(0);
                });
            } else {
                Object.entries(groupedDates).map(() => {
                    listData[eventIndex].data.push(0);
                });
            }
        });

        if (selectedAveragePeriod === 'Dia') {
            uniqueDates.map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    if (indexFilterData !== -1 && date === overview.date) {
                        const average = Number(overview.average?.toFixed(2));
                        listData[indexFilterData].data[indexDate] += average;
                    }
                });
            });
        } else if (selectedAveragePeriod === 'Semana') {
            Object.entries(groupedDates).map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        const average = Number(overview.average?.toFixed(2));
                        listData[indexFilterData].data[indexDate] += average;
                    }
                });
            });
        } else if (selectedAveragePeriod === 'Mês') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByMonth).map((date, indexDate) => {
                overviewData.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        const average = Number(overview.average?.toFixed(2));
                        listData[indexFilterData].data[indexDate] += average;
                    }
                });
            });
        } else if (selectedAveragePeriod === 'Ano') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'yyyy-MM-dd'));
            });

            Object.entries(datesByYear).forEach((year, datesInYear) => {
                overviewData.forEach(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = year[1].findIndex(dateFilter => dateFilter === overview.date);

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        const average = Number(overview.average?.toFixed(2));
                        listData[indexFilterData].data[datesInYear] += average;
                    }
                });
            });
        }
        return listData;
    }, [overviewData, selectedAveragePeriod]);

    const handleUpdateEvents = useCallback(async () => {
        const eventsFiltered: EventsProps[] = [];
        const response = await filterByAlarmsName.execute();

        response.map((event: any) => {
            if (event.equipament_type === selectedType) {
                const eventFilter: EventsProps = {
                    checked: false,
                    name: event.pt_br,
                    translate: t(`tableEventName.${event.pt_br}`),
                };
                eventsFiltered.push(eventFilter);
            }
        });

        const uniqueNamesSet = new Set();
        const result: EventsProps[] = [];

        eventsFiltered.forEach(event => {
            if (!uniqueNamesSet.has(event.name)) {
                uniqueNamesSet.add(event.name);
                result.push(event);
            }
        });

        setEvents(result);
    }, [selectedType, t]);

    const handleUpdateLocations = useCallback(
        async (eventsProps?: EventsProps[]) => {
            try {
                setIsLoading(true);
                if (startDate && endDate) {
                    const responseLocations = await overviewServiceLocations.execute({
                        dateFrom: startDate,
                        dateTo: endDate,
                        equipament: selectedType,
                        events: eventsProps?.filter(event => event.checked).map(event => event.name),
                    });

                    setOverviewDataLocation(responseLocations);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [endDate, selectedType, startDate],
    );

    const handleUpdate = useCallback(async () => {
        try {
            if (startDate && endDate) {
                setIsLoading(true);

                handleUpdateEvents();

                const response = await overviewService.execute({
                    dateFrom: startDate,
                    dateTo: endDate,
                    equipament: selectedType === 'Telemetria' ? 'Geotab' : selectedType,
                });

                setOverviewData(response);

                if (selectedType !== 'Telemetria') {
                    handleUpdateLocations();
                }
            }
        } finally {
            setIsLoading(false);
        }
    }, [startDate, endDate, selectedType, handleUpdateEvents, handleUpdateLocations]);

    const handleOnChangeEventsFilter = useCallback(
        (eventChange: SelectChangeEvent<string[]>) => {
            const selectedValues = eventChange.target.value as string[];
            const oldValues = [...events];

            const updatedOptions: EventsProps[] = oldValues.map(event => ({
                ...event,
                checked: selectedValues.includes(event.name),
            }));

            setEvents(updatedOptions);
        },
        [events],
    );

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    const renderedGoogleMapHeatLayer = useMemo(() => {
        if (!overviewDataLocation || overviewDataLocation.length === 0) {
            return [];
        }

        const listLocation = overviewDataLocation.map(location => new google.maps.LatLng(location.latitude, location.longitude));

        return listLocation;
    }, [overviewDataLocation]);

    const content: JSX.Element = useMemo(() => {
        if (isLoading) {
            return (
                <Content className="mb-16" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Skeleton width={'30%'} height={'2rem'} />
                    <Skeleton width={'70%'} height={'2rem'} />
                    <Skeleton height={'20rem'} style={{ marginTop: '1rem' }} />
                </Content>
            );
        }
        return <></>;
    }, [isLoading]);

    const contentProportionWithoutHeatmap: JSX.Element = useMemo(() => {
        if (isLoading) {
            return (
                <Content className="mb-16" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Skeleton width={'30%'} height={'2rem'} />
                    <Skeleton width={'40%'} height={'2rem'} />
                    <Skeleton height={'20rem'} style={{ marginTop: '1rem' }} />
                </Content>
            );
        }
        return <></>;
    }, [isLoading]);

    const contentHeatMap = useMemo(() => {
        if (isLoading) {
            return (
                <Content className="mb-16" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Skeleton width={'30%'} height={'2rem'} />
                    <Skeleton width={'70%'} height={'2rem'} />
                    <Skeleton height={'20rem'} style={{ marginTop: '1rem' }} />
                </Content>
            );
        }

        return (
            <>
                <div className="filterDiv">
                    <TitleContainer>
                        <Title>
                            <h2>{t('overview.heat_map_events')}</h2>
                        </Title>
                        <ChartSubTitle>{t('overview.heat_map_events_chart_subtile')}</ChartSubTitle>
                    </TitleContainer>
                    <div>
                        <button className="filterButton" onClick={handleOpenHeatMapFilters}>
                            <FilterAlt color={'#46526C'} />
                        </button>
                        <div className="filters">
                            {filterHeatMapIsOpen && (
                                <FilterGraph handleCancelButton={handleOpenHeatMapFilters} handleApplyButton={() => handleUpdateLocations(events)}>
                                    <div className="radioInput">
                                        <RadioText>{t('general.events')}</RadioText>
                                        <div>
                                            <EventsFilter events={events} handleChange={handleOnChangeEventsFilter} />
                                        </div>
                                    </div>
                                    <div className="heatMapInputs">
                                        <div>
                                            <Input
                                                name="raio"
                                                label={String(t('Raio'))}
                                                style={{ width: '100%' }}
                                                type="number"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRadius(Number(e.target.value))}
                                                value={radius}
                                                defaultValue={20}
                                            />
                                        </div>
                                        <div>
                                            <Input name="blur" label={String(t('Blur'))} style={{ width: '100%' }} type="number" defaultValue={7} />
                                        </div>
                                    </div>
                                </FilterGraph>
                            )}
                        </div>
                    </div>
                </div>
                <ChartContent>
                    <GoogleMapContainer center={centerHeatMap} zoom={2}>
                        {renderedGoogleMapHeatLayer && (
                            <HeatmapLayer
                                options={{
                                    radius: 20,
                                }}
                                data={renderedGoogleMapHeatLayer}
                            />
                        )}
                    </GoogleMapContainer>
                </ChartContent>
            </>
        );
    }, [
        centerHeatMap,
        events,
        filterHeatMapIsOpen,
        handleOnChangeEventsFilter,
        handleOpenHeatMapFilters,
        handleUpdateLocations,
        isLoading,
        radius,
        renderedGoogleMapHeatLayer,
        t,
    ]);

    const graphAverageSelected: JSX.Element = useMemo(() => {
        if (selectedAverageGraph === 'Barra') {
            return <BarChartStack legends={renderChartLegendAverageEvents()} data={renderChartAverageEvents()} />;
        }
        if (selectedAverageGraph === 'Linha') {
            return <StackedLineChart legends={renderChartLegendAverageEvents()} data={renderChartAvarageEventsLineChart()} />;
        }
        return <></>;
    }, [renderChartAverageEvents, renderChartAvarageEventsLineChart, renderChartLegendAverageEvents, selectedAverageGraph]);

    const graphTotalSelected: JSX.Element = useMemo(() => {
        if (selectedTotalGraph === 'Barra') {
            return <BarChartStack legends={renderChartLegendTotalEvents()} data={renderChartTotalEvents()} />;
        }
        if (selectedTotalGraph === 'Linha') {
            return <StackedLineChart legends={renderChartLegendTotalEvents()} data={renderChartTotalEventsLineChart()} />;
        }
        return <></>;
    }, [renderChartLegendTotalEvents, renderChartTotalEvents, renderChartTotalEventsLineChart, selectedTotalGraph]);

    const { register: registerTotalFilter, handleSubmit: handleTotalFilterSubmit } = useForm<IOverviewTotalFilter>({
        defaultValues: {
            typeOfGraph: 'barra',
            periodOfGraph: 'dia',
        },
    });

    const { register: registerAvarageFilter, handleSubmit: handleAvarageFilterSubmit } = useForm<IOverviewAverageFilter>({
        defaultValues: {
            typeOfGraph: 'barra',
            periodOfGraph: 'dia',
        },
    });

    const handleApplyAvarageFilter = useCallback(() => {
        setSelectedAverageGraph(selectedAvarageGraphFilter);
        setSelectedAveragePeriod(selectedAvaragePeriodFilter);
    }, [selectedAvarageGraphFilter, selectedAvaragePeriodFilter]);

    const handleApplyTotalFilter = useCallback(() => {
        setSelectedTotalGraph(selectedTotalGraphFilter);
        setSelectedTotalPeriod(selectedTotalPeriodFilter);
    }, [selectedTotalGraphFilter, selectedTotalPeriodFilter]);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between">
                <Title>
                    <h2>Analytics</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
            </Row>

            <MainCard style={{ paddingBottom: '1rem' }}>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>{t('general.overview')}</h3>
                        </Row>

                        <Row align="flex-start" gap={16}>
                            <Row align="center" gap={8}>
                                <Row
                                    gap={8}
                                    style={{ marginRight: '1rem' }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setSelectedType(event.target.value as 'DS' | 'FW' | 'Telemetria')
                                    }
                                >
                                    <label style={{ color: '#333333', fontWeight: '500' }}>{t('general.view')}</label>
                                    <Radio label="DS" checked={selectedType === 'DS'} name="DS" value="DS" />
                                    <Radio label="FW" checked={selectedType === 'FW'} name="FW" value="FW" />

                                    <RenderIf condition={hasTelemetry('Geotab')}>
                                        <Radio
                                            label="Telemetria"
                                            checked={selectedType === 'Telemetria'}
                                            name={String(t('overview.telemetria'))}
                                            value="Telemetria"
                                        />
                                    </RenderIf>
                                </Row>

                                <DatePicker
                                    endDate={endDate}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    multiple
                                    name="period"
                                    onChange={handleChangeDates}
                                    onChangeRaw={handleChangeDatesRaw}
                                    placeholder={String(t('general.select_period'))}
                                    startDate={startDate}
                                />
                                <Button
                                    color={filterIsOpen ? 'purple' : 'outline-purple'}
                                    leftIcon={<FilterAlt color={filterIsOpen ? '#fff' : '#46526C'} />}
                                    size="small"
                                    rightIcon={<ChevronDown spin={filterIsOpen} color={filterIsOpen ? '#fff' : '#46526C'} />}
                                    type="button"
                                    onClick={handleOpenFilters}
                                >
                                    {t('general.filter')}
                                </Button>
                            </Row>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {filters}
                    {usedFiltersComponent}
                </Column>

                <Row padding="0.8rem" style={{ justifyContent: 'space-between' }}>
                    <ChartContainer style={{ width: selectedType === 'Telemetria' ? '100%' : '50%' }}>
                        {!isLoading ? (
                            <Fragment>
                                <div className="filterDiv">
                                    <TitleContainer>
                                        <Title>
                                            <h2>{t('overview.proportion_events')}</h2>
                                        </Title>
                                        <ChartSubTitle>{t('overview.proportion_events_chart_subtitle')}</ChartSubTitle>
                                    </TitleContainer>
                                </div>
                                <ChartContentPie>
                                    <PieChart data={renderChartProportion()} />
                                </ChartContentPie>
                            </Fragment>
                        ) : (
                            <Fragment>{selectedType !== 'Telemetria' ? content : contentProportionWithoutHeatmap}</Fragment>
                        )}
                    </ChartContainer>

                    {selectedType !== 'Telemetria' && <ChartContainer>{contentHeatMap}</ChartContainer>}
                </Row>

                <Row padding="0.8rem" style={{ justifyContent: 'space-between' }}>
                    {!isLoading ? (
                        <ChartContainer>
                            <div className="filterDiv">
                                <TitleContainer>
                                    <Title>
                                        <h2>{t('overview.average_events')}</h2>
                                    </Title>
                                    <ChartSubTitle>{t('overview.average_events_chart_subtile')}</ChartSubTitle>
                                </TitleContainer>
                                <div>
                                    <button className="filterButton">
                                        <FilterAlt color={'#46526C'} onClick={handleOpenAverageFilters} />
                                    </button>
                                    <div className="filters">
                                        {filterAverageIsOpen && (
                                            <FilterGraph
                                                handleCancelButton={handleOpenAverageFilters}
                                                handleApplyButton={handleAvarageFilterSubmit(handleApplyAvarageFilter)}
                                            >
                                                <div>
                                                    <RadioText>{t('period.graph_type')}</RadioText>
                                                    <div
                                                        className="radioInputGraphs"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                            setSelectedAvarageGraphFilter(event.target.value)
                                                        }
                                                    >
                                                        <RadioButton
                                                            {...registerAvarageFilter('typeOfGraph')}
                                                            group="barStackType"
                                                            checked={selectedAvarageGraphFilter === 'Barra'}
                                                            name={t('period.bar')}
                                                            value="Barra"
                                                        />
                                                        <RadioButton
                                                            {...registerAvarageFilter('typeOfGraph')}
                                                            group="barStackType"
                                                            checked={selectedAvarageGraphFilter === 'Linha'}
                                                            name={t('period.line')}
                                                            value="Linha"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <RadioText>{t('general.period')}</RadioText>
                                                    <div
                                                        className="radioInputGraphs"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                            setSelectedAvaragePeriodFilter(event.target.value)
                                                        }
                                                    >
                                                        <RadioButton
                                                            group="barStackPeriod"
                                                            checked={selectedAvaragePeriodFilter === 'Dia'}
                                                            name={t('period.day')}
                                                            value="Dia"
                                                        />
                                                        <RadioButton
                                                            group="barStackPeriod"
                                                            checked={selectedAvaragePeriodFilter === 'Semana'}
                                                            name={t('period.week')}
                                                            value="Semana"
                                                        />
                                                        <RadioButton
                                                            group="barStackPeriod"
                                                            checked={selectedAvaragePeriodFilter === 'Mês'}
                                                            name={t('period.month')}
                                                            value="Mês"
                                                        />
                                                        <RadioButton
                                                            group="barStackPeriod"
                                                            checked={selectedAvaragePeriodFilter === 'Ano'}
                                                            name={t('period.year')}
                                                            value="Ano"
                                                        />
                                                    </div>
                                                </div>
                                            </FilterGraph>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <ChartContent>{graphAverageSelected}</ChartContent>
                        </ChartContainer>
                    ) : (
                        <ChartContainer>{content}</ChartContainer>
                    )}

                    {!isLoading ? (
                        <ChartContainer>
                            <div className="filterDiv">
                                <TitleContainer>
                                    <Title>
                                        <h2>{t('overview.total_events')}</h2>
                                    </Title>
                                    <ChartSubTitle>{t('overview.total_events_chart_subtitle')}</ChartSubTitle>
                                </TitleContainer>
                                <div>
                                    <button className="filterButton" onClick={handleOpenTotalFilters}>
                                        <FilterAlt color={'#46526C'} />
                                    </button>
                                    <div className="filters">
                                        {filterTotalIsOpen && (
                                            <FilterGraph
                                                handleCancelButton={handleOpenTotalFilters}
                                                handleApplyButton={handleTotalFilterSubmit(handleApplyTotalFilter)}
                                            >
                                                <div>
                                                    <RadioText>{t('period.graph_type')}</RadioText>
                                                    <div
                                                        className="radioInputGraphs"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                            setSelectedTotalGraphFilter(event.target.value)
                                                        }
                                                    >
                                                        <RadioButton
                                                            {...registerTotalFilter('typeOfGraph')}
                                                            group="barStackType"
                                                            checked={selectedTotalGraphFilter === 'Barra'}
                                                            name={t('period.bar')}
                                                            value="Barra"
                                                        />
                                                        <RadioButton
                                                            {...registerTotalFilter('typeOfGraph')}
                                                            group="barStackType"
                                                            checked={selectedTotalGraphFilter === 'Linha'}
                                                            name={t('period.line')}
                                                            value="Linha"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <RadioText>{t('general.period')}</RadioText>
                                                    <div
                                                        className="radioInputGraphs"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                            setSelectedTotalPeriodFilter(event.target.value)
                                                        }
                                                    >
                                                        <RadioButton
                                                            group="barStackPeriod"
                                                            checked={selectedTotalPeriodFilter === 'Dia'}
                                                            name={t('period.day')}
                                                            value="Dia"
                                                        />
                                                        <RadioButton
                                                            group="barStackPeriod"
                                                            checked={selectedTotalPeriodFilter === 'Semana'}
                                                            name={t('period.week')}
                                                            value="Semana"
                                                        />
                                                        <RadioButton
                                                            group="barStackPeriod"
                                                            checked={selectedTotalPeriodFilter === 'Mês'}
                                                            name={t('period.month')}
                                                            value="Mês"
                                                        />
                                                        <RadioButton
                                                            group="barStackPeriod"
                                                            checked={selectedTotalPeriodFilter === 'Ano'}
                                                            name={t('period.year')}
                                                            value="Ano"
                                                        />
                                                    </div>
                                                </div>
                                            </FilterGraph>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <ChartContent>{graphTotalSelected}</ChartContent>
                        </ChartContainer>
                    ) : (
                        <ChartContainer>{content}</ChartContainer>
                    )}
                </Row>
            </MainCard>
        </Column>
    );
}
