export enum Layout {
    CARD = 'CARD',
    LINE = 'LINE',
}

export interface ListMediaSize {
    heigth: number;
    width: number;
}

export interface ListLayoutContextProps {
    layout: Layout;
    handleChangeLayout(layout: Layout): void;
    media: ListMediaSize;
    handleMediaVisualization(media: ListMediaSize): void;
}
