import styled from 'styled-components';

import { ChartLegendProps } from './chart-legend-props';

export const LegendIcon = styled.button<Partial<ChartLegendProps>>`
    border: 0;
    margin: 0;
    border-radius: 5px;
    height: 14px;
    width: 24.5px;
    background-color: ${props => props.color};
`;

export const Value = styled.span`
    font-weight: 700;
    font-size: 18px;
    line-height: 90%;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    color: #262d3c;
    cursor: default;
`;
