import { sub } from 'date-fns';
import { graphic } from 'echarts';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBell, BsTruck } from 'react-icons/bs';
import { MdOutlineBackHand } from 'react-icons/md';
import { ImSleepy } from 'src/common/components/icons/im-sleepy';

import { Row, Title, TypeSearch, useSearch, Divider, Skeleton } from '../../common';
import Column from '../../common/components/column';
import { DoughnutChart, DoughnutChartDataProps } from '../../common/components/graphics/doughnut-chart';
import { LineChart } from '../../common/components/graphics/line-chart';
import UpdateData from '../../common/components/update-data';
import { Pagination } from '../../common/core/pagination';
import { vehicleService } from '../../modules/configurations/vehicles/services/vehicle.service';
import { Card } from '../../modules/home/components/Card';
import { SkeletonCard } from '../../modules/home/components/CardSkeleton';
import { InterventionsMap } from '../../modules/home/components/InterventionsMap';
import { getSummary } from '../../modules/home/services/get-summary.service';
import { InterventionRequestDTO } from '../../modules/interventions/dtos/intervention.dto';
import { interventionService } from '../../modules/interventions/services/interventions.service';
import { Vehicle } from '../../modules/vehicle-monitoring/domain/entities/vehicle';
import {
    CardRow,
    ChartContainer,
    ChartContainerInfo,
    ChartSubTitle,
    ChoiceButton,
    EventDistributionDetailsContainer,
    EventDistributionItem,
    SpanTotal,
} from './styles';
interface SummaryDistributionResponse {
    name: string;
    value: number;
    color: string;
}
interface SummaryResponse {
    somnolence: number;
    distribution: Array<SummaryDistributionResponse>;
}

export default function Home() {
    const { t } = useTranslation();
    const [filterDate, setFilterDate] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const [summary, setSummary] = useState<SummaryResponse>({} as SummaryResponse);
    const [listInterventions, setListInterventions] = useState<Pagination<InterventionRequestDTO>>();
    const [listVehicles, setListVehicles] = useState<Pagination<Vehicle>>();

    const { setIsVisible } = useSearch();

    useEffect(() => {
        setIsVisible(false);

        return () => {
            setIsVisible(true);
        };
    }, [setIsVisible]);

    const handleUpdate = useCallback(async () => {
        try {
            setIsLoading(true);

            const dateTo = new Date();
            const dateFrom = sub(dateTo, { days: filterDate });

            const [responseSummary, responseInterventions, responseVehicles] = await Promise.all([
                getSummary.execute({
                    dateFrom,
                    dateTo,
                }),

                interventionService.execute({
                    dateFrom,
                    dateTo,
                    currentPage: 1,
                    pageSize: 2000,
                    sortProps: [],
                    operation: undefined,
                    endHour: '23:59',
                    startHour: '00:00',
                    textSearch: undefined,
                }),

                vehicleService.list({
                    currentPage: 1,
                    pageSize: 50,
                    typeSearch: TypeSearch.PARAMS,
                }),
            ]);

            setSummary(responseSummary?.data?.content);
            setListInterventions(responseInterventions);
            setListVehicles(responseVehicles);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, [filterDate]);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    const renderChartDoughnut = useCallback(() => {
        const data: Array<DoughnutChartDataProps> = summary.distribution?.map(group => ({
            name: t(`tableEventName.${group.name}`),
            value: group.value,
            color: group.color,
        }));

        return data;
    }, [summary.distribution, t]);

    const calculatePercentages = useCallback(
        (value: number) => {
            const total = summary?.distribution?.reduce((sum, item) => sum + item.value, 0);

            return ((value / total) * 100).toFixed(2) + '%';
        },
        [summary?.distribution],
    );

    const cardsContent = useMemo(() => {
        if (isLoading) {
            return (
                <>
                    <SkeletonCard icon={<BsBell color="#fff" size={28} />} />
                    <SkeletonCard icon={<MdOutlineBackHand color="#fff" size={28} />} fontColor="#f03738" />
                    <SkeletonCard icon={<ImSleepy color="#fff" width="68" height="68" fill="#fff" />} fontColor=" #FAC858" />
                    <SkeletonCard icon={<BsTruck color="#fff" size={32} />} fontColor="#9A60B4" />
                </>
            );
        }
        return (
            <>
                <Card
                    title={t('home.events')}
                    value={summary?.distribution?.reduce((sum, item) => sum + item.value, 0) || 0}
                    icon={<BsBell color="#fff" size={28} />}
                />
                <Card
                    title={t('home.interventions')}
                    value={listInterventions?.totalElements || 0}
                    icon={<MdOutlineBackHand color="#fff" size={28} />}
                    fontColor="#f03738"
                />
                <Card
                    title={t('home.drowsiness')}
                    value={summary?.somnolence || 0}
                    icon={<ImSleepy color="#fff" width="68" height="68" fill="#fff" />}
                    fontColor=" #FAC858"
                />
                <Card
                    title={t('home.vehicles')}
                    value={listVehicles?.totalElements || 0}
                    icon={<BsTruck color="#fff" size={32} />}
                    fontColor="#9A60B4"
                />
            </>
        );
    }, [isLoading, listInterventions?.totalElements, listVehicles?.totalElements, summary?.distribution, summary?.somnolence, t]);

    const lastHoursInterventionsGraph = useMemo(() => {
        if (isLoading) {
            return (
                <>
                    <Skeleton height={'30rem'} width={'20rem'} />
                    <Skeleton height={'30rem'} width={'30rem'} style={{ marginTop: '-0.5rem' }} />
                    <Skeleton height={'450rem'} />
                </>
            );
        }

        return (
            <>
                <ChartContainerInfo>
                    <Title>
                        <h2>{t('home.interventions_last_hour')}</h2>
                    </Title>
                    <ChartSubTitle>{t('home.interventions_last_hour_subtitle')}</ChartSubTitle>
                </ChartContainerInfo>
                <div style={{ margin: '-1rem', height: '100%' }}>
                    <LineChart
                        data={listInterventions?.elements}
                        color={
                            new graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(109, 164, 211)',
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(255, 255, 255)',
                                },
                            ])
                        }
                    />
                </div>
            </>
        )

    }, [isLoading, listInterventions, t]);

    const eventsDistributionPieChart = useMemo(() => {
        if (isLoading) {
            return (
                <>
                    <Skeleton height={'30rem'} width={'20rem'} />
                    <Skeleton height={'30rem'} width={'30rem'} style={{ marginTop: '-0.5rem' }} />
                    <Skeleton height={'450rem'} />
                </>
            );
        }

        return (
            <>
                <Title>
                    <h2>{t('home.event_distribution')}</h2>
                </Title>
                <ChartSubTitle>{t('home.event_distribution_subtitle')}</ChartSubTitle>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <div style={{ width: '50%', height: '100%', padding: '1.5rem' }}>
                        <DoughnutChart data={renderChartDoughnut()} />
                    </div>

                    <EventDistributionDetailsContainer>
                        {summary?.distribution?.map(groupEvent => (
                            <div key={groupEvent.name}>
                                <EventDistributionItem>
                                    <div style={{ background: groupEvent.color, width: '1rem', height: '1rem', borderRadius: 2 }} />
                                    <p>{t(`tableEventName.${groupEvent.name}`)}</p>
                                    <span>{calculatePercentages(groupEvent.value)}</span>
                                </EventDistributionItem>
                                <Divider />
                            </div>
                        ))}
                    </EventDistributionDetailsContainer>
                </div>
            </>
        );
    }, [calculatePercentages, isLoading, renderChartDoughnut, summary?.distribution, t]);

    const recentInterventionsMap = useMemo(() => {
        if (isLoading) {
            return (
                <>
                    <Skeleton height={'30rem'} width={'20rem'} />
                    <Skeleton height={'30rem'} width={'88rem'} style={{ marginTop: '-0.5rem' }} />
                    <Skeleton height={'450rem'} />
                </>
            );
        }

        return (
            <>
                <Title>
                    <h2>
                        {t('home.recent_interventions')}
                        <SpanTotal>({listInterventions?.totalElements || 0})</SpanTotal>
                    </h2>
                </Title>
                <ChartSubTitle>{t('home.recent_interventions_subtitle')}</ChartSubTitle>

                <InterventionsMap data={listInterventions?.elements || []} />
            </>
        );
    }, [isLoading, listInterventions?.elements, listInterventions?.totalElements, t]);

    return (
        <Column gap={16}>
            <Row align="center" width="100%" justify="space-between" style={{ marginBottom: '2rem' }}>
                <Title>
                    <h2>{t('menu.home')}</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
                <div style={{ display: 'flex' }}>
                    <ChoiceButton className={filterDate === 1 ? 'active' : ''} onClick={() => setFilterDate(1)}>
                        1 dia
                    </ChoiceButton>
                    <ChoiceButton className={filterDate === 3 ? 'active' : ''} onClick={() => setFilterDate(3)}>
                        3 dias
                    </ChoiceButton>
                    <ChoiceButton className={filterDate === 7 ? 'active' : ''} onClick={() => setFilterDate(7)}>
                        7 dias
                    </ChoiceButton>
                </div>
            </Row>
            <CardRow>{cardsContent}</CardRow>

            <CardRow>
                <ChartContainer>{lastHoursInterventionsGraph}</ChartContainer>

                <ChartContainer>{eventsDistributionPieChart}</ChartContainer>
            </CardRow>

            <CardRow>
                <ChartContainer style={{ width: '100%', height: '42rem' }}>{recentInterventionsMap}</ChartContainer>
            </CardRow>
        </Column>
    );
}
