import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'src/common/components/row';

import { Button, Divider } from '../../../../../common';
import Column from '../../../../../common/components/column';
import { CancelButton, DialogContent } from './styles';

export function RegistrationShipmentsDeleteDialogForm() {
    const { t } = useTranslation();

    const [DeleteChange, setDeleteChange] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    return (
        <DialogContent>
            <Row justify="center" width="100%" align="start">
                <h4>{t('registration_shipments.delete_contact')}</h4>
            </Row>
            <Column align="center" gap={16} flex width="100%">
                <Column align="center">
                    <p>{t('registration_shipments.title_confirm_delete')}</p>
                </Column>
                <Divider />
            </Column>
            <Row align="flex-end" gap={16} flex width="100%">
                <CancelButton onClick={() => setDeleteChange(false)}>{t('registration_shipments.cancel')}</CancelButton>
                <Button isLoading={isLoading} type="reset" color="alert" onClick={() => setDeleteChange(false)}>
                    {t('registration_shipments.confirm')}
                </Button>
            </Row>
        </DialogContent>
    );
}
