import styled from 'styled-components';

export const Subtitle = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    color: #262d3c;
`;

export const FakeLoader = styled.div`
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #fff;

    flex-direction: column;
    gap: 8px;

    &.show {
        display: flex;
    }

    &.hidde {
        display: none;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1;
    width: 100%;
`;

export const Grid = styled.div`
    margin: 0.25rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 14px;
    width: 100%;

    > label {
        color: #333;
        font-size: 0.8rem;
        font-weight: 500;
    }
`;
