import { Circle, DrawingManager, GoogleMap, Polygon } from '@react-google-maps/api';
import { useCallback, useMemo, useRef } from 'react';
import { RenderIf, Skeleton } from 'src/common';
import { useMap } from 'src/common/hooks';
import { GeometricShape } from 'src/modules/vehicle-monitoring/domain/constants/geometric-shape';
import { useElectronicFence } from 'src/modules/vehicle-monitoring/hooks';

import { Container } from './styles';

import { OverlayProps } from '..';

interface MapProps {
    center?: google.maps.LatLngLiteral | google.maps.LatLng | undefined;
    className: 'big-map' | 'small-map';
    color?: string;
    opacity?: number;
    overlay: OverlayProps | null;
    readonly?: boolean;
    setOverlay: (overlay: OverlayProps | null) => void;
    zoom?: number | undefined;
}

export function Map({ color, overlay, setOverlay, opacity, className, readonly, center, zoom }: MapProps) {
    const { isLoaded } = useMap();
    const { map, overlayRef } = useElectronicFence();

    const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(null);

    const overlayOptions = useMemo(
        () => ({
            strokeColor: color,
            fillColor: color,
            fillOpacity: opacity,
        }),
        [color, opacity],
    );

    const options = useMemo<google.maps.drawing.DrawingManagerOptions>(
        () => ({
            drawingControl: !readonly,
            drawingControlOptions: {
                drawingModes: readonly ? [] : [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.POLYGON],
            },
        }),
        [readonly],
    );

    const handleLoadDrawingManager = (drawingManager: google.maps.drawing.DrawingManager) => {
        drawingManagerRef.current = drawingManager;
    };

    const handleLoadOverlay = (overlay: google.maps.Polygon | google.maps.Circle) => {
        overlayRef.current = overlay;
    };

    const handleLoadMap = useCallback(
        (data: google.maps.Map) => {
            map.current = data;

            if (overlay) {
                map?.current?.setZoom(zoom || 15);
                map?.current?.setCenter(overlay?.coordinates[0]);
            }
        },
        [map, overlay, zoom],
    );

    const handleOverlayComplete = (e: google.maps.drawing.OverlayCompleteEvent) => {
        drawingManagerRef?.current?.setDrawingMode(null);

        switch (e.type) {
            case google.maps.drawing.OverlayType.POLYGON:
                {
                    const newPolygon = (e.overlay as google.maps.Polygon)
                        .getPath()
                        .getArray()
                        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
                    const startPoint = newPolygon[0];
                    newPolygon.push(startPoint);
                    e.overlay?.setMap(null);

                    setOverlay({ type: GeometricShape.POLYGON, coordinates: newPolygon, radius: 0 });
                }

                break;
            case google.maps.drawing.OverlayType.CIRCLE:
                {
                    const circle = e.overlay as google.maps.Circle;
                    const circleRadius = circle.getRadius();
                    const circleCenter = circle.getCenter();

                    const coordinates: google.maps.LatLngLiteral[] = circleCenter ? [{ ...circleCenter.toJSON() }] : [];

                    e.overlay?.setMap(null);

                    setOverlay({ type: GeometricShape.CIRCLE, coordinates, radius: circleRadius });
                }
                break;
            default:
                break;
        }
    };

    return (
        <Container gap={8} align="center" justify="center">
            <RenderIf condition={isLoaded}>
                <GoogleMap center={center} clickableIcons id="google-map" mapContainerClassName={className} onLoad={handleLoadMap} zoom={zoom}>
                    <DrawingManager options={options} onLoad={handleLoadDrawingManager} onOverlayComplete={handleOverlayComplete} />
                    <RenderIf condition={overlay?.type === 'POLYGON'}>
                        <Polygon
                            draggable={!readonly}
                            editable={!readonly}
                            options={overlayOptions}
                            paths={overlay?.coordinates || undefined}
                            onLoad={handleLoadOverlay}
                        />
                    </RenderIf>
                    <RenderIf condition={overlay?.type === 'CIRCLE'}>
                        <Circle
                            draggable={!readonly}
                            center={overlay?.coordinates[0]}
                            editable={!readonly}
                            onLoad={handleLoadOverlay}
                            options={overlayOptions}
                            radius={overlay?.radius}
                        />
                    </RenderIf>
                </GoogleMap>
            </RenderIf>
            <RenderIf condition={!isLoaded}>
                <Skeleton height={248} />
            </RenderIf>
        </Container>
    );
}
