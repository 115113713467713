import { Fragment, ReactElement, memo } from 'react';
import { FunctionComponent } from 'src/common/types';

interface RenderIfProps extends FunctionComponent<ReactElement> {
    condition: boolean;
}

export default memo(function RenderIf({ condition, children }: RenderIfProps) {
    return condition ? children :   <Fragment />;
});
