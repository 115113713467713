import { AxiosResponse } from 'axios';
import { SignalDTO, api } from 'src/common';
import { ApiResponse, Service } from 'src/common/core';

import { CreateElectronicFenceDTO, CreateElectronicFenceFormDTO } from '../dtos/create-electronic-fence.dto';
import { ElectronicFenceMapper } from '../mappers/electronic-fence.mapper';

export class CreateElectronicFenceService implements Service<CreateElectronicFenceFormDTO & SignalDTO, void> {
    public async execute(request: CreateElectronicFenceFormDTO & SignalDTO): Promise<void> {
        const { signal } = request;

        const data = ElectronicFenceMapper.fromFormToDTO(request);

        await api.post<ApiResponse<void>, AxiosResponse<ApiResponse<void>>, CreateElectronicFenceDTO>('/vehicle-monitoring/electronic-fences', data, {
            signal,
        });
    }
}

export const createElectronicFence = new CreateElectronicFenceService();
