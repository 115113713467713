import { DialogHTMLAttributes, forwardRef, ForwardRefRenderFunction, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import { useAuth } from 'src/modules/auth';

import Button from '../button';
import Column from '../column';
import { ArgusLogo } from '../icons';
import Row from '../row';
import { CloseButton, Dialog, DialogContent } from './styles';

const LogoutDialogBase: ForwardRefRenderFunction<HTMLDialogElement, DialogHTMLAttributes<HTMLDialogElement>> = (props, ref) => {
    const { signOut } = useAuth();
    const { t } = useTranslation();

    const handleClose = useCallback((e: MouseEvent<HTMLDialogElement>) => {
        e.currentTarget.close();
    }, []);

    const handleCloseByChild = useCallback(() => {
        document.querySelector<HTMLDialogElement>(`dialog#${props?.id}`)?.close();
    }, [props?.id]);

    const handleClickOnContent = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    return (
        <Dialog onClick={handleClose} ref={ref} {...props}>
            <DialogContent onClick={handleClickOnContent} role="dialog">
                <Row justify="space-between" width="100%" align="center" gap={8}>
                    <ArgusLogo color="#262D3C" height={30} width={131.25} />
                    <CloseButton type="button" onClick={handleCloseByChild}>
                        <CgClose color="#4D4D4D" size={20} />
                    </CloseButton>
                </Row>
                <Column align="center" gap={16} width="100%">
                    <Column align="center" gap={8}>
                        <h4>{t('auth.logout_system')}</h4>
                        <p>{t('auth.logout_confirmation')}</p>
                    </Column>
                    <Column align="center" width="100%" gap={8}>
                        <Button type="button" onClick={signOut}>
                            {t('menu.logout')}
                        </Button>
                        <Button type="button" color="outline" onClick={handleCloseByChild}>
                            {t('general.cancel')}
                        </Button>
                    </Column>
                </Column>
            </DialogContent>
        </Dialog>
    );
};

const LogoutDialog = forwardRef(LogoutDialogBase);

export default LogoutDialog;
