import { ApiResponse } from 'src/common/core';
import { Pagination } from 'src/common/core/pagination';
import { TransformRequestHelper } from 'src/common/helpers';
import { api } from 'src/common/infra';

import { CreateFunctionDTO, Functionality, FunctionRole, ListFunctionsDTO, UpdateFunctionDTO } from '../dtos';

export class FunctionService {
    public async create(data: CreateFunctionDTO): Promise<void> {
        await api.post('/functions', data);
    }

    public async delete(id: string): Promise<void> {
        await api.delete(`/functions/${id}`);
    }

    public async deleteInBulk(ids: string[]): Promise<void> {
        await api.delete('/functions-bulk', {
            data: {
                ids,
            },
        });
    }

    public async getFunctionRolesById(id: string): Promise<FunctionRole[]> {
        const {
            data: { content },
        } = await api.get<ApiResponse<FunctionRole[]>>(`/functions/${id}/roles`);

        return content;
    }

    public async listFunctions(data: ListFunctionsDTO): Promise<Pagination<Functionality>> {
        const { pageSize, currentPage, active, name } = data;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<Functionality>>>('/functions', {
            params: {
                active,
                name,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
        });

        return content;
    }

    public async update(data: UpdateFunctionDTO): Promise<void> {
        const { id, name, roles, observation } = data;

        await api.put(`/functions/${id}`, { name, roles, observation });
    }
}

export const functionService = new FunctionService();
