import CircularProgress from '../circular-progress';
import { FieldContainer } from './styles';

interface FieldProps {
    isLoading?: boolean;
    name: string;
    value: string;
    warn?: boolean;
}

export default function Field({ isLoading, name, value, warn = false }: FieldProps) {
    return (
        <FieldContainer>
            <span className="label">{name}:</span>
            {isLoading ? (
                <CircularProgress color="#262D3C" />
            ) : (
                <span className="value" style={warn ? { color: '#F4B000' } : {}}>
                    {value}
                </span>
            )}
        </FieldContainer>
    );
}
