import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';

import Button from '../../button';
import Divider from '../../divider';
import { ButtonDiv, CloseButton, GraphDiv, OptionsText, TitleDiv } from './styles';

export interface GraphsModalProps {
    rightPosition: number;
    topPosition: number;
    children: ReactNode;
    handleCloseGraphFilter: () => void;
    handleApplyButton?: () => void;
    handleCancelButton?: () => void;
}

export function GraphsModal({ children, handleCloseGraphFilter, handleApplyButton, rightPosition, topPosition }: GraphsModalProps) {
    const { t } = useTranslation();

    const wrapperRef = useRef(null);

    return (
        <GraphDiv topPosition={topPosition} rightPosition={rightPosition} ref={wrapperRef}>
            <TitleDiv>
                <OptionsText>{t('period.options')}</OptionsText>
                <CloseButton onClick={handleCloseGraphFilter}>
                    <CgClose size={20} />
                </CloseButton>
            </TitleDiv>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            {children}
            <ButtonDiv>
                <Button color="outline" style={{ width: '50%' }} onClick={handleCloseGraphFilter}>
                    {t('general.cancel')}
                </Button>
                <Button style={{ width: '50%' }} onClick={handleApplyButton}>
                    {t('general.apply')}
                </Button>
            </ButtonDiv>
        </GraphDiv>
    );
}
