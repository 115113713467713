import { useCallback, useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TypeSearch, useSearch } from 'src/common';
import { Button, Column, Input } from 'src/common/components';
import { Reducer, reducer } from 'src/common/types/reducer';
import { INITIAL_DEPARTMENT_STATE } from 'src/modules/vehicle-monitoring/contexts/const';

import { DepartmentResume, departmentService } from '../../..';
import { useEvents } from '../../../../alarm-processing/hooks';
import { useUsers } from '../../../users/hooks/use-users';
import { InterventionProtocolDTO } from '../../dtos/intervention-protocol.dto';
import { Form } from './styles';

export default function InterventionProtocolFilter() {
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_DEPARTMENT_STATE);

    const { clear } = useSearch();
    const { t } = useTranslation();
    const { requestData, handleRequestList, handleSelectAll } = useUsers();

    const {
        setValue,
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm<InterventionProtocolDTO>({
        defaultValues: requestData,
        mode: 'onChange',
    });

    const onSubmit = useCallback(
        (data: Partial<InterventionProtocolDTO>) => {
            delete data?.pageSize;
            delete data?.enabled;

            clear();
            handleSelectAll([]);

            handleRequestList({ ...data, text: '', typeSearch: TypeSearch.PARAMS, currentPage: 1 });
        },
        [clear, handleRequestList, handleSelectAll],
    );

    const operation = watch('operation');

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 15,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations, setValue]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Autocomplete
                isLoading={operations?.isLoading}
                options={operations?.data?.map(operation => ({ ...operation, value: operation.name }))}
                setValue={setValue}
                label={String(t('alarms.operation'))}
                placeholder={String(t('alarms.operation'))}
                {...register('operation')}
                nextElement={{
                    id: 'protocol',
                }}
            />
            <Input
                autoComplete="off"
                label={String(t('intervention_protocol.protocol'))}
                id="filter-email"
                placeholder={String(t('general.write'))}
                type="email"
                {...register('protocol')}
            />
            <Input
                autoComplete="off"
                label={String(t('intervention_protocol.group_whatsapp'))}
                id="filter-function"
                placeholder={String(t('general.write'))}
                type="text"
                {...register('group')}
            />

            <Column justify="flex-end" height="60px">
                <Column justify="flex-end" height="36px">
                    <Button type="submit" size="small" width="144px">
                        {t('general.apply')}
                    </Button>
                </Column>
            </Column>
        </Form>
    );
}
