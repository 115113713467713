import { Outlet } from 'react-router-dom';
import { Column, Header, Sidebar } from 'src/common/components';

export default function MainWrapper() {
    return (
        <Sidebar>
            <Column gap={36}>
                <Header />
                <Outlet/>
            </Column>
        </Sidebar>
    );
}
