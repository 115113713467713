import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import blindSpot from '../../../../../assets/blind-spot_marker.svg';
import cellPhone from '../../../../../assets/cell-phone_marker.svg';
import collision from '../../../../../assets/collision_marker.svg';
import drowsiness from '../../../../../assets/drowsiness_marker.svg';
import faceMissing from '../../../../../assets/face-missing_marker.svg';
import lookingDown from '../../../../../assets/looking-down_marker.svg';
import occlusion from '../../../../../assets/occlusion_marker.svg';
import pedestrianCollision from '../../../../../assets/pedestrian-collision-warning_marker.svg';
import smoking from '../../../../../assets/smoking_marker.svg';
import speeding from '../../../../../assets/speeding_marker.svg';
import unevenBelt from '../../../../../assets/uneven-belt_marker.svg';
import yawn from '../../../../../assets/yawn_marker.svg';
import { Column, DateHelper, Field } from '../../../../../common';
import { InterventionRequestDTO } from '../../../../interventions/dtos/intervention.dto';

interface CustomMakerProps {
    intervention: InterventionRequestDTO;
    isShowStatus?: boolean;
    clusterer: any;
}

export function CustomMarker({ intervention, isShowStatus = false, clusterer }: CustomMakerProps) {
    const [showInfo, setShowInfo] = useState(isShowStatus);

    const { t, i18n } = useTranslation();

    const position: google.maps.LatLngLiteral = {
        lat: intervention.latitude,
        lng: intervention.longitude,
    };

    const getIcon = (eventName: string) => {
        switch (eventName) {
            case 'Sonolência':
                return drowsiness;
            case 'Celular':
                return cellPhone;
            case 'Bocejo':
                return yawn;
            case 'Fumando':
                return smoking;
            case 'Oclusão':
                return occlusion;
            case 'Velocidade':
                return speeding;
            case 'Colisão de pedestres':
                return pedestrianCollision;
            case 'Olhando para baixo':
                return lookingDown;
            case 'Face missing':
                return faceMissing;
            case 'Ponto cego':
                return blindSpot;
            case 'Colisão frontal':
                return collision;
            case 'Cinto irregular':
                return unevenBelt;
            case 'Sem cinto':
                return unevenBelt;
            default:
                return undefined;
        }
    };

    return (
        <Marker icon={getIcon(intervention.event)} clusterer={clusterer} position={position} onClick={() => setShowInfo(state => !state)}>
            {showInfo ? (
                <InfoWindow position={position} onCloseClick={() => setShowInfo(false)}>
                    <Column gap={4} align="flex-start" style={{ width: '350px' }}>
                        {intervention.videoUrl && (
                            <video
                                width="100%"
                                height="180"
                                controls
                                muted
                                autoPlay
                                loop
                                style={{
                                    width: '100%',
                                    objectFit: 'fill',
                                    borderRadius: '4px 4px 0px 0px',
                                    alignSelf: 'center',
                                }}
                            >
                                <source src={intervention.videoUrl} type="video/mp4" />
                            </video>
                        )}

                        <Column gap={2} align="flex-start" style={{ marginTop: '0.5rem' }}>
                            <Field name={t('menu.events')} value={t(`tableEventName.${intervention.event}`)} />
                            <Field name={t('general.operation')} value={intervention.operation} />
                            <Field name={t('alarms.fleet')} value={intervention.fleet} />
                            <Field name={t('general.date')} value={DateHelper.format(intervention.alarmTime, i18n.language)} />
                            <Field name={t('monitoring.lat')} value={intervention.latitude.toString()} />
                            <Field name={t('monitoring.long')} value={intervention.longitude.toString()} />
                        </Column>
                    </Column>
                </InfoWindow>
            ) : null}
        </Marker>
    );
}
