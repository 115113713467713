import { CSSProperties, Fragment, MouseEvent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, EditFill, Eye, RenderIf, Row, Table, TableOptionButton, Trash } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { Pagination } from 'src/common/core/pagination';
import { usePermission } from 'src/common/hooks';

import { PasswordPolicy } from '../../dtos';
import { usePasswordPolicies } from '../../hooks';

interface TableListProps {
    data: Pagination<PasswordPolicy>;
    onDelete(policy: PasswordPolicy | null): void;
    onEdit(policy: PasswordPolicy | null): void;
}

export default memo(function TableList({ data, onDelete, onEdit }: TableListProps) {
    const { isAdmin } = usePermission();
    const { allOnThisPageAreSelected, handleSelectAll, selectedValues, handleSelect } = usePasswordPolicies();
    const { t } = useTranslation();

    const handleChecked = useCallback((data: PasswordPolicy) => selectedValues.findIndex(event => event.id === data.id) !== -1, [selectedValues]);

    const handleDelete = (e: MouseEvent<HTMLButtonElement>, data: PasswordPolicy) => {
        e.stopPropagation();
        onDelete(data);
    };

    const handleEdit = (e: MouseEvent<HTMLButtonElement>, data: PasswordPolicy) => {
        e.stopPropagation();
        onEdit(data);
    };

    const css: CSSProperties = useMemo(
        () =>
            !isAdmin
                ? {
                      display: 'none',
                  }
                : {},
        [isAdmin],
    );

    const tableHeaders = useMemo(
        () => [
            <Checkbox
                mode={'blue-check'}
                name={'checkboxAll'}
                key="checkboxAll"
                checked={allOnThisPageAreSelected}
                onChange={() => handleSelectAll(data.elements)}
                style={css}
            />,
            t('configurations.number'),
            t('configurations.password_policy'),
            t('general.description'),
            t('monitoring.status'),
            t('configurations.organization'),
            <Fragment key="options" />,
        ],
        [allOnThisPageAreSelected, css, data.elements, handleSelectAll, t],
    );

    return (
        <Table tableHeaders={tableHeaders}>
            {data?.elements?.map(item => {
                return (
                    <TableRow className="body pointer" key={item?.id} onClick={() => handleSelect(item)}>
                        <TableData style={{ width: '16px' }}>
                            <Checkbox mode={'blue-check'} name={item.name} checked={handleChecked(item)} readOnly style={css} />
                        </TableData>

                        <TableData>{item?.number}</TableData>
                        <TableData>{item?.name}</TableData>
                        <TableData>{item?.description || '---'}</TableData>
                        <TableData>{t(`general.status.${item?.status}`)}</TableData>
                        <TableData>{item?.operation?.name}</TableData>
                        <TableData style={{ paddingRight: '16px', width: '80px' }}>
                            <Row gap={16}>
                                <RenderIf condition={!isAdmin}>
                                    <TableOptionButton type="button" onClick={e => handleEdit(e, item)}>
                                        <Eye color="#46526C" />
                                    </TableOptionButton>
                                </RenderIf>
                                <RenderIf condition={isAdmin}>
                                    <Fragment>
                                        <TableOptionButton type="button" onClick={e => handleEdit(e, item)}>
                                            <EditFill color="#46526C" />
                                        </TableOptionButton>
                                        <TableOptionButton type="button" onClick={e => handleDelete(e, item)}>
                                            <Trash />
                                        </TableOptionButton>
                                    </Fragment>
                                </RenderIf>
                            </Row>
                        </TableData>
                    </TableRow>
                );
            })}
        </Table>
    );
});
