import { yup } from 'src/common';
import { Phone } from 'src/common/domain';

export const createDptoContactFormSchema = yup.object().shape({
    contactName: yup
        .string()
        .required('field_validations.required')
        .test({
            name: 'is-void',
            message: 'field_validations.required',
            test: value => (value ? value.trim().length > 0 : false),
        }),
    phone: yup
        .string()
        .nullable()
        .test('is-phone-number', 'field_validations.phone', field => {
            if (!field) return true;

            return Phone.isValid(field);
        }),
});
