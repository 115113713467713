import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { authService } from 'src/modules/auth';

import { InternalLogin, PreLogin } from '../../modules/auth/components';

export default function Login(): JSX.Element {
    const controller = useMemo(() => new AbortController(), []);

    const [userEmail, setUserEmail] = useState<string>('');

    const { mutateAsync, isLoading } = useMutation({
        mutationKey: ['pre-login'],
        mutationFn: authService.preLogin,
    });

    const handleGoBack = useCallback(() => {
        setUserEmail('');
    }, []);

    const handlePreLogin = useCallback(
        async (email: string) => {
            try {
                const { federatedIdentity } = await mutateAsync({
                    email,
                    signal: controller.signal,
                });

                if (federatedIdentity) return authService.federatedSignIn(federatedIdentity.poolName);
                setUserEmail(email);
            } catch {
                setUserEmail(email);
            }
        },
        [controller.signal, mutateAsync],
    );

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, [controller]);

    return userEmail ? <InternalLogin email={userEmail} onGoBack={handleGoBack} /> : <PreLogin onSubmit={handlePreLogin} isLoading={isLoading} />;
}
