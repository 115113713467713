import { SVGProps } from 'react';

interface EditFillProps extends SVGProps<SVGSVGElement> {
    color: string;
}

function EditFill({ color, ...props }: EditFillProps) {
    return (
        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.433 7.567l.818-.817a1.735 1.735 0 10-2.454-2.454l-.833.832a6.3 6.3 0 002.469 2.44zm-3.924-.984l-3.605 3.605c-.425.425-.637.637-.777.898s-.199.556-.316 1.146l-.23 1.144c-.066.333-.1.499-.005.594.095.094.261.06.594-.006l1.144-.229c.59-.117.885-.176 1.146-.316.26-.14.473-.352.898-.777l3.616-3.616a8.303 8.303 0 01-2.465-2.443z"
                fill={color}
            />
        </svg>
    );
}

export default EditFill;
