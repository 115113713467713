import { SVGProps } from 'react';

interface CheckCircleProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export default function CheckCircle({ color = '#ccc', ...props }: CheckCircleProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.99992 1.33325C4.31992 1.33325 1.33325 4.31992 1.33325 7.99992C1.33325 11.6799 4.31992 14.6666 7.99992 14.6666C11.6799 14.6666 14.6666 11.6799 14.6666 7.99992C14.6666 4.31992 11.6799 1.33325 7.99992 1.33325ZM6.66659 11.3333L3.33325 7.99992L4.27325 7.05992L6.66659 9.44659L11.7266 4.38659L12.6666 5.33325L6.66659 11.3333Z"
                fill={color}
            />
        </svg>
    );
}
