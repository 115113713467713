import styled from 'styled-components';

type CursorProps = {
    allowSort?: boolean;
    sortHeaders?: string[];
    headerSelected?: string;
};

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto !important;
    overflow: auto;
`;

export const TableButton = styled.button`
    cursor: pointer;
    padding: 8px;
    margin: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border: 0;
    background: transparent;
    color: #7b7b7b;
    border-radius: 50%;

    &:hover {
        background-color: #f5f5f5;
    }
`;

export const TableContainer = styled.table`
    border-collapse: collapse;
    width: 100%;
    height: fit-content;
    overflow-x: auto !important;
`;

export const TableData = styled.td`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: 'kern' off;
    text-align: left;
    color: #7b7b7b;
    padding: 8px 8px 0 0;
    max-width: 124px;
    word-break: break-all;

    &:first-child {
        padding-left: 16px;
    }
`;

export const TableHead = styled.thead`
    padding: 8px;
    text-align: center;
`;

export const TableHeader = styled.th<CursorProps>`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding-right: 8px;
    padding-bottom: 16px;
    text-align: left;
    color: ${props =>
        props?.sortHeaders?.length && props?.sortHeaders?.length > 0 && props?.sortHeaders.includes(props?.headerSelected as string)
            ? '#6DA4D3'
            : ''};
    cursor: ${props => (props?.allowSort ? 'pointer' : 'default')};

    &:first-child {
        padding-left: 16px;
    }
`;

export const TableRow = styled.tr`
    &.body {
        border-top: 1px solid #d9d9d9;
    }

    &.pointer {
        cursor: pointer;
    }
    .relative {
        position: relative;
    }
`;
