import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, EditFill, Eye, Row, Table, TableOptionButton, Trash } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { usePermission } from 'src/common/hooks';

import { Department } from '../../dtos';
import { useOperations } from '../../hooks/use-operations';
import DeleteDialog from '../delete-dialog';
import DeleteDptosInBulkDialog from '../delete-dptos-in-bulk-dialog';

export default function DptoTableList() {
    const {
        allOnThisPageAreSelected,
        operations,
        handleSelectAll: selectAll,
        handleSelect: select,
        selectedOperations,
        deleteModalRef,
        handleOpenDialog,
    } = useOperations();
    const { isAdmin } = usePermission();
    const { t } = useTranslation();

    const [dptoToDelete, setDptoToDelete] = useState<Department | null>(null);

    const handleChecked = useCallback((data: Department) => selectedOperations.findIndex(event => event.id === data.id) !== -1, [selectedOperations]);

    const handleOpenDeleteDialog = useCallback(
        (dpto: Department | null) => {
            if (!dpto) return;

            setDptoToDelete(dpto);
            deleteModalRef?.current?.showModal();
        },
        [deleteModalRef],
    );

    const handleSelect = useCallback(
        (dpto: Department | null) => {
            if (!dpto || !isAdmin) {
                return;
            }

            select(dpto);
        },
        [isAdmin, select],
    );

    const handleSelectAll = useCallback(() => {
        if (operations?.elements) {
            selectAll(operations.elements.map(e => e as Department));
        }
    }, [operations?.elements, selectAll]);

    const tableHeaders = useMemo(
        () => [
            <Checkbox
                mode={'blue-check'}
                name={'checkboxAll'}
                key="checkboxAll"
                hidden={!isAdmin}
                checked={allOnThisPageAreSelected}
                onChange={handleSelectAll}
            />,
            t('configurations.organization'),
            t('configurations.status'),
            t('configurations.institution'),
            <Fragment key="options" />,
        ],
        [allOnThisPageAreSelected, handleSelectAll, isAdmin, t],
    );

    return (
        <Fragment>
            <DeleteDialog ref={deleteModalRef} department={dptoToDelete} />
            <DeleteDptosInBulkDialog />

            <Table tableHeaders={tableHeaders}>
                {operations?.elements?.map(item => {
                    return (
                        <TableRow className="body pointer" key={item?.id} onClick={() => handleSelect(item)}>
                            <TableData style={{ width: '16px' }}>
                                <Checkbox
                                    hidden={!isAdmin}
                                    mode={'blue-check'}
                                    name={item?.id.toString() as string}
                                    readOnly
                                    checked={handleChecked(item as Department)}
                                />
                            </TableData>

                            <TableData>{item?.name}</TableData>
                            <TableData>{t(`general.status.${item?.status}`)}</TableData>
                            <TableData>{item?.parentName}</TableData>
                            <TableData style={{ width: '68px', paddingRight: '16px' }}>
                                {isAdmin ? (
                                    <Row gap={16}>
                                        <TableOptionButton
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOpenDialog(item);
                                            }}
                                        >
                                            <EditFill color="#7B7B7B" />
                                        </TableOptionButton>
                                        <TableOptionButton
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOpenDeleteDialog(item);
                                            }}
                                        >
                                            <Trash />
                                        </TableOptionButton>
                                    </Row>
                                ) : (
                                    <Row gap={16}>
                                        <TableOptionButton
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOpenDialog(item);
                                            }}
                                        >
                                            <Eye color="#7B7B7B" />
                                        </TableOptionButton>
                                    </Row>
                                )}
                            </TableData>
                        </TableRow>
                    );
                })}
            </Table>
        </Fragment>
    );
}
