import { SignalDTO, TransformRequestHelper, TypeSearch, api } from 'src/common';
import { ApiResponse, Service } from 'src/common/core';
import { Pagination } from 'src/common/core/pagination';

import { ElectronicFence } from '../domain/entities/electronic-fence';
import { GetElectronicFenceDTO } from '../dtos/get-electronic-fence.dto';

export class ListElectronicFenceService implements Service<GetElectronicFenceDTO & SignalDTO, Pagination<ElectronicFence>> {
    public async execute(request: GetElectronicFenceDTO & SignalDTO): Promise<Pagination<ElectronicFence>> {
        const { currentPage, operation, pageSize, status, signal, text } = request;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<ElectronicFence>>>('/vehicle-monitoring/electronic-fences', {
            params: {
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
                status,
                operation: operation || text,
                typeSearch: text ? TypeSearch.TEXT : TypeSearch.PARAMS,
            },
            signal,
        });

        return content;
    }
}

export const listElectronicFence = new ListElectronicFenceService();
