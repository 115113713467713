import { EChartsOption, LinearGradientObject } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';


export interface DoughnutChartDataProps {
    value: number;
    name: string;
    color?: string;
}

export interface DoughnutChartProps {
    width?: string | number;
    height?: string | number;
    isSmooth?: boolean;
    color?: string | LinearGradientObject;
    data: Array<DoughnutChartDataProps>;
}

export function DoughnutChart({ width = '100%', height = '100%', data }: DoughnutChartProps) {
    const { t } = useTranslation();

    const option: EChartsOption = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        series: [
            {
                name: t('echarts.group') || 'Grupo',
                type: 'pie',
                radius: ['75%', '90%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2,
                },
                label: {
                    show: false,
                    position: 'center',
                },
                emphasis: {
                    label: {
                        show: false,
                    },
                },
                labelLine: {
                    show: false,
                },
                data: data?.map(serie => ({ value: serie.value, name: serie.name, itemStyle: { color: serie.color } })) || [],
            },
        ],
    };

    return <ReactEcharts notMerge={true} option={JSON.parse(JSON.stringify(option))} style={{ height, width }} />;
}
