import { createContext, useCallback, useMemo, useState } from 'react';

import { FunctionComponent, ResponseStatus } from '../../../../common';
import { Pagination } from '../../../../common/core/pagination';
import { ComparisonOfUnitsDTO, ComparisonOfUnitsData, GeneralIndexType } from '../dtos/comparison-of-units-dtos';
import { comparisonOfUnitsService } from '../services/comparison-of-units.service';
import { ComparisonOfUnitsContextProps } from './types';

export const ComparisonOfUnitsContext = createContext({} as ComparisonOfUnitsContextProps);

export function ComparisonOfUnitsProvider({ children }: FunctionComponent) {
    const [comparisonOfUnits, setComparisonOfUnits] = useState<Pagination<ComparisonOfUnitsData> | null>(null);
    const [selectedFleet, setSelectedFleet] = useState<GeneralIndexType | null>(null);

    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: true,
        success: false,
        void: false,
    });

    const [requestData, setRequestData] = useState<ComparisonOfUnitsDTO>({
        currentPage: 1,
        pageSize: 15,
    });

    const handleRequestList = useCallback((data: Partial<ComparisonOfUnitsData>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleGetList = useCallback(async (props: ComparisonOfUnitsDTO) => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const response: Pagination<ComparisonOfUnitsData> = await comparisonOfUnitsService.getData(props);

            setComparisonOfUnits(response);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: !response?.elements || response?.elements?.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const handleSelect = useCallback((data: GeneralIndexType) => {
        setSelectedFleet(data);
    }, []);

    const data: ComparisonOfUnitsContextProps = useMemo(
        () => ({
            comparisonOfUnits,
            responseStatus,
            requestData,
            handleRequestList,
            handleGetList,
            selectedFleet,
            handleSelect,
        }),
        [comparisonOfUnits, responseStatus, requestData, handleRequestList, handleGetList, selectedFleet, handleSelect],
    );

    return <ComparisonOfUnitsContext.Provider value={data}>{children}</ComparisonOfUnitsContext.Provider>;
}
