import { Phone } from 'src/common/domain';
import { yup } from 'src/common/infra/validations';

export const createOrUpdateUserSchema = yup.object().shape({
    email: yup.string().email('field_validations.email').required('field_validations.required').max(100, 'field_validations.max'),
    enabled: yup.string(),
    function: yup.string().required('field_validations.required'),
    name: yup
        .string()
        .required('field_validations.required')
        .max(191, 'field_validations.max')
        .min(3, 'field_validations.min')
        .test('is-num', 'field_validations.name', name => {
            if (!name) return true;

            if (!name || name.trim().length < 3) {
                return false;
            }

            const numberRegex = /[0-9]/g;

            if (numberRegex.test(name)) {
                return false;
            }

            return true;
        }),
    phone: yup
        .string()
        .nullable()
        .test('is-phone-number', 'field_validations.phone', field => (!field ? true : Phone.isValid(field))),
});
