import { SVGProps } from 'react';

interface ArgusLogoProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export default function ArgusLogo({ color = '#fff', ...props }: ArgusLogoProps) {
    return (
        <svg width="175" height="40" viewBox="0 0 175 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_147_7838)">
                <path
                    d="M19.9603 40V34.2478V40ZM14.7103 28.7704C12.7963 27.6222 11.3108 25.8761 10.4815 23.8C9.6522 21.7239 9.52495 19.4324 10.1192 17.2768C10.7135 15.1212 11.9966 13.2205 13.7716 11.8663C15.5467 10.5121 17.7157 9.7791 19.9461 9.77967H19.9792V2.651e-09H19.9461C15.6732 -6.97343e-05 11.5132 1.37573 8.07885 3.92478C4.64447 6.47382 2.11722 10.0614 0.869333 14.159C-0.378558 18.2567 -0.281132 22.6479 1.14726 26.6858C2.57565 30.7238 5.25951 34.1951 8.80354 36.5885L8.74684 36.5458L14.7103 28.7704Z"
                    fill="#B2D5F2"
                />
                <path
                    d="M19.979 0V9.77967C20.2751 9.77967 20.5681 9.79389 20.8579 9.82232C20.9182 9.05859 21.1688 8.32227 21.5868 7.681C22.0049 7.03973 22.5769 6.51407 23.2504 6.15229C23.9239 5.79051 24.6773 5.60421 25.4413 5.61051C26.2053 5.61681 26.9555 5.81551 27.623 6.18834C28.2904 6.56118 28.8538 7.09621 29.2612 7.74428C29.6687 8.39236 29.9072 9.13271 29.9549 9.89733C30.0026 10.6619 29.8579 11.4263 29.5342 12.1203C29.2104 12.8142 28.7179 13.4154 28.102 13.8688C29.2346 15.3851 29.9245 17.1872 30.0948 19.0739C30.2652 20.9606 29.9092 22.8577 29.0666 24.5533C28.224 26.2489 26.928 27.6763 25.3233 28.6762C23.7186 29.676 21.8683 30.209 19.979 30.2156V40C25.269 40 30.3424 37.8929 34.083 34.1421C37.8236 30.3914 39.925 25.3043 39.925 20C39.925 14.6957 37.8236 9.60859 34.083 5.85786C30.3424 2.10714 25.269 0 19.979 0Z"
                    fill="#75A8D8"
                />
            </g>
            <path
                d="M66.8986 31.8425L59.9311 12.1043C59.8693 11.9037 59.745 11.7282 59.5765 11.6036C59.408 11.4789 59.2041 11.4117 58.9947 11.4117C58.7852 11.4117 58.5813 11.4789 58.4128 11.6036C58.2443 11.7282 58.12 11.9037 58.0582 12.1043L51.097 31.8425H46.4957L54.1874 10.2263C54.5064 9.23613 55.1543 8.3855 56.0229 7.81615C56.9183 7.26873 57.9525 6.99263 59.0009 7.02111C60.0513 6.99213 61.0876 7.26823 61.9852 7.81615C62.8538 8.3855 63.5016 9.23613 63.8207 10.2263L71.4998 31.8425H66.8986Z"
                fill={color}
            />
            <path
                d="M75.92 24.4245C75.92 22.0206 76.6192 20.2135 78.0177 19.0032C79.4162 17.7929 81.3141 17.1857 83.7115 17.1815H88.9246C89.2998 17.1879 89.6722 17.1161 90.0183 16.9706C90.3643 16.8251 90.6765 16.6092 90.9349 16.3364C91.204 16.0642 91.4156 15.7404 91.5572 15.3844C91.6988 15.0284 91.7674 14.6475 91.759 14.2643C91.7615 13.8847 91.6827 13.509 91.528 13.1625C91.3728 12.8247 91.1617 12.5157 90.9037 12.2485C90.654 11.984 90.3549 11.7713 90.0234 11.6225C89.6876 11.4642 89.3207 11.3829 88.9496 11.3846H75.92V7.00255H88.2753C89.4744 6.97506 90.6683 7.17032 91.7965 7.57848C92.714 7.92943 93.5432 8.47873 94.2251 9.18733C94.8515 9.85476 95.3283 10.6488 95.6236 11.5161C96.2102 13.2733 96.2233 15.1722 95.6611 16.9374C95.3845 17.8003 94.9244 18.5927 94.3125 19.2599C93.6327 19.9803 92.8067 20.546 91.8901 20.9188C90.7384 21.3653 89.5097 21.5781 88.2753 21.5448H83.2807L96.6412 27.4481V32.2371L80.2653 24.8376V31.8427H75.895L75.92 24.4245Z"
                fill={color}
            />
            <path
                d="M111.344 31.8424C103.852 31.8424 100.106 27.7107 100.106 19.4473V19.3409C100.131 11.1276 103.892 7.021 111.388 7.021H120.365V11.4031H110.264C108.299 11.4031 106.843 12.052 105.894 13.35C104.945 14.6479 104.46 16.7012 104.439 19.5099C104.439 24.8686 106.379 27.5458 110.258 27.5417H115.433C115.796 27.5413 116.153 27.4556 116.476 27.2913C116.802 27.1267 117.095 26.9015 117.337 26.6277C117.581 26.3431 117.775 26.0193 117.912 25.6699C118.117 25.1329 118.175 24.5506 118.079 23.9836C117.984 23.4166 117.738 22.8856 117.369 22.4459C117.134 22.1613 116.838 21.9339 116.502 21.7809C116.167 21.628 115.802 21.5536 115.433 21.5633H111.519V17.1812H115.646C116.568 17.1739 117.481 17.3638 118.324 17.7383C119.139 18.1052 119.871 18.6333 120.478 19.2908C121.11 19.981 121.604 20.7865 121.932 21.6634C122.301 22.6239 122.485 23.6455 122.476 24.6745C122.476 25.587 122.285 26.4893 121.914 27.3226C121.536 28.1697 121.012 28.9432 120.365 29.6075C119.738 30.2756 118.996 30.8248 118.174 31.2289C117.364 31.6368 116.471 31.8511 115.564 31.8549L111.344 31.8424Z"
                fill={color}
            />
            <path
                d="M148.235 22.4397C148.26 23.7522 148.048 25.0585 147.611 26.2959C147.199 27.4173 146.534 28.4283 145.669 29.2507C144.732 30.1101 143.623 30.7608 142.417 31.16C140.942 31.6437 139.397 31.8743 137.847 31.8424C136.323 31.8708 134.805 31.6401 133.358 31.16C132.147 30.746 131.036 30.0853 130.092 29.2194C129.21 28.3907 128.523 27.3748 128.082 26.2458C127.621 25.048 127.39 23.7736 127.402 22.4898V7.021H131.772V22.3645C131.746 23.0666 131.877 23.7657 132.156 24.4103C132.434 25.0548 132.854 25.6286 133.383 26.0893C134.469 27.0534 135.986 27.5291 137.928 27.5291H138.034C139.975 27.5291 141.436 27.0534 142.404 26.0893C142.893 25.6069 143.277 25.0277 143.53 24.3886C143.784 23.7495 143.902 23.0645 143.878 22.3771V7.021H148.248L148.235 22.4397Z"
                fill={color}
            />
            <path
                d="M175 24.5999C175 27.0038 174.311 28.8109 172.933 30.0212C171.556 31.2315 169.641 31.8387 167.19 31.8429H154.454V27.5172H167.995C168.358 27.5239 168.718 27.448 169.048 27.2951C169.378 27.1422 169.669 26.9164 169.899 26.6345C170.405 26.0381 170.674 25.276 170.655 24.4935C170.662 23.7338 170.394 22.9973 169.899 22.4214C169.669 22.1395 169.378 21.9137 169.048 21.7608C168.718 21.6079 168.358 21.532 167.995 21.5387H161.028C159.782 21.5814 158.542 21.3684 157.382 20.9127C156.461 20.5302 155.638 19.9463 154.972 19.2037C154.373 18.5303 153.92 17.7394 153.642 16.8812C153.372 16.061 153.233 15.2033 153.23 14.3396C153.222 13.3815 153.368 12.4283 153.661 11.5163C153.944 10.6463 154.418 9.85065 155.047 9.18753C155.728 8.48683 156.555 7.94595 157.469 7.60372C158.61 7.19365 159.816 6.99839 161.028 7.02779H173.383V11.4099H160.322C159.951 11.4082 159.584 11.4894 159.248 11.6478C158.917 11.7966 158.618 12.0093 158.368 12.2738C158.108 12.5393 157.897 12.8487 157.744 13.1877C157.587 13.5338 157.506 13.9095 157.506 14.2895C157.499 14.6731 157.569 15.0542 157.712 15.4102C157.854 15.7661 158.067 16.0897 158.337 16.3616C158.595 16.6347 158.907 16.8509 159.253 16.9964C159.599 17.1419 159.972 17.2135 160.347 17.2067H167.215C169.612 17.2067 171.504 17.814 172.89 19.0284C174.276 20.2429 174.971 22.05 174.975 24.4497L175 24.5999Z"
                fill={color}
            />
            <path
                d="M66.8986 31.8425L59.9311 12.1043C59.8693 11.9037 59.745 11.7282 59.5765 11.6036C59.408 11.4789 59.2041 11.4117 58.9947 11.4117C58.7852 11.4117 58.5813 11.4789 58.4128 11.6036C58.2443 11.7282 58.12 11.9037 58.0582 12.1043L51.0658 31.8425H46.4957L54.1874 10.2263C54.5064 9.23613 55.1543 8.3855 56.0229 7.81615C56.9183 7.26873 57.9525 6.99263 59.0009 7.02111C60.0513 6.99213 61.0876 7.26823 61.9852 7.81615C62.8538 8.3855 63.5016 9.23613 63.8207 10.2263L71.4998 31.8425H66.8986Z"
                fill={color}
            />
            <path
                d="M75.92 24.4245C75.92 22.0206 76.6192 20.2114 78.0177 18.9969C79.4162 17.7825 81.3141 17.1773 83.7115 17.1815H88.9246C89.2998 17.1879 89.6722 17.1161 90.0183 16.9706C90.3643 16.8251 90.6765 16.6092 90.9349 16.3364C91.204 16.0642 91.4156 15.7404 91.5572 15.3844C91.6988 15.0284 91.7674 14.6475 91.759 14.2643C91.7615 13.8847 91.6827 13.509 91.528 13.1625C91.3728 12.8247 91.1617 12.5157 90.9037 12.2485C90.654 11.984 90.3549 11.7713 90.0234 11.6225C89.6853 11.4643 89.3165 11.383 88.9433 11.3846H75.92V7.00255H88.2753C89.4744 6.97506 90.6683 7.17032 91.7965 7.57848C92.7129 7.92802 93.5404 8.47757 94.2189 9.18733C94.8492 9.85286 95.3284 10.6473 95.6236 11.5161C95.9288 12.4256 96.0807 13.3798 96.0731 14.3394C96.0706 15.2215 95.9316 16.098 95.6611 16.9374C95.3845 17.8003 94.9244 18.5927 94.3125 19.2599C93.6318 19.9821 92.8033 20.548 91.8839 20.9188C90.7344 21.3655 89.5077 21.5783 88.2753 21.5448H83.2807L96.6412 27.4481V32.2371L80.2653 24.8376V31.8427H75.895L75.92 24.4245Z"
                fill={color}
            />
            <path
                d="M111.338 31.8424C103.846 31.8424 100.1 27.7107 100.1 19.4473V19.3409C100.125 11.1276 103.886 7.021 111.382 7.021H120.366V11.4031H110.264C108.3 11.4031 106.843 12.052 105.894 13.35C104.945 14.6479 104.46 16.7012 104.439 19.5099C104.439 24.8686 106.379 27.5458 110.258 27.5417H115.433C115.796 27.5413 116.153 27.4556 116.476 27.2913C116.802 27.1267 117.095 26.9015 117.338 26.6277C117.581 26.3431 117.776 26.0193 117.912 25.6699C118.117 25.1329 118.175 24.5506 118.079 23.9836C117.984 23.4166 117.738 22.8856 117.369 22.4459C117.134 22.1613 116.838 21.9339 116.503 21.7809C116.167 21.628 115.802 21.5536 115.433 21.5633H111.519V17.1812H115.646C116.568 17.1746 117.481 17.3646 118.324 17.7383C119.139 18.1052 119.872 18.6333 120.478 19.2908C121.11 19.981 121.604 20.7865 121.933 21.6634C122.301 22.6239 122.485 23.6455 122.476 24.6745C122.476 25.587 122.285 26.4893 121.914 27.3226C121.536 28.1697 121.012 28.9432 120.366 29.6075C119.738 30.2756 118.996 30.8248 118.174 31.2289C117.364 31.6368 116.471 31.8511 115.565 31.8549L111.338 31.8424Z"
                fill={color}
            />
            <path
                d="M148.235 22.4397C148.26 23.7522 148.048 25.0585 147.611 26.2959C147.199 27.4173 146.534 28.4283 145.669 29.2507C144.732 30.1101 143.623 30.7608 142.417 31.16C140.942 31.6437 139.397 31.8743 137.847 31.8424C136.323 31.8708 134.805 31.6401 133.358 31.16C132.147 30.746 131.036 30.0853 130.092 29.2194C129.21 28.3907 128.523 27.3748 128.082 26.2458C127.621 25.048 127.39 23.7736 127.402 22.4898V7.021H131.772V22.3645C131.746 23.0666 131.877 23.7657 132.156 24.4103C132.434 25.0548 132.854 25.6286 133.383 26.0893C134.469 27.0534 135.986 27.5291 137.928 27.5291H138.034C139.975 27.5291 141.436 27.0534 142.404 26.0893C142.893 25.6069 143.277 25.0277 143.53 24.3886C143.784 23.7495 143.902 23.0645 143.878 22.3771V7.021H148.248L148.235 22.4397Z"
                fill={color}
            />
            <path
                d="M175 24.5999C175 27.0038 174.311 28.8109 172.933 30.0212C171.556 31.2315 169.641 31.8387 167.19 31.8429H154.454V27.5172H167.995C168.358 27.5239 168.718 27.448 169.048 27.2951C169.378 27.1422 169.669 26.9164 169.899 26.6345C170.404 26.0375 170.673 25.2758 170.655 24.4935C170.662 23.7338 170.394 22.9973 169.899 22.4214C169.669 22.1395 169.378 21.9137 169.048 21.7608C168.718 21.6079 168.358 21.532 167.995 21.5387H161.028C159.782 21.5814 158.542 21.3684 157.382 20.9127C156.463 20.528 155.64 19.9444 154.972 19.2037C154.373 18.5303 153.92 17.7394 153.642 16.8812C153.372 16.061 153.233 15.2033 153.23 14.3396C153.222 13.3815 153.368 12.4283 153.661 11.5163C153.944 10.6463 154.418 9.85065 155.047 9.18753C155.728 8.48683 156.555 7.94595 157.469 7.60372C158.61 7.19365 159.816 6.99839 161.028 7.02779H173.383V11.4099H160.322C159.951 11.4082 159.584 11.4894 159.248 11.6478C158.917 11.7966 158.618 12.0093 158.368 12.2738C158.108 12.5393 157.897 12.8487 157.744 13.1877C157.587 13.5338 157.506 13.9095 157.506 14.2895C157.499 14.6731 157.569 15.0542 157.712 15.4102C157.854 15.7661 158.067 16.0897 158.337 16.3616C158.595 16.6347 158.907 16.8509 159.253 16.9964C159.599 17.1419 159.972 17.2135 160.347 17.2067H167.215C169.612 17.2067 171.504 17.814 172.89 19.0284C174.276 20.2429 174.971 22.05 174.975 24.4497L175 24.5999Z"
                fill={color}
            />
            <defs>
                <clipPath id="clip0_147_7838">
                    <rect width="39.892" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
