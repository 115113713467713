import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton, FunctionComponent, RenderIf, ToggleSwitch } from 'src/common';
import { CardHeader, Column, Divider, MainCard, Row } from 'src/common/components';

import { useHistoricalRoute } from '../../hooks';
import HistoricalRouteFilter from '../historical-route-filter';

type HistoricalRouteContainer = FunctionComponent;

export default function HistoricalRouteContainer({ children }: HistoricalRouteContainer) {
    const { reset, filterIsOpen, setFilterIsOpen, showEvents, setShowEvents } = useHistoricalRoute();
    const { t } = useTranslation();

    useEffect(() => {
        return () => reset();
    }, [reset]);

    return (
        <MainCard>
            <Column width="100%" gap={16}>
                <CardHeader>
                    <Row gap={16} align="center">
                        <h3>{t('monitoring.track_playback')}</h3>
                    </Row>
                    <Row align="center" gap={32}>
                        <Row align="center" gap={16}>
                            <ToggleSwitch
                                checked={showEvents}
                                label={t('general.show_events')?.toString()}
                                mode={'underline'}
                                name={'showEvents'}
                                onChange={() => setShowEvents(state => !state)}
                            />
                        </Row>
                        <Row align="center" gap={8}>
                            <FilterButton isOpen={filterIsOpen} onOpen={setFilterIsOpen} />
                        </Row>
                    </Row>
                </CardHeader>
                <Divider />
                <RenderIf condition={filterIsOpen}>
                    <HistoricalRouteFilter />
                </RenderIf>
                {children}
            </Column>
        </MainCard>
    );
}
