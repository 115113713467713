import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArgusLogo, Button, Column, Divider, Input, RenderIf, Row, Select } from 'src/common/components';
import {
    AuthBackground,
    AuthCard,
    AuthForm,
    BackButton,
    QRCode,
    SetupKey,
    SupportingText,
    Title,
    setupMFA,
    useAuth,
    useLogin,
} from 'src/modules/auth';
import { SetupMFAParams } from 'src/modules/auth/dtos/auth.dto';
import { MFA, SetupMFAFormDTO } from 'src/modules/auth/dtos/mfa.dto';

export default function SetupMFA() {
    const { challenge } = useAuth();
    const { mutateAsync: login, isLoading } = useLogin();

    const {
        handleSubmit: onSubmit,
        register,
        watch,
        formState: { errors, isValid },
    } = useForm<SetupMFAFormDTO>({
        resolver: yupResolver(setupMFA),
        defaultValues: {
            mfaType: MFA.TOTP,
            phone: (challenge?.challengeParameters as SetupMFAParams)?.phone,
        },
    });
    const { t } = useTranslation();

    const controller = useMemo(() => new AbortController(), []);

    const feedback = (field: keyof SetupMFAFormDTO) => {
        return { message: t(errors[field]?.message || '')?.toString() };
    };

    const options = [
        {
            label: `${t('auth.authenticator_app')} (${t('general.recommended')})`,
            value: MFA.TOTP,
        },
        {
            label: t('auth.sms'),
            value: MFA.SMS,
        },
    ];

    const handleSubmit = useCallback(
        async (data: SetupMFAFormDTO) => {
            const { code, mfaType, phone } = data;

            await login({
                code,
                mfaType,
                password: challenge?.password as string,
                phone,
                setupMFA: true,
                signal: controller.signal,
                username: challenge?.email as string,
            });
        },
        [challenge?.email, challenge?.password, controller.signal, login],
    );

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, [controller]);

    return (
        <AuthBackground>
            <Column align="center" justify="center" size="100%">
                <AuthCard>
                    <Row width="100%" align="center" justify="space-between" gap={8}>
                        <BackButton />
                        <ArgusLogo color="#262D3C" height={30} width={131} />
                        <div style={{ width: '30px' }} />
                    </Row>
                    <Column align="center" gap={16}>
                        <Column align="center">
                            <Title>{t('auth.configure_mfa')}</Title>
                            <SupportingText>{t('auth.configure_mfa_text')}</SupportingText>
                        </Column>
                        <AuthForm onSubmit={onSubmit(handleSubmit)}>
                            <Column align="center" gap={16} style={{ minWidth: '302px' }}>
                                <Column width="100%" id="fieldset" align="flex-start" gap={8}>
                                    <Select
                                        options={options}
                                        feedback={feedback('mfaType')}
                                        style={{ width: '100%' }}
                                        label={String(t('auth.preferred_mfa_method'))}
                                        {...register('mfaType')}
                                    />
                                </Column>

                                <RenderIf condition={watch('mfaType') === MFA.SMS}>
                                    <Input
                                        autoComplete="off"
                                        label={String(t('configurations.phone_number'))}
                                        placeholder={String(t('general.write'))}
                                        type="text"
                                        feedback={feedback('phone')}
                                        tooltip={String(t('field_validations.phone_number_tooltip'))}
                                        {...register('phone')}
                                        style={{ width: '100%' }}
                                    />
                                </RenderIf>
                                <RenderIf condition={watch('mfaType') === MFA.TOTP}>
                                    <Column width="100%" align="center" gap={16}>
                                        <Divider />
                                        <Column width="100%" align="center" gap={16}>
                                            <QRCode
                                                value={(challenge?.challengeParameters as SetupMFAParams)?.code}
                                                title={String(t('auth.scan_the_qr_code'))}
                                                message={String(t('auth.use_an_authenticator_app_or_browser_extension_to_scan'))}
                                            />
                                            <SetupKey code={(challenge?.challengeParameters as SetupMFAParams)?.code} />
                                        </Column>
                                        <Divider />
                                        <Input
                                            label={String(t('auth.verify_code'))}
                                            placeholder={String(t('general.write'))}
                                            autoComplete="off"
                                            type="text"
                                            feedback={feedback('code')}
                                            {...register('code')}
                                            style={{ width: '100%' }}
                                        />
                                    </Column>
                                </RenderIf>
                            </Column>
                            <Column gap={16} width="100%" align="center">
                                <Button type="submit" disabled={!isValid} isLoading={isLoading}>
                                    {t('general.confirm')}
                                </Button>
                            </Column>
                        </AuthForm>
                    </Column>
                </AuthCard>
            </Column>
        </AuthBackground>
    );
}
