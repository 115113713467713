import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { CardHeader, Column, Divider, MainCard, Row } from 'src/common/components';


export default function Container({ children }: PropsWithChildren) {
    const { t } = useTranslation();

    return (
        <MainCard>
            <Column width="100%" gap={16}>
                <CardHeader>
                    <Row gap={16} align="center">
                        <h3>{t('general.historic')}</h3>
                    </Row>
                </CardHeader>
                <Divider />
                {children}
            </Column>
        </MainCard>
    );
}
