import { AxiosResponse } from 'axios';
import { SessionDTO } from 'src/modules/auth/dtos/auth.dto';

import { ApiResponse } from '../core/api-response';
import { Service } from '../core/service';
import { api } from '../infra/http';

export class RefreshTokenService implements Service<string, AxiosResponse<ApiResponse<SessionDTO>>> {
    public async execute(token: string): Promise<AxiosResponse<ApiResponse<SessionDTO>>> {
        return api.post<{ token: string }, AxiosResponse<ApiResponse<SessionDTO>>>('/auth/refresh-token', { token });
    }
}

export const refreshTokenService = new RefreshTokenService();
