import styled from 'styled-components';

interface ValueProps {
    color: string;
}

export const Value = styled.strong<ValueProps>`
    cursor: default;
    color: ${props => props.color};
    font-weight: 700;
    font-size: 42px;
    line-height: 90%;
`;

export const Title = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    cursor: default;
    letter-spacing: 0.25px;

    color: #7b7b7b;
`;
