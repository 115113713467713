import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './styles';

interface ExportButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function Export({ onClick }: ExportButtonProps) {
    const { t } = useTranslation();

    return (
        <Button type="button" onClick={onClick}>
            <span>{t('general.export')}</span>
            <svg width={16} height={12} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M.879.879C0 1.757 0 3.172 0 6c0 2.828 0 4.243.879 5.121C1.757 12 3.172 12 6 12h4c2.828 0 4.243 0 5.121-.879C16 10.243 16 8.828 16 6c0-2.828 0-4.243-.879-5.121C14.243 0 12.828 0 10 0H6C3.172 0 1.757 0 .879.879zm2.59 1.535a.75.75 0 10-.938 1.172l4.376 3.5a1.75 1.75 0 002.186 0l4.376-3.5a.75.75 0 10-.938-1.172l-4.375 3.5a.25.25 0 01-.312 0l-4.375-3.5z"
                    fill="#46526C"
                />
            </svg>
        </Button>
    );
}
