import { SVGProps } from 'react';

interface ImSleepyProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export function ImSleepy({ color, ...rest }: ImSleepyProps) {
    return (
        <svg width="21" height="20" viewBox="0 0 42 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path stroke={color} d="M15.88 11.7295C10.29 13.0695 6.24995 16.4295 3.41995 20.7895C1.31995 24.0095 -0.0300437 28.9795 0.379956 33.0495C0.709956 36.2795 1.35995 38.5395 2.61995 41.0195C3.83995 43.4195 5.45996 45.2795 7.09996 46.7995C10.47 49.9195 15.98 52.4995 22.24 52.0395C25.17 51.8195 28.04 50.9895 30.22 49.7995C37.55 45.8195 41.7199 38.6695 41.2299 30.1395C41.0699 27.2495 40.13 24.3595 38.99 22.1995C37.73 19.7995 36.46 18.2495 34.52 16.4195C32.93 14.9095 30.9499 13.6595 28.7599 12.7495C28.6799 13.7695 28.2699 14.7095 27.6499 15.5095C31.3699 17.0595 34.7199 20.2095 36.4799 23.8295C42.0699 35.4495 34.16 48.3795 21.67 49.0995C16.2 49.4095 11.7 47.0495 8.87996 44.4295C3.85996 39.7595 1.67995 32.1195 4.57995 25.0295C6.20995 21.0395 9.08995 17.9795 13.0099 15.9595C14.1999 15.3395 15.29 14.9595 16.42 14.6795C16.04 13.9695 15.8399 13.1895 15.8399 12.3395C15.8699 12.1195 15.87 11.9295 15.89 11.7295" />
            <path stroke={color} d="M15.9293 27.3899C15.1793 27.5699 9.50935 28.9599 9.05935 29.2799C7.71935 30.2199 8.62936 32.3899 10.3494 31.9299C12.2194 31.4299 13.9993 30.94 15.8893 30.48C16.8493 30.24 17.7494 30.0599 17.8194 28.9299C17.8894 27.8999 16.9593 27.1499 15.9293 27.3999" />
            <path stroke={color} d="M24.8291 27.4096C23.7191 27.8596 23.5691 28.9196 24.0291 29.6396C24.4791 30.3196 25.6291 30.4496 26.5091 30.6896C27.4291 30.9196 28.3591 31.1496 29.2691 31.4096C29.9091 31.5896 31.5191 32.1296 32.1591 31.9296C32.9691 31.6696 33.5691 30.5996 32.9491 29.6896C32.4891 29.0196 31.4391 28.9196 30.4691 28.6396C29.5291 28.3996 28.6491 28.1796 27.7091 27.9196C27.0891 27.7396 25.3991 27.1896 24.8291 27.4196" />
            <path stroke={color} d="M22.1792 9.72004L19.4792 13.43C19.3292 13.74 19.2492 13.8 19.2792 14.22C19.3792 15.56 20.1992 15.17 22.7192 15.02C24.3592 14.92 25.2192 15.24 25.1592 13.69C24.2492 13.07 23.0792 13.42 21.8292 13.49C22.2792 12.08 26.8392 8.05004 23.3092 8.11004C22.3292 8.12004 19.6392 8.03004 19.1692 8.83004C18.2892 10.32 21.2992 9.75004 22.1792 9.73004" />
            <path stroke={color} d="M23.9609 2.56962C24.8009 3.42962 25.1509 2.78962 26.5709 2.40962C26.3809 3.35962 26.1609 3.84961 25.8009 4.75961L25.4209 6.53962C25.5409 7.36962 25.5209 6.95962 25.6609 7.21962C26.0909 7.29962 31.0009 6.16962 30.0709 4.97962C29.5809 4.33962 27.9509 5.19962 27.2209 5.36962C27.6409 3.68962 28.5809 2.41962 28.3909 0.689615C27.0109 0.509615 24.2809 1.47962 23.9609 2.57962" />
            <path stroke={color} d="M22.779 38.4899C22.009 38.4799 18.789 38.4399 18.269 38.6299C16.739 39.1999 17.089 41.5399 18.849 41.5199C20.789 41.4899 20.019 41.4699 21.959 41.4799C22.949 41.4899 23.859 41.5199 24.209 40.4599C24.539 39.4599 23.839 38.4999 22.769 38.4899" />
        </svg>
    )
}
