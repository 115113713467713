import { AiOutlineMonitor } from 'react-icons/ai';
import { GiRoad } from 'react-icons/gi';
import { IoAnalytics } from 'react-icons/io5';
import { MdAlarm, MdOutlineAssessment } from 'react-icons/md';

import CircleGridCrossLeftFill from '../../icons/circle-grid-cross-left-fill';
import { SidebarItemProps } from '../sidebar-item';

export const SIDEBAR_ITEMS: SidebarItemProps[] = [
    {
        iconComponent: <CircleGridCrossLeftFill className="circle-grid-cross-left-fill" />,
        id: 'home',
        shouldMatchExactHref: true,
        to: '/',
        value: 'menu.home',
    },
    {
        iconComponent: <MdAlarm size={24} />,
        id: 'alarm-processing',
        to: '/alarm-processing',
        userHasAny: ['alarms_processing'],
        value: 'menu.processing_alarms',
        subMenu: [
            {
                to: '/alarm-processing?search=pending-alarms',
                id: 'pending-alarms',
                value: 'menu.pending_alarms',
                userHasAny: ['sort_alarm'],
            },
            {
                to: '/alarm-processing?search=events',
                id: 'events',
                value: 'menu.events',
                userHasAny: ['review_alarm'],
            },
            {
                to: '/review-request',
                id: 'review-request',
                value: 'menu.analysis_requests',
                userHasAny: ['review_requests'],
            },
        ],
    },
    {
        iconComponent: <AiOutlineMonitor size={24} />,
        id: 'vehicle-monitoring',
        to: '/vehicle-monitoring',
        value: 'menu.vehicles_monitoring',
        userHasAny: ['vehicle_monitoring'],
        subMenu: [
            {
                id: 'real-time-location',
                to: '/vehicle-monitoring/real-time-location',
                userHasAny: ['real_time_localization'],
                value: 'monitoring.real_time',
            },
            {
                id: 'track-playback',
                to: '/vehicle-monitoring/historic-route',
                userHasAny: ['playback_of_tracks'],
                value: 'monitoring.track_playback',
            },
            {
                id: 'cam-stream',
                to: '#',
                userHasAny: ['live_camera_stream'],
                value: 'monitoring.cam_stream',
            },
            {
                id: 'eletronic-fence',
                to: '/vehicle-monitoring/electronic-fence',
                userHasAny: ['electronic_fence'],
                value: 'monitoring.eletronic_fence',
            },
        ],
    },
    {
        iconComponent: <IoAnalytics size={24} />,
        id: 'analytics',
        to: '/analytics',
        userHas: 'analytics',
        value: 'menu.analytics',
        subMenu: [
            {
                id: 'overview',
                to: '/analytics/overview',
                userHasAny: ['overview'],
                value: 'menu.overview',
            },
            {
                id: 'period',
                to: '/analytics/period',
                userHasAny: ['period'],
                value: 'general.period',
            },
            {
                to: '/analytics/fleets',
                id: 'fleet',
                value: 'general.fleets',
                userHasAny: ['fleet'],
            },
            {
                to: '/analytics/driver',
                id: 'driver',
                value: 'general.driver',
                userHasAny: ['driver'],
            },
            {
                to: '/analytics/face-missing',
                id: 'face-missing',
                value: 'general.face_missing',
                userHasAny: ['face_missing'],
            },

            {
                to: '/analytics/last-alarm',
                id: 'last-alarm',
                value: 'last_alarm.last_alarm',
                userHasAny: ['last_alarm'],
            },
            {
                to: '/analytics/comparison-of-units',
                id: 'comparison-of-units',
                value: 'comparison_of_units.comparison_of_units',
                userHasAny: ['unit_comparison'],
            },
        ],
    },
    {
        iconComponent: <MdOutlineAssessment size={24} />,
        id: 'smart-report',
        to: '/smart-report',
        userHas: 'smart_report',
        value: 'menu.smart_report',
        subMenu: [
            {
                to: '/smart-report/events',
                id: 'smart-report.events',
                value: 'events.events',
                userHasAny: ['overview_smart_report'],
            },
        ],
    },
    {
        iconComponent: <GiRoad size={24} />,
        id: 'interventions',
        userHas: 'interventions',
        to: '/interventions',
        value: 'menu.interventions',
    },
];
