import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollbar } from 'src/common';
import { Button, CancelButton, Column, Dialog, Divider, Row } from 'src/common/components';
import { toast } from 'src/common/components/toast';

import { useFunctions } from '../../hooks';
import { functionService } from '../../services';
import { DialogContent } from './styles';

function Content() {
    const { deleteDialogRef, deletableFunction, setDeletableFunction, handleRequestList } = useFunctions();
    const { hideScrollBar, showScrollBar } = useScrollbar();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = useCallback(() => {
        showScrollBar();
        setDeletableFunction(null);
        deleteDialogRef?.current?.close();
    }, [deleteDialogRef, setDeletableFunction, showScrollBar]);

    const handleConfirm = useCallback(async () => {
        if (!deletableFunction) return;
        setIsLoading(true);
        try {
            await functionService.delete(deletableFunction.id);
            handleRequestList({
                currentPage: 1,
            });
            toast.success(t('general.success'), '');
            handleClose();
        } catch (e) {
            toast.error(t('general.error'), '');
        } finally {
            setIsLoading(false);
        }
    }, [deletableFunction, handleClose, handleRequestList, t]);

    useEffect(() => {
        hideScrollBar();

        return () => {
            showScrollBar();
            setDeletableFunction(null);
        };
    }, [hideScrollBar, setDeletableFunction, showScrollBar]);

    return (
        <Fragment>
            <Row justify="center" width="100%" align="start">
                <h4>{t('configurations.delete_function')}</h4>
            </Row>
            <Column align="center" gap={16} flex width="100%">
                <Column align="center">
                    <p>
                        {t('configurations.do_you_want_to_delete_the_role')} <b>{JSON.stringify(deletableFunction?.name)}</b>?
                    </p>
                    <p>{t('configurations.this_action')}</p>
                </Column>
                <Divider />
            </Column>
            <Row align="flex-end" gap={16} flex width="100%">
                <CancelButton onClick={handleClose}>{t('general.cancel')}</CancelButton>
                <Button type="reset" color="alert" isLoading={isLoading} onClick={handleConfirm}>
                    {t('configurations.delete')}
                </Button>
            </Row>
        </Fragment>
    );
}

export default function DeleteDialog() {
    const { deleteDialogRef, deletableFunction, setDeletableFunction } = useFunctions();
    const { showScrollBar } = useScrollbar();

    return (
        <Dialog
            onCloseModal={() => {
                showScrollBar();
                setDeletableFunction(null);
            }}
            id="delete-function-dialog"
            ref={deleteDialogRef}
        >
            <DialogContent>{deletableFunction ? <Content /> : <Fragment />}</DialogContent>
        </Dialog>
    );
}
