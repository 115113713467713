import { yup } from 'src/common';

export const createDepartmentFormSchema = yup.object().shape({
    name: yup
        .string()
        .required('field_validations.required')
        .test({
            name: 'is-void',
            message: 'field_validations.required',
            test: value => (value ? value.trim().length > 0 : false),
        }),
    parentName: yup
        .string()
        .required('field_validations.required')
        .test({
            name: 'is-void',
            message: 'field_validations.required',
            test: value => (value ? value.trim().length > 0 : false),
        }),
    imageCapture: yup.boolean(),
});
