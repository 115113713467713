import { Skeleton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineAlignLeft, AiOutlineInfoCircle } from 'react-icons/ai';
import { CgClose } from 'react-icons/cg';

import { Button, CloseButton, Content, EditFill, Input, NoContent, SquareButton, Table, Trash } from '../../../../../common';
import { DptoTableList } from '../../../departments';
import { useInterventionProtocol } from '../../hooks';
import ButtonAddProtocol from '../button-add_protocol';
import {
    ButtonAddProtocolContainer,
    Form,
    InputContainer,
    Intro,
    LabelInterventionProtocol,
    ButtonNewProtocol,
    TableSection,
    TableRow,
    TableData,
    ContainerTab,
} from './styles';

export function InterventionProtocolForm() {
    const { t } = useTranslation();

    const { handleOpenDialog, handleGetList, interventionProtocolByName } = useInterventionProtocol();

    const [detailsPopUp, setDetailsPopUp] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [tabIndex, setTabIndex] = useState(0);

    const [data, setData] = useState([]);
    const [isError] = useState(true);

    const handleRequest = useCallback(() => {
        console.log('TESTE');
    }, []);

    const content: JSX.Element = useMemo(() => {
        if (isLoading) {
            return (
                <Content style={{ marginTop: '1rem' }}>
                    <Skeleton width={'20%'} />
                    <Skeleton height="3rem" style={{ marginTop: '-1rem' }} />
                    <Skeleton width={'20%'} />
                    <Skeleton height="3rem" style={{ marginTop: '-1rem' }} />
                </Content>
            );
        }

        if (isError) {
            return <NoContent message={t('general.error')} onClick={handleRequest} messageClassName="error" />;
        }

        if (data?.length === 0) {
            return <NoContent message={t('general.no_content')} onClick={handleRequest} />;
        }

        return <DptoTableList />;
    }, [data?.length, handleRequest, isError, isLoading, t]);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);

            setData([]);
        }, 1000);
    }, []);

    const tableHeadersPopUp = useMemo(
        () => [
            t('intervention_protocol.event'),
            t('intervention_protocol.amount'),
            t('intervention_protocol.time'),
            t('intervention_protocol.wait'),
            t('intervention_protocol.send_image'),
            t('intervention_protocol.send_video'),
        ],
        [t],
    );

    const changeTab = (index: number) => {
        setTabIndex(index);
    };

    return (
        <Form
            method="POST"
            onSubmit={event => {
                event.preventDefault();
            }}
        >
            <Intro>
                <div>
                    <h2>{tabIndex === 0 ? t('intervention_protocol.view_protocol') : t('intervention_protocol.protocol_details')}</h2>
                </div>

                <div>
                    <SquareButton bgColor={tabIndex === 0 ? '#262D3C' : '#D7D7D7'} onClick={() => changeTab(0)}>
                        <AiOutlineInfoCircle color={tabIndex === 0 ? 'white' : '#262D3C'} fontSize={'20px'} />
                    </SquareButton>
                    <SquareButton bgColor={tabIndex === 1 ? '#262D3C' : '#D7D7D7'} onClick={() => changeTab(1)}>
                        <AiOutlineAlignLeft color={tabIndex === 1 ? 'white' : '#262D3C'} fontSize={'18px'} />
                    </SquareButton>
                    <CloseButton onClick={handleOpenDialog}>
                        <CgClose color="#262D3C" fontSize={'20px'} />
                    </CloseButton>
                </div>
            </Intro>
            {tabIndex === 0 ? (
                <InputContainer>
                    {isLoading ? (
                        <>{content}</>
                    ) : (
                        <>
                            <div>
                                <LabelInterventionProtocol>{t('alarms.operation')}</LabelInterventionProtocol>
                                <Input name="operation" placeholder={interventionProtocolByName?.operationName} disabled />
                            </div>
                            <div>
                                <LabelInterventionProtocol>{t('intervention_protocol.protocol')}</LabelInterventionProtocol>
                                <Input name="protocol" placeholder={interventionProtocolByName?.protocolName} disabled />
                            </div>
                        </>
                    )}
                </InputContainer>
            ) : (
                <ContainerTab>
                    {/* <ButtonAddProtocolContainer>
                        <ButtonAddProtocol />
                    </ButtonAddProtocolContainer> */}

                    <TableSection>
                        <div>
                            {isLoading ? (
                                <>{content}</>
                            ) : (
                                <Table tableHeaders={tableHeadersPopUp} style={{ marginTop: '2rem' }} allowSort={false}>
                                    {interventionProtocolByName?.detailsProtocols.map((data: any, i: number) => {
                                        if (!data) {
                                            return;
                                        }

                                        return (
                                            <TableRow className="body pointer" key={Math.random() * i}>
                                                <TableData>{data.event}</TableData>
                                                <TableData>{data.quantity}</TableData>
                                                <TableData>{data.time}</TableData>
                                                <TableData>{data.wait}</TableData>
                                                <TableData>{data.go_image ? 'Não' : 'Sim'}</TableData>
                                                <TableData>{data.go_video ? 'Não' : 'Sim'}</TableData>
                                                {/* <TableData>
                                                    <EditFill
                                                        color="#48546E"
                                                        style={{ fontSize: '20px', fontWeight: '900', cursor: 'pointer' }}
                                                        onClick={() => changeTab(2)}
                                                    />
                                                </TableData>
                                                <TableData>
                                                    <Trash color="#ff0404" style={{ fontSize: '20px', fontWeight: '900', cursor: 'pointer' }} />
                                                </TableData> */}
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            )}
                        </div>
                    </TableSection>
                </ContainerTab>
            )}

            {/* <ButtonNewProtocol>
                <Button
                    color="outline"
                    size="medium"
                    onClick={() => {
                        setDetailsPopUp(false);
                    }}
                >
                    {t('intervention_protocol.cancel')}
                </Button>
                <Button color="primary" size="medium" onClick={() => setDetailsPopUp(false)}>
                    {t('intervention_protocol.confirm')}
                </Button>
            </ButtonNewProtocol> */}
        </Form>
    );
}
