import moment from 'moment';
import { api } from 'src/common/infra';

import { DriversRequest } from '../dtos/drivers.dtos';

export class DriversIdClusterService {
    public async execute(data: DriversRequest) {
        const { dateFrom, dateTo, events, id } = data;
        const {
            data: { content },
        } = await api.get<any>(`/analytics/drivers/${id}`, {
            params: {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                events,
            },
        });

        return content;
    }

    public async getHistoric(data: DriversRequest) {
        const { dateFrom, dateTo, events, id } = data;
        const {
            data: { content },
        } = await api.get<any>(`/analytics/drivers/historic/${id}`, {
            params: {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                events,
            },
        });

        return content;
    }

}

export const driversIdClusterService = new DriversIdClusterService();
