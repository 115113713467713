import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateHelper } from 'src/common/helpers';

import Chip from '../chip';
import Row from '../row';
import { ClearFilters, Container, Title } from './styles';

interface UsedFiltersProps {
    data: { [key: string]: any };
    onClear(key: string): void;
    onClearAll(): void;
}

function UsedFilters({ data, onClear, onClearAll }: UsedFiltersProps) {
    const { t, i18n } = useTranslation();

    const label = useCallback(
        (value: unknown): string => {
            if (value instanceof Date) {
                return DateHelper.formatSimple(value, i18n.language);
            }

            return String(value);
        },
        [i18n],
    );

    return (
        <Container align="center" gap={16}>
            <Title>{t('general.filters')}:</Title>
            <Row align="center" gap={8}>
                {Object.keys(data)?.map(key => data[key] && <Chip key={key} label={label(data[key])} onClose={() => onClear(key)} />)}
            </Row>
            <ClearFilters type="button" onClick={onClearAll}>
                {t('general.clear_filters')}
            </ClearFilters>
        </Container>
    );
}

export default memo(UsedFilters);
