import {
    cloneElement,
    DialogHTMLAttributes,
    forwardRef,
    ForwardRefRenderFunction,
    HTMLAttributes,
    MouseEvent,
    ReactElement,
    useCallback,
} from 'react';
import { FunctionComponent } from 'src/common/types';

import { Container } from './styles';

export type DialogProps = DialogHTMLAttributes<HTMLDialogElement> & FunctionComponent<ReactElement> & { id: string; onCloseModal?: () => void };

const Base: ForwardRefRenderFunction<HTMLDialogElement, DialogProps> = ({ onCloseModal, ...props }, ref) => {
    const handleClose = useCallback(
        (e: MouseEvent<HTMLDialogElement>) => {
            e.currentTarget.close();
            onCloseModal?.();
        },
        [onCloseModal],
    );

    const handleClickOnContent = useCallback((e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    const handleCancel = () => {
        onCloseModal?.();
    };

    return (
        <Container onCancel={handleCancel} onClick={handleClose} ref={ref} {...props}>
            {cloneElement<HTMLAttributes<HTMLDivElement>>(props.children, {
                onClick: handleClickOnContent,
                role: 'dialog',
            })}
        </Container>
    );
};

const Dialog = forwardRef(Base);

export default Dialog;
