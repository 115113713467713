import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Popover, Typography } from '@mui/material';
import { Checkbox, ListItemText, MenuItem, Select as SelectMaterial, SelectChangeEvent } from '@mui/material';
import { ClipboardEvent, Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Column, HourHelper, Input, Select, TypeSearch } from 'src/common';
import { Pagination } from 'src/common/core/pagination';
import i18n from 'src/common/infra/translations';
import { Reducer, reducer } from 'src/common/types/reducer';
import { FilterNamePropsEvents } from 'src/modules/alarm-processing/components/alarms-container';
import { SortProps } from 'src/modules/alarm-processing/dtos/event.dto';
import { useEvents } from 'src/modules/alarm-processing/hooks/use-events';
import { filterEventsSchema } from 'src/modules/alarm-processing/infra';
import { DepartmentResume, departmentService } from 'src/modules/configurations';
import { vehicleService } from 'src/modules/configurations/vehicles/services/vehicle.service';
import { INITIAL_DEPARTMENT_STATE, INITIAL_VEHICLES_STATE } from 'src/modules/vehicle-monitoring/contexts/const';
import { Vehicle } from 'src/modules/vehicle-monitoring/domain/entities/vehicle';

import { ChatListStatus, InterventionRequestDTO } from '../../dtos/intervention.dto';
import { filterByEventName } from '../../services/interventions-get-events.service';
import { interventionService } from '../../services/interventions.service';
import { Container, Form, SpanStyle } from './styles';

interface InterventionsProps {
    setInterventions: Dispatch<SetStateAction<Pagination<InterventionRequestDTO> | null>>;
    startDate: Date | null;
    endDate: Date | null;
    pageSize: number;
    sortProps: SortProps[];
    operation: string;
    setOperation: Dispatch<SetStateAction<string>>;
    fleet: string;
    setFleet: Dispatch<SetStateAction<string>>;
    names: FilterNamePropsEvents[];
    setNames: Dispatch<SetStateAction<FilterNamePropsEvents[]>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setHours: Dispatch<SetStateAction<{ startHour: string; endHour: string }>>;
    chatListStatus: ChatListStatus[];
}

export default function InterventionFilter({
    setInterventions,
    startDate,
    endDate,
    pageSize,
    operation,
    fleet,
    names,
    setNames,
    sortProps,
    setFleet,
    setOperation,
    setLoading,
    setHours,
    chatListStatus
}: InterventionsProps) {
    const { t } = useTranslation();

    const filterEventsSchemaMemo = useMemo(() => filterEventsSchema(t), [t]);
    const { requestData } = useEvents();
    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_DEPARTMENT_STATE);
    const [vehicles, setVehicles] = useReducer<Reducer<Array<Vehicle>>>(reducer, INITIAL_VEHICLES_STATE);

    const [alarmsInPtbr, setAlarmsInPtbr] = useState(['']);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [componentWidth, setComponentWidth] = useState<number>(0);
    const open = Boolean(anchorEl);

    const componentRef = useRef<HTMLDivElement>(null); // This will hold a reference to the DOM node
    const selectedLanguage = useCallback(() => {
        switch (i18n.language) {
            case 'es':
                return 'es';
            case 'pt-BR':
            case 'pt':
                return 'pt_br';
            case 'it':
                return 'it';
            case 'fr':
                return 'fr';
            default:
                return 'en';
        }
    }, []);

    const filterByAlarmName = useCallback(async () => {
        const response = await filterByEventName.execute();
        const responseMap: FilterNamePropsEvents[] = response.map((obj: any) => {
            return {
                language: obj[selectedLanguage()],
                languagePtbr: obj.pt_br,
                real_name: obj.real_name,
                checked: false,
            };
        });
        setNames(responseMap);
    }, [selectedLanguage, setNames]);

    const situationOptions = useMemo(() => {
        return [
            {
                value: 'Todos',
                label: t('interventions.all'),
            },
            {
                value: 'Pendente',
                label: t('interventions.pending'),
            },
            {
                value: 'Em tratativa',
                label: t('interventions.in_negotiation'),
            },
            {
                value: 'Fechado',
                label: t('interventions.closed'),
            },
        ];
    }, [t]);

    useEffect(
        () => () => {
            setFleet('');
            setOperation('');
            setNames([]);
        },
        [setFleet, setNames, setOperation],
    );

    useEffect(() => {
        filterByAlarmName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByAlarmName, i18n.language]);

    const handleResize = () => {
        if (componentRef.current) {
            const width: number = componentRef.current.clientWidth;
            setComponentWidth(width);
        }
    };

    useEffect(() => {
        handleResize(); // Get the initial width on mount

        window.addEventListener('resize', handleResize); // Add event listener for resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on unmount
        };
    }, [componentRef.current?.clientWidth]);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (names.filter(name => name.checked).length > 0) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const selectedValues = event.target.value as string[];
        const updatedOptions: FilterNamePropsEvents[] | undefined = names.map(name => ({
            ...name,
            checked: selectedValues.includes(name.language),
        }));
        setNames(updatedOptions);

        showSelectedEvents(updatedOptions);
    };

    const showSelectedEvents = useCallback((updatedOptions: FilterNamePropsEvents[]) => {
        const resultArray: string[] = [];

        updatedOptions.forEach(inputItem => {
            if (inputItem.checked) {
                resultArray.push(inputItem.language);
            }
        });

        setAlarmsInPtbr(resultArray);
    }, []);

    useEffect(() => {
        showSelectedEvents(names);
    }, [names, showSelectedEvents]);

    const {
        setValue,
        register,
        watch,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<InterventionRequestDTO>({
        defaultValues: requestData,
        resolver: yupResolver(filterEventsSchemaMemo),
        mode: 'onChange',
    });

    const fleetForm = watch('board');
    const operationForm = watch('operation');

    useEffect(() => {
        setOperation(operationForm);
        setFleet(fleetForm);
    }, [fleet, fleetForm, operation, operationForm, setFleet, setOperation]);

    const onSubmit = useCallback(
        async (e: any) => {

            const { startHour, endHour, status } = e;
            setHours({ startHour, endHour });

            try {
                setLoading(true);
                const response = await interventionService.execute({
                    dateFrom: startDate,
                    dateTo: endDate,
                    currentPage: 1,
                    pageSize,
                    sortProps: sortProps,
                    operation: operation,
                    fleet: fleet,
                    events: names?.filter(name => name.checked).map(obj => obj.languagePtbr),
                    startHour,
                    endHour,
                    chatListStatus,
                    status
                });

                setInterventions(response);
            } catch (error: any) {
                setInterventions(null);
            } finally {
                setLoading(false);
            }
        },
        [setHours, setLoading, startDate, endDate, pageSize, sortProps, operation, fleet, names, chatListStatus, setInterventions],
    );

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        setValue('board', '');

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 15,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations, setValue]);

    useEffect(() => {
        const controller = new AbortController();

        setVehicles({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            vehicleService
                .list({
                    currentPage: 1,
                    pageSize: 15,
                    fleet,
                    typeSearch: TypeSearch.PARAMS,
                    operation,
                    signal: controller.signal,
                })
                .then(res =>
                    setVehicles({
                        type: 'data',
                        payload: res.elements as Array<Vehicle>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [fleet, operation, setValue, setVehicles]);

    const handleInputHour = useCallback((e: FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;

        const newValue = HourHelper.mask(value?.substring(0, 5));

        e.currentTarget.value = newValue;
    }, []);

    const handlePreventPaste = useCallback((event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
    }, []);

    return (
        <>
            <Divider />
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Column
                        className="select-container"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        onClick={handlePopoverClose}
                        style={{ width: componentWidth }}
                    >
                        <SpanStyle> {t('alarms.event')}</SpanStyle>
                        <SelectMaterial
                            id="select-alarm"
                            multiple
                            displayEmpty={true}
                            style={{ height: '2.25rem', background: 'white', borderRadius: '0.5rem' }}
                            sx={{
                                '&:hover': {
                                    '&& fieldset': {
                                        borderColor: '#3e66fb',
                                    },
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#3e66fb',
                                },
                                fontFamily: 'Open Sans',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '20px',
                                color: '#000000',
                                '& .MuiSelect-select .notranslate::after': t('alarms.event')
                                    ? {
                                          content: `"${t('alarms.event')}"`,
                                          opacity: 0.42,
                                      }
                                    : {},
                            }}
                            value={names?.filter(name => name.checked).map(name => name.language)}
                            onChange={handleChange}
                            renderValue={() => {
                                return alarmsInPtbr.join(', ');
                            }}
                        >
                            {names?.map(name => {
                                if (!name) return null;
                                return (
                                    <MenuItem key={name.language} value={name.language} property={name.language} placeholder={name.language}>
                                        <Checkbox checked={name.checked} />
                                        <ListItemText primary={name.language} />
                                    </MenuItem>
                                );
                            })}
                        </SelectMaterial>
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                                width: '70%',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Typography sx={{ p: 1 }}>
                                {names
                                    ?.filter(name => name.checked)
                                    .map(name => name.language)
                                    .join(', ')}
                            </Typography>
                        </Popover>
                    </Column>
                    <Autocomplete
                        isLoading={operations.isLoading}
                        options={operations.data.map(operation => ({ ...operation, value: operation.name }))}
                        setValue={setValue}
                        label={String(t('alarms.operation'))}
                        placeholder={String(t('alarms.operation'))}
                        {...register('operation')}
                        feedback={{
                            message: errors['operation']?.message?.toString(),
                        }}
                        nextElement={{
                            id: 'board',
                        }}
                    />
                    <Autocomplete
                        setValue={setValue}
                        label={String(t('alarms.fleet'))}
                        placeholder={String(t('alarms.fleet'))}
                        isLoading={vehicles.isLoading}
                        options={vehicles.data.map(vehicle => ({
                            id: vehicle.id,
                            value: vehicle.fleet,
                        }))}
                        {...register('board')}
                        feedback={{
                            message: errors['board']?.message?.toString(),
                        }}
                    />
                    <Input
                        autoComplete="off"
                        maxLength={5}
                        type="text"
                        label={String(t('general.initial_hour'))}
                        placeholder={String(t('general.initial_hour'))}
                        onInput={handleInputHour}
                        onPaste={handlePreventPaste}
                        {...register('startHour')}
                        feedback={{
                            message: errors['startHour']?.message?.toString(),
                        }}
                    />
                    <Input
                        autoComplete="off"
                        maxLength={5}
                        type="text"
                        label={String(t('general.final_hour'))}
                        placeholder={String(t('general.final_hour'))}
                        onInput={handleInputHour}
                        onPaste={handlePreventPaste}
                        {...register('endHour')}
                        feedback={{
                            message: errors['endHour']?.message?.toString(),
                        }}
                    />
                    <Select
                        label={String('Status')}
                        placeholder={String('Status')}
                        options={situationOptions}
                        {...register('status')}
                    />
                    <Column justify="flex-end" height="60px" ref={componentRef}>
                        <Column justify="flex-end" height="36px">
                            <Button type="submit" size="small" disabled={ !isValid}>
                                {t('general.apply')}
                            </Button>
                        </Column>
                    </Column>
                </Form>
            </Container>
        </>
    );
}
