import { AxiosResponse } from 'axios';
import { Service } from 'src/common/core';
import { BadRequestError } from 'src/common/errors/bad-request';
import { InternalServerError } from 'src/common/errors/internal-server-error';

import { LastOnlineData } from '../dtos/last-online-data';
import { argusAPI } from '../infra/http';

interface GetLastOnlineDataRequest {
    controller: AbortController;
    fleet: string;
}

export interface GetLastOnlineDataResponse {
    code: number;
    data: Array<LastOnlineData>;
    message: string;
    success?: boolean;
}

export class GetLastOnlineDataService implements Service<GetLastOnlineDataRequest, LastOnlineData | null> {
    public async execute(request: GetLastOnlineDataRequest): Promise<LastOnlineData | null> {
        const { controller, fleet } = request;

        const { data: response } = await argusAPI.get<void, AxiosResponse<GetLastOnlineDataResponse>>('/web-api/api/alarm/getLastOnlineData', {
            signal: controller.signal,
        });

        const { data, message } = response;

        if (message === '未知异常') {
            throw new InternalServerError();
        }

        if (!data) {
            throw new BadRequestError();
        }

        const lastFleetOnlineData = data.find(item => item.plateNo === fleet);

        if (!lastFleetOnlineData) {
            return null;
        }

        const { plateNo, sendTime: chineseDateTime } = lastFleetOnlineData;

        return {
            plateNo,
            sendTime: new Date(`${chineseDateTime} GMT+0800`)?.toLocaleString(),
        };
    }
}

export const getLastOnlineData = new GetLastOnlineDataService();
