import { Fragment, MouseEventHandler, memo } from 'react';

import { ColorBox } from '../styles';

export const suggestedColors = ['#ff6000', '#ffa500', '#008000', '#ffff00', '#add8e6', '#0000ff', '#800080'];

interface SuggestedColorsProps {
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLInputElement>;
    readonly?: boolean;
}

export default memo(function SuggestedColors({ onClick, ...props }: SuggestedColorsProps): JSX.Element {
    return (
        <Fragment>
            {suggestedColors.map(color => (
                <ColorBox color={color} key={color} name={color} onClick={onClick} {...props} />
            ))}
        </Fragment>
    );
});
