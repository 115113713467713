import { ApiResponse } from 'src/common/core/api-response';
import { Service } from 'src/common/core/service';
import { api } from 'src/common/infra/http';

import { ListEventOptionsByEventNamesDTO } from '../dtos/event.dto';

class ListEventOptionsByEventNamesService implements Service<Array<string>, ListEventOptionsByEventNamesDTO> {
    public async execute(eventNames: string[]): Promise<ListEventOptionsByEventNamesDTO> {
        const {
            data: { content },
        } = await api.get<ApiResponse<ListEventOptionsByEventNamesDTO>>('/alarms-processing/event-options-by-event-names', {
            params: {
                eventNames,
            },
        });

        return content;
    }
}

export const listEventsOptions = new ListEventOptionsByEventNamesService();
