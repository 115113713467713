import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Column, Divider, Field, Image, Row } from 'src/common/components';
import { DateHelper } from 'src/common/helpers';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';

import CloseRound from '../../close-round';
import { ContainerContainer, Content, EventName, Modal, StyledImage, StyledVideoPlayer, Title } from './styles';

interface EventModalProps {
    event: EventDTO;
    onClose(): void;
    open: boolean;
}

function Carousel({ event }: Pick<EventModalProps, 'event'>) {
    const [selectedTab, setSelectedTab] = useState(true);

    return (
        <ContainerContainer>
            <StyledImage className="fade" selected={selectedTab}>
                <Image src={event.photoUrl || undefined} alt={event?.alarm || 'Generic image'} height={190} width={328} />
            </StyledImage>
            {event?.videoUrl ? (
                <StyledVideoPlayer source={event?.videoUrl} type="video/mp4" selected={!selectedTab} />
            ) : (
                <StyledImage className="fade" selected={!selectedTab}>
                    <Image alt="" height={190} width={328} />
                </StyledImage>
            )}
            <a className="prev" onClick={() => setSelectedTab(state => !state)}>
                <MdChevronLeft size={24} color="#46526C" />
            </a>
            <a className="next" onClick={() => setSelectedTab(state => !state)}>
                <MdChevronRight size={24} color="#46526C" />
            </a>
        </ContainerContainer>
    );
}



export default function EventModal({ event, onClose, open }: EventModalProps) {
    const { t, i18n } = useTranslation();

    return (
        <Modal open={open} onClose={onClose}>
            <Content>
                <Column align="flex-start" gap="1.5rem">
                    <Column align="flex-start" gap="1rem">
                        <Row align="flex-start" justify="space-between" gap="3rem" flex width="100%">
                            <Title>{t('alarms.event')}</Title>
                            <CloseRound onClose={onClose} />
                        </Row>
                        <Row align="flex-start" gap="1rem">
                            <Carousel event={event} />
                            <Column gap={5} align="flex-start" width="328px">
                                <EventName>{event?.alarm}</EventName>
                                <Column gap={8} align="flex-start">
                                    <Field name={t('alarms.operation')} value={event?.operation || t('general.information_unavailable')} />
                                    <Field name={t('alarms.fleet')} value={event?.fleet || t('general.information_unavailable')} />
                                    <Field
                                        name={t('alarms.alarm_time')}
                                        value={
                                            event?.alarmTime ? DateHelper.intl(event.alarmTime, i18n.language) : t('general.information_unavailable')
                                        }
                                    />
                                    <Field
                                        name={t('alarms.velocity')}
                                        value={event?.speedInKmH ? event.speedInKmH : t('general.information_unavailable')}
                                    />
                                    <Field name={t('monitoring.lat')} value={event.latitude?.toString()} />
                                    <Field name={t('monitoring.long')} value={event.longitude?.toString()} />
                                </Column>
                            </Column>
                        </Row>
                    </Column>
                    <Divider />
                </Column>
            </Content>
        </Modal>
    );
}
