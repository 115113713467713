import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from './styles';

interface BackButtonProps {
    onClick?(): void;
}

export default function BackButton({ onClick }: BackButtonProps) {
    const back = useNavigate();

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
            return;
        }
        back(-1);
    }, [back, onClick]);

    return (
        <Button type="button" onClick={handleClick}>
            <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 5.23077L1.30484 5.94963L0.561478 5.23077L1.30484 4.51191L2 5.23077ZM17 11C17 11.5523 16.5523 12 16 12C15.4477 12 15 11.5523 15 11L17 11ZM5.67984 10.1804L1.30484 5.94963L2.69516 4.51191L7.07016 8.74268L5.67984 10.1804ZM1.30484 4.51191L5.67984 0.281144L7.07016 1.71886L2.69516 5.94963L1.30484 4.51191ZM2 4.23077L10.2308 4.23077L10.2308 6.23077L2 6.23077L2 4.23077ZM10.2308 4.23077C13.9693 4.23077 17 7.26146 17 11L15 11C15 8.36603 12.8647 6.23077 10.2308 6.23077L10.2308 4.23077Z"
                    fill="#6DA4D3"
                />
            </svg>
        </Button>
    );
}
