import { useEffect, useMemo, useState } from 'react';

interface CoordinatesProps {
    latitude: number;
    longitude: number;
}

export interface UseLocationResponseProps {
    coordinates: CoordinatesProps | undefined;
}

export function usePosition(): UseLocationResponseProps {
    const [coordinates, setCoordinates] = useState<CoordinatesProps | undefined>();

    useEffect(() => {
        const userLocation = navigator.geolocation;

        function success(position: GeolocationPosition) {
            setCoordinates({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        }

        if (userLocation) {
            userLocation.getCurrentPosition(success);
        } else {
            throw 'The geolocation API is not supported by your browser.';
        }
    }, []);

    return useMemo(() => ({ coordinates }), [coordinates]);
}
