import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { ApiResponse, api } from 'src/common';
import { BadRequestError } from 'src/common/errors/bad-request';
import { env } from 'src/config/env';
import Cookies from 'universal-cookie';

let isRefreshing = false;
let failedRequestsQueue: any[] = [];

const argusAPI = axios.create({
    baseURL: env.ARGUS_API_URL,
});

argusAPI.interceptors.request.use(async (request: InternalAxiosRequestConfig) => {
    const cookies = new Cookies();

    const token: string = cookies.get('p-argus-token');

    request.params = { ...(request?.params || {}), token };

    return request;
});

argusAPI.interceptors.response.use(async response => {
    if (response.data?.code === 2 && response.data.message === '会话不存在') {
        const originalConfig = response.config;

        if (!isRefreshing) {
            isRefreshing = true;

            const cookies = new Cookies();
            const {
                data: { content },
            } = await api.get<ApiResponse<string>>('/vehicle-monitoring/auth-in-p-argus');

            isRefreshing = false;

            if (!content) {
                failedRequestsQueue.forEach(request => request.reject('INVALID_CREDENTIALS'));

                failedRequestsQueue = [];

                throw new BadRequestError('INVALID_CREDENTIALS');
            }

            cookies.set('p-argus-token', response.data.token, { path: '/', secure: true });
            argusAPI.defaults.params = { ...(argusAPI.defaults.params || {}), token: response.data.token };

            failedRequestsQueue?.forEach(request => request.resolve(response.data.token));

            failedRequestsQueue = [];
        }

        return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
                resolve: (token: string) => {
                    if (originalConfig) {
                        originalConfig.params = { ...(originalConfig || {}), token };

                        resolve(argusAPI(originalConfig));
                    }
                },
                reject: (error: AxiosError) => {
                    reject(error);
                },
            });
        });
    }

    return response;
});

export { argusAPI };
