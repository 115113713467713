import * as Mui from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import CommonProviders from 'src/common/contexts';

import { AuthProvider } from './modules/auth/contexts';

export type ProvidersProps = {
    muiTheme: Mui.Theme;
};

export default function Providers({ muiTheme }: ProvidersProps) {
    return (
        <Mui.ThemeProvider theme={muiTheme}>
            <AuthProvider>
                <CommonProviders>
                    <Outlet />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </CommonProviders>
            </AuthProvider>
        </Mui.ThemeProvider>
    );
}
