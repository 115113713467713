import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'src/common/components/toast';

import { listElectronicFence } from '../services/list-electronic-fence.service';
import { useElectronicFence } from './use-electronic-fence';

export function useListElectronicFence() {
    const { setElectronicFences } = useElectronicFence();
    const { t, i18n } = useTranslation();

    return useMutation({
        mutationFn: listElectronicFence.execute,
        mutationKey: ['list-electronic-fences'],
        onError: (error: unknown) => {
            if (error instanceof AxiosError) {
                return toast.error(
                    t('general.message.error.title'),
                    i18n.exists(error.response?.data?.message) ? t(error.response?.data?.message) : t('general.message.error.message'),
                );
            }
            toast.error(t('general.message.error.title'), t('general.message.error.message'));
        },
        onSuccess: setElectronicFences,
    });
}
