import { useCallback, useMemo } from 'react';
import { Costumer, useAuth } from 'src/modules/auth';

import { TokenHelper } from '../helpers';

interface UsePermissionProps {
    decodedToken: TokenPayload | undefined;
    has: (role: string) => boolean;
    hasAny: (...roles: string[]) => boolean;
    hasEvery: (...roles: string[]) => boolean;
    hasTelemetry: (telemetry: string) => boolean;
    isAdmin: boolean;
}

interface UserProps {
    isAdmin: boolean;
}

interface TokenPayload {
    iat: number;
    exp: number;
    sub: string;
    userRoles: string[];
    costumers: Costumer[];
    user: UserProps;
}

export function usePermission(): UsePermissionProps {
    const { session } = useAuth();

    const decodedToken = useMemo(() => (session?.token ? TokenHelper.decode<TokenPayload>(session?.token) : undefined), [session?.token]);

    const has = useCallback(
        (role: string) => {
            return decodedToken?.userRoles.includes(role) || false;
        },
        [decodedToken?.userRoles],
    );

    const hasAny = useCallback(
        (...roles: string[]) => {
            return decodedToken?.userRoles.some(r => roles.includes(r)) || false;
        },
        [decodedToken?.userRoles],
    );

    const hasEvery = useCallback(
        (...roles: string[]) => {
            return decodedToken?.userRoles.every(r => roles.includes(r)) || false;
        },
        [decodedToken?.userRoles],
    );

    const hasTelemetry = useCallback(
        (telemetry: string) => {
            const listCostumers = session?.costumers || [];

            if (listCostumers.length === 0) {
                return false;
            }

            const hasTheOne = listCostumers.filter(costumer => costumer.telemetry === telemetry);

            return hasTheOne.length > 0;
        },
        [session?.costumers],
    );

    const isAdmin = useMemo<boolean>(() => decodedToken?.user?.isAdmin || false, [decodedToken?.user?.isAdmin]);

    return useMemo<UsePermissionProps>(
        () => ({ decodedToken, has, hasAny, hasEvery, hasTelemetry, isAdmin }),
        [decodedToken, has, hasAny, hasEvery, hasTelemetry, isAdmin],
    );
}
