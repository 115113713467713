import { ApiResponse } from 'src/common/core/api-response';
import { Pagination } from 'src/common/core/pagination';
import { TransformRequestHelper } from 'src/common/helpers/transform-request.helper';
import { api } from 'src/common/infra';
import { User } from 'src/modules/auth/contexts';

import { HierarchyOperation } from '../../departments/dtos/hierarchy.dto';
import { CreateUserRequestDTO, ListUsersDTO, UpdateUserRequestDTO, UserExistsByEmailDTO } from '../dtos';

export class UserService {
    public async create(request: CreateUserRequestDTO): Promise<void> {
        const { signal, ...data } = request;

        await api.post<ApiResponse<void>>('/users', data, {
            signal,
        });
    }

    public async delete(id: number): Promise<void> {
        await api.delete<ApiResponse<void>>(`/users/${id}`);
    }

    public async deleteUsersInBulk(uuids: Array<string>): Promise<void> {
        await api.delete<ApiResponse<void>>('/users-bulk', {
            data: { uuids },
        });
    }

    public async existsByEmail(data: UserExistsByEmailDTO): Promise<boolean> {
        const {
            data: {
                content: { exists },
            },
        } = await api.get<ApiResponse<{ exists: boolean }>>('/users/exists-by-email', {
            params: data,
        });

        return exists;
    }

    public async getOperations(email: string, uuid: string): Promise<Array<HierarchyOperation>> {
        const {
            data: { content },
        } = await api.get<ApiResponse<Array<HierarchyOperation>>>(`/users/info/${email}/${uuid}`);

        return content;
    }

    public async inactivate(id: number, uuid: string): Promise<void> {
        await api.patch<ApiResponse<void>>(`/users/inactivate/${id}`, { uuid });
    }

    public async list(data: ListUsersDTO): Promise<Pagination<User>> {
        const { currentPage, pageSize, enabled, text, typeSearch, creator, email, function: fun, name } = data;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<User>>>('/users', {
            params: {
                creator,
                email,
                enabled,
                function: fun,
                name,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
                text,
                typeSearch,
            },
        });

        return content;
    }

    public async redefinePassword(id: number): Promise<void> {
        await api.patch(`/users/redefine-password/${id}`);
    }

    public async update(request: UpdateUserRequestDTO): Promise<void> {
        const { id, signal, ...data } = request;
        await api.put<ApiResponse<void>>(`/users/${id}`, data, {
            signal,
        });
    }
}

export const userService = new UserService();
