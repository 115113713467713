interface Model {
    id: string | number;
}

export class CheckboxHelper {
    public static allOnThisPageAreSelected<T extends Model>(values: Array<T> | undefined, selectedValues: Array<T>): boolean {
        return values?.length ? values?.every(value => selectedValues.findIndex(dt => value && dt.id === value.id) !== -1) : false;
    }

    public static select<T extends Model>(data: T, state: Array<T>): Array<T> {
        const index = state.findIndex(event => event.id === data.id);

        if (index === -1) {
            return [...state, data];
        }

        return state.filter(event => event.id !== data.id);
    }

    public static selectAll<T extends Model>(data: Array<T>, state: Array<T>): Array<T> {
        const all = state.length > 0 && data?.every(value => state.findIndex(dt => dt.id === value.id) !== -1);

        if (all) {
            return [];
        }

        return [...state, ...data].filter((elem, index, self) => index === self.indexOf(elem));
    }
}
