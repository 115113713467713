import styled from 'styled-components';

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    cursor: default;

    .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: #7b7b7b;

        transform: rotate(-0.16deg);
    }

    .value {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #262d3c;
        word-break: break-all;
    }
`;
