import moment from 'moment';

import { ApiResponse, Service, TransformRequestHelper, api } from '../../../common';
import { Pagination } from '../../../common/core/pagination';
import { InterventionDTO, InterventionRequestDTO  } from '../dtos/intervention.dto';

class InterventionService implements Service<InterventionDTO, Pagination<InterventionRequestDTO>> {
    public async execute({ dateFrom, dateTo, currentPage, pageSize, sortProps, operation, fleet, events, textSearch, startHour, endHour, chatListStatus, status }: InterventionDTO) {
        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<InterventionRequestDTO>>>('/interventions', {
            params: {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
                sortProps,
                operation,
                fleet,
                events,
                textSearch,
                startHour,
                endHour,
                chatListStatus,
                status
            },
        });

        return content;
    }
}

export const interventionService = new InterventionService();
