import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableData, TableRow } from 'src/common/components/table/styles';

import { DateHelper, Table } from '../../../../../common';
import { useLastAlarm } from '../../hook';

function TableList() {
    const { i18n, t } = useTranslation();

    const { listLastAlarmDetails } = useLastAlarm();

    const tableHeaders = useMemo(
        () => [
            t('last_alarm.operation'),
            t('last_alarm.fleet'),
            t('last_alarm.last_communication'),
            t('last_alarm.last_alarm'),
            t('last_alarm.days_since_the_last_communication'),
            t('last_alarm.days_since_the_last_alarm'),
            t('last_alarm.status'),
        ],
        [t],
    );

    return (
        <Fragment>
            <Table tableHeaders={tableHeaders}>
                {listLastAlarmDetails?.elements.map((data, index) => (
                    <TableRow className="body" key={index}>
                        <TableData style={{ width: '30%' }}>{data.operation}</TableData>
                        <TableData style={{ width: '10%' }}>{data.fleet}</TableData>
                        <TableData>{DateHelper.format(data?.lastCommunicationTime, i18n.language)}</TableData>
                        <TableData>{DateHelper.format(data?.lastAlarmTime, i18n.language)}</TableData>
                        <TableData>{data.daysSinceLastCommunication}</TableData>
                        <TableData>{data.daysSinceLastAlarm}</TableData>
                        <TableData>{data.status}</TableData>
                    </TableRow>
                ))}
            </Table>
        </Fragment>
    );
}

export default memo(TableList);
