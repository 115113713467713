import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateHelper } from 'src/common';
import { Table } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';

import { RealTimeLocation } from '../../domain/entities/real-time-location';

interface TableProps {
    data: Array<RealTimeLocation>;
}

export default memo(function RealTimeLocationTable({ data }: TableProps) {
    const { t } = useTranslation();

    const tableHeaders: Array<string> = [
        t('alarms.fleet'),
        t('monitoring.position'),
        t('alarms.velocity'),
        t('monitoring.direction'),
        t('monitoring.filiation'),
        t('monitoring.lat'),
        t('monitoring.long'),
        t('monitoring.status'),
        t('monitoring.altitude'),
    ];

    return (
        <Table tableHeaders={tableHeaders} style={{ marginBottom: '1rem' }}>
            {data?.map(item => {
                return (
                    <TableRow className="body" key={item.id}>
                        <TableData>{item.licensePlate}</TableData>
                        <TableData>{DateHelper.toDateString(item.positioningTime)}</TableData>
                        <TableData>{item.currentSpeed + 'km/h'}</TableData>
                        <TableData>{t(`monitoring.compass_points.${item.direction.toLowerCase()}`)}</TableData>
                        <TableData>{item.operationName}</TableData>
                        <TableData>{item.coordinates.latitude}</TableData>
                        <TableData>{item.coordinates.longitude}</TableData>
                        <TableData>{item.online ? 'ONLINE' : 'OFFLINE'}</TableData>
                        <TableData>{item.height}</TableData>
                    </TableRow>
                );
            })}
        </Table>
    );
});
