import { forwardRef, InputHTMLAttributes, MouseEvent } from 'react';

import { Container } from './styles';

interface ToggleSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    marginTop?: string;
    name: string;
    mode: 'normal' | 'small' | 'underline';
}

export const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(function Base(
    { label, marginTop, mode, name, ...rest },
    ref,
): JSX.Element {
    const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
        e.currentTarget.querySelector('input')?.click();
    };

    return (
        <Container mode={mode} onClick={handleOnClick} marginTop={marginTop} className={rest?.className || ''}>
            <span>{label}</span>
            <label htmlFor={name}>
                <input type="checkbox" name={name} onClick={e => e.stopPropagation()} ref={ref} {...rest} />
            </label>
        </Container>
    );
});

ToggleSwitch.defaultProps = {
    marginTop: undefined,
};
