import { useMutation } from '@tanstack/react-query';

import { FirstAccessDTO } from '../dtos/auth.dto';
import { authService } from '../factories';

interface UseFirstAccessProps {
    onError: (error: unknown) => void;
    onSuccess: (data: void, variables: FirstAccessDTO) => void;
}

export function useFirstAccess({ onError, onSuccess }: UseFirstAccessProps) {
    return useMutation({
        mutationFn: authService.firstAccess,
        mutationKey: ['first-access'],
        onError,
        onSuccess,
    });
}
