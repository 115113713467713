import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterButton, FunctionComponent } from 'src/common';
import { CardHeader, Column, Divider, MainCard, Radio, Row } from 'src/common/components';
import RenderIf from 'src/common/components/render-if';

import { useRealTimeLocation } from '../../hooks';
import RealTimeLocationFilter from '../real-time-location-filter';

export default function Container({ children }: FunctionComponent) {
    const { t } = useTranslation();

    const { filterIsOpen, setFilterIsOpen, filter, setFilter } = useRealTimeLocation();

    const handleChangeStatus: ChangeEventHandler<HTMLInputElement> = e => {
        setFilter(state => ({ ...state, status: e.target.value as 'all' | 'online' | 'offline' }));
    };

    return (
        <MainCard>
            <Column width="100%" gap={16}>
                <CardHeader>
                    <Row gap={16} align="center">
                        <h3>{t('monitoring.real_time')}</h3>
                    </Row>

                    <Row align="center" gap={32}>
                        <Row align="center" gap={16}>
                            <Radio
                                name="status"
                                value="all"
                                label={t('general.all')?.toString()}
                                checked={filter.status === 'all'}
                                onChange={handleChangeStatus}
                            />
                            <Radio
                                name="status"
                                value="online"
                                label={t('general.online')?.toString()}
                                checked={filter.status === 'online'}
                                onChange={handleChangeStatus}
                            />
                            <Radio
                                name="status"
                                value="offline"
                                label={t('general.offline')?.toString()}
                                checked={filter.status === 'offline'}
                                onChange={handleChangeStatus}
                            />
                        </Row>
                        <Row align="center" gap={8}>
                            <FilterButton isOpen={filterIsOpen} onOpen={setFilterIsOpen} />
                        </Row>
                    </Row>
                </CardHeader>
                <Divider />
                <RenderIf condition={filterIsOpen}>
                    <RealTimeLocationFilter />
                </RenderIf>
                {children}
            </Column>
        </MainCard>
    );
}
