import { forwardRef } from 'react';
import { FunctionComponent } from 'src/common/types/react';


import { FlexBox, FlexBoxProps } from '../flex-box';

type ColumnProps = Omit<FlexBoxProps, 'direction'> & FunctionComponent;

export default forwardRef<HTMLDivElement, ColumnProps>(function Column({ children, ...props }, ref) {
    return (
        <FlexBox direction="column" ref={ref} {...props}>
            {children}
        </FlexBox>
    );
})
