import authBackground from 'src/assets/images/auth-bg.png';
import styled from 'styled-components';

export const BackgroundContainer = styled.main`
    height: 100vh;
    mix-blend-mode: multiply;
    opacity: 0.98;
    position: relative;
    min-height: 100%;
`;

export const BackgroundImage = styled.div`
    position: absolute;
    background-image: url(${authBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    mix-blend-mode: overlay;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

export const BackgroundColor = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: -2px;
    z-index: -2;
    background: #262d3c;
    mix-blend-mode: multiply;
`;
