import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { FunctionComponent } from 'src/common';
import { Pagination } from 'src/common/core/pagination';

import { Department, ListDepartmentsDTO } from '../dtos';
import { CreateDptoContactDTO } from '../dtos/create-dpto-contact.dto';
import { CreateOperationWithContactsDTO } from '../dtos/create-operation-with-contacts.dto';
import { DepartmentsContextProps } from './types';

export const DepartmentsContext = createContext({} as DepartmentsContextProps);

export default function DepartmentsProvider({ children }: FunctionComponent) {
    const deleteModalRef = useRef<HTMLDialogElement>(null);
    const deleteInBulkDialogRef = useRef<HTMLDialogElement>(null);
    const dptoDialogRef = useRef<HTMLDialogElement>(null);

    const [contacts, setContacts] = useState<CreateDptoContactDTO[]>([]);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [edittingId, setEdittingId] = useState<number | null>(null);
    const [initialFormData, setInitialFormData] = useState<CreateOperationWithContactsDTO>({
        name: '',
        contacts: [],
        imageCapture: false,
        parentName: '',
    });
    const [operations, setOperations] = useState<Pagination<Department> | undefined>(undefined);
    const [request, setRequest] = useState<ListDepartmentsDTO>({
        active: true,
        currentPage: 1,
        pageSize: 15,
    });
    const [selectedOperations, setSelectedOperations] = useState<Department[]>([]);

    const allOnThisPageAreSelected: boolean = useMemo(
        () =>
            operations?.elements && operations?.elements?.length > 0
                ? operations.elements?.every(value => selectedOperations.findIndex(dt => value && dt.id === value.id) !== -1)
                : false,
        [selectedOperations, operations?.elements],
    );

    const handleOpenDialog = useCallback((data?: Department | null) => {
        const initialData: CreateOperationWithContactsDTO = data
            ? {
                  name: data.name,
                  contacts: [],
                  imageCapture: data.imageCapture,
                  parentName: data.parentName || '',
              }
            : {
                  name: '',
                  contacts: [],
                  imageCapture: false,
                  parentName: '',
              };

        setDialogIsOpen(state => {
            setContacts([]);
            if (state) {
                dptoDialogRef?.current?.close();
                setInitialFormData({
                    name: '',
                    contacts: [],
                    imageCapture: false,
                    parentName: '',
                });
                setEdittingId(null);
            } else {
                setInitialFormData(initialData);
                dptoDialogRef?.current?.showModal();
                setEdittingId(data?.id || null);
            }

            return !state;
        });
    }, []);

    const handleSelectAll = useCallback((data: Array<Department>) => {
        setSelectedOperations(state => {
            const all = state.length > 0 && data?.every(value => state.findIndex(dt => dt.id === value.id) !== -1);

            if (all) {
                return [];
            }

            return [...state, ...data].filter((elem, index, self) => index === self.indexOf(elem));
        });
    }, []);

    const handleSelect = useCallback((data: Department) => {
        setSelectedOperations(state => {
            const index = state.findIndex(event => event.id === data.id);

            if (index === -1) {
                return [...state, data];
            }

            return state.filter(event => event.id !== data.id);
        });
    }, []);

    const data: DepartmentsContextProps = useMemo<DepartmentsContextProps>(
        () => ({
            allOnThisPageAreSelected,
            contacts,
            deleteInBulkDialogRef,
            deleteModalRef,
            dialogIsOpen,
            dptoDialogRef,
            edittingId,
            handleOpenDialog,
            handleSelect,
            handleSelectAll,
            initialFormData,
            operations,
            request,
            selectedOperations,
            setContacts,
            setEdittingId,
            setOperations,
            setRequest,
        }),
        [
            allOnThisPageAreSelected,
            contacts,
            dialogIsOpen,
            edittingId,
            handleOpenDialog,
            handleSelect,
            handleSelectAll,
            initialFormData,
            operations,
            request,
            selectedOperations,
        ],
    );

    return <DepartmentsContext.Provider value={data}>{children}</DepartmentsContext.Provider>;
}
