import { useTranslation } from 'react-i18next';
import { Row, Search } from 'src/common/components';
import { useSearch } from 'src/common/hooks';

import { InputProps } from '../inputs/input';
import RenderIf from '../render-if';
import { SearchBox, SearchInput } from './styles';

export default function SearchBar(props: InputProps) {
    const { t } = useTranslation();
    const { formRef, inputRef, isVisible } = useSearch();

    return (
        <Row flex>
            <RenderIf condition={isVisible}>
                <SearchBox className="search-box" ref={formRef} onSubmit={e => e.preventDefault()}>
                    <Search className="search-icon" />
                    <SearchInput type="search" autoComplete="off" placeholder={String(t('general.search'))} ref={inputRef} {...props} name="text" />
                </SearchBox>
            </RenderIf>
        </Row>
    );
}
