import { Fragment } from 'react';
import { Column, Dialog } from 'src/common/components';

import { useRegistrationShipments } from '../../hooks';
import { RegistrationShipmentsDialogForm } from '../registration-shipments-dialog-form';

export default function RegistrationShipmentsDialog() {
    const { registrationShipmentsDialogRef, dialogIsOpen, handleOpenDialog } = useRegistrationShipments();

    return (
        <Dialog onCloseModal={handleOpenDialog} ref={registrationShipmentsDialogRef} id="function-dialog">
            <Column width="656px">{dialogIsOpen ? <RegistrationShipmentsDialogForm /> : <Fragment />}</Column>
        </Dialog>
    );
}
