import { format } from 'date-fns';

export class DateHelper {
    public static toDateString(date: Date | null): string {
        if (!date) return '';

        return new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString();
    }

    public static format(date?: Date | null, locale?: string, timeZone?: string): string {
        if (!date) return '';

        return new Date(date).toLocaleDateString(locale, {
            month: '2-digit',
            year: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
            timeZone,
        });
    }

    public static intl(date?: Date | string | null, locale?: string): string {
        if (!date) return '';

        const raw = new Date(date);

        const userTime = new Date().getTimezoneOffset() / 60;

        const format = new Date(
            Date.UTC(raw.getFullYear(), raw.getMonth(), raw.getDate(), raw.getHours() + userTime, raw.getMinutes(), raw.getSeconds()),
        );

        return new Intl.DateTimeFormat(locale, {
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            month: '2-digit',
            second: '2-digit',
            year: 'numeric',
            timeZone: 'UTC',
        }).format(format);
    }

    public static formatSimple(date?: Date | null, locale?: string, timeZone?: string): string {
        if (!date) return '';

        return new Date(date).toLocaleDateString(locale, {
            month: '2-digit',
            year: 'numeric',
            day: '2-digit',
            timeZone,
        });
    }

    public static yesterday(): Date {
        const date = new Date();

        date.setDate(date.getDate() - 1);

        return date;
    }

    public static getWeek(date: Date): number {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const days = Math.floor((date.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000));
        return Math.ceil((days + firstDayOfYear.getDay() + 1) / 7);
    }

    public static getStartAndEndDateOfWeek(inputDateString: string): { startWeekDate: string; endWeekDate: string } {
        const blanckSpaceForFixDate = ' ';

        const [day, month, year] = inputDateString.split('-');

        const inputDate = new Date(`${year}-${month}-${day}${blanckSpaceForFixDate}`);

        const startOfWeek = new Date(inputDate);
        const endOfWeek = new Date(inputDate);

        startOfWeek.setDate(inputDate.getDate() - inputDate.getDay());
        endOfWeek.setDate(inputDate.getDate() + (6 - inputDate.getDay()));

        return { startWeekDate: format(startOfWeek, 'dd-MM-yyyy'), endWeekDate: format(endOfWeek, 'dd-MM-yyyy') };
    }
}
