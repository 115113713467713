import { Fragment, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, EditFill, Eye, RenderIf, Row, Table, Trash } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { DateHelper } from 'src/common/helpers/date.helper';
import { usePermission } from 'src/common/hooks';
import { User } from 'src/modules/auth/contexts';

import { TableOptionButton } from '../../../../../common/components/table-option-button';
import { useUsers } from '../../hooks/use-users';
import DeleteDialog from '../delete-dialog';

function TableList() {
    const { isAdmin } = usePermission();
    const { i18n, t } = useTranslation();

    const { allOnThisPageAreSelected, users, handleOpenDialog, handleSelectAll: selectAll, selectedUsers, handleSelect: select } = useUsers();

    const [userToDelete, setUserToDelete] = useState<User | null>(null);

    const dialogRef = useRef<HTMLDialogElement>(null);

    const handleChecked = useCallback((data: User) => selectedUsers.findIndex(event => event.id === data.id) !== -1, [selectedUsers]);

    const handleOpenDeleteDialog = useCallback((user: User | null) => {
        if (!user) return;

        setUserToDelete(user);
        dialogRef?.current?.showModal();
    }, []);

    const handleOpenUserDialog = useCallback(
        (user: User | null) => {
            if (!user) return;

            handleOpenDialog(user);
        },
        [handleOpenDialog],
    );

    const handleSelectAll = useCallback(() => {
        if (users?.elements) {
            selectAll(users.elements.map(e => e as User));
        }
    }, [users?.elements, selectAll]);

    const handleSelect = useCallback(
        (user: User | null) => {
            if (!user || !isAdmin) {
                return;
            }

            select(user);
        },
        [isAdmin, select],
    );

    const tableHeaders = useMemo(
        () => [
            <Checkbox
                mode={'blue-check'}
                name={'checkboxAll'}
                key="checkboxAll"
                hidden={!isAdmin}
                checked={allOnThisPageAreSelected}
                onChange={() => handleSelectAll()}
            />,
            t('configurations.number'),
            t('configurations.user'),
            t('configurations.email'),
            t('configurations.status'),
            t('configurations.function'),
            t('configurations.password_policy'),
            t('configurations.creator'),
            t('configurations.creation_date'),
            <Fragment key="options" />,
        ],
        [allOnThisPageAreSelected, handleSelectAll, isAdmin, t],
    );

    return (
        <Fragment>
            <DeleteDialog ref={dialogRef} user={userToDelete} />

            <Table tableHeaders={tableHeaders}>
                {users?.elements?.map(user => {
                    return (
                        <TableRow className="body pointer" key={user?.id} onClick={() => handleSelect(user)}>
                            <TableData style={{ width: '16px' }}>
                                <Checkbox
                                    hidden={!isAdmin}
                                    checked={handleChecked(user as User)}
                                    mode={'blue-check'}
                                    name={user ? `${user.id.toString()}` : ''}
                                    readOnly
                                />
                            </TableData>

                            <TableData>{user?.id}</TableData>
                            <TableData className="break">{user?.name || '---'}</TableData>
                            <TableData className="break">{user?.email || '---'}</TableData>
                            <TableData>{t(`general.status.${user?.status}`)}</TableData>
                            <TableData>{user?.function || '---'}</TableData>
                            <RenderIf condition={!!user?.passwordPolicy}>
                                <TableData>{user?.passwordPolicy}</TableData>
                            </RenderIf>
                            <RenderIf condition={!user?.passwordPolicy}>
                                <TableData>
                                    <b>{t('general.default')}</b>
                                </TableData>
                            </RenderIf>
                            <TableData>{user?.creator || '---'}</TableData>
                            <TableData>{DateHelper.formatSimple(user?.createdAt, i18n.language, 'UTC')}</TableData>
                            <TableData style={{ width: '78px', paddingRight: '16px' }}>
                                <RenderIf condition={isAdmin}>
                                    <Row gap={16}>
                                        <TableOptionButton
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOpenUserDialog(user);
                                            }}
                                        >
                                            <EditFill color="#7B7B7B" />
                                        </TableOptionButton>
                                        <TableOptionButton
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOpenDeleteDialog(user);
                                            }}
                                        >
                                            <Trash />
                                        </TableOptionButton>
                                    </Row>
                                </RenderIf>
                                <RenderIf condition={!isAdmin}>
                                    <Row gap={16}>
                                        <TableOptionButton
                                            type="button"
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleOpenUserDialog(user);
                                            }}
                                        >
                                            <Eye color="#7B7B7B" />
                                        </TableOptionButton>
                                    </Row>
                                </RenderIf>
                            </TableData>
                        </TableRow>
                    );
                })}
            </Table>
        </Fragment>
    );
}

export default memo(TableList);
