import styled, { css } from 'styled-components';

interface ContainerProps {
    marginTop?: string;
    mode: 'normal' | 'small' | 'underline';
}

export const Container = styled.div<ContainerProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 31px;
    width: fit-content;
    margin-top: ${props => props?.marginTop};

    ${props =>
        props.mode === 'small' &&
        css`
            gap: 16px;
        `}

    input[type='checkbox'] {
        appearance: none;
        cursor: pointer;
        position: relative;
        border-radius: 50%;
        background-color: #ffffff;
        width: 27px;
        height: 27px;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
        left: calc(50% - 27px / 2 - 16px);
        top: calc(50% - 27px / 2);

        outline-color: #6da4d3;

        &:checked {
            left: calc(50% - 27px / 2 + 10px);
            top: calc(50% - 27px / 2);
        }

        ${props =>
            props.mode === 'small' &&
            css`
                width: 20px;
                height: 20px;

                left: calc(50% - 20px / 2 - 16px);
                top: calc(50% - 20px / 2);

                outline-color: #6da4d3;

                &:checked {
                    left: calc(50% - 20px / 2 + 10px);
                    top: calc(50% - 20px / 2);
                }
            `}
    }

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 25px;
        width: 43px;
        height: 25px;
        background: rgba(120, 120, 128, 0.16);
        left: calc(50% - 43px / 2);
        top: calc(50% - 25px / 2);
        transition: all 0.3s ease-in-out;

        ${props =>
            props.mode === 'small' &&
            css`
                height: 18px;
                width: 32px;
                left: calc(50% - 32px / 2);
                top: calc(50% - 18px / 2);

                outline-color: #6da4d3;
            `}
    }

    span {
        transition: all 0.3s ease-in-out;

        ${props =>
            props.mode === 'normal' &&
            css`
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.2px;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #262d3c;
            `}
        ${props =>
            props.mode === 'small' &&
            css`
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
                color: #000000;
            `}

        ${props =>
            props.mode === 'underline' &&
            css`
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: rgba(120, 120, 128, 0.6);
            `}
    }

    &:has(input[type='checkbox']:checked) {
        span {
            ${props =>
                props.mode === 'underline' &&
                css`
                    color: #6da4d3;
                    text-decoration-line: underline;
                `}
        }

        label {
            background-color: #6da4d3;
        }
    }
`;
