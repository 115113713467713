import { Column, Image } from 'src/common/components';
import styled from 'styled-components';

export const CardBody = styled(Column)`
    box-sizing: border-box;
    padding: 0 12px;
    width: 100%;

    align-items: flex-start;
    flex: 1;
    cursor: pointer;
`;

export const CardBox = styled.div`
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #f7f7f7;
    box-shadow: -1px 1px 4px 1px rgba(3, 3, 3, 0.15);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 0px 16px;
    position: relative;
`;

export const CardImage = styled(Image)`
    width: 280px;
    height: 168px;
    border-radius: 4px 4px 0px 0px;
    object-fit: cover;
    position: relative;
    top: 0;
`;

export const ButtonsWrapper = styled.div`
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 8px;
    display: flex;
    height: 16px;
    margin-top: 14px;
    position: absolute;
    z-index: 1;
    left: 0;
    cursor: pointer;

    .checkbox-absolute {
        margin-left: 12.5px;
    }
`;

export const Field = styled.h5`
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #262d3c;
    margin: 0;
    margin-top: 0.25rem;
`;

export const FieldValue = styled.small`
    font-size: 12px;
    line-height: 24px;

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #7b7b7b;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-all;
`;

export const Grid = styled.div`
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 4px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
`;

export const Tag = styled.div`
    cursor: default;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    gap: 10px;

    height: 24px;
    width: min-content;

    background: #ffebe9;

    border: 1px solid #ef1d00;
    border-radius: 8px;

    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.02em;

    color: #ef1d00;
`;

export const TransparentButton = styled.button`
    cursor: pointer;
    padding: 4px;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12.5px;

    background-color: transparent;

    border: 1px solid transparent;
    border-radius: 50%;

    &:hover {
        border-color: #6da4d3;
    }
`;
