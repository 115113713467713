import brazilFlag from 'src/assets/flags/brazil-flag.svg';

import { LanguageTranslations } from '../types/translations';

const PT_BR: LanguageTranslations = {
    dateFormat: 'dd/MM/yyyy',
    flag: brazilFlag,
    translations: {
        alarms: {
            pending_analysis: 'Pendente de análise',
            last_location: 'Última localização',
            GPS: 'GPS',
            alarm: 'Alarme',
            alarm_time: 'Hora do alarme',
            alarm_type: 'Tipo de Alarme',
            arrival: 'Chegada',
            board: 'Placa',
            break: 'Intervalo',
            cam: 'Câmera',
            capture: 'Capturar',
            captures: 'Capturas',
            device: 'Dispositivo',
            event: 'Evento',
            event_type: 'Tipo do evento',
            extra_events: 'Eventos Extras',
            false_alarm: 'Falso Alarme',
            fleet: 'Frota',
            historic_route: 'Rota Histórica',
            hour: 'Hora',
            information: 'Informações do Alarme',
            level: 'Nível de Alarme',
            looking_down: 'Olhando para Baixo',
            minimize: 'Minimizar',
            name: 'Nome do Alarme',
            number_of: 'Nº de Capturas',
            operation: 'Operação',
            previous_events: 'Eventos Anteriores',
            priority: 'Prioridade',
            priority_1: 'Alta',
            priority_2: 'Média',
            priority_3: 'Baixa',
            real_time: 'Monitoramento em Tempo Real',
            see_all: 'Ver tudo',
            select_alarm: 'Selecionar alarme',
            select_board: 'Selecionar Placa',
            select_destination: 'Endereço do Destino',
            select_events: 'Selecionar Eventos',
            select_operation: 'Selecionar Operação',
            select_origin: 'Endereço de Origem',
            select_vehicle: 'Selecionar Veículo',
            somnolence: 'Sonolência',
            sort_multiple_alarms: 'Classificar múltiplos Alarmes',
            velocity: 'Velocidade',
            select_redistribution: 'Redistribuição de Alarmes',
            label_select_redistribution: 'Redistribuir meus alarmes (porcentagem)',
            placeholder_select_redistribution: 'Selecione a porcentagem para redistribuir',
            alarms_search_parameters: 'Busca de Alarmes',
            label_alarms_search_parameters: 'Distribuição dos alarmes',
            placeholder_select_alarms_search_parameters: 'Selecione a opção de distribuição',
            input_alarms_search_parameters: 'Período de busca (em horas)',
            placeholder_alarms_search_parameters: 'Digite a quantidade de horas para buscar',
            dynamic: 'Dinâmica',
            open_alarms: 'Alarmes Aberto',
            processed_alarms: 'Alarmes Processados',
            activity_analyst: 'Atividade dos Analistas',
            automatic_search: 'Busca Automática',
            automatic_search_tooltip: 'Realiza a busca automática de novos alarmes a cada 30s',
            analyst: 'Analista',
            request_revision: 'Solicitar Revisão',
            this_action_revision: 'Deseja fazer a solicitação de revisão do evento',
            placeholder_action_revision: 'Digite a justificativa da revisão do evento',
            media_visualization: 'Visualização das mídias',
            media_size: 'Tamanho das mídias (pixels)',
            placeholder_midias_size: 'Digite a quantidade de pixels para aumentar as mídias',
            options: 'Opções',
            request_review: 'Solicitar Revisão',
            review_event: 'Revisar Evento',
            see_details: 'Ver Detalhes',
        },
        review_request: {
            review_request: 'Revisão de Eventos',
            analysis_requests: 'Pedidos de Análise',
            analysis_request: 'Pedido de Análise',
            request_number: 'Nº do Pedido',
            request_time: 'Data do Pedido',
            fleet: 'Frota',
            operation: 'Operação',
            event: 'Evento',
            event_time: 'Data do Evento',
            post_analysis: 'Pós Análise',
            justification: 'Justificativa',
            answer: 'Resposta',
            status: 'Situação',
            see: 'ver',
            success: 'Analisado',
            pending: 'Pendente',
        },
        auth: {
            unable_to_scan: 'Não foi possível digitalizar? Você pode usar a X para configurar manualmente seu aplicativo autenticador.',
            setup_key: 'chave de configuração',
            use_an_authenticator_app_or_browser_extension_to_scan: 'Use um aplicativo autenticador ou extensão do navegador para escanear.',
            verify_code: 'Verificar código',
            scan_the_qr_code: 'Escaneie o QR Code',
            preferred_mfa_method: 'Método MFA preferido',
            authenticator_app: 'Aplicativo autenticador',
            sms: 'SMS/mensagem de texto',
            configure_mfa_text: 'Para continuar, por favor configure o MFA para este usuário.',
            configure_mfa: 'Configurar MFA',
            enter_the_code_sent_to: 'Insira o código enviado para:',
            redefine_password: 'Redefinir Senha',
            access_password: 'Senha de acesso',
            confirm_new_password: 'Confirmar nova senha',
            email: 'E-mail',
            email_or_phone: 'E-mail ou telefone',
            forgot_password: 'Esqueci minha senha',
            login: 'Entrar',
            logout_confirmation: 'Você irá sair do sistema, deseja continuar?',
            logout_system: 'Sair do sistema',
            name: 'Nome',
            new_password: 'Nova senha',
            password: 'Senha',
            password_confirmation: 'Confirmação da senha',
            register_new_password: 'Para continuar confira seus dados e cadastre uma senha para seu acesso a nossa plataforma.',
            remember_password: 'Lembrar senha',
            send: 'Enviar',
            user: 'Usuário',
            welcome: 'Seja bem vindo',
            continue: 'Continuar',
            error_login: 'Usuário ou senha incorretos.',
            forgot_password_page_message: 'Insira o email  cadastrado para receber o link de redefinição:',
            forgot_password_code_message: 'Informe o código de verificação recebido:',
            request_new_code: 'Solicitar novo código',
            verification_code: 'Código de Verificação',
            on_confirm_change_pass: {
                success: {
                    title: 'Sucesso',
                    message: 'Senha alterada com sucesso.',
                },
                error: {
                    expiredCodeException: {
                        title: 'Código expirado',
                        message: 'Código de verificação expirado.',
                    },
                },
            },
            on_request_code: {
                success: {
                    title: 'Solicitação recebida',
                    message: 'E-mail de recuperação foi enviado com sucesso!',
                },
                error: {
                    limitExceeded: {
                        title: 'Limite excedido',
                        message: 'Limites de tentativas já se excederam, tente novamente mais tarde.',
                    },
                },
            },
        },
        configurations: {
            edit_password_policy: 'Editar Política de Senha',
            view_password_policy: 'Visualizar Política de Senha',
            view_operation: 'Visualizar operação',
            view_function: 'Visualizar função',
            edit_function: 'Editar função',
            view_user: 'Visualizar usuário',
            save_contact: 'Salvar contato',
            do_you_want_to_delete_the_password_policy: 'Deseja excluir a(s) política(s) de senha',
            delete_policy: 'Excluir Política',
            mfa_validation: 'Validação de MFA Obrigatória',
            password_policy_status: 'Status da Política de Senha',
            current_password_must_be_different_from_the_last_X_passwords: 'A senha atual deve ser diferente das últimas X senhas.',
            contains_at_least_one_uppercase_letter: 'Contém pelo menos 1 letra maiúscula',
            contains_at_least_one_lowercase_letter: 'Contém pelo menos 1 letra minúscula',
            contains_at_least_one_special_character: 'Contém pelo menos 1 caractere especial',
            contains_at_least_one_number: 'Contém pelo menos 1 número',
            minimum_time: 'Tempo mínimo (em dias)',
            expiry_time: 'Tempo de expiração (em dias)',
            password_minimum_length: 'Comprimento mínimo da senha (caracteres)',
            redefine_password: 'Redefinir senha',
            view_vehicle: 'Visualizar Veículo',
            edit_vehicle: 'Editar Veículo',
            delete_the_vehicle_white_board: 'Deseja excluir o veículo de placa',
            delete_vehicle: 'Excluir Veículo',
            acquisition_date: 'Data da Aquisição',
            add_vehicle: 'Adicionar veículo',
            current_vehicle_statuses: {
                new: 'Novo',
                semi_new: 'Seminovo',
                used: 'Usado',
            },
            vehicle_types: {
                agricultural_machine: 'Máquina Agrícola',
                bus: 'Ônibus',
                light_vehicle: 'Veículo Leve',
                locomotive: 'Locomotiva',
                motorcycle: 'Motocicleta',
                ship: 'Navio',
                stationary_equipment: 'Equipamento Estacionário',
                truck: 'Caminhão',
            },
            assigned_to: 'Atribuído a',
            password_policy_name: 'Nome da política de senha',
            create_password_policy: 'Criar Política de Senha',
            current_vehicle_status: 'Estado atual do veículo',
            vehicle_type: 'Tipo de veículo',
            create_vehicle: 'Criar veículo',
            edit_department: 'Editar Operação',
            equals_resources: 'Recursos pai e filho são iguais',
            ambiguous_record: 'Esse registro é ambiguo, selecione o registro desejado.',
            create_department: 'Criar Operação',
            contact: 'Contato',
            add_department: 'Adicionar Operação',
            add_function: 'Adicionar Função',
            add_user: 'Adicionar usuário',
            confirm_delete_functions: 'Deseja excluir a(s) função(ões)?',
            confirm_delete_users: 'Deseja excluir o(s) usuário(s)?',
            confirm_password: 'Confirmar Senha',
            contacts: 'Contatos',
            creation_date: 'Data de criação',
            creator: 'Criador',
            delete: 'Excluir',
            delete_department: 'Excluir Operação',
            delete_function: 'Excluir função',
            delete_the_user: 'Deseja excluir o usuário',
            department: 'Operação',
            departments: 'Operações',
            do_you_want_to_delete_the_department: 'Deseja excluir a(s) operação(ões)',
            do_you_want_to_delete_the_role: 'Deseja excluir a função',
            edit_user: 'Editar usuário',
            email: 'E-mail',
            equipments: 'Equipamentos',
            function: 'Função',
            function_name: 'Nome da função',
            function_number: 'Nº da função',
            functionalities: 'Funcionalidades',
            functions: 'Funções',
            institution: 'Instituição',
            institution_name: 'Nome da Instituição',
            login: 'Login',
            name_login: 'Nome de login',
            name_user: 'Nome de usuário',
            number: 'Nº',
            observation: 'Observação',
            organization: 'Organização',
            organization_name: 'Nome da Organização',
            paper: 'Papel',
            password: 'Senha',
            password_policy: 'Política de senha',
            password_policies: 'Políticas de Senha',
            phone_number: 'Telefone de contato',
            settings: 'Configuração',
            show_active_users: 'Mostrar usuários ativos',
            status: 'Situação',
            system: 'Sistema',
            this_action: 'Esta ação não poderá ser desfeita.',
            user: 'Usuário',
            user_delete: 'Excluir usuário',
            users: 'Usuários',
            vehicles: 'Veículos',
            intervention_protocol: 'Protocolo de intervenções',
            client: 'Cliente',
            analyst: 'Analista',
            client_analyst: 'Cliente Analista',
            profile: 'Perfil',
            confirm: 'Confirmar',
            description: 'Descrição',
        },
        field_validations: {
            min: 'O número minímo de caracteres é: $$min',
            name: 'Nome inválido',
            date_is_before_creation_date: 'A data selecionada não pode ser inferior à data de criação',
            date_is_before_today: 'A data selecionada não pode ser inferior à data atual',
            date: 'Data inválida',
            phone_number_tooltip: 'Insira um número de telefone, incluindo + e o código do país, por exemplo, +5521971718888.',
            password_length_recommended:
                'Deve ser um número entre 6 e 99. Recomendamos enfaticamente que você exija que as senhas tenham pelo menos 8 caracteres.',
            equals_resources: 'Recursos pai e filho são iguais',
            mandatory_fields: 'Campos obrigatórios',
            max: 'Tamanho máximo: %%',
            email: 'E-mail inválido',
            hour: 'Formato de horas inválido',
            invalid_record: 'Registro inválido',
            passwords_match: 'As senhas não conferem',
            record_already_exists: 'Esse registro já existe.',
            required: 'Campo obrigatório',
            phone: 'Telefone inválido',
            password: {
                length_8: 'A senha deve conter no mínimo 8 caracteres.',
                lower_case: 'A senha deve conter letras minúsculas.',
                number: 'A senha deve conter no mínimo um número.',
                special_character: 'A senha deve conter no mínimo um carácter especial.',
                upper_case: 'A senha deve conter letras maiúsculas.',
            },
        },
        general: {
            recommended: 'Recomendado',
            name: 'Nome',
            input: 'Entrada',
            output: 'Saída',
            expires_in: 'Expira em',
            default: 'Padrão',
            record_has_relationships: 'A(s) política(s) tem usuário(s) vinculado(s), desvincule-o (s) e tente novamente.',
            select: 'Selecionar',
            export: 'Exportar',
            date: 'Data',
            select_filters_to_view_the_map: 'Selecione os filtros para visualizar o mapa',
            error_loading_data: 'Error ao carregar dados',
            admin: 'Admin',
            vehicle_monitoring: 'Monitoramento de veículos',
            interventions: 'Intervenções',
            analytics: 'Analytics',
            overview: 'Visão Geral',
            unit: 'Unidade',
            fleet: 'Frota',
            driver: 'Motorista',
            communication: 'Comunicação',
            support: 'Suporte',
            sort_alarm: 'Classificar alarme',
            review_alarm: 'Revisar alarme',
            reports: 'Relatórios',
            real_time_localization: 'Localização em tempo real',
            playback_of_tracks: 'Reprodução de Pistas',
            live_camera_stream: 'Stream de câmeras',
            settings: 'Configurações',
            electronic_fence: 'Cerca Eletrônica',
            dashboard: 'Dashboard',
            authentication: 'Autenticação',
            alarms_processing: 'Processamento de alarmes',
            selection_of_settings: 'Configurações',
            main_menu: 'Menu principal',
            alarm_type: 'Tipos de Alarme',
            alarms_high_risk: 'Alarmes de Risco Alto',
            alarms_solved: 'Alarmes tratados',
            unhandled_alarms: 'Alarmes não tratados',
            alarms_total: 'Total de Alarmes',
            all: 'Todos',
            see_all: 'Ver Tudo',
            apply: 'Aplicar',
            cancel: 'Cancelar',
            classification: 'Classificar',
            clear_filters: 'Limpar filtros',
            conclude: 'Concluir',
            configurations: 'Configurações',
            confirm: 'Confirmar',
            description: 'Descrição',
            destination: 'Destino',
            error: 'Erro, tente novamente.',
            events: 'Eventos',
            exclude_yourself: 'Você não pode excluir a si mesmo',
            filter: 'Filtrar',
            filters: 'Filtros',
            final_hour: 'Hora Final',
            high_risk: 'Risco Alto',
            historic: 'Histórico',
            information_unavailable: 'Informação indisponível',
            initial_hour: 'Hora Inicial',
            items_per_page: 'Itens por página',
            loading: 'Carregando...',
            low_risk: 'Risco Baixo',
            medium_risk: 'Risco Médio',
            no_content: 'Nenhum Registro',
            no_content_specific_alarm: 'Você ainda não possui nenhum alarme',
            no_content_specific_event: 'Você ainda não possui nenhum evento',
            no_filter_vehicle: 'Não encontramos nenhum resultado para os filtros aplicados',
            no_vehicle_selected: 'Nenhum veículo selecionado',
            no_vehicle: 'Nenhum veículo encontrado',
            of: 'de',
            offline: 'Offline',
            on_the_page: 'Você está na página',
            online: 'Online',
            online_vehicles: 'Veículos Online',
            origin: 'Origem',
            period: 'Período',
            review: 'Revisar',
            search: 'Buscar...',
            select_period: 'Selecionar período',
            selected_alarms: 'Alarmes Selecionados',
            seven_days: '7 dias',
            show_events: 'Mostrar Eventos',
            success: 'Sucesso',
            test: 'Teste',
            total_vehicles: 'Total de Veículos',
            twenty_four_hours: '24 horas',
            update: 'Atualizar',
            update_data: 'Atualizar Dados',
            vehicle: 'Veículo',
            vehicles: 'Veículos',
            vehicles_events: 'Eventos do veículo',
            view: 'Visualizar',
            write: 'Digitar',
            write_here: 'Digite Aqui',
            activity: 'Atividade',
            last: 'Últimas',
            hours: 'Horas',
            region: 'Região',
            country: 'País',
            operation: 'Operação',
            notification_sound: 'Som da Notificação',
            functionalities: 'Funcionalidades',
            operations: 'Operações',
            password_policy: 'Política de senha',
            users: 'Usuários',
            speed_above: 'Velocidade acima de',
            message: {
                success: {
                    title: 'Sucesso',
                    message: 'Solcicitação concluída com sucesso.',
                },
                error: {
                    title: 'Falha',
                    message: 'Houve uma falha ao processar sua solicitação.',
                },
            },
            status: {
                active: 'Ativo',
                inactive: 'Inativo',
            },
            face_missing: 'Face Missing',
            yes: 'Sim',
            no: 'Não',
            back_to_home: 'Voltar para Página inicial',
            intervention_protocol: 'Protocolo de intervenção',
            registration_shipments: 'Cadastro de envios',
            event_redistribution: 'Redistribuição de eventos',
            select_the_event: 'Selecione o evento',
            review_requests: 'Pedidos de Análise',
            unit_comparison: 'Comparação de Unidades',
            last_alarm: 'Último Alarme',
            fleets: 'Frotas',
            select_options: 'Selecione as opções',
            see_driver_name: 'Ver o nome do motorista',
        },
        menu: {
            events: 'Eventos',
            language: 'Português',
            logout: 'Sair',
            panel: 'Painel',
            pending_alarms: 'Alarmes Pendentes',
            processing_alarms: 'Processamento de Alarmes',
            vehicles_monitoring: 'Monitoramento de Veículos',
            interventions: 'Intervenções',
            analytics: 'Analytics',
            overview: 'Visão Geral',
            period: 'Período',
            registration_shipments: 'Cadastro de envios',
            help: 'Ajuda',
            review_request: 'Revisão de Eventos',
            analysis_requests: 'Pedidos de Análise',
            home: 'Inicio',
            smart_report: 'Smart Report',
        },
        monitoring: {
            view_electronic_fence: 'Visualizar Cerca Eletrônica',
            do_you_want_to_delete_the_electronic_fence: 'Deseja excluir a cerca eletrônica',
            delete_electronic_fence: 'Excluir cerca eletrônica',
            change_zone_shape: 'Alterar formato da zona',
            zone_color: 'Cor da zona',
            transparency: 'Transparência',
            expires_in: 'Data Final',
            generate_event_by: 'Gerar evento por',
            electronic_fence_name: 'Nome da cerca eletrônica',
            edit_electronic_fence: 'Editar Cerca Eletrônica',
            add_electronic_fence: 'Adicionar Cerca Eletrônica',
            add_fence: 'Adicionar Cerca',
            SIM: 'Número de Cartão SIM',
            add_perimeter: 'Adicionar Perímetro',
            altitude: 'Altitude',
            board_color: 'Cor da Placa',
            board_number: 'Número de Placa',
            cam_stream: 'Stream de Câmeras',
            cellphone: 'Telefone de contato',
            cities: 'Províncias e Cidades',
            conduction: 'Modelo de condução',
            define_route: 'Definir Rota',
            defined_perimeters: 'Perímetros Definidos',
            direction: 'Direção',
            driver_info: 'Informações do Condutor',
            driver_name: 'Nome do Motorista',
            eletronic_fence: 'Cerca Eletrônica',
            filiation: 'Afiliação',
            identity: 'Número de Identidade',
            last_events: 'Últimos Eventos',
            last_register: 'Último Registro',
            lat: 'Latitude',
            localization: 'Localização',
            long: 'Longitude',
            mark_perimeter: 'Marcar Perímetros',
            organization: 'Organização Controladora',
            perimeter: 'Perímetro',
            position: 'Posicionamento',
            radius: 'Raio',
            real_time: 'Localização em Tempo Real',
            select_perimeter: 'Selecionar perímetro',
            status: 'Status',
            track_playback: 'Reprodução de Pistas',
            tracking: 'Tracking',
            vehicle_info: 'Informações do Veículo',
            write_radius: 'Digitar raio (km)',
            view: 'View',
            compass_points: {
                east: 'Leste',
                north: 'Norte',
                northeast: 'Nordeste',
                northwest: 'Noroeste',
                south: 'Sul',
                southeast: 'Sudeste',
                southwest: 'Sudoeste',
                west: 'Oeste',
            },
            event_expires_in: {
                never: 'Nunca',
                today: 'Hoje',
                tomorrow: 'Amanhã',
                seven_days: '7 dias',
                custom: 'Personalizado',
            },
        },
        password: {
            new_password_is_equal_to_old: 'A sua nova senha deve ser diferente da(s) antiga(s).',
            contains_at_least_one_lowercase_letter: 'A senha deve conter pelo menos 1 letra minúscula',
            contains_at_least_one_number: 'A senha deve conter pelo menos 1 número',
            contains_at_least_one_special_character: 'A senha deve conter pelo menos um caractere especial',
            contains_at_least_one_uppercase_letter: 'A senha deve conter pelo menos uma letra maiuscula',
            is_expired: 'A senha está expirada',
        },
        overview: {
            proportion_events: 'Proporção',
            proportion_events_chart_subtitle: 'Esse gráfico representa a proporção dos eventos nos períodos selecionados.',
            heat_map_events: 'Mapa de calor',
            heat_map_events_chart_subtile: 'Os pontos deste mapa indicam os locais de maior ocorrência de eventos.',
            average_events: 'Média',
            average_events_chart_subtile: 'Esse gráfico representa a média dos veículos  no período selecionado.',
            total_events: 'Total',
            total_events_chart_subtitle: 'Esse gráfico representa o total dos eventos nos períodos selecionados.',
            telemetry: 'Telemetria',
        },
        period: {
            time_range: 'Faixa de horário',
            day_week: 'Dias da semana',
            evento_per_day_hour: 'Eventos por dia e hora',
            hours_per_bar: 'Horas por barra',
            coordinates: 'Coordenadas',
            polar: 'Polar',
            cartesian: 'Cartesiano',
            options: 'Opções',
            day: 'Dia',
            week: 'Semana',
            month: 'Mês',
            year: 'Ano',
            bar: 'Barra',
            line: 'Linha',
            graph_type: 'Tipo de gráfico',
            cancel: 'Cancelar',
            apply: 'Aplicar',
            worst_tracks_highlights: 'Destaques piores faixas',
            total_events: 'Total de eventos',
            at: 'ás',
            scatterGraphSubTitle:
                'Os pontos do gráfico representam os eventos ' +
                'selecionados durante as horas e dias da semana. Quanto maior o ponto' +
                ', mais eventos, e os pontos piscando representam a quantidade selecionada' +
                ' de faixas mais graves.',
            cartesianChart: 'As faixas desse gráfico indicam os horários com maior ocorrência de eventos.',
            barChartGraph: 'As barras desse gráfico indicam os dias da semana com maior ocorrência de eventos.',
            telemetry: 'Telemetria',
        },
        interventions: {
            interventions_informations: 'Informações da Intervenção',
            qtd_events: 'Quantidade de eventos: ',
            hour_initial: 'Hora início dos eventos: ',
            hour_final: 'Hora final dos eventos: ',
            interventions_situation: 'Situação da intervenção',
            media: 'Mídia',
            speed: 'Velocidade',
            hour: 'Hora',
            lat: 'Latitude',
            long: 'Longitude',
            close: 'Fechar',
            finalize: 'Finalizar',
            informations: 'Informações',
            comments: 'Comentários',
            type_comment: 'Escreva seu comentário',
            comment: 'Comentar',
            event: 'Evento',
            pending: 'Pendente',
            in_negotiation: 'Em tratativa',
            closed: 'Fechado',
            status: 'Situação',
            all: 'Todos',
            'Em aberto': 'Em Aberto',
            Pendente: 'Pendente',
            'Em tratativa': 'Em tratativa',
            Fechado: 'Fechado',
            'mudou para status': 'mudou para status',
        },
        face_missing: {
            proportion: 'Proporção',
            fleet: 'Frota',
            details: 'Detalhes',
            details_table_subtitle: 'Selecione o formato desejado para baixar as informações da tabela',
            face_missing: 'Face Missing',
            total_alarms: 'Total de Alarmes',
            percentage: 'Porcentagem',
            status: 'Situação',
            proportion_graph_subtitle: 'Esse gráfico representa a proporção dos eventos nos períodos selecionados.',
            fleet_graph_subtitle: 'O gráfico abaixo mostra a situação do aparelho DS de acordo com cada frota.',
            informative: 'Informativo',
            about_face_missing: 'Sobre Face missing:',
            a_fleet_is_classified: 'Uma frota é classificada como deslocada se pelo menos 50% dos eventos desta frota são de face missing.',
        },
        registration_shipments: {
            registration_of_report_submissions: 'Cadastro de envios de relatórios',
            operation: 'Operação',
            email: 'Email',
            shipping_type: 'Tipo Envio',
            channel: 'Canal',
            priority: 'Prioridade',
            telephone: 'Telefone',
            add_chance: 'Adicionar Contato',
            send_request: 'Enviar Solicitação',
            contact: 'Contato',
            save: 'Salvar',
            cancel: 'Cancelar',
            daily_report: 'Report Díario',
            delete_contact: 'Excluir Contato',
            title_confirm_delete: 'Deseja fazer a exclusão do contato “NOME DO CONTATO”?',
            confirm: 'Confirmar',
            registration_shipments: 'Tipo de envio',
            intervention: 'Intervenção',
            contacts: 'Contato',
            select_type: 'Selecione o tipo',
        },
        fleets: {
            fleets: 'Frotas',
            ds_ranking: 'Ranking DS',
            fleet_ranking: 'Ranking de Frotas',
            total: 'Total',
            time_range: 'Faixa de horário',
            heat_map: 'Mapa de Calor',
            events: 'Eventos',
            type: 'Tipo',
            bar: 'Barra',
            line: 'Linha',
            day: 'Dia',
            week: 'Semana',
            month: 'Mês',
            year: 'Ano',
            select_the_event: 'Selecione o evento',
            hours_per_bar: 'Horas por barra',
            period: 'Período',
            polar: 'Polar',
            cartesian: 'Cartesiano',
            radio: 'Radio',
            blur: 'Blur',
            period_view: 'Visão Período',
            need_to_select_fleet: 'Selecione uma frota no ranking acima para visualizar os detalhes da frota',
            fleet_ranking_subtile: 'Esse ranking representa o total de eventos de todas as frotas',
            fleet_total_subtile: 'Esse gráfico representa o total de eventos da frota selecionada',
            time_range_subtile: 'As faixas desse gráfico indicam os horários com maior ocorrência de eventos',
            heat_map_subtitle: 'As ondas de calor desse gráfico indicam os locais com maior ocorrência de eventos',
        },
        events: {
            events: 'Eventos',
        },
        intervention_protocol: {
            intervention_protocol: 'Protocolo de intervenções',
            protocol: 'Protocolo',
            group_whatsapp: 'Grupo Whatsapp',
            observation: 'Observação',
            enter_the_name_of_the_operation: 'digite o nome da operação',
            enter_protocol_name: 'digite o nome do protocolo',
            enter_group_name: 'digite o nome do grupo',
            type_the_note: 'digite sua observação',
            enter_event_name: 'digite o nome do evento',
            to_accept: 'Aceitar',
            cancel: 'Cancelar',
            to_add: 'Adicionar',
            add_protocol: 'Adicionar Protocolo',
            add_details: 'Adicionar Detalhes',
            delete_protocol: 'Excluir Protocolo',
            do_you_want_to_delete_the_protocol: 'Deseja fazer a exclusão do protocolo “NOME DO PROTOCOLO”?',
            operation: 'Operação',
            protocol_details: 'Detalhes do protocolo',
            edit_protocol: 'Editar Protocolo',
            save: 'Salvar',
            event: 'Evento',
            amount: 'Quantidade',
            enter_the_amount: 'Digite a quantidade',
            time: 'Tempo',
            enter_the_time: 'Digite o tempo',
            wait: 'Espera (h)',
            enter_the_wait: 'Digite a espera',
            send_image: 'Enviar imagem',
            select_the_option: 'Selecione a opção',
            send_video: 'Enviar vídeo',
            edit_details: 'Editar Detalhes',
            back: 'Voltar',
            advance: 'Avançar',
            to_close: 'Fechar',
            want_to_delete_the_protocol_event_protocol_name: 'deseja fazer a exclusão do evento do protocolo "NOME DO PROTOCOLO"?',
            new_details: 'Novos detalhes',
            delete: 'Excluir',
            this_action_cannot_be_undone: 'Esta ação não poderá ser desfeita.',
            select_the_event: 'Selecione o evento',
            confirm: 'Confirmar',
            view_protocol: 'Visualizar Protocolo',
        },
        drivers: {
            drivers: 'Motoristas',
            driver_history: 'Histórico do motorista',
            images: 'Imagens',
            id: 'Id',
            operation: 'Operação',
            fleet: 'Frota',
            event: 'Evento',
            day: 'Dia',
            alarm_hour: 'Hora Alarme',
            latitude: 'Latitude',
            longitude: 'Longitude',
            id_cluster: 'Id Cluster',
            driver: 'Motorista',
            document: 'Documento',
            time_range: 'Faixa de horário',
            heat_map: 'Mapa de calor',
            history_of_events: 'Histórico dos eventos',
            subtitle_history_of_events: 'Os pontos deste mapa indicam os locais de maior ocorrência de eventos.',
            subtitle_time_range: 'As faixas desse gráfico indicam os horários com maior ocorrência de eventos.',
            subtitle_history_events: 'Os dados da tabela mostram o histórico dos eventos.',
            driver_ranking: 'Ranking de motoristas',
            search_by_id_cluster: 'Busca por id_cluster',
            search_by_image: 'Busca por imagem',
            upload_image: 'Upload Imagem',
            click_on_a_line: 'Clique numa linha para ver o histórico do motorista selecionado.',
            period: 'Período',
            week: 'Semana',
            month: 'Mês',
            year: 'Ano',
            hours_per_bar: 'Horas por barra',
            coordinates: 'Coordenadas',
            polar: 'Polar',
            cartesian: 'Cartesiano',
            events: 'Eventos',
            select_the_event: 'Selecione o evento',
            ray: 'Raio',
            blur: 'Blur',
            subtitle_driver_history: 'Os dados desse grafico indiciam o total de eventos do motorista baseado por período.',
            need_to_select_driver: 'Selecione um motorista no ranking acima para visualizar os detalhes do motorista',
            photos_legends: 'Fotos das ocorrências de eventos',
            file_text: 'Nenhum arquivo selecionado.',
            search: 'Procurar',
            no_face_founded: 'Nenhum rosto foi detectado na imagem.',
            try_again: 'Tente novamente',
            driver_founded_no_events: 'Rosto detectado mas nenhum motorista encontrado.',
            similarity: 'Similaridade',
            type: 'Tipo',
            bar: 'Barra',
            line: 'Linha',
            heat_map_subtitle: 'As ondas de calor desse gráfico indicam os locais com maior ocorrência de eventos',
        },
        last_alarm: {
            last_alarm: 'Último Alarme',
            operation: 'Operação',
            fleet: 'Frota',
            last_communication: 'Última Comunicação ',
            days_since_the_last_communication: 'Última comunicação (dias)',
            days_since_the_last_alarm: 'Último alarme (dias)',
            status: 'Situação',
            proportion: 'Proporção',
            table: 'Tabela',
            subtitle_pie_chart: 'Esse gráfico representa a proporção dos eventos nos períodos selecionados.',
            subtitle_bar_chart: 'Esse gráfico mostra o status de comunicação por operação e todas as frotas que estão dentro dela.',
            informative: 'Informativo',
            a_fleet_is_classified_as: 'Uma frota é classificada como:',
            no_communication: 'Sem comunicação caso não tenha havido comunicação há mais 6 dias;',
            communicating_without_event:
                'Comunicando sem evento caso tenha comunicado nos últimos 6 dias mas não apresentou nenhum alarme nesse período;',
            if_you_are_communicating: 'Ok! caso esteja comunicando e tendo alarmes nos últimos 6 dias.',
        },
        comparison_of_units: {
            comparison_of_units: 'Comparação de unidades',
            country: 'País',
            week: 'Semana',
            yawn: 'Bocejo',
            drowsinessN1: 'Sonolência N1',
            drowsinessN2: 'Sonolência N2',
            cellPhone: 'Celular',
            smoking: 'Fumando',
            occlusion: 'Oclusão',
            lookingDownN1: 'Olhando para baixo N1',
            lookingDownN2: 'Olhando para baixo N2',
            drowsinessIndex: 'Índice sonolência',
            distractionIndex: 'Índice distração',
            telemetryIndex: 'Índice telemetria',
            generalIndex: 'Índice geral',
            averages_per_period: 'Médias por período',
            comparison: 'Comparação',
            the_vehicles_column_is_the_maximum: 'A coluna <strong>Veículos</strong> é o máximo da quantidade de veículos que rodaram a cada dia.',
            each_event_column_contains_the_total_number:
                'Cada coluna de eventos contém o total de eventos do período dividido pelo número de Veículos obtido acima, por sua vez dividido pela quantidade de dias ativos.',
            we_have_the_following_formulas: 'Temos as seguintes fórmulas:',
            fatigue_index: 'Índice fadiga = 10 * Sonolência N1 + 20 * Sonolência N2',
            distraction_index:
                'Índice distração = 15 * Celular + 5 * Fumando + 15 * Oclusão + 1 * Olhando para baixo N1 + 2 * Olhando para baixo N2 + 15 * Fone de ouvido',
            indice_argus: 'Índice Argus = Índice fadiga + Índice distração',
            period_vision: 'Visão Período',
            unity_vision: 'Visão Unidade',
            day: 'Dia',
            month: 'Mês',
            year: 'Ano',
            period: 'Período',
            operation: 'Operação',
            region: 'Região',
            column: 'Coluna',
            select_the_event: 'Selecione o evento',
            informative: 'Informativo',
        },
        tableEventName: {
            Sonolência: 'Sonolência',
            'Sonolência N1': 'Sonolência N1',
            'Sonolência N2': 'Sonolência N2',
            'Sonolência leve': 'Sonolência leve',
            'Olhando para baixo N1': 'Olhando para baixo N1',
            'Olhando para baixo N2': 'Olhando para baixo N2',
            Celular: 'Celular',
            'Sem cinto': 'Sem cinto',
            Fumando: 'Fumando',
            Oclusão: 'Oclusão',
            Bocejo: 'Bocejo',
            'Face missing': 'Face missing',
            'Óculos de sol': 'Óculos de sol',
            'Consumo alimento': 'Consumo alimento',
            'Fone de ouvido': 'Fone de ouvido',
            'Colisão frontal': 'Colisão frontal',
            'Alarme antecipado de colisão': 'Colisão frontal',
            'Colisão de pedestres': 'Colisão de pedestres',
            'Alarme de colisão de passageiros': 'Colisão de pedestres',
            'Excesso de velocidade da via': 'Excesso de velocidade da via',
            'Excesso de velocidade': 'Excesso de velocidade',
            'Alarme de excesso de limite de sinal rodoviário': 'Excesso de velocidade da via',
            'Colisão para frente de baixa velocidade': 'Colisão frontal de baixa velocidade',
            'Colisão frontal de baixa velocidade': 'Colisão frontal de baixa velocidade',
            'Curva rápida': 'Curva rápida',
            'Curva rápida à direita': 'Curva rápida',
            'Curva rápida à esquerda': 'Curva rápida',
            'Veículo muito próximo': 'Veículo muito próximo',
            'Distância de veículos muito próxima': 'Veículo muito próximo',
            'Excesso de velocidade na faixa de pedestre': 'Excesso de velocidade na faixa de pedestre',
            'Excesso de velocidade na listra de zebra': 'Excesso de velocidade na faixa de pedestre',
            'Mudança irregular de faixa': 'Mudança irregular de faixa',
            'Linha de pressão direita da pista': 'Mudança irregular de faixa',
            'Linha de pressão esquerda da pista': 'Mudança irregular de faixa',
            'Ponto cego': 'Ponto cego',
            Velocidade: 'Excesso de velocidade',
            'Condução perigosa N1': 'Condução perigosa N1',
            'Condução perigosa N2': 'Condução perigosa N2',
            'DS deslocado': 'DS deslocado',
            'Dano ao DS': 'Dano ao DS',
            'Cinto irregular': 'Cinto irregular',
            Rádio: 'Rádio',
            'Falso alarme': 'Falso alarme',
            'Tentativa de Oclusão': 'Tentativa de Oclusão',
            'Oclusão parcial': 'Oclusão parcial',
            'Passageiro irregular': 'Passageiro irregular',
            Teste: 'Teste',
            '?': '?',
            'Óculos de sol à noite': 'Oculos de sol à noite',
            Infração: 'Infração',
            Distração: 'Distração',
            Fadiga: 'Fadiga',
            'Excesso de Velocidade': 'Excesso de velocidade',
            'Direção perigosa': 'Direção perigosa',
            'Direção agressiva': 'Direção agressiva',
        },
        hours: {
            in: 'em',
            ago: 'atrás',
            secondsAgo: 'à poucos segundos',
            seconds: 'segundos',
            minute: 'minutos',
            aMinute: 'um minuto',
            hour: 'hora',
            hours: 'horas',
            oneDay: 'um dia',
            days: 'dias',
            oneWeek: 'uma semana',
            weeks: 'semanas',
            monthAgo: 'mês atrás',
            months: 'meses',
            aYear: 'um ano',
            years: 'anos',
        },
        home: {
            events: 'Eventos',
            updated_at: 'Atualizado às',
            interventions: 'Intervenções',
            drowsiness: 'Sonolência',
            vehicles: 'Veículos',
            interventions_last_hour: 'Intervenções na última hora',
            interventions_last_hour_subtitle: 'Cada ponto representa a evolução da quantidade de eventos na última hora.',
            event_distribution: 'Distribuição de eventos',
            event_distribution_subtitle: 'Cada sessão indica a porcentagem de eventos de cada categoria no período selecionado.',
            violation: 'Infração',
            fatigue: 'Fadiga',
            distraction: 'Distração',
            speeding: 'Excesso de velocidade',
            dangerous_driving: 'Direção perigosa',
            aggressive_driving: 'Direção agressiva',
            recent_interventions: 'Intervenções recentes',
            recent_interventions_subtitle:
                'Os marcadores em vermelho no mapa indicam a localização das intervenções ocorridas no período selecionado. Ao clicar neles, são exibidos o vídeo e informações relacionadas à intervenção.',
        },
        echarts: {
            group: 'Grupo',
            total: 'Total',
        },
    },
};

export default PT_BR;
