import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #f1f1f1;
    background-color: #262d3c;

    &.primary {
        color: #f1f1f1;
        background-color: #262d3c;
    }

    &.success {
        background: #28a745;
        color: #f1f1f1;
    }

    &.pending {
        background: #ffeb3b;
        color: #121212;
    }

    &.error {
        background: #dc3545;
        color: #f1f1f1;
    }

    &.dark {
        background: #121212;
        color: #f1f1f1;
    }

    &.light {
        background: #f1f1f1;
        color: #121212;
    }
`;
