import { SVGProps } from 'react';

interface EllipsisVerticalProps extends SVGProps<SVGSVGElement> {
    color: string;
}

function EllipsisVertical({ color, ...props }: EllipsisVerticalProps) {
    return (
        <svg width={4} height={16} viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.5 2a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                fill={color}
            />
        </svg>
    );
}

export default EllipsisVertical;
