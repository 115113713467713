import { Dispatch, Fragment, ReactNode, SetStateAction } from 'react';
import ReactCheckboxTree from 'react-checkbox-tree';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';

import { Check } from './icons/check';
import { HalfCheck } from './icons/halfcheck';
import { Uncheck } from './icons/uncheck';
import './styles.css';

export interface CheckboxTreeState {
    checked: string[];
    expanded: string[];
}

export interface NodeProps {
    children?: Array<NodeProps>;
    className?: string;
    disabled?: boolean;
    icon?: ReactNode;
    label: string;
    showCheckbox?: boolean;
    title?: string;
    value: string;
}

interface CheckboxTreeProps {
    nodes: Array<NodeProps>;
    state: CheckboxTreeState;
    setState: Dispatch<SetStateAction<CheckboxTreeState>>
}

function CheckboxTree(props:  CheckboxTreeProps) {
    return (
        <ReactCheckboxTree
            checkModel="all"
            icons={{
                check: <Check />,
                expandClose: <BsChevronRight size={12} />,
                expandOpen: <BsChevronDown size={12} />,
                halfCheck: <HalfCheck />,
                leaf: <Fragment />,
                parentClose: <Fragment />,
                parentOpen: <Fragment />,
                uncheck: <Uncheck />,
            }}
            nodes={props.nodes}
            checked={props.state.checked}
            expanded={props.state.expanded}
            onCheck={checked => props.setState(state => ({ ...state, checked }))}
            onExpand={expanded => props.setState(state => ({ ...state, expanded }))}
        />
    );
}

export default CheckboxTree;
