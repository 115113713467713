import { alarms } from '../constants/alarms';
import { events } from '../constants/events';

export class EventHelper {
    public static getColorByEventName(eventName: string): string | undefined {
        const event = events.find(event => event.name === eventName);

        return event?.color;
    }

    public static getColorByAlarmName(alarmName: string): string | undefined {
        const alarm = alarms.find(alarm => alarm.name === alarmName);

        return alarm?.color;
    }
}
