import { CSSProperties, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useListLayout, useSidebar } from 'src/common';
import { Checkbox, Image, Row, Table, VideoPlayer } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { EventDTO } from 'src/modules/alarm-processing/dtos/event.dto';
import { DateHelper } from 'src/modules/alarm-processing/helpers/date.helper';
import { useEvents, useSortReviewEvent } from 'src/modules/alarm-processing/hooks';
import { useAuth } from 'src/modules/auth';

import { userProfile } from '../../../auth/constants/user-profile';
import { criticalCustomers } from '../../constants/critical-customers';
import { criticalOperations } from '../../constants/critical-operations';
import { Tag } from '../alarm-card/styles';
import { SortProps } from '../alarms-container';

interface TableListProps {
    currentPage?: number;
    initialDate?: Date;
    finalDate?: Date;
    alarmsName?: string[];
    handleHeaderClick?: (event: MouseEvent, child: string) => void;
    sortElements?: SortProps[];
    newSortElements?: (newSortElements: SortProps[]) => void;
}

export default function TableList({
    currentPage,
    initialDate,
    finalDate,
    alarmsName,
    handleHeaderClick,
    sortElements,
    newSortElements,
}: TableListProps) {
    const { events, eventScreenType, handleRequestList } = useEvents();
    const { allOnThisPageAreSelected, handleSelectAll: selectAll, handleSelectEvent, selectedEvents } = useSortReviewEvent();
    const { t, i18n } = useTranslation();
    const { session } = useAuth();
    const { displaySidebar } = useSidebar();
    const { media } = useListLayout();

    const isAnalyst = useMemo(() => {
        return session?.user.profile === userProfile.analyst;
    }, [session?.user.profile]);

    const handleChangeSortTable = useCallback(
        (tableHeaderName: any) => {
            const verifyTableHeaderName = tableHeaderName.find((x: SortProps) => x.keyOrder !== 'Captures' && x.keyOrder !== 'Capturas');
            if (tableHeaderName[0].keyOrder.props !== undefined) {
                return;
            }

            if (verifyTableHeaderName) {
                handleRequestList({
                    currentPage,
                    initialDate,
                    finalDate,
                    sortProps: tableHeaderName,
                    alarmsName,
                });
            }
        },
        [alarmsName, currentPage, finalDate, handleRequestList, initialDate],
    );

    const handleChecked = useCallback((data: EventDTO) => selectedEvents.findIndex(event => event.id === data.id) !== -1, [selectedEvents]);

    const handleSelectAll = useCallback(() => {
        if (events?.elements) {
            selectAll(events.elements.map(e => e as EventDTO));
        }
    }, [events, selectAll]);

    const tableHeaders = useMemo(
        () =>
            [
                <Checkbox
                    onChange={() => handleSelectAll()}
                    checked={allOnThisPageAreSelected}
                    mode={'blue-check'}
                    name={'checkboxAll'}
                    key="checkboxAll"
                    hidden={eventScreenType === 'events'}
                />,
                t('alarms.captures'),
                eventScreenType === 'events' ? t('alarms.event') : t('alarms.alarm'),
                t('alarms.velocity'),
                t('alarms.hour'),
                t('alarms.operation'),
                t('alarms.fleet'),
                isAnalyst && t('alarms.arrival'),
                t('alarms.priority'),
                isAnalyst && t('configurations.analyst'),
            ].filter(x => x !== false),
        [allOnThisPageAreSelected, eventScreenType, handleSelectAll, isAnalyst, t],
    );

    const tableList = useMemo(() => {
        if (!events?.elements) {
            return null;
        }

        const styleDefault: CSSProperties = {
            overflowX: 'auto',
        };

        const styleCriticalCustomer: CSSProperties = {
            border: '4px solid #d50000',
            fontWeight: '600',
            color: '#1D1D1D',
        };

        const handleSelectEventToProcess = (event: any, data: EventDTO) => {
            if (!event.shiftKey || selectedEvents.length === 0) {
                handleSelectEvent(data);
            }
        };

        const criticalEvent = (data: EventDTO) => {
            if (!isAnalyst) {
                return false;
            }

            if (data.customer && criticalCustomers.includes(data.customer)) {
                return true;
            }

            if (data.operation && criticalOperations.includes(data.operation)) {
                return true;
            }

            return false;
        };

        return (
            <Table
                tableHeaders={tableHeaders}
                sortData={handleChangeSortTable}
                allowSort={true}
                handleHeaderClick={handleHeaderClick}
                sortElements={sortElements}
                newSortElements={newSortElements}
                style={{
                    width: isAnalyst && displaySidebar ? '105%' : '100%',
                    overflowX: 'auto',
                }}
            >
                {events.elements.map(data => {
                    if (!data) return null;

                    const isCriticalEvent = criticalEvent(data);

                    return (
                        <TableRow
                            className="body pointer"
                            key={data.id.toString()}
                            onClick={e => handleSelectEventToProcess(e, data)}
                            style={isCriticalEvent ? { ...styleDefault, ...styleCriticalCustomer, width: '155px' } : styleDefault}
                        >
                            <TableData
                                style={{
                                    width: '0.25rem',
                                }}
                            >
                                <Checkbox checked={handleChecked(data)} mode={'blue-check'} name={`${data.id.toString()}.${data.alarm}`} readOnly />
                            </TableData>
                            <TableData
                                style={{
                                    width: '250px',
                                    maxWidth: '100%',
                                }}
                            >
                                <Row
                                    gap={8}
                                    align="center"
                                    justify="flex-start"
                                    style={{
                                        marginBottom: '8px',
                                    }}
                                >
                                    {data.videoUrl !== null && data.videoUrl !== '' ? (
                                        <VideoPlayer
                                            source={data.videoUrl}
                                            type="video/mp4"
                                            autoPlay
                                            muted
                                            loop
                                            onClick={event => {
                                                if (document.pictureInPictureElement !== event.currentTarget) {
                                                    if (event.currentTarget.requestPictureInPicture) {
                                                        event.currentTarget.requestPictureInPicture();
                                                    }
                                                } else {
                                                    document.exitPictureInPicture();
                                                }
                                            }}
                                            style={{
                                                borderRadius: '4px',
                                                width: `${media.width}px`,
                                                height: `${media.heigth}px`,
                                                objectFit: 'fill',
                                            }}
                                        />
                                    ) : data.photoUrl ? (
                                        <Image
                                            src={data.photoUrl || undefined}
                                            alt={data.alarm || 'Generic image'}
                                            width={media.width - 5}
                                            height={media.heigth}
                                            style={{
                                                borderRadius: '4px',
                                                objectFit: 'fill',
                                            }}
                                        />
                                    ) : (
                                        <Image
                                            style={{
                                                borderRadius: '4px',
                                                width: `${media.width}px`,
                                                height: `${media.heigth}px`,
                                                objectFit: 'cover',
                                            }}
                                        />
                                    )}
                                </Row>
                            </TableData>
                            <TableData
                                style={{
                                    color: isCriticalEvent ? styleCriticalCustomer.color : undefined,
                                    width: '10%',
                                }}
                            >
                                {eventScreenType === 'events' ? t(`tableEventName.${data.event}`) : t(`tableEventName.${data.alarm}`)}
                            </TableData>
                            <TableData
                                style={{
                                    color: isCriticalEvent ? styleCriticalCustomer.color : undefined,
                                    width: '7%',
                                }}
                            >
                                {data.speedInKmH}
                            </TableData>
                            <TableData
                                style={{
                                    color: isCriticalEvent ? styleCriticalCustomer.color : undefined,
                                    width: '8%',
                                }}
                            >
                                {DateHelper.format(data?.alarmTime, i18n.language, 'UTC')}
                            </TableData>
                            <TableData
                                style={{
                                    color: isCriticalEvent ? styleCriticalCustomer.color : undefined,
                                    width: isAnalyst ? '15%' : undefined,
                                }}
                            >
                                {data.operation}
                            </TableData>
                            <TableData
                                style={{
                                    color: isCriticalEvent ? styleCriticalCustomer.color : undefined,
                                    width: isAnalyst ? '5%' : '8%',
                                }}
                            >
                                {data.fleet}
                            </TableData>
                            {isAnalyst && (
                                <TableData
                                    style={{
                                        color: isCriticalEvent ? styleCriticalCustomer.color : undefined,
                                        width: isAnalyst ? '7.5%' : '10%',
                                    }}
                                >
                                    {DateHelper.format(data?.alarmArrivalTime, i18n.language, 'UTC')}
                                </TableData>
                            )}
                            <TableData
                                style={{
                                    color: isCriticalEvent ? styleCriticalCustomer.color : undefined,
                                    width: '5%',
                                }}
                            >
                                {data.priority ? <Tag>{data.priority}</Tag> : null}
                            </TableData>
                            {isAnalyst && (
                                <TableData
                                    style={{
                                        color: isCriticalEvent ? styleCriticalCustomer.color : undefined,
                                    }}
                                >
                                    {eventScreenType === 'events' ? data.analyst : data.analyst_per_event}
                                </TableData>
                            )}
                        </TableRow>
                    );
                })}
            </Table>
        );
    }, [
        events?.elements,
        tableHeaders,
        handleChangeSortTable,
        handleHeaderClick,
        sortElements,
        newSortElements,
        displaySidebar,
        selectedEvents.length,
        handleSelectEvent,
        isAnalyst,
        handleChecked,
        eventScreenType,
        t,
        i18n.language,
        media,
    ]);

    return tableList;
}
