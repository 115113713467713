import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../button';
import { Trash } from '../icons';

interface DeleteButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
}

const StyledButton = styled(Button)`
    &:hover {
        svg path {
            stroke: #fff;
        }
    }
`;

export default function DeleteButton({ onClick }: DeleteButtonProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <StyledButton type="button" color="alert" size="medium" padding="0 12px" leftIcon={<Trash />} onClick={onClick}>
            {t('configurations.delete')}
        </StyledButton>
    );
}
