import { createContext, useCallback, useMemo, useState } from 'react';
import { FunctionComponent, TypeSearch } from 'src/common';
import { ResponseStatus } from 'src/common/core/api-response';
import { Pagination } from 'src/common/core/pagination';
import { User } from 'src/modules/auth/contexts';

import { CreateUserDTO, ListUsersDTO } from '../dtos/user.dto';
import { userService } from '../services/user.service';
import { defaultValues } from './default-form-values';
import { UsersContextProps } from './types';

export const UsersContext = createContext({} as UsersContextProps);

export default function UsersProvider({ children }: FunctionComponent) {
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [requestData, setRequestData] = useState<ListUsersDTO>({
        currentPage: 1,
        pageSize: 15,
        enabled: true,
        typeSearch: TypeSearch.PARAMS,
    });
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
    });
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [users, setUsers] = useState<Pagination<User> | null>(null);
    const [initialFormData, setInitialFormData] = useState<CreateUserDTO>(defaultValues);

    const allOnThisPageAreSelected: boolean = useMemo(
        () =>
            users?.elements && users?.elements?.length > 0
                ? users.elements?.every(value => selectedUsers.findIndex(dt => value && dt.id === value.id) !== -1)
                : false,
        [selectedUsers, users?.elements],
    );

    const handleGetList = useCallback(async () => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        userService
            .list(requestData)
            .then(data => {
                setUsers(data);
                setResponseStatus({
                    loading: false,
                    error: undefined,
                    hasError: false,
                    void: !data?.elements || data?.elements?.length === 0,
                    success: true,
                });
            })
            .catch(reason =>
                setResponseStatus({
                    loading: false,
                    error: reason,
                    hasError: true,
                    void: false,
                    success: false,
                }),
            );
    }, [requestData]);

    const handleOpenDialog = useCallback((data?: User | null) => {
        const initialData: CreateUserDTO = data
            ? {
                  email: data.email,
                  emailVerified: true,
                  enabled: JSON.stringify(Boolean(data.enabled)),
                  function: data.function || '',
                  name: data.name || '',
                  phone: data?.phoneNumber || '',
                  id: data.id,
                  isAdmin: Boolean(data.isAdmin),
                  notificationSound: Boolean(data.notificationSound ?? false),
                  uuid: data.uuid,
                  passwordPolicy: data.passwordPolicy || '',
                  profile: data.profile || 'client',
              }
            : defaultValues;

        setDialogIsOpen(state => {
            if (state) {
                setInitialFormData(defaultValues);
            } else {
                setInitialFormData(initialData);
            }

            return !state;
        });
    }, []);

    const handleRequestList = useCallback((data: Partial<ListUsersDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleSelectAll = useCallback((data: Array<User>) => {
        setSelectedUsers(state => {
            const all = state.length > 0 && data?.every(value => state.findIndex(dt => dt.id === value.id) !== -1);

            if (all) {
                return [];
            }

            return [...state, ...data].filter((elem, index, self) => index === self.indexOf(elem));
        });
    }, []);

    const handleSelect = useCallback((data: User) => {
        setSelectedUsers(state => {
            const index = state.findIndex(event => event.id === data.id);

            if (index === -1) {
                return [...state, data];
            }

            return state.filter(event => event.id !== data.id);
        });
    }, []);

    const data: UsersContextProps = useMemo(
        () => ({
            allOnThisPageAreSelected,
            dialogIsOpen,
            handleGetList,
            handleOpenDialog,
            handleRequestList,
            handleSelectAll,
            handleSelect,
            initialFormData,
            requestData,
            responseStatus,
            selectedUsers,
            users,
        }),
        [
            allOnThisPageAreSelected,
            dialogIsOpen,
            handleGetList,
            handleOpenDialog,
            handleRequestList,
            handleSelectAll,
            handleSelect,
            initialFormData,
            requestData,
            responseStatus,
            selectedUsers,
            users,
        ],
    );

    return <UsersContext.Provider value={data}>{children}</UsersContext.Provider>;
}
