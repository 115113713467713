import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, RenderIf, Textarea, ToggleSwitch, usePermission } from 'src/common';
import { Button, CloseButton, Column, Divider, Input, MainCard, Row } from 'src/common/components';
import { toast } from 'src/common/components/toast';
import { DepartmentResume, departmentService } from 'src/modules/configurations/departments';
import AmbigousContactDialog from 'src/modules/configurations/vehicles/components/ambigous-contact-dialog';

import { CreatePasswordPolicyFormDTO } from '../../dtos/create-password-policy.dto';
import { usePasswordPolicies } from '../../hooks';
import { passwordPolicyService } from '../../services';
import { createPasswordPolicySchema } from '../../validations';
import { Form, Grid, StyledModal } from './styles';
import { TextInput } from './text-input';

interface CreateOrUpdatePolicyDialogProps {
    open: boolean;
    onClose(): void;
}

export default function CreateOrUpdatePolicyDialog({ onClose, open }: CreateOrUpdatePolicyDialogProps) {
    const [ambiguousParents, setAmbiguousParents] = useState<DepartmentResume[]>([]);
    const [parentId, setParentId] = useState<number | null>(null);
    const [showAmbiguousModal, setShowAmbiguousModal] = useState(false);

    const { operations, setOperations, setFilters, initialFormData } = usePasswordPolicies();
    const { isAdmin } = usePermission();
    const { t } = useTranslation();
    const { mutateAsync, isLoading } = useMutation({
        mutationKey: ['create-or-update-password-policy'],
        mutationFn: initialFormData?.id ? passwordPolicyService.update : passwordPolicyService.create,
        onSuccess: () => {
            toast.success(t('general.success'), '');
            setFilters(state => ({ ...state, currentPage: 1 }));
            onClose();
        },
        onError: error => {
            if (error instanceof AxiosError) {
                if (error.response?.data.message === 'RECORD_ALREADY_EXISTS') {
                    return toast.error(t('general.error'), t('field_validations.record_already_exists'));
                }
            }
            toast.error(t('general.error'), '');
        },
    });

    const {
        formState: { errors, isValid },
        handleSubmit: onSubmit,
        register,
        setValue,
        watch,
    } = useForm<CreatePasswordPolicyFormDTO>({
        defaultValues: initialFormData,
        mode: 'onChange',
        resolver: yupResolver(createPasswordPolicySchema),
    });

    const handleSubmit = useCallback(
        async (data: CreatePasswordPolicyFormDTO) => {
            const operationsFiltered = operations.data.filter(o => o.name?.toUpperCase()?.trim() === data.organization?.toUpperCase()?.trim());

            if (!operationsFiltered?.length) {
                toast.error(t('general.error'), `${t('field_validations.invalid_record')}: ${t('monitoring.organization')}`);
                return;
            }

            if (initialFormData?.organization !== data.organization) {
                if (!parentId) {
                    if (operationsFiltered.length > 1) {
                        setAmbiguousParents(operationsFiltered);
                        setShowAmbiguousModal(true);
                        return;
                    }
                }
            }

            await mutateAsync({
                ...data,
                operationId: parentId || operationsFiltered[0].id,
                id: initialFormData?.id as string,
            });
        },
        [initialFormData?.id, initialFormData?.organization, mutateAsync, operations.data, parentId, t],
    );

    const operation = watch('organization');
    const title = useMemo(() => {
        if (!isAdmin) {
            return t('configurations.view_password_policy');
        }

        return t(initialFormData?.id ? 'configurations.edit_password_policy' : 'configurations.create_password_policy');
    }, [initialFormData?.id, isAdmin, t]);

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    active: true,
                    currentPage: 1,
                    pageSize: 15,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations]);

    const isReadOnly = useMemo(
        () => ({
            disabled: !isAdmin,
            readOnly: !isAdmin,
        }),
        [isAdmin],
    );

    return (
        <Fragment>
            <StyledModal open={open} onClose={onClose}>
                <MainCard className="card">
                    <Form onSubmit={onSubmit(handleSubmit)}>
                        <Column padding="24px" className="bottom" gap={32} align="flex-start" width="656px" height="100%">
                            <Column gap={24} width="100%" align="flex-start">
                                <Column gap={16} align="flex-start" width="100%">
                                    <Row gap={48} align="flex-start" justify="space-between" width="100%">
                                        <h4 className="modal-title">{title}</h4>
                                        <CloseButton type="button" onClick={onClose} />
                                    </Row>
                                    <Row gap={16} width="100%">
                                        <Input
                                            label={String(t('configurations.password_policy_name'))}
                                            placeholder={String(t('general.write'))}
                                            autoComplete="off"
                                            hint="*"
                                            feedback={{
                                                message: t(errors?.name?.message || '')?.toString(),
                                            }}
                                            {...register('name')}
                                            {...isReadOnly}
                                        />
                                        <Autocomplete
                                            hint="*"
                                            isLoading={operations.isLoading}
                                            label={String(t('configurations.organization'))}
                                            options={operations.data.map(o => ({ id: o.id, value: o.name }))}
                                            placeholder={String(t('general.write'))}
                                            setValue={setValue}
                                            feedback={{
                                                message: t(errors.organization?.message || '')?.toString(),
                                            }}
                                            {...register('organization')}
                                            nextElement={{
                                                id: 'passwordMinimumLength',
                                            }}
                                            showErrorOnMount
                                            {...isReadOnly}
                                        />
                                    </Row>
                                    <Row gap={16} width="100%">
                                        <Input
                                            type="number"
                                            min={0}
                                            label={String(t('configurations.expiry_time'))}
                                            placeholder={String(t('general.write'))}
                                            autoComplete="off"
                                            feedback={{
                                                message: t(errors?.expiryTime?.message || '')?.toString(),
                                            }}
                                            {...register('expiryTime')}
                                            {...isReadOnly}
                                        />
                                        <Input
                                            type="number"
                                            min={6}
                                            max={99}
                                            label={String(t('configurations.password_minimum_length'))}
                                            placeholder={String(t('general.write'))}
                                            autoComplete="off"
                                            hint="*"
                                            feedback={{
                                                message: t(errors?.passwordMinimumLength?.message || '')?.toString(),
                                            }}
                                            {...register('passwordMinimumLength')}
                                            {...isReadOnly}
                                            tooltip={String(t('field_validations.password_length_recommended'))}
                                        />
                                    </Row>
                                    <Row gap={16} width="100%">
                                        <Textarea
                                            label={String(t('general.description'))}
                                            placeholder={String(t('general.write'))}
                                            autoComplete="off"
                                            feedback={{
                                                message: t(errors?.description?.message || '')?.toString(),
                                            }}
                                            {...register('description')}
                                            {...isReadOnly}
                                        />
                                    </Row>
                                    <Grid>
                                        <ToggleSwitch
                                            label={String(t('configurations.password_policy_status'))}
                                            mode="small"
                                            {...register('active')}
                                            {...isReadOnly}
                                        />
                                        <ToggleSwitch
                                            label={String(t('configurations.mfa_validation'))}
                                            mode="small"
                                            {...register('mfa')}
                                            {...isReadOnly}
                                        />
                                        <ToggleSwitch
                                            label={String(t('configurations.contains_at_least_one_number'))}
                                            mode="small"
                                            {...register('containsAtLeastOneNumber')}
                                            {...isReadOnly}
                                        />
                                        <ToggleSwitch
                                            label={String(t('configurations.contains_at_least_one_uppercase_letter'))}
                                            mode="small"
                                            {...register('containsAtLeastOneUppercaseLetter')}
                                            {...isReadOnly}
                                        />
                                        <ToggleSwitch
                                            label={String(t('configurations.contains_at_least_one_lowercase_letter'))}
                                            mode="small"
                                            {...register('containsAtLeastOneLowercaseLetter')}
                                            {...isReadOnly}
                                        />
                                        <ToggleSwitch
                                            label={String(t('configurations.contains_at_least_one_special_character'))}
                                            mode="small"
                                            {...register('containsAtLeastOneSpecialCharacter')}
                                            {...isReadOnly}
                                        />
                                    </Grid>
                                    <TextInput {...register('passwordDiffFrom')} {...isReadOnly} />
                                </Column>
                                <Divider />
                            </Column>
                            <RenderIf condition={isAdmin}>
                                <Row align="flex-start" gap={16} width="100%">
                                    <Button color="alert" type="reset" onClick={onClose}>
                                        {t('general.cancel')}
                                    </Button>
                                    <Button type="submit" isLoading={isLoading || operations.isLoading} disabled={!isValid}>
                                        {t('general.confirm')}
                                    </Button>
                                </Row>
                            </RenderIf>
                        </Column>
                    </Form>
                </MainCard>
            </StyledModal>

            <RenderIf condition={showAmbiguousModal}>
                <AmbigousContactDialog
                    open={showAmbiguousModal}
                    onClose={() => setShowAmbiguousModal(false)}
                    parentId={parentId}
                    ambiguousParents={ambiguousParents}
                    onSelect={setParentId}
                    title={title}
                />
            </RenderIf>
        </Fragment>
    );
}
