import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'src/common/components/toast';

import { updateElectronicFence } from '../services/update-electronic-fence.service';
import { useElectronicFence } from './use-electronic-fence';

export function useUpdateElectronicFence() {
    const { t, i18n } = useTranslation();
    const { setIsOpen, setFilter } = useElectronicFence();

    return useMutation({
        mutationFn: updateElectronicFence.execute,
        mutationKey: ['update-electronic-fence'],
        onError: (error: unknown) => {
            if (error instanceof AxiosError) {
                return toast.error(
                    t('general.message.error.title'),
                    i18n.exists(error.response?.data?.message) ? t(error.response?.data?.message) : t('general.message.error.message'),
                );
            }
            toast.error(t('general.message.error.title'), t('general.message.error.message'));
        },
        onSuccess() {
            toast.success(t('general.success'), '');
            setFilter(state => ({ ...state, currentPage: 1 }));
            setIsOpen({ createOrUpdate: false, filter: false, map: false, delete: false });
        },
    });
}
