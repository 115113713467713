import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../common';
import Column from '../../../../../common/components/column';
import AutocompleteMultiple from '../../../../../common/components/inputs/autocomplete-multiple';
import { DepartmentResume, departmentService } from '../../../../configurations';
import { genericAnalyticsFilterSchema } from '../../../common/infra/validations/yup/generic-analytics-filter.schema';
import { LastAlarmFilterDTO } from '../../dtos/last-alarm-filter';
import { useLastAlarm } from '../../hook';
import { Container, Filter } from './styles';

export function LastAlarmFilter() {
    const { filter, operations, handleRequestList, setFilter, setOperations } = useLastAlarm();
    const { t } = useTranslation();

    const {
        setValue,
        register,
        handleSubmit: onSubmit,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: filter,
        mode: 'onChange',
        resolver: yupResolver(genericAnalyticsFilterSchema),
    });

    const handleSubmit = useCallback(
        (data: LastAlarmFilterDTO) => {
            setFilter({ ...data });
            handleRequestList({
                operations: data.operations,
            });
        },
        [handleRequestList, setFilter],
    );

    const operation = watch('operationInput');

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 30,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations, setValue]);

    return (
        <Container align="flex-start">
            <Filter onSubmit={onSubmit(handleSubmit)}>
                <AutocompleteMultiple
                    showErrorOnMount
                    autocompleteName="operations"
                    id="operation"
                    isLoading={operations.isLoading}
                    options={operations.data
                        .filter(operation => operation.type === 'Operação')
                        .map(operation => ({
                            id: operation.id,
                            value: operation.name,
                        }))}
                    nextElement={{
                        id: 'real-time-submit-button',
                    }}
                    feedback={{
                        message: t(errors.operations?.message || '')
                            ?.toString()
                            ?.replace('%%', '10'),
                    }}
                    label={String(t('general.operation'))}
                    placeholder={String(t('general.operation'))}
                    setValue={setValue}
                    type="text"
                    values={watch('operations', filter.operations)}
                    tooltip={t('field_validations.max')?.toString()?.replace('%%', '10')}
                    {...register('operationInput')}
                    disabled={watch('operations')?.length >= 10}
                />

                <Column justify="flex-end" height="60px">
                    <Column justify="flex-end" height="36px">
                        <Button type="submit" size="small" id="real-time-submit-button">
                            {t('general.apply')}
                        </Button>
                    </Column>
                </Column>
            </Filter>
        </Container>
    );
}
