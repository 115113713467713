import { vector } from 'echarts';
import { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from 'react';

import { CheckboxLabel } from './styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    mode: 'blue-check' | 'blue-check-rounded' | 'blue-fill' | 'gray-check';
    name: string;
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = ({ label, mode, ...props }, ref) => {
    return (
        <CheckboxLabel htmlFor={props?.id || props?.value?.toString() || props.name} mode={mode}>
            <input type="checkbox" id={props?.id || props?.value?.toString() || props.name} ref={ref} {...props} />
            <span>{label}</span>
        </CheckboxLabel>
    );
};

const Checkbox = forwardRef(CheckboxBase);

export default Checkbox;
