import { object, string } from 'yup';

export const redefinePasswordSchema = object().shape({
    email: string().email('field_validations.email').required('field_validations.required'),
    code: string().required('field_validations.required'),
    newPassword: string().required('field_validations.required'),
    newPasswordConfirmation: string()
        .required('field_validations.required')
        .test('is-equal', 'field_validations.passwords_match', (field, body) => field === body.parent.newPassword),
});
