import styled from 'styled-components';

export const IndexFilter = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
`;

export const ButtonChangeContainer = styled.div`
    display: flex;
    padding: 5px;

    > button {
        padding: 0.625rem;
        background-color: #e6e6e6;
        font-size: 20px;
        font-weight: 500;
        border: 0;
    }
`;

export const ButtonTab = styled.button`
    transition: 0.3s ease-in all;

    &:hover {
        background-color: #262d3c;
        color: #fff;
    }
`
