import { forwardRef, HTMLAttributes } from 'react';

import { Container } from './styles';

interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
}

const Badge = forwardRef<HTMLDivElement, BadgeProps>(function Base({ label, ...props }, ref) {
  return (
    <Container ref={ref} {...props}>
      <span>{label}</span>
    </Container>
  );
});

export { Badge };
