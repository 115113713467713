import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import gpsOffline from 'src/assets/icons/car-offline.svg';
import gpsOnline from 'src/assets/icons/car-online.svg';
import { Field } from 'src/common/components';
import Column from 'src/common/components/column';
import { DateHelper } from 'src/common/helpers';

import { RealTimeLocation } from '../../domain/entities/real-time-location';
import { useRealTimeLocation } from '../../hooks';

interface IsShowingProps {
    infoWindow: boolean;
}

export default function RealTimeMarker(realTimeLocation: RealTimeLocation) {
    const { t } = useTranslation();

    const [isShowing, setIsShowing] = useState<IsShowingProps>({ infoWindow: false });
    const { setModalData } = useRealTimeLocation();

    const position: google.maps.LatLngLiteral = {
        lat: realTimeLocation.coordinates.latitude,
        lng: realTimeLocation.coordinates.longitude,
    };

    return (
        <Marker
            icon={realTimeLocation.online ? gpsOnline : gpsOffline}
            position={position}
            onClick={() => setModalData(state => ({ isOpen: !state.isOpen, realTimeLocation }))}
            onMouseOver={() => setIsShowing(state => ({ ...state, infoWindow: true }))}
            onMouseOut={() => setIsShowing(state => ({ ...state, infoWindow: false }))}
        >
            {isShowing.infoWindow && (
                <InfoWindow position={position} onCloseClick={() => setIsShowing(state => ({ ...state, infoWindow: false }))}>
                    <Column gap={2} align="flex-start">
                        <Field name={t('alarms.board')} value={realTimeLocation.licensePlate} />
                        <Field name={t('monitoring.position')} value={DateHelper.toDateString(realTimeLocation.positioningTime)} />
                        <Field name={t('alarms.velocity')} value={`${realTimeLocation.currentSpeed} km/h`} />
                        <Field name={t('monitoring.direction')} value={t(`monitoring.compass_points.${realTimeLocation.direction.toLowerCase()}`)} />
                        <Field name={t('monitoring.filiation')} value={realTimeLocation.operationName || t('general.information_unavailable')} />
                        <Field name={t('monitoring.lat')} value={realTimeLocation.coordinates.latitude?.toString()} />
                        <Field name={t('monitoring.long')} value={realTimeLocation.coordinates.longitude?.toString()} />
                    </Column>
                </InfoWindow>
            )}
        </Marker>
    );
}
