import { useTranslation } from 'react-i18next';
import { Column, Field } from 'src/common/components';
import { DateHelper } from 'src/modules/alarm-processing/helpers/date.helper';
import { useSortReviewEvent } from 'src/modules/alarm-processing/hooks';

import { EventName } from './styles';

export default function EventInfo() {
    const { showEvent } = useSortReviewEvent();
    const { t, i18n } = useTranslation();

    return (
        <Column gap={5} align="flex-start" width="328px">
            <EventName>{t(`tableEventName.${showEvent.data?.alarm}`)}</EventName>
            <Column gap={8} align="flex-start">
                <Field name={t('alarms.operation')} value={showEvent.data?.operation || t('general.information_unavailable')} />
                <Field name={t('alarms.fleet')} value={showEvent.data?.fleet || t('general.information_unavailable')} />
                <Field
                    name={t('alarms.alarm_time')}
                    value={
                        showEvent.data?.alarmTime ? DateHelper.format(showEvent.data.alarmTime, i18n.language, 'UTC') : t('general.information_unavailable')
                    }
                />
                <Field
                    name={t('alarms.velocity')}
                    value={showEvent.data?.speedInKmH ? showEvent.data.speedInKmH : t('general.information_unavailable')}
                />
            </Column>
        </Column>
    );
}
