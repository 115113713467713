import { forwardRef, SelectHTMLAttributes, useMemo } from 'react';
import { Column, Feedback } from 'src/common/components';
import { useFeedback } from 'src/common/hooks';
import { FeedbackType } from 'src/common/types';

import { InputProps as DefaultInputProps } from '../index';
import Label from '../label';
import { StyledSelect } from '../styles';

type Option = {
    label: string;
    value?: string | number | readonly string[];
};

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> &
    DefaultInputProps & {
        name: string;
        options: Option[];
    };

const Select = forwardRef<HTMLSelectElement, SelectProps>(function Base(
    { feedback = { message: '', type: FeedbackType.ERROR }, label, hint, name, options, ...props },
    ref,
) {
    const { className } = useFeedback(feedback);

    const feedbackComponent = useMemo(() => {
        if (feedback?.message) {
            return <Feedback {...feedback} />;
        }

        return null;
    }, [feedback]);

    return (
        <Column align="flex-end" justify="flex-end" gap={8} style={props?.style} flex>
            <Label label={label} hint={hint} htmlFor={name}>
                <StyledSelect className={className} id={name} name={name} ref={ref} {...props}>
                    <option value="" hidden data-placeholder>
                        {props?.placeholder}
                    </option>
                    {options.map(opt => (
                        <option key={opt.label} value={opt?.value || opt.label}>
                            {opt.label}
                        </option>
                    ))}
                </StyledSelect>
            </Label>
            {feedbackComponent}
        </Column>
    );
});

export default Select;
