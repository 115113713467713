import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    padding-right: 2px;
    gap: 6px;
    cursor: default;

    background: #f1f1f1;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #7b7b7b;

    &:hover {
        background-color: #f0f0f0;
        color: #46526c;
    }
`;

export const CloseButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    cursor: pointer;
    height: 24px;
    background-color: transparent;

    svg path {
        stroke: #92979d;
    }

    &:hover svg path {
        stroke: #46526c;
    }
`;
