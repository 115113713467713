import { FormEventHandler, InputHTMLAttributes, forwardRef, useRef } from 'react';

import { Label } from './styles';

interface RangeProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    onFormat?: (value: string) => string;
}

export default forwardRef<HTMLInputElement, RangeProps>(function Range({ label, onFormat, ...props }, ref) {
    const outputRef = useRef<HTMLOutputElement>(null);

    const handleChangeOutput: FormEventHandler<HTMLInputElement> = e => {
        if (outputRef.current) {
            outputRef.current.value = onFormat ? onFormat(e.currentTarget.value) : e.currentTarget.value;
        }
    };

    return (
        <Label htmlFor={props?.name}>
            {label}
            <input type="range" {...props} ref={ref} onInput={handleChangeOutput} />
            <output ref={outputRef}>{props?.value}</output>
        </Label>
    );
});
