import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'src/common/components';
import Column from 'src/common/components/column';
import { DateHelper } from 'src/common/helpers';
import { HistoricalRoute } from 'src/modules/alarm-processing/domain/models/historical-route';

export default function MapCircleHistoricalRoute(route: HistoricalRoute) {
    const { t, i18n } = useTranslation();

    const [showInfo, setShowInfo] = useState(false);

    const markerIcon: google.maps.Icon | google.maps.Symbol = {
        fillColor: '#6DA4D3',
        fillOpacity: 0.9,
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeColor: '#6DA4D3',
        strokeWeight: 2,
    };

    const position: google.maps.LatLngLiteral = {
        lat: route.latitude,
        lng: route.longitude,
    };

    return (
        <Marker icon={markerIcon} key={route.id} position={position} onClick={() => setShowInfo(state => !state)}>
            {showInfo && (
                <InfoWindow position={position} onCloseClick={() => setShowInfo(false)}>
                    <Column gap={4} align="flex-start">
                        <Field
                            name={t('alarms.arrival')}
                            value={
                                route.shippingTime
                                    ? DateHelper.format(route?.shippingTime, i18n.language, 'UTC')
                                    : t('general.information_unavailable')
                            }
                        />
                        <Field name={t('monitoring.lat')} value={route.latitude.toString()} />
                        <Field name={t('monitoring.long')} value={route.longitude.toString()} />
                    </Column>
                </InfoWindow>
            )}
        </Marker>
    );
}
