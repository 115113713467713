import { RadioText } from "./styles";

export interface RadioButtonProps {
    group: string;
    checked: boolean;
    name: string;
    value: string;
}

export default function RadioButton( {group, checked, name, value }: RadioButtonProps) {
    return (
        <div>
            <RadioText>
                <input id={value} type="radio" value={value} name={group} checked={checked} />
                {name}
            </RadioText>
        </div>
    )
}