import { SVGProps } from 'react';

interface RefreshProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export default function Refresh({ color = '#fff', ...props }: RefreshProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11 18L10.2929 17.2929L9.58579 18L10.2929 18.7071L11 18ZM13.2929 14.2929L10.2929 17.2929L11.7071 18.7071L14.7071 15.7071L13.2929 14.2929ZM10.2929 18.7071L13.2929 21.7071L14.7071 20.2929L11.7071 17.2929L10.2929 18.7071Z"
                fill={color}
            />
            <path
                d="M6.80385 15C6.14344 13.8561 5.87893 12.5263 6.05133 11.2168C6.22373 9.90734 6.82341 8.69131 7.75736 7.75736C8.69131 6.82341 9.90734 6.22373 11.2168 6.05133C12.5263 5.87893 13.8561 6.14344 15 6.80385C16.1439 7.46425 17.0378 8.48363 17.5433 9.7039C18.0487 10.9242 18.1374 12.2771 17.7956 13.5529C17.4537 14.8287 16.7004 15.9561 15.6526 16.7601C14.6047 17.5642 13.3208 18 12 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}
