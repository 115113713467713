import styled from 'styled-components';

const UpdateBox = styled.button`
    font-family: 'Space Grotesk';
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: underline;
    color: #6da4d3;

    cursor: pointer;
    background: none;
    border: 0;
    margin: 0;
    padding: 0;

    p {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

export default UpdateBox;
