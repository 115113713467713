import styled from 'styled-components';

interface LineProps {
    type: 'ERROR' | 'SUCCESS';
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    line-height: 20px;

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #262d3c;
        font-family: 'Space Grotesk';
    }
    .body {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: #7b7b7b;
        font-family: 'Space Grotesk';
    }
`;

export const Line = styled.div<LineProps>`
    background-color: ${props => (props.type === 'ERROR' ? '#EF1D00' : '#6da4d3')};
    border-radius: 8px;
    height: 70%;
    position: absolute;
    width: 4px;
    left: 10px;
    top: 13px;
`;
