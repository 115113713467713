import styled, { css } from 'styled-components';

interface ButtonProps {
    selected?: boolean;
}

export const ButtonGroup = styled.div`
    background: #f5f5f5;
    border-radius: 6px;
    padding: 2px;

    display: flex;
    align-items: center;
    gap: 2px;
`;

export const InputButton = styled.input<ButtonProps>`
    background: transparent;
    border-radius: 4px;
    border: 0;

    font-family: 'Space Grotesk';
    font-weight: 500;
    font-size: 8px;
    line-height: 20px;

    color: #7b7b7b;

    cursor: pointer;

    ${props =>
        props.selected &&
        css`
            background: #ffffff;
            color: #6da4d3;
        `}

    &:hover {
        background: #ffffff;
        color: #6da4d3;
    }
`;
