import { Menu, MenuItem } from '@mui/material';
import { HTMLAttributes, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import brazilFlag from 'src/assets/flags/brazil-flag.svg';
import locales from 'src/common/infra/translations/locales';

import { ChevronDown } from '../icons';
import { ChveronContainer, LanguageSelectorContainer } from './styles';

export interface LanguageSelectorProps extends HTMLAttributes<HTMLDivElement> {
    color: string;
}

export default function LanguageSelector(props: LanguageSelectorProps) {
    const { t, i18n } = useTranslation();

    const chevronRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleCloseMenu = useCallback(() => {
        setOpen(false);
    }, []);

    const handleOpenMenu = useCallback(() => {
        setOpen(true);
    }, []);

    const handleChangeLanguage = useCallback(
        async (language: string) => {
            await i18n.changeLanguage(language);
            handleCloseMenu();
        },
        [handleCloseMenu, i18n],
    );

    const selectedLocale = useMemo(() => locales[i18n.language], [i18n.language]);

    return (
        <LanguageSelectorContainer onClick={handleOpenMenu} {...props}>
            <img src={(selectedLocale?.flag as string | undefined) || brazilFlag} alt="flag" width={18} height={18} />
            <p>{t('menu.language')}</p>
            <ChveronContainer
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                ref={chevronRef}
            >
                <ChevronDown color={props.color} />
            </ChveronContainer>

            <Menu
                id="language-menu"
                anchorEl={chevronRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={open}
                onClose={handleCloseMenu}
            >
                {Object.keys(locales).map(key => {
                    return (
                        <MenuItem onClick={() => handleChangeLanguage(key)} key={key}>
                            {(locales[key].translations as any)?.menu?.language}
                        </MenuItem>
                    );
                })}
            </Menu>
        </LanguageSelectorContainer>
    );
}
