import styled from 'styled-components';

export const MainContainer = styled.main`
    background: '#fff';
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ChartContainer = styled.div`
    padding: 1rem 1rem 2.5rem;
    height: 50vh;
    border: 1px solid #d9d9d9;
    border-radius: 0.6rem;
    width: 49%;

    .filterDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filterButton {
        border: none;
        background-color: white;
        cursor: pointer;
    }

    .filters {
        position: relative;
        margin-left: 1.5625rem;
    }

    .radioInput {
        display: flex;
        flex-direction: column;
        align-items: initial;
        padding: 0.4rem 0 0.6rem;
    }

    .radioInputGraphs {
        display: flex;
        align-items: initial;
        margin: 0.5rem 0;
    }

    .heatMapInputs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        gap: 2rem;

        div {
            width: 100%;
        }
    }

    .lineChart {
        height: 400px;
        margin-top: -1.875rem;
    }
`;

export const ChartContentPie = styled.div`
    height: 98%;
    margin-top: 1rem;
`;

export const ChartContent = styled.div`
    height: 90%;
    margin-top: 1rem;
`;

export const ChartContentLineChart = styled.div`
    height: 85%;
    padding: 1.25rem 1rem;
`;

const BaseText = styled.p`
    letter-spacing: 0em;
    text-align: left;
`;

export const ChartSubTitle = styled(BaseText)`
    font-size: 0.813rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-top: -20px;
    color: #b9b6b6;
`;

export const DefaultText = styled.p`
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: #262d3c;
`;

export const OptionsText = styled(DefaultText)`
    font-size: 1.625rem;
`;

export const RadioText = styled(DefaultText)`
    font-size: 1rem;
`;
