import { ApiResponse } from 'src/common/core';
import { Pagination } from 'src/common/core/pagination';
import { TransformRequestHelper } from 'src/common/helpers';
import { api } from 'src/common/infra';

import { Department, DepartmentResume, ListDepartmentsDTO } from '../dtos';
import { ContactDTO } from '../dtos/contact.dto';
import { CreateDepartmetRequestDTO } from '../dtos/create-department.dto';
import { HierarchyOperation } from '../dtos/hierarchy.dto';
import { ListUserOperationsDTO } from '../dtos/list-user-operations.dto';

export class DepartmentService {
    public async create(data: CreateDepartmetRequestDTO): Promise<void> {
        await api.post('/operations', data);
    }

    public async delete(id: number): Promise<void> {
        await api.delete(`/operations/${id}`);
    }

    public async deleteInBulk(ids: number[]): Promise<void> {
        await api.delete('/operations-in-bulk', {
            data: {
                ids,
            },
        });
    }

    public async getHierarchicalList(): Promise<HierarchyOperation[]> {
        const {
            data: { content },
        } = await api.get<ApiResponse<Array<HierarchyOperation>>>(`/operations/hierarchy`);

        return content;
    }

    public async listContacts(id: number): Promise<Array<ContactDTO>> {
        const {
            data: { content },
        } = await api.get<ApiResponse<Array<ContactDTO>>>(`/operations/${id}/contacts`);

        return content;
    }

    public async listDepartments(data: ListDepartmentsDTO): Promise<Pagination<Department>> {
        const { pageSize, currentPage, active, name, parentName, text, controller } = data;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<Department>>>('/operations', {
            params: {
                active,
                name: name || text,
                parentName,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
            signal: controller?.signal,
        });

        return content;
    }

    public async me(request: ListUserOperationsDTO): Promise<Pagination<DepartmentResume>> {
        const { controller, currentPage, name, pageSize } = request;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<DepartmentResume>>>('/operations/me', {
            signal: controller.signal,
            params: {
                name,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
            },
        });

        return content;
    }

    public async update(data: CreateDepartmetRequestDTO & { id: number }): Promise<void> {
        await api.put(`/operations/${data.id}`, data);
    }
}

export const departmentService = new DepartmentService();
