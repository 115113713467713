import styled from 'styled-components';

interface CardContainerProps {
    isSidebarOpen: boolean;
}

export const CardContainer = styled.div<CardContainerProps>`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 0.5rem;
    width: ${props => (props?.isSidebarOpen ? '22.5rem' : '26.5rem')};
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

export const CardHeader = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
`;

interface CardHeaderIconProps {
    color?: string;
}

export const CardHeaderIcon = styled.div<CardHeaderIconProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    padding: 0.8rem 1rem;
    border-radius: 0.5rem;
    background-color: ${props => props.color || '#4f8dca'};
    margin-top: -70px;
    box-shadow: 0 6px 8px -3px ${props => props.color || '#4f8dca'}, 0 4px 6px -3px rgba(0, 0, 0, 0.8);
`;

export const CardHeaderInfo = styled.div``;

export const CardHeaderInfoTitle = styled.p`
    font-size: 1rem;
    font-weight: 400;
    text-align: right;
    margin-bottom: 0.2rem;
`;

export const CardHeaderInfoValue = styled.p`
    font-size: 2.2rem;
    font-weight: 600;
    text-align: right;
`;

export const CardFooter = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: rgb(185, 182, 182);
    font-weight: 500;

    > p {
        font-size: 1rem;
        font-weight: 600;

        > span {
            color: green;
            font-weight: 500;
        }
    }
`;
