import styled from 'styled-components';

export const CheckboxLegend = styled.span`
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #262d3c;
`;

export const CloseButton = styled.button`
    border: 0;
    box-sizing: border-box;
    height: 30px;
    padding: 0;
    width: 30px;

    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &:hover {
        background-color: buttonface;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;

    h4 {
        color: #262d3c;
        margin: 0;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        color: #7b7b7b;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 8px;
`;

export const Title = styled.h4`
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;

    color: #262d3c;
`;
