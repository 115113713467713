import styled from 'styled-components';

export const GraphSection = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
`;

export const GraphContainer = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: 1px solid #d9d9d9;
    border-radius: 0.625rem;
    padding: 1.25rem;
    position: relative;
`;

export const GraphTitle = styled.div`
    display: flex;
    flex-direction: column;

    > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    p {
        font-size: 1rem;
        color: #b9b6b6;
    }
`;

export const ButtonFilter = styled.button`
    background: transparent;
    border: none;
`;

export const RankingSection = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
`;

export const RankingContainer = styled.div`
    width: 100%;
    height: 32rem;
    border: 1px solid #d9d9d9;
    border-radius: 0.625rem;
    padding: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const FilterOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-bottom: 0.3rem ;

    p {
        font-weight: 500;
    }

    label {
        font-weight: 400;
    }
`;

export const RadioButtonContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const FilterRange = styled.section`
    position: absolute;
    /* top: 90%;
    left: 52%;
    transform: translate(-50%, -90%);
    width: 30rem;
    height: 20%; */

    div {
        label {
            font-weight: 200;
        }
    }
`;

export const FilterMap = styled.section`
    padding: 0;

    > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`;

export const ModalFilter = styled.div`
    position: absolute;
    right: 0;

    .heatMapInputs {
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        margin-bottom: 1rem;
        gap: 2rem;

        div {
            width: 100%;
        }
    }
`;

export const FilterTimeRangeRadioContainer = styled.div`
    display: flex;
    gap: 1rem;
`;
