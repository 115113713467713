import { SVGProps } from 'react';

export default function PinAlt({ color, ...props }: SVGProps<SVGSVGElement>) {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.3978 17.0267L10.9385 16.1384L11.3978 17.0267ZM10.6022 17.0267L10.1429 17.915L10.6022 17.0267ZM17.2593 7.8889C17.2593 10.1349 16.1122 11.9842 14.6888 13.4155C13.2664 14.8456 11.6527 15.7691 10.9385 16.1384L11.8571 17.915C12.6609 17.4994 14.478 16.4636 16.1068 14.8258C17.7346 13.1892 19.2593 10.8621 19.2593 7.8889H17.2593ZM11.0615 16.1384C10.3473 15.7691 8.73364 14.8456 7.31126 13.4155C5.88779 11.9842 4.74075 10.1349 4.74075 7.8889H2.74075C2.74075 10.8621 4.26545 13.1892 5.8932 14.8258C7.52206 16.4636 9.33915 17.4994 10.1429 17.915L11.0615 16.1384ZM4.74075 7.8889C4.74075 4.49958 7.47303 2 11 2V0C6.50864 0 2.74075 3.25986 2.74075 7.8889H4.74075ZM11 2C14.527 2 17.2593 4.49958 17.2593 7.8889H19.2593C19.2593 3.25986 15.4914 0 11 0V2ZM10.9385 16.1384C10.9516 16.1316 10.9732 16.1248 11 16.1248C11.0268 16.1248 11.0484 16.1316 11.0615 16.1384L10.1429 17.915C10.684 18.1948 11.316 18.1948 11.8571 17.915L10.9385 16.1384Z"
                fill={color}
            />
            <path
                d="M19.0829 16.6667C19.9021 17.1734 20.3333 17.7482 20.3333 18.3333C20.3333 18.9185 19.9021 19.4933 19.0829 20C18.2637 20.5067 17.0855 20.9275 15.6667 21.2201C14.2478 21.5126 12.6383 21.6667 11 21.6667C9.36165 21.6667 7.75217 21.5126 6.33332 21.2201C4.91448 20.9275 3.73626 20.5067 2.91709 20C2.09792 19.4933 1.66666 18.9185 1.66666 18.3333C1.66666 17.7482 2.09791 17.1734 2.91709 16.6667"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}
