import { useMutation } from '@tanstack/react-query';

import { ContactDTO } from '../dtos/contact.dto';
import { departmentService } from '../services';

export function useContacts() {
    return useMutation<Array<ContactDTO>, unknown, number>({
        mutationKey: ['listContacts'],
        mutationFn: departmentService.listContacts,
    });
}
