import styled from 'styled-components';

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    cursor: default;

    height: 40px;

    h2 {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 40px;
        display: flex;
        align-items: center;
        color: #262d3c;
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        gap: 8px;

        h2 {
            font-size: 20px;
            line-height: 24px;
        }
    }
`;
