import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, subDays } from 'date-fns';
import { Dispatch, FocusEvent, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AutocompleteMultiple, Button, Column, FeedbackProps } from 'src/common';
import DatePicker from 'src/common/components/date-picker';
import { DepartmentResume, departmentService } from 'src/modules/configurations';

import { GenericAnalyticsFilterDTO } from '../../common/dtos/generic-filter.dto';
import { genericAnalyticsFilterSchema } from '../../common/infra/validations/yup/generic-analytics-filter.schema';
import { OverviewLocationsRequestDTO, OverviewRequestDTO } from '../../dtos/overview';
import { Filter } from '../../fleets/components/fleets-filter/styles';
import { useAnalyticsOverview } from '../../overview/hooks';
import { overviewServiceLocations } from '../../services/overview-locations.service';
import { overviewService } from '../../services/overview.service';
import { Container } from './styles';

export interface FilterProps {
    selectedType: string;
    setIsLoading:  Dispatch<SetStateAction<boolean>>;
    setOverviewData:  Dispatch<SetStateAction<OverviewRequestDTO[]>>;
    setOverviewDataLocation:  Dispatch<SetStateAction<OverviewLocationsRequestDTO[]>>;
}
export function AnalyticsFilter({selectedType, setIsLoading, setOverviewData, setOverviewDataLocation}: FilterProps) {
    const { filter, setFilter, operations, setOperations } = useAnalyticsOverview();

    const { t } = useTranslation();

    const {
        setValue,
        register,
        handleSubmit: onSubmit,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: filter,
        mode: 'onChange',
        resolver: yupResolver(genericAnalyticsFilterSchema),
    });

    const startDate: Date = watch('initialDate');
    const endDate: Date = watch('finalDate');
    const maxDate = useMemo(() => (startDate ? addDays(new Date(startDate), 7) : new Date()), [startDate]);
    const minDate = useMemo(() => (endDate ? subDays(new Date(endDate), 7) : undefined), [endDate]);

    const handleChangeDatesRaw = useCallback((event: FocusEvent<HTMLInputElement, Element>) => {
        event.preventDefault();
    }, []);

    const handleChangeDates = useCallback(
        (dates: [Date, Date]) => {
            setValue('initialDate', dates[0]);
            setValue('finalDate', dates[1]);
        },
        [setValue],
    );

    const feedback = (key: keyof GenericAnalyticsFilterDTO): FeedbackProps => {
        return {
            message: t(errors[key]?.message || '')?.toString(),
        };
    };

    const operation = watch('operationInput');

    const handleSubmit = useCallback(async (data: GenericAnalyticsFilterDTO) => {
            setFilter({ ...data });
            try {
                setIsLoading(true);

                if (startDate && endDate) {
                    const responseLocations = await overviewServiceLocations.execute({
                        dateFrom: startDate,
                        dateTo: endDate,
                        equipament: selectedType as 'DS' | 'FW' | 'Telemetria',
                        operations: data.operations
                    })

                    setOverviewDataLocation(responseLocations);

                    const response = await overviewService.execute({
                        dateFrom: startDate,
                        dateTo: endDate,
                        equipament: selectedType as 'DS' | 'FW' | 'Telemetria',
                        operations: data.operations
                    });

                    setOverviewData(response);

                setIsLoading(true);
            }

            } finally {
                setIsLoading(false);
            }
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        //     }, [endDate, startDate, selectedType]);

        //     setFilter({ ...data });
        //     handleRequestListRankingFleet({
        //         dateFrom: data.initialDate.toISOString().split('T')[0],
        //         dateTo: data.finalDate.toISOString().split('T')[0],
        //         operations: data.operations,
        //     });
        // },
        // [handleRequestListRankingFleet, setFilter],
        },[endDate, selectedType, setFilter, setIsLoading, setOverviewData, setOverviewDataLocation, startDate]
    );

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 30,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations, setValue]);

    return (

        <Container align="flex-start">
            <Filter onSubmit={onSubmit(handleSubmit)}>
                <AutocompleteMultiple
                    showErrorOnMount
                    autocompleteName="operations"
                    id="operation"
                    isLoading={operations.isLoading}
                    options={operations.data
                        .filter(operation => operation.type === 'Operação')
                        .map(operation => ({
                            id: operation.id,
                            value: operation.name,
                        }))}
                    feedback={{
                        message: t(errors.operations?.message || '')
                            ?.toString()
                            ?.replace('%%', '10'),
                    }}
                    label={String(t('general.operation'))}
                    placeholder={String(t('general.operation'))}
                    setValue={setValue}
                    type="text"
                    values={watch('operations', filter.operations)}
                    tooltip={t('field_validations.max')?.toString()?.replace('%%', '10')}
                    {...register('operationInput')}
                    disabled={watch('operations')?.length >= 10}
                />
                <DatePicker
                    endDate={endDate}
                    feedback={feedback('finalDate')}
                    label={t('general.date')?.toString()}
                    maxDate={maxDate}
                    minDate={minDate}
                    multiple
                    name="period"
                    onChange={handleChangeDates}
                    onChangeRaw={handleChangeDatesRaw}
                    placeholder={String(t('general.select_period'))}
                    startDate={startDate}
                />
                <Column justify="flex-end" height="60px">
                    <Column justify="flex-end" height="36px">
                        <Button type="submit" size="small" id="real-time-submit-button">
                            {t('general.apply')}
                        </Button>
                    </Column>
                </Column>
            </Filter>
        </Container>
    );
}
