import { MouseEventHandler } from 'react';
import { CloseButton, Column, ListClipboard, PinAlt, Row, SquareButton } from 'src/common/components';

interface HeaderProps {
    onChangeTab(tab: number): void;
    onClose: MouseEventHandler<HTMLButtonElement>;
    tab: number;
    title: string;
}

export function Header({ onClose, onChangeTab, tab, title }: HeaderProps) {
    const handleActive = (value: number) => {
        return value === tab;
    };

    const handleChange: MouseEventHandler<HTMLButtonElement> = event => {
        onChangeTab(Number(event.currentTarget.value) as number);
    };

    return (
        <Column gap={16} align="flex-start" width="100%">
            <Row gap={48} align="flex-start" justify="space-between" width="100%">
                <Column gap={8} align="flex-start">
                    <h4 className="modal-title">{title}</h4>
                    <Row gap={16} align="flex-start">
                        <SquareButton type="button" bgColor={handleActive(0) ? '#262D3C' : '#E6E6E6'} value={0} onClick={handleChange}>
                            <ListClipboard height={24} color={handleActive(0) ? '#FFFFFF' : '#A9A9A9'} />
                        </SquareButton>
                        <SquareButton type="button" bgColor={handleActive(1) ? '#262D3C' : '#E6E6E6'} value={1} onClick={handleChange}>
                            <PinAlt color={handleActive(1) ? '#FFFFFF' : '#A9A9A9'} />
                        </SquareButton>
                    </Row>
                </Column>
                <CloseButton type="button" onClick={onClose} />
            </Row>
        </Column>
    );
}
