import { useMutation } from '@tanstack/react-query';

import { authService } from '../factories';

interface UseSendForgotPasswordRequestProps {
    onError: (error: unknown) => void;
    onSuccess: () => void;
}

export function useSendForgotPasswordRequest({ onError, onSuccess }: UseSendForgotPasswordRequestProps) {
    return useMutation({
        mutationFn: authService.forgotPassword,
        mutationKey: ['forgot-password'],
        onError,
        onSuccess,
    });
}
