import { useSidebar, Divider, Skeleton } from "src/common";

import { SkeletonCardContainer, SkeletonHeader, SkeletonHeaderIcon, SkeletonTitle } from "./styles";

interface ISkeletonCardProps {
    icon: JSX.Element | any;
    fontColor?: string;
}

export function SkeletonCard({ fontColor, icon }: ISkeletonCardProps) {
    const { displaySidebar } = useSidebar();

    return (
        <SkeletonCardContainer isSidebarOpen={displaySidebar} >
            <SkeletonHeader>
                <SkeletonHeaderIcon color={fontColor}>{icon}</SkeletonHeaderIcon>
                <SkeletonTitle>
                    <Skeleton
                        width={'8rem'}
                        height={'1rem'}
                        style={{ marginBottom: '1rem', marginLeft: 'auto', marginRight: 0 }}
                    />
                    <Skeleton width={'6rem'} height={'1.8rem'} style={{ marginLeft: 'auto', marginRight: 0 }} />
                </SkeletonTitle>
            </SkeletonHeader>

            <Divider />

            <Skeleton width={'12rem'} height={'1rem'} style={{ margin: '0.5rem 1rem' }} />
        </SkeletonCardContainer>
    )
}
