import { PropsWithChildren, createContext, useMemo } from 'react';
import { useLocalStorage } from 'src/common/hooks';

import { SidebarContextProps } from './types';

export const SidebarContext = createContext({} as SidebarContextProps);

export default function SidebarProvider({ children }: PropsWithChildren) {
    const [displaySidebar, setDisplaySidebar] = useLocalStorage('sidebarIsOpen', true);

    const data = useMemo<SidebarContextProps>(() => ({ displaySidebar, setDisplaySidebar }), [displaySidebar, setDisplaySidebar]);

    return <SidebarContext.Provider value={data}>{children}</SidebarContext.Provider>;
}
