import { yup } from 'src/common';

export const createVehicleSchema = yup.object().shape({
    fleet: yup
        .string()
        .required('field_validations.required')
        .min(1, 'field_validations.required')
        .max(20, 'field_validations.max')
        .test('fleet', 'field_validations.required', field => (!field ? false : !!field.trim())),
    organization: yup.string().required('field_validations.required'),
    observation: yup.string(),
    vehicleType: yup.string().required('field_validations.required'),
});
