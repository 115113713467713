import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import UpdateBox from './styles';

interface UpdateDataProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function UpdateData({ onClick }: UpdateDataProps) {

    const {t} = useTranslation();

    return (
        <UpdateBox type="button" onClick={onClick}>
            {t('general.update_data')}
        </UpdateBox>
    );
}
