import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Column, Divider, Image, Skeleton } from 'src/common/components';
import { TableData, TableRow } from 'src/common/components/table/styles';
import { Tag } from 'src/modules/alarm-processing/components/alarm-card/styles';
import { DateHelper } from 'src/modules/alarm-processing/helpers/date.helper';
import { usePastEvents } from 'src/modules/alarm-processing/hooks';
import { useAuth } from 'src/modules/auth';
import { userProfile } from 'src/modules/auth/constants/user-profile';

import { Error, StyledPagination, StyledTable } from './styles';

export default function PastEventsList() {
    const { events, requestData, responseStatus, handleRequestList } = usePastEvents();
    const { t, i18n } = useTranslation();
    const { session } = useAuth();

    const isAnalyst = useMemo(() => {
        return session?.user.profile === userProfile.analyst;
    }, [session?.user.profile]);

    const tableHeaders = useMemo(
        () => [
            <Fragment key="view" />,
            t('alarms.event'),
            t('alarms.velocity'),
            t('alarms.hour'),
            isAnalyst && t('alarms.arrival'),
            t('alarms.priority'),
        ].filter(x => x !== false),
        [isAnalyst, t],
    );

    return useMemo(() => {
        if (responseStatus.loading) {
            return (
                <Column gap={8} width="100%">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Column>
            );
        }

        if (responseStatus?.hasError) {
            return <Error>{t('general.error')}</Error>;
        }

        if (!events?.elements || responseStatus?.void) {
            return (
                <Column gap={16} width="100%" align="center" justify="center">
                    <Error>{t('general.no_content')}</Error>
                    <Button type="button" width="50%">
                        {t('general.update')}
                    </Button>
                </Column>
            );
        }

        return (
            <Column gap={8} width='100%'>
                <StyledTable tableHeaders={tableHeaders}>
                    {events.elements.map((data, index) => {
                        if (!data) return null;
                        return (
                            <TableRow className={index !== 0 ? 'body' : ''} key={data.id.toString()}>
                                <TableData>
                                    <Image
                                        src={data.photoUrl || undefined}
                                        alt={data.alarm || undefined}
                                        width={130}
                                        height={70}
                                        showErrorMessage={false}
                                        style={{
                                            borderRadius: '8px',
                                            marginBottom: '2px'
                                        }}
                                    />
                                </TableData>
                                <TableData>{t(`tableEventName.${data.event}`)}</TableData>
                                <TableData>{data.speedInKmH}</TableData>
                                <TableData>{DateHelper.format(data?.alarmTime, i18n.language, 'UTC')}</TableData>
                                { isAnalyst && <TableData>{DateHelper.format(data?.alarmArrivalTime, i18n.language, 'UTC')}</TableData>}
                                <TableData> {data.priority ? <Tag>{data.priority}</Tag> : null}</TableData>
                            </TableRow>
                        );
                    })}
                </StyledTable>
                <Divider />
                <StyledPagination
                    currentPage={requestData.currentPage}
                    perPage={requestData.pageSize}
                    total={events?.totalElements}
                    onPageChange={currentPage => handleRequestList({ currentPage })}
                />
            </Column>
        );
    }, [
        events?.elements,
        events?.totalElements,
        handleRequestList,
        i18n.language,
        isAnalyst,
        requestData.currentPage,
        requestData.pageSize,
        responseStatus?.hasError,
        responseStatus.loading,
        responseStatus?.void,
        t,
        tableHeaders
    ]);
}
