import { useMemo } from 'react';
import { FeedbackProps, FeedbackType } from 'src/common/types/feedback-component';


export function useFeedback(props?: FeedbackProps) {
    const className = useMemo(() => {
        if (props?.message) {
            switch (props?.type) {
                case FeedbackType.ERROR:
                    return 'input-error';
                case FeedbackType.SUCCESS:
                    return 'input-success';
                case FeedbackType.WARN:
                    return 'input-warning';
                default:
                    return '';
            }
        }

        return '';
    }, [props]);

    return useMemo(() => {
        return { className };
    }, [className]);
}
