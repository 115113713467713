import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import gpsOnline from 'src/assets/icons/pin-alt-on.svg';
import { Column, Field } from 'src/common/components';
import { DateHelper } from 'src/common/helpers';
import { HistoricalRoute } from 'src/modules/alarm-processing/domain/models/historical-route';

import { Title } from './styles';

export default function MapLastLocation(lastAppearance: HistoricalRoute) {
    const { t, i18n } = useTranslation();
    const [showInfo, setShowInfo] = useState(false);

    const position: google.maps.LatLngLiteral = {
        lat: lastAppearance.latitude,
        lng: lastAppearance.longitude,
    };

    return (
        <Marker icon={gpsOnline} position={position} onClick={() => setShowInfo(state => !state)}>
            {showInfo ? (
                <InfoWindow position={position} onCloseClick={() => setShowInfo(false)}>
                    <Column gap={4} align="flex-start">
                        <Title>{t('alarms.last_location')}</Title>
                        <Column gap={2} align="flex-start">
                            <Field
                                name={t('alarms.arrival')}
                                value={
                                    lastAppearance?.arrivalTime
                                        ? DateHelper.format(lastAppearance?.arrivalTime, i18n.language, 'UTC')
                                        : t('general.information_unavailable')
                                }
                            />
                            <Field
                                name={t('alarms.velocity')}
                                value={lastAppearance?.speed ? `${lastAppearance?.speed}km/h` : t('general.information_unavailable')}
                            />
                            <Field name={t('monitoring.lat')} value={lastAppearance.latitude.toString()} />
                            <Field name={t('monitoring.long')} value={lastAppearance.longitude.toString()} />
                        </Column>
                    </Column>
                </InfoWindow>
            ) : null}
        </Marker>
    );
}
