import spainFlag from 'src/assets/flags/spain-flag.svg';

import { LanguageTranslations } from '../types/translations';

const ES: LanguageTranslations = {
    dateFormat: 'dd/MM/yyyy',
    flag: spainFlag,
    translations: {
        auth: {
            unable_to_scan: '¿No se puede escanear? Puede usar el X para configurar manualmente su aplicación de autenticación.',
            setup_key: 'clave de configuración',
            use_an_authenticator_app_or_browser_extension_to_scan: 'Use una aplicación de autenticación o una extensión de navegador para escanear.',
            verify_code: 'Verificar código',
            scan_the_qr_code: 'Escanear el código QR',
            preferred_mfa_method: 'Método MFA preferido',
            authenticator_app: 'Aplicación de autenticación',
            sms: 'SMS/mensaje de texto',
            configure_mfa_text: 'Para continuar, configure MFA para este usuario.',
            configure_mfa: 'Configurar MFA',
            enter_the_code_sent_to: 'Introduce el código enviado a:',
            redefine_password: 'Redefinir contraseña',
            access_password: 'Contraseña de acceso',
            confirm_new_password: 'Confirmar nueva contraseña',
            email: 'Correo electrónico',
            email_or_phone: 'Email o teléfono',
            forgot_password: 'Olvide mi contraseña',
            login: 'Acceso',
            logout_confirmation: 'Vas a cerrar sesión, ¿quieres continuar?',
            logout_system: 'Cerrar sesión',
            name: 'Nombre',
            new_password: 'Nueva contraseña',
            password: 'Contraseña',
            password_confirmation: 'Confirmación de contraseña',
            remember_password: 'Recordar contraseña',
            register_new_password: 'Para continuar, revisa tus datos y registra una contraseña para acceder a nuestra plataforma.',
            user: 'Usuario',
            send: 'Enviar',
            welcome: 'Bienvenidos',
            continue: 'Continuar',
            error_login: 'Nombre de usuario o contraseña incorrecta.',
            forgot_password_page_message: 'Ingrese el correo electrónico registrado para recibir el enlace de restablecimiento:',
            forgot_password_code_message: 'Introduce el código de verificación recibido.',
            request_new_code: 'Solicitar nuevo código',
            verification_code: 'Código de verificación',
            on_confirm_change_pass: {
                success: {
                    title: 'Éxito',
                    message: 'Contraseña cambiada con éxito.',
                },
                error: {
                    expiredCodeException: {
                        title: 'Código vencido',
                        message: 'Código de verificación vencido.',
                    },
                },
            },
            on_request_code: {
                success: {
                    title: 'Solicitud recibida',
                    message: '¡El correo electrónico de recuperación se ha enviado correctamente!',
                },
                error: {
                    limitExceeded: {
                        title: 'límiteExcedido',
                        message: 'Los límites de los intentos ya han excedido, inténtelo de nuevo más tarde.',
                    },
                },
            },
        },
        configurations: {
            paper: 'Papel',
            edit_password_policy: 'Editar política de contraseñas',
            view_password_policy: 'Ver política de contraseñas',
            view_operation: 'Ver Operación',
            view_function: 'Ver función',
            edit_function: 'Editar la Función',
            view_user: 'Ver usuario',
            save_contact: 'Guardar contacto',
            do_you_want_to_delete_the_password_policy: '¿Quieres eliminar la(s) política(s) de contraseñas',
            delete_policy: 'Eliminar política',
            mfa_validation: 'Validación obligatoria de MFA',
            password_policy_status: 'Estado de la política de contraseñas',
            current_password_must_be_different_from_the_last_X_passwords: 'La contraseña actual debe ser diferente de las últimas X contraseñas.',
            contains_at_least_one_uppercase_letter: 'Contiene al menos 1 letra mayúscula',
            contains_at_least_one_lowercase_letter: 'Contiene al menos 1 letra minúscula',
            contains_at_least_one_special_character: 'Contiene al menos 1 carácter especial',
            contains_at_least_one_number: 'Contiene al menos 1 número',
            minimum_time: 'Tiempo mínimo (en días)',
            expiry_time: 'Tiempo de expiración (en días)',
            password_minimum_length: 'Longitud mínima de la contraseña (caracteres)',
            assigned_to: 'Asignado a',
            password_policy_name: 'Nombre de la política de contraseñas',
            create_password_policy: 'Crear política de contraseña',
            password_policies: 'Políticas de contraseña',
            redefine_password: 'Redefinir contraseña',
            view_vehicle: 'Ver Vehículo',
            edit_vehicle: 'Editar Vehículo',
            delete_vehicle: 'Excluir vehículo',
            delete_the_vehicle_white_board: 'Quiere borrar la matricula del vehiculo',
            acquisition_date: 'Fecha de Adquisicion',
            add_vehicle: 'Añadir vehículo',
            current_vehicle_statuses: {
                new: 'Nuevo',
                semi_new: 'Seminuevo',
                used: 'Usado',
            },
            vehicle_types: {
                agricultural_machine: 'Máquina agrícola',
                bus: 'Autobús',
                light_vehicle: 'Vehículo ligero',
                locomotive: 'Locomotora',
                motorcycle: 'Moto',
                ship: 'Barco',
                stationary_equipment: 'Equipo estacionario',
                truck: 'Camión',
            },
            current_vehicle_status: 'Estado actual del vehículo',
            vehicle_type: 'Tipo de vehiculo',
            create_vehicle: 'Crear vehículo',
            edit_department: 'Editar Operación',
            equals_resources: 'Los recursos de padres e hijos son iguales',
            ambiguous_record: 'Este registro es ambiguo, seleccione el registro deseado.',
            create_department: 'Crear Operación',
            contact: 'Contacto',
            add_department: 'Agregar Operación',
            add_function: 'Añadir función',
            add_user: 'Agregar usuario',
            confirm: 'Confirmar',
            confirm_delete_functions: '¿Desea eliminar los roles?',
            confirm_delete_users: '¿Desea eliminar los usuarios?',
            confirm_password: 'Confirmar contraseña',
            contacts: 'Contactos',
            creation_date: 'Fecha de creación',
            creator: 'Creador',
            delete: 'Borrar',
            delete_department: 'Eliminar Operación',
            delete_function: 'Eliminar rol',
            delete_the_user: '¿Quieres eliminar el usuario',
            department: 'Operación',
            departments: 'Operaciónes',
            do_you_want_to_delete_the_department: '¿Desea eliminar los Operaciónes',
            do_you_want_to_delete_the_role: '¿Quieres eliminar el rol',
            edit_user: 'Editar usuario',
            email: 'E-mail',
            equipments: 'Equipo',
            function: 'Función',
            function_name: 'Nombre da función',
            function_number: 'Número da función',
            functionalities: 'Funcionalidades',
            functions: 'Funciones',
            institution: 'Institución',
            institution_name: 'Nombre de la Institución',
            login: 'Acesso',
            name_login: 'Inicio de sesión de usuario',
            name_user: 'Nombre de usuario',
            number: 'Número',
            observation: 'Observación',
            organization: 'Organización',
            organization_name: 'Nombre de la Organización',
            password: 'Clave',
            password_policy: 'Política de contraseñas',
            phone_number: 'Número de teléfono',
            settings: 'Ajustes',
            show_active_users: 'Mostrar usuarios activos',
            status: 'Estado',
            system: 'Sistema',
            this_action: 'Esta acción no se puede deshacer',
            user: 'User',
            user_delete: 'Borrar usuario',
            users: 'Usuarios',
            vehicles: 'Vehículos',
            client: 'Cliente',
            analyst: 'Analista',
            client_analyst: 'Cliente Analista',
            profile: 'Perfil',
            description: 'Descripción',
            intervention_protocol: 'Protocolo Intervención ',
        },
        field_validations: {
            min: 'El número mínimo de caracteres es: $$min',
            name: 'Nombre inválido',
            date_is_before_creation_date: 'La fecha seleccionada no puede ser anterior a la fecha de creación',
            date_is_before_today: 'La fecha seleccionada no puede ser anterior a la fecha actual',
            date: 'Fecha invalida',
            phone_number_tooltip: 'Ingrese un número de teléfono, incluidos + y el código de país, por ejemplo, +34600000000.',
            password_length_recommended:
                'Debe ser un número entre 6 y 99. Recomendamos enfáticamente que requiera que las contraseñas tengan al menos 8 caracteres de longitud.',
            equals_resources: 'Los recursos de padres e hijos son iguales',
            mandatory_fields: 'Campos obligatorios',
            max: 'Tamaño máximo: %%',
            email: 'Email inválido',
            hour: 'Formato de hora no válido',
            invalid_record: 'Registro inválido',
            passwords_match: 'Las contraseñas no coinciden',
            record_already_exists: 'Este registro ya existe.',
            phone: 'Teléfono inválido',
            required: 'Campo obligatorio',
            password: {
                lower_case: 'La contraseña debe contener letras minúsculas.',
                upper_case: 'La contraseña debe contener letras mayúsculas.',
                special_character: 'La contraseña debe contener al menos un carácter especial.',
                length_8: 'La contraseña debe contener al menos 8 caracteres.',
                number: 'La contraseña debe contener al menos un número.',
            },
        },
        home: {
            events: 'Eventos',
            updated_at: 'Actualizado a las',
            interventions: 'Intervenciones',
            drowsiness: 'Somnolencia',
            vehicles: 'Vehículos',
            interventions_last_hour: 'Intervenciones en la última hora',
            interventions_last_hour_subtitle: 'Cada punto representa la evolución de la cantidad de eventos en la última hora.',
            event_distribution: 'Distribución de eventos',
            event_distribution_subtitle: 'Cada sección indica el porcentaje de eventos de cada categoría en el período seleccionado.',
            violation: 'Infracción',
            fatigue: 'Fatiga',
            distraction: 'Distracción',
            speeding: 'Exceso de velocidad',
            dangerous_driving: 'Conducción peligrosa',
            aggressive_driving: 'Conducción agresiva',
            recent_interventions: 'Intervenciones recientes',
            recent_interventions_subtitle:
                'Los marcadores rojos en el mapa indican la ubicación de las intervenciones ocurridas en el período seleccionado. Al hacer clic en ellos, se muestra el video y la información relacionada con la intervención.',
        },
        menu: {
            events: 'Eventos',
            language: 'Español',
            logout: 'Cerrar sesión',
            panel: 'Panel',
            pending_alarms: 'Alarmas pendientes',
            processing_alarms: 'Procesamiento de alarmas',
            vehicles_monitoring: 'Monitoreo de vehículos',
            interventions: 'Intervenciones',
            analytics: 'Analytics',
            overview: 'Visión General',
            period: 'Período',
            registration_shipments: 'Registro de embarques',
            help: 'Ayuda',
            review_request: 'Revisión del evento',
            analysis_requests: 'Solicitudes de análisis',
            home: 'Inicio',
            smart_report: 'Smart Report',
        },
        general: {
            see_all: 'Ver todo',
            loading: 'Cargando',
            last: 'última',
            hours: 'horas',
            status: {
                active: 'Activo',
                inactive: 'Inactivo',
            },
            recommended: 'Recomendado',
            name: 'Nombre',
            input: 'Entrada',
            output: 'Salida',
            expires_in: 'Caduca en',
            default: 'Por defecto',
            record_has_relationships: 'Las políticas tienen usuarios vinculados, desvincúlelos y vuelva a intentarlo.',
            select: 'Seleccionar',
            export: 'Exportar',
            select_filters_to_view_the_map: 'Seleccionar filtros para ver el mapa',
            no_vehicle_selected: 'Ningún vehículo seleccionado',
            error_loading_data: 'Error al cargar datos',
            unhandled_alarms: 'Alarmas no gestionadas',
            admin: 'Admin',
            interventions: 'Intervenciones',
            analytics: 'Analytics',
            overview: 'Visión General',
            unit: 'Unidad',
            fleet: 'Flota',
            driver: 'Conductor',
            communication: 'Comunicación',
            support: 'Soporte',
            vehicle_monitoring: 'Monitoreo de vehículos',
            sort_alarm: 'Ordenar alarma',
            settings: 'Ajustes',
            review_alarm: 'Revisar alarma',
            reports: 'Informes',
            real_time_localization: 'Localización en tiempo real',
            playback_of_tracks: 'Reproducción de pistas',
            live_camera_stream: 'Transmisión de cámara',
            electronic_fence: 'Cerca electrónica',
            dashboard: 'Dashboard',
            authentication: 'Autenticación',
            alarms_processing: 'Procesamiento de alarmas',
            selection_of_settings: 'Selección de ajustes',
            main_menu: 'Menú principal',
            exclude_yourself: 'No puedes borrarte a ti mismo.',
            alarm_type: 'Tipo de alarma',
            alarms_high_risk: 'Alarmas de alto riesgo',
            alarms_solved: 'Alarmas manejadas',
            alarms_total: 'Alarmas Totales',
            configurations: 'Ajustes',
            all: 'Todos',
            apply: 'Aplicar',
            cancel: 'Cancelar',
            classification: 'Clasificar',
            clear_filters: 'Borrar filtros',
            conclude: 'Concluir',
            confirm: 'Confirmar',
            date: 'Fecha',
            description: 'Descripción',
            destination: 'Destino',
            error: 'Error, inténtalo de nuevo.',
            events: 'Eventos',
            filter: 'Filtro',
            filters: 'Filtros',
            final_hour: 'Hora final',
            high_risk: 'Alto riesgo',
            historic: 'Histórico',
            information_unavailable: 'Información no disponible',
            initial_hour: 'Hora inicial',
            items_per_page: 'Elementos por página',
            low_risk: 'Riesgo bajo',
            medium_risk: 'Riesgo medio',
            no_content: 'Sin registro',
            no_content_specific_alarm: 'Aún no tienes ninguna alarma.',
            no_content_specific_event: 'Aún no tienes ningún evento.',
            no_filter_vehicle: 'No encontramos ningún resultado para los filtros aplicados.',
            no_vehicle: 'No se encontraron vehículos',
            of: 'de',
            offline: 'Desconectado',
            on_the_page: 'Estás en la página',
            online: 'Conectado',
            online_vehicles: 'Vehículos en línea',
            origin: 'Origen',
            period: 'Período',
            review: 'Revisar',
            search: 'Búsqueda...',
            select_period: 'Seleccione Periodo',
            selected_alarms: 'Alarmas seleccionadas',
            seven_days: '7 días',
            show_events: 'Mostrar eventos',
            success: 'Éxito',
            test: 'Test',
            total_vehicles: 'Vehículos Total',
            twenty_four_hours: '24 horas',
            update: 'Actualizar',
            update_data: 'Actualizar datos',
            vehicle: 'Vehículo',
            vehicles: 'Vehículos',
            vehicles_events: 'Vehículos Eventos',
            view: 'Mirar',
            write: 'Escribir',
            write_here: 'Escribe aquí',
            activity: 'Actividad',
            region: 'Región',
            country: 'País',
            operation: 'Operación',
            notification_sound: 'Sonido de notificación',
            functionalities: 'Funcionalidades',
            operations: 'Operaciones',
            password_policy: 'Política de contraseñas',
            users: 'Usuarios',
            speed_above: 'Velocidad arriba',
            message: {
                success: {
                    title: 'Éxito',
                    message: 'Solicitud completada correctamente.',
                },
                error: {
                    title: 'Falla',
                    message: 'No hubo una falla en procesar su solicitud.',
                },
            },
            face_missing: 'Rostro manquant',
            yes: 'Sí',
            no: 'No',
            back_to_home: 'de vuelta a casa',
            intervention_protocol: 'Protocolo de intervención',
            registration_shipments: 'Registro de envío',
            event_redistribution: 'Redistribución de eventos',
            select_the_event: 'Seleccione el evento',
            review_requests: 'Solicitudes de Análisis',
            unit_comparison: 'Comparación de unidades',
            last_alarm: 'Última alarma',
            fleets: 'Flotas',
            select_options: 'Seleccione las opciones',
            see_driver_name: 'Ver el nombre del conductor',
        },
        alarms: {
            pending_analysis: 'Análisis pendiente',
            last_location: 'Última ubicación',
            minimize: 'Minimizar',
            see_all: 'Verlo todo',
            alarm_time: 'Hora de alarma',
            event_type: 'Tipo de evento',
            GPS: 'GPS',
            alarm: 'Alarma',
            alarm_type: 'Tipo de alarma',
            arrival: 'Llegada',
            board: 'Junta',
            break: 'Romper',
            cam: 'Cámara',
            capture: 'Captura',
            captures: 'Capturas',
            device: 'Dispositivo',
            event: 'Evento',
            extra_events: 'Eventos adicionales',
            false_alarm: 'Falsa alarma',
            fleet: 'Flota',
            historic_route: 'Ruta Histórica',
            hour: 'Hora',
            information: 'Información de alarma',
            level: 'Nivel de alarma',
            looking_down: 'Mirando abajo',
            name: 'Nombre de alarma',
            number_of: 'Número de capturas',
            operation: 'Operación',
            previous_events: 'Eventos anteriores',
            priority: 'Prioridad',
            priority_1: 'Alto',
            priority_2: 'Medio',
            priority_3: 'Bajo',
            real_time: 'Monitoreo en tiempo real',
            select_alarm: 'Seleccionar alarma',
            select_board: 'Seleccionar tablero',
            select_destination: 'Dirección de destino',
            select_events: 'Seleccionar eventos',
            select_operation: 'Seleccionar operación',
            select_origin: 'Dirección de origen',
            select_vehicle: 'Seleccionar vehículo',
            somnolence: 'Somnolencia',
            sort_multiple_alarms: 'Ordenar múltiples alarmas',
            velocity: 'Velocidad',
            open_alarms: 'Alarmas Abiertas',
            processed_alarms: 'Alarmas Procesadas',
            activity_analyst: 'Actividad de los Analistas',
            automatic_search: 'Búsqueda Automática',
            automatic_search_tooltip: 'Realiza búsqueda automática de nuevas alarmas cada 30s',
            select_redistribution: 'Redistribución de Alarmas',
            label_select_redistribution: 'Redistribuir mis alarmas (porcentaje)',
            placeholder_select_redistribution: 'Seleccionar porcentaje a redistribuir',
            alarms_search_parameters: 'Búsqueda de alarma',
            label_alarms_search_parameters: 'Distribuição dos alarmes',
            placeholder_select_alarms_search_parameters: 'Seleccione la opción de distribución',
            input_alarms_search_parameters: 'Período de búsqueda (en horas)',
            placeholder_alarms_search_parameters: 'Ingrese el número de horas para buscar',
            dynamic: 'Dinámica',
            analyst: 'Analista',
            media_visualization: 'Visualización de medios',
            media_size: 'Tamaño del medio (píxeles)',
            placeholder_midias_size: 'Ingrese la cantidad de píxeles para aumentar los medios',
            options: 'Opciones',
            request_review: 'Solicitar Revisión',
            review_event: 'Evento de Revisión',
            see_details: 'Ver Detalles',
        },
        review_request: {
            review_request: 'Revisión del evento',
            analysis_requests: 'Solicitudes de análisis',
            analysis_request: 'Solicitud de Análisis',
            request_number: 'Nº del pedido',
            request_time: 'Fecha de solicitud',
            fleet: 'Flota',
            operation: 'Operación',
            event: 'Evento',
            event_time: 'Fecha del evento',
            post_analysis: 'Análisis posterior',
            justification: 'Justificación',
            answer: 'Respuesta',
            status: 'Situación',
            see: 'Ver',
            success: 'Analizado',
            pending: 'Pendiente',
        },
        monitoring: {
            view_electronic_fence: 'Ver cerca electrónica',
            do_you_want_to_delete_the_electronic_fence: '¿Quiere eliminar la cerca electrónica',
            delete_electronic_fence: 'Eliminar valla electrónica',
            change_zone_shape: 'Cambiar forma de zona',
            zone_color: 'Color de la zona',
            transparency: 'Transparencia',
            expires_in: 'Caduca en',
            generate_event_by: 'Generar evento por',
            electronic_fence_name: 'Nombre de cerco electrónico',
            edit_electronic_fence: 'Editar Cerco Electrónico',
            add_electronic_fence: 'Agregar Cerco Electrónico',
            add_fence: 'Agregar cerca',
            view: 'vista',
            SIM: 'Número de tarjeta SIM',
            add_perimeter: 'Agregar perímetro',
            altitude: 'Altitud',
            board_color: 'Color del tablero',
            board_number: 'Número de tablero',
            cam_stream: 'Transmisión de cámara',
            cellphone: 'Celular de contacto',
            cities: 'Ciudades y Provincias',
            conduction: 'Modelo de conducción',
            define_route: 'Definir ruta',
            defined_perimeters: 'Perímetros Definidos',
            direction: 'Dirección',
            driver_info: 'Información del conductor',
            driver_name: 'Nombre del conductor',
            eletronic_fence: 'Cerca eléctrica',
            filiation: 'Filiación',
            identity: 'Número de identidad',
            last_events: 'Últimos eventos',
            last_register: 'Último registro',
            lat: 'Latitud',
            localization: 'Localización',
            long: 'Longitud',
            mark_perimeter: 'Marcar perímetros',
            organization: 'Organización controladora',
            perimeter: 'Perímetro',
            position: 'Posicionamiento',
            radius: 'Radio',
            real_time: 'Localización en tiempo real',
            select_perimeter: 'Seleccionar perímetro',
            status: 'Estado',
            track_playback: 'Reproducción de pistas',
            tracking: 'Seguimiento',
            vehicle_info: 'Información del vehículo',
            write_radius: 'Radio de escritura (km)',
            compass_points: {
                east: 'Este',
                north: 'Norte',
                northeast: 'Noreste',
                northwest: 'Noroeste',
                south: 'Sur',
                southeast: 'Sureste',
                southwest: 'Suroeste',
                west: 'Oeste',
            },
            event_expires_in: {
                never: 'Nunca',
                today: 'Hoy',
                tomorrow: 'Mañana',
                seven_days: '7 días',
                custom: 'Personalizado',
            },
        },
        password: {
            new_password_is_equal_to_old: 'Su nueva contraseña debe ser diferente de la(s) anterior(es).',
            contains_at_least_one_lowercase_letter: 'La contraseña debe contener al menos 1 letra minúscula',
            contains_at_least_one_number: 'La contraseña debe contener al menos 1 número',
            contains_at_least_one_special_character: 'La contraseña debe contener al menos un carácter especial',
            contains_at_least_one_uppercase_letter: 'La contraseña debe contener al menos una letra mayúscula',
            is_expired: 'La contraseña venció',
        },
        overview: {
            proportion_events: 'Proporción',
            proportion_events_chart_subtitle: 'Este gráfico representa la proporción de eventos en los períodos seleccionados.',
            heat_map_events: 'Mapa de calor',
            heat_map_events_chart_subtile: 'Os pontos deste mapa indicam os locais de maior ocorrência de eventos.',
            average_events: 'Promedio',
            average_events_chart_subtile: 'Este gráfico representa el promedio de los vehículos en el período seleccionado.',
            total_events: 'Total',
            total_events_chart_subtitle: 'Este gráfico representa el total de eventos en los períodos seleccionados.',
            telemetry: 'Telemetria',
        },
        period: {
            time_range: 'Intervalo de tiempo',
            day_week: 'Dias de la semana',
            evento_per_day_hour: 'Eventos por día y hora',
            hours_per_bar: 'Horas por barra',
            coordinates: 'Coordenadas',
            polar: 'Polar',
            cartesian: 'Cartesiano',
            options: 'Opciones',
            day: 'Día',
            week: 'Semana',
            month: 'Mes',
            year: 'Año',
            bar: 'Bar',
            line: 'Línea',
            graph_type: 'Tipo de gráfico',
            cancel: 'Cancelar',
            apply: 'Aplicar',
            worst_tracks_highlights: 'Destaca las peores pistas',
            total_events: 'Eventos totales',
            at: 'en',
            scatterGraphSubTitle:
                'Los puntos del gráfico representan ' +
                ' eventos seleccionados durante las horas y los días de la semana.' +
                ' Cuanto más grande sea el punto, más eventos, y los puntos' +
                ' parpadeantes representan la cantidad seleccionada de pistas de bajo.',
            cartesianChart: 'Las bandas de este gráfico indican los momentos con mayor ocurrencia de eventos.',
            barChartGraph: 'Las barras de este gráfico indican los días de la semana con mayor ocurrencia de eventos.',
            telemetry: 'Telemetria',
        },
        interventions: {
            interventions_informations: 'Información de intervención',
            qtd_events: 'Numero de eventos: ',
            hour_initial: 'Hora de inicio de los eventos: ',
            hour_final: 'Hora de finalización de los eventos: ',
            interventions_situation: 'Situación de intervención',
            media: 'Video',
            speed: 'Velocidad',
            hour: 'Hora',
            lat: 'Latitud',
            long: 'Longitud',
            close: 'Cerrar',
            finalize: 'Finalizar',
            informations: 'Información',
            comments: 'Comentarios',
            type_comment: 'Escribe tu comentario',
            comment: 'Comentario',
            event: 'Evento',
            pending: 'Pendiente',
            in_negotiation: 'En negociación',
            closed: 'Cerrado',
            status: 'Estado',
            all: 'Todo',
            'Em aberto': 'En abierto',
            Pendente: 'Pendiente',
            'Em tratativa': 'En negociación',
            Fechado: 'Cerrado',
            'mudou para status': 'cambiado en status',
        },
        face_missing: {
            proportion: 'Proporción',
            fleet: 'Flota',
            details: 'Detalles',
            details_table_subtitle: 'Seleccione el formato deseado para descargar la información de la tabla',
            face_missing: 'Face Missing',
            total_alarms: 'Alarmas Totales',
            percentage: 'Porcentaje',
            status: 'Estado',
            proportion_graph_subtitle: 'Este gráfico representa la proporción de eventos en los períodos seleccionados.',
            fleet_graph_subtitle: 'El siguiente gráfico muestra el estado del dispositivo DS según cada flota.',

            informative: 'Informativo',
            about_face_missing: 'Acerca de Face missing:',
            a_fleet_is_classified: 'Una flota se clasifica como desplazada si al menos el 50 % de los eventos de esta flota están Face Missing.',
        },
        registration_shipments: {
            registration_of_report_submissions: 'Registro De Envíos De Informes',
            operation: 'Operación',
            email: 'Correo Electrónico',
            shipping_type: 'Tipo de envío',
            channel: 'Canal',
            priority: 'Prioridad',
            telephone: 'Teléfono',
            add_chance: 'Agregar cambio',
            send_request: 'Enviar petición',
            contact: 'Contacto',
            save: 'Ahorrar',
            cancel: 'Cancelar',
            daily_report: 'Reporte diario',
            delete_contact: 'Borrar contacto',
            title_confirm_delete: '¿Quieres eliminar el contacto “NOMBRE DEL CONTACTO”?',
            confirm: 'Confirmar',
            registration_shipments: 'Envíos de matrícula',
            intervention: 'Intervención',
            contacts: 'Contactos',
            select_type: 'Seleccione tipo',
        },
        fleets: {
            fleets: 'Flotas',
            ds_ranking: 'Clasificación DS',
            fleet_ranking: 'Clasificación de la flota',
            total: 'Total',
            time_range: 'Intervalo de tiempo',
            heat_map: 'Mapa de calor',
            events: 'Eventos',
            type: 'Tipo',
            bar: 'Bar',
            line: 'Línea',
            day: 'Día',
            week: 'Semana',
            month: 'Mes',
            year: 'Año',
            select_the_event: 'Seleccione el evento',
            hours_per_bar: 'Horas por barra',
            period: 'Período',
            polar: 'Polar',
            cartesian: 'Cartesiano',
            radio: 'Radio',
            blur: 'Blur',
            period_view: 'Vista de período',
            need_to_select_fleet: 'Selecciona una flota en la clasificación de arriba para ver los detalles de la flota',
            fleet_ranking_subtile: 'Este ranking representa el total de eventos de todas las flotas',
            fleet_total_subtile: 'Este gráfico representa el total de eventos de la flota seleccionada',
            time_range_subtile: 'Las bandas en este gráfico indican las horas con mayor incidencia de eventos',
            heat_map_subtitle: 'Las ondas de calor en este gráfico indican los lugares con mayor incidencia de eventos',
        },
        events: {
            events: 'Eventos',
        },
        intervention_protocol: {
            intervention_protocol: 'Protocolo de intervención',
            protocol: 'Protocolo',
            group_whatsapp: 'Grupo de Whatsapp',
            observation: 'Observación',
            enter_the_name_of_the_operation: 'ingrese el nombre de la operación',
            enter_protocol_name: 'ingrese el nombre del protocolo',
            enter_group_name: 'ingrese el nombre del grupo',
            type_the_note: 'escriba su observación',
            enter_event_name: 'ingresar nombre evento',
            to_accept: 'Aceptar',
            cancel: 'Cancelar',
            to_add: 'Para agregar',
            add_protocol: 'Agregar protocolo',
            add_details: 'Agregar detalles',
            delete_protocol: 'Eliminar protocolo',
            do_you_want_to_delete_the_protocol: '¿Desea eliminar el protocolo "NOMBRE DEL PROTOCOLO"?',
            operation: 'Operation',
            protocol_details: 'Detalles del protocolo',
            edit_protocol: 'Editar protocolo',
            save: 'Ahorrar',
            event: 'Evento',
            amount: 'Cantidad',
            enter_the_amount: 'Introduce la cantidad',
            time: 'Tiempo',
            enter_the_time: 'Introduce la hora',
            wait: 'Esperar (h)',
            enter_the_wait: 'Entra en la espera',
            send_image: 'Enviar imagen',
            select_the_option: 'Seleccione la Opción',
            send_video: 'Enviar vídeo',
            edit_details: 'Editar detalles',
            back: 'Atrás',
            advance: 'Avance',
            to_close: 'Estoy cerca',
            want_to_delete_the_protocol_event_protocol_name: '¿Desea excluir el evento de protocolo "NOMBRE DE PROTOCOLO"?',
            new_details: 'Nuevos Detalles',
            delete: 'Borrar',
            this_action_cannot_be_undone: 'Esta acción no se puede deshacer',
            select_the_event: 'Seleccione el evento',
            confirm: 'Confirmar',
            view_protocol: 'Ver protocolo',
        },
        drivers: {
            drivers: 'Conductores',
            driver_history: 'Historial del conductor',
            images: 'Imágenes',
            id: 'Id',
            operation: 'Operación',
            fleet: 'Flota',
            event: 'Evento',
            day: 'Día',
            alarm_hour: 'Hora de alarma',
            latitude: 'Latitud',
            longitude: 'Longitud',
            id_cluster: 'Id Cluster',
            driver: 'Conductor',
            document: 'Documento',
            time_range: 'Intervalo de tiempo',
            heat_map: 'Mapa de calor',
            history_of_events: 'Historial de eventos',
            subtitle_history_of_events: 'Los puntos de este mapa indican los lugares donde ocurren eventos con mayor frecuencia.',
            subtitle_time_range: 'Las bandas de este gráfico indican los momentos con mayor ocurrencia de eventos',
            subtitle_history_events: 'Los datos de la tabla muestran el historial de eventos.',
            driver_ranking: 'Ranking de conductores',
            search_by_id_cluster: 'Búsqueda por id cluster',
            search_by_image: 'Buscar por imagen',
            upload_image: 'Subir Imagen',
            click_on_a_line: 'Haga clic en una fila para ver el historial del controlador seleccionado.',
            period: 'Período',
            week: 'Semana',
            month: 'Mes',
            year: 'Año',
            hours_per_bar: 'Horas por barra',
            coordinates: 'Coordenadas',
            polar: 'Polar',
            cartesian: 'Cartesiano',
            events: 'Eventos',
            select_the_event: 'Selecciona los eventos',
            ray: 'Rayo',
            blur: 'Blur',
            subtitle_driver_history: 'Los datos de este gráfico indican el número total de eventos de conductores según el período.',
            need_to_select_driver: 'Seleccione un conductor de las clasificaciones anteriores para ver los detalles del conductor.',
            photos_legends: 'Fotos de sucesos de eventos.',
            file_text: 'Ningún archivo seleccionado.',
            search: 'Buscar',
            no_face_founded: 'No se detectaron rostros en la imagen.',
            try_again: 'Inténtalo de nuevo',
            driver_founded_no_events: 'Cara detectada pero no se encontró ningún controlador.',
            similarity: 'Semejanza',
            type: 'Tipo',
            bar: 'Bar',
            line: 'Línea',
            heat_map_subtitle: 'Las ondas de calor en este gráfico indican los lugares con mayor incidencia de eventos',
        },
        last_alarm: {
            last_alarm: 'Última alarma',
            operation: 'Operación',
            fleet: 'Flota',
            last_communication: 'Última Comunicación',
            days_since_the_last_communication: 'Última comunicación ',
            days_since_the_last_alarm: 'Última alarma (días)',
            status: 'Status',
            proportion: 'Proporción',
            table: 'Tabla',
            subtitle_pie_chart: 'Este gráfico representa la proporción de eventos en los períodos seleccionados.',
            subtitle_bar_chart: 'Este gráfico muestra el estado de las comunicaciones por operación y todas las flotas dentro de ella.',

            informative: 'Informativo',
            a_fleet_is_classified_as: 'Una flota se clasifica en:',
            no_communication: 'No comunicación si no ha habido comunicación durante más de 6 días;',
            communicating_without_event:
                'No reportar ningún evento si ha informado en los últimos 6 días pero no ha presentado ninguna alarma en ese período;',
            if_you_are_communicating: '¡DE ACUERDO! si ha estado comunicándose y teniendo alarmas en los últimos 6 días.',
        },
        comparison_of_units: {
            comparison_of_units: 'Comparación de unidades',
            country: 'País',
            week: 'Semana',
            yawn: 'Bostezo',
            drowsinessN1: 'Somnolencia N1',
            drowsinessN2: 'Somnolencia N2',
            cellPhone: 'Teléfono móvil',
            smoking: 'De fumar',
            occlusion: 'Oclusión',
            lookingDownN1: 'Mirando hacia abajo N1',
            lookingDownN2: 'Mirando hacia abajo N2',
            drowsinessIndex: 'Índice de somnolencia',
            distractionIndex: 'Índice de distracción',
            generalIndex: 'Índice general',
            telemetryIndex: 'Índice de telemetría',
            averages_per_period: 'Promedios por periodo',
            comparison: 'Comparación',
            the_vehicles_column_is_the_maximum: 'La columna <strong>Vehículos</strong> es la cantidad máxima de vehículos que circularon cada día.',
            each_event_column_contains_the_total_number:
                'Cada columna de eventos contiene el total de eventos del período dividido por el número de Vehículos obtenidos anteriormente, a su vez dividido por el número de días activos.',
            we_have_the_following_formulas: 'Tenemos las siguientes fórmulas:',
            fatigue_index: 'Índice de fatiga = 10 * Somnolencia N1 + 20 * Somnolencia N2',
            distraction_index:
                'Índice de distracción = 15 * Celular + 5 * Fumar + 15 * Oclusión + 1 * Mirar hacia abajo N1 + 2 * Mirar hacia abajo N2 + 15 * Auriculares',
            indice_argus: 'Índice Argus = Índice de fatiga + Índice de distracción',
            period_vision: 'Visión de época',
            unity_vision: 'Visión de unidad',
            day: 'Día',
            month: 'Mes',
            year: 'Año',
            period: 'Período',
            operation: 'Operación',
            region: 'Región',
            column: 'Columna',
            select_the_event: 'Selecciona el evento',
            informative: 'Informativo',
        },
        tableEventName: {
            Sonolência: 'Somnolencia',
            'Sonolência N1': 'Somnolencia N1',
            'Sonolência N2': 'Somnolencia N2',
            'Sonolência leve': 'Somnolencia leve',
            'Olhando para baixo N1': 'Mirar hacia abajo N1',
            'Olhando para baixo N2': 'Mirar hacia abajo N2',
            Celular: 'Celular',
            'Sem cinto': 'Sin cinturón',
            Fumando: 'Fumar',
            Oclusão: 'Oclusión',
            Bocejo: 'Bostezo',
            'Face missing': 'Face missing',
            'Óculos de sol': 'Gafas de sol',
            'Consumo alimento': 'Consumo comida',
            'Fone de ouvido': 'Audífono',
            'Colisão frontal': 'Advertencia de colisión frontal (FCW)',
            'Alarme antecipado de colisão': 'Advertencia de colisión frontal (FCW)',
            'Colisão de pedestres': 'Advertencia de colisión peatonal',
            'Alarme de colisão de passageiros': 'Advertencia de colisión peatonal',
            'Excesso de velocidade da via': 'Sobre-límite de señal de tráfico',
            'Alarme de excesso de limite de sinal rodoviário': 'Sobre-límite de señal de tráfico',
            'Colisão para frente de baixa velocidade': 'Advertencia de colisión frontal urbana (UFCW)',
            'Curva rápida': 'Giro rápido',
            'Curva rápida à direita': 'Giro rápido',
            'Curva rápida à esquerda': 'Giro rápido',
            'Veículo muito próximo': 'Vehículo muy cerca',
            'Distância de veículos muito próxima': 'Vehículo muy cerca',
            'Excesso de velocidade na faixa de pedestre': 'Velocidad excesiva en el paso de peatones',
            'Excesso de velocidade na listra de zebra': 'Velocidad excesiva en el paso de peatones',
            'Mudança irregular de faixa': 'Cambio irregular de rango',
            'Linha de pressão direita da pista': 'Cambio irregular de rango',
            'Linha de pressão esquerda da pista': 'Cambio irregular de rango',
            'Ponto cego': 'Punto ciego',
            Velocidade: 'Exceso de velocidad',
            'Condução perigosa N1': 'Conducción peligrosa N1',
            'Condução perigosa N2': 'Conducción peligrosa N2',
            'DS deslocado': 'DS dislocado',
            'Dano ao DS': 'Daño DS',
            'Cinto irregular': 'Cinturón desigual',
            Rádio: 'Radio',
            'Falso alarme': 'Falsa alarma',
            'Tentativa de Oclusão': 'Intento de oclusión',
            'Oclusão parcial': 'Oclusión parcial',
            'Passageiro irregular': 'Pasajero irregular',
            Teste: 'Teste',
            '?': '?',
            'Óculos de sol à noite': 'Gafas de sol por la noche',
            Infração: 'Infracción',
            Distração: 'Distracción',
            Fadiga: 'Fatiga',
            'Excesso de Velocidade': 'Exceso de velocidad',
            'Direção perigosa': 'Conducción peligrosa',
            'Direção agressiva': 'Conducción agresiva',
        },
        hours: {
            in: 'en',
            ago: 'atrás',
            secondsAgo: 'hace unos segundos',
            seconds: 'segundos',
            minute: 'minutos',
            aMinute: 'un minuto',
            hour: 'hora',
            hours: 'horas',
            oneDay: 'un día',
            days: 'días',
            oneWeek: 'una semana',
            weeks: 'semanas',
            monthAgo: 'hace un mes',
            months: 'meses',
            aYear: 'un año',
            years: 'años',
        },
        echarts: {
            group: 'Grupo',
            total: 'Total',
        },
    },
};

export default ES;
