import styled from 'styled-components';

import Row from '../row';

export const Content = styled(Row)`
    padding: 0 16px;
    gap: 8px;

    &.mb-16 {
        margin-bottom: 16px;
    }

    span.error {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;

        color: #ef1d00;

        margin-bottom: 16px;
    }
`;
