import { SelectChangeEvent, Slider } from '@mui/material';
import { HeatmapLayer } from '@react-google-maps/api';
import * as turf from '@turf/turf';
import { format } from 'date-fns';
import { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ChevronDown,
    Column,
    Divider,
    FilterAlt,
    Input,
    Row,
    Title,
    MainCard,
    CardHeader,
    NoContent,
    Skeleton,
    DateHelper,
    RenderIf,
    usePermission,
} from 'src/common';
import { GraphsModal } from 'src/common/components/graphics/graphs-modal';
import { StackedLineChart } from 'src/common/components/graphics/stacked-line-chart';
import { EventsFilter, EventsProps } from 'src/modules/analytics/components/events-filter';
import { IFleetTotalFilter } from 'src/modules/analytics/fleets/dtos/fleet.dto';

import { BarChartDataStackProps, BarChartStack } from '../../../common/components/graphics/bar-chart-stack';
import { BarPolar, BarPolarDataProps } from '../../../common/components/graphics/bar-polar';
import Radio from '../../../common/components/inputs/radio';
import RadioButton from '../../../common/components/radio-button';
import UpdateData from '../../../common/components/update-data';
import { EventHelper } from '../../../common/helpers/events.helper';
import { typeEquipament } from '../../../modules/analytics/common/consts/typeEquipaments';
import { GoogleMapContainer } from '../../../modules/analytics/components/google-map';
import { FleetsFilter } from '../../../modules/analytics/fleets/components/fleets-filter';
import { IFleetTimeRangeFilter } from '../../../modules/analytics/fleets/dtos/filters-analytics-fleet.dto';
import { useAnalyticsFleets } from '../../../modules/analytics/fleets/hooks';
import {
    GraphSection,
    GraphContainer,
    GraphTitle,
    RankingSection,
    RankingContainer,
    FilterRange,
    ModalFilter,
    RadioButtonContainer,
    FilterOptionContainer,
    ButtonFilter,
    FilterTimeRangeRadioContainer,
} from './style';

function calculateValue(value: number) {
    switch (value) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        case 5:
            return 6;
        case 6:
            return 12;
        default:
            return 1;
    }
}

const marks = [1, 2, 3, 4, 5, 6].map(value => ({
    value,
    label: calculateValue(value),
}));

export function Fleets() {
    const { t } = useTranslation();
    const { hasTelemetry } = usePermission();

    const [uniqueEvents, setUniqueEvents] = useState<EventsProps[]>([]);
    const [uniqueEventsHeatMap, setUniqueEventsHeatMap] = useState<EventsProps[]>([]);
    const [uniqueEventsFilter, setUniqueEventsFilter] = useState<EventsProps[]>([]);
    const [uniqueEventsFilterHeatMap, setUniqueEventsFilterHeatMap] = useState<EventsProps[]>([]);
    const [radius, setRadius] = useState(20);

    const blanckSpaceForFixDate = ' ';
    const {
        handleGetListRankingFleet,
        listRankingFleets,
        handleSelect,
        selectedFleet,
        handleGetEventsByFleet,
        listEventsByFleet,
        filterIsOpen,
        handleFilterIsOpen,
        handleRequestListRankingFleet,
        requestRankingData,
        responseRankingStatus,
        responseEventsByFleetStatus,
        listHistoricEventsByFleet,
    } = useAnalyticsFleets();

    const handleUpdate = useCallback(async () => {
        handleGetListRankingFleet();
    }, [handleGetListRankingFleet]);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    useEffect(() => {
        if (selectedFleet) {
            handleGetEventsByFleet();
        }
    }, [handleGetEventsByFleet, selectedFleet]);

    const [filtersRankingGraphIsOpen, setFiltersRankingGraphIsOpen] = useState(false);

    const handleOpenGraphBarFilters = useCallback(() => {
        setFiltersRankingGraphIsOpen(state => !state);
    }, []);

    const [filtersTotalGraphIsOpen, setFiltersTotalGraphIsOpen] = useState(false);

    const handleOpenGraphTotalFilters = useCallback(() => {
        setFiltersTotalGraphIsOpen(state => !state);
    }, []);

    const [filtersRangeGraphIsOpen, setFiltersRangeGraphIsOpen] = useState(false);

    const handleOpenGraphRangeFilters = useCallback(() => {
        setFiltersRangeGraphIsOpen(state => !state);
    }, []);

    const [filtersMapGraphIsOpen, setFiltersMapGraphIsOpen] = useState(false);

    const handleOpenGraphMapFilters = useCallback(() => {
        setFiltersMapGraphIsOpen(state => !state);
    }, []);

    const filters = useMemo(() => {
        if (filterIsOpen) {
            return <FleetsFilter />;
        }

        return null;
    }, [filterIsOpen]);

    const [selectedType, setSelectedType] = useState<string>('Barra');
    const [selectedTypeBar, setSelectedTypeBar] = useState<string>('Dia');

    const [selectedTotalGraph, setSelectedTotalGraph] = useState<string>('Barra');
    const [selectedTotalPeriod, setSelectedTotalPeriod] = useState<string>('Dia');

    const [selectPeriod, setSelectPeriod] = useState<number>(1);
    const [selectTimeRangeTypeChart, setSelectTimeRangeTypeChart] = useState<'polar' | 'cartesian'>('polar');

    const handleApplyRankingFleetFilter = useCallback(() => {
        setUniqueEventsFilter(uniqueEvents.filter(eventProp => eventProp.checked === true));
    }, [uniqueEvents]);

    const handleApplyHeatMapFilter = useCallback(() => {
        setUniqueEventsFilterHeatMap(uniqueEventsHeatMap.filter(eventProp => eventProp.checked === true));
    }, [uniqueEventsHeatMap]);

    const { handleSubmit: handleTotalFilterSubmit } = useForm<IFleetTotalFilter>({
        defaultValues: {
            typeOfGraph: 'Barra',
            periodOfGraph: 'Dia',
        },
    });

    const handleApplyTotalFilter = useCallback(() => {
        setSelectedTotalGraph(selectedType);
        setSelectedTotalPeriod(selectedTypeBar);
    }, [selectedType, selectedTypeBar]);

    const handleChange = useCallback(
        (eventChange: SelectChangeEvent<string[]>) => {
            const selectedValues = eventChange.target.value as string[];
            const oldValues = [...uniqueEvents];

            const updatedOptions: EventsProps[] = oldValues.map(event => ({
                ...event,
                checked: selectedValues.includes(event.name),
            }));

            setUniqueEvents(updatedOptions);
        },
        [uniqueEvents],
    );

    const handleChangeHeatMap = useCallback(
        (eventChange: SelectChangeEvent<string[]>) => {
            const selectedValues = eventChange.target.value as string[];
            const oldValues = [...uniqueEventsHeatMap];

            const updatedOptions: EventsProps[] = oldValues.map(event => ({
                ...event,
                checked: selectedValues.includes(event.name),
            }));

            setUniqueEventsHeatMap(updatedOptions);
        },
        [uniqueEventsHeatMap],
    );

    useEffect(() => {
        const allEvents: EventsProps[] = [];
        const uniqueEvents = [...new Set(listRankingFleets?.flatMap(item => item.data.map(event => event.event)))];

        uniqueEvents.map(event => {
            allEvents.push({
                name: event,
                translate: t(`tableEventName.${event}`),
                checked: false,
            });
        });

        setUniqueEvents(allEvents);
        setUniqueEventsHeatMap(allEvents);
    }, [listRankingFleets, t]);

    const renderRankingFleetLegendChart = useCallback(() => {
        const filteredDataArray =
            uniqueEventsFilter.length > 0
                ? listRankingFleets?.map(item => ({
                    ...item,
                    data: item.data.filter(event =>
                        uniqueEventsFilter.some(eventFilter => eventFilter.name === event.event && eventFilter.checked),
                    ),
                }))
                : listRankingFleets;

        const sortedArrayDesc = filteredDataArray?.sort(
            (a, b) => b.data.reduce((sum, entry) => sum + entry.total, 0) - a.data.reduce((sum, entry) => sum + entry.total, 0),
        );

        const legends = [...new Set(sortedArrayDesc?.map(ranking => ranking.fleet))];

        return legends;
    }, [listRankingFleets, uniqueEventsFilter]);

    const renderRankingFleetDataChart = useCallback(() => {
        const content: BarChartDataStackProps[] = [];

        const typeEquipamentSelected = requestRankingData.typeEquipament === 'Telemetria' ? 'Geotab' : requestRankingData.typeEquipament;

        const labels = typeEquipament.filter(equipament => equipament.type === typeEquipamentSelected);

        const filteredDataArray =
            uniqueEventsFilter.length > 0
                ? listRankingFleets?.map(item => ({
                    ...item,
                    data: item.data.filter(event =>
                        uniqueEventsFilter.some(eventFilter => eventFilter.name === event.event && eventFilter.checked),
                    ),
                }))
                : listRankingFleets;

        const sortedArrayDesc = filteredDataArray?.sort(
            (a, b) => b.data.reduce((sum, entry) => sum + entry.total, 0) - a.data.reduce((sum, entry) => sum + entry.total, 0),
        );

        labels.forEach(label =>
            content.push({
                label: label.name,
                data: [],
                color: EventHelper.getColorByEventName(label.name),
            }),
        );

        content.forEach(serie => {
            sortedArrayDesc?.forEach(ranking => {
                const labelFounded = ranking.data.findIndex(fleet => fleet.event === serie.label);

                serie.data.push(labelFounded !== -1 ? (ranking.data[labelFounded].total ? ranking.data[labelFounded].total : 0) : 0);
            });
        });

        return content;
    }, [listRankingFleets, requestRankingData.typeEquipament, uniqueEventsFilter]);

    const renderEventsFleetTotalLegendChart = useMemo(() => {
        const listData: string[] = [];

        if (!listHistoricEventsByFleet) {
            return listData;
        }

        if (selectedTotalPeriod === 'Dia') {
            listHistoricEventsByFleet?.map(eventsFleet => {
                const index = listData.findIndex(data => data === format(new Date(eventsFleet.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));

                if (index === -1) {
                    listData.push(format(new Date(eventsFleet.date + blanckSpaceForFixDate), 'dd-MM-yyyy'));
                }
            });
        } else if (selectedTotalPeriod === 'Semana') {
            const uniqueDates = [...new Set(listHistoricEventsByFleet.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            const groupedDates: Record<string, string[]> = {};
            parsedDates.forEach(date => {
                const weekNumber = DateHelper.getWeek(date);
                const weekKey = `Semana ${weekNumber}`;
                if (!groupedDates[weekKey]) {
                    groupedDates[weekKey] = [];
                }

                groupedDates[weekKey].push(format(date, 'dd-MM-yyyy'));
            });

            Object.entries(groupedDates).map(date => {
                const { startWeekDate, endWeekDate } = DateHelper.getStartAndEndDateOfWeek(date[1][0]);

                listData.push(`${startWeekDate} - ${endWeekDate}`);
            });
        } else if (selectedTotalPeriod === 'Mês') {
            const uniqueDates = [...new Set(listHistoricEventsByFleet.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByMonth).map(date => {
                listData.push(format(new Date(date[0].split('-')[1] + '-01-' + date[0].split('-')[0]), 'MM-yyyy'));
            });
        } else if (selectedTotalPeriod === 'Ano') {
            const uniqueDates = [...new Set(listHistoricEventsByFleet.map(obj => obj.date))];

            const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByYear).map(date => {
                listData.push(format(new Date(date[0] + blanckSpaceForFixDate), 'yyyy'));
            });
        }

        return listData;
    }, [listHistoricEventsByFleet, selectedTotalPeriod]);

    const renderEventsByFleetTotalDataChart = useMemo(() => {
        const listData: BarChartDataStackProps[] = [];

        console.log(listHistoricEventsByFleet);

        if (!listHistoricEventsByFleet) {
            return listData;
        }

        const uniqueDates = [...new Set(listHistoricEventsByFleet.map(obj => obj.date))];
        const uniqueEvents = [...new Set(listHistoricEventsByFleet.map(obj => obj.event))];

        const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

        const groupedDates: Record<string, string[]> = {};

        parsedDates.forEach(date => {
            const weekNumber = DateHelper.getWeek(date);
            const weekKey = `Week ${weekNumber}`;

            if (!groupedDates[weekKey]) {
                groupedDates[weekKey] = [];
            }

            groupedDates[weekKey].push(format(date, 'dd-MM-yyyy'));
        });

        uniqueEvents.map((event, eventIndex) => {
            listData.push({
                data: [],
                label: event,
                color: EventHelper.getColorByEventName(event) as string,
            });

            if (selectedTotalPeriod === 'Dia') {
                uniqueDates.map(() => {
                    listData[eventIndex].data.push(0);
                });
            } else {
                Object.entries(groupedDates).map(() => {
                    listData[eventIndex].data.push(0);
                });
            }
        });

        if (selectedTotalPeriod === 'Dia') {
            uniqueDates.map((date, indexDate) => {
                listHistoricEventsByFleet.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    if (indexFilterData !== -1 && date === overview.date) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Semana') {
            Object.entries(groupedDates).map((date, indexDate) => {
                listHistoricEventsByFleet.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    const indexFilterDate = date[1].findIndex(
                        dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'),
                    );

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Mês') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByMonth).map((date, indexDate) => {
                listHistoricEventsByFleet.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(
                        dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'),
                    );

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Ano') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByYear).forEach((year, datesInYear) => {
                listHistoricEventsByFleet.forEach(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = year[1].findIndex(
                        dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'),
                    );

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[datesInYear] += overview.total;
                    }
                });
            });
        }

        return listData;
    }, [listHistoricEventsByFleet, selectedTotalPeriod]);

    const renderChartTotalEventsLineChart = useMemo(() => {
        const listData: StackedLineChart[] = [];

        if (!listHistoricEventsByFleet) {
            return listData;
        }

        const uniqueDates = [...new Set(listHistoricEventsByFleet.map(obj => obj.date))];
        const uniqueEvents = [...new Set(listHistoricEventsByFleet.map(obj => obj.event))];

        const parsedDates = uniqueDates.map(dateString => new Date(dateString.replace(/-/g, '/')));

        const groupedDates: Record<string, string[]> = {};

        parsedDates.forEach(date => {
            const weekNumber = DateHelper.getWeek(date);
            const weekKey = `Week ${weekNumber}`;
            if (!groupedDates[weekKey]) {
                groupedDates[weekKey] = [];
            }

            groupedDates[weekKey].push(format(date, 'dd-MM-yyyy'));
        });

        uniqueEvents.map((event, eventIndex) => {
            listData.push({
                data: [],
                label: event,
                color: EventHelper.getColorByEventName(event) as string,
            });
            if (selectedTotalPeriod === 'Dia') {
                uniqueDates.map(() => {
                    listData[eventIndex].data.push(0);
                });
            } else {
                Object.entries(groupedDates).map(() => {
                    listData[eventIndex].data.push(0);
                });
            }
        });

        if (selectedTotalPeriod === 'Dia') {
            uniqueDates.map((date, indexDate) => {
                listHistoricEventsByFleet.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);

                    if (indexFilterData !== -1 && date === overview.date) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Semana') {
            Object.entries(groupedDates).map((date, indexDate) => {
                listHistoricEventsByFleet.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(
                        dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'),
                    );

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += overview.total;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Mês') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByMonth: { [month: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const monthKey = dateObj.toISOString().slice(0, 7);
                datesByMonth[monthKey] = datesByMonth[monthKey] || [];
                datesByMonth[monthKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByMonth).map((date, indexDate) => {
                listHistoricEventsByFleet.map(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = date[1].findIndex(
                        dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'),
                    );

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[indexDate] += 1;
                    }
                });
            });
        } else if (selectedTotalPeriod === 'Ano') {
            parsedDates.sort((a, b) => a.getTime() - b.getTime());

            const datesByYear: { [year: string]: string[] } = {};
            parsedDates.forEach(dateObj => {
                const yearKey = dateObj.getFullYear().toString();

                datesByYear[yearKey] = datesByYear[yearKey] || [];
                datesByYear[yearKey].push(format(dateObj, 'dd-MM-yyyy'));
            });

            Object.entries(datesByYear).forEach((year, datesInYear) => {
                listHistoricEventsByFleet.forEach(overview => {
                    const indexFilterData = listData.findIndex(data => data.label === overview.event);
                    const indexFilterDate = year[1].findIndex(
                        dateFilter => dateFilter === format(new Date(overview.date + blanckSpaceForFixDate), 'dd-MM-yyyy'),
                    );

                    if (indexFilterData !== -1 && indexFilterDate !== -1) {
                        listData[indexFilterData].data[datesInYear] += 1;
                    }
                });
            });
        }

        return listData;
    }, [listHistoricEventsByFleet, selectedTotalPeriod]);

    const handleModalGraphFilter = () => {
        setFiltersRankingGraphIsOpen(state => !state);
    };

    const handleModalTotalGraphFilter = () => {
        setFiltersTotalGraphIsOpen(state => !state);
    };

    const renderEventsByFleetToHourLegendChart = useMemo(() => {
        const interval = Number(selectPeriod > 4 ? (selectPeriod === 5 ? 6 : 12) : selectPeriod);

        const hoursArray = Array.from({ length: 25 }, (_, index) => index);

        const labels = [];

        for (let i = 0; i < hoursArray.length; i += interval) {
            const groupStart = hoursArray[i];
            const groupEnd = Math.min(groupStart + interval - 1, 24);

            if (groupStart < 24) {
                const endLabel = Math.min(groupEnd, 24);
                labels.push(interval === 1 ? `${endLabel}H` : `${groupStart}-${endLabel}H`);
            }
        }

        return labels;
    }, [selectPeriod]);

    const renderEventsByFleetToHourDataChart = useMemo(() => {
        const content: BarPolarDataProps[] = [];

        const typeEquipamentSelected = requestRankingData.typeEquipament === 'Telemetria' ? 'Geotab' : requestRankingData.typeEquipament;

        const labels = typeEquipament.filter(equipament => equipament.type === typeEquipamentSelected);

        labels.forEach(label =>
            content.push({
                label: label.name,
                data: [],
                color: EventHelper.getColorByEventName(label.name),
            }),
        );

        const groupedEvents: Array<{ event: string; hourGroup: string; total: number }> = [];

        const interval = Number(selectPeriod > 4 ? (selectPeriod === 5 ? 6 : 12) : selectPeriod);

        listEventsByFleet?.forEach(fleet => {
            const hourGroup = Math.floor(fleet.hour / interval) * interval;

            const hourGroupLabel = interval === 1 ? `${hourGroup}H` : `${hourGroup}-${hourGroup + interval - 1}H`;

            const itemIndex = groupedEvents.findIndex(item => item.event === fleet.event && item.hourGroup === hourGroupLabel);

            if (itemIndex !== -1) {
                groupedEvents[itemIndex].total += 1;
            } else {
                groupedEvents.push({
                    event: fleet.event,
                    hourGroup: hourGroupLabel,
                    total: 1,
                });
            }
        });

        content.forEach(serie => {
            renderEventsByFleetToHourLegendChart.forEach(hour => {
                const eventFound = groupedEvents.find(groupedEvent => groupedEvent.event === serie.label && groupedEvent.hourGroup === hour);

                serie.data.push(eventFound ? eventFound.total : 0);
            });
        });

        return content;
    }, [listEventsByFleet, renderEventsByFleetToHourLegendChart, requestRankingData.typeEquipament, selectPeriod]);

    const dataHeatMapLayer = useMemo(() => {
        if (!listEventsByFleet || listEventsByFleet.length === 0) {
            return [];
        }

        const filteredEvents =
            uniqueEventsFilterHeatMap.length > 0
                ? listEventsByFleet.filter(event => {
                    return uniqueEventsFilterHeatMap.some(eventName => eventName.name === event.event);
                })
                : listEventsByFleet;

        return filteredEvents.map(event => new google.maps.LatLng(event.latitude, event.longitude));
    }, [listEventsByFleet, uniqueEventsFilterHeatMap]);

    const centerHeatMap = useMemo(() => {
        if (dataHeatMapLayer.length === 0) {
            return { lat: -22.7253017, lng: -47.6532601 };
        }

        const point1 = turf.point([dataHeatMapLayer[0].lat(), dataHeatMapLayer[0].lng()]);
        const point2 = turf.point([dataHeatMapLayer[dataHeatMapLayer.length - 1].lat(), dataHeatMapLayer[dataHeatMapLayer.length - 1].lng()]);

        const midpoint = turf.midpoint(point1, point2);

        return { lat: midpoint.geometry.coordinates[0], lng: midpoint.geometry.coordinates[1] };
    }, [dataHeatMapLayer]);

    const handleChangeStatus: ChangeEventHandler<HTMLInputElement> = event => {
        handleRequestListRankingFleet({ typeEquipament: event.target.value as 'DS' | 'FW' | 'Telemetria' });
    };

    const contentRanking = useMemo(() => {
        if (responseRankingStatus.loading) {
            return (
                <>
                    <Skeleton height={'40rem'} width={'20rem'} />
                    <Skeleton height={'40rem'} width={'30rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'450rem'} style={{ marginTop: '-1rem' }} />
                </>
            );
        } else if (responseRankingStatus.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetListRankingFleet} messageClassName="error" />;
        } else if (responseRankingStatus.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetListRankingFleet} />;
        }

        return (
            <>
                <GraphTitle>
                    <div>
                        <Title>
                            <h2>{t('fleets.fleet_ranking')}</h2>
                        </Title>
                        <ButtonFilter>
                            <FilterAlt color={'#46526C'} onClick={handleOpenGraphBarFilters} />
                        </ButtonFilter>
                    </div>
                    <div>
                        <p>{t('fleets.fleet_ranking_subtile')}</p>
                    </div>
                </GraphTitle>
                <BarChartStack
                    isDataZoom
                    dataZoom={{
                        start: 0,
                        end: 3,
                    }}
                    legends={renderRankingFleetLegendChart()}
                    data={renderRankingFleetDataChart()}
                    onEvents={{
                        click: (params: any) => {
                            const fleetFound = listRankingFleets?.find(fleet => fleet.fleet === params.name);

                            if (fleetFound) {
                                handleSelect(fleetFound);
                            }
                        },
                    }}
                />

                <ModalFilter>
                    {filtersRankingGraphIsOpen && (
                        <GraphsModal
                            topPosition={12}
                            rightPosition={40}
                            handleCloseGraphFilter={handleModalGraphFilter}
                            handleCancelButton={handleModalGraphFilter}
                            handleApplyButton={handleApplyRankingFleetFilter}
                        >
                            <Column gap={8}>
                                <p>{t('fleets.events')}</p>
                                <EventsFilter events={uniqueEvents} handleChange={handleChange} />
                            </Column>
                        </GraphsModal>
                    )}
                </ModalFilter>
            </>
        );
    }, [
        filtersRankingGraphIsOpen,
        handleApplyRankingFleetFilter,
        handleChange,
        handleGetListRankingFleet,
        handleOpenGraphBarFilters,
        handleSelect,
        listRankingFleets,
        renderRankingFleetDataChart,
        renderRankingFleetLegendChart,
        responseRankingStatus.hasError,
        responseRankingStatus.loading,
        responseRankingStatus.void,
        t,
        uniqueEvents,
    ]);

    const graphTotalSelected: JSX.Element = useMemo(() => {
        if (selectedTotalGraph === 'Barra') {
            return (
                <BarChartStack
                    legends={renderEventsFleetTotalLegendChart}
                    data={renderEventsByFleetTotalDataChart}
                    isDataZoom
                    dataZoom={{ start: 95, end: 100 }}
                />
            );
        }

        if (selectedTotalGraph === 'Linha') {
            return <StackedLineChart legends={renderEventsFleetTotalLegendChart} data={renderChartTotalEventsLineChart} />;
        }

        return <></>;
    }, [renderChartTotalEventsLineChart, renderEventsByFleetTotalDataChart, renderEventsFleetTotalLegendChart, selectedTotalGraph]);

    const graphTimeRangeSelected: JSX.Element = useMemo(() => {
        if (selectTimeRangeTypeChart === 'polar') {
            return <BarPolar dataY={renderEventsByFleetToHourLegendChart} dataX={renderEventsByFleetToHourDataChart} />;
        }
        if (selectTimeRangeTypeChart === 'cartesian') {
            return <BarChartStack legends={renderEventsByFleetToHourLegendChart} data={renderEventsByFleetToHourDataChart} />;
        }
        return <></>;
    }, [renderEventsByFleetToHourDataChart, renderEventsByFleetToHourLegendChart, selectTimeRangeTypeChart]);

    const contentFleetDetails = useMemo(() => {
        if (!selectedFleet) {
            return <NoContent message={t('fleets.need_to_select_fleet')} />;
        }

        if (responseEventsByFleetStatus.loading) {
            return (
                <>
                    <Skeleton height={'40rem'} width={'20rem'} />
                    <Skeleton height={'40rem'} width={'30rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'450rem'} style={{ marginTop: '-1rem' }} />
                </>
            );
        } else if (responseEventsByFleetStatus.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetEventsByFleet} messageClassName="error" />;
        } else if (responseEventsByFleetStatus.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetEventsByFleet} />;
        }

        return (
            <>
                <GraphTitle>
                    <div>
                        <Title>
                            <h2>
                                {t('fleets.total')} {selectedFleet?.fleet ? ` - ${selectedFleet?.fleet}` : ''}
                            </h2>
                        </Title>
                        <ButtonFilter>
                            <FilterAlt color={'#46526C'} onClick={handleOpenGraphTotalFilters} />
                        </ButtonFilter>
                    </div>
                    <div>
                        <p>{t('fleets.fleet_total_subtile')}</p>
                    </div>
                </GraphTitle>

                {graphTotalSelected}

                <ModalFilter>
                    {filtersTotalGraphIsOpen && (
                        <GraphsModal
                            topPosition={12}
                            rightPosition={40}
                            handleCancelButton={handleModalTotalGraphFilter}
                            handleCloseGraphFilter={handleModalTotalGraphFilter}
                            handleApplyButton={handleTotalFilterSubmit(handleApplyTotalFilter)}
                        >
                            <FilterOptionContainer onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedType(event.target.value)}>
                                <p>{t('fleets.type')}</p>
                                <RadioButtonContainer>
                                    <RadioButton
                                        group="filterchecktype"
                                        checked={selectedType === 'Barra'}
                                        name={String(t('fleets.bar'))}
                                        value={'Barra'}
                                    />
                                    <RadioButton
                                        group="filterchecktype"
                                        checked={selectedType === 'Linha'}
                                        name={String(t('fleets.line'))}
                                        value={'Linha'}
                                    />
                                </RadioButtonContainer>
                            </FilterOptionContainer>

                            <FilterOptionContainer
                                className="teste"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedTypeBar(event.target.value)}
                            >
                                <p>{t('fleets.period_view')}</p>
                                <RadioButtonContainer>
                                    <RadioButton
                                        group="filtercheck"
                                        checked={selectedTypeBar === 'Dia'}
                                        name={String(t('fleets.day'))}
                                        value={'Dia'}
                                    />
                                    <RadioButton
                                        group="filtercheck"
                                        checked={selectedTypeBar === 'Semana'}
                                        name={String(t('fleets.week'))}
                                        value={'Semana'}
                                    />
                                    <RadioButton
                                        group="filtercheck"
                                        checked={selectedTypeBar === 'Mês'}
                                        name={String(t('fleets.month'))}
                                        value={'Mês'}
                                    />
                                    <RadioButton
                                        group="filtercheck"
                                        checked={selectedTypeBar === 'Ano'}
                                        name={String(t('fleets.year'))}
                                        value={'Ano'}
                                    />
                                </RadioButtonContainer>
                            </FilterOptionContainer>
                        </GraphsModal>
                    )}
                </ModalFilter>
            </>
        );
    }, [
        filtersTotalGraphIsOpen,
        graphTotalSelected,
        handleApplyTotalFilter,
        handleGetEventsByFleet,
        handleOpenGraphTotalFilters,
        handleTotalFilterSubmit,
        responseEventsByFleetStatus.hasError,
        responseEventsByFleetStatus.loading,
        responseEventsByFleetStatus.void,
        selectedFleet,
        selectedType,
        selectedTypeBar,
        t,
    ]);

    const { register: registerFleetTimeRange, handleSubmit: handleFleetTimeRangeSubmit } = useForm<IFleetTimeRangeFilter>({
        defaultValues: {
            slider: selectPeriod,
            typeCoordinates: selectTimeRangeTypeChart,
        },
    });

    const handleApplyFleetTimeRangeFilter = useCallback((data: IFleetTimeRangeFilter) => {
        const { typeCoordinates, slider } = data;

        setSelectPeriod(Number(slider));
        setSelectTimeRangeTypeChart(typeCoordinates);
    }, []);

    const contentFleetTimeRange = useMemo(() => {
        if (!selectedFleet) {
            return <NoContent message={t('fleets.need_to_select_fleet')} />;
        }

        if (responseEventsByFleetStatus.loading) {
            return (
                <>
                    <Skeleton height={'40rem'} width={'20rem'} />
                    <Skeleton height={'40rem'} width={'30rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'450rem'} style={{ marginTop: '-1rem' }} />
                </>
            );
        } else if (responseEventsByFleetStatus.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetEventsByFleet} messageClassName="error" />;
        } else if (responseEventsByFleetStatus.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetEventsByFleet} />;
        }

        return (
            <>
                <GraphTitle>
                    <div>
                        <Title>
                            <h2>{t('fleets.time_range')}</h2>
                        </Title>
                        <ButtonFilter>
                            <FilterAlt color={'#46526C'} onClick={handleOpenGraphRangeFilters} />
                        </ButtonFilter>
                    </div>
                    <div>
                        <p>{t('fleets.time_range_subtile')}</p>
                    </div>
                </GraphTitle>

                {graphTimeRangeSelected}

                <ModalFilter>
                    <FilterRange>
                        {filtersRangeGraphIsOpen && (
                            <GraphsModal
                                topPosition={12}
                                rightPosition={50}
                                handleCloseGraphFilter={handleOpenGraphRangeFilters}
                                handleCancelButton={handleOpenGraphRangeFilters}
                                handleApplyButton={handleFleetTimeRangeSubmit(handleApplyFleetTimeRangeFilter)}
                            >
                                <div style={{ marginBottom: '1rem' }}>
                                    <p>{t('fleets.hours_per_bar')}</p>
                                    <div className="radioInput" style={{ margin: '0.3rem 0.5rem 0 0.5rem' }}>
                                        <Slider {...registerFleetTimeRange('slider')} marks={marks} step={null} min={1} max={6} />
                                    </div>
                                </div>
                                <div>
                                    <p style={{ marginBottom: '0.5rem' }}>{t('fleets.period')}</p>
                                    <FilterTimeRangeRadioContainer>
                                        <Radio {...registerFleetTimeRange('typeCoordinates')} value="polar" label={String(t('fleets.polar'))} />
                                        <Radio
                                            {...registerFleetTimeRange('typeCoordinates')}
                                            value="cartesian"
                                            label={String(t('fleets.cartesian'))}
                                        />
                                    </FilterTimeRangeRadioContainer>
                                </div>
                            </GraphsModal>
                        )}
                    </FilterRange>
                </ModalFilter>
            </>
        );
    }, [
        filtersRangeGraphIsOpen,
        graphTimeRangeSelected,
        handleApplyFleetTimeRangeFilter,
        handleFleetTimeRangeSubmit,
        handleGetEventsByFleet,
        handleOpenGraphRangeFilters,
        registerFleetTimeRange,
        responseEventsByFleetStatus.hasError,
        responseEventsByFleetStatus.loading,
        responseEventsByFleetStatus.void,
        selectedFleet,
        t,
    ]);

    const contentFleetHeatMap = useMemo(() => {
        if (!selectedFleet) {
            return <NoContent message={t('fleets.need_to_select_fleet')} />;
        }

        if (responseEventsByFleetStatus.loading) {
            return (
                <>
                    <Skeleton height={'40rem'} width={'20rem'} />
                    <Skeleton height={'40rem'} width={'30rem'} style={{ marginTop: '-1.5rem' }} />
                    <Skeleton height={'450rem'} style={{ marginTop: '-1rem' }} />
                </>
            );
        } else if (responseEventsByFleetStatus.hasError) {
            return <NoContent message={t('general.error')} onClick={handleGetEventsByFleet} messageClassName="error" />;
        } else if (responseEventsByFleetStatus.void) {
            return <NoContent message={t('general.no_content')} onClick={handleGetEventsByFleet} />;
        }

        return (
            <>
                <GraphTitle>
                    <div>
                        <Title>
                            <h2>{t('fleets.heat_map')}</h2>
                        </Title>
                        <ButtonFilter>
                            <FilterAlt color={'#46526C'} onClick={handleOpenGraphMapFilters} />
                        </ButtonFilter>
                    </div>
                    <div>
                        <p>{t('fleets.heat_map_subtitle')}</p>
                    </div>
                </GraphTitle>

                <GoogleMapContainer center={centerHeatMap}>
                    <HeatmapLayer
                        options={{
                            radius: radius,
                        }}
                        data={dataHeatMapLayer}
                    />
                </GoogleMapContainer>

                <ModalFilter>
                    {filtersMapGraphIsOpen && (
                        <GraphsModal
                            topPosition={12}
                            rightPosition={50}
                            handleCloseGraphFilter={handleOpenGraphMapFilters}
                            handleCancelButton={handleOpenGraphMapFilters}
                            handleApplyButton={handleApplyHeatMapFilter}
                        >
                            <div>
                                <p>{t('fleets.events')}</p>
                                <EventsFilter events={uniqueEventsHeatMap} handleChange={handleChangeHeatMap} />
                            </div>
                            <div className="heatMapInputs">
                                <div>
                                    <Input
                                        name="raio"
                                        label={String(t('Raio'))}
                                        style={{ width: '100%' }}
                                        type="number"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRadius(Number(e.target.value))}
                                        value={radius}
                                        defaultValue={20}
                                    />
                                </div>
                                <div>
                                    <Input name="blur" label={String(t('Blur'))} style={{ width: '100%' }} type="number" defaultValue={7} />
                                </div>
                            </div>
                        </GraphsModal>
                    )}
                </ModalFilter>
            </>
        );
    }, [
        centerHeatMap,
        dataHeatMapLayer,
        filtersMapGraphIsOpen,
        handleApplyHeatMapFilter,
        handleChangeHeatMap,
        handleGetEventsByFleet,
        handleOpenGraphMapFilters,
        radius,
        responseEventsByFleetStatus.hasError,
        responseEventsByFleetStatus.loading,
        responseEventsByFleetStatus.void,
        selectedFleet,
        t,
        uniqueEventsHeatMap,
    ]);

    return (
        <Column width="100%">
            <Row align="center" width="100%" justify="space-between" padding="0 0 1rem 0">
                <Title>
                    <h2>Analytics</h2>
                    <UpdateData onClick={handleUpdate} />
                </Title>
            </Row>

            <MainCard style={{ paddingBottom: '1rem' }}>
                <Column width="100%" gap={16}>
                    <CardHeader>
                        <Row gap={16} align="center">
                            <h3>{t('fleets.fleets')}</h3>
                        </Row>

                        <Row align="center" gap={16}>
                            <Row align="center" gap={16}>
                                <label style={{ color: '#333333', fontWeight: '500' }}>{t('general.view')}</label>
                                <Radio
                                    name="status"
                                    value="DS"
                                    label="DS"
                                    checked={requestRankingData.typeEquipament === 'DS'}
                                    onChange={handleChangeStatus}
                                />
                                <Radio
                                    name="status"
                                    value="FW"
                                    label="FW"
                                    checked={requestRankingData.typeEquipament === 'FW'}
                                    onChange={handleChangeStatus}
                                />
                                <RenderIf condition={hasTelemetry('Geotab')}>
                                    <Radio
                                        name="status"
                                        value="Telemetria"
                                        label="Telemetria"
                                        checked={requestRankingData.typeEquipament === 'Telemetria'}
                                        onChange={handleChangeStatus}
                                    />
                                </RenderIf>
                            </Row>

                            <Row align="center" gap={8}>
                                <Button
                                    color={filterIsOpen ? 'purple' : 'outline-purple'}
                                    leftIcon={<FilterAlt color={filterIsOpen ? '#fff' : '#46526C'} />}
                                    size="small"
                                    rightIcon={<ChevronDown spin={filterIsOpen} color={filterIsOpen ? '#fff' : '#46526C'} />}
                                    type="button"
                                    onClick={handleFilterIsOpen}
                                >
                                    {t('general.filter')}
                                </Button>
                            </Row>
                        </Row>
                    </CardHeader>
                    <Divider />
                    {filters}
                </Column>

                <Row padding="0.8rem">
                    <RankingSection>
                        <RankingContainer>{contentRanking}</RankingContainer>
                    </RankingSection>
                </Row>

                <Row padding="0.8rem">
                    <RankingSection>
                        <RankingContainer>{contentFleetDetails}</RankingContainer>
                    </RankingSection>
                </Row>

                <Row padding="0.8rem" style={{ justifyContent: 'space-between' }}>
                    <GraphSection>
                        <GraphContainer>{contentFleetTimeRange}</GraphContainer>

                        <GraphContainer>{contentFleetHeatMap}</GraphContainer>
                    </GraphSection>
                </Row>
            </MainCard>
        </Column>
    );
}
