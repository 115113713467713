import Slider from '@mui/material/Slider';
import { t } from 'i18next';

import { SliderRangeContainer, VelocitySpan } from './styles';

interface RangeSliderProps {
    min: number,
    max: number,
    value: number[],
    setValue: (value: number[] | ((prevVar: number[]) => number[])) => void;
}

export default function RangeSlider({min, max, value, setValue}: RangeSliderProps) {
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

  return (
    <SliderRangeContainer>
        <VelocitySpan>{t('alarms.velocity')} ({value[0] + ' Km/h - ' + value[1] + ' Km/h'})</VelocitySpan>
        <Slider
            min={min}
            max={max}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
        />
    </SliderRangeContainer>
  );
}
