import { GoogleMap } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { Column, Skeleton } from 'src/common/components';
import { useMap } from 'src/common/hooks';
import { GetHistoricalRouteOfEventsResponse } from 'src/modules/alarm-processing/services';

import { ErrorMessage } from '../historic-route/styles';
import MapCircleHistoricalRoute from '../map-circle-historical-route';
import MapEventLocation from '../map-event-location';
import MapLastLocation from '../map-last-location';
import { mapContainerStyle } from './styles';

export default function HistoricalRouteMap({ lastAppearance, historicalRoutes, selectedEvent }: GetHistoricalRouteOfEventsResponse) {
    const { isLoaded, loadError } = useMap();
    const { t } = useTranslation();

    if (loadError) return <ErrorMessage>{t('general.error')}</ErrorMessage>;

    if (isLoaded) {
        return (
            <GoogleMap
                zoom={15}
                mapContainerStyle={mapContainerStyle}
                clickableIcons
                center={{
                    lat: selectedEvent?.latitude as number,
                    lng: selectedEvent?.longitude as number,
                }}
                options={{
                    gestureHandling: 'greedy',
                }}
            >
                {lastAppearance && <MapLastLocation {...lastAppearance} />}

                {selectedEvent && <MapEventLocation {...selectedEvent} />}

                {/*events?.map(event => (
                    <MapCircle key={Number(event.id)} {...event} />
                ))*/}

                {historicalRoutes?.map(route => (
                    <MapCircleHistoricalRoute key={Number(route.id)} {...route} />
                ))}
            </GoogleMap>
        );
    }

    return (
        <Column align="center" justify="center" gap={4} width="742px" height="450px">
            <Skeleton />
        </Column>
    );
}
