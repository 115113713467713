import { SVGProps } from 'react';

export default function Search(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.917 11.667h-.659l-.233-.225a5.393 5.393 0 001.308-3.525 5.417 5.417 0 10-5.416 5.416 5.393 5.393 0 003.525-1.308l.225.233v.659l4.166 4.158 1.242-1.242-4.158-4.166zm-5 0a3.745 3.745 0 01-3.75-3.75 3.745 3.745 0 013.75-3.75 3.745 3.745 0 013.75 3.75 3.745 3.745 0 01-3.75 3.75z"
                fill="#C2C9D1"
            />
            <path
                d="M12.917 11.667h-.659l-.233-.225a5.393 5.393 0 001.308-3.525 5.417 5.417 0 10-5.416 5.416 5.393 5.393 0 003.525-1.308l.225.233v.659l4.166 4.158 1.242-1.242-4.158-4.166zm-5 0a3.745 3.745 0 01-3.75-3.75 3.745 3.745 0 013.75-3.75 3.745 3.745 0 013.75 3.75 3.745 3.745 0 01-3.75 3.75z"
                fill="#000"
                fillOpacity={0.25}
            />
        </svg>
    );
}
