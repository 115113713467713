import { Fragment, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow } from 'src/common/components/table/styles';

import { DateHelper, Skeleton, Table } from '../../../../../common';
import { useComparisonOfUnits } from '../../hook';
import { TableColumnData } from './styles';

function TableList({ tab }: { tab: number}) {
    const { t, i18n } = useTranslation();

    const { comparisonOfUnits } = useComparisonOfUnits();

    const comparisonOfUnitsData = comparisonOfUnits?.elements[0];
    const comparisonOfUnitsKeys = comparisonOfUnitsData
        ? Object.keys(comparisonOfUnitsData)
        : [];
    const selectedKey = comparisonOfUnitsKeys[tab];

    const headers = Object.keys((comparisonOfUnitsData as any)[selectedKey])

    const tableHeaders = useMemo(
        () => {
            return headers.map((head): string => t('comparison_of_units.' + head))
        },
        [t],
    );

    useEffect(() => {
        console.log(tab)
    }, [tab])

    return (
        <Fragment>
            {
                comparisonOfUnitsData
                    ? <Table tableHeaders={tableHeaders}>
                        {comparisonOfUnits?.elements?.map((data, index) => {
                            return (
                                <TableRow className="body" key={index}>
                                    <TableColumnData backgroundColor="#218821">{'a'}</TableColumnData>
                                </TableRow>
                            );
                        })}
                    </Table>
                    : <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>
            }
        </Fragment>
    );
}

export default memo(TableList);
