import styled from 'styled-components';

export const Grid = styled.div`
    display: grid;
    column-gap: 52px;
    row-gap: 16px;
    flex-wrap: wrap;
    align-items: center;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
`;

export const All = styled.button`
    cursor: pointer;
    background: transparent;
    border: 0;

    display: flex;
    align-items: center;
    text-decoration-line: underline;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: #6da4d3;
`;
