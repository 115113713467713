import styled from 'styled-components';

export const ForgotPasswordButton = styled.button`
    cursor: pointer;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #46526c;
    padding: 0;
    border: 0;
    background-color: transparent;
    margin: 0;
`;
