import { Image } from 'src/common/components';
import styled, { css } from 'styled-components';
interface PreviewButtonProps {
    selected?: boolean;
}

export const Container = styled.section`
    width: 46.3125rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    position: relative;
`;

export const BlankContainer = styled.div`
    border: 0;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;
    z-index: 9999;
`

export const CloseButton = styled.button`
    border: 0;
    width: 1.25rem;
    height: 1.25rem;

    padding: 0;

    color: #fff;
    background: #ef1d00;
    border-radius: 50%;

    left: 6.125rem;
    top: 0.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 9999;
`;

export const PreviewButton = styled.button<PreviewButtonProps>`
    padding: 0;
    margin: 0;
    cursor: pointer;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 112px;
    width: 112px;
    height: 65px;
    overflow: hidden;

    transition: transform 300ms ease 100ms;

    border-radius: 8px;

    &:hover {
        transform: scale(1.05);
    }

    ${({ selected }) =>
        selected &&
        css`
            border: 2px solid #186deb;

            filter: drop-shadow(0px 0px 2px #66a3d4);
        `}
`;

export const Preview = styled(Image)`
    width: 100%;
    height: 65px;
    width: 112px;
    border-radius: 8px;
    object-fit: fill;
`;

export const NavigationButton = styled.button`
    cursor: pointer;
    width: 24px;
    height: 24px;

    background: rgba(255, 255, 255, 0.7);
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 0;
    transition: background-color 0.3s;

    position: relative;
    z-index: 1;

    &:hover {
        background-color: #f7f7f7;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    flex: 1;
    align-items: center;

    padding: 0 4px;

    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
`;
