import { SignalDTO, api } from 'src/common';
import { Service } from 'src/common/core';

type Request = SignalDTO & { id: number };

export class DeleteElectronicFenceService implements Service<Request, void> {
    public async execute(request: Request): Promise<void> {
        const { signal, id } = request;

        await api.delete(`/vehicle-monitoring/electronic-fences/${id}`, {
            signal,
        });
    }
}

export const deleteElectronicFence = new DeleteElectronicFenceService();
