import { SVGProps } from 'react';

function Plus(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.91 12.395h7.659v7.659c0 .778.64 1.43 1.431 1.43s1.431-.652 1.431-1.43v-7.66h7.66c.778 0 1.43-.64 1.43-1.43 0-.792-.652-1.432-1.43-1.432h-7.66v-7.66c0-.778-.64-1.43-1.431-1.43s-1.431.652-1.431 1.43v7.66h-7.66c-.778 0-1.43.64-1.43 1.431s.652 1.432 1.43 1.432z"
                fill="#fff"
            />
        </svg>
    );
}

export default Plus;
