import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArgusLogo, Button, Column, Input, RenderIf, Row } from 'src/common/components';
import { useAuth, useLogin } from 'src/modules/auth';
import { MFA } from 'src/modules/auth/dtos/mfa.dto';
import { object, string } from 'yup';

import BackButton from '../../../modules/auth/components/back-button';
import AuthBackground from '../../../modules/auth/components/background';
import AuthCard from '../../../modules/auth/components/card';
import { SupportingText } from '../../../modules/auth/components/forgot-password';
import { AuthForm } from '../../../modules/auth/components/form';
import { LoginFormDTO } from '../../../modules/auth/dtos/auth.dto';

const mfaSchema = object().shape({
    code: string().required('field_validations.required'),
});

export default function MFALogin() {
    const { challenge } = useAuth();
    const { mutateAsync: login, isLoading } = useLogin();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit: onSubmit,
        formState: { errors, isValid },
    } = useForm<LoginFormDTO>({
        resolver: yupResolver(mfaSchema),
    });

    const controller = useMemo(() => new AbortController(), []);

    const handleSubmit = useCallback(
        async (data: LoginFormDTO) => {
            const { code } = data;

            await login({
                code,
                mfaType: challenge?.challengeName === 'SMS_MFA' ? MFA.SMS : MFA.TOTP,
                password: challenge?.password as string,
                signal: controller.signal,
                username: challenge?.email as string,
            });
        },
        [challenge?.challengeName, challenge?.email, challenge?.password, controller.signal, login],
    );

    return (
        <AuthBackground>
            <Column align="center" justify="center" size="100%">
                <AuthCard>
                    <Row width="100%" align="center" justify="space-between" gap={8}>
                        <BackButton />
                        <ArgusLogo color="#262D3C" height={30} width={131} />
                        <div style={{ width: '30px' }} />
                    </Row>
                    <Column align="center" gap={16}>
                        <RenderIf condition={challenge?.challengeName === 'SMS_MFA'}>
                            <SupportingText>
                                {t('auth.enter_the_code_sent_to')} <br />
                                <b>{challenge?.challengeParameters?.CODE_DELIVERY_DESTINATION}</b>
                            </SupportingText>
                        </RenderIf>

                        <AuthForm onSubmit={onSubmit(handleSubmit)}>
                            <Column width="302px" align="center" gap={16}>
                                <Column width="100%" id="fieldset" align="flex-start" gap={8}>
                                    <Input
                                        type="text"
                                        placeholder={t('auth.verification_code')?.toString()}
                                        style={{ width: '100%' }}
                                        feedback={{
                                            message: t(errors['code']?.message || '')?.toString(),
                                        }}
                                        disabled={isLoading}
                                        {...register('code')}
                                    />
                                </Column>
                            </Column>
                            <Column gap={16} width="100%" align="center">
                                <Button type="submit" disabled={!isValid} isLoading={isLoading}>
                                    {t('auth.login')}
                                </Button>
                            </Column>
                        </AuthForm>
                    </Column>
                </AuthCard>
            </Column>
        </AuthBackground>
    );
}
