import { forwardRef, ForwardRefRenderFunction, useEffect, useRef, VideoHTMLAttributes } from 'react';

interface VideoPlayerProps extends VideoHTMLAttributes<HTMLVideoElement> {
    source: string;
    type: string;
}

const Video: ForwardRefRenderFunction<HTMLVideoElement, VideoPlayerProps> = ({ source, type, ...props }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleKeyPress = (event: KeyboardEvent) => {
        if (videoRef.current) {
            switch (event.key) {
                case '0':
                    videoRef.current.playbackRate = 0.5;
                    break;
                case '1':
                    videoRef.current.playbackRate = 1;
                    break;
                case '2':
                    videoRef.current.playbackRate = 1.5;
                    break;
                case '3':
                    videoRef.current.playbackRate = 2;
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <video width="320" height="240" controls ref={videoRef} {...props} muted autoPlay loop>
            <source src={source} type={type} />
            Your browser does not support the video tag.
        </video>
    );
};

const VideoPlayer = forwardRef(Video);

export default VideoPlayer;
