import { GenerateEventBy } from '../domain/constants/generate-event-by';
import { GeometricShape } from '../domain/constants/geometric-shape';
import { CreateElectronicFenceDTO, CreateElectronicFenceFormDTO } from '../dtos/create-electronic-fence.dto';

export class ElectronicFenceMapper {
    public static fromFormToDTO(data: CreateElectronicFenceFormDTO): CreateElectronicFenceDTO {
        return {
            active: Boolean(JSON.parse(data.active)),
            color: data.color || undefined,
            colorTransparencyPercentage: data.colorTransparencyPercentage ? Number(data.colorTransparencyPercentage) : undefined,
            coordinates: data.coordinates,
            expiresIn: data.expiresIn,
            generateEventBy: data.generateEventBy as GenerateEventBy,
            geometricShape: data.geometricShape as GeometricShape,
            name: data.name.trim(),
            observation: data?.observation?.trim(),
            operation: data?.operation.trim(),
            operationId: Number(data.operationId),
            radius: data.radius,
            expirationDate: data?.expirationDate ? new Date(data?.expirationDate).toISOString() : undefined,
            mapZoom: data?.zoom,
        };
    }
}
