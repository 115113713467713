import britishFlag from 'src/assets/flags/british-flag.svg';

import { LanguageTranslations } from '../types/translations';

const EN_US: LanguageTranslations = {
    dateFormat: 'MM/dd/yyyy',
    flag: britishFlag,
    translations: {
        auth: {
            unable_to_scan: 'Unable to scan? You can use the X to manually configure your authenticator app.',
            setup_key: 'setup key',
            scan_the_qr_code: 'Scan the QR code',
            use_an_authenticator_app_or_browser_extension_to_scan: 'Use an authenticator app or browser extension to scan.',
            verify_code: 'Verify code',
            preferred_mfa_method: 'Preferred MFA method',
            authenticator_app: 'Authenticator app',
            sms: 'SMS/Text message',
            configure_mfa_text: 'To continue, please configure MFA for this user.',
            configure_mfa: 'Configure MFA',
            enter_the_code_sent_to: 'Enter the code sent to:',
            redefine_password: 'Redefine password',
            access_password: 'Access password',
            confirm_new_password: 'Confirm new password',
            email: 'Email',
            email_or_phone: 'Email or phone',
            forgot_password: 'Forgot password',
            login: 'Login',
            logout_confirmation: 'You are going to log out, do you want to continue?',
            logout_system: 'Logout',
            name: 'Name',
            new_password: 'New password',
            password: 'Password',
            password_confirmation: 'Password confirmation',
            register_new_password: 'To continue, check your data and register a password to access our platform.',
            remember_password: 'Remember password',
            send: 'Send',
            user: 'User',
            welcome: 'Welcome',
            continue: 'Continue',
            error_login: 'Incorrect username or password.',
            forgot_password_page_message: 'Enter the registered email to receive the reset link:',
            forgot_password_code_message: 'Enter the verification code received.',
            request_new_code: 'Request new code',
            verification_code: 'Verification code',
            on_confirm_change_pass: {
                success: {
                    title: 'Success',
                    message: 'Password changed successfully.',
                },
                error: {
                    expiredCodeException: {
                        title: 'Expired code',
                        message: 'Expired verification code.',
                    },
                },
            },
            on_request_code: {
                success: {
                    title: 'Request received',
                    message: 'Recovery email sent successfully!',
                },
                error: {
                    limitExceeded: {
                        title: 'Limit exceeded',
                        message: 'Retry limits already exceeded, please try again later.',
                    },
                },
            },
        },
        configurations: {
            equals_resources: 'Equals resources',
            edit_password_policy: 'Edit Password Policy',
            view_password_policy: 'View Password Policy',
            view_operation: 'View Operation',
            view_function: 'View Function',
            edit_function: 'Edit Function',
            view_user: 'View User',
            save_contact: 'Save contact',
            do_you_want_to_delete_the_password_policy: 'Do you want to delete the password policy',
            delete_policy: 'Delete Policy',
            mfa_validation: 'Mandatory MFA Validation',
            password_policy_status: 'Password Policy Status',
            current_password_must_be_different_from_the_last_X_passwords: 'The current password must be different from the last X passwords.',
            contains_at_least_one_uppercase_letter: 'Contains at least 1 uppercase letter',
            contains_at_least_one_lowercase_letter: 'Contains at least 1 lowercase letter',
            contains_at_least_one_special_character: 'Contains at least 1 special character',
            contains_at_least_one_number: 'Contains at least 1 number',
            minimum_time: 'Minimum Type (in days)',
            expiry_time: 'Expiry time (days)',
            password_minimum_length: 'Password minimum length (characters)',
            assigned_to: 'Assigned to',
            password_policy_name: 'Password policy name',
            create_password_policy: 'Create Password Policy',
            password_policies: 'Password Policies',
            redefine_password: 'Redefine password',
            view_vehicle: 'View Vehicle',
            edit_vehicle: 'Edit Vehicle',
            delete_vehicle: 'Delete Vehicle',
            delete_the_vehicle_white_board: 'Do you want to delete the license plate vehicle',
            acquisition_date: 'Acquisition Date',
            add_vehicle: 'Add vehicle',
            current_vehicle_statuses: {
                new: 'New',
                semi_new: 'Semi new',
                used: 'Used',
            },
            vehicle_types: {
                agricultural_machine: 'Agricultural Machine',
                bus: 'Bus',
                light_vehicle: 'Light Vehicle',
                locomotive: 'Locomotive',
                motorcycle: 'Motorcycle',
                ship: 'Ship',
                stationary_equipment: 'Stationary Equipment',
                truck: 'Truck',
            },
            current_vehicle_status: 'Current vehicle status',
            vehicle_type: 'Vehicle type',
            add_department: 'Add Operation',
            add_function: 'Add function',
            add_user: 'Add user',
            ambiguous_record: 'This record is ambiguous, select the desired record.',
            confirm_delete_functions: 'Do you want to delete the role(s)?',
            confirm_delete_users: 'Do you want to delete the user(s)?',
            confirm_password: 'Confirm Password',
            contact: 'Contact',
            contacts: 'Contacts',
            create_department: 'Create Operation',
            create_vehicle: 'Create vehicle',
            creation_date: 'Creation date',
            creator: 'Creator',
            delete: 'Delete',
            delete_department: 'Delete Operation',
            delete_function: 'Delete role',
            delete_the_user: 'Do you want to delete the user',
            department: 'Operation',
            departments: 'Operations',
            do_you_want_to_delete_the_department: 'Do you want to delete the Operation(s)',
            do_you_want_to_delete_the_role: 'Do you want to delete the role',
            edit_department: 'Edit Operation',
            edit_user: 'Edit user',
            email: 'E-mail',
            equipments: 'Equipments',
            function: 'Function',
            function_name: 'Function name',
            function_number: 'Function no.',
            functionalities: 'Functionalities',
            functions: 'Functions',
            institution: 'Institution',
            institution_name: 'Institution name',
            login: 'Login',
            name_login: 'Name login',
            name_user: 'User name',
            number: 'Number',
            observation: 'Observation',
            organization: 'Organization',
            organization_name: 'Organization Name',
            paper: 'Paper',
            password: 'Password',
            password_policy: 'Password policy',
            phone_number: 'Phone number',
            settings: 'Settings',
            show_active_users: 'Show active users',
            status: 'Status',
            system: 'System',
            this_action: 'This action cannot be undone',
            user: 'User',
            user_delete: 'Delete user',
            users: 'Users',
            vehicles: 'Vehicles',
            client: 'Client',
            analyst: 'Analyst',
            client_analyst: 'Analyst Client',
            profile: 'Profile',
            confirm: 'Confirm',
            description: 'Descrição',
            intervention_protocol: 'Intervention Protocol',
        },
        field_validations: {
            min: 'The minimum number of characters is: $$min',
            name: 'Invalid name',
            date_is_before_creation_date: 'The selected date cannot be less than the creation date',
            date_is_before_today: 'The selected date cannot be less than the current date',
            date: 'Invalid date',
            phone_number_tooltip: 'Enter a phone number, including + and the country code, for example +13101234567',
            password_length_recommended:
                'Must be a number between 6 and 99. We strongly recommend that you require passwords to be at least 8 characters in length.',
            equals_resources: 'Parent and child resources are equal',
            mandatory_fields: 'Mandatory fields',
            max: 'Max size: %%',
            email: 'Invalid email',
            hour: 'Invalid time format',
            invalid_record: 'Invalid record',
            passwords_match: 'Passwords do not match',
            record_already_exists: 'This record already exists.',
            required: 'Required field',
            phone: 'Invalid phone',
            password: {
                lower_case: 'The password should contain lowercase letters.',
                upper_case: 'The password must contain capital letters.',
                special_character: 'The password must contain at least a special character.',
                length_8: 'The password must contain at least 8 characters.',
                number: 'The password must contain at least one number.',
            },
        },
        menu: {
            events: 'Events',
            language: 'English',
            logout: 'Logout',
            panel: 'Panel',
            pending_alarms: 'Pending Alarms',
            processing_alarms: 'Alarm Processing',
            vehicles_monitoring: 'Vehicles Monitoring',
            interventions: 'Interventions',
            analytics: 'Analytics',
            overview: 'Overview',
            period: 'Period',
            registration_shipments: 'Registration Shipments',
            help: 'Help',
            review_request: 'Event Review',
            analysis_requests: 'Analysis Requests',
            home: 'Home',
            smart_report: 'Smart Report',
        },
        general: {
            status: {
                active: 'Active',
                inactive: 'Inactive',
            },
            loading: 'Loading',
            recommended: 'Recommended',
            name: 'Name',
            input: 'Input',
            output: 'Output',
            expires_in: 'Expires in',
            default: 'Default',
            record_has_relationships: 'Policy(s) have user(s) linked, please unlink them and try again.',
            select: 'Select',
            export: 'Export',
            select_filters_to_view_the_map: 'Select filters to view the map',
            no_vehicle_selected: 'No vehicle selected',
            error_loading_data: 'Error loading data',
            unhandled_alarms: 'Unhandled alarms',
            admin: 'Admin',
            vehicle_monitoring: 'Vehicle monitoring',
            interventions: 'Interventions',
            analytics: 'Analytics',
            overview: 'Overview',
            unit: 'Unit',
            fleet: 'Fleet',
            driver: 'Driver',
            communication: 'Communication',
            support: 'Support',
            alarm_type: 'Alarm Type',
            alarms_high_risk: 'High Risk Alarms',
            alarms_processing: 'Alarms Processing',
            alarms_solved: 'Alarms Solved',
            alarms_total: 'Alarms Total',
            all: 'All',
            see_all: 'See All',
            apply: 'Apply',
            authentication: 'Authentication',
            cancel: 'Cancel',
            classification: 'Classificate',
            clear_filters: 'Clear filters',
            conclude: 'Conclude',
            configurations: 'Configurations',
            confirm: 'Confirm',
            dashboard: 'Dashboard',
            date: 'Date',
            description: 'Description',
            destination: 'Destination',
            electronic_fence: 'Electronic Fence',
            error: 'Error, try again.',
            events: 'Events',
            exclude_yourself: 'You cannot delete yourself.',
            filter: 'Filter',
            filters: 'Filters',
            final_hour: 'Final Hour',
            high_risk: 'High Risk',
            historic: 'Historic',
            information_unavailable: 'Information unavailable',
            initial_hour: 'Initial Hour',
            items_per_page: 'Items per page',
            live_camera_stream: 'Live camera stream',
            low_risk: 'Low Risk',
            main_menu: 'Main menu',
            medium_risk: 'Medium Risk',
            no_content: 'No Record',
            no_content_specific_alarm: 'You dont have any alarms yet',
            no_content_specific_event: 'You dont have any events yet',
            no_filter_vehicle: 'We didnt find any results for the applied filters',
            no_vehicle: 'No vehicles found',
            of: 'of',
            offline: 'Offline',
            on_the_page: 'You are on the page',
            online: 'Online',
            online_vehicles: 'Online Vehicles',
            origin: 'Origin',
            period: 'Period',
            playback_of_tracks: 'Track Playback',
            real_time_localization: 'Real time localization',
            reports: 'Reports',
            review_alarm: 'Review alarm',
            settings: 'Settings',
            sort_alarm: 'Sort alarm',
            review: 'Review',
            search: 'Search...',
            select_period: 'Select Period',
            selected_alarms: 'Selected Alarms',
            selection_of_settings: 'Selection of settings',
            seven_days: '7 days',
            show_events: 'Show Events',
            success: 'Success',
            test: 'Test',
            total_vehicles: 'Vehicles Total',
            twenty_four_hours: '24 hours',
            update: 'Update',
            update_data: 'Update Data',
            vehicle: 'Vehicle',
            vehicles: 'Vehicles',
            vehicles_events: 'Vehicles Events',
            view: 'View',
            write: 'Write',
            write_here: 'Write Here',
            activity: 'Activity',
            region: 'Region',
            country: 'Country',
            operation: 'Operation',
            last: 'Last',
            hours: 'Hours',
            notification_sound: 'Notification Sound',
            functionalities: 'Functionalities',
            operations: 'Operations',
            password_policy: 'Password policy',
            users: 'Users',
            speed_above: 'Speed above',
            message: {
                success: {
                    title: 'Success',
                    message: 'Request completed successfully.',
                },
                error: {
                    title: 'Failure',
                    message: 'There was a failure to process your request.',
                },
            },
            face_missing: 'Face Missing',
            yes: 'Yes',
            no: 'No',
            back_to_home: 'Back to home',
            intervention_protocol: 'Intervention protocol',
            registration_shipments: 'Registration shipments',
            event_redistribution: 'Event redistribution',
            select_the_event: 'Select the event',
            review_requests: 'Analysis Requests',
            unit_comparison: 'Unit Comparison',
            last_alarm: 'Last Alarm',
            fleets: 'Fleets',
            select_options: 'Select options',
            see_driver_name: `See the driver's name`,
        },
        alarms: {
            pending_analysis: 'Pending analysis',
            last_location: 'Last location',
            minimize: 'Minimize',
            see_all: 'See it all',
            alarm_time: 'Alarm time',
            event_type: 'Event type',
            GPS: 'GPS',
            alarm: 'Alarm',
            alarm_type: 'Alarm Type',
            arrival: 'Arrival',
            board: 'Board',
            break: 'Break',
            cam: 'Cam',
            capture: 'Capture',
            captures: 'Captures',
            device: 'Device',
            event: 'Event',
            extra_events: 'Extra Events',
            false_alarm: 'False Alarm',
            fleet: 'Fleet',
            historic_route: 'Historic Route',
            hour: 'Hour',
            information: 'Alarm Information',
            level: 'Alarm Level',
            looking_down: 'Looking Down',
            name: 'Alarm Name',
            number_of: 'Number of Captures',
            operation: 'Operation',
            previous_events: 'Previous Events',
            priority: 'Priority',
            priority_1: 'High',
            priority_2: 'Medium',
            priority_3: 'Low',
            real_time: 'Real Time Monitoring',
            select_alarm: 'Select Alarm',
            select_board: 'Select Board',
            select_destination: 'Destination Address',
            select_events: 'Select Events',
            select_operation: 'Select Operation',
            select_origin: 'Origin Address',
            select_vehicle: 'Select Vehicle',
            somnolence: 'Somnolence',
            sort_multiple_alarms: 'Sort Multiple Alarms',
            velocity: 'Velocity',
            select_redistribution: 'Redistribution of Alarms',
            label_select_redistribution: 'Redistribute my alarms (percentage)',
            placeholder_select_redistribution: 'Select percentage to redistribute',
            alarms_search_parameters: 'Alarms Search',
            label_alarms_search_parameters: 'Distribution of alarms',
            placeholder_select_alarms_search_parameters: 'Select distribution option',
            input_alarms_search_parameters: 'Search period (in hours) ',
            placeholder_alarms_search_parameters: 'Type quantity of hours to search',
            dynamic: 'Dynamic',
            open_alarms: 'Open Alarms',
            processed_alarms: 'Processed Alarms',
            activity_analyst: 'Analyst Activity',
            automatic_search: 'Automatic Search',
            automatic_search_tooltip: 'Execute automatic search for new alarms every 30s',
            analyst: 'Analyst',
            media_visualization: 'Media visualization',
            media_size: 'Media size (pixels)',
            placeholder_midias_size: 'Enter the amount of pixels to increase the media',
            options: 'Options',
            request_review: 'Request Review',
            review_event: 'Review Event',
            see_details: 'See Details',
        },
        review_request: {
            review_request: 'Event Review',
            analysis_requests: 'Analysis Requests',
            analysis_request: 'Analysis Request',
            request_number: 'Nº request',
            request_time: 'Request Date',
            fleet: 'Fleet',
            operation: 'Operation',
            event: 'Event',
            event_time: 'Date of the event',
            post_analysis: 'Post Analysis',
            justification: 'Justification',
            answer: 'Answer',
            status: 'Status',
            see: 'See',
            success: 'Analyzed',
            pending: 'Pending',
        },
        monitoring: {
            view_electronic_fence: 'View Electronic Fence',
            do_you_want_to_delete_the_electronic_fence: 'Do you want to delete the electronic fence',
            delete_electronic_fence: 'Delete electronic fence',
            change_zone_shape: 'Change zone shape',
            zone_color: 'Zone color',
            transparency: 'Transparency',
            expires_in: 'Expires in',
            generate_event_by: 'Generate event by',
            electronic_fence_name: 'Electronic fence name',
            edit_electronic_fence: 'Edit Electronic Fence',
            add_electronic_fence: 'Add Electronic Fence',
            add_fence: 'Add Fence',
            SIM: 'SIM Card Number',
            add_perimeter: 'Add Perimeter',
            altitude: 'Altitude',
            board_color: 'Board Color',
            board_number: 'Board Number',
            cam_stream: 'Cam Stream',
            cellphone: 'Contact Cellphone',
            cities: 'Cities and Provinces',
            conduction: 'Conduction Model',
            define_route: 'Define Route',
            defined_perimeters: 'Defined Perimeters',
            direction: 'Direction',
            driver_info: 'Driver Information',
            driver_name: 'Driver Name',
            eletronic_fence: 'Eletronic Fence',
            filiation: 'Filiation',
            identity: 'Identity Number',
            last_events: 'Last Events',
            last_register: 'Last Register',
            lat: 'Latitude',
            localization: 'Localization',
            long: 'Longitude',
            mark_perimeter: 'Mark Perimeters',
            organization: 'Controlling Organization',
            perimeter: 'Perimeter',
            position: 'Positioning',
            radius: 'Radius',
            real_time: 'Real Time Localization',
            select_perimeter: 'Select Perimeter',
            status: 'Status',
            track_playback: 'Track Playback',
            tracking: 'Tracking',
            vehicle_info: 'Vehicle Information',
            write_radius: 'Write Radius (km)',
            view: 'View',
            compass_points: {
                east: 'East',
                north: 'North',
                northeast: 'Northeast',
                northwest: 'Northwest',
                south: 'South',
                southeast: 'Southeast',
                southwest: 'Southwest',
                west: 'West',
            },
            event_expires_in: {
                never: 'Never',
                today: 'Today',
                tomorrow: 'Tomorrow',
                seven_days: '7 days',
                custom: 'Custom',
            },
        },
        password: {
            new_password_is_equal_to_old: 'A sua nova senha deve ser diferente da(s) antiga(s).',
            contains_at_least_one_lowercase_letter: 'Password must contain at least 1 lowercase letter',
            contains_at_least_one_number: 'Password must contain at least 1 number',
            contains_at_least_one_special_character: 'Password must contain at least one special character',
            contains_at_least_one_uppercase_letter: 'Password must contain at least one uppercase letter',
            is_expired: 'Password is expired',
        },
        overview: {
            proportion_events: 'Proportion',
            proportion_events_chart_subtitle: 'This graph represents the proportion of events in the selected period.',
            heat_map_events: 'Heat map',
            heat_map_events_chart_subtile: 'The dots on this map indicate the locations with the highest occurrence of events.',
            total_events: 'Total',
            total_events_chart_subtitle: 'This graph represents the total number of events in the selected period.',
            average_events: 'Average',
            average_events_chart_subtile: 'This graph represents the average number of vehicles in the selected period.',
            telemetry: 'Telemetry',
        },
        period: {
            time_range: 'Time range',
            day_week: 'Days of week',
            evento_per_day_hour: 'Events by day and time',
            hours_per_bar: 'Hours per bar',
            coordinates: 'Coordinates',
            polar: 'Polar',
            cartesian: 'Cartesian',
            options: 'Options',
            day: 'Day',
            week: 'Week',
            month: 'Month',
            year: 'Year',
            bar: 'Bar',
            line: 'Line',
            graph_type: 'Graph Type',
            cancel: 'Cancel',
            apply: 'Apply',
            worst_tracks_highlights: 'Worst tracks highlights',
            total_events: 'Total Events',
            at: 'at',
            scatterGraphSubTitle:
                'The graph points represent selected ' +
                'events during the hours and days of the week. The larger' +
                ' the dot, the more events, and the flashing dots represent' +
                ' the selected amount of bass tracks.',
            cartesianChart: 'The bands on this graph indicate the times with the highest occurrence of events.',
            barChartGraph: 'The bars in this graph indicate the days of the week with the highest occurrence of events.',
            telemetry: 'Telemetry',
        },
        interventions: {
            interventions_informations: 'Intervention Information',
            qtd_events: 'Number of events: ',
            hour_initial: 'Start time of events: ',
            hour_final: 'Final time of events: ',
            interventions_situation: 'Intervention Situation',
            media: 'Media',
            speed: 'Speed',
            hour: 'Hour',
            lat: 'Latitude',
            long: 'Longitude',
            close: 'Close',
            finalize: 'Finalize',
            informations: 'Informations',
            comments: 'Comments',
            type_comment: 'Type your comment',
            comment: 'Comment',
            event: 'Event',
            pending: 'Pending',
            in_negotiation: 'In negotiation',
            closed: 'Close',
            status: 'Status',
            all: 'All',
            'Em aberto': 'Opened',
            Pendente: 'Pending',
            'Em tratativa': 'In negotiation',
            Fechado: 'Close',
            'mudou para status': 'changed into status',
        },
        face_missing: {
            proportion: 'Proportion',
            fleet: 'Fleet',
            details: 'Details',
            details_table_subtitle: 'Select the desired format to download table information',
            face_missing: 'Face Missing',
            total_alarms: 'Total Alarms',
            percentage: 'Percentage',
            status: 'Status',
            proportion_graph_subtitle: 'This graph represents the proportion of events in the selected period.',
            fleet_graph_subtitle: 'The graph below shows the status of the DS device according to each fleet.',
            informative: 'Informative',
            about_face_missing: 'About Face Missing:',
            a_fleet_is_classified: 'A fleet is classified as displaced if at least 50% of the events in this fleet are face missing.',
        },
        registration_shipments: {
            registration_of_report_submissions: 'Registration of Report Submissions',
            operation: 'Operation',
            email: 'E-mail',
            shipping_type: 'Shipping Type',
            channel: 'Channel',
            priority: 'Priority',
            telephone: 'Telephone',
            add_chance: 'Add Chance',
            send_request: 'Send Request',
            contact: 'Contact',
            save: 'Save',
            cancel: 'Cancel',
            daily_report: 'Daily Report',
            delete_contact: 'Delete Contact',
            title_confirm_delete: 'Do you want to delete the contact “CONTACT NAME”?',
            confirm: 'Confirm',
            registration_shipments: 'Registration shipments',
            intervention: 'Intervention',
            contacts: 'Contacts',
            select_type: 'Select type',
        },
        fleets: {
            fleets: 'Fleets',
            ds_ranking: 'DS Ranking',
            fleet_ranking: 'Fleet Ranking',
            total: 'Total',
            time_range: 'Time Range',
            heat_map: 'Heat Map',
            events: 'Events',
            type: 'Type',
            bar: 'Bar',
            line: 'Line',
            day: 'Day',
            week: 'Week',
            month: 'Month',
            year: 'Year',
            select_the_event: 'Select the event',
            hours_per_bar: 'Hours per bar',
            period: 'Period',
            polar: 'Polar',
            cartesian: 'Cartesian',
            radio: 'Radio',
            blur: 'Blur',
            period_view: 'Period View',
            need_to_select_fleet: 'Select a fleet from the ranking above to view fleet details',
            fleet_ranking_subtile: 'This ranking represents the total events of all fleets.',
            fleet_total_subtile: 'This chart represents the total events of the selected fleet',
            time_range_subtile: 'The bands on this chart indicate the time periods with higher occurrence of events',
            heat_map_subtitle: 'The heatwaves on this chart indicate the areas with a higher occurrence of events',
        },
        events: {
            events: 'Events',
        },
        intervention_protocol: {
            intervention_protocol: 'Intervention Protocol',
            protocol: 'Protocol',
            group_whatsapp: 'Group Whatsapp',
            observation: 'Observation',
            enter_the_name_of_the_operation: 'enter the name of the operation',
            enter_protocol_name: 'enter protocol name',
            enter_group_name: 'enter group name',
            type_the_note: 'type the note',
            enter_event_name: 'enter event name',
            to_accept: 'To accept',
            cancel: 'Cancel',
            to_add: 'To add',
            add_protocol: 'Add Protocol',
            add_details: 'Add Details',
            delete_protocol: 'Delete Protocol',
            do_you_want_to_delete_the_protocol: 'do you want to delete "the protocol"',
            operation: 'Operation',
            protocol_details: 'Protocol Details',
            edit_protocol: 'Edit Protocol',
            save: 'Save',
            event: 'Event',
            amount: 'Amount',
            enter_the_amount: 'Enter the amount',
            time: 'Time',
            enter_the_time: 'Enter the time',
            wait: 'Wait (h)',
            enter_the_wait: 'Enter the wait',
            send_image: 'Send image',
            select_the_option: 'Select the option',
            send_video: 'Send vídeo',
            edit_details: 'Edit details',
            back: 'Back',
            advance: 'Advance',
            to_close: 'Close',
            want_to_delete_the_protocol_event_protocol_name: 'do you want to exclude the protocol event "PROTOCOL NAME"?',
            new_details: 'News Details',
            delete: 'Delete',
            this_action_cannot_be_undone: 'This action cannot be undone',
            select_the_event: 'Select the event',
            confirm: 'Confirm',
            view_protocol: 'View Protocol',
        },
        drivers: {
            drivers: 'Drivers',
            driver_history: 'Driver history',
            images: 'Images',
            id: 'Id',
            operation: 'Operation',
            fleet: 'Fleet',
            event: 'Event',
            day: 'Day',
            alarm_hour: 'Alarm Hour',
            latitude: 'Latitude',
            longitude: 'Longitue',
            id_cluster: 'Id Cluster',
            driver: 'Driver',
            document: 'Document',
            time_range: 'Time Range',
            heat_map: 'Heat Map',
            history_of_events: 'History of events',
            subtitle_history_of_events: 'The points on this map indicate the places where events occur most frequently.',
            subtitle_time_range: 'The bands on this graph indicate the times with the highest occurrence of events',
            subtitle_history_events: 'The data in the table shows the history of events.',
            driver_ranking: 'Driver Ranking',
            search_by_id_cluster: 'Search by id_cluster',
            search_by_image: 'Search by image',
            upload_image: 'Upload Image',
            click_on_a_line: 'Click on a row to view the history of the selected driver.',
            period: 'Period',
            week: 'Week',
            month: 'Month',
            year: 'Year',
            hours_per_bar: 'Hours per bar',
            coordinates: 'Coordinates',
            polar: 'Polar',
            cartesian: 'Cartesian',
            events: 'Events',
            select_the_event: 'Select the events',
            ray: 'Ray',
            blur: 'Blur',
            subtitle_driver_history: 'The data in this graph indicates the total number of driver events based on period.',
            need_to_select_driver: 'Select a driver from the rankings above to view the drivers details',
            photos_legends: 'Photos of event occurrences',
            file_text: 'No file selected.',
            search: 'Search',
            no_face_founded: 'No faces were detected in the image.',
            try_again: 'Try again',
            driver_founded_no_events: 'Face detected but no driver found.',
            similarity: 'Similarity',
            type: 'Type',
            bar: 'Bar',
            line: 'Line',
            heat_map_subtitle: 'The heatwaves on this chart indicate the areas with a higher occurrence of events',
        },
        last_alarm: {
            last_alarm: 'Last Alarm',
            operation: 'Operation',
            fleet: 'Fleet',
            last_communication: 'Last Communication',
            days_since_the_last_communication: 'Last communication (days)',
            days_since_the_last_alarm: 'Last alarm (days)',
            status: 'Status',
            proportion: 'Proportion',
            table: 'Table',
            subtitle_pie_chart: 'This graph represents the proportion of events in the selected period.',
            subtitle_bar_chart: 'This graph shows the communication status by operation and all fleets within it.',
            informative: 'Informative',
            a_fleet_is_classified_as: 'A fleet is classified as:',
            no_communication: 'No communication if there has been no communication for over 6 days;',
            communicating_without_event: 'Reporting no event if it has reported in the last 6 days but has not presented any alarm in that period;',
            if_you_are_communicating: 'OK! if you have been communicating and having alarms in the last 6 days.',
        },
        comparison_of_units: {
            comparison_of_units: 'Comparison of units',
            country: 'Country',
            week: 'Week',
            yawn: 'Yawn',
            drowsinessN1: 'Drowsiness N1',
            drowsinessN2: 'Drowsiness N2',
            cellPhone: 'Cell phone',
            smoking: 'Smoking',
            occlusion: 'Occlusion',
            lookingDownN1: 'looking down N1',
            lookingDownN2: 'looking down N2',
            drowsinessIndex: 'Drowsiness index',
            distractionIndex: 'Distraction index',
            generalIndex: 'General index',
            telemetryIndex: 'Telemetry Index',
            averages_per_period: 'Averages per period',
            comparison: 'Comparison',
            the_vehicles_column_is_the_maximum: 'The <strong>Vehicles</strong> column is the maximum number of vehicles that ran each day.',
            each_event_column_contains_the_total_number:
                'Each column of events contains the total events of the period divided by the number of Vehicles obtained above, in turn divided by the number of active days.',
            we_have_the_following_formulas: 'We have the following formulas:',
            fatigue_index: 'Fatigue index = 10 * Drowsiness N1 + 20 * Drowsiness N2',
            distraction_index:
                'Distraction index = 15 * Cell phone + 5 * Smoking + 15 * Occlusion + 1 * Looking down N1 + 2 * Looking down N2 + 15 * Headphone',
            indice_argus: 'Argus Index = Fatigue Index + Distraction Index',
            period_vision: 'Period Vision',
            unity_vision: 'Unity Vision',
            day: 'Day',
            month: 'Month',
            year: 'Year',
            period: 'Period',
            operation: 'Operation',
            region: 'Region',
            column: 'Column',
            select_the_event: 'Select the event',
            informative: 'Informative',
        },
        tableEventName: {
            Sonolência: 'Drowsiness',
            'Sonolência N1': 'Drowsiness N1',
            'Sonolência N2': 'Drowsiness N2',
            'Sonolência leve': 'Mild drowsiness',
            'Olhando para baixo N1': 'Looking down N1',
            'Olhando para baixo N2': 'Looking down N2',
            Celular: 'Cell phone',
            'Sem cinto': 'No belt',
            Fumando: 'Smoking',
            Oclusão: 'Occlusion',
            Bocejo: 'Yawn',
            'Face missing': 'Face missing',
            'Óculos de sol': 'Sunglasses',
            'Consumo alimento': 'Food consumption',
            'Fone de ouvido': 'Earphone',
            'Colisão frontal': 'Front Collision Warning (FCW)',
            'Alarme antecipado de colisão': 'Front Collision Warning (FCW)',
            'Colisão de pedestres': 'Pedestrian Collision Warning',
            'Alarme de colisão de passageiros': 'Pedestrian Collision Warning',
            'Excesso de velocidade da via': 'Road Sign Over-Limit',
            'Alarme de excesso de limite de sinal rodoviário': 'Road Sign Over-Limit',
            'Colisão para frente de baixa velocidade': 'Urban Front Collision Warning (UFCW)',
            'Curva rápida': 'Fast curve',
            'Curva rápida à direita': 'Fast curve',
            'Curva rápida à esquerda': 'Fast curve',
            'Veículo muito próximo': 'Vehicle too close',
            'Distância de veículos muito próxima': 'Vehicle too close',
            'Excesso de velocidade na faixa de pedestre': 'Excessive crosswalk speed',
            'Excesso de velocidade na listra de zebra': 'Excessive crosswalk speed',
            'Mudança irregular de faixa': 'Irregular lane change',
            'Linha de pressão direita da pista': 'Irregular lane change',
            'Linha de pressão esquerda da pista': 'Irregular lane change',
            'Ponto cego': 'Blind spot',
            Velocidade: 'Speeding',
            'Condução perigosa N1': 'Dangerous driving N1',
            'Condução perigosa N2': 'Dangerous driving N2',
            'DS deslocado': 'DS dislocated',
            'Dano ao DS': 'DS damage',
            'Cinto irregular': 'Uneven belt',
            Rádio: 'Radio',
            'Falso alarme': 'False alarm',
            'Tentativa de Oclusão': 'Attempted Occlusion',
            'Oclusão parcial': 'Partial occlusion',
            'Passageiro irregular': 'Irregular passenger',
            Teste: 'Test',
            '?': '?',
            'Óculos de sol à noite': 'Sunglasses at night',
            Infração: 'Violation',
            Distração: 'Distraction',
            Fadiga: 'Fatigue',
            'Excesso de Velocidade': 'Overspeeding',
            'Direção perigosa': 'Dangerous driving',
            'Direção agressiva': 'Aggressive driving',
        },
        hours: {
            in: 'in',
            ago: 'ago',
            secondsAgo: 'a few seconds',
            seconds: 'seconds',
            minute: 'minutes',
            aMinute: 'a minute',
            hour: 'hour',
            hours: 'hours',
            oneDay: 'a day',
            days: 'days',
            oneWeek: 'a week',
            weeks: 'weeks',
            monthAgo: 'month ago',
            months: 'months',
            aYear: 'a years',
            years: 'years',
        },
        home: {
            events: 'Events',
            updated_at: 'Updated at',
            interventions: 'Interventions',
            drowsiness: 'Drowsiness',
            vehicles: 'Vehicles',
            interventions_last_hour: 'Interventions in the last hour',
            interventions_last_hour_subtitle: 'Each point represents the evolution of the number of events in the last hour.',
            event_distribution: 'Event distribution',
            event_distribution_subtitle: 'Each section indicates the percentage of events for each category in the selected period.',
            violation: 'Violation',
            fatigue: 'Fatigue',
            distraction: 'Distraction',
            speeding: 'Speeding',
            dangerous_driving: 'Dangerous driving',
            aggressive_driving: 'Aggressive driving',
            recent_interventions: 'Recent interventions',
            recent_interventions_subtitle:
                'The red markers on the map indicate the location of the interventions that occurred in the selected period. Clicking on them displays the video and related information about the intervention.',
        },
        echarts: {
            group: 'Group',
            total: 'Total',
        },
    },
};

export default EN_US;
