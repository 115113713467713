import { useTranslation } from 'react-i18next';
import { Skeleton } from 'src/common';

import { ComparisonOfUnitsData } from '../../dtos/comparison-of-units-dtos';
import { useComparisonOfUnits } from '../../hook';
import { ButtonChangeContainer, ButtonTab, IndexFilter } from './styles';

export default function SelectIndexButton({tab, changeTab}: {tab: number, changeTab: (newValue: number) => void}) {
    const { t } = useTranslation();

    const { comparisonOfUnits } = useComparisonOfUnits();

    const comparisonOfUnitsData = comparisonOfUnits?.elements[0];

    return (
        <IndexFilter>
            <ButtonChangeContainer>
                {comparisonOfUnitsData
                    ? Object.keys(comparisonOfUnitsData as unknown as ComparisonOfUnitsData).map((key, index) => {
                        return (
                            <ButtonTab
                                key={index}
                                onClick={() => changeTab(index)}
                                style={{ backgroundColor: tab === index ? '#262D3C' : '#E6E6E6', color: tab === index ? '#ffffff' : '#000000' }}
                            >
                                {t('comparison_of_units.' + key)}
                            </ButtonTab>
                        );
                    }) : <Skeleton />}
        </ButtonChangeContainer>
        </IndexFilter>
    );
}
