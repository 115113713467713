import { Table } from 'src/common/components';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
    min-width: 768px;

    td,
    th {
        text-align: left !important;
    }

    th {
        padding-bottom: 0;
    }

    .pointer {
        cursor: pointer;
    }
`;

export const Tag = styled.div`
    cursor: default;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    gap: 10px;

    height: 24px;
    width: min-content;

    background: #ffebe9;

    border: 1px solid #ef1d00;
    border-radius: 8px;

    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.02em;

    color: #ef1d00;
`;
