import styled from 'styled-components';

import Chip from '../../chip';
import Column from '../../column';

export const Container = styled(Column)`
    position: relative;
    gap: 8px;
`;

export const StyledChip = styled(Chip)`
    background: #eaf6ff;

    &:hover {
        background-color: #eaf6ff;
    }

    button {
        background: #eaf6ff;
    }
`;
