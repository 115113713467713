import { SVGProps } from 'react';

export default function GroupShare(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.046 13.604a2.362 2.362 0 102.362 4.09 2.362 2.362 0 00-2.362-4.09h0zM17.954 13.604a2.362 2.362 0 11-2.362 4.091 2.362 2.362 0 012.362-4.091h0zM12.361 4.287a2.362 2.362 0 11.001 4.725 2.362 2.362 0 010-4.725h0z"
                stroke="#fff"
                strokeWidth={2}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.048 8.097a2.361 2.361 0 01.487-2.286 7.3 7.3 0 00-5.252 9.096 2.36 2.36 0 011.736-1.565 5.3 5.3 0 013.029-5.245zm4.502 0a5.3 5.3 0 013.03 5.245 2.362 2.362 0 011.735 1.566 7.296 7.296 0 00-5.252-9.097 2.363 2.363 0 01.487 2.286zm3.001 9.865a2.363 2.363 0 01-2.223-.72 5.297 5.297 0 01-6.058 0 2.363 2.363 0 01-2.223.72l.09.093a7.299 7.299 0 0010.414-.093z"
                fill="#fff"
            />
        </svg>
    );
}
