import { SVGProps } from 'react';

function ListClipboard({ color, ...props }: SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={30} viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.688 12.492h10.335a.74.74 0 00.739-.726.75.75 0 00-.739-.739H4.688a.75.75 0 00-.739.739c0 .398.34.726.739.726zm0 4.16h10.335c.41 0 .739-.328.739-.738a.723.723 0 00-.739-.715H4.688a.723.723 0 00-.739.715c0 .41.328.738.739.738zm0 4.383H9.89a.73.73 0 00.726-.726.723.723 0 00-.726-.727H4.687a.733.733 0 00-.738.727c0 .398.34.726.739.726zm-1.055 7.36h12.445c2.426 0 3.633-1.22 3.633-3.68V6.949c0-2.402-1.16-3.68-3.504-3.68h-.281c.011.094.011.2.011.293v1.055c0 .188-.011.375-.046.54h.28c1.102 0 1.653.714 1.653 1.816V24.69c0 1.172-.62 1.817-1.84 1.817H3.727c-1.22 0-1.84-.645-1.84-1.817V6.973c0-1.102.55-1.817 1.652-1.817h.281a2.64 2.64 0 01-.047-.539V3.563c0-.094 0-.2.012-.293h-.281C1.16 3.27 0 4.547 0 6.95v17.765c0 2.46 1.207 3.68 3.633 3.68zM6.234 5.953h7.243c.68 0 1.09-.433 1.09-1.16v-1.23c0-.727-.41-1.16-1.09-1.16h-1.079C12.316 1.077 11.215 0 9.855 0c-1.359 0-2.46 1.078-2.543 2.402H6.234c-.68 0-1.09.434-1.09 1.16v1.231c0 .727.41 1.16 1.09 1.16zm3.621-2.437c-.562 0-1.02-.47-1.02-1.02 0-.574.458-1.031 1.02-1.031.563 0 1.02.457 1.02 1.031 0 .55-.457 1.02-1.02 1.02z"
                fill={color}
            />
        </svg>
    );
}

export default ListClipboard;
