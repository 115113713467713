import { differenceInBusinessDays } from 'date-fns';
import { hourRegex } from 'src/common/helpers';
import { yup } from 'src/common/infra/validations';

export const historicRouteSchema = yup.object().shape({
    operation: yup.string().optional(),
    fleet: yup.string().required('field_validations.required'),
    finalDate: yup
        .date()
        .typeError('field_validations.required')
        .required('field_validations.required')
        .test('days-diff', 'Invalid days difference, maximum range: 7 days.', (field, body) => {
            if (!field) return false;

            const { parent } = body;

            return Math.abs(differenceInBusinessDays(field, parent.initialDate)) <= 7;
        }),
    initialDate: yup.date().typeError('field_validations.required').required('field_validations.required'),
    startTime: yup
        .string()
        .optional()
        .test('is-valid-hour', 'field_validations.hour', field => {
            if (!field) return true;
            const match = field.match(hourRegex);

            if (!match) return false;

            return match?.length > 0;
        }),
    endTime: yup
        .string()
        .optional()

        .test('is-valid-hour', 'field_validations.hour', field => {
            if (!field) return true;
            const match = field.match(hourRegex);

            if (!match) return false;

            return match?.length > 0;
        }),
});
