import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, subDays } from 'date-fns';
import { ClipboardEvent, FocusEvent, FormEvent, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Column, DatePicker, Input } from 'src/common/components';
import { TypeSearch } from 'src/common/constants';
import { HourHelper } from 'src/common/helpers';
import { FeedbackProps } from 'src/common/types';
import { DepartmentResume, departmentService } from 'src/modules/configurations/departments';
import { vehicleService } from 'src/modules/configurations/vehicles/services/vehicle.service';

import { Vehicle } from '../../domain/entities/vehicle';
import { GetHistoricalRouteDTO } from '../../dtos/get-historical-route.dto';
import { useHistoricalRoute } from '../../hooks';
import { historicRouteSchema } from '../../infra/validations/yup/historic-route.schema';
import { Form } from './styles';

export default function HistoricalRouteFilter() {
    const { filter, operations, vehicles, setFilter, setOperations, setVehicles } = useHistoricalRoute();
    const { t } = useTranslation();

    const {
        setValue,
        register,
        handleSubmit: onSubmit,
        watch,
        formState: { errors },
    } = useForm<GetHistoricalRouteDTO>({
        defaultValues: filter,
        mode: 'onChange',
        resolver: yupResolver(historicRouteSchema),
    });

    const handleChangeDatesRaw = useCallback((event: FocusEvent<HTMLInputElement, Element>) => {
        event.preventDefault();
    }, []);

    const handleInputHour = useCallback((e: FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;

        const newValue = HourHelper.mask(value?.substring(0, 5));

        e.currentTarget.value = newValue;
    }, []);

    const handlePreventPaste = useCallback((event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
    }, []);

    const handleChangeDates = useCallback(
        (dates: [Date, Date]) => {
            setValue('initialDate', dates[0]);
            setValue('finalDate', dates[1]);
        },
        [setValue],
    );

    const handleSubmit = useCallback(
        (data: GetHistoricalRouteDTO) => {
            setFilter({ ...data });
        },
        [setFilter],
    );

    const startDate: Date = watch('initialDate');
    const endDate: Date = watch('finalDate');
    const maxDate = useMemo(() => (startDate ? addDays(new Date(startDate), 7) : new Date()), [startDate]);
    const minDate = useMemo(() => (endDate ? subDays(new Date(endDate), 7) : undefined), [endDate]);

    const feedback = (key: keyof GetHistoricalRouteDTO): FeedbackProps => {
        return {
            message: t(errors[key]?.message || '') as string,
        };
    };

    const fleet = watch('fleet');
    const operation = watch('operation');

    useEffect(() => {
        const controller = new AbortController();

        setOperations({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            departmentService
                .listDepartments({
                    controller,
                    name: operation,
                    currentPage: 1,
                    pageSize: 15,
                    active: true,
                })
                .then(res =>
                    setOperations({
                        type: 'data',
                        payload: res.elements as Array<DepartmentResume>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [operation, setOperations, setValue]);

    useEffect(() => {
        const controller = new AbortController();

        setVehicles({
            type: 'loading',
        });

        const timer = setTimeout(() => {
            vehicleService
                .list({
                    currentPage: 1,
                    pageSize: 15,
                    fleet,
                    typeSearch: TypeSearch.PARAMS,
                    operation,
                    signal: controller.signal,
                })
                .then(res =>
                    setVehicles({
                        type: 'data',
                        payload: res.elements as Array<Vehicle>,
                    }),
                );
        }, 1000);

        return () => {
            clearTimeout(timer);
            controller.abort();
        };
    }, [fleet, operation, setValue, setVehicles]);

    return (
        <Form onSubmit={onSubmit(handleSubmit)}>
            <Autocomplete
                label={String(t('alarms.operation'))}
                nextElement={{
                    id: 'fleet',
                }}
                feedback={feedback('operation')}
                isLoading={operations.isLoading}
                options={operations.data.map(operation => ({ ...operation, value: operation.name }))}
                placeholder={String(t('alarms.operation'))}
                setValue={setValue}
                type="text"
                {...register('operation')}
            />
            <Autocomplete
                label={String(t('alarms.fleet'))}
                isLoading={vehicles.isLoading}
                options={vehicles.data.map(vehicle => ({
                    id: vehicle.id,
                    value: vehicle.fleet,
                }))}
                nextElement={{
                    id: 'period',
                }}
                feedback={feedback('fleet')}
                placeholder={String(t('alarms.fleet'))}
                setValue={setValue}
                type="text"
                {...register('fleet')}
            />
            <DatePicker
                endDate={endDate}
                feedback={feedback('finalDate')}
                label={t('general.date')?.toString()}
                maxDate={maxDate}
                minDate={minDate}
                multiple
                name="period"
                onChange={handleChangeDates}
                onChangeRaw={handleChangeDatesRaw}
                placeholder={String(t('general.select_period'))}
                startDate={startDate}
            />
            <Input
                autoComplete="off"
                feedback={feedback('startTime')}
                label={String(t('general.initial_hour'))}
                maxLength={5}
                onInput={handleInputHour}
                onPaste={handlePreventPaste}
                placeholder={String(t('general.initial_hour'))}
                type="text"
                {...register('startTime')}
            />
            <Input
                autoComplete="off"
                feedback={feedback('endTime')}
                label={String(t('general.final_hour'))}
                maxLength={5}
                onInput={handleInputHour}
                onPaste={handlePreventPaste}
                placeholder={String(t('general.final_hour'))}
                type="text"
                {...register('endTime')}
            />
            <Column justify="flex-end" height="60px">
                <Column justify="flex-end" height="36px">
                    <Button type="submit" size="small">
                        {t('general.apply')}
                    </Button>
                </Column>
            </Column>
        </Form>
    );
}
