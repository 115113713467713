import { useMutation } from '@tanstack/react-query';

import { vehicleService } from '../services/vehicle.service';
import { useVehicles } from './use-vehicles';

export function useListVehicles() {
    const { setVehicles } = useVehicles();

    return useMutation({
        mutationFn: vehicleService.list,
        mutationKey: ['list-vehicles'],
        onSuccess(data) {
            setVehicles(data);
        },
    });
}
