import { ForwardRefRenderFunction, ForwardedRef, SelectHTMLAttributes, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Label = styled.label`
    font-family: 'Open Sans', 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
    gap: 8px;
`;

const InputNumber = styled.select`
    background-color: #f7f7f7;
    border: 0;
    border-bottom: 1px solid #ccc;
    width: max-content;
    outline: pink;
    border-radius: 4px;

    font-family: 'Open Sans', 'Space Grotesk', sans-serif;
    font-size: 12px;
    line-height: 16px;
`;

const Input: ForwardRefRenderFunction<HTMLSelectElement, SelectHTMLAttributes<HTMLSelectElement>> = (
    props: SelectHTMLAttributes<HTMLSelectElement>,
    ref: ForwardedRef<HTMLSelectElement>,
): JSX.Element => {
    const { t } = useTranslation();

    const [pre, post] = t('configurations.current_password_must_be_different_from_the_last_X_passwords').split('X');

    return (
        <Label htmlFor={props?.id}>
            {pre}
            <InputNumber {...props} ref={ref}>
                <option value="" hidden></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </InputNumber>
            {post}
        </Label>
    );
};

const TextInput = forwardRef<HTMLSelectElement, SelectHTMLAttributes<HTMLSelectElement>>(Input);

export { TextInput };
