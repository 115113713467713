import { FunctionComponent } from 'src/common/types/react';

import { FlexBox, FlexBoxProps } from '../flex-box';

type RowProps = Omit<FlexBoxProps, 'direction'> & FunctionComponent;

export default function Row({ children, ...props }: RowProps) {
    return (
        <FlexBox direction="row" {...props}>
            {children}
        </FlexBox>
    );
}
