import styled from 'styled-components';

export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr); 
    gap: 16px;
    box-sizing: border-box;
    height: min-content;
    width: 100%;
    justify-content: center;
    padding: 0 8px;

    @media (max-width: 1440px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
