import { ApiResponse, TransformRequestHelper } from 'src/common';
import { Pagination } from 'src/common/core/pagination';
import { SignalDTO, api } from 'src/common/infra';
import { Vehicle } from 'src/modules/vehicle-monitoring/domain/entities/vehicle';

import { CreateVehicleDTO } from '../dtos';
import { ListVehiclesDTO } from '../dtos/list-vehicles.dto';

export class VehicleService {
    public async create(request: CreateVehicleDTO): Promise<void> {
        const { fleet, operationId, vehicleType, observation } = request;

        await api.post('/vehicles', {
            fleet: fleet.trim(),
            observation,
            operationId,
            vehicleType,
        });
    }

    public async delete(id: number): Promise<void> {
        await api.delete(`/vehicles/${id}`);
    }

    public async list(request: ListVehiclesDTO & SignalDTO): Promise<Pagination<Vehicle>> {
        const { pageSize, currentPage, fleet, operation, type, typeSearch, text, signal } = request;

        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<Vehicle>>>('/vehicles', {
            signal,
            params: {
                fleet,
                operation,
                size: pageSize,
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
                text,
                type,
                typeSearch,
            },
        });

        return content;
    }

    public async update(request: CreateVehicleDTO): Promise<void> {
        const { fleet, operationId, vehicleType, observation, id } = request;

        if (!id) return;

        await api.put(`/vehicles/${id}`, {
            fleet: fleet.trim(),
            observation,
            operationId,
            vehicleType,
        });
    }
}

export const vehicleService = new VehicleService();
