export const alarms = [
    {
        name: 'Condução perigosa N2',
        color: '#DC3545',
    },
    {
        name: 'Condução perigosa N1',
        color: '#FD7E14',
    },
    {
        name: 'Celular',
        color: '#6F42C1',
    },
    {
        name: 'Bocejo',
        color: '#FFC107',
    },
    {
        name: 'Face missing',
        color: '#CFCFCF',
    },
    {
        name: 'Sem cinto',
        color: '#8BE08F',
    },
    {
        name: 'Fumando',
        color: '#635a3b',
    },
    {
        name: 'Óculos de sol',
        color: '#D7F9CA',
    },
    {
        name: 'Oclusão',
        color: '#000000',
    },
];
