import styled from 'styled-components';

import Row from '../row';

export const Container = styled(Row)`
    padding: 0 16px;
`;

export const ClearFilters = styled.button`
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    border: 0;
    padding: 0;

    display: flex;
    align-items: center;

    color: #a9a9a9;
    text-decoration-line: underline;

    background-color: transparent;
    cursor: pointer;

    &:hover {
        color: #6da4d3;
    }
`;

export const Title = styled.h5`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0;

    display: flex;
    align-items: center;

    color: #262d3c;

    cursor: default;
`;
