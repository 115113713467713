import { ApiResponse, TransformRequestHelper, api } from '../../../../common';
import { LastAlarmRequestDTO } from '../dtos/last-alarm-dtos';

export class LastAlarmService {
    public async getData({ dateFrom, dateTo, operations }: LastAlarmRequestDTO): Promise<any> {
        const {
            data: { content },
        } = await api.get<ApiResponse>('analytics/last_communication', {
            params: { dateFrom, dateTo, operations },
        });
        return content;
    }

    public async getDetails({ dateFrom, dateTo, currentPage, pageSize, operations }: LastAlarmRequestDTO): Promise<any> {
        const {
            data: { content },
        } = await api.get<ApiResponse>('analytics/last_communication/paginated', {
            params: { dateFrom, dateTo, start: TransformRequestHelper.currentPageToStart(currentPage, pageSize), size: pageSize, operations },
        });
        return content;
    }
}

export const lastAlarmService = new LastAlarmService();
