import styled from 'styled-components';

export const SupportingText = styled.p`
    color: #4d4d4d;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    cursor: default;
    text-align: center;
    max-width: 302px;
`;

export const Title = styled.h4`
    color: #46526c;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    cursor: default;
`;
