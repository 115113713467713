import calendar from 'src/assets/calendar-inactive.svg';
import styled from 'styled-components';

interface TextareaProps {
    minHeight?: string;
    width?: string;
}

export const StyledInput = styled.input`
    align-items: flex-start;
    align-self: stretch;
    border-image-source: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(0deg, #c2c9d1, #c2c9d1);
    border-radius: 8px;
    border: 1px solid #c2c9d1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 0 8px;
    height: 36px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    outline: 0;

    &[hidden] {
        display: none;
    }

    &:read-only {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #c2c9d1;
        color: #000000;
    }

    &:disabled {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #c2c9d1;
        color: rgba(0, 0, 0, 0.5);
    }

    &:enabled:focus,
    &:enabled:focus-within,
    &:enabled:hover {
        border-color: #3e66fb;
    }

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }

    &:focus::placeholder {
        color: rgba(0, 0, 0, 0.25);
    }

    &[type='date'] {
        flex-direction: row-reverse;
        align-items: center;
        font-family: 'Open Sans', sans-serif;

        &::-webkit-calendar-picker-indicator {
            cursor: pointer;
            background-image: url(${calendar});
            width: 20px;
            height: 20px;
        }

        &:disabled::-webkit-calendar-picker-indicator {
            display: none;
        }
    }
`;

export const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    flex-wrap: wrap;
    width: 100%;

    strong {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
    }

    small {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: right;
        color: rgba(0, 0, 0, 0.5);
    }
`;

export const StyledSelect = styled.select`
    background: #ffffff;

    align-items: center;
    border-image-source: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(0deg, #c2c9d1, #c2c9d1);
    border-radius: 8px;
    border: 1px solid #c2c9d1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 8px;
    height: 36px;
    width: 100%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    outline: 0;

    &:invalid {
        color: red;
    }

    &:disabled {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #c2c9d1;
        color: rgba(0, 0, 0, 0.5);
    }

    &:enabled:focus,
    &:enabled:focus-within,
    &:enabled:hover {
        border-color: #3e66fb;
    }

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }

    &:focus::placeholder {
        color: rgba(0, 0, 0, 0.25);
    }
`;

export const StyledTextarea = styled.textarea<TextareaProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 8px;
    gap: 10px;

    flex: 1;

    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #c2c9d1;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    outline: 0;

    min-height: ${props => props?.minHeight || '80px'};
    resize: vertical;
    min-width: ${props => props?.width};
    width: 100%;
    margin: 0;

    &:invalid {
        color: red;
    }

    &:disabled {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #c2c9d1;
        color: rgba(0, 0, 0, 0.5);
    }

    &:enabled:focus,
    &:enabled:focus-within,
    &:enabled:hover {
        border-color: #3e66fb;
    }

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }

    &:focus::placeholder {
        color: rgba(0, 0, 0, 0.25);
    }
`;
