import { useEffect, useRef } from 'react';

type EventMap = HTMLElementEventMap | DocumentEventMap | WindowEventMap;

export function useEventListener<T extends HTMLElement | Document | Window | null, K extends keyof EventMap, EventType = any>(
    element: T,
    eventName: K,
    handler: (this: T, ev: EventType) => any,
) {
    const savedHandler: any = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) {
            return;
        }

        const eventListener: EventListenerOrEventListenerObject = event => savedHandler.current(event);

        element.addEventListener(eventName, eventListener);
        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
}
