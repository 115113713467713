import { SignalDTO, api } from 'src/common';
import { Service } from 'src/common/core';

import { CreateElectronicFenceFormDTO } from '../dtos/create-electronic-fence.dto';
import { ElectronicFenceMapper } from '../mappers/electronic-fence.mapper';

type Request = CreateElectronicFenceFormDTO & SignalDTO & { id: number };

export class UpdateElectronicFenceService implements Service<Request, void> {
    public async execute(request: Request): Promise<void> {
        const { signal, id } = request;

        const data = ElectronicFenceMapper.fromFormToDTO(request);

        await api.put(`/vehicle-monitoring/electronic-fences/${id}`, data, {
            signal,
        });
    }
}

export const updateElectronicFence = new UpdateElectronicFenceService();
