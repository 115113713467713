import styled from 'styled-components';

import Button from '../button';

export const CancelButton = styled(Button).attrs(() => ({
    type: 'button',
    color: 'outline',
    id: 'cancel-btn',
}))`
    &#cancel-btn {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.2px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #7b7b7b;
        background: #d7d7d7;
        border: 1px solid #a9a9a9;

        &:hover {
            background-color: #f5f5f5;
        }
    }
`;
