import { useMemo } from 'react';
import { FeedbackProps, FeedbackType } from 'src/common/types/feedback-component';

import { CheckCircle, Warning } from '../icons';
import Row from '../row';
import { Message } from './styles';

interface FeedbackComponentProps extends FeedbackProps {
    maxLength?: number;
    replace?: string;
}

export default function Feedback({ message, type = FeedbackType.ERROR, maxLength = 191, replace = '%%' }: FeedbackComponentProps) {
    const icon = useMemo(() => {
        switch (type) {
            case FeedbackType.ERROR:
                return <Warning color="#F03738" />;
            case FeedbackType.SUCCESS:
                return <CheckCircle color="#3CC13B" />;
            case FeedbackType.WARN:
                return <Warning color="#F3BB1C" />;
            default:
                return null;
        }
    }, [type]);

    return (
        <Row
            gap={8}
            align="flex-start"
            style={{
                flexWrap: 'nowrap',
            }}
        >
            {icon}
            {message?.trim() && <Message>{message?.trim()?.replace(replace, maxLength?.toString())}</Message>}
        </Row>
    );
}
