import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TypeSearch, useSearch } from 'src/common';
import { Button, Column, Input } from 'src/common/components';

import { ListUsersDTO } from '../../../../../modules/configurations';
import { useUsers } from '../../../../../modules/configurations/users/hooks/use-users';
import { Form } from './styles';

export default function UsersFilters() {
    const { clear } = useSearch();
    const { t } = useTranslation();
    const { requestData, handleRequestList, handleSelectAll } = useUsers();

    const { register, handleSubmit } = useForm<ListUsersDTO>({
        defaultValues: requestData,
    });

    const onSubmit = useCallback(
        (data: Partial<ListUsersDTO>) => {
            delete data?.pageSize;
            delete data?.enabled;

            clear();
            handleSelectAll([]);

            handleRequestList({ ...data, text: '', typeSearch: TypeSearch.PARAMS, currentPage: 1 });
        },
        [clear, handleRequestList, handleSelectAll],
    );

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
                autoComplete="off"
                label={String(t('configurations.name_user'))}
                id="filter-username"
                placeholder={String(t('general.write'))}
                type="text"
                {...register('name')}
            />
            <Input
                autoComplete="off"
                label={String(t('configurations.email'))}
                id="filter-email"
                placeholder={String(t('general.write'))}
                type="email"
                {...register('email')}
            />
            <Input
                autoComplete="off"
                label={String(t('configurations.function'))}
                id="filter-function"
                placeholder={String(t('general.write'))}
                type="text"
                {...register('function')}
            />
            <Input
                autoComplete="off"
                label={String(t('configurations.creator'))}
                id="filter-creator"
                placeholder={String(t('general.write'))}
                type="text"
                {...register('creator')}
            />
            <Column justify="flex-end" height="60px">
                <Column justify="flex-end" height="36px">
                    <Button type="submit" size="small" width="144px">
                        {t('general.apply')}
                    </Button>
                </Column>
            </Column>
        </Form>
    );
}
