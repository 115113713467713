import { api, ApiResponse } from 'src/common';

import { Role } from '../dtos';

export class RoleService {
    public async list(): Promise<Role[]> {
        const {
            data: { content },
        } = await api.get<ApiResponse<Role[]>>('/functions/roles');

        return content;
    }
}

export const roleService = new RoleService();
