export const events = [
    {
        name: 'Sonolência N2',
        color: '#DC3545',
        type: 'DS',
    },
    {
        name: 'Sonolência N1',
        color: '#FD7E14',
        type: 'DS',
    },
    {
        name: 'Bocejo',
        color: '#FFDA79',
        type: 'DS',
    },
    {
        name: 'Olhando para baixo N2',
        color: '#003f5c',
        type: 'DS',
    },
    {
        name: 'Olhando para baixo N1',
        color: '#2166AC',
        type: 'DS',
    },
    {
        name: 'Celular',
        color: '#6F42C1',
        type: 'DS',
    },
    {
        name: 'Sem cinto',
        color: '#8BE08F',
        type: 'DS',
    },
    {
        name: 'Fumando',
        color: '#494229',
        type: 'DS',
    },
    {
        name: 'Fone de ouvido',
        color: '#bc5090',
        type: 'DS',
    },
    {
        name: 'Consumo alimento',
        color: '#C59DD7',
        type: 'DS',
    },
    {
        name: 'Face missing',
        color: '#CFCFCF',
        type: 'DS',
    },
    {
        name: 'Oclusão',
        color: '#0D0D0D',
        type: 'DS',
    },
    {
        name: 'Óculos de sol',
        color: '#D7F9CA',
        type: 'DS',
    },
    {
        name: 'Colisão frontal',
        color: '#8C510A',
        type: 'FW',
    },
    {
        name: 'Colisão de pedestres',
        color: '#BF812D',
        type: 'FW',
    },
    {
        name: 'Excesso de velocidade da via',
        color: '#7B68A8',
        type: 'FW',
    },
    {
        name: 'Colisão frontal de baixa velocidade',
        color: '#DFC27D',
        type: 'FW',
    },
    {
        name: 'Curva rápida',
        color: '#004529',
        type: 'FW',
    },
    {
        name: 'Veículo muito próximo',
        color: '#F6E8C3',
        type: 'FW',
    },
    {
        name: 'Excesso de velocidade na faixa de pedestre',
        color: '#C8C6DA',
        type: 'FW',
    },
    {
        name: 'Mudança irregular de faixa',
        color: '#6E897C',
        type: 'FW',
    },
    {
        name: 'Ponto cego',
        color: '#FC728B',
        type: 'FW',
    },
    {
        name: 'Excesso de velocidade',
        color: '#3F007D',
        type: 'FW',
    },
];
