import { ButtonHTMLAttributes, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BsHeadset } from 'react-icons/bs';
import i18n from 'src/common/infra/translations';

import { StyledSupport } from './styles';

export default function SupportButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
    const { t } = useTranslation();

    const selectedLanguage = useCallback(() => {
        switch(i18n.language){
            case 'es':
                return 'es';
            case 'pt-BR':
                return 'pt-BR';
            default:
                return 'en';
        }
    }, []);

    const supportUrl = `https://support.argusolutions.com.br/${selectedLanguage()}/support/home`;

    const handleOpenHelp = useCallback(() => {
        const newTab = window.open(supportUrl, '_blank');
        newTab?.focus();
    }, [supportUrl]);

    return (
        <StyledSupport type="button" {...props} onClick={handleOpenHelp}>
            <BsHeadset size={26}/>
            <p>{t('menu.help')}</p>
        </StyledSupport>
    );
}
