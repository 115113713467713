import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Fragment, useCallback, useRef, useState } from 'react';
import { useSidebar } from 'src/common/hooks';
import { FunctionComponent } from 'src/common/types/react';

import { ArrowMenu } from '../../../assets';
import ArgusLogo from '../icons/argus-logo';
import LanguageSelector from '../language-selector';
import LogoutButton from '../logout-button';
import LogoutDialog from '../logout-dialog';
import SupportButton from '../support-button';
import SidebarItemsList from './sidebar-items-list';
import { Children, SidebarContainer, SidebarFooter, SidebarLogo, SidebarLogoWrapper, SidebarOptions, SidebarToggler, SidebarWrapper } from './styles';

export default function Sidebar({ children }: FunctionComponent) {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const [selectedItem, setSelectedItem] = useState('');

    const { displaySidebar, setDisplaySidebar } = useSidebar();

    const handleSelectItem = useCallback((id: string) => {
        setSelectedItem(oldValue => (oldValue === id ? '' : id));
    }, []);

    const handleOpenLogoutDialog = useCallback(() => {
        dialogRef.current?.showModal();
    }, []);

    const handleOpenSidebar = useCallback(() => {
        setDisplaySidebar(oldValue => !oldValue);
        setSelectedItem('');
    }, [setDisplaySidebar]);

    return (
        <Fragment>
            <SidebarContainer className={displaySidebar ? 'sidebar-opened' : 'sidebar-closed'} displaySidebar={displaySidebar}>
                <SidebarWrapper>
                    <SidebarOptions>
                        <SidebarLogoWrapper displaySidebar={displaySidebar}>
                            <SidebarLogo className="argus-logo" to="/">
                                <ArgusLogo />
                            </SidebarLogo>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleOpenSidebar}
                                edge="start"
                                sx={{ ...(displaySidebar && { display: 'none' }) }}
                            >
                                <MenuIcon className="menu-icon" />
                            </IconButton>
                            <SidebarToggler id="arrow" type="button" displaySidebar onClick={handleOpenSidebar}>
                                <img className="arrow-menu" src={ArrowMenu} alt="Arrow menu" />
                            </SidebarToggler>
                        </SidebarLogoWrapper>
                        <SidebarItemsList displaySidebar={displaySidebar} selectedItem={selectedItem} onClick={handleSelectItem} />
                    </SidebarOptions>
                    <SidebarFooter id="sidebar-footer" displaySidebar={displaySidebar}>
                        <LanguageSelector color="#fff" />
                        <SupportButton />
                        <LogoutButton onClick={handleOpenLogoutDialog} />
                    </SidebarFooter>
                </SidebarWrapper>
            </SidebarContainer>
            <Children displaySidebar={displaySidebar}>{children}</Children>
            <LogoutDialog id="logout-dialog" ref={dialogRef} />
        </Fragment>
    );
}
