import { VideoPlayer } from 'src/common/components';
import styled from 'styled-components';

interface SlideProps {
    selected?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 328px;
    height: 190px;

    background: #f7f7f7;
    border-radius: 8px;
    position: relative;
    transition: 0.7s ease;

    &:hover {
        .prev,
        .next {
            display: flex;
        }
    }

    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        transition: 0.6s ease;
        user-select: none;

        align-items: center;
        justify-content: center;

        border-radius: 4px;
        width: 24px;
        height: 24px;
        z-index: 2;
        display: none;
    }

    .prev {
        left: 16px;
    }

    .next {
        right: 16px;
    }

    .prev:hover,
    .next:hover {
        background: rgba(255, 255, 255, 0.7);
    }
`;

export const PlayerButton = styled.button`
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    background-color: transparent;
    width: 32px;
    height: 32px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0;
    margin: 0;
    padding: 0;

    &:hover {
        background: rgba(255, 255, 255, 0.7);
    }
`;

export const StyledImage = styled.div<SlideProps>`
    @keyframes fade {
        from {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }

    display: ${props => (props?.selected ? 'flex' : 'none')};
    z-index: ${props => (props?.selected ? '1' : '0')};

    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 0;
    animation-name: fade;
    animation-duration: 1.5s;
    overflow: hidden;

    height: 190px;
    width: 328px;
`;

export const StyledVideoPlayer = styled(VideoPlayer)<SlideProps>`
    position: relative;
    width: 328px;
    height: 190px;
    left: 0;
    top: 0;

    display: ${props => (props?.selected ? 'flex' : 'none')};
    z-index: ${props => (props?.selected ? '1' : '0')};

    border-radius: 8px;
`;

export const VideoContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-radius: 8px;

    &:hover {
        .player {
            display: flex;
        }
    }
`;
