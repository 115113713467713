import { endOfDay, isAfter, isEqual } from 'date-fns';
import { array, date, number, object, string } from 'yup';

export const createElectronicFenceSchema = object().shape({
    id: number(),
    active: string(),
    color: string(),
    colorTransparencyPercentage: string(),
    coordinates: array()
        .of(
            object()
                .shape({
                    lat: number().required('field_validations.required'),
                    lng: number().required('field_validations.required'),
                })
                .required('field_validations.required'),
        )
        .required('field_validations.required'),
    expiresIn: string().required('field_validations.required'),
    generateEventBy: string().required('field_validations.required'),
    geometricShape: string().required('field_validations.required').typeError('field_validations.required'),
    name: string()
        .required('field_validations.required')
        .max(191, 'field_validations.max')
        .test({
            test: value => !!value?.trim(),
            message: 'field_validations.required',
        }),
    operation: string().required('field_validations.required'),
    observation: string().max(191, 'field_validations.max'),
    expirationDate: date()
        .optional()
        .typeError('field_validations.date')
        .when('id', {
            is: (id: number) => !id,
            otherwise: date()
                .optional()
                .typeError('field_validations.date')
                .test({
                    test: (value, data) => {
                        if (!value) return true;

                        return (
                            isAfter(endOfDay(new Date(value)), endOfDay(new Date(data.parent.createdAt))) ||
                            isEqual(endOfDay(new Date(value)), endOfDay(new Date(data.parent.createdAt)))
                        );
                    },
                    message: 'field_validations.date_is_before_creation_date',
                }),
            then: date()
                .optional()
                .typeError('field_validations.date')
                .test({
                    test: value => {
                        if (!value) return true;

                        return isAfter(endOfDay(new Date(value)), endOfDay(new Date())) || isEqual(endOfDay(new Date(value)), endOfDay(new Date()));
                    },
                    message: 'field_validations.date_is_before_today',
                }),
        }),
    radius: number().default(0),
});
