import styled from "styled-components";

interface AutomaticSearchProps {
    isAutomaticSearch: boolean;
}

export const AutomaticSearchOptionsSwitch = styled.div`
    display: flex;
    gap: 0.4rem;

    .tooltip-container {
        display: flex;
        gap: 0.4rem;
    }
`;

export const AutomaticSearchTitle = styled.span<AutomaticSearchProps>`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-decoration-line: ${props => (props.isAutomaticSearch ? 'underline' : 'none')};
    color: ${props => (props.isAutomaticSearch ? '#6da4d3 !important' : 'rgb(169, 169, 169)')};
`;
