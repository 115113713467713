import { createContext, useMemo, useRef } from 'react';
import { DialogLoader } from 'src/common/components';
import { FunctionComponent } from 'src/common/types';

import { LoaderContextProps } from './types';

export const LoaderContext = createContext({} as LoaderContextProps);

export default function LoaderProvider({ children }: FunctionComponent): JSX.Element {
    const loaderRef = useRef<HTMLDialogElement>(null);

    const data = useMemo<LoaderContextProps>(() => ({ loaderRef }), []);

    return (
        <LoaderContext.Provider value={data}>
            <DialogLoader />
            {children}
        </LoaderContext.Provider>
    );
}
