import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import { Autocomplete, Button, Column, Divider, Input, RenderIf, Row, Select, ToggleSwitch } from 'src/common/components';
import CheckboxTree, { CheckboxTreeState, NodeProps } from 'src/common/components/checkbox-tree';
import { OptionProps } from 'src/common/components/inputs/autocomplete';
import { toast } from 'src/common/components/toast';
import { usePermission, useScrollbar } from 'src/common/hooks';
import { departmentService } from 'src/modules/configurations/departments';
import { functionService } from 'src/modules/configurations/functions';
import { useListPasswordPolicies } from 'src/modules/configurations/password-policies';

import { CreateUserDTO } from '../../dtos';
import { OperationHelper } from '../../helpers/operation.helper';
import { useUsers } from '../../hooks/use-users';
import { createOrUpdateUserSchema } from '../../infra';
import { userService } from '../../services/user.service';
import { CloseButton, Form, MandatoryFields } from './styles';

interface IsLoading {
    function: boolean;
    operations: boolean;
}

export default function CreateOrUpdateUserForm() {
    const { isAdmin } = usePermission();
    const { showScrollBar } = useScrollbar();
    const { t, i18n } = useTranslation();
    const { handleOpenDialog, handleRequestList, initialFormData } = useUsers();

    const controller = useMemo(() => new AbortController(), []);

    const { isLoading: isListingPasswordPolicies, mutate: listPolicies, data: policies } = useListPasswordPolicies();

    const { mutateAsync, isLoading: isSaving } = useMutation({
        mutationFn: initialFormData?.id ? userService.update : userService.create,
        mutationKey: ['create-or-update-user'],
        onSuccess: () => {
            toast.success(t('general.success'), '');
            handleRequestList({
                currentPage: 1,
            });
            setOperationsState({
                checked: [],
                expanded: [],
            });
            handleClose();
            showScrollBar();
        },
        onError: error => {
            if (error instanceof AxiosError) {
                return toast.error(
                    t('general.message.error.title'),
                    i18n.exists(error.response?.data?.message) ? t(error.response?.data?.message) : t('general.message.error.message'),
                );
            }
            toast.error(t('general.message.error.title'), t('general.message.error.message'));
        },
    });

    const [operationsSearch, setOperationsSearch] = useState('');

    const [isLoading, setIsLoading] = useState<IsLoading>({
        function: false,
        operations: false,
    });

    const [functions, setFunctions] = useState<OptionProps[]>([]);
    const [operationsNode, setOperationsNode] = useState<{
        nodes: NodeProps[];
        filter: NodeProps[];
    }>({
        filter: [],
        nodes: [],
    });

    const [operationsState, setOperationsState] = useState<CheckboxTreeState>({
        checked: [],
        expanded: [],
    });

    const isReadOnly = useMemo(
        () => ({
            readOnly: !isAdmin,
            disabled: !isAdmin,
        }),
        [isAdmin],
    );

    const handleClose = useCallback(() => {
        handleOpenDialog();
    }, [handleOpenDialog]);

    const onSubmit = useCallback(
        async (data: CreateUserDTO) => {
            if (!isAdmin) return;

            const functionId = functions.find(func => func.value === data.function)?.id;
            const passwordPolicyId = data.passwordPolicy
                ? policies?.elements.find(p => p.name.toUpperCase() === data.passwordPolicy?.trim()?.toUpperCase())?.id
                : undefined;

            if (!functionId) {
                return toast.error(t('general.error'), `${t('field_validations.invalid_record')}: ${t('configurations.function')}`);
            }

            if (data.passwordPolicy?.trim()) {
                if (!passwordPolicyId) {
                    return toast.error(t('general.error'), `${t('field_validations.invalid_record')}: ${t('configurations.password_policy')}`);
                }
            }

            const request = {
                ...data,
                id: (data?.id || null) as any,
                functionId: String(functionId),
                enabled: JSON.parse(data.enabled),
                operationsIds: operationsState.checked.map(o => Number(o)) || [],
                signal: controller.signal,
                passwordPolicyId,
            };

            await mutateAsync(request);
        },
        [controller.signal, functions, isAdmin, mutateAsync, operationsState.checked, policies?.elements, t],
    );

    const {
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        register,
        watch,
    } = useForm<CreateUserDTO>({
        resolver: yupResolver(createOrUpdateUserSchema),
        defaultValues: initialFormData,
        mode: 'onChange',
    });

    const statusOptions = useMemo(() => {
        return [
            {
                value: 'true',
                label: t('general.status.active'),
            },
            {
                value: 'false',
                label: t('general.status.inactive'),
            },
        ];
    }, [t]);

    const profileOptions = useMemo(() => {
        return [
            {
                value: 'Cliente',
                label: t('configurations.client'),
            },
            {
                value: 'Analista',
                label: t('configurations.analyst'),
            },
            {
                value: 'Cliente Analista',
                label: t('configurations.client_analyst'),
            },
        ];
    }, [t]);

    const name = watch('function');

    useEffect(() => {
        if (name && functions.some(fun => fun.value.startsWith(name || ''))) {
            setIsLoading(state => ({ ...state, function: false }));
            return;
        }

        setIsLoading(state => ({ ...state, function: true }));

        const id = setTimeout(() => {
            functionService
                .listFunctions({
                    active: true,
                    currentPage: 0,
                    name,
                    pageSize: 10,
                })
                .then(res =>
                    setFunctions(
                        res.elements?.map((el, index) => ({
                            value: el?.name || '',
                            id: el?.id || index,
                        })) || [],
                    ),
                )
                .finally(() => setIsLoading(state => ({ ...state, function: false })));
        }, 1000);

        return () => clearTimeout(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    useEffect(() => {
        setIsLoading(state => ({ ...state, operations: true }));
        departmentService
            .getHierarchicalList()
            .then(res => {
                const nodes = OperationHelper.toTree(res);

                setOperationsNode({
                    filter: nodes,
                    nodes,
                });
                if (initialFormData?.id && initialFormData?.uuid) {
                    userService.getOperations(initialFormData.email, initialFormData.uuid).then(data => {
                        const operationHelper = new OperationHelper([]);

                        const check = operationHelper.getIds(data);

                        setOperationsState(state => ({ ...state, checked: check }));
                    });
                }
            })
            .finally(() => setIsLoading(state => ({ ...state, operations: false })));
    }, [initialFormData.email, initialFormData?.id, initialFormData.uuid]);

    const operationHelper = useMemo(() => new OperationHelper([]), []);

    useEffect(() => {
        const id = setTimeout(() => {
            operationHelper.setCopy = [];

            setOperationsNode(state => {
                return { ...state, filter: operationHelper.filter(operationsSearch, state.nodes) };
            });
        }, 500);

        return () => clearTimeout(id);
    }, [operationHelper, operationsSearch]);

    const policy = watch('passwordPolicy');

    useEffect(() => {
        const id = setTimeout(() => {
            listPolicies({
                signal: controller.signal,
                currentPage: 1,
                pageSize: 15,
                active: true,
                text: policy,
            });
        }, 500);

        return () => clearTimeout(id);
    }, [controller.signal, listPolicies, policy]);

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, [controller]);

    const title = useMemo(() => {
        if (!isAdmin) {
            return t('configurations.view_user');
        }

        return t(initialFormData?.id ? 'configurations.edit_user' : 'configurations.add_user');
    }, [initialFormData?.id, isAdmin, t]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row justify="space-between" width="100%" align="flex-start" gap={48}>
                <h4>{title}</h4>
                <CloseButton type="button" onClick={handleClose}>
                    <CgClose color="#4D4D4D" size={20} />
                </CloseButton>
            </Row>

            <Column align="flex-start" gap={24} width="100%">
                <Row align="center" justify="flex-end" gap={16} width="100%" style={{ margin: '-24px 0' }}>
                    <MandatoryFields>* {t('field_validations.mandatory_fields')}</MandatoryFields>
                </Row>
                <Row align="flex-start" gap={16} width="50%">
                    <ToggleSwitch mode="small" label={t('general.admin')?.toString()} {...register('isAdmin')} {...isReadOnly} />
                    <ToggleSwitch mode="small" label={t('general.notification_sound')?.toString()} {...register('notificationSound')} />
                </Row>
                <Row align="flex-start" gap={16} width="100%">
                    <Input
                        autoComplete="off"
                        hint="*"
                        label={String(t('configurations.name_user'))}
                        placeholder={String(t('general.write'))}
                        type="text"
                        feedback={{
                            message: t(errors['name']?.message || '')
                                ?.toString()
                                ?.replace('$$min', '3'),
                        }}
                        {...register('name')}
                        {...isReadOnly}
                    />
                    <Select
                        options={statusOptions}
                        feedback={{
                            message: t(errors['enabled']?.message || '')?.toString(),
                        }}
                        label={String(t('configurations.status'))}
                        placeholder={String(t('general.write'))}
                        {...register('enabled')}
                        {...isReadOnly}
                    />
                </Row>
                <Row align="flex-start" gap={16} width="100%">
                    <Input
                        autoComplete="off"
                        feedback={{
                            message: t(errors.email?.message || '')?.toString(),
                        }}
                        hint="*"
                        label={String(t('configurations.email'))}
                        placeholder={String(t('general.write'))}
                        type="email"
                        maxLength={100}
                        {...register('email')}
                        {...isReadOnly}
                    />
                    <Input
                        autoComplete="off"
                        label={String(t('configurations.phone_number'))}
                        placeholder={String(t('general.write'))}
                        type="text"
                        feedback={{
                            message: t(errors['phone']?.message || '')?.toString(),
                        }}
                        tooltip={String(t('field_validations.phone_number_tooltip'))}
                        {...register('phone')}
                        {...isReadOnly}
                    />
                </Row>
                <Row align="flex-start" gap={16} width="100%">
                    <Autocomplete
                        hint="*"
                        isLoading={isLoading.function}
                        label={String(t('configurations.function'))}
                        options={functions}
                        optionsHeight="96px"
                        placeholder={String(t('general.write'))}
                        setValue={setValue}
                        feedback={{
                            message: t(errors['function']?.message || '')?.toString(),
                        }}
                        nextElement={{
                            id: 'passwordPolicy',
                        }}
                        showErrorOnMount
                        {...register('function')}
                        {...isReadOnly}
                    />
                    <Autocomplete
                        label={String(t('configurations.password_policy'))}
                        isLoading={isListingPasswordPolicies}
                        options={policies?.elements.map(e => ({ id: e.id, value: e.name })) || []}
                        optionsHeight="96px"
                        placeholder={String(t('general.write'))}
                        setValue={setValue}
                        feedback={{
                            message: t(errors['passwordPolicy']?.message || '')?.toString(),
                        }}
                        nextElement={{
                            id: 'searchOperation',
                        }}
                        showErrorOnMount
                        {...register('passwordPolicy')}
                        {...isReadOnly}
                    />
                </Row>
                <Row align="flex-start" gap={16} width="100%">
                    <Column gap={16} flex>
                        <Row gap={16}>
                            <Input
                                name="searchOperation"
                                autoComplete="off"
                                label={String(t('alarms.operation'))}
                                placeholder={String(t('general.write'))}
                                type="text"
                                onChange={e => setOperationsSearch(e.target.value.toUpperCase())}
                                value={operationsSearch}
                                isLoading={isLoading.operations}
                                {...isReadOnly}
                            />
                            <Select
                                options={profileOptions}
                                feedback={{
                                    message: t(errors['profile']?.message || '')?.toString(),
                                }}
                                label={String(t('configurations.profile'))}
                                {...register('profile')}
                                {...isReadOnly}
                            />
                        </Row>
                        <CheckboxTree nodes={operationsNode.filter} state={operationsState} setState={setOperationsState} />
                    </Column>
                </Row>
                <Divider />
            </Column>
            <RenderIf condition={isAdmin}>
                <Row align="flex-start" gap={16} width="100%">
                    <Button color="alert" type="reset" onClick={handleClose}>
                        {t('general.cancel')}
                    </Button>
                    <Button
                        disabled={!isValid || isLoading.function || isLoading.operations || isListingPasswordPolicies}
                        isLoading={isSaving}
                        type="submit"
                    >
                        {t('general.confirm')}
                    </Button>
                </Row>
            </RenderIf>
        </Form>
    );
}
