import styled from 'styled-components';

export const ErrorMessage = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #ef1d00;
`;
