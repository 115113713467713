import { SVGProps } from 'react';

function Trash(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.5 11.25V9M10.5 11.25V9M2.25 6h13.5v0c-.232 0-.348 0-.446.01a2 2 0 00-1.794 1.794c-.01.098-.01.214-.01.446V11c0 1.886 0 2.828-.586 3.414C12.328 15 11.386 15 9.5 15h-1c-1.886 0-2.828 0-3.414-.586C4.5 13.828 4.5 12.886 4.5 11V8.25c0-.232 0-.348-.01-.446A2 2 0 002.696 6.01C2.598 6 2.482 6 2.25 6v0zM7.551 3.278c.086-.08.274-.15.536-.2A5.01 5.01 0 019 3c.33 0 .651.027.913.077s.45.121.536.2"
                stroke="#EF1D00"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    );
}

export default Trash;
