import { SVGProps } from 'react';

export default function CircleGridCrossLeftFill(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.0001 9.33807C13.8156 9.33807 15.2997 7.84644 15.2997 6.03087C15.2997 4.22283 13.8156 2.7312 12.0001 2.7312C10.1694 2.7312 8.68534 4.22283 8.68534 6.03087C8.68534 7.84644 10.1694 9.33807 12.0001 9.33807ZM12.0001 7.94437C10.9152 7.94437 10.079 7.10815 10.079 6.03087C10.079 4.96111 10.9152 4.09476 12.0001 4.09476C13.0698 4.09476 13.9136 4.96111 13.9136 6.03087C13.9136 7.10815 13.0698 7.94437 12.0001 7.94437ZM6.52323 15.0032C8.33126 15.0032 9.82289 13.5116 9.82289 11.696C9.82289 9.88801 8.33126 8.39638 6.52323 8.39638C4.69259 8.39638 3.2085 9.88801 3.2085 11.696C3.2085 13.5116 4.69259 15.0032 6.52323 15.0032ZM17.492 15.0032C19.3 15.0032 20.7916 13.5116 20.7916 11.696C20.7916 9.88801 19.3 8.39638 17.492 8.39638C15.6613 8.39638 14.1772 9.88801 14.1772 11.696C14.1772 13.5116 15.6613 15.0032 17.492 15.0032ZM17.4844 13.6095C16.4072 13.6095 15.5634 12.7733 15.5634 11.696C15.5634 10.6263 16.4072 9.75994 17.4844 9.75994C18.5617 9.75994 19.4055 10.6263 19.4055 11.696C19.4055 12.7733 18.5617 13.6095 17.4844 13.6095ZM12.0001 20.6684C13.8156 20.6684 15.2997 19.1693 15.2997 17.3612C15.2997 15.5532 13.8156 14.0616 12.0001 14.0616C10.1694 14.0616 8.68534 15.5532 8.68534 17.3612C8.68534 19.1693 10.1694 20.6684 12.0001 20.6684ZM12.0001 19.2747C10.9152 19.2747 10.079 18.4385 10.079 17.3612C10.079 16.2915 10.9152 15.4251 12.0001 15.4251C13.0698 15.4251 13.9136 16.2915 13.9136 17.3612C13.9136 18.4385 13.0698 19.2747 12.0001 19.2747Z"
                fill="#D7D7D7"
            />
        </svg>
    );
}
