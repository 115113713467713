import { t } from 'i18next';

import Robot from '../../../assets/pagenot.svg';
import { Container } from './styles';

export function PageNotFound() {
    return (
        <>
            <Container>
                <img src={Robot} />
                <a href="/">{t('general.back_to_home')}</a>
            </Container>
        </>
    );
}
