import { ApiResponse, TransformRequestHelper, api } from '../../../../common';
import { ComparisonOfUnitsDTO } from '../dtos/comparison-of-units-dtos';

export class ComparisonOfUnitsService {
    public async getData({ currentPage, pageSize }: ComparisonOfUnitsDTO): Promise<any> {
        const {
            data: { content },
        } = await api.get<ApiResponse>('/analytics/units_comparison/paginated', {
            params: {
                start: TransformRequestHelper.currentPageToStart(currentPage, pageSize),
                size: pageSize,
            },
        });

        return content;
    }
}

export const comparisonOfUnitsService = new ComparisonOfUnitsService();
