import { useMutation } from '@tanstack/react-query';

import { passwordPolicyService } from '../services';

export function useListPasswordPolicies() {
    return useMutation({
        mutationKey: ['list-password-policies'],
        mutationFn: passwordPolicyService.listPolicies,
    });
}
