import { t } from 'i18next';
import { IoMdAdd } from 'react-icons/io';

import { Button } from './styles';

export default function ButtonAddProtocol() {
    return (
        <Button>
            <IoMdAdd color="#6da4d3" fontSize={'20px'} />
            {t('intervention_protocol.new_details')}
        </Button>
    );
}
