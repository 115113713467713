import { FocusEvent, forwardRef, Fragment, InputHTMLAttributes, useCallback, useMemo, useState } from 'react';

import Divider from '../../divider';
import { Input, InputProps } from '../index';
import { Button, Container, Options } from './styles';

export interface OptionProps<T = string> {
    id: string | number;
    value: T;
}

export interface NextElementProps {
    id: string;
    name?: string;
}

export type AutocompleteProps = InputHTMLAttributes<HTMLInputElement> &
    InputProps & {
        name: string;
        options: OptionProps[];
        optionsHeight?: string;
        setValue(field: string, value: string): void;
        showErrorOnMount?: boolean;
        nextElement?: NextElementProps;
    };

const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>(function Base(
    { options, showErrorOnMount, optionsHeight, nextElement, setValue, ...props },
    ref,
) {
    const [showError, setShowError] = useState(showErrorOnMount || false);

    const handleSelectOption = useCallback(
        (value: string) => {
            setValue(props.name, value);
        },
        [props.name, setValue],
    );

    const handleShowOnBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            setTimeout(() => {
                props?.onBlur?.(e);
                setShowError(true);
            }, 100);
        },
        [props],
    );

    const optionsMemo = useMemo(() => {
        if (options?.length > 0) {
            return (
                <Options className="opt" optionsHeight={optionsHeight}>
                    {options.map(option => (
                        <Fragment key={option.id}>
                            <Button
                                type="button"
                                key={option.id}
                                onClick={() => {
                                    handleSelectOption(option.value);
                                    setShowError(true);

                                    if (nextElement) {
                                        document
                                            .querySelector<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(`#${nextElement.id}`)
                                            ?.focus();
                                    }
                                }}
                            >
                                {option.value}
                            </Button>
                            <Divider />
                        </Fragment>
                    ))}
                </Options>
            );
        }
    }, [handleSelectOption, nextElement, options, optionsHeight]);

    return (
        <Container style={props?.style} flex onBlur={handleShowOnBlur}>
            <Input role="combobox" autoComplete="off" ref={ref} style={{ width: '100%' }} showFeedback={showError} {...props} />
            {optionsMemo}
        </Container>
    );
});

export default Autocomplete;
