import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';

import { Button, Checkbox, CloseButton, Column, Divider, Input, Row, Select } from '../../../../../common';
import { useRegistrationShipments } from '../../hooks';
import { Form, Label, Title } from './styles';

export function RegistrationShipmentsDialogForm() {
    const { handleOpenDialog } = useRegistrationShipments();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<any>({
        mode: 'onChange',
    });

    const onSubmit = useCallback(() => {
        setIsLoading(true);

        setIsLoading(false);
    }, []);

    const title = useMemo(() => {
        return t('registration_shipments.add_chance');
    }, [t]);

    return (
        <Form method="POST" onSubmit={handleSubmit(onSubmit)}>
            <Column gap={24} align="flex-start" flex width="100%">
                <Column gap={16} align="flex-start" width="100%">
                    <Row flex justify="space-between" width="100%" align="flex-start" gap={48}>
                        <Title>{title}</Title>
                        <CloseButton type="button" onClick={() => handleOpenDialog()}>
                            <CgClose color="#4D4D4D" size={20} />
                        </CloseButton>
                    </Row>

                    <Row width="100%">
                        <Input
                            autoComplete="off"
                            autoFocus
                            hint="*"
                            label={String(t('registration_shipments.contact'))}
                            type="text"
                            placeholder="Digite o e-mail de contato"
                            {...register('email')}
                        />
                    </Row>

                    <Row width="100%">
                        <Select
                            {...register('operation')}
                            label={String(t('registration_shipments.operation'))}
                            options={[]}
                            placeholder="Selecione a operação"
                        />
                    </Row>

                    <Row width="100%" gap={8}>
                        <Input
                            autoComplete="off"
                            label={String(t('registration_shipments.telephone'))}
                            type="tel"
                            placeholder="(99) 99999-9999"
                            {...register('telephone')}
                        />

                        <Select
                            {...register('operation')}
                            label={String(t('registration_shipments.shipping_type'))}
                            options={[]}
                            placeholder="Para"
                        />

                        <Input
                            autoComplete="off"
                            label={String(t('registration_shipments.priority'))}
                            type="number"
                            min={1}
                            {...register('priority')}
                        />
                    </Row>

                    <Column gap={8}>
                        <Label>{t('registration_shipments.channel')}</Label>
                        <Row gap={8}>
                            <Checkbox name="intervenção" mode="blue-check" label="Intervenção" value="Intervenção" />
                            <Checkbox name="report" mode="blue-check" label="Report diário" value="Report diário" />
                            <Checkbox name="report" mode="blue-check" label="Contato Telefônico" value="Contato telefônico" />
                        </Row>
                    </Column>
                </Column>
                <Divider />
            </Column>
            <Row align="flex-start" gap={16} width="100%">
                <Button color="alert" type="reset" onClick={() => handleOpenDialog()}>
                    {t('general.cancel')}
                </Button>
                <Button disabled={!isValid} isLoading={isLoading} type="submit">
                    {t('general.confirm')}
                </Button>
            </Row>
        </Form>
    );
}
