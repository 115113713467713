import styled from 'styled-components';

export const Container = styled.span`
    font-family: 'Open Sans', sans-serif;
    color: #4d4d4d;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    cursor: default;
    text-align: center;
    width: 100%;
    max-width: 302px;
    word-break: break-all;
`;

export const Details = styled.details`
    display: inline-block;
`;

export const Summary = styled.summary`
    transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: color, background-color, box-shadow, border-color;
    list-style: none;
    text-decoration: underline;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    &:hover {
        color: #a2cdf0;
    }
`;

export const ModalTitle = styled.h4`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #262d3c;
    margin: 0;
`;
