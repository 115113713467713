import { useEffect, useState } from 'react';
import styled from 'styled-components';

export type TTextWithTimer = {
    timer: number;
    mode: 'increment' | `countdonw`;
    increment?: number;
    textOnEnable: string;
    enbleFn: (timer: number) => boolean;
    onClick: () => void;
};

const TextLink = styled.div`
    cursor: pointer;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #46526c;
`;

const TextLinkDisabled = styled.div`
    cursor: not-allowed;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #7e7e7e;
`;

export default function TextWithTimer({ timer: timerValue, mode, enbleFn, textOnEnable, increment = 1, ...rest }: TTextWithTimer) {
    const incrementValue = increment * 1000;
    const multiplier = mode === 'countdonw' ? -1 : 1;
    const initValue = mode === 'increment' ? 0 : timerValue;
    const [timer, setTimer] = useState(initValue);
    useEffect(() => {
        if ((mode === 'countdonw' && timer <= 0) || (mode === 'increment' && timer >= timerValue)) {
            return;
        }
        const interval = setInterval(() => setTimer(timer + multiplier * increment >= 0 ? timer + multiplier * increment : 0), increment * 1000);
        return () => clearInterval(interval);
    }, [increment, incrementValue, mode, multiplier, timer, timerValue]);

    if (enbleFn(timer)) {
        return (
            <TextLink
                onClick={() => {
                    setTimer(initValue);
                    rest.onClick();
                }}
            >
                {textOnEnable}
            </TextLink>
        );
    } else {
        return (
            <TextLinkDisabled>
                {textOnEnable} em {timer}s
            </TextLinkDisabled>
        );
    }
}
