import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Field } from 'src/common/components';

import { Text, Title } from './styles';

interface WelcomeProps {
    email: string;
    name: string;
}

export default function Welcome({ email, name }: WelcomeProps) {
    const { t } = useTranslation();

    const firstName = useMemo(() => name?.split(' ')[0], [name]);

    return (
        <Column align="flex-start" width="100%" gap={8}>
            <Text>
                <Title>
                    {t('auth.welcome')}
                    {name && (
                        <>
                            , <strong>{firstName}</strong>
                        </>
                    )}
                    .
                </Title>
                <p>{t('auth.register_new_password')}</p>
            </Text>
            {name && <Field name={t('auth.name')} value={name} />}
            <Field name={t('auth.email')} value={email} />
        </Column>
    );
}
