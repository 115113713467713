import { CSSProperties } from 'react';

export const mapContainerStyle: CSSProperties = {
    borderRadius: '0.5rem',
    height: '450px',
    width: '742px',
};

export const circleStyleOptions: google.maps.CircleOptions = {
    clickable: false,
    draggable: false,
    editable: false,
    fillColor: '#6DA4D3',
    fillOpacity: 0.35,
    radius: 800,
    strokeColor: '#6DA4D3',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    visible: true,
    zIndex: 1,
};
