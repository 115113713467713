import blueCheck from 'src/assets/icons/checkbox-blue-fill.svg';
import styled from 'styled-components';

export const RadioLabel = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    color: #7a7f85;
    cursor: pointer;

    &:has(input[type='radio']:checked) {
        span {
            color: #262d3c;
        }
    }

    span {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }

    input[type='radio'] {
        appearance: none;
        width: 20px;
        height: 20px;
        background: #f5f5f5;
        border-radius: 8px;
        margin: 0;
        cursor: pointer;

        border: 2px solid #a9a9a9;
        position: relative;

        &:checked {
            border: 2px solid #186deb;
            background: url(${blueCheck}) no-repeat center;
        }
    }
`;
