import { Pagination, Table } from 'src/common/components';
import styled from 'styled-components';

export const Error = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #ef1d00;
`;

export const StyledPagination = styled(Pagination)`
    padding: 0;
`;

export const StyledTable = styled(Table)`
    td,
    th {
        text-align: left !important;
    }

    td {
        padding-left: 4px;
    }

    th {
        padding-bottom: 0;
    }

    .pointer {
        cursor: pointer;
    }
`;
