import { Fragment } from 'react';
import { Layout } from 'src/common/contexts/list-layout/types';
import { useListLayout } from 'src/common/hooks/use-list-layout';

import { LayoutCard, LayoutLine } from '../icons';
import { Button } from './styles';

export default function ChangeLayout() {
    const { handleChangeLayout, layout } = useListLayout();

    return (
        <Fragment>
            <Button type="button" selected={layout === Layout.LINE} onClick={() => handleChangeLayout(Layout.LINE)}>
                <LayoutLine className="layout-line" />
            </Button>
            <Button type="button" selected={layout === Layout.CARD} onClick={() => handleChangeLayout(Layout.CARD)}>
                <LayoutCard className="layout-card" />
            </Button>
        </Fragment>
    );
}
