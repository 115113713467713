import styled from 'styled-components';

export const Container = styled.section`
    z-index: 1;
    height: 100vh;
    width: 100%;
    display: flex;
    bottom: 1rem;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 90%;
        height: 50%;
    }

    a {
        display: flex;
        width: 15%;
        height: 50px;
        background-color: #262d3c;
        color: #ffffff;
        border: 0;
        border-radius: 8px;
        text-decoration: none;
        text-align: center;
        justify-content: center;
        padding-top: 13px;
    }
`;
