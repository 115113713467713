import { Menu as MuiMenu, styled } from '@mui/material';

export const Menu = styled(MuiMenu)`
    a {
        text-decoration: none;

        &.active {
            li {
                color: #6da4d3;
                background: #eaf6ff;

                &::after {
                    content: '';
                    position: absolute;
                    height: 26px;
                    width: 3px;
                    background: #6da4d3;
                    border-radius: 4px 0px 0px 4px;
                    right: 0;
                    top: 0;
                }
            }
        }
    }

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 16px;

        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-feature-settings: 'kern' off;
        color: #262d3c;

        &:hover {
            color: #6da4d3;
            background: #eaf6ff;

            &::after {
                content: '';
                position: absolute;
                height: 26px;
                width: 3px;
                background: #6da4d3;
                border-radius: 4px 0px 0px 4px;
                right: 0;
                top: 0;
            }
        }
    }

    .MuiMenu-paper {
        box-shadow: -1px 1px 4px 1px rgba(3, 3, 3, 0.15);
        border-radius: 8px;
        margin-top: 3px;
    }
`;
