import styled from 'styled-components';

export const Avatar = styled.div`
    width: 2.5rem;
    height: 2.5rem;

    background: #b2d5f2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #262d3c;
    border: 1px solid #186deb;
`;

export const Container = styled.button`
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    gap: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        background-color: #f1f1f1;
        width: 100%;
        height: 100%;
        mix-blend-mode: multiply;
        padding: 4px;
        border-radius: 8px;
        display: none;
    }

    &:hover::after {
        display: flex;
    }
`;

export const UserBox = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    height: 40px;

    font-size: 12px;
    line-height: 24px;
    text-align: right;
    color: #262d3c;
    font-family: 'Space Grotesk';
    font-weight: 500;

    p {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        p {
            display: none;
        }
    }
`;
