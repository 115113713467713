import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import { Button, Checkbox, Column, Divider, Feedback, Input, Row, Textarea } from 'src/common/components';
import { toast } from 'src/common/components/toast';
import { useLoader, usePermission } from 'src/common/hooks';
import { FeedbackType } from 'src/common/types';

import { CreateFunctionDTO, Role } from '../../dtos';
import { useFunctions } from '../../hooks';
import { createOrUpdateFunctionSchema } from '../../infra/validations';
import { functionService, roleService } from '../../services';
import { CheckboxLegend, CloseButton, Form, Grid, Title } from './styles';

export default function FunctionDialogForm() {
    const { handleOpenDialog, handleSelectAll, handleRequestList, initialFormData, editableFunctionId } = useFunctions();
    const { loaderRef } = useLoader();
    const { isAdmin } = usePermission();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [roles, setRoles] = useState<Role[] | null>(null);

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors, isValid },
    } = useForm<CreateFunctionDTO>({
        resolver: yupResolver(createOrUpdateFunctionSchema),
        mode: 'onChange',
        defaultValues: initialFormData,
    });

    const parentRoles = useMemo(
        () =>
            roles
                ?.filter(role => !role?.parentRoleId)
                ?.sort((a, b) => {
                    if (!a?.nameInPtBr || !b?.nameInPtBr) return 0;
                    if (a.nameInPtBr > b?.nameInPtBr) return 1;
                    if (a.nameInPtBr < b.nameInPtBr) return -1;
                    return 0;
                }),
        [roles],
    );

    useEffect(() => {
        loaderRef?.current?.showModal();
        roleService
            .list()
            .then(data => {
                setRoles(data);
                if (!editableFunctionId) {
                    setValue(
                        'roles',
                        data?.filter(role => role?.parentRole === 'selection_of_settings')?.map(role => role.id),
                    );
                } else {
                    functionService.getFunctionRolesById(editableFunctionId).then(data => {
                        setValue(
                            'roles',
                            data?.map(item => item.roleId),
                        );
                    });
                }
            })
            .finally(() => loaderRef?.current?.close());
    }, [editableFunctionId, loaderRef, setValue]);

    const onSubmit = useCallback(
        (data: CreateFunctionDTO) => {
            setIsLoading(true);

            functionService[editableFunctionId ? 'update' : 'create']({ ...data, id: editableFunctionId as any })
                .then(() => {
                    toast.success(t('general.success'), '');
                    handleSelectAll([]);
                    handleRequestList({
                        currentPage: 1,
                    });
                    handleOpenDialog();
                })
                .catch(e => {
                    if (e instanceof AxiosError) {
                        if (e.response?.data.message === 'field_validations.record_already_exists') {
                            toast.error(t('general.error'), t('field_validations.record_already_exists'));
                            return;
                        }
                    }
                    toast.error(t('general.error'), '');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [editableFunctionId, handleOpenDialog, handleRequestList, handleSelectAll, t],
    );

    const title = useMemo(() => {
        if (!isAdmin) {
            return t('configurations.view_function');
        }

        return t(editableFunctionId ? 'configurations.edit_function' : 'configurations.add_function');
    }, [editableFunctionId, isAdmin, t]);

    return (
        <Form method="POST" onSubmit={handleSubmit(onSubmit)}>
            <Column gap={24} align="flex-start" flex width="100%">
                <Column gap={16} align="flex-start" width="100%">
                    <Row flex justify="space-between" width="100%" align="flex-start" gap={48}>
                        <Title>{title}</Title>
                        <CloseButton type="button" onClick={() => handleOpenDialog()}>
                            <CgClose color="#4D4D4D" size={20} />
                        </CloseButton>
                    </Row>

                    <Row width="50%">
                        <Input
                            autoComplete="off"
                            autoFocus
                            hint="*"
                            label={t('configurations.function_name')?.toString()}
                            type="text"
                            disabled={!isAdmin}
                            {...register('name')}
                            feedback={{
                                message: t(errors['name']?.message || '')?.toString(),
                            }}
                        />
                    </Row>
                    <Column gap={8} align="flex-start">
                        {parentRoles?.map(parentRole => {
                            return (
                                <Column gap={8} key={parentRole?.id}>
                                    <CheckboxLegend>{t(`general.${parentRole?.name}`)}:</CheckboxLegend>
                                    <Grid>
                                        {roles
                                            ?.filter(role => role.parentRoleId === parentRole.id)
                                            ?.sort((a, b) => {
                                                if (!a?.nameInPtBr || !b?.nameInPtBr) return 0;
                                                if (a.nameInPtBr > b?.nameInPtBr) return 1;
                                                if (a.nameInPtBr < b.nameInPtBr) return -1;
                                                return 0;
                                            })
                                            ?.map(role => (
                                                <Checkbox
                                                    mode={'blue-check-rounded'}
                                                    id={role.id}
                                                    label={t(`general.${role?.name}`)?.toString()}
                                                    key={role.id}
                                                    value={role.id}
                                                    disabled={!isAdmin}
                                                    {...register('roles')}
                                                />
                                            ))}
                                    </Grid>
                                </Column>
                            );
                        })}
                        {errors?.roles && <Feedback type={FeedbackType.ERROR} message={t('field_validations.required')?.toString()} />}
                    </Column>
                    <Textarea
                        label={t('configurations.observation')?.toString()}
                        style={{ width: '100%' }}
                        disabled={!isAdmin}
                        {...register('observation')}
                    />
                </Column>
                <Divider />
            </Column>
            {isAdmin && (
                <Row align="flex-start" gap={16} width="100%">
                    <Button color="alert" type="reset" onClick={() => handleOpenDialog()}>
                        {t('general.cancel')}
                    </Button>
                    <Button disabled={!isValid} isLoading={isLoading} type="submit">
                        {t('general.confirm')}
                    </Button>
                </Row>
            )}
        </Form>
    );
}
