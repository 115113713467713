import { forwardRef, ForwardRefRenderFunction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';

import CircularProgress from '../circular-progress';
import { ButtonProps } from './button-props';
import { StyledButton } from './styles';

const ButtonBase: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = ({ className, ...props }, ref) => {
    const { t } = useTranslation();

    const classes = useMemo(() => `${props.color} ${props.size} ${className || ''}`.trim(), [className, props.color, props.size]);

    const leftIconComponent = useMemo(() => {
        if (props?.leftIcon) {
            return props?.leftIcon;
        }
        return null;
    }, [props?.leftIcon]);

    const rightIconComponent = useMemo(() => {
        if (props?.rightIcon) {
            return props?.rightIcon;
        }
        return null;
    }, [props?.rightIcon]);

    const childrenComponent = useMemo(() => {
        if (props.isLoading) {
            return (
                <>
                    <CircularProgress />
                    <span> {t('general.loading')}</span>
                </>
            );
        }

        return (
            <>
                {leftIconComponent}
                <span>{props?.children}</span>
                {rightIconComponent}
            </>
        );
    }, [leftIconComponent, props?.children, props.isLoading, rightIconComponent, t]);

    const style: CSSProperties = useMemo(
        () => ({
            height: props?.height,
            padding: props?.padding,
            width: props?.width || '100%',
        }),
        [props?.height, props?.padding, props?.width],
    );

    return (
        <StyledButton className={classes} style={style} ref={ref} {...props} disabled={props?.disabled || props?.isLoading}>
            {childrenComponent}
        </StyledButton>
    );
};

const Button = forwardRef(ButtonBase);

Button.defaultProps = {
    color: 'primary',
    disabled: false,
    isLoading: false,
    size: 'medium',
};

export default Button;
