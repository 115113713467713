import { InfoWindow, Marker } from '@react-google-maps/api';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Field } from 'src/common/components';
import { DateHelper } from 'src/common/helpers';
import { HistoricalRoute } from 'src/modules/alarm-processing/domain/models/historical-route';

interface MarkProps {
    icon: string;
    route: HistoricalRoute;
}

export default function Mark({ icon, route }: MarkProps) {
    const { t, i18n } = useTranslation();

    const [isVisible, setIsVisible] = useState(false);

    const position: google.maps.LatLngLiteral = useMemo<google.maps.LatLngLiteral>(
        () => ({
            lat: route.latitude,
            lng: route.longitude,
        }),
        [route.latitude, route.longitude],
    );

    return (
        <Marker icon={icon} position={position} onMouseOver={() => setIsVisible(true)} onMouseOut={() => setIsVisible(false)}>
            {isVisible && (
                <InfoWindow position={position} onCloseClick={() => setIsVisible(false)}>
                    <Column gap={2} align="flex-start">
                        <Field name={t('alarms.fleet')} value={route.fleet} />
                        <Field name={t('general.date')} value={DateHelper.intl(route.shippingTime, i18n.language)} />
                        <Field name={t('monitoring.lat')} value={route.latitude.toString()} />
                        <Field name={t('monitoring.long')} value={route.longitude.toString()} />
                    </Column>
                </InfoWindow>
            )}
        </Marker>
    );
}
