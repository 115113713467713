import styled from 'styled-components';

export const Button = styled.button`
    color: #6da4d3;
    background-color: #ffffff;
    border: 0;
    font-weight: 600;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: flex-end;
`;
