import { boolean, number, object, string } from 'yup';

export const createPasswordPolicySchema = object().shape({
    active: boolean().required('field_validations.required'),
    containsAtLeastOneLowercaseLetter: boolean().required('field_validations.required'),
    containsAtLeastOneNumber: boolean().required('field_validations.required'),
    containsAtLeastOneSpecialCharacter: boolean().required('field_validations.required'),
    containsAtLeastOneUppercaseLetter: boolean().required('field_validations.required'),
    description: string(),
    expiryTime: string(),
    mfa: boolean().required('field_validations.required'),
    name: string()
        .required('field_validations.required')
        .test('is-required', 'field_validations.required', value => (!value ? false : !!value.trim())),
    organization: string().required('field_validations.required'),
    passwordDiffFrom: string(),
    passwordMinimumLength: number()
        .typeError('field_validations.password_length_recommended')
        .required('field_validations.required')
        .min(6, 'field_validations.password_length_recommended')
        .max(99, 'field_validations.password_length_recommended'),
});
