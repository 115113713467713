import { DialogHTMLAttributes, forwardRef, ForwardRefRenderFunction, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollbar } from 'src/common';
import { Button, Dialog, Divider } from 'src/common/components';
import Column from 'src/common/components/column';
import Row from 'src/common/components/row';
import { toast } from 'src/common/components/toast';

import { Department } from '../../dtos';
import { useOperations } from '../../hooks/use-operations';
import { departmentService } from '../../services';
import { CancelButton, DialogContent } from './styles';

interface DeleteDialogProps extends DialogHTMLAttributes<HTMLDialogElement> {
    department: Department | null;
}

const DeleteDialogBase: ForwardRefRenderFunction<HTMLDialogElement, DeleteDialogProps> = ({ department, ...props }, ref) => {
    const { showScrollBar } = useScrollbar();
    const { deleteModalRef, setRequest, handleSelectAll } = useOperations();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = useCallback(() => {
        deleteModalRef.current?.close();
    }, [deleteModalRef]);

    const handleConfirm = useCallback(async () => {
        if (!department) return;
        setIsLoading(true);
        try {
            await departmentService.delete(department.id);
            setRequest(state => ({
                ...state,
                currentPage: 1,
            }));
            showScrollBar();
            toast.success(t('general.success'), '');
            handleSelectAll([]);
            handleClose();
        } catch (e) {
            toast.error(t('general.error'), '');
        } finally {
            setIsLoading(false);
        }
    }, [department, handleClose, handleSelectAll, setRequest, showScrollBar, t]);

    const data = useMemo(() => {
        if (!department) return <Fragment />;

        return (
            <Fragment>
                <Row justify="center" width="100%" align="start">
                    <h4>{t('configurations.delete_department')}</h4>
                </Row>
                <Column align="center" gap={16} flex width="100%">
                    <Column align="center">
                        <p>
                            {t('configurations.do_you_want_to_delete_the_department')} <b>{JSON.stringify(department.name)}</b>?
                        </p>
                        <p>{t('configurations.this_action')}</p>
                    </Column>
                    <Divider />
                </Column>
                <Row align="flex-end" gap={16} flex width="100%">
                    <CancelButton onClick={handleClose}>{t('general.cancel')}</CancelButton>
                    <Button isLoading={isLoading} type="reset" color="alert" onClick={handleConfirm}>
                        {t('configurations.delete')}
                    </Button>
                </Row>
            </Fragment>
        );
    }, [department, handleClose, handleConfirm, isLoading, t]);

    return (
        <Dialog ref={ref} id="delete-operation-dialog" {...props}>
            <DialogContent>{data}</DialogContent>
        </Dialog>
    );
};

const DeleteDialog = forwardRef(DeleteDialogBase);

export default DeleteDialog;
