import { Tooltip } from '@mui/material';
import { Fragment, MouseEvent, ReactElement, RefAttributes, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { usePermission } from 'src/common/hooks';

import { ChevronDown } from '../../icons';
import DropdownMenu from '../dropdown-menu';
import { SidebarItemsProps } from '../sidebar-items-list';
import Submenu from '../submenu';
import { Container } from './styles';

export type SidebarItemProps = LinkProps &
    SidebarItemsProps &
    RefAttributes<HTMLAnchorElement> & {
        iconComponent?: JSX.Element;
        id: string;
        shouldMatchExactHref?: boolean;
        subMenu?: Array<SidebarItemProps>;
        subMenuAreOpen?: boolean;
        userHas?: string;
        userHasAny?: string[];
        value: string;
    };

export default function SidebarItem({
    displaySidebar,
    iconComponent,
    id,
    onClick,
    shouldMatchExactHref,
    subMenu,
    subMenuAreOpen,
    userHas,
    userHasAny,
    value,
    ...props
}: SidebarItemProps) {
    const { pathname, search } = useLocation();
    const { has, hasAny } = usePermission();
    const { t } = useTranslation();

    const anchorRef = useRef<HTMLAnchorElement>(null);
    const asPath = useMemo(() => pathname + search, [pathname, search]);

    const isActive: boolean = useMemo(() => {
        if ((shouldMatchExactHref && asPath === props.to) || (!shouldMatchExactHref && asPath.startsWith(String(props.to)))) {
            return true;
        }
        return false;
    }, [asPath, props.to, shouldMatchExactHref]);

    const subMenuComponent = useMemo(() => {
        if (subMenu) {
            if (subMenu?.length > 0) {
                if (userHas) {
                    if (!has(userHas)) return null;
                }
                if (subMenuAreOpen) {
                    return displaySidebar ? (
                        <Submenu items={subMenu} />
                    ) : (
                        <DropdownMenu
                            anchorEl={anchorRef.current}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            items={subMenu}
                            onClose={() => anchorRef?.current?.click()}
                            onGo={() => anchorRef?.current?.click()}
                            open={subMenuAreOpen || false}
                        />
                    );
                }
                return null;
            }
            return null;
        }

        return null;
    }, [displaySidebar, has, subMenu, subMenuAreOpen, userHas]);

    const handleClick = useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            if (subMenu) {
                if (subMenu.length > 0) {
                    e.preventDefault();
                }
            }

            onClick?.(e);
        },
        [onClick, subMenu],
    );

    const handleParent = useCallback(
        (children: ReactElement) => {
            if (userHas) {
                if (!has(userHas)) return null;
            }

            if (userHasAny) {
                if (!hasAny(...userHasAny)) return null;
            }

            return displaySidebar ? (
                <Fragment>{children}</Fragment>
            ) : (
                <Tooltip arrow title={t(value)} placement="right">
                    {children}
                </Tooltip>
            );
        },

        [displaySidebar, has, hasAny, t, userHas, userHasAny, value],
    );

    return (
        <Fragment>
            {handleParent(
                <Link ref={anchorRef} id={id} onClick={handleClick} {...props}>
                    <Container isActive={isActive || (!displaySidebar && subMenuAreOpen) || false}>
                        {iconComponent}
                        <span className={displaySidebar ? '' : 'no-display'}>{t(value)}</span>
                        {displaySidebar && subMenu && subMenu.length > 0 && (
                            <ChevronDown className="icon-menu" width={15} height={15} spin={subMenuAreOpen || false} />
                        )}
                    </Container>
                </Link>,
            )}
            {subMenuComponent}
        </Fragment>
    );
}
