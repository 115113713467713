import { VideoPlayer } from 'src/common/components';
import styled from 'styled-components';

export const Subtitle = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    color: #262d3c;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1;
    width: 100%;
`;

export const ImageWrapper = styled.div`
    @keyframes fade {
        from {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }

    display: flex;

    border-radius: 8px;
    animation-name: fade;
    animation-duration: 1.5s;
    overflow: hidden;

    height: 190px;
    width: 328px;
`;

export const StyledVideoPlayer = styled(VideoPlayer)`
    @keyframes fade {
        from {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }

    width: 328px;
    height: 190px;

    display: flex;
    border-radius: 8px;
    animation-name: fade;
    animation-duration: 1.5s;
    box-shadow: -1px 1px 4px 1px rgba(3, 3, 3, 0.15);
`;

export const Title = styled.h5`
    margin-bottom: 0.25rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #262d3c;
`;

export const ButtonOption = styled.button`
    color: var(--primary);
    font-weight: 500;
    text-decoration: underline;
    background: transparent;
    border: none;
    padding: 0;

    &:disabled {
        color: #7b7b7b;
        text-decoration: none;
        cursor: default;
    }
`;

export const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;

    h4 {
        color: #262d3c;
        margin: 0;
        font-weight: 500;
        font-size: 26px;
        line-height: 24px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        color: #7b7b7b;
        word-break: break-all;
    }
`;
