import { useCallback, useMemo } from 'react';

export function useScrollbar() {
    const hideScrollBar = useCallback(() => {
        const { body } = document;

        body?.setAttribute('style', 'overflow: hidden;');
    }, []);

    const showScrollBar = useCallback(() => {
        const { body } = document;

        body?.setAttribute('style', 'overflow: auto;');
    }, []);

    return useMemo(() => ({ hideScrollBar, showScrollBar }), [hideScrollBar, showScrollBar]);
}
