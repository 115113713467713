import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'src/modules/auth/hooks';

export default function MFARoute() {
    const { challenge } = useAuth();

    const Component = useMemo<JSX.Element>(() => {
        if (challenge) {
            switch (challenge.challengeName) {
                case 'NEW_PASSWORD_REQUIRED':
                    return <Navigate to="/login/first-access" />;
                case 'REDEFINE_PASSWORD_REQUIRED':
                    return <Navigate to="/login/new-password" />;
                default:
                    return <Outlet />;
            }
        }
        return <Navigate to="/login" />;
    }, [challenge]);

    return Component;
}
