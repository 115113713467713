import styled from 'styled-components';

export const Label = styled.label`
    cursor: default;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #262d3c;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: fit-content;
    transition: ease-in-out width 0.3s;

    input[type='range'] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #186deb;
        border-radius: 4px;
        height: 8px;
        transition: ease-in 0.3s background-color;
        width: 82px;

        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
            border: 1px solid #186deb;
        }

        &::-moz-range-thumb {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
            border: 1px solid #186deb;
        }
    }
`;
