import moment from 'moment';
import { ApiResponse, Service } from 'src/common';
import { Pagination } from 'src/common/core/pagination';
import { api } from 'src/common/infra';

import { DriversDTO, DriversIdClusterDTO, DriversRequest } from '../dtos/drivers.dtos';

export class DriversPagedIdClusterService implements Service<DriversRequest, Pagination<DriversIdClusterDTO     >> {
    public async execute(data: DriversRequest) {
        const { dateFrom, dateTo, start, pageSize, events, id } = data;
        const {
            data: { content },
        } = await api.get<ApiResponse<Pagination<DriversDTO>>>(`/analytics/drivers/paginated/${id}`, {
            params: {
                dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(dateTo).format('YYYY-MM-DD'),
                events,
                start,
                size: pageSize,
            },
        });

        return content;
    }

}

export const driversPagedIdClusterService = new DriversPagedIdClusterService();
