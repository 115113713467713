import { forwardRef, ForwardRefRenderFunction, TextareaHTMLAttributes, useMemo } from 'react';
import { Column, Feedback } from 'src/common/components';
import { useFeedback } from 'src/common/hooks';

import { InputProps } from '..';

import { FeedbackType } from 'src/common/types';

import Label from '../label';
import { StyledTextarea } from '../styles';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
    InputProps & {
        minHeight?: string;
        name: string;
        width?: string;
    };

const TextareaBase: ForwardRefRenderFunction<HTMLTextAreaElement, TextareaProps> = (
    {
        feedback = {
            message: '',
            type: FeedbackType.ERROR,
        },
        hint,
        label,
        minHeight,
        name,
        width,
        ...props
    },
    ref,
) => {
    const { className } = useFeedback(feedback);

    const feedbackComponent = useMemo(() => {
        if (feedback?.message) {
            return <Feedback {...feedback} />;
        }

        return null;
    }, [feedback]);

    return (
        <Column align="flex-start" gap={8} style={props?.style} flex>
            <Label label={label} hint={hint} htmlFor={name}>
                <StyledTextarea className={className} minHeight={minHeight} name={name} id={name} ref={ref} width={width} {...props} maxLength={191}/>
            </Label>
            {feedbackComponent}
        </Column>
    );
};

const Textarea = forwardRef(TextareaBase);

export default Textarea;
