import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableData, TableRow } from 'src/common/components/table/styles';

import { Table } from '../../../../../common';
import { useFaceMissing } from '../../hook';

function TableList() {
    const { t } = useTranslation();

    const { listFaceMissingDetails } = useFaceMissing();

    const tableHeaders = useMemo(
        () => [
            t('face_missing.fleet'),
            t('face_missing.face_missing'),
            t('face_missing.total_alarms'),
            t('face_missing.percentage'),
            t('face_missing.status'),
        ],
        [t],
    );

    return (
        <Fragment>
            <Table tableHeaders={tableHeaders}>
                {listFaceMissingDetails?.elements.map((data, index) => (
                    <TableRow className="body" key={index}>
                        <TableData>{data.fleet}</TableData>
                        <TableData>{data.totalFaceMissing}</TableData>
                        <TableData>{data.totalEvents}</TableData>
                        <TableData>{data.percentage}</TableData>
                        <TableData>{data.status}</TableData>
                    </TableRow>
                ))}
            </Table>
        </Fragment>
    );
}

export default memo(TableList);
