import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArgusLogo, Button, Column, Input, Row, Title } from 'src/common/components';
import { toast } from 'src/common/components/toast';
import { AuthBackground, AuthCard, AuthForm, BackButton, SupportingText } from 'src/modules/auth/components';
import { createNewPasswordSchema } from 'src/modules/auth/infra/validations';

import { FirstAccessFormDTO } from '../../../modules/auth/dtos/auth.dto';
import { authService } from '../../../modules/auth/factories';
import { useAuth } from '../../../modules/auth/hooks';

export default function CreateNewPassword(): JSX.Element {
    const { challenge, setChallenge } = useAuth();
    const { t, i18n } = useTranslation();
    const controller = useMemo(() => new AbortController(), []);

    const { mutateAsync: firstAccess, isLoading } = useMutation({
        mutationFn: authService.createNewPassword,
        mutationKey: ['new-passwd'],
        onError: error => {
            if (error instanceof AxiosError) {
                return toast.error(
                    t('general.message.error.title'),
                    i18n.exists(error.response?.data?.message) ? t(error.response?.data?.message) : t('general.message.error.message'),
                );
            }
            toast.error(t('general.message.error.title'), t('general.message.error.message'));
        },
        onSuccess: async () => {
            toast.success(t('auth.on_confirm_change_pass.success.title'), t('auth.on_confirm_change_pass.success.message'));
            setChallenge(null);
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<FirstAccessFormDTO>({
        resolver: yupResolver(createNewPasswordSchema),
        mode: 'onChange',
        defaultValues: {
            email: challenge?.email,
            newPassword: '',
            newPasswordConfirmation: '',
            oldPassword: challenge?.password,
        },
    });

    const onSubmit = useCallback(
        async (data: FirstAccessFormDTO) => {
            const { newPassword, email, newPasswordConfirmation, oldPassword } = data;

            await firstAccess({
                newPassword,
                email,
                newPasswordConfirmation,
                oldPassword,
                signal: controller.signal,
            });
        },
        [controller.signal, firstAccess],
    );

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, [controller]);

    return (
        <AuthBackground>
            <Column align="center" justify="center" size="100%">
                <AuthCard>
                    <Row width="100%" align="center" justify="space-between" gap={8}>
                        <BackButton />
                        <ArgusLogo color="#262D3C" height={30} width={131} />
                        <div
                            style={{
                                width: '30px',
                            }}
                        />
                    </Row>
                    <Column align="center" gap={16}>
                        <Column align="center">
                            <Title>{t('auth.redefine_password')}</Title>
                            <SupportingText>{t(challenge?.issue as string)}</SupportingText>
                        </Column>
                        <AuthForm onSubmit={handleSubmit(onSubmit)}>
                            <Column id="welcome" align="center" gap={16} width="302px">
                                <Column width="100%" id="fieldset" align="flex-start" gap={8}>
                                    <Input
                                        type="password"
                                        placeholder={t('auth.password')?.toString()}
                                        style={{ width: '100%' }}
                                        feedback={{
                                            message: t(errors['newPassword']?.message || '')?.toString(),
                                        }}
                                        {...register('newPassword')}
                                    />
                                    <Input
                                        type="password"
                                        placeholder={t('auth.confirm_new_password')?.toString()}
                                        style={{ width: '100%' }}
                                        feedback={{
                                            message: t(errors['newPasswordConfirmation']?.message || '')?.toString(),
                                        }}
                                        {...register('newPasswordConfirmation')}
                                    />
                                </Column>
                            </Column>
                            <Button type="submit" isLoading={isLoading} disabled={!isValid}>
                                {t('auth.login')}
                            </Button>
                        </AuthForm>
                    </Column>
                </AuthCard>
            </Column>
        </AuthBackground>
    );
}
