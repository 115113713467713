import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { usePermission } from 'src/common/hooks';

export interface CheckPermissionRouteProps {
    userHas?: string;
    userHasAny?: string[];
}

export default function CheckPermissionRoute(props: CheckPermissionRouteProps) {
    const { has, hasAny } = usePermission();

    return useMemo(() => {
        if (props?.userHas) {
            return has(props?.userHas) ? <Outlet /> : <Navigate to="not-found-page" />;
        }

        if (props?.userHasAny) {
            return hasAny(...props.userHasAny) ? <Outlet /> : <Navigate to="not-found-page" />;
        }

        return <Outlet />;
    }, [has, hasAny, props?.userHas, props?.userHasAny]);
}
