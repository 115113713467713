import { InputHTMLAttributes, forwardRef, useId } from 'react';

import { RadioLabel } from './styles';

interface RadioProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    label?: string;
}

export default forwardRef<HTMLInputElement, RadioProps>(function Radio(props, ref) {
    const id = useId();

    return (
        <RadioLabel htmlFor={props?.id || id}>
            <span>{props?.label}</span>
            <input id={id} {...props} type="radio" ref={ref} />
        </RadioLabel>
    );
});
