export type EnviromentData = {
    API_URL: string;
    SOCKET_URL: string;
    COGNITO: {
        domain: string;
        clientId: string;
        userPoolId: string;
        region: string;
        redirect: string;
    };
};

export type Environment = {
    local: EnviromentData;
    develop: EnviromentData;
    argus: EnviromentData;
    alfa: EnviromentData;
    beta: EnviromentData;
    production: EnviromentData;
};

const environment: Environment = {
    local: {
        API_URL: 'http://localhost:8888/v1',
        SOCKET_URL: 'http://localhost:8889',
        COGNITO: {
            userPoolId: 'us-east-1_6TznT04aR',
            clientId: '6ab3q2b7uigcp1fspaboqo81jk',
            domain: 'https://argus-authenticator-beta.auth.us-east-1.amazoncognito.com/',
            region: 'us-east-1',
            redirect: 'http://localhost:3000',
        },
    },
    develop: {
        API_URL: 'https://backend.k8s.argus-beta.com.br/v1',
        SOCKET_URL: 'https://webhook.k8s.argus-beta.com.br',
        COGNITO: {
            userPoolId: 'us-east-1_6TznT04aR',
            clientId: '6ab3q2b7uigcp1fspaboqo81jk',
            domain: 'https://argus-authenticator-beta.auth.us-east-1.amazoncognito.com/',
            region: 'us-east-1',
            redirect: 'https://p.argus-beta.com.br',
        },
    },
    argus: {
        API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8888',
        SOCKET_URL: process.env.REACT_APP_SOCKET_URL || 'http://localhost:8889',
        COGNITO: {
            userPoolId: 'us-east-1_6TznT04aR',
            clientId: '6ab3q2b7uigcp1fspaboqo81jk',
            domain: 'https://argus-authenticator-beta.auth.us-east-1.amazoncognito.com/',
            region: 'us-east-1',
            redirect: process.env.REACT_APP_REDIRECT_URL || 'http://localhost:3000',
        },
    },
    alfa: {
        API_URL: 'https://api.argus.alfa.uds.com.br/v1',
        SOCKET_URL: 'https://monitoring.argus.alfa.uds.com.br',
        COGNITO: {
            userPoolId: 'us-east-1_CoE4yghQP',
            clientId: '65rh0760cndeqm4uoupf7k1qij',
            domain: 'https://argus-auth-alfa.auth.us-east-1.amazoncognito.com/',
            region: 'us-east-1',
            redirect: 'https://frontend.argus.alfa.uds.com.br',
        },
    },
    beta: {
        API_URL: 'https://api.argus.beta.uds.com.br/v1',
        SOCKET_URL: 'https://monitoring.argus.beta.uds.com.br',
        COGNITO: {
            userPoolId: 'us-east-1_6TznT04aR',
            clientId: '6ab3q2b7uigcp1fspaboqo81jk',
            domain: 'https://argus-authenticator-beta.auth.us-east-1.amazoncognito.com/',
            region: 'us-east-1',
            redirect: 'https://frontend.argus.beta.uds.com.br',
        },
    },
    production: {
        API_URL: 'https://backend.k8s.argus-ia.com.br/v1',
        SOCKET_URL: 'https://websocket.k8s.argus-ia.com.br',
        COGNITO: {
            userPoolId: 'us-east-1_XS0QvguCs',
            clientId: '19jdhbs76f3g5us6tk41uskol4',
            domain: 'https://argus-authenticator.auth.us-east-1.amazoncognito.com/',
            region: 'us-east-1',
            redirect: 'https://p.argus-ia.com.br',
        },
    },
};

let envName = process.env.REACT_APP_ENV;
let env = environment[envName as keyof Environment];

if (!env) {
    env = environment.local;
    envName = `local`;
}
export { env };
export const API_URL = env.API_URL;
export const ENV = envName;
export const COGNITO_DATA = env.COGNITO;
export const SOCKET_URL = env.SOCKET_URL;
