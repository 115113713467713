import styled from 'styled-components';

export const EventName = styled.h5`
    margin: 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #262d3c;
`;
