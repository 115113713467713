import styled from 'styled-components';

export const DeleteProtocolContainer = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    div {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > h2 {
            color: #262d3c;
            font-weight: 500;
            text-align: center;
        }

        > p {
            color: #7b7b7b;
            text-align: center;
            font-size: 12px;
        }
    }
`;

export const ButtonsDeleteContainer = styled.section`
    display: flex;
    gap: 2rem;
`;
