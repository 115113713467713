import { QRCodeCanvas } from 'qrcode.react';
import Column from 'src/common/components/column';
import RenderIf from 'src/common/components/render-if';

import { Message, Title } from './styles';

interface QRCodeProps {
    value: string;
    message?: string;
    title?: string;
}

export default function QRCode({ message, value, title }: QRCodeProps) {
    return (
        <Column align="center" justify="flex-start" gap={16}>
            <RenderIf condition={!!title || !!message}>
                <Column align="center" justify="start" gap={4}>
                    <RenderIf condition={!!title}>
                        <Title>{title}</Title>
                    </RenderIf>
                    <RenderIf condition={!!message}>
                        <Message>{message}</Message>
                    </RenderIf>
                </Column>
            </RenderIf>
            <QRCodeCanvas value={value} />
        </Column>
    );
}
