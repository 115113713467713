import styled from 'styled-components';

import { ButtonProps } from './button-props';

export const StyledButton = styled.button<ButtonProps>`
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;

    border-radius: 8px;
    flex-wrap: wrap;
    flex: 1;

    font-family: 'Space Grotesk';
    font-style: normal;

    font-size: 14px;
    line-height: 12px;
    text-align: center;

    @media screen and (max-width: 1024px) {
        & > svg {
            display: none;
        }
    }

    &.alert {
        background: #ffebe9;

        border: 1px solid #ef1d00;
        border-radius: 8px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;

        text-align: center;
        letter-spacing: 0.5px;

        color: #ef1d00;
        padding: 0;
        transition: all 0.3s ease-in;

        &:hover {
            background: #ef1d00;
            border-color: #ff0000;
            color: #ffebe9;
        }
    }

    &.primary {
        background-color: #262d3c;
        color: #ffffff;

        letter-spacing: 0.2px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        font-weight: 500;
        transition: all 0.3s ease-out;

        &:disabled {
            background-color: ${props => (props.isLoading ? '#262d3c' : '#f7f7f7')};
            cursor: default;
            color: ${props => (props.isLoading ? '#ffffff' : '#5f5f5f')};

            border: ${props => (props.isLoading ? 'none' : '1px solid #e6e6e6')};
        }

        &:enabled:hover {
            background-color: #46526c;
        }
    }

    &.purple {
        background: #6da4d3;
        border: 1px solid #9fafd2;
        color: #ffffff;

        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.2px;
        font-feature-settings: 'pnum' on, 'lnum' on;
    }

    &.outline {
        background: #f7f7f7;
        border: 1px solid #e6e6e6;
        color: #262d3c;
        font-weight: 600;
        letter-spacing: 0.5px;

        &:disabled {
            cursor: default;
            color: #5f5f5f;
            border: 1px solid #e6e6e6;
        }
    }

    &.outline-purple {
        background: #f5f5f5;
        border: 1px solid #9fafd2;
        color: #46526c;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.2px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        &:disabled {
            cursor: default;
            color: #5f5f5f;
            border: 1px solid #e6e6e6;
        }
    }

    &.large {
        height: 48px;
        padding: 0 16px;
        min-height: 48px;
    }

    &.medium {
        height: 40px;
        padding: 0 16px;
        min-height: 40px;
    }

    &.small {
        height: 36px;
        padding: 0 16px;
        min-height: 36px;
    }
`;
