import { MouseEventHandler } from 'react';

import { ActionButton, Container, Message, Title } from './styles';

interface ContentProps {
    action?: {
        onClick: MouseEventHandler<HTMLButtonElement>;
        text: string;
    };
    message: string;
    title: string;
}

export default function Content({ message, title, action }: ContentProps) {
    return (
        <Container>
            <Title>{title}</Title>
            <Message>{message}</Message>
            {action && (
                <ActionButton onClick={action.onClick}>
                    {action.text}
                </ActionButton>
            )}
        </Container>
    );
}
