import { Column } from 'src/common/components';
import styled from 'styled-components';

export const Container = styled(Column)`
    background: #f1f1f1;
    margin-top: -1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 1rem;
`;

export const Form = styled.form`
    padding: 0 1rem;

    display: grid;
    place-content: start start;
    grid-template-columns: repeat(4, 1fr);

    gap: 1rem;
    flex-wrap: wrap;

    .select-container {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const SpanStyle = styled.span`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
`;
