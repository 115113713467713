import moment from 'moment';
import { useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Divider, Row, Skeleton } from 'src/common/components';
import { useSortReviewEvent } from 'src/modules/alarm-processing/hooks';
import { GetHistoricalRouteOfEventsResponse, getHistoricalRouteService } from 'src/modules/alarm-processing/services';

import Carousel from '../carousel';
import HistoricalRouteMap from '../historical-route-map';
import PastEventInfo from '../past-event-info';
import { ErrorMessage } from './styles';

interface HistoricRouteState {
    hasError: boolean;
    isLoading: boolean;
    data: GetHistoricalRouteOfEventsResponse;
}

interface HistoricRouteAction {
    payload?: GetHistoricalRouteOfEventsResponse;
    type: 'data' | 'loading' | 'error';
}

const INIT_STATE: HistoricRouteState = {
    data: {
        selectedEvent: null,
        events: [],
        historicalRoutes: [],
        lastAppearance: null,
    },
    hasError: false,
    isLoading: true,
};

export default function HistoricRoute() {
    const {
        showEvent: { data: selectedEvent },
    } = useSortReviewEvent();
    const { t } = useTranslation();

    const reducer = (state: HistoricRouteState, action: HistoricRouteAction): HistoricRouteState => {
        if (action?.payload) {
            return { ...state, data: action.payload, hasError: false, isLoading: false };
        }

        switch (action.type) {
            case 'error':
                return { ...state, hasError: true, isLoading: false };
            case 'loading':
                return { ...state, isLoading: true };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, INIT_STATE);

    const mapComponent = useMemo(() => {
        if (state.hasError) return <ErrorMessage>{t('general.error')}</ErrorMessage>;

        if (state.isLoading) {
            return (
                <Column align="center" justify="center" gap={4} width="742px" height="450px">
                    <Skeleton width="100%" height="100%" />
                </Column>
            );
        }

        return (
            <HistoricalRouteMap
                {...{
                    ...state.data,
                    selectedEvent,
                }}
            />
        );
    }, [selectedEvent, state.data, state.hasError, state.isLoading, t]);

    useEffect(() => {
        const controller = new AbortController();

        if (selectedEvent && selectedEvent.fleet) {
            const initialDate = moment.utc(selectedEvent.alarmTimeBr).subtract(1, 'hours');
            const finalDate = moment.utc(selectedEvent.alarmTimeBr).add(1, 'hours');

            dispatch({
                type: 'loading',
            });
            getHistoricalRouteService
                .execute({
                    fleet: selectedEvent.fleet,
                    initialDate: initialDate.toDate(),
                    finalDate: finalDate.toDate(),
                    size: 15,
                    start: 0,
                    controller,
                })
                .then(data => {
                    dispatch({
                        payload: data,
                        type: 'data',
                    });
                })
                .catch(() => {
                    dispatch({
                        type: 'error',
                    });
                });
        }

        return () => controller.abort();
    }, [selectedEvent]);

    return (
        <Column align="flex-start" gap={24} flex width="100%">
            <Row align="flex-start" gap={16}>
                <Carousel />
                <Column gap={16} align="flex-start">
                    <PastEventInfo />
                </Column>
            </Row>
            <Divider color="#E6E6E6" />
            {selectedEvent ? mapComponent : null}
        </Column>
    );
}
