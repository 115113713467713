import styled from 'styled-components';

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 20px;
`;

export const ModalInfos = styled.div`
    div {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
        gap: 0.2rem;
        margin-bottom: 0.5rem;

        h2 {
            color: #262d3c;
            font-weight: 500;
            font-size: 17px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            > label {
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: rgb(123, 123, 123);
                transform: rotate(-0.16deg);
                margin-right: 0.5rem;
            }

            > span {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: rgb(38, 45, 60);
                word-break: break-all;
            }
        }
    }
`;

export const JustificationContainer = styled.div`
    div {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
        gap: 0.2rem;

        h2 {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: rgb(123, 123, 123);
            transform: rotate(-0.16deg);
            margin-right: 0.5rem;
        }
    }
`;

export const Intro = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        gap: 1rem;
    }
`;

export const Title = styled.h1`
    color: #262d3c;
    margin: 0px;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
`;
