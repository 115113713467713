import styled from 'styled-components';

export const UploadImageSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    span {
        color: #b9b6b6;
        text-align: center;
        font-size: 12px;
        
        h2 {
            font-style: normal;
            font-weight: 10;
        }
    }

    button {
        padding: 0.625rem;
        background-color: #e6e6e6;
        color: #262d3c;
        font-size: 20px;
        font-weight: 500;
        border: 0;
        margin-right: 5px;
        border-radius: 5px;
    }

    .fileInput {
        display: flex;
        flex-direction: row;
        font-size: 12px;
         
        button {
            margin-right: 20px;
        }

        h2 {
            display: flex;
            align-items: center;
            font-weight: 800;
        }
    }
`;

export const UploadImageTop = styled.section`
    padding: 0 10px;
    align-items: center;
    justify-content: center;
    color: #b9b6b6;
    
    button {
        padding: 0.625rem;
        background-color: #e6e6e6;
        color: #262d3c;
        font-size: 20px;
        font-weight: 500;
        border: 0;
        margin-right: 5px;
        border-radius: 5px;
    }

    .fileInput {
        display: flex;
        flex-direction: row;
        font-size: 12px;
         
        button {
            margin-right: 20px;
        }

        h2 {
            display: flex;
            align-items: center;
        }
    }
`;
