import { SVGProps } from 'react';

interface WarningProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export default function Warning({ color = '#000000', ...props }: WarningProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.666748 13.9999H15.3334L8.00008 1.33325L0.666748 13.9999ZM8.66675 11.9999H7.33341V10.6666H8.66675V11.9999ZM8.66675 9.33325H7.33341V6.66659H8.66675V9.33325Z"
                fill={color}
            />
        </svg>
    );
}
