import { SVGProps } from 'react';

export default function LayoutLine(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="1" y="1" width="18" height="6" rx="1" stroke="#262D3C" strokeWidth="2" strokeLinejoin="round" />
            <rect x="1" y="11" width="18" height="6" rx="1" stroke="#262D3C" strokeWidth="2" strokeLinejoin="round" />
        </svg>
    );
}
