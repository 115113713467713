import { createContext, useCallback, useMemo, useReducer, useState } from 'react';

import { DateHelper, FunctionComponent, ResponseStatus } from '../../../../common';
import { Pagination } from '../../../../common/core/pagination';
import { Reducer, reducer } from '../../../../common/types/reducer';
import { DepartmentResume } from '../../../configurations';
import { INITIAL_OPERATION_STATE } from '../../fleets/context/const';
import { LastAlarmRequestDTO, LastAlarmDTO, LastAlarmDetailsDTO } from '../dtos/last-alarm-dtos';
import { LastAlarmFilterDTO } from '../dtos/last-alarm-filter';
import { lastAlarmService } from '../services/last-alarm.service';
import { LastAlarmContextProps } from './types';

export const LastAlarmContext = createContext({} as LastAlarmContextProps);

export const INIT_FILTER_STATE: LastAlarmFilterDTO = {
    operationInput: '',
    operations: [],
};

export function LastAlarmProvider({ children }: FunctionComponent) {
    const [filter, setFilter] = useState<LastAlarmFilterDTO>(INIT_FILTER_STATE);
    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const [operations, setOperations] = useReducer<Reducer<Array<DepartmentResume>>>(reducer, INITIAL_OPERATION_STATE);

    const [listLastAlarm, setListLastAlarm] = useState<Array<LastAlarmDTO> | null>(null);
    const [listLastAlarmDetails, setListLastAlarmDetails] = useState<Pagination<LastAlarmDetailsDTO> | null>(null);

    const [requestData, setRequestData] = useState<LastAlarmRequestDTO>({
        currentPage: 1,
        pageSize: 10,
        dateFrom: DateHelper.yesterday(),
        dateTo: new Date(),
    });
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: true,
        success: false,
        void: false,
    });

    const [responseStatusDetails, setResponseStatusDetails] = useState<ResponseStatus>({
        loading: false,
        error: '',
        hasError: true,
        success: false,
        void: false,
    });

    const handleRequestList = useCallback((data: Partial<LastAlarmRequestDTO>) => {
        setRequestData(state => ({
            ...state,
            ...data,
        }));
    }, []);

    const handleGetList = useCallback(async () => {
        setResponseStatus(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const data = await lastAlarmService.getData(requestData);

            setListLastAlarm(data);

            setResponseStatus({
                loading: false,
                error: undefined,
                hasError: false,
                void: data.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatus({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const handleGetListDetails = useCallback(async () => {
        setResponseStatusDetails(state => ({
            ...state,
            loading: true,
            hasError: false,
        }));

        try {
            const data = await lastAlarmService.getDetails(requestData);

            setListLastAlarmDetails(data);

            setResponseStatusDetails({
                loading: false,
                error: undefined,
                hasError: false,
                void: !data?.elements || data?.elements?.length === 0,
                success: true,
            });
        } catch (error: any) {
            setResponseStatusDetails({
                loading: false,
                error,
                hasError: true,
                void: false,
                success: false,
            });
        }
    }, [requestData]);

    const reset = useCallback(() => {
        setFilter(INIT_FILTER_STATE);
        setFilterIsOpen(true);
        setOperations({
            type: 'data',
            payload: [],
        });
    }, []);

    const handleFilterIsOpen = useCallback(() => {
        setFilterIsOpen(state => !state);
    }, []);

    const data: LastAlarmContextProps = useMemo(
        () => ({
            listLastAlarm,
            responseStatus,
            requestData,
            handleRequestList,
            handleGetList,
            handleGetListDetails,
            listLastAlarmDetails,
            responseStatusDetails,
            filter,
            setFilter,
            filterIsOpen,
            setFilterIsOpen,
            reset,
            handleFilterIsOpen,
            operations,
            setOperations,
        }),
        [
            listLastAlarm,
            responseStatus,
            requestData,
            handleRequestList,
            handleGetList,
            handleGetListDetails,
            listLastAlarmDetails,
            responseStatusDetails,
            filter,
            setFilter,
            filterIsOpen,
            setFilterIsOpen,
            reset,
            handleFilterIsOpen,
            operations,
            setOperations,
        ],
    );

    return <LastAlarmContext.Provider value={data}>{children}</LastAlarmContext.Provider>;
}
